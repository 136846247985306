import {
    Avatar,
    Button,
    Grid,
    Icon,
    IconButton,
    Link,
    Typography,
    Box,
    Paper,
    TextField,
} from '@material-ui/core'
import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { userService } from '_services/user.service'
import { renderText } from '../common/DisplayComponent'
import ApplyBackDrop from '../common/ApplyBackDrop'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        background: '#fff',
        boxShadow: 'unset',
        textTransform: 'capitalize',
        color: '#8f8cac',
        '&:hover': {
            background: '#efefef',
            boxShadow: 'unset',
            textDecoration: 'none',
            // color: '#ffffff',
        },
    },
    formControl: {
        minWidth: '100%',
        marginBottom: 15,
    },
    h5: {
        color: '#25233C',
        fontSize: 18,
    },
    buttonStyle: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
        width: '100%',
        '@media (max-width:635px)': {
            width: '30%',
        },
        '@media (max-width:590px)': {
            width: '35%',
        },
        '@media (max-width:510px)': {
            width: '40%',
        },
        '@media (max-width:460px)': {
            width: '50%',
        },
        '@media (max-width:380px)': {
            width: '60%',
        },
    },
    selectadj: {
        border: '1px solid #B9B8C4',
        height: 50,
        padding: 15,
        borderRadius: 5,
    },
    formContainer: {
        width: '100%',
        height: '98vh',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        minHeight: '350px',
        padding: '16px',
        // margin:"20px 0"
    },
    boxStyle: {
        marginTop: '30px',
        '@media(max-width:600px)':
        {
          padding:'0px 10px'
        }
    },
    displayFlexJustifyCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    marginBottom24: {
        marginBottom: '24px',
    },
    minPaymentSection: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '65%',
        alignItems: 'center',
        marginTop: '30px',
    },
    width44: {
        width: '44%',
    },
    buttonStyle: {

        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
        width: '100%',
        '@media (max-width:635px)': {
            width: '30%',
        },
        '@media (max-width:590px)': {
            width: '35%',
        },
        '@media (max-width:510px)': {
            width: '40%',
        },
        '@media (max-width:460px)': {
            width: '50%',
        },
        '@media (max-width:380px)': {
            width: '60%',
        },
    },
    Imginput: {
        display: 'none',
    },
    displayFlex: {
        display: 'flex',
    },
    dividerStyle: {
        border: 'none',
        borderTop: '1px dashed black',
        color: '#fff',
        backgroundColor: '#fff',
        height: '1px',
    },
    buttonStyleNextPrevious: {
        marginLeft: 10,
        backgroundColor: '#DB0D0D',
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '10px',
        border: '1px solid #DB0D0D',
        '&:hover': {
            background: "#fff",
            border: "1px solid #DB0D0D",
            color: "#DB0D0D"
        },
    },
    outlinedButtonStyle: {
        color: '#fff',
        borderColor: '#DB0D0D',
        backgroundColor: '#DB0D0D',
        borderRadius: '10px',
        '&:hover': {

            color: '#DB0D0D',
        },
    },
    roots: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 10
        },
      },
}))

const Step5 = ({
    faq,
    handleOnChangeFaq,
    handleMoreFieldFaq,
    handleNextFaq,
    handlePrev,
    apiCall,
}) => {
    const classes = useStyles()
    return (
        <div>
            <Box className={classes.marginBottom24}>
                {renderText({ label: 'Add FAQs' })}
            </Box>

            {faq.map((inputField, index) => (
                <Box key={index}>
                    <Box className={classes.boxStyle}>
                        <TextField
                            // label="Working At (Optional)"
                            variant="outlined"
                            placeholder="Question"
                            inputProps={{ maxLength: 80 }}
                            fullWidth={true}
                            size="small"
                            name="question"
                            value={inputField.question}
                            onChange={(event) => handleOnChangeFaq(index, event)}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            className={classes.roots}
                        />
                    </Box>
                    {
                        inputField.questionLength ? (
                            <p style={{ color: 'grey', marginTop: '10px', textAlign: 'right',paddingRight:'10px' }}>{inputField.questionLength}/80</p>
                        ) :
                            (<p style={{ color: 'grey', marginTop: '10px', textAlign: 'right',paddingRight:'10px' }}>0/80</p>)
                    }
                    <Box className={classes.boxStyle}>
                        <TextField
                            id="standard-multiline-static"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 240 }}
                            placeholder="Answer"
                            variant="outlined"
                            fullWidth={true}
                            size="small"
                            name="answer"
                            value={inputField.answer}
                            onChange={(event) => handleOnChangeFaq(index, event)}
                            InputLabelProps={{ shrink: true }}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            className={classes.roots}
                        />
                    </Box>
                    <Box>
                        {
                            inputField.answerLength ? (
                                <p style={{ color: 'grey', marginTop: '10px', textAlign: 'right',paddingRight:'10px' }}>{inputField.answerLength}/240</p>
                            ) :
                                (<p style={{ color: 'grey', marginTop: '10px', textAlign: 'right',paddingRight:'10px' }}>0/240</p>)
                        }

                    </Box>
                    {faq.length > 1 && (
                        <Box className={classes.boxStyle}>
                            <Divider className={classes.dividerStyle} />
                        </Box>
                    )}
                </Box>
            ))}

            {faq.length > 0 && faq.length < 10 ?
                <Box className={classes.buttonStyle} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="outlined"
                        className={classes.outlinedButtonStyle}
                        onClick={handleMoreFieldFaq}
                    >
                        Add More
                    </Button>
                </Box>
                :
                ""
            }
            <Grid container className={classes.buttonStyle} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Box className={classes.buttonStyle} style={{ marginTop: "0px" }}>
                    <Button
                        variant="contained"
                        className={classes.buttonStyleNextPrevious}
                        onClick={handlePrev}
                    >
                        Prev
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.buttonStyleNextPrevious}
                        onClick={handleNextFaq}
                    >
                        {window.location.href.split('/')[
                            window.location.href.split('/').length - 1
                        ] == 'newSp'
                            ? 'Next'
                            : 'Save'}
                    </Button>
                    {<ApplyBackDrop apiCall={apiCall} />}
                </Box>
            </Grid>
        </div>
    )
}

export default Step5
