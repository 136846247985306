import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from '@material-ui/core/Button';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, TextField, Grid, Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { userService } from "_services/user.service";
import { servicesandpackagesService } from "_services/servicesandpackages.service";
import { categoryService } from "_services/category.service";
import { SPService } from "_services/serviceprovider.service";
import SweetAlert from 'react-bootstrap-sweetalert';
import { set } from 'date-fns';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useRouteMatch
} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    displayFlex: {
        display: "flex"
    },
    DelButton: {
        border: "1px solid grey",
        color: "#6362ca",
        backgroundColor: 'white',
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: 'grey',
            color: 'white',
        },
        "&:focus": {
            backgroundColor: 'grey',
            color: 'White',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        // margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    Imginput: {
        display: "none"
    },
    boxStyle: {
        marginTop: "30px",
    },
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid red'
        }
    },
    selectStyle: {

        border: '1px solid #c4c4c4',
        borderRadius: '5px',
        height: '45px'

    },
    errorSelectStyle: {
        border: '2px solid red',
        borderRadius: '5px',
        height: '45px'

    },
    paddingLeft24: {
        paddingLeft: '24px'
    },
    displayFlexJustifyEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    services:
    {
        marginBottom:'14px !important'
    }
}));



const AddService = (props) => {
    const classes = useStyles();

    const { slug } = useParams();
    //  functions ************************************************************************************************************************************

    function getToPrevious() {
        // const { from } = { from: { pathname: "/app/product_services/featuredListing" } };
        // props.history.push(from);
        props.history.goBack();
    }


    function SweetAlertConfirmError(data) {
        const getAlert = () => (
            <SweetAlert
                error
                title="Error"
                onConfirm={() => {
                    setAlertError(null);
                }}
            >
                {data}
            </SweetAlert>
        )
        setAlertError(getAlert());
    }

    function SweetAlertConfirm(data) {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                    if (data == "updated") {
                        setAlert(null);
                        props.history.goBack();
                    }
                    else if (data == "saved") {
                        setAlert(null);
                        props.history.goBack();
                    }
                    else if (data == "deleted") {
                        setAlert(null);
                        props.history.goBack();
                    }
                }}
            >
                Data {data} successfully
            </SweetAlert>
        )
        setAlert(getAlert());
    }


    const deleteFeatured = (idToDelete) => {

        const obj = {
            id: idToDelete
        }
        SPService.deleteFeaturedSp(obj).then((res) => {
            SweetAlertConfirm('deleted')
        })
    }


    function SweetAlertConfirmDelete(id) {
        const getAlert = () => (id &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => {
                    setAlertDelete(null);
                    deleteFeatured(id);
                }}
                onCancel={() => setAlertDelete(null)}
                focusCancelBtn
            >
                You want to delete this record?
              </SweetAlert>
        )
        setAlertDelete(getAlert());
    }

    const saveService = (buttonType) => {
        if (addServiceData.categoryId == undefined || addServiceData.categoryId.length == 0) {
            setErrorCheck({ categoryId: true, subcategoryId: false, servicesId: false, price: false, duration: false })
        }
        else if (addServiceData.subCategoryId == undefined || addServiceData.subCategoryId.length == 0) {
            setErrorCheck({ categoryId: false, subcategoryId: true, servicesId: false, price: false, duration: false })
        }
        else if (addServiceData.serviceId == undefined || addServiceData.serviceId.length == 0) {
            setErrorCheck({ categoryId: false, subcategoryId: false, servicesId: true, price: false, duration: false })
        }
        else if (addServiceData.price == undefined || addServiceData.price.length == 0) {
            setErrorCheck({ categoryId: false, subcategoryId: false, servicesId: false, price: true, duration: false })
        }
        else if (addServiceData.duration == undefined || addServiceData.duration.length == 0) {
            setErrorCheck({ categoryId: false, subcategoryId: false, servicesId: false, price: false, duration: true })
        }
        else {
            let serviceDataToSend = servicesArray.filter((prop) => {
                return prop.id == addServiceData.serviceId;
            })
            const obj = {
                normal_price: addServiceData.price,
                sp_id: slug,
                service_id: serviceDataToSend[0].id,
                title: serviceDataToSend[0].name,
                duration: addServiceData.duration,
                description: addServiceData.description,
                category_id: addServiceData.categoryId,
                subcategory_id: addServiceData.subCategoryId
            }

            if (buttonType == 'save') {
                servicesandpackagesService.postPackage(obj).then((res) => {
                    if (res.error) {
                        SweetAlertConfirmError(res.error);
                    }
                    else {
                        SweetAlertConfirm('saved');
                    }
                })
            }
            else if (buttonType == 'update') {
                obj.id = props.location.state.serviceIdCreatedBySp;
                servicesandpackagesService.putPackage(obj).then((res) => {
                    if (res.error) {
                        SweetAlertConfirmError(res.error);
                    }
                    else {
                        SweetAlertConfirm('updated');
                    }
                })
            }
        }
    }

    // const saveFeatured = () => {
    //     if (dataSendFeatured.subcategoryid.length == 0) {
    //         setErrorCheck({ spid: false, subcategoryid: true });
    //     } else if (dataSendFeatured.spid.length == 0) {
    //         setErrorCheck({ spid: true, subcategoryid: false });
    //     } else if (dataSendFeatured.subcategoryid.length == 0 && dataSendFeatured.spid.length == 0) {
    //         setErrorCheck({ spid: true, subcategoryid: true });
    //     }
    //     else {

    //         const obj = {
    //             id: dataSendFeatured.spid,
    //             featured_from: dataSendFeatured.fromDate.toISOString().split('T')[0],
    //             featured_to: dataSendFeatured.toDate == null ? new Date(dataSendFeatured.fromDate).toISOString().split('T')[0] : dataSendFeatured.toDate.toISOString().split('T')[0],
    //             subcategory_id: dataSendFeatured.subcategoryid
    //         }
    //         SPService.postFeaturedSp(obj).then((res) => {
    //             if (res.message) {
    //                 SweetAlertConfirmError(res.message)
    //             }
    //             else {
    //                 SweetAlertConfirm('saved');
    //             }
    //         })
    //     }
    // }



    //  setState declarations ************************************************************************************************************************************

    const [errorCheck, setErrorCheck] = React.useState({
        categoryId: false,
        subcategoryId: false,
        servicesId: false,
        price: false,
        duration: false
    });

    const [apiCallForEdit, setApiCallForEdit] = React.useState(false);
    const [categoryArray, setCategoryArray] = React.useState([]);
    const [subcategoryArray, setSubCategoryArray] = React.useState([]);
    const [servicesArray, setServicesArray] = React.useState([]);
    const [alertError, setAlertError] = React.useState(null);
    const [alert, setAlert] = React.useState(null);
    const [alertDelete, setAlertDelete] = React.useState(null);
    const [addServiceData, setAddServiceData] = React.useState({
        categoryId: '',
        subCategoryId: '',
        serviceId: '',
        price: '',
        duration: '',
        description: ''
    })



    //  useEffect Code  ************************************************************************************************************************************


    React.useEffect(() => {
        const getCategory = async () => {
            setApiCallForEdit(true);
            try {
                const response = await categoryService.getCategory();
                setApiCallForEdit(false);
                let categoryArr = response.map((prop, index) => {
                    return {
                        'id': prop._id,
                        'name': prop.name
                    }
                })

                setCategoryArray([...categoryArr]);
            }
            catch (err) {
                setApiCallForEdit(false);
            }
        }
        getCategory();

    }, [])

    //   For Edit services ************************************************************************************************************************************************************************* 

    React.useEffect(() => {
        const getData = async () => {
            if (categoryArray.length > 0 && window.location.href.split('/')[window.location.href.split('/').length - 2] == 'editService') {
                setApiCallForEdit(true);
                try {
                    if (props.location?.state?.categoryId) {
                        setAddServiceData(prevState => {
                            return {
                                ...prevState,
                                price: props.location.state.price,
                                duration: props.location.state.duration,
                                description: props.location.state.description,
                                categoryId: props.location.state.categoryId
                            }
                        })

                        // on the basis of category show subcat from props edit 

                        const responseForSubCategoryData = await categoryService.getSubCategorybyId2(props.location.state.categoryId);
                        let subcategoryArr = responseForSubCategoryData.map((prop, index) => {
                            return {
                                'id': prop._id,
                                'name': prop.name
                            }
                        })
                        setSubCategoryArray([...subcategoryArr]);
                        setAddServiceData(prevState => {
                            return {
                                ...prevState,
                                subCategoryId: props.location.state.subCategoryId
                            }
                        })

                        // on the basis of subCat show services from props edit 
                        const responseForServices = await servicesandpackagesService.getServicebyId2(props.location.state.subCategoryId);
                        let servicesArr = responseForServices.map((prop, index) => {
                            return {
                                'id': prop._id,
                                'name': prop.name
                            }
                        })
                        setServicesArray([...servicesArr])
                        setAddServiceData(prevState => {
                            return {
                                ...prevState,
                                serviceId: props.location.state.serviceId
                            }
                        })
                    }
                    setApiCallForEdit(false)
                }
                catch (err) {
                    setApiCallForEdit(false)
                }
            }
        }
        getData();

    }, [categoryArray])

    //    React.useEffect(async()=>{
    //     if(addServiceData.categoryId !=='' && window.location.href.split('/')[window.location.href.split('/').length - 2] == 'editService'){
    //         if(props.location?.state?.subCategoryId){
    //         const responseForSubCategoryData = await categoryService.getSubCategorybyId2(props.location.state.categoryId);
    //         let subcategoryArr = responseForSubCategoryData.map((prop, index) => {
    //             return {
    //                 'id': prop._id,
    //                 'name': prop.name
    //             }
    //         })
    //         setSubCategoryArray([...subcategoryArr]);
    //         setAddServiceData(prevState=>{
    //                return {
    //                    ...prevState,
    //                    subCategoryId:props.location.state.subCategoryId
    //                }
    //            })
    //         }
    //     }

    //    },[addServiceData.categoryId])


    //  ************************************************************************************************************************************************************************* 

    //   For SelectChange ************************************************************************************************************************************************************************* 

    const onChangeSelectCategory = async (event) => {
        setErrorCheck(prevState => {
            return {
                categoryId: false,
                subcategoryId: false,
                servicesId: false,
                price: false,
                duration: false
            }
        })
        setAddServiceData({ ...addServiceData, categoryId: event.target.value, subCategoryId: '', serviceId: '' });
        const responseForSubCategoryData = await categoryService.getSubCategorybyId2(event.target.value);
        let subcategoryArr = responseForSubCategoryData.map((prop, index) => {
            return {
                'id': prop._id,
                'name': prop.name
            }
        })
        setSubCategoryArray([...subcategoryArr]);
    }

    const onChangeSelectSubCategory = async (event) => {
        setErrorCheck(prevState => {
            return {
                categoryId: false,
                subcategoryId: false,
                servicesId: false,
                price: false,
                duration: false
            }
        })
        setAddServiceData({ ...addServiceData, subCategoryId: event.target.value, serviceId: '' });
        try {
            const responseForServices = await servicesandpackagesService.getServicebyId2(event.target.value);
            let servicesArr = responseForServices.map((prop, index) => {
                return {
                    'id': prop._id,
                    'name': prop.name
                }
            })
            setServicesArray([...servicesArr])
        }
        catch (err) {

        }
    }

    const onChangeSelectServices = async (event) => {
        setErrorCheck(prevState => {
            return {
                categoryId: false,
                subcategoryId: false,
                servicesId: false,
                price: false,
                duration: false
            }
        })
        setAddServiceData({ ...addServiceData, serviceId: event.target.value });

    }

    const handleTextData = (event) => {
        setAddServiceData({ ...addServiceData, [event.target.name]: event.target.value })

        if (event.target.value.length == 0) {
            setErrorCheck(prevState => {
                return {
                    ...prevState,
                    [event.target.name]: true
                }
            })
        }
        else {
            setErrorCheck(prevState => {
                return {
                    ...prevState,
                    [event.target.name]: false
                }
            })
        }
    }


    return (

        <>
            {
                <div className={classes.container}>
                    {
                        <form style={{ padding: '0px'}}>
                            <Card style={{marginTop: 0}}>
                                <GridContainer style={{ width: "100%" }}>
                                    <GridItem xs={12} sm={12} md={8}>
                                        <div>
                                            <div className={classes.header}>
                                                <IconButton aria-label="edit" onClick={getToPrevious}>
                                                    <ArrowBackIcon />
                                                </IconButton>
                                                {window.location.href.split('/')[window.location.href.split('/').length - 2] !== 'editService' && <h5 style={{fontSize: 20, fontFamily: "Muli-Bold, marginBottom:'14px'"}} className={classes.services}>Add Service</h5>}
                                                {window.location.href.split('/')[window.location.href.split('/').length - 2] == 'editService' && <h5 style={{fontSize: 20, fontFamily: "Muli-Bold", color: "#25233C",marginBottom:'15px'}}>Edit Service</h5>}
                                            </div>

                                        </div>
                                    </GridItem>
                                </GridContainer>
                                <CardBody className="px-0 pb-0"></CardBody>
                                {apiCallForEdit == false && categoryArray.length > 0 &&
                                    <div style={{ padding: '0px 30px' }}>
                                        <GridContainer >
                                            <GridItem xs={12} sm={12} md={6} className={classes.boxStyle}>

                                                <Box className={classes.boxStyle} >
                                                    <InputLabel htmlFor="category" style={{fontSize: 18, fontFamily: "Muli-SemiBold", color: "#25233C"}}>Select Category *</InputLabel>
                                                    <Select
                                                        required
                                                        disableUnderline={true}
                                                        className={`${classes.paddingLeft24} ${errorCheck.categoryId == true ? classes.errorSelectStyle : classes.selectStyle}`}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        name="category"
                                                        fullWidth={true}
                                                        value={addServiceData.categoryId}
                                                        onChange={onChangeSelectCategory}
                                                        input={<Input
                                                        // classes={{
                                                        //     underline:errorCheck.spid==true ? classes.underline:'',
                                                        //   }} 
                                                        />} >
                                                        {categoryArray.length == 0 && <MenuItem value={''}>No Category added *</MenuItem>}
                                                        {categoryArray.length > 0 &&
                                                            categoryArray.map((val, index) => (
                                                                <MenuItem key={val.id} value={val.id}>
                                                                    {val.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </Box>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} className={classes.boxStyle}>

                                                <Box className={classes.boxStyle}>
                                                    <InputLabel htmlFor="subCategory" style={{fontSize: 18, fontFamily: "Muli-SemiBold", color: "#25233C"}}>Select SubCategory *</InputLabel>
                                                    <Select
                                                        disableUnderline={true}
                                                        className={`${classes.paddingLeft24} ${errorCheck.subcategoryId == true ? classes.errorSelectStyle : classes.selectStyle}`}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        name="subcategoryid"
                                                        fullWidth={true}
                                                        value={addServiceData.subCategoryId}
                                                        onChange={onChangeSelectSubCategory}
                                                        input={<Input
                                                        // classes={{
                                                        //     underline:errorCheck.spid==true ? classes.underline:'',
                                                        //   }} 
                                                        />} >
                                                        {addServiceData.categoryId == '' && <MenuItem value={''}>Select Category First </MenuItem>}
                                                        {addServiceData.categoryId !== '' && subcategoryArray.length == 0 && <MenuItem value={''}>No SubCategory Found </MenuItem>}
                                                        {subcategoryArray.length > 0 &&
                                                            subcategoryArray.map((val, index) => (
                                                                <MenuItem key={val.id} value={val.id}>
                                                                    {val.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </Box>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} className={classes.boxStyle}>

                                                <Box className={classes.boxStyle} >
                                                    <InputLabel htmlFor="subCategory" style={{fontSize: 18, fontFamily: "Muli-SemiBold", color: "#25233C"}}>Select Service *</InputLabel>
                                                    <Select
                                                        disableUnderline={true}
                                                        className={`${classes.paddingLeft24} ${errorCheck.servicesId == true ? classes.errorSelectStyle : classes.selectStyle}`}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        name="subcategoryid"
                                                        fullWidth={true}
                                                        value={addServiceData.serviceId}
                                                        onChange={onChangeSelectServices}
                                                        input={<Input
                                                        // classes={{
                                                        //     underline:errorCheck.spid==true ? classes.underline:'',
                                                        //   }} 
                                                        />} >
                                                        {addServiceData.subCategoryId == '' && <MenuItem value={''}>Select Subcategory First </MenuItem>}
                                                        {addServiceData.subCategoryId !== '' && servicesArray.length == 0 && <MenuItem value={''}>No Service Found </MenuItem>}
                                                        {servicesArray.length > 0 &&
                                                            servicesArray.map((val, index) => (
                                                                <MenuItem key={val.id} value={val.id}>
                                                                    {val.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </Box>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <Box className={classes.boxStyle}>
                                                    <InputLabel htmlFor="price" style={{fontSize: 18, fontFamily: "Muli-SemiBold", color: "#25233C"}}>Price *</InputLabel>
                                                    <TextField
                                                        // label="Working At (Optional)"
                                                        className={errorCheck.price == true ? classes.root : ''}
                                                        variant="outlined"
                                                        placeholder="Enter Price"
                                                        fullWidth={true}
                                                        size="small"
                                                        name="price"
                                                        type="number"
                                                        value={addServiceData.price}
                                                        onChange={handleTextData} />
                                                </Box>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} className={classes.boxStyle}>

                                                <Box className={classes.boxStyle} >
                                                    <InputLabel htmlFor="duration" style={{fontSize: 18, fontFamily: "Muli-SemiBold", color: "#25233C"}}>Duration *</InputLabel>
                                                    <Select
                                                        disableUnderline={true}
                                                        className={`${classes.paddingLeft24} ${errorCheck.duration == true ? classes.errorSelectStyle : classes.selectStyle}`}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        name="duration"
                                                        fullWidth={true}
                                                        value={addServiceData.duration}
                                                        onChange={handleTextData}
                                                        input={<Input
                                                        // classes={{
                                                        //     underline:errorCheck.spid==true ? classes.underline:'',
                                                        //   }} 
                                                        />} >
                                                        <MenuItem value={'15 min'}>15 min </MenuItem>
                                                        <MenuItem value={'30 min'}>30 min </MenuItem>
                                                        <MenuItem value={'45 min'}>45 min </MenuItem>
                                                        <MenuItem value={'60 min'}>60 min </MenuItem>
                                                        <MenuItem value={'1-2 hrs'}>1-2 hrs </MenuItem>
                                                        <MenuItem value={'2+ hrs'}>2+ hrs </MenuItem>
                                                    </Select>
                                                </Box>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <Box className={classes.boxStyle}>
                                                    <InputLabel htmlFor="description" style={{fontSize: 18, fontFamily: "Muli-SemiBold", color: "#25233C"}}>Description (optional)</InputLabel>
                                                    <TextField
                                                        // label="Working At (Optional)"
                                                        variant="outlined"
                                                        placeholder="Enter Description"
                                                        fullWidth={true}
                                                        size="small"
                                                        name="description"
                                                        value={addServiceData.description}
                                                        onChange={handleTextData} />
                                                </Box>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Box className={classes.boxStyle} style={{ width: "40%" }}>
                                                    {
                                                        window.location.href.split('/')[window.location.href.split('/').length - 2] !== 'editService' &&
                                                        <Button className={classes.showBtn} variant="contained" color="primary" onClick={() => { saveService('save') }} style={{fontSize: 16, fontFamily: "Muli", color: "#fff"}}>
                                                            Save
                                            </Button>
                                                    }
                                                    {
                                                        window.location.href.split('/')[window.location.href.split('/').length - 2] == 'editService' &&
                                                        <Button className={classes.showBtn} variant="contained" color="primary" onClick={() => { saveService('update') }} style={{fontSize: 16, fontFamily: "Muli", color: "#fff"}}>
                                                            Update
                                            </Button>
                                                    }
                                                </Box>
                                            </GridItem>

                                        </GridContainer>
                                    </div>}
                                {apiCallForEdit == true &&
                                    <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </div>
                                }
                            </Card>
                        </form>
                    }
                </div>
            }

            <>
                {alertError}
            </>
            <>
                {alert}
            </>
            <>
                {alertDelete}
            </>

        </>


    );
}

export default AddService;
