/* eslint-disable prettier/prettier */
import { BehaviorSubject } from 'rxjs';

import { handleResponse } from "_helpers/handle-response.js";

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));

export const servicesandpackagesService = {
    getServicesbySubCategory,
    getServicebyId,
    getServicebyId2,
    postServices,
    putServices,
    deleteServices,
    serviceStatusChange,
    getPackagesbySubCategory,
    postPackage,
    putPackage,
    deletePackage,
    packageStatusChange,
    getPackagesbyServiceprovider,
    getComboPackagesBySp,
    deleteComboPackage,
    postComboPackage,
    getCategoryOrSubcategoryBasedOnSpService,
    getServicesByServiceProviderAndSubcategory,
    updateComboPackage
}

function getServicesbySubCategory(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'valueby' : 'subcategory',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Services/${id}`, requestOptions).then(handleResponse);
}

function getServicebyId(id){
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'id' : id,
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Services`, requestOptions).then(handleResponse);
}

function getServicebyId2(id){
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'valueby' : 'subcategory',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Services/${id}`, requestOptions).then(handleResponse);
}


function postServices(obj) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'valueby' : 'subcategory',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body : JSON.stringify(obj)
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Services`, requestOptions).then(handleResponse);
}

function putServices(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'valueby' : 'subcategory',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body : JSON.stringify(obj)
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Services`, requestOptions).then(handleResponse);
}

function deleteServices(data) {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify({ "id": data })
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Services`, requestOptions).then(handleResponse);
}

function serviceStatusChange(status, data) {

    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({ id: data }),
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_URL}/CategoryController/${status}`, requestOptions).then(handleResponse);
}


function getPackagesbySubCategory(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'valueby' : 'subcategory',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/Packages/${id}`, requestOptions).then(handleResponse);
}

function getPackagesbyServiceprovider(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'valueby' : 'serviceprovider',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/Packages/${id}`, requestOptions).then(handleResponse);
}


function postPackage(obj) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body : JSON.stringify(obj)
    }
    return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/Packages`, requestOptions).then(handleResponse);
}

function putPackage(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body : JSON.stringify(obj)
    }
    return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/Packages`, requestOptions).then(handleResponse);
}

function deletePackage(data) {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify({ "id": data })
    };
    return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/Packages`, requestOptions).then(handleResponse);
}

function packageStatusChange(status, data) {

    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({ id: data }),
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/${status}`, requestOptions).then(handleResponse);
}

function getComboPackagesBySp(id){
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'spid' : id,
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/ComboPackages`, requestOptions).then(handleResponse);
}


function getCategoryOrSubcategoryBasedOnSpService(obj){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify(obj)
    };
    return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/spCategoryandSubcategory`, requestOptions).then(handleResponse);
}

function getServicesByServiceProviderAndSubcategory(obj){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify(obj)
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/SpPackagesByIds`, requestOptions).then(handleResponse);
}


function deleteComboPackage(data) {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify({ "id": data })
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/ComboPackages`, requestOptions).then(handleResponse);
}

function postComboPackage(obj){
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);
    var formdata = new FormData();
    formdata.append("normal_price", obj.normal_price);
    formdata.append("category_id", obj.category_id);
    formdata.append("sub_category_id",obj.sub_category_id)
    formdata.append("sp_id",obj.sp_id)
    formdata.append("description",obj.description)
    formdata.append("title",obj.title)
    formdata.append("duration",obj.duration)
    formdata.append("image_url", obj.image_url)

    if(obj.category_id && obj.category_id.length>0){
        obj.category_id.map((prop,index)=>{
            formdata.append("category_id[]",prop)
          })
    }

    if(obj.sub_category_id && obj.sub_category_id.length>0){
        obj.sub_category_id.map((prop,index)=>{
            formdata.append("sub_category_id[]",prop)
          })
    }


    if(obj.service_id && obj.service_id.length>0){
      obj.service_id.map((prop,index)=>{
        formdata.append("service_id[]",prop)
      })
    }

    if(obj.packages_id && obj.packages_id.length>0){
        obj.packages_id.map((prop,index)=>{
          formdata.append("package_id[]",prop)
        })
    }
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
     return fetch(`${process.env.REACT_APP_URL}/CategoryController/ComboPackages`,requestOptions).then(handleResponse);
}

function updateComboPackage(obj){
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);
    var formdata = new FormData();
    formdata.append("id", obj.id);
    formdata.append("normal_price", obj.normal_price);
    formdata.append("sp_id",obj.sp_id)
    formdata.append("description",obj.description)
    formdata.append("title",obj.title)
    formdata.append("duration",obj.duration)
    formdata.append("image_url",obj.image_url)

    if(obj.category_id && obj.category_id.length>0){
        obj.category_id.map((prop,index)=>{
            formdata.append("category_id[]",prop)
          })
    }

    if(obj.sub_category_id && obj.sub_category_id.length>0){
        obj.sub_category_id.map((prop,index)=>{
            formdata.append("sub_category_id[]",prop)
          })
    }

    if(obj.service_id && obj.service_id.length>0){
      obj.service_id.map((prop,index)=>{
        formdata.append("service_id[]",prop)
      })
    }

    if(obj.packages_id && obj.packages_id.length>0){
        obj.packages_id.map((prop,index)=>{
          formdata.append("package_id[]",prop)
        })
    }
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
     return fetch(`${process.env.REACT_APP_URL}/CategoryController/ComboPackages/${obj.id}`,requestOptions).then(handleResponse);
}

export const getPackageImages = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append('token', JSON.parse(sessionStorage.getItem('currentUser')).token);
      myHeaders.append('Content-Type', 'application/json');
  
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };
  
      let response = await fetch(`${process.env.REACT_APP_URL}/app/SpAppController/packageImages`, requestOptions);
      return { ok: response.ok, data: await response.json() };
    } catch (e) {
      console.log(e);
    }
  }