import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import AppBar from '@material-ui/core/AppBar';
import { Icon } from '@material-ui/core';
import AddBadge from './AddBadge';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ReactTable from "_components/ReactTable.js";
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditBadge from './EditBadge';
import { categoryService } from "_services/category.service";
import edit from '../../../edit-svgrepo-com.svg'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    right: "3pc",
    // top: "7pc",
    top: "6pc",
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8d9d",
    "@media (max-width: 576px)": {
      position: "unset",
      top: "unset",
      right: "unset",
      marginLeft: "16px",
      paddingLeft: "12px !important"
    }
  },
  SaveButton: {
    backgroundColor: '#6362ca',
    color: '#ffffff',
    width: 100,
    fontSize: "10px",
    padding: "10px",
    textTransform: "capitalize",
    fontSize: 14,
    "&:hover": {
      backgroundColor: '#6362ca',
      color: '#ffffff',
    },
    "&:focus": {
      backgroundColor: '#6362ca',
      color: '#ffffff',
    }
  },
  DelButton: {
    border: "1px solid grey",
    color: "#8E8CAC",
    textTransform: "capitalize",
    fontSize: 14,
    "&:hover": {
      backgroundColor: '#6362ca',
      color: '#ffffff',
    },
    "&:focus": {
      backgroundColor: '#6362ca',
      color: '#ffffff',
    }
  },
  btnAlign: {
    display: "flex",
    marginLeft: "5px",
  },
  h5: {
    fontSize: 18
  },
  container: {
    // border:"1px solid grey",
    margin: "0 -20px"
  },
  Imginput: {
    display: "none",

  },
  UploadBtn: {
    padding: "10px 15px",
    fontSize: "14px",
    textTransform: "capitalize",
    marginBottom: "7px",
    background: "#8E8CAC"
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
    paddingLeft: '8px',
    color: '#25233C'
  },
  fontSize20: {
    fontSize: '20px',
    color: '#25233C',
    marginBottom: 10,
  },
  tableCard: {
    "@media (max-width: 1340px)": {
      overflow: "auto"
    }
  },
  tableCardBody: {
    padding: 0,
    "@media (max-width: 1340px)": {
      width: "991px",
    }
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  addNewBadge: {
    color: '#6B6987', fontSize: '15px'
  }
}));

const Badges = (props) => {
  const classes = useStyles();
  let { path, url } = useRouteMatch();

  const [badgesArray, setBadgesArray] = React.useState([]);
  const [bagesDisplayed, setBadgesDisplayed] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [alertDelete, setAlertDelete] = React.useState(null);
  const [apiCall, setApiCall] = React.useState(false);

  function SweetAlertConfirmDelete() {
    const getAlert = () => (
      <SweetAlert
        success
        title="Woot!"
        onConfirm={() => {
          setAlertDelete(null);
        }}
      >
        Data deleted successfully
      </SweetAlert>
    )
    setAlertDelete(getAlert());
  }


  const deleteBadges = (id) => {
    categoryService.deleteBadge(id).then((res) => {
      SweetAlertConfirmDelete();
    })
  }

  function SweetAlertConfirm(id) {
    const getAlert = () => (id &&
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="success"
        title="Are you sure?"
        onConfirm={() => {
          setAlert(null);
          deleteBadges(id);
        }}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You want to delete this record?
      </SweetAlert>
    )
    setAlert(getAlert());
  }


  React.useEffect(() => {

    const getBadges = async () => {
      setApiCall(true)
      try {
        const res = await categoryService.getBadge();
        setBadgesArray(res);
        setApiCall(false)
      }
      catch (err) {
        setApiCall(false)
      }
    }

    getBadges();


  }, [props.history.location.pathname, alertDelete])


  React.useEffect(() => {
    if (badgesArray.length > 0) {

      let array = [];
      badgesArray.map((prop) => {
        array.push({
          id: prop._id,
          name: prop.title,
          image: prop?.icon?.split('/')[prop?.icon?.split('/').length - 1],
          actions: (
            <div className="actions-right">
              <Link to={{
                pathname: "/app/product_services/badges/editBadge", state: {
                  type: prop.type,
                  id: prop._id,
                  title: prop.title,
                  iconName: prop.icon,
                  containerColor: prop.container_color,
                  outlineColor: prop.outline_color,
                  textColor: prop.text_color,
                  height:prop.height,
                  width:prop.width
                }
              }}>
                <IconButton style={{paddingRight:'5px'}}>
                <img src={edit} style={{width:'21px', height:'18px',color:'#8E8CAC'}}/>
                </IconButton>
              </Link>
              {" "}
              {prop.type !== 'auto' &&
                <IconButton onClick={() => {
                  SweetAlertConfirm(prop._id);
                }} style={{padding:'0px',paddingRight:'5px'}}>
                  <DeleteIcon style={{width:'21px', height:'18px',color:'#8E8CAC' }} />
                </IconButton>}
              {" "}
            </div>
          )
        })
      });
      setBadgesDisplayed(array);
    }
  }, [badgesArray])


  function getToPrevious() {
    const { from } = { from: { pathname: "/app/product_services" } };
    props.history.goBack();
  }

  return (
    <>
      {
        window.location.href.split('/')[window.location.href.split('/').length - 1] == 'badges' && <div>
          <AppBar elevation={0} position="static" color="transparent" className={classes.appbarAdj} >
            <div className={classes.header}>
              <IconButton aria-label="edit" onClick={getToPrevious} style={{paddingLeft:'0px',marginLeft:'-8px'}}>
                <ArrowBackIcon />
              </IconButton>
              <h4 className={classes.fontSize20} style={{fontFamily: "Muli-Bold", transform: 'translateY(-5px)', color: "#25233C"}}>Badges</h4>
            </div>
            <Link to="/app/product_services/badges/addBadge">
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<Icon style={{ color: '#6B6987' }}>add</Icon>}
              >
                <span className={classes.addNewBadge}>Add New Badge</span>
              </Button>
            </Link>
          </AppBar>
          <GridContainer container>
            <GridItem xs={12}  style={{marginLeft:'-15px',marginRight:'-30px'}}>
              <Card className={classes.tableCard}>
                <CardHeader color="primary"
                  // style={{paddigBottom:'15px' }} 
                  icon>
                  {/* <CardIcon color="primary">
                    <Assignment />
                  </CardIcon> */}
                  {/* <h4 className={classes.cardIconTitle}>React Table</h4> */}
                </CardHeader>
                <CardBody className={classes.tableCardBody}>
                  {badgesArray.length == 0 && apiCall == true &&
                    <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                      <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                  }

                  {badgesArray.length == 0 && apiCall == false &&
                    <h6 style={{ textAlign: 'center', marginBottom: 20 }}>
                      No Badges
                    </h6>
                  }

                  {badgesArray.length > 0 && apiCall == false &&
                    <ReactTable
                      className={'user-table-adj'}
                      columns={[
                        {
                          Header: "Name",
                          accessor: "name",
                          placeholder: "name"
                        },
                        {
                          Header: "Image",
                          accessor: "image",
                          Footer: "Image"
                        },
                        {
                          Header: "",
                          accessor: "actions",
                        }

                      ]}

                      data={bagesDisplayed.length > 0 ? bagesDisplayed : []}
                      onRowClick={(rowData, clickType) => { }}
                    // onRowClick={(rowData, clickType) => handleRowClick(rowData, clickType)}
                    />
                  }

                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>

      }

      <div className="container-fluid">
        <Switch>
          <Route path={`${path}/addBadge`}>
            <AddBadge {...props} />
          </Route>
          <Route path={`${path}/editBadge`}>
            <EditBadge {...props} />
          </Route>
        </Switch>
      </div>

      <>
        {alert}
      </>
      <>
        {alertDelete}
      </>

    </>
  );
}

export default Badges;
