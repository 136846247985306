import React,{useEffect} from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Role} from '_helpers/role.js';
import { authenticationService } from '_services/authentication.service';
import { PrivateRoute } from '_components/PrivateRoute';

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/BooKAProLogo.png";
import { useMediaQuery } from "@material-ui/core";

let ps;


  
  const useStyles = makeStyles(styles);
  
  export default function App({ ...rest }) {
    // styles
    const isMedium = useMediaQuery("@media(max-width: 767px)");
    const classes = useStyles();
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
    // states and functions
    const [image, setImage] = React.useState(bgImage);
    const [color, setColor] = React.useState("blue");
    const [currentUser, setCurrentUser] = React.useState(null);
    const [role, setRole] = React.useState("");
    const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const switchRoutes = (
      <Switch>
        {routes.map((prop, key) => {
          if (prop.layout === "/app") {
            return (
              <PrivateRoute
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
                roles={prop.roles}
              />
            );
          }
          return null;
        })}
        {role==="111" && <Redirect from="/" to="/newSp" /> }
        {role==="333" && <Redirect from="/" to="/app/dashboard" /> }
      </Switch>
    );

    const handleImageClick = (image) => {
      setImage(image);
    };
    const handleColorClick = (color) => {
      setColor(color);
    };
    const handleFixedClick = () => {
      if (fixedClasses === "dropdown") {
        setFixedClasses("dropdown show");
      } else {
        setFixedClasses("dropdown");
      }
    };
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
      return window.location.pathname !== "/admin/maps";
    };
    const resizeFunction = () => {
      if (window.innerWidth >= 960) {
        setMobileOpen(false);
      }
    };
    // initialize and destroy the PerfectScrollbar plugin
    React.useEffect(() => {
       
      let disposable = authenticationService.currentUser.subscribe(x => {
        setCurrentUser(x);
        setRole(x && x.user.role);
        // console.log(x.user.role)
      });

      if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
        document.body.style.overflow = "hidden";
      }
      window.addEventListener("resize", resizeFunction);
      // Specify how to clean up after this effect:
      return function cleanup() {

        disposable.unsubscribe(x => {
          setCurrentUser(x);
          setRole(x && x.user.role);
        });

        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy();
        }
        window.removeEventListener("resize", resizeFunction);
      };
    }, [mainPanel, currentUser]);
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          role={role}
          logoText={"Book A Pro"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        />
        <div className={classes.mainPanel} ref={mainPanel}>
          <Navbar
            routes={routes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={!isMedium && classes.container}>{switchRoutes}</div>
          </div>
          {getRoute() ? <Footer /> : null}
          {/* <FixedPlugin
            handleImageClick={handleImageClick}
            handleColorClick={handleColorClick}
            bgColor={color}
            bgImage={image}
            handleFixedClick={handleFixedClick}
            fixedClasses={fixedClasses}
          /> */}
        </div>
      </div>
    );
  }
  