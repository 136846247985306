import React from 'react'
import { Avatar, Button, Grid, Icon, IconButton, Link, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Image } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { userService } from "_services/user.service";
import SweetAlert from 'react-bootstrap-sweetalert';
const useStyles = makeStyles((theme) => ({

    button: {
        margin: theme.spacing(1),
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "#efefef",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
    },
    formControl: {
        minWidth: '100%'
    },
    
}));

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        border: '1px solid',
        backgroundColor: '#6362ca',
        borderColor: '#6362ca',
        color: '#ffffff',
        '&:hover': {
            border: '1px solid',
            backgroundColor: '#6362ca',
            borderColor: '#6362ca',
            color: '#ffffff',
        },
        '&:active': {
            border: '1px solid',
            backgroundColor: '#6362ca',
            borderColor: '#6362ca',
            color: '#ffffff',
        },
        '&:focus': {
            border: '1px solid',
            backgroundColor: '#6362ca',
            borderColor: '#6362ca',
            color: '#ffffff',
        },
    },
})(Button);


const SPVerificationHeader = (props) => {
    // console.log(props);
    const classes = useStyles();
    const [data, setData] = React.useState(props.data);
    const [alert, setAlert] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [selectedStatus, setSelectedStatus] = React.useState('');

    const handleClickOpen = () => {
        setSelectedStatus(data.user.active || '');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function ActivateSP() {        
        userService.activateServiceProvider({id:props.location.state.request.user._id,active:true,description:''})
            .then((res) => {
                SweetAlertConfirm("true");
            })
    }

    function DeleteSP() {
        userService.activateServiceProvider({id:props.location.state.request.user._id,active:false,description:''})
            .then((res) => {
                SweetAlertConfirm("false");
            })
    }

    function SweetAlertConfirm(status) {
        const getTrueAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() =>{
                setAlert(null)
                const { from } = { from: { pathname: "/app/service_providers/verification/requests", ...props } };
                props.history.push(from);
                } }
            >
                Service Provider request approved successfully
            </SweetAlert>
        )
        const getFalseAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                setAlert(null)
                const { from } = { from: { pathname: "/app/service_providers/verification/requests", ...props } };
                props.history.push(from);
                }}
            >
                Service Provider request deleted successfully
            </SweetAlert>
        )
        if (status == "true")
            setAlert(getTrueAlert())
        else {
            setAlert(getFalseAlert())
        }
    }

    return (
        <>
            {<>
                <div >
                    <Dialog open={open} onClose={(e) => handleClose(e)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Change user status</DialogTitle>
                        <DialogContent>
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={selectedStatus}
                                    onChange={(e) => handleSelectChange(e)}
                                    input={<Input />} >
                                    <MenuItem value={'true'}>Active</MenuItem>
                                    <MenuItem value={'false'}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose()} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => saveChangedStatus()} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Grid container justifyContent="flex-end" style={{ margin: 15, padding: 10, background: "#fff", borderRadius: 5,padding:'10px 10px 5pc'}} >
                    <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end", background: "#8e8cac30", height: "88px", alignItems: "center", paddingRight: 20, borderRadius: 5 }}>
                        <Link className={classes.textDecNone}>
                            <BootstrapButton
                                variant="contained"
                                color="default"
                                className={classes.button}
                                onClick={() => ActivateSP()}
                            >Activate Account
                            </BootstrapButton>
                        </Link>
                        <Link className={classes.textDecNone}>
                            <Button
                                variant="contained"
                                color="default"
                                className={classes.button}
                                onClick={() => DeleteSP()}
                            >Delete Request
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end", height: "90px", alignItems: "center", position: "relative" }}>
                        <div className={classes.ImgBorder} style={{ border: "3px solid #fff", height: 115, width: 115, borderRadius: "50%", position: "absolute", left: 20, top: "-4.5em", backgroundImage: `url(${props && props.location?.state?.request?.user?.image_url ? props.location?.state?.request?.user?.image_url : 'https://clipartart.com/images/clipart-profile-4.jpg'})`, backgroundPosition: "center center", backgroundSize: "cover" }}>
                        </div>
                        <Grid item container>
                            <Grid item md={6} alignItems="flex-end" style={{ display: "flex", padding: "0 20px 0", marginTop: '40px',flexDirection:'column',alignItems:'flex-start' }}>
                                <Typography gutterBottom variant="h5" style={{ fontSize: 20, marginBottom: "unset", fontFamily: "Muli-SemiBold" }} >
                                    {props.location?.state?.request?.user?.name ? props.location?.state?.request?.user?.name :'' }
                                </Typography>
                                <Typography gutterBottom variant="h6" style={{ fontSize: 15, marginBottom: "unset", fontWeight: 200,color:'#9997B2' }} >
                                    {props.location?.state?.request?.user?.profession ? props.location.state.request.user.profession :'' }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {alert}
            </>}
        </>

    )
}

export default SPVerificationHeader
