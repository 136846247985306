import React from "react";
import Card from "components/Card/Card.js";
import {  withStyles,makeStyles } from "@material-ui/core/styles";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles((styles) => ({   
    divPadColor:{
        background: "#F3F3FC",
        padding:'16px',
        whiteSpace:'nowrap' 
    },
    padding16:{
        padding:'16px'
    },
    tcell1: {
        border: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell2: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    totaldivStyle:{
      width: "87%",
      padding: "0px 28px 11px 28px",
      display: "flex",
      justifyContent: "flex-end",
      "@media (max-width:1366px)":{
        width: '92%'
      }
    },
    totalh6Style:{
      fontWeight:'bold',
      textTransform:'inherit'
    },
    thead:{
      borderLeft: "15px solid #F5F5FC",
      borderRight: "15px solid #F5F5FC",
      borderBottom: "15px solid #F5F5FC",
      "& th":{
        padding: "16px 0 0 16px",
        whiteSpace: "nowrap",
        fontFamily: 'Muli-Bold !important'
      }
    }

  }));

  const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F5F5FC",
        color: "#6c6987",
    },
  
  }))(TableCell);

const RevenueList = (props) => {   
    const classes=useStyles();
    const spDetailArray=props.data;
    const handleEditOpen=(slug,whichUser)=>{
      if(whichUser=="sp"){
        const { from } = { from: { pathname: "/app/service_providers/service_provider_detail/" + slug } };
        props.history.push(from);
      }
      else if(whichUser=="user"){
        const { from } = { from: { pathname: "/app/users/" + slug } };
        props.history.push(from);
      }
       
    }

    // React.useEffect(()=>{
    // console.log(props);
    // },[])

    return (
        spDetailArray && spDetailArray.length>0 &&
        <Card style={{marginTop: 0}}>
             <TableContainer className={classes.tcontainer}>
                        <Table style={{marginBottom:'16px'}} aria-label="simple table">
                            <TableHead className={classes.thead}>
                                <TableRow>
                                    <StyledTableCell style={{color:'#6B6987',fontSize:'16px'}}>S.P Name</StyledTableCell>
                                    <StyledTableCell style={{color:'#6B6987',fontSize:'16px'}}>
                                      Client Name
                                    </StyledTableCell>
                                    <StyledTableCell style={{color:'#6B6987',fontSize:'16px'}}>
                                      Location
                                    </StyledTableCell>
                                    <StyledTableCell style={{color:'#6B6987',fontSize:'16px'}}>
                                      Services Booked
                                    </StyledTableCell>
                                    <StyledTableCell style={{color:'#6B6987',fontSize:'16px'}}>
                                      Booking Date
                                    </StyledTableCell>
                                    <StyledTableCell style={{color:'#6B6987',fontSize:'16px'}}>
                                      Revenue (C)
                                    </StyledTableCell>
                               </TableRow>
                            </TableHead>
                            <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
                                { spDetailArray.map((prop,index)=>{
                                    return  <TableRow key={index}>
                                    <TableCell className={classes.tcell1}>
                                      
                                      <div onClick={()=>{handleEditOpen(prop.spId,"sp")}} style={{cursor:'pointer',fontSize:'14px',color:'#25233C',fontFamily:'Muli'}} className={index%2==0 ? classes.divPadColor:classes.padding16}>
                                      {prop.spName}
                                      </div>
                                                                  
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div onClick={()=>{handleEditOpen(prop.clientId,"user")}} style={{cursor:'pointer',fontSize:'14px',color:'#25233C',fontFamily:'Muli'}} className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                      {prop.clientNAme}
                                      </div>
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} style={{cursor:'pointer',fontSize:'14px',color:'#25233C',fontFamily:'Muli'}}>
                                      {prop.location}
                                      </div>
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} style={{cursor:'pointer',fontSize:'14px',color:'#25233C',fontFamily:'Muli'}}>
                                      {prop.servicesBooked}
                                      </div>
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} style={{cursor:'pointer',fontSize:'14px',color:'#25233C',fontFamily:'Muli'}}>
                                      {prop.bookingDate}
                                      </div>
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} style={{cursor:'pointer',fontSize:'14px',color:'#25233C',fontFamily:'Muli'}} >
                                      {prop.revenue}
                                      </div>
                                    </TableCell>
                                   
                                </TableRow>
                                })
                                }
                                {
                                  spDetailArray.length>0 &&
                                      <TableRow key={"credit-scores"} >
                                      <TableCell className={classes.tcell1}></TableCell>
                                      <TableCell className={classes.tcell2}></TableCell>
                                      <TableCell className={classes.tcell2}></TableCell>
                                      <TableCell className={classes.tcell2}></TableCell>
                                      <TableCell className={classes.tcell2}>    
                                         <h6 style={{padding: "16px", textAlign: "right",color:'#25233C',fontFamily:'Muli-Bold'}}>Total</h6>                                    
                                      </TableCell>
                                      <TableCell className={classes.tcell2}>  
                                         <h6 style={{padding: "16px"}}>{`${props?.totalRevenue?.toFixed(2)}C`}</h6>
                                      </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                        {/* <div className={classes.totaldivStyle}>
                          <h6 className={classes.totalh6Style}>Total &nbsp; &nbsp; {`${props.totalRevenue.toFixed(2)} C`}</h6>
                        </div> */}
                    </TableContainer>
             </Card>

    );
}

export default RevenueList;
