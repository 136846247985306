import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AddSubCategory from '../ProductCategory/AddSubCategory';
import { servicesandpackagesService } from '_services/servicesandpackages.service';

//components imported
import ServiceGrid from './ServiceGrid';
import AddServices from './AddServices';
import EditServices from './EditServices';
//components imported

const useStyles = makeStyles((theme) => ({
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    radiobtn: {
        position: "absolute",
        right: 0,
        top: 0,
    }
}));

const Services = (props) => {
    const classes = useStyles();
    let { path, url } = useRouteMatch();
    const [data, setData] = useState(props.location.state ? props.location.state.row ? props.location.state.row : props.location.state.data : null);
    return (
        <>
            {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'services' && <div>
                {data && <AddSubCategory {...props} editData={data} isService="true" />}
                <ServiceGrid {...props} subCategory={data} />
            </div>}
            <div className="container-fluid">
                <Switch>
                    <Route path={`${path}/addservicesandpackages`}>
                        <AddServices {...props} />
                    </Route>
                    <Route path={`${path}/editservicesandpackages/:slug`}>
                        <EditServices {...props} />
                    </Route>
                </Switch>
            </div>
        </>
    )
}

export default Services
