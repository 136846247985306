/* eslint-disable prettier/prettier */
import { BehaviorSubject } from 'rxjs';
import { handleResponse } from "_helpers/handle-response.js";

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));

export const categoryService = {
    getCategory,
    getBadge,
    postBadge,
    getCategorybyId,
    getSubCategorybyId,
    getSubCategorybyId2,
    postCategory,
    putCategory,
    deleteCategory,
    getSubCategory,
    postSubCategory,
    putSubCategory,
    deleteSubCategory,
    categoryStatusChange,
    getAllSubCategory,
    updateBadge,
    deleteBadge,
    getReviewsOfSp,
    putReviewStatus,
    postSpTiers,
    getAdminPromo,
    postAdminPromo,
    putAdminPromo,
    getAdvertisement,
    getAllSpCreditsAccordingToType,
    getBadgeById,
    getAllZipCodes,
    deleteAdminPromo,
    assign_detachBadgeToSp,
    changeAdminPassword,
    updateTop,
    deleteAdminPromotion,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function getCategory() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Category`, requestOptions).then(handleResponse);
}

function getBadge() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Badge`, requestOptions).then(handleResponse);
}

function postBadge(data){
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);

    var formdata = new FormData();
    formdata.append("title", data.title);
    formdata.append("outline_color", data.outline_color);
    formdata.append("container_color", data.container_color);
    formdata.append("text_color", data.text_color);
    formdata.append("icon", data.icon);
    formdata.append("type",data.type);
    formdata.append("height",data.height);
    formdata.append("width",data.width);
    formdata.append("Content-Type", "application/json");

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Badge`, requestOptions).then(handleResponse);
}


function updateBadge(data) {
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);

    var formdata = new FormData();
    formdata.append("id", data.id)
    formdata.append("title", data.title);
    formdata.append("outline_color", data.outline_color);
    formdata.append("container_color", data.container_color);
    formdata.append("text_color", data.text_color);
    formdata.append("type", data.type);
    formdata.append("width", data.width);
    formdata.append("height", data.height);
    formdata.append("Content-Type", "application/json");
    if (data.icon) {
        formdata.append("icon", data.icon);
    }

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Badge/id`, requestOptions).then(handleResponse);
}

function updateTop(data) {
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);

    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/UpdateTopPros`, requestOptions).then(handleResponse);
}
function assign_detachBadgeToSp(obj) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify(obj)
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/AssignBadgeToSp`, requestOptions).then(handleResponse);
}

function deleteBadge(data) {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify({ "id": data })
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Badge`, requestOptions).then(handleResponse);
}


function getCategorybyId(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Category/${id}`, requestOptions).then(handleResponse);
}

function postCategory(data) {
    // const formData = new FormData();
    // formData.append("name", data.name);
    // formData.append("description", data.description);
    // formData.append("default_credits", data.default_credits);
    // formData.append("image", data.image);
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json', 'Connection': 'keep-alive', 'token': JSON.parse(sessionStorage.getItem('currentUser')).token },
    //     body: formData
    // };
    // return fetch(`${process.env.REACT_APP_URL}/CategoryController/Category`, requestOptions).then(handleResponse);

    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);

    var formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("description", data.description);
    formdata.append("default_credits", data.default_credits);
    formdata.append("image", data.image);
    formdata.append("event", data.event);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Category`, requestOptions).then(handleResponse);
}

function putCategory(data) {

    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);

    var formdata = new FormData();
    formdata.append("id", data.id);
    if(data.name) formdata.append("name", data.name);
    formdata.append("description", data.description);
    formdata.append("default_credits", data.default_credits);
    if (data.image) {
        formdata.append("image", data.image);
    }
    formdata.append("event", data.event);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Category/${data.id}`, requestOptions).then(handleResponse);
}

function deleteCategory(data) {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify({ "id": data })
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Category`, requestOptions).then(handleResponse);
}

function categoryStatusChange(status, data) {

    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({ id: data }),
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_URL}/CategoryController/${status}`, requestOptions).then(handleResponse);
}

function getSubCategory() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/SubCategory`, requestOptions).then(handleResponse);
}
function getAllSubCategory() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/AllSubCategory`, requestOptions).then(handleResponse);
}

function getSubCategorybyId(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/SubCategory/${id}`, requestOptions).then(handleResponse);
}

function getSubCategorybyId2(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'categoryid':id,
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/SubCategory`, requestOptions).then(handleResponse);
}

function postSubCategory(data) {

    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);

    var formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("category_id", data.category_id);
    // formdata.append("slab_first", data.slab_first);
    // formdata.append("slab_second", data.slab_second);
    // formdata.append("slab_third", data.slab_third);
    formdata.append("description", data.description);
    formdata.append("image", data.image);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/SubCategory`, requestOptions).then(handleResponse);
}

function putSubCategory(data) {

    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);

    var formdata = new FormData();
    formdata.append("id", data.id);
    if(data.name) formdata.append("name", data.name);
    formdata.append("category_id", data.category_id);
    // formdata.append("slab_first", data.slab_first);
    // formdata.append("slab_second", data.slab_second);
    // formdata.append("slab_third", data.slab_third);
    formdata.append("description", data.description);
    if (data.image) {
        formdata.append("image", data.image);
    }

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/SubCategory/${data.id}`, requestOptions).then(handleResponse);
}

function deleteSubCategory(data) {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify({ "id": data })
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/SubCategory`, requestOptions).then(handleResponse);
}

function getReviewsOfSp(id) {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token,
            'serviceproviderid': id,
        }
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/ServiceProviderReviews`, requestOptions).then(handleResponse);
}

function putReviewStatus(data) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/UpdateServiceProviderReviews`, requestOptions).then(handleResponse);
}


function postSpTiers(obj) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify(obj)
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/spTiers`, requestOptions).then(handleResponse);
}

function getAdminPromo() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Promocode`, requestOptions).then(handleResponse);
}

function postAdminPromo(data){
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);

    var formdata = new FormData();
    formdata.append("promotion_level", data.promotion_level);
    formdata.append("promocode", data.promocode);
    formdata.append("dicount_percentage", data.dicount_percentage);
    formdata.append("start_date", data.start_date);
    formdata.append("end_date", data.end_date);

    if(data.description.length > 0){
        formdata.append("description", data.description);
    }

    if(data.category_id){
        formdata.append("category_id",data.category_id)
    }

    if(data.sub_category_id){
        formdata.append("sub_category_id",data.sub_category_id)
    }

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Promocode`, requestOptions).then(handleResponse);
}

function putAdminPromo(data) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Promocode`, requestOptions).then(handleResponse);
}

function deleteAdminPromo(data) {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify({ "id": data })
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Promocode`, requestOptions).then(handleResponse);
}


function getAdvertisement() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/getAdvertisment`, requestOptions).then(handleResponse);
}

function getAllSpCreditsAccordingToType(obj){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify(obj)
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/getCreditsHistory`, requestOptions).then(handleResponse);
}

function getBadgeById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'id':id,
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/BadgeById`, requestOptions).then(handleResponse);
}

function getAllZipCodes() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/allZipcode`, requestOptions).then(handleResponse);
}

function changeAdminPassword(obj) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify(obj)
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/changeAdminPassword`, requestOptions).then(handleResponse);
}

function deleteAdminPromotion(obj) {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'token': JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body: JSON.stringify({ "id": obj })
    };
    return fetch(`${process.env.REACT_APP_URL}/CategoryController/Promocode`, requestOptions).then(handleResponse);
}