import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import UserRegisteredTable from './UserRegisteredTable';
import CustomDateSelection from 'components/CustomDateSelection/CustomDateSelection';
import {userService} from '_services/user.service';

const useStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    right: "3pc",
    top: "7pc",
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8d9d",
  },
  perPageSection: {
    position: "absolute",
    right: "3pc",
    top: "7pc",
  },
  displayFlex: {
    display: "flex"
  },
  btnAlign: {
    display: "flex",
    marginLeft: "5px",
  },
  h5: {
    fontSize: 18
  },
  container: {
    // border:"1px solid grey",
    margin: "0 -20px"
  },
  Imginput: {
    display: "none",

  },
  header: {
    display: "flex",
    alignItems: "flex-end"
  },
  Imginput: {
    display: "none"
  },
  boxStyle: {
    marginTop: "30px",
  },
  displayFlexJustifyEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  displayFlexJustifySpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  backButtonSize: {
    '& .MuiIconButton-root': {
      padding: '8px'
    }
  },
  fontSize20: {
    fontSize: '20px'
  },
  fontSize14: {
    fontSize: '14px'
  },
  width165px: {
    width: '165px'
  },
  displayFlexPaddingRight32: {
    display: 'flex',
    paddingRight: '32px'
  },
  tabs: {
    margin: "0px 10px 0px 10px",
    background: "#EAEDF7",
    borderBottom:'1px solid #DDDDE4'
    // "&.MuiTabs-indicator": {
    //   display: "none"
    // }
  },
  tab: {
    "&.MuiButtonBase-root.MuiTab-root": {
      height: "45px",
      padding: "unset",
      minWidth: "72px",
      textTransform: "capitalize",
      fontWeight: "400",
    }
  },
  divPadColor: {
    background: "#F3F3FC",
    padding: '16px'
  },
  tcell1: {
    border: "unset",
    paddingRight: "unset",
    paddingBottom: "unset",
  },
  tcell2: {
    border: "unset",
    paddingLeft: "unset",
    paddingRight: "unset",
    paddingBottom: "unset",
  },
  padding16: {
    padding: '16px'
  },
  padding0: {
    "& .MuiBox-root": {
      padding: '0'
    },
  },
  marginLeft30Px: {
    // marginLeft: '30px',
    '@media(max-width:420px)':
    {
      marginLeft:'0px'
    }
  },
  appbarAdj:
  {
    marginTop:'20px',
    '@media(max-width:420px)':
    {
      marginTop:'50px'
    }
  }
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UserRegistration = (props) => {
  
  const [value, setValue] = React.useState(0);
  const [userRegisteredList,setUserRegisteredList]=React.useState({
    "daily":[],
    "weekly":[],
    "monthly":[],
    "custom":[]
  })
  const [apiCall,setApiCall]=React.useState(false);
  const [totalRegisUser,setTotalRegsUser]=React.useState(false);
  const dalWeekMonth=['daily','weekly','monthly'];

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    React.useEffect(()=>{
      let isMount=false;

      dalWeekMonth.map(async(prop)=>{
        setApiCall(true);
        if(!isMount){
          try{
        const response=await userService.getDetailsOfSpRegistration({type:`${prop}`,user_role:"customer"});
        // console.log(response);
        let arr=[];
        response.map((dataOfUser,index)=>{
  
          let address=dataOfUser.user?.address?.filter((prop)=>{
            return prop.default_address=="true"
          })
          let date = new Date(dataOfUser.created_at.replace(/-/g, "/")); // M-D-YYYY
          let d = date.getDate();
          let m = date.toLocaleString('default', { month: 'long' });
          let y = date.getFullYear();
          let dateString = (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
         let obj={
           userName:dataOfUser?.user?.name ? dataOfUser.user.name :"Not available",
           registrationDate: dateString,
           contactNo:dataOfUser?.user?.mobile_number ? dataOfUser.user.mobile_number:'Not Available',
           location: address && address[0]?.zipcode ? address[0]?.zipcode :'Not Available'
         }
          arr.push(obj);
        })
        setUserRegisteredList(prevState=>
            {
              return {
              ...prevState,
              [prop]:arr
            }
          }
          );
          setApiCall(false);
        }
        catch(err){
          setApiCall(false);
        }
        }
      })
   
      return function cleanup(){
        setUserRegisteredList({
          "daily": [],
          "weekly": [],
          "monthly": [],
          "custom": []
        });
        setApiCall(false);
        isMount=true;
      }
  
    },[])
  
    
    React.useEffect(()=>{
      //console.log(userRegisteredList);
      if(userRegisteredList.daily.length >0){
        setTotalRegsUser(userRegisteredList.daily.length);
      }
    },[userRegisteredList])

  
    function setTotalUserRegister(type){
      if(type=='daily'){
        if(userRegisteredList.daily.length >0){
          setTotalRegsUser(userRegisteredList.daily.length);
        }
        else{
          setTotalRegsUser(0);
        }
      }
      if(type=='weekly'){
        if(userRegisteredList.weekly.length > 0){
          setTotalRegsUser(userRegisteredList.weekly.length);
        }
        else{
          setTotalRegsUser(0);
        }
      }
      if(type=='monthly'){
        if(userRegisteredList.monthly.length >0){
          setTotalRegsUser(userRegisteredList.monthly.length);
        }
        else{
          setTotalRegsUser(0);
        }
      }
      if(type=='custom'){
        if(userRegisteredList.custom.length >0){
          setTotalRegsUser(userRegisteredList.custom.length);
        }
        else{
          setTotalRegsUser(0);
        }
      }
    }

    const callbackFuncForCustom=async(fromDate,toDate)=>{
      setApiCall(true);
      try{
      const response=await userService.getDetailsOfSpRegistration({type:`custom`,user_role:"customer",from_date:fromDate,to_date:toDate});
      let arr=[];
      if(response.length > 0){
      response.map((dataOfUser,index)=>{
      let address=dataOfUser.user?.address?.filter((prop)=>{
          return prop.default_address=="true"
        })

      let date = new Date(dataOfUser.created_at.replace(/-/g, "/")); // M-D-YYYY
      let d = date.getDate();
      let m = date.toLocaleString('default', { month: 'long' });
      let y = date.getFullYear();
      let dateString = (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
       let obj={
         userName:dataOfUser?.user?.name ? dataOfUser.user.name :"not provided",
         registrationDate: dateString,
         contactNo:dataOfUser?.user?.mobile_number ? dataOfUser.user.mobile_number:'Not Available',
         location: address && address[0]?.zipcode ? address[0]?.zipcode :'Not Available'
       }
        arr.push(obj);
      })
    }
      setUserRegisteredList(prevState=>
          {
            return {
            ...prevState,
            ['custom']:arr
          }
        }
        );
        setApiCall(false);
      }
      catch(err){
        setApiCall(false);
      }
    }

    React.useEffect(()=>{
        if(userRegisteredList.custom.length > 0 ){
          setTotalRegsUser(userRegisteredList.custom.length)
        }
        else{
          setTotalRegsUser(0)
        }
    },[userRegisteredList.custom])

  return (
    <>
      <AppBar elevation={0} position="static" color="default" className={`${classes.appbarAdj}`} style={{ flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', backgroundColor: "transparent",overflow:'auto',paddingBottom:'10px' }} >
        <h6 className={`${classes.marginLeft30Px} ${classes.fontSize14}`} style={{ color: '#6B6987',fontSize:'14px',fontFamily:'Muli' }}>Users Registered - {totalRegisUser} results</h6>
        <div className={classes.displayFlexPaddingRight32}>
          <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label="simple tabs example" TabIndicatorProps={{ style: { backgroundColor: "#6259ca" } }} textColor="primary">
            <Tab className={classes.tab} label="Daily" {...a11yProps(0)} onClick={()=>{setTotalUserRegister('daily')}} />
            <Tab className={classes.tab} label="Weekly" {...a11yProps(1)} onClick={()=>{setTotalUserRegister('weekly')}} />
            <Tab className={classes.tab} label="Monthly" {...a11yProps(2)} onClick={()=>{setTotalUserRegister('monthly')}} />
            <Tab className={classes.tab} label="custom dates" {...a11yProps(4)}  onClick={()=>{setTotalUserRegister('custom')}} />
          </Tabs>
        </div>

      </AppBar>

      {/* whenever use this class removePaddingTopFromCard padding top and bottom from card is removed and margin top is reduced to 8px-> material-dashboard-react.css */}
      
      <TabPanel  className={'removePaddingTopFromCard'} value={value} index={0}>
        <GridContainer >
          {  userRegisteredList.daily.length > 0 &&
            <UserRegisteredTable {...props} data={userRegisteredList.daily} />
          }
          {
         userRegisteredList.daily.length == 0 && apiCall==false &&
         <Card style={{padding:20}}>
                  <h6 style={{textAlign:'center',color: '#6B6987'}}>No record Found</h6> 
         </Card>
       }
       { apiCall==true &&
         <div style={{display:"flex",justifyContent:'center',marginTop:20,width:'100%'}}>
         <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
         </div>
       }
        </GridContainer>
      </TabPanel>

      <TabPanel  className={'removePaddingTopFromCard'} value={value} index={1}>
        <GridContainer className={'removeMarginTopFromCard'} >
        {  userRegisteredList.weekly.length >0 &&
            <UserRegisteredTable {...props} data={userRegisteredList.weekly} />
          }
          {
         userRegisteredList.weekly.length == 0 && 
         <Card style={{padding:20}}>
                  <h6 style={{textAlign:'center',color: '#6B6987'}}>No record Found</h6> 
         </Card>
       }
        </GridContainer>
      </TabPanel>

      <TabPanel  className={'removePaddingTopFromCard'} value={value} index={2}>
        <GridContainer >
        {  userRegisteredList.monthly.length >0 &&
            <UserRegisteredTable {...props} data={userRegisteredList.monthly} />
          }
          {
         userRegisteredList.monthly.length == 0 && 
         <Card style={{padding:20}}>
                  <h6 style={{textAlign:'center',color: '#6B6987'}}>No record Found</h6> 
         </Card>
       }
        </GridContainer>
      </TabPanel>

      <TabPanel  className={'removePaddingTopFromCard'} value={value} index={3}>
        <GridContainer >
        <CustomDateSelection {...props} callbackFuncForCustom={callbackFuncForCustom} />
        {
          userRegisteredList.custom.length > 0 && apiCall==false &&
          <UserRegisteredTable {...props} data={userRegisteredList.custom} />
        }
        { apiCall==true &&
         <div style={{display:"flex",justifyContent:'center',marginTop:20,width:'100%'}}>
         <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
         </div>
       }
        {
         userRegisteredList.custom.length == 0 && apiCall==false &&
         <Card style={{padding:20}}>
                  <h6 style={{textAlign:'center',color: '#6B6987'}}>No record Found</h6> 
         </Card>
       }
        </GridContainer>
      </TabPanel>
    </>
  );
}

export default UserRegistration