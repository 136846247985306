import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import { Formik, Field, Form, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from '@material-ui/core/Button';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { configurationService } from '_services/configuration.service';
import SweetAlert from 'react-bootstrap-sweetalert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, TextField, Grid, Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { categoryService } from "_services/category.service";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    displayFlex: {
        display: "flex"
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#A8A8A8',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#A8A8A8',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    Imginput: {
        display: "none"
    },
    boxStyle: {
        marginTop: "30px",
    },
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid red'
        }
    },
    displayFlexJustifyEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    fontSize20: {
        fontSize: '20px',
        marginBottom:'14px'
    },
    SubCategory:
    {
        backgroundColor: '#eaeaea'
    }

}));

function formatDate(dateParameter) {
    let date = new Date(dateParameter); // M-D-YYYY
    let d = date.getDate();
    let m = date.toLocaleString('default', { month: 'long' });
    let y = date.getFullYear();
    let dateFormat = (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
    return dateFormat;
}

const AddPro = (props) => {
    const classes = useStyles();
    const [proData, setProData] = React.useState({
        location: '',
        toDate: '',
        fromDate: '',
        subcategory: '',
        id: ''
    })
    const [errorTop, setErrorTop] = React.useState({
        location: '',
        toDate: '',
        fromDate: '',
        subcategory: ''
    });
    const [alert, setAlert] = React.useState(null);
    function getToPrevious() {
        const { from } = { from: { pathname: "/app/product_services/topPro" } };
        props.history.goBack();
    }

    React.useEffect(() => {
        if (window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editPro') {
            setProData(props.location.state);
        }
        else {

        }
    }, []);

    const handleProData = (event) => {

        if (event.target.value.length > 0) {
            setErrorTop({ ...errorTop, [event.target.name]: false })
            setProData({ ...proData, [event.target.name]: event.target.value });
        }
        else {
            setErrorTop({ ...errorTop, [event.target.name]: true })
            setProData({ ...proData, [event.target.name]: event.target.value });
        }
    }



    const handleDatePickerChange = (event, prop) => {
        const date = formatDate(event)
        setProData({ ...proData, [prop]: date })
        setErrorTop({ toDate: false })
    }

    // const handleSubcategoryChange = (event) => {
    //     setProData({...proData, subcategory: event.target.value })
    // }

    // for success messages
    function SweetAlertConfirm(status) {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                    setAlert(null)
                    const { from } = { from: { pathname: "/app/product_services/topPro" } };
                    props.history.push(from);
                }}
            >
                Data Updated Successfully
            </SweetAlert>
        )
        if (status == 'true') {
            setAlert(getAlert())
        }
        // else {
        //     setAlert(getFalseAlert())
        // }
    }

    const updateTopData = () => {
        if (proData.location.length != undefined && proData.location.length == 0) {
            setErrorTop({ location: true })
        }
        // else if (proData.toDate.length != undefined && proData.toDate.length == 0) {
        //     setErrorBadge({ toDate: true })
        // }
        else if (proData.toDate.length == undefined || proData.toDate.length == null) {
            setErrorTop({ toDate: true })
        }

        else {
            const obj = {};
            const startDate = new Date(proData.fromDate);
            const endDate = new Date(proData.toDate);
            obj.id = proData.id,
                obj.location = proData.location,
                obj.start_date = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0],
                obj.end_date = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0]
            categoryService?.updateTop(obj).then((res) => {
                SweetAlertConfirm('true');
            })
        }
    }

    return (
        <>
            <div className={classes.container}>
                <GridContainer style={{ width: "100%" }}>
                    <GridItem xs={12} sm={12} md={8}>
                        <div>
                            <div className={classes.header}>
                                <IconButton aria-label="edit" onClick={getToPrevious} style={{paddingLeft:'0px',marginLeft:'-4px'}}>
                                    <ArrowBackIcon />
                                </IconButton>
                                {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editPro' && <h4 className={classes.fontSize20} style={{fontFamily: "Muli-Bold", transform: 'translateY(-5px)'}}>Edit Pro</h4>}
                                {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'addPro' && <h4 className={classes.fontSize20} style={{fontFamily: "Muli-Bold", transform: 'translateY(-5px)'}}>TopPro</h4>}
                            </div>

                        </div>
                    </GridItem>
                </GridContainer>

                <form style={{ padding: '0px 20px', maxWidth: 1200 }}>
                    <Card style={{ paddingBottom: "20px" }}>
                        <CardBody className="px-0 pb-0">
                            <div style={{ padding: '0px 30px' }}>


                                <GridContainer style={{ marginBottom: '20px' }}>
                                    <GridItem xs={12} sm={12} md={8} className={classes.boxStyle}>
                                        <Box>
                                            <InputLabel htmlFor="title" style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>Sub Category</InputLabel>
                                            <TextField
                                                className={classes.SubCategory}
                                                variant="outlined"
                                                // placeholder="SubCategory"
                                                fullWidth={true}
                                                size="small"
                                                name="subcategory"
                                                value={proData.subcategory}
                                                disabled={true}
                                                inputProps={{ style: { backgroundColor: "#eaeaea" } }}
                                            />
                                            {/* {
                                                errorTop.subcategory == true &&
                                                <p style={{ color: 'red', marginBottom: 0 }}>
                                                    * This Field is required
                                                </p>
                                            } */}

                                        </Box>
                                    </GridItem>

                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={8} className={classes.boxStyle}>
                                        <Box>
                                            <InputLabel htmlFor="title" style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>Location</InputLabel>
                                            <TextField
                                                className={errorTop.location == true ? classes.root : ''}
                                                variant="outlined"
                                                placeholder="Location"
                                                fullWidth={true}
                                                size="small"
                                                name="location"
                                                value={proData.location}
                                                onChange={handleProData}
                                                onBlur={handleProData}
                                            />
                                            {
                                                errorTop.location == true &&
                                                <p style={{ color: 'red', marginBottom: 0 }}>
                                                    * This Field is required
                                                </p>
                                            }

                                        </Box>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={8} md={4} style={{ marginTop: "20px" }}>
                                        <div className="form-group">
                                            <InputLabel htmlFor="fromDate" style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>From</InputLabel>
                                            {
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        style={{ minWidth: '100%' }}
                                                        // InputProps={{readOnly: true}}
                                                        autoOk
                                                        // maxDate={customDates.toDate!==null ? customDates.toDate:new Date(Date.now())}
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        format="yyyy/MM/dd"
                                                        name="fromDate"
                                                        value={proData.fromDate}
                                                        // InputAdornmentProps={{ position: "start" }}
                                                        onChange={(event) => { handleDatePickerChange(event, 'fromDate') }}
                                                    />
                                                </MuiPickersUtilsProvider>

                                            }
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={8} md={4} style={{ marginTop: "20px" }}>
                                        <div className="form-group">
                                            <InputLabel htmlFor="toDate" style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>To</InputLabel>
                                            {

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        style={{ minWidth: '100%' }}
                                                        // InputProps={{readOnly: true}}
                                                        // minDate={customDates.fromDate!==null ? customDates.fromDate : null}
                                                        // maxDate={new Date(Date.now())}
                                                        autoOk
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        format="yyyy/MM/dd"
                                                        name="toDate"
                                                        value={proData.toDate === 'Till Now' ? null : proData.toDate}
                                                        // InputAdornmentProps={{ position: "start" }}
                                                        onChange={(event) => { handleDatePickerChange(event, 'toDate') }}
                                                    />
                                                </MuiPickersUtilsProvider>

                                            }
                                            {
                                                errorTop.toDate == true &&
                                                <p style={{ color: 'red', marginBottom: 0 }}>
                                                    * This Field is required
                                                </p>
                                            }
                                        </div>
                                    </GridItem>
                                </GridContainer>
                                {
                                    window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editPro' &&
                                    <>
                                        <GridItem xs={12} sm={12} md={8} >
                                            <Box className={classes.boxStyle}>
                                                <Button className={classes.showBtn} variant="contained" color="primary"
                                                onClick={updateTopData}
                                                >
                                                    update
                                                </Button>
                                            </Box>
                                        </GridItem>
                                    </>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </form>
            </div>
            <> {alert} </>
        </>)
}

export default AddPro;
