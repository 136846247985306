import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Grid } from '@material-ui/core';
import { userService } from '_services/user.service';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import SweetAlert from 'react-bootstrap-sweetalert';

const useStyles = makeStyles((theme) => ({
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    labelText: {
        color: "#25233C",
        fontSize: 18,
        marginBottom: 10,
        fontFamily:'Muli-SemiBold'
    },
    root: {
        "& .MuiInputBase-root": {
            padding: 0,
            "& .MuiButtonBase-root": {
                padding: 0,
                paddingLeft: 10
            },
            "& .MuiInputBase-input": {
                paddingTop: 9.5,
                paddingBottom: 9.5
            }
        }
    }

}));

const ContactInfo = (props) => {
    const classes = useStyles();

    const [data, setData] = useState(props.data.user);
    const [mobileNumber, setMobileNumber] = useState(null);
    const [address, setAddress] = useState(null);
    const [date, setDate] = useState(null);
    const [email, setEmail] = useState(null);
    const [render, setRender] = useState(0);
    const [alert, setAlert] = React.useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await userService.getUser(props.data.user._id);
                setData(response);
                if (Array.isArray(response.address)) {
                    response.address.map((prop) => {
                        if (prop.default_address == "true") {
                            setAddress(prop.zipcode)
                        }
                    })
                }
                else {
                    setAddress(response.address ? response.address : '')
                }
                localStorage.setItem("userEmail", data.email);
                setMobileNumber(data.mobile_number ? data.mobile_number : '');
                // setAddress(data.address?data.address:'');
                setEmail(data.email ? data.email : '')
                setDate(data.created_at ? data.created_at : '');
            }
            catch (err) { }

        }

        fetchData();


    }, [render]);

    function SweetAlertConfirm() {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => setAlert(null)}
            >
                Account status updated successfully
            </SweetAlert>
        )
        setAlert(getAlert());
    }

    const onChangeEmail = (e) => {
        if (e.target.value.length > 0) {
            setEmail(e.target.value)
        }
        else {
            setEmail(data.email)
        }
    }
    const onChangeAddress = (e) => {
        if (e.target.value.length > 0) {
            setAddress(e.target.value)
        }
        else {
            setAddress(data.address)
        }
    }
    const onChangeDate = (e) => {

        if (e) {
            setDate(e);
        }
        else {
            setDate(date);
        }
    }
    const onChangeMobileNumber = (e) => {
        if (e.target.value.length > 0) {
            setMobileNumber(e.target.value)
        }
        else {
            setMobileNumber(data.mobile_number)
        }
    }
    const saveChangedStatus = () => {
        const obj = {
            id: props.data.user._id,
            mobile_number: mobileNumber,
            address: address,
            created_at: date
        };

        if (localStorage.getItem("userEmail") != email) {
            obj.email = email
        }

        userService.putUser(obj)
            .then(res => {
                console.log("success");
                SweetAlertConfirm();
                setRender(!render);
            })
    }

    return (
        <>
            {

                <>
                    <div className={classes.container}>
                        <GridContainer style={{ width: "100%", margin: "unset" }}>
                            <Formik>
                                <Form style={{ width: "100%" }}>
                                    <Card style={{ marginTop: "unset" }}>
                                        <CardBody className="px-0 pb-0" style={{ height: "378px" }}>
                                            <GridContainer spacing={0}>
                                                <GridItem xs={12} sm={8} md={3}>
                                                    <div className="form-group">
                                                        <h5 className={classes.labelText}>Mobile No.</h5>
                                                        <Field name="mobile"
                                                            id="mobile"
                                                            type="number"
                                                            readOnly
                                                            onChange={(e) => { }}
                                                            placeholder={mobileNumber ? mobileNumber : "Enter your mobile number"}
                                                            className={'form-control'}
                                                            style={{color:'#25233C',fontSize:'16px',fontFamily:'Muli'}}/>
                                                        <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                                                    </div>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={3}>
                                                    <div className="form-group">
                                                        <h5 className={classes.labelText}>Email</h5>
                                                        <Field name="email"
                                                            id="email"
                                                            placeholder={email ? email : "Enter your email"}
                                                            type="text"
                                                            onChange={(e) => { }}
                                                            readOnly
                                                            className={'form-control'} 
                                                            style={{color:'#25233C',fontSize:'16px',fontFamily:'Muli'}}/>
                                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                    </div>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={3}>
                                                    <div className="form-group">
                                                        <h5 className={classes.labelText}>Joined On</h5>
                                                        {

                                                            <Field name="address"
                                                                id="address"
                                                                placeholder={date ? new Date(date.replace(/-/g, "/")).toISOString().split('T')[0] : "Enter your address"}
                                                                type="text"
                                                                readOnly
                                                                onChange={(e) => { }}
                                                                className={'form-control'} 
                                                                style={{color:'#25233C',fontSize:'16px',fontFamily:'Muli'}}/>

                                                        // <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        // <KeyboardDatePicker className={classes.root}
                                                        //     autoOk
                                                        //     variant="inline"
                                                        //     inputVariant="outlined"                                                       
                                                        //     format="MM/dd/yyyy"
                                                        //     value={date}
                                                        //     InputAdornmentProps={{ position: "start" }}
                                                        //     onChange={date => onChangeDate(date)}
                                                        //      />
                                                        //      </MuiPickersUtilsProvider>

                                                        /* <Field name="joined_on"
                                                            id="joined_on"
                                                            type="text"
                                                            placeholder={new Date(date).toLocaleDateString() ? new Date(date).toLocaleDateString(): "Enter date joined on"}
                                                            onChange={(e)=>onChangeDate(e)}
                                                            className={'form-control'} /> */}
                                                        <ErrorMessage name="joined_on" component="div" className="invalid-feedback" />
                                                    </div>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={6}>
                                                    <div className="form-group">
                                                        <h5 className={classes.labelText}>Address</h5>
                                                        <Field name="address"
                                                            id="address"
                                                            placeholder={address ? address : "Enter your address"}
                                                            type="text"
                                                            onChange={(e) => { }}
                                                            readOnly
                                                            className={'form-control'}
                                                            style={{color:'#25233C',fontSize:'16px',fontFamily:'Muli'}}
                                                        />
                                                        <ErrorMessage name="address" component="div" className="invalid-feedback" />
                                                    </div>
                                                </GridItem>
                                                {/* <GridItem xs={12} sm={8} md={8}>
                                                <Button className={classes.SaveButton} onClick={() => saveChangedStatus()} color="primary">Save</Button>
                                                </GridItem> */}
                                            </GridContainer>

                                        </CardBody>
                                    </Card>
                                </Form>
                            </Formik>
                        </GridContainer>
                    </div>
                </>
            }
            {alert}
        </>
    )
}

export default ContactInfo;
