import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,
    }
}));

// Backdrop for saving form 

const ApplyBackDrop = ({
    apiCall
}) => {
    const classes = useStyles();

    return <Backdrop
        className={classes.backdrop}
        open={apiCall}
        style={{zIndex:1000}}
    >
        <CircularProgress color="inherit" />
    </Backdrop>

}

export default ApplyBackDrop;