import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import AppBar from '@material-ui/core/AppBar';
import { Icon } from '@material-ui/core';
import ReactTable from "_components/ReactTable.js";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { SPService } from "_services/serviceprovider.service";
import { userService } from "_services/user.service";
import { categoryService } from "_services/category.service";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
import NewAdv from './NewAdv';


const useStyles = makeStyles((theme) => ({
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    button2: {
        background: "transparent",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8cac"
    },
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    fontSize20:{
        fontSize:'20px'
      }
}));

const AdManager = (props) => {
    const classes = useStyles();
    let { path, url } = useRouteMatch();
    const [advFullData, setAdvFullData] = React.useState([]);
    const [advDisplayed, setAdvDisplayed] = React.useState([]);
    const [alert, setAlert] = React.useState(null);
    const [alertDelete, setAlertDelete] = React.useState(null);
    const [apiCall, setApiCall] = React.useState(false);

    function SweetAlertConfirmDelete() {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                    setAlertDelete(null);
                }}
            >
                Data deleted successfully
            </SweetAlert>
        )
        setAlertDelete(getAlert());
    }

    function SweetAlertConfirm(id) {
        const getAlert = () => (id &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => {
                    setAlert(null);
                    deleteFeatured(id);
                }}
                onCancel={() => setAlert(null)}
                focusCancelBtn
            >
                You want to delete this record?
            </SweetAlert>
        )
        setAlert(getAlert());
    }


    const deleteFeatured = (idToDelete) => {

        const obj = {
            id: idToDelete
        }
        SPService.deleteFeaturedSp(obj).then((res) => {
            SweetAlertConfirmDelete();
        })
    }


    React.useEffect(() => {
        if (advFullData.length > 0) {
            let array = [];
            advFullData.map((prop) => {
                array.push({
                    screenName: prop.screenName,
                    location: prop.location,
                    interface: prop.interface,
                    updatedOn: prop.updatedOn,
                    advertisementType: prop.advertisementType
                });
                setAdvDisplayed([...array]);
            })
        }
    }, [advFullData])


    React.useEffect(async () => {
        setApiCall(true);
        // console.log(res[`${prop}`][0],res[`${prop}`][res[`${prop}`].length-1])

        const resForAdv = await categoryService.getAdvertisement();

        if (resForAdv) {
            let array2 = [];
            resForAdv.map((prop, index) => {
              
                let date = new Date(prop.updated_at); // M-D-YYYY
                let d = date.getDate();
                let m = date.toLocaleString('default', { month: 'long' });
                let y = date.getFullYear();
                let dateString = (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
              
                array2.push({
                    screenName: prop.screen_name,
                    location: prop.location,
                    interface: prop.interface=="serviceprovider" ? "Service Provider" : "User",
                    updatedOn: dateString,
                    advertisementType: prop.choose_from == "upload_image" ? 'image' : 'Adv. Code',
                });
            })
            setAdvFullData([...array2]);
        }
        setApiCall(false);

    }, [props.history.location.pathname, alertDelete])



    function getToPrevious() {
        // console.log('hello')
        const { from } = { from: { pathname: "/app/product_services" } };
        props.history.goBack();
    }
    return (
        <>
            {
                window.location.href.split('/')[window.location.href.split('/').length - 1] == 'adManager' && <div>
                    <AppBar elevation={0} position="static" color="transparent" className={classes.appbarAdj} >
                        <div className={classes.header}>
                            <IconButton aria-label="edit" onClick={getToPrevious}>
                                <ArrowBackIcon />
                            </IconButton>
                            <h4 className={classes.fontSize20} >Adv. Manager</h4>
                        </div>
                        <Link to="/app/product_services/adManager/newAdv">
                            <Button

                                variant="contained"
                                className={classes.button}
                                startIcon={<Icon color="primary">add</Icon>}
                            >
                                Add New Advertisement
                            </Button>
                        </Link>
                    </AppBar>
                    <GridContainer container>
                        <GridItem xs={12}>
                            <Card>
                                <CardHeader color="primary" style={{height:'20px'}} icon>
                                    {/* <CardIcon color="primary">
                    <Assignment />
                  </CardIcon> */}
                                    {/* <h4 className={classes.cardIconTitle}>React Table</h4> */}
                                </CardHeader>
                                <CardBody className="p-0">
                                    {advFullData.length == 0 && apiCall == true &&
                                        <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    }

                                    {advFullData.length == 0 && apiCall == false &&

                                        <h6 style={{ textAlign: 'center', marginBottom: 20 }}>
                                            No Sp is featured
                                        </h6>

                                    }


                                    {advFullData.length > 0 && apiCall == false &&

                                        <ReactTable
                                            className={'user-table-adj'}
                                            columns={[
                                                {
                                                    Header: "Screen Name",
                                                    accessor: "screenName",
                                                },
                                                {
                                                    Header: "Location",
                                                    accessor: "location",
                                                },
                                                {
                                                    Header: "Interface",
                                                    accessor: 'interface'
                                                },
                                                {
                                                    Header: "Advertisement Type",
                                                    accessor: 'advertisementType'
                                                },
                                                {
                                                    Header: "Updated On",
                                                    accessor: 'updatedOn'
                                                },
                                            ]}


                                            data={advDisplayed.length > 0 ? advDisplayed : []}
                                            onRowClick={(rowData, clickType) => console.log("")}
                                        // onRowClick={(rowData, clickType) => handleRowClick(rowData, clickType)}
                                        />
                                    }
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            }

            <div className="container-fluid">
                <Switch>
                    <Route path={`${path}/newAdv`}>
                        <NewAdv {...props} />
                    </Route>
                </Switch>
            </div>
            
            <>
                {alert}
            </>
            
            <>
                {alertDelete}
            </>
        </>
    );
}

export default AdManager;
