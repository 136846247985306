import { Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GridContainer from 'components/Grid/GridContainer';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import spdataContext from 'context/spdataContext';

import {
    useParams,
    useRouteMatch
} from "react-router-dom";
import * as Yup from 'yup';
import { userService } from "_services/user.service";

const useStyles = makeStyles((theme) => ({
    bold:{
        fontFamily: "Muli-Bold !important",
        color: "#6B6987", 
    },
    cover: {
        border: "1px solid #8E8CAC",
        height: "100%",
        maxWidth: 74,
        minHeight: 74,
        width: "100%",

    },
    cardTitle: {
        margin: "unset",
        padding: "0 20px",
        fontSize: 20,
        color: "#25233C",
        fontWeight: "400"
    },
    cardAction: {
        color: "#000",
    },
    button: {
        margin: theme.spacing(1),
        background: "transparent",
        boxShadow: "unset",
        border: "1px solid #8f8cac",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "transparent",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
    }
    ,
    table: {
        margin: "10px 0",
        background: '#f5f5fc'
    },
    tcontainer: {
        //   margin:"0 -5px",
    },
    thead: {
        padding: 10
    },
    gridCont: {
        paddingTop: "10px",
        margin: 0,
    },
    gridItem1: {
        display: "flex",
        alignItems: "center",
    },
    gridItem2: {
        textAlign: "right",
    },
    tcell1: {
        border: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
        paddingLeft:'0px'
    },
    tcell2: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell2sub: {
        padding: "unset",
        margin: "auto",
        border: "unset",
    },
    tcell3: {
        border: "unset",
        paddingLeft: "unset",
        paddingBottom: "unset",
    },
    div1: {
        display: "flex",
        alignItems: "center",
        background: "#F3F3FC",
        padding: 10,
        minHeight: 50,
    },
    div2: {
        background: "#F3F3FC",
        padding: 15,
        color: "#6d6b75",
        minHeight: 50,
        display: "flex",
        justifyContent: "space-between",
    },
    div3: {
        background: "#F3F3FC",
        padding: 1,
        minHeight: 50,
    },
    blockTitle: {
        paddingLeft: 10,
        color: "#25233C"
    },
    mainCard: {
        marginBottom: '2em',
        // marginLeft: -25,
        // width: "calc(100% + 75px)"
        marginInline: "auto",
        width: "100%",
        marginTop: 0
    },
    subcellWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    subCell: {
        padding: 0,
        border: "unset",
        margin: "auto",
    },
    textDecNone: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none"
        },
    },
    imgItem: { border: "1px solid grey", height: 38, width: 38, background: "#fff", alignItems: "center", display: "flex", justifyContent: "center" },
    h5: {
        color: "#25233C",
        fontSize: 18,
        fontFamily: "Muli-SemiBold"
    },
    marginBottom30: {
        marginBottom: '30px'
    },
    displayFlexJustifySBalignCenter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    dialogTitle:{
        fontSize: 16,
        fontFamily: "Muli-Bold !important"
    },
    // thead:
    // {
    //     borderLeft: "15px solid #F5F5FC",
    //     borderRight: "15px solid #F5F5FC",
    //     borderBottom: "15px solid #F5F5FC",
    //     "& th":{
    //         padding: "16px 0 0 16px",
    //         whiteSpace: "nowrap",
    //         fontFamily: 'Muli-Bold !important'
    //       }
    // }

}));


const StyledTableCell = withStyles((theme) => ({
    head: {
        //   backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
        backgroundColor: "#F5F5FC",
        color: "#6c6987",
        whiteSpace: "nowrap"
    },

}))(TableCell);
const PaymentHistory = (props) => {

    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    let { path, url } = useRouteMatch();
    const [promoData, setPromoData] = React.useState([]);
    const classes = useStyles();
    const { slug } = useParams();
    const [open, setOpen] = React.useState(false);
    const [selectedStatus, setSelectedStatus] = React.useState('');
    const [refresh, setRefresh] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState("");
    const [confirmDialog, setConfirmDialog] = React.useState("");
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [creditAmount, setCreditAmount] = React.useState("");
    const [userName, SetUserName] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [creditActual, setCreditActual] = React.useState(0);
    const [alertError, setAlertError] = React.useState(null);

    const dataContext = React.useContext(spdataContext);

    function SweetAlertConfirm(msg) {
        const getAlert = () => (
            <SweetAlert
                error
                title="Error"
                onConfirm={() => setAlertError(null)}
            >
                {msg}
            </SweetAlert>
        )
        setAlertError(getAlert());
    }

    function handleSelectChange(event) {
        setSelectedStatus(event.target.value || '');
    }
    const handleClickOpen = (ref) => {
        setOpenDialog(ref);
        setOpen(true);
    };
    const handleClose = (ref) => {
        setOpen(false);
    }

    const handleClickOpenConfirm = (ref, credit) => {
        setCreditAmount(credit);
        setOpen(false);
        setConfirmDialog(ref);
        setOpenConfirm(true);
    };
    const handleEditConfirm = () => {
        setOpenConfirm(false);
        setOpen(true);

    }

    const handleSubmitRequest = (ref, credits) => {
        const obj = {
            sp_id: slug,
            credits: credits.toString(),
            status:"succeeded"
        }
        if (ref == "Add") {
            userService.postSPCredit(obj)
                .then(
                    user => {
                        dataContext.update(slug);
                        setOpen(false);
                        setOpenConfirm(false);
                        setRefresh(true);

                    },
                    error => {
                        setSubmitting(false);
                        setStatus(error);
                    }
                );
        }
        else if (ref == "Deduct") {
            if (creditActual < credits) {
                SweetAlertConfirm('Not enough credit');
                setOpen(false);
                setOpenConfirm(false);
                setRefresh(true);
            }
            else {
                userService.deductSPCredit(obj)
                    .then(
                        user => {
                            dataContext.update(slug);
                            setOpen(false);
                            setOpenConfirm(false);
                            setRefresh(true);

                        },
                        error => {
                            setSubmitting(false);
                            setStatus(error);
                        }
                    );
            }
        }
    }

    React.useEffect(() => {
        const fetchPromotions = async () => {
            setIsLoading(true);
            try {
                const response = await userService.getSP(slug);
                if (response) {
                    let sortedTransactionList = response.creditransaction;
                    sortedTransactionList = sortedTransactionList.sort((a, b) => (a.created_at > b.created_at) ? -1 : 1);
                    setCreditActual(response.credit);
                    SetUserName(response.user.name);
                    setPromoData([...sortedTransactionList]);
                    setRefresh(false);
                    setIsLoading(false);
                }
            }
            catch (err) {
                setIsLoading(false);
            }
        };
        fetchPromotions();
    }, [refresh === true]);


    const getToPrevious = () => {
        props.history.goBack();
    }

    return (
        <>

            <Dialog open={openConfirm} onClose={(e) => handleClose(e)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{ background: "#F5F5FC", color: "#6B6987"}} className={classes.dialogTitle} >{openDialog} Credits</DialogTitle>
                <DialogContent style={{ maxWidth: 450, paddingBottom: 20, paddingTop: 20 }}>
                    {openDialog == "Add" &&
                        <div className={classes.marginBottom30}>
                            Adding <strong>{creditAmount}</strong> credits to <strong>"{userName}"</strong>  Account
                     </div>
                    }
                    {openDialog == "Deduct" &&
                        <div className={classes.marginBottom30}>
                            Deducting <strong>{creditAmount}</strong> credits from <strong>"{userName}"</strong>  Account
                     </div>
                    }
                    <Button type="submit" className={classes.loginButton} simple block
                        onClick={() => handleSubmitRequest(openDialog, creditAmount)}
                        style={{ background: "#6259CA", color: "#fff", padding: "15px 27px" }}>
                        confirm
                                </Button>
                    <Button type="button" onClick={() => handleEditConfirm()} simple block variant="outlined"
                        style={{ padding: 15, marginLeft: 16 }}> Edit </Button>
                    {/* {isSubmitting &&
                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                } */}
                    <br />
                </DialogContent>
            </Dialog>

            <Dialog open={open} onClose={(e) => handleClose(e)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{ background: "#F5F5FC", color: "#6B6987" }} >{openDialog} Credits</DialogTitle>
                <DialogContent style={{ maxWidth: 450, paddingBottom: 20, paddingTop: 20 }}>
                    <Formik
                        initialValues={{
                            sp_id: "",
                            credits: ''
                        }}
                        validationSchema={Yup.object().shape({
                            credits: Yup.number().required(`${openDialog}` == 'Add' ? 'Credit Amount is required' : 'Debit Amount is required')
                        })}
                        onSubmit={({ credits }, { setStatus, setSubmitting }) => {

                            setStatus();
                            setRefresh(false);
                            if (openDialog == 'Add') {
                                handleClickOpenConfirm('Add', credits)

                            }
                            else if (openDialog == 'Deduct') {
                                handleClickOpenConfirm('Deduct', credits)

                            }

                        }
                        }>
                        {({ errors, status, touched, isSubmitting }) => (
                            <Form style={{ width: 270 }}>
                                <div className="form-group mb-4">
                                    <h5 className={classes.h5}>{`${openDialog}` == 'Add' ? 'Credit Amount' : 'Debit Amount'}</h5>
                                    <Field name="credits"
                                        id="credits"
                                        min={1}
                                        type="number"
                                        // type="text"
                                        placeholder={`${openDialog}` == 'Add' ? "Enter credit amount" : "Enter Debit amount"}
                                        className={`${classes.emailInput} ${'form-control' + (errors.credits && touched.credits ? ' is-invalid' : '')}`}
                                    />
                                    <ErrorMessage name="credits" component="div" className="invalid-feedback" />
                                </div>

                                {/* <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button> */}

                                <Button type="submit" className={classes.loginButton} simple block
                                    style={{ background: "#6259CA", color: "#fff", padding: "15px 27px" }}>
                                    Proceed
                                </Button>
                                <Button type="button" onClick={() => setOpen(false)} simple block variant="outlined"
                                    style={{ padding: 15, marginLeft: 16 }}> Cancel </Button>
                                {isSubmitting &&
                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                                <br />

                                <div className="row">
                                    <div className="col-md-12">
                                        {status &&
                                            <div className={'alert alert-danger'}>{status}</div>
                                        }
                                    </div>
                                </div>



                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            <div>
                {
                    <Card className={`${classes.mainCard} productCategory`}>
                        <div className={classes.displayFlexJustifySBalignCenter}>
                            <div>
                                {/* <IconButton aria-label="edit" onClick={getToPrevious}>
                                    <ArrowBackIcon />
                                </IconButton> */}
                            </div>


                            <div style={{ paddingTop: 10, paddingRight: 10, display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    style={{fontSize: 15}}
                                    variant="contained"
                                    color="default"
                                    className={classes.button}
                                    onClick={() => handleClickOpen('Add')}
                                    startIcon={<Icon color="primary">add</Icon>}
                                >
                                    Add Credits
                        </Button>
                                <Button
                                    style={{fontSize: 15}}
                                    variant="contained"
                                    color="default"
                                    className={classes.button}
                                    onClick={() => handleClickOpen('Deduct')}
                                    startIcon={<Icon color="primary">remove</Icon>}>
                                    Deduct Credits
                        </Button>
                            </div>
                        </div>
                        <GridContainer style={{ paddingBottom: "20px", margin: 0 }}>
                            <TableContainer className={classes.tcontainer}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead className={classes.thead}>
                                        <TableRow>
                                            <StyledTableCell style={{fontSize: 16}} className={classes.bold}>Invoice Number</StyledTableCell>
                                            <StyledTableCell style={{fontSize: 16}} className={classes.bold}>Date </StyledTableCell>
                                            <StyledTableCell style={{fontSize: 16}} className={classes.bold}>Time</StyledTableCell>
                                            <StyledTableCell style={{fontSize: 16}} className={classes.bold}>Credit/ Debit</StyledTableCell>
                                            <StyledTableCell style={{fontSize: 16}} className={classes.bold}>Credit/ Debit Request</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
                                        {promoData && promoData.map((promo) => (
                                            <TableRow key={promo._id} >
                                                <TableCell component="th" scope="row" className={classes.tcell1}>
                                                    <div className={classes.div1} style={{fontSize: 14, fontFamily: "Muli", color: "#25233C"}}>
                                                        {promo.payment_intent_id ? promo.payment_intent_id : promo.description}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left" className={classes.tcell2}>
                                                    <div className={classes.div2} style={{fontSize: 14, fontFamily: "Muli", color: "#25233C"}}>
                                                        {new Date(promo.datetime.replace(/-/g, "/")).toISOString().split('T')[0]}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left" className={classes.tcell2}>
                                                    <div className={classes.div2} style={{fontSize: 14, fontFamily: "Muli", color: "#25233C"}}>
                                                        {new Date(promo.datetime.replace(/-/g, "/")).toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row" className={classes.tcell2}>
                                                    <div className={classes.div2} style={{fontSize: 14, fontFamily: "Muli", color: "#25233C"}}>
                                                        {promo.type}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left" className={classes.tcell3}>
                                                    <div className={classes.div2} style={{fontSize: 14, fontFamily: "Muli", color: "#25233C"}}>
                                                        {promo.credits}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {
                                promoData && promoData.length == 0 && isLoading == false &&
                                <h6 style={{ color: 'grey', marginTop: '40px', paddingLeft: '20px' }}>
                                    No credit / Debit Transaction
                                    </h6>
                            }
                            {isLoading == true &&
                                <div style={{ display: "flex", justifyContent: 'center', width: '100%', marginBottom: 20 }}>
                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                </div>
                            }
                        </GridContainer>
                    </Card>}
            </div>
            <>
                {alertError}
            </>
        </>
    )
}

export default PaymentHistory
