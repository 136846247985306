import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import AppBar from '@material-ui/core/AppBar';
import { Icon } from '@material-ui/core';
import AddFeatured from './AddFeatured';
import ReactTable from "_components/ReactTable.js";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { SPService } from "_services/serviceprovider.service";
import { userService } from "_services/user.service";
import { categoryService } from "_services/category.service";
import edit from '../../../edit-svgrepo-com.svg'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import EditFeatured from './EditFeatured';

const useStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    right: "3pc",
    // top: "7pc",
    top: "6pc",
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8d9d",
    "@media (max-width: 576px)":{
      position: "unset",
      top: "unset",
      right: "unset",
      marginLeft: "16px",
      paddingLeft: "12px !important"
    }
  },
  button2: {
    background: "transparent",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8cac"
  },
  SaveButton: {
    backgroundColor: '#6362ca',
    color: '#ffffff',
    width: 100,
    fontSize: "10px",
    padding: "10px",
    textTransform: "capitalize",
    fontSize: 14,
    "&:hover": {
      backgroundColor: '#6362ca',
      color: '#ffffff',
    },
    "&:focus": {
      backgroundColor: '#6362ca',
      color: '#ffffff',
    }
  },
  DelButton: {
    border: "1px solid grey",
    color: "#8E8CAC",
    textTransform: "capitalize",
    fontSize: 14,
    "&:hover": {
      backgroundColor: '#6362ca',
      color: '#ffffff',
    },
    "&:focus": {
      backgroundColor: '#6362ca',
      color: '#ffffff',
    }
  },
  btnAlign: {
    display: "flex",
    marginLeft: "5px",
  },
  h5: {
    fontSize: 18
  },
  container: {
    // border:"1px solid grey",
    margin: "0 -20px"
  },
  Imginput: {
    display: "none",

  },
  UploadBtn: {
    padding: "10px 15px",
    fontSize: "14px",
    textTransform: "capitalize",
    marginBottom: "7px",
    background: "#8E8CAC"
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
    color: '#25233C'
  },
  fontSize20: {
    fontSize: '20px',
    color: '#25233C',
    marginBottom: 14,
  },
  tableCard:{
    "@media (max-width: 1340px)":{
      overflow: "auto"
    }
  },
  tableCardBody:{
    padding: 0,
    "@media (max-width: 1340px)":{
      width: "991px",
    }
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  addNewBadge: {
    color: '#6B6987', fontSize: '15px'
  },
  Featured:
  {
    paddingLeft:'0px !important',
    paddingRight:'0px !important'
  }
}));

const FeaturedList = (props) => {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [featuredFullData, setFeaturedFullData] = React.useState([]);
  const [featuredDisplayed, setFeaturedDisplayed] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [alertDelete, setAlertDelete] = React.useState(null);
  const [apiCall, setApiCall] = React.useState(false);

  function SweetAlertConfirmDelete() {
    const getAlert = () => (
      <SweetAlert
        success
        title="Woot!"
        onConfirm={() => {
          setAlertDelete(null);
        }}
      >
        Data deleted successfully
      </SweetAlert>
    )
    setAlertDelete(getAlert());
  }

  function SweetAlertConfirm(id) {
    const getAlert = () => (id &&
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="success"
        title="Are you sure?"
        onConfirm={() => {
          setAlert(null);
          deleteFeatured(id);
        }}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You want to delete this record?
          </SweetAlert>
    )
    setAlert(getAlert());
  }


  const deleteFeatured = (idToDelete) => {

    const obj = {
      id: idToDelete
    }
    SPService.deleteFeaturedSp(obj).then((res) => {
      SweetAlertConfirmDelete();
    })
  }

  React.useEffect(() => {
    let isMount = false;
    const getData = async () => {
      if (!isMount) {
        setApiCall(true);
        try {
          const res = await SPService.getFeaturedList();
          const array = [];
          let array2 = [];
          let sortedFeaturedList = [];

          // if (res) {
          //   for (let prop in res) {
          //     //  console.log(res[`${prop}`]);
          //     const spDetail = await userService.getSP(res[`${prop}`][0].serviceprovider_id);
          //     const subcateDetail = await categoryService.getSubCategorybyId(res[`${prop}`][0].subactegory_id)
          //     // console.log(res[`${prop}`][0],res[`${prop}`][res[`${prop}`].length-1])
          //     if (spDetail && subcateDetail) {
          //       array2.push({
          //         spid: res[`${prop}`][0].serviceprovider_id,
          //         featuredFromId: res[`${prop}`][0]._id,
          //         featuredToId: res[`${prop}`][0]._id,
          //         featuredFromDate: res[`${prop}`][0].featured_date,
          //         featuredToDate: res[`${prop}`][res[`${prop}`].length - 1].featured_date,
          //         uniqueIdVerify: prop,
          //         spName: spDetail?.user?.name ? spDetail.user.name :" not available"  ,
          //         subcatName: subcateDetail.name,
          //         subCatid: subcateDetail._id
          //       })
          //     }
          //   }
          // }

          if (res.length > 0) {
            res.map((prop) => {
              array2.push({
                spid: prop.serviceprovider_id,
                featuredFromDate: prop.featured_start_date,
                featuredToDate: prop.featured_end_date,
                uniqueIdVerify: prop.unique_featured_id,
                spName: prop.sp_name,
                subcatName: prop.subcategory_name,
                subCatid: prop.subactegory_id
              })
            })
          }

          sortedFeaturedList = array2.sort((a, b) => (a.featuredToDate > b.featuredToDate) ? -1 : 1);
          setFeaturedFullData([...sortedFeaturedList]);
          setApiCall(false);
        }
        catch (err) { 
          setApiCall(false);
        }
      }
    }

    getData();



    return function cleanup() {
      isMount = true;
      setApiCall(false);
      setFeaturedFullData([])
    }

  }, [props.history.location.pathname, alertDelete])

  React.useEffect(() => {
    if (featuredFullData.length > 0) {
      let array = [];
      featuredFullData.map((prop) => {
        array.push({
          id: prop.uniqueIdVerify,
          name: prop.subcatName,
          service: prop.spName,
          from: prop.featuredFromDate,
          to: prop.featuredToDate,
          actions: (
            <div className="actions-right">
              <Link to={{
                pathname: "/app/product_services/featuredListing/editFeatured", state: {
                  spid: prop.spid,
                  // featuredFromId: prop.featuredFromId,
                  // featuredToId: prop.featuredToId,
                  featuredFromDate: prop.featuredFromDate,
                  featuredToDate: prop.featuredToDate,
                  uniqueIdVerify: prop.uniqueIdVerify,
                  spName: prop.spName,
                  subcatName: prop.subcatName,
                  subCatid: prop.subCatid
                }
              }}>
                <IconButton style={{paddingRight:'5px'}}>
                  {/* <EditIcon style={{ color: '#8E8CAC' }}/> */}
                  <img src={edit} style={{width:'21px', height:'18px',color:'#8E8CAC'}}/>
                </IconButton>
              </Link>
              {" "}
              <IconButton onClick={() => {
                SweetAlertConfirm(prop.uniqueIdVerify);
              }} style={{paddingLeft:'0px'}}>
                <DeleteIcon style={{width:'21px', height:'18px',color:'#8E8CAC'}}/>
              </IconButton>

              {" "}
            </div>
          )
        })
      });
      setFeaturedDisplayed([...array]);
    }
  }, [featuredFullData])


  function getToPrevious() {
    // console.log('hello')
    const { from } = { from: { pathname: "/app/product_services" } };
    props.history.goBack();
  }
  return (
    <>
      {
        window.location.href.split('/')[window.location.href.split('/').length - 1] == 'featuredListing' && <div>
          <AppBar elevation={0} position="static" color="transparent" className={classes.appbarAdj} >
            <div className={classes.header}>
              <IconButton aria-label="edit" onClick={getToPrevious} style={{paddingLeft:'0px',marginLeft:'-5px'}}>
                <ArrowBackIcon style={{ color: '#25233C' }}/>
              </IconButton>
              <h4 className={classes.fontSize20} style={{fontFamily: "Muli-Bold"}}>Featured Listing</h4>
            </div>
            <Link to="/app/product_services/featuredListing/addFeatured">
              <Button

                variant="contained"
                className={classes.button}
                startIcon={<Icon style={{ color: '#6B6987' }}>add</Icon>}
              >
                  <span className={classes.addNewBadge}>Add New Listing</span> 
            </Button>
            </Link>
          </AppBar>
          <GridContainer container >
            <GridItem xs={12} id="featured">
              <Card className={classes.tableCard}>
                <CardHeader color="primary" 
                // style={{ height: '20px' }}
                 icon>
                  {/* <CardIcon color="primary">
                    <Assignment />
                  </CardIcon> */}
                  {/* <h4 className={classes.cardIconTitle}>React Table</h4> */}
                </CardHeader>
                <CardBody className={classes.tableCardBody}>
                  {featuredFullData.length == 0 && apiCall == true &&
                    <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                      <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                  }

                  {featuredFullData.length == 0 && apiCall == false &&

                    <h6 style={{ textAlign: 'center', marginBottom: 20 }}>
                      No Sp is featured
                    </h6>

                  }


                  {featuredFullData.length > 0 && apiCall == false &&

                    <ReactTable
                      className={'user-table-adj'}
                      columns={[
                        {
                          Header: "Subcategory",
                          accessor: "name",
                        },
                        {
                          Header: "Service Providers",
                          accessor: "service",
                        },
                        {
                          Header: "From",
                          accessor: 'from'
                        },
                        {
                          Header: "To",
                          accessor: 'to'
                        },
                        {
                          Header: "",
                          accessor: "actions",
                        }

                      ]}


                      data={featuredDisplayed.length > 0 ? featuredDisplayed : []}
                      onRowClick={(rowData, clickType) => console.log("")}
                    // onRowClick={(rowData, clickType) => handleRowClick(rowData, clickType)}
                    />
                  }
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      }

      <div className="container-fluid">
        <Switch>
          <Route path={`${path}/addFeatured`}>
            <AddFeatured {...props} />
          </Route>
          <Route path={`${path}/editFeatured`}>
            <EditFeatured {...props} />
          </Route>
        </Switch>
      </div>
      <>
        {alert}
      </>
      <>
        {alertDelete}
      </>
    </>
  );
}

export default FeaturedList;
