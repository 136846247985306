import { Avatar, Button, Grid, Icon, IconButton, Link, Typography, useMediaQuery } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Image } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { userService } from "_services/user.service";
import blueTick from "../../../assets/img/blueTick.png";
import apple from "../../../assets/img/apple-icon.png";
import { useHistory, Redirect } from "react-router-dom";
import StarIcon from '@material-ui/icons/Star';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import { OutlinedInput } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import { ListItemText } from '@material-ui/core';
import spdataContext from 'context/spdataContext';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useRouteMatch
} from "react-router-dom";
import { categoryService } from '_services/category.service';
import edit from '../../../edit-svgrepo-com.svg'
const useStyles = makeStyles((theme) => ({

    button: {
        margin: theme.spacing(1),
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#6B6987",
        fontSize: '15px',
        fontFamily: 'Muli',
        "&:hover": {
            background: "#efefef",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
    },
    formControl: {
        minWidth: '100%',
        marginBottom: 15
    },
    h5: {
        color: "#25233C",
        fontSize: 18,
    },
    selectadj: {
        border: "1px solid #B9B8C4",
        height: 50,
        padding: 15,
        borderRadius: 5
    },
    serviceProviderProfilePic: {
        border: "3px solid #fff",
        height: 115,
        width: 115,
        borderRadius: "50%",
        position: "absolute",
        left: 20,
        top: "-4.5em",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        "@media (max-width: 576px)": {
            position: "unset",
            marginTop: "14px",
            width: "75px",
            height: "75px"
        }
    },
    marginTop14: {
        marginTop: '14px',
    },
    marginTop40: {
        marginTop: '40px'
    },
    marginTop30: {
        marginTop: '40px'
    },
    creditBalanceAndTimesHiredSectionGrid: {
        paddingRight: 20,
        justifyContent: "center",
        marginTop: "30px"
    },
    badgeInfo:
    {
        '@media(max-width:380px)': {
            display: 'flex !important',
            flexWrap: 'wrap !important',
        }
    },
    badgeInfoTitle:
    {
        '@media(max-width:380px)': {
            paddingRight: '15px'
        }
    },
    listView:
    {
        marginTop:'5px',
        marginBottom:'5px',
    }
}));

const ServiceProviderDetailHeader = (props) => {
    const classes = useStyles();
    let history = useHistory();
    let { path, url } = useRouteMatch();
    const [data, setData] = React.useState(null);
    const { slug } = useParams();
    const [open, setOpen] = React.useState(false);
    const [openBadge, setOpenBadge] = React.useState(false);
    const [selectedStatus, setSelectedStatus] = React.useState('');
    const [selectedVerifiedStatus, setSelectedVerifiedStatus] = React.useState('');
    const [selectedCustomStatus, setSelectedCustomStatus] = React.useState('');
    const [selectedTier, setSelectedTier] = React.useState('');
    const [alert, setAlert] = React.useState(null);
    const [putRequestSendVerificationStatus, setPutRequestSendVerificationStatus] = React.useState('');
    const [render, setRender] = React.useState(0);
    const [avgReview, setAvgReview] = React.useState(0);
    const [manualBadge, setManualBadge] = React.useState([]);
    const [badgeId, setBadgeId] = React.useState([]);
    const [badgeDisplay, setBadgeDisplay] = React.useState([]);
    const [badgesToSendId, setBadgesToSendId] = React.useState([]);
    const [badgeNameDisp, setBadgeNameDisp] = React.useState([]);
    const [autoBadgeCount, setAutoBAdgeCount] = React.useState([]);
    const isSmMedia = useMediaQuery("@media(max-width:576px)");
    const dataContext = React.useContext(spdataContext);
    const [dataObj, setDataObj] = React.useState({
        about: '',
        creditBalance: 0,
        timesHired: '',
        faq: [],
        serviceLocation: [],
        experience: '',
    })

    // In useEffect in some section we have called api in two methods like by taking slug and window object ,reason for that is some times the slug can't get fetched due to stack of routes over each other, so we used window object for that


    React.useEffect(() => {

        const fetchPromotions = async () => {

            if (slug == 'addService' || slug == 'addPackage' || slug == 'editService' || slug == 'editPackage' || slug == 'addpromotion') {
                dataContext.update(window.location.href.split('/')[window.location.href.split('/').length - 1]);
                try {
                    const response = await userService.getSP(window.location.href.split('/')[window.location.href.split('/').length - 1]);
                    setData(response);
                    setSelectedStatus(response.user && response.user.active ? response.user.active : null);
                    setSelectedVerifiedStatus(response && response.background_verification && response.background_verification == 'na' ? 'not verified' : response.background_verification)
                    setSelectedCustomStatus(response && response.message_for_custom_requirement ? response.message_for_custom_requirement : "")
                    setPutRequestSendVerificationStatus(response && response.background_verification && response.background_verification == 'na' ? 'not verified' : response.background_verification)
                    // setSelectedTier(response && response.tier ? response.tier : '');
                    setDataObj({
                        about: response.brief_desription ? response.brief_desription : '',
                        creditBalance: response.credit ? response.credit : 0,
                        faq: response.faq ? response.faq : [],
                        serviceLocation: response.service_location ? response.service_location : [],
                        experience: response.experience ? response.experience : ''
                    })
                    setSelectedTier(response && response.tier_type ? response.tier_type : '')

                    if (response.badge_ids && response.badge_ids.length > 0) {
                        setBadgeId([...response.badge_ids]);
                    }
                    else {
                        setBadgeId([]);
                    }


                    if (response && response.badge_data.length > 0) {
                        let arr = [];
                        response.badge_data.map((prop) => {
                            if (prop.type == "manual") {
                                arr.push(prop._id)
                            }
                        })
                        setBadgesToSendId([...arr]);
                    }
                    else {
                        setBadgesToSendId([]);
                    }

                    if (response && response.badge_data.length > 0) {
                        let count = 0;
                        response.badge_data.map((prop) => {
                            if (prop.type == "auto") {
                                count = count + 1;
                                setAutoBAdgeCount(count);
                            }
                        })
                    }
                    else {
                        setAutoBAdgeCount(0);
                    }

                    const responseBadge = await categoryService.getBadge();
                    let arrBadge = [];
                    responseBadge.map((prop, index) => {
                        if (prop.type == "manual") {
                            arrBadge.push({
                                'id': prop._id,
                                'name': prop.title,
                            })
                        }

                    })
                    setManualBadge([...arrBadge]);
                }
                catch (err) { }
            }
            else {
                dataContext.update(slug);
                try {
                    const response = await userService.getSP(slug);
                    setData(response);
                    const badgesId = response?.badge_data?.map((item) => {return item?._id})
                    setBadgesToSendId(badgesId);
                    setSelectedStatus(response.user && response.user.active ? response.user.active : null);
                    setSelectedVerifiedStatus(response && response.background_verification && response.background_verification == 'na' ? 'not verified' : response.background_verification)
                    setSelectedCustomStatus(response && response.message_for_custom_requirement ? response.message_for_custom_requirement : "")
                    setPutRequestSendVerificationStatus(response && response.background_verification && response.background_verification == 'na' ? 'not verified' : response.background_verification)
                    setDataObj({
                        about: response.brief_desription ? response.brief_desription : '',
                        creditBalance: response.credit ? response.credit : 0,
                        faq: response.faq ? response.faq : [],
                        serviceLocation: response.service_location ? response.service_location : [],
                        experience: response.experience ? response.experience : ''
                    })
                    setSelectedTier(response && response.tier_type ? response.tier_type : '')
                    if (response.badge_ids && response.badge_ids.length > 0) {
                        setBadgeId([...response.badge_ids]);
                    }
                    else {
                        setBadgeId([])
                    }

                    if (response && response.badge_data.length > 0) {
                        let arr = [];
                        response.badge_data.map((prop) => {
                            if (prop.type == "manual") {
                                arr.push(prop._id)
                            }
                        })
                        setBadgesToSendId([...arr]);
                    }

                    else {
                        setBadgesToSendId([])
                    }

                    if (response && response.badge_data.length > 0) {
                        let count = 0;
                        response.badge_data.map((prop) => {
                            if (prop.type == "auto") {
                                count = count + 1;
                                setAutoBAdgeCount(count);
                            }
                        })
                    }
                    else {
                        setAutoBAdgeCount(0);
                    }

                    const responseBadge = await categoryService.getBadge();
                    let arrBadge = [];
                    responseBadge.map((prop, index) => {
                        if (prop.type == "manual") {
                            arrBadge.push({
                                'id': prop._id,
                                'name': prop.title,
                            })
                        }


                    })
                    setManualBadge([...arrBadge]);
                }
                catch (err) { }
            }
        };
        fetchPromotions();
    }, [render, alert])

    React.useEffect(() => {
        if (badgeId.length > 0) {
            let arr = [];
            try {
                badgeId.map(async (prop) => {
                    const res = await categoryService.getBadgeById(prop);
                    arr.push({
                        container_color: res.container_color,
                        icon: res.icon,
                        outline_color: res.outline_color,
                        text_color: res.text_color,
                        title: res.title
                    });
                    setBadgeDisplay([...arr]);
                })
            }
            catch (err) { }
        }
        else {
            setBadgeDisplay([])
        }
    }, [badgeId])

    const handleChangeMulti = (event) => {
        if (autoBadgeCount == 2) {
            SweetAlertConfirmError('Maximum Number of Badges reached can not add more');
        }
        else {
            let value = [];
            value = event.target.value;
            setBadgesToSendId(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };

    React.useEffect(() => {
        if (badgesToSendId.length > 0) {
        let array = [];
            manualBadge.map((prop, index) => {
                if (badgesToSendId.includes(prop.id)) {
                    array.push(prop.name);
                }
                else {
                    return
                }
            });
            setBadgeNameDisp([...array]);
        }
        else {
            setBadgeNameDisp([]);

        }
    }, [badgesToSendId, manualBadge])

    React.useEffect(() => {
        const getReviewSp = async () => {
            try {
                if (slug == 'addService' || slug == 'addPackage' || slug == 'editService' || slug == 'editPackage' || slug == 'addpromotion') {
                    const responseOfReview = await categoryService.getReviewsOfSp(window.location.href.split('/')[window.location.href.split('/').length - 1]);
                    if (responseOfReview.avg_rating) {
                        setAvgReview(responseOfReview.avg_rating)
                    }
                }
                else {
                    const responseOfReview = await categoryService.getReviewsOfSp(slug);
                    if (responseOfReview.avg_rating) {
                        setAvgReview(responseOfReview.avg_rating)
                    }
                }
            }
            catch (err) { }
        }

        getReviewSp()


    }, [])

    function SweetAlertConfirm(data) {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                    setAlert(null)
                }}
            >
                {data}
            </SweetAlert>
        )
        setAlert(getAlert());
    }

    function SweetAlertConfirmError(data) {
        const getAlert = () => (
            <SweetAlert
                error
                title="Error"
                onConfirm={() => {
                    setAlert(null);
                }}
            >
                {data}
            </SweetAlert>
        )
        setAlert(getAlert());
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setRender(!render);
    };
    const handleCloseBadge = () => {
        setOpenBadge(false);
    };
    function handleSelectChange(e) {
        setSelectedStatus(e.target.value);
    }

    function handleVerifiedChange(e) {
        if (e.target.value == "verified") {
            setPutRequestSendVerificationStatus(e.target.value)
        }
        else {
            setPutRequestSendVerificationStatus("not verified")
        }
        setSelectedVerifiedStatus(e.target.value)
    }

    function handleCustomChange(e) {
        setSelectedCustomStatus(e.target.value)
    }

    function handleChangeTier(e) {
        setSelectedTier(e.target.value);
    }

    function saveChangedStatus() {
        const obj = {
            id: data.user._id,
            bg_verified: putRequestSendVerificationStatus,
            active: selectedStatus,
            description: '',
            custom_message: selectedCustomStatus
        }
        userService.manageAccountSetting(obj)
            .then(res => {
                if (selectedTier.length > 0) {
                    categoryService.postSpTiers({
                        "serviceprovider_id": slug,
                        "tier_type": selectedTier
                    }).then((res) => {
                        setOpen(false);
                        SweetAlertConfirm('Account status updated successfully');
                        setRender(!render)
                    })
                }
                else {
                    setOpen(false);
                    SweetAlertConfirm('Account status updated successfully');
                    setRender(!render)
                }
            })

    }

    const saveAttachBadge = async () => {
        const value=(badgesToSendId.filter((badge)=>
        {
              return data.badge_ids.includes(badge)
            }))
 
        if (badgesToSendId.length > 0) {
            let badgeLength = 0;
            badgesToSendId.map((prop) => {
                if (slug == 'addService' || slug == 'addPackage' || slug == 'editService' || slug == 'editPackage' || slug == 'addpromotion') {
                    categoryService.assign_detachBadgeToSp(
                        {
                            "serviceprovider_id": window.location.href.split('/')[window.location.href.split('/').length - 1],
                            "badge_id": prop,
                            "action": "attach"
                        }).then((res) => {
                            setOpenBadge(false);
                            if (res.status == "error") {
                                SweetAlertConfirmError(res.errors)
                            }
                            else {
                                badgeLength = badgeLength + 1
                                if (badgeLength == badgesToSendId.length) {
                                    SweetAlertConfirm('Badge Assigned To ServiceProvider')
                                }
                            }
                        });
                }
                else if(badgesToSendId.length>2)
                {
                    SweetAlertConfirmError('Maximum Number of Badges reached can not add more');
                }
               else if(value.length>0)
               {
                SweetAlertConfirmError('Badge is Already assigned To ServiceProvider');
               }

                else {
                        categoryService.assign_detachBadgeToSp({
                            "serviceprovider_id": slug,
                            "badge_id": prop,
                            "action": "attach"

                        }).then((res) => {
                            setOpenBadge(false);
                            if (res.status == "error") {
                                SweetAlertConfirmError(res.errors)
                            }
                            else {
                                badgeLength = badgeLength + 1
                                if (badgeLength == badgesToSendId.length) {
                                    SweetAlertConfirm('Badge Assigned To ServiceProvider');
                                }
                            }
                        });
                    }
                }
            )

        }
        else {
            SweetAlertConfirmError('Please Select At least One Badge');
        }
    }


    const saveDetachBadge = async () => {
        if (badgesToSendId.length > 0) {
            let badgeLength = 0;
            badgesToSendId.map((prop) => {
                if (slug == 'addService' || slug == 'addPackage' || slug == 'editService' || slug == 'editPackage' || slug == 'addpromotion') {
                    categoryService.assign_detachBadgeToSp(
                        {
                            "serviceprovider_id": window.location.href.split('/')[window.location.href.split('/').length - 1],
                            "badge_id": prop,
                            "action": "detach"
                        }).then((res) => {
                            setOpenBadge(false);
                            if (res.status == "error") {
                                SweetAlertConfirmError(res.errors)
                            }
                            else {
                                badgeLength = badgeLength + 1
                                if (badgeLength == badgesToSendId.length) {
                                    SweetAlertConfirm('Badge Detach from service provider')
                                }
                            }
                        });
                }
                else {
                    categoryService.assign_detachBadgeToSp({
                        "serviceprovider_id": slug,
                        "badge_id": prop,
                        "action": "detach"

                    }).then((res) => {
                        setOpenBadge(false);
                        if (res.status == "error") {
                            SweetAlertConfirmError(res.errors)
                        }
                        else {
                            badgeLength = badgeLength + 1
                            if (badgeLength == badgesToSendId.length) {
                                SweetAlertConfirm('Badge Detach from service provider')
                            }
                        }
                    });
                }
            })

        }
        else {
            SweetAlertConfirmError('Please Select At least One Badge');
        }
    }

    const handleEditOpen = () => {
        if (slug == 'addService' || slug == 'addPackage' || slug == 'editService' || slug == 'editPackage' || slug == 'addpromotion') {
            const { from } = { from: { pathname: "/app/service_providers/service_provider_detail/edit/" + window.location.href.split('/')[window.location.href.split('/').length - 1] } };
            props.history.push(from);
        }
        else {
            const { from } = { from: { pathname: "/app/service_providers/service_provider_detail/edit/" + slug } };
            props.history.push(from);
        }

    }


    return (
        <>
            {<>
                <div>
                    <Dialog style={{ maxWidth: 450, margin: "auto" }} open={open} onClose={(e) => handleClose(e)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" style={{ background: "#F5F5FC", color: "#6B6987" }}>Change user status</DialogTitle>
                        <DialogContent>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <h5 className={classes.h5}>Verified Account</h5>
                                <Select
                                    disableUnderline
                                    className={classes.selectadj}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectedVerifiedStatus}
                                    onChange={(e) => handleVerifiedChange(e)}
                                    input={<Input />} >
                                    <MenuItem value={'verified'}>Yes</MenuItem>
                                    <MenuItem value={'not verified'}>No</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <h5 className={classes.h5}>Account Status</h5>
                                <Select
                                    disableUnderline
                                    className={classes.selectadj}
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={selectedStatus}
                                    onChange={(e) => handleSelectChange(e)}
                                    input={<Input />} >
                                    <MenuItem value={'true'}>Active</MenuItem>
                                    <MenuItem value={'false'}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <h5 className={classes.h5}>Open for Custom Request</h5>
                                <Select
                                    disableUnderline
                                    className={classes.selectadj}
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={selectedCustomStatus}
                                    onChange={(e) => handleCustomChange(e)}
                                    input={<Input />} >
                                    <MenuItem value={'true'}>Yes</MenuItem>
                                    <MenuItem value={'false'}>No</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <h5 className={classes.h5}>Change Tier</h5>
                                <Select
                                    disableUnderline
                                    className={classes.selectadj}
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={selectedTier}
                                    onChange={(e) => handleChangeTier(e)}
                                    input={<Input />} >
                                    <MenuItem value={'budget'}>Budget ($)</MenuItem>
                                    <MenuItem value={'regular'}>Regular ($$)</MenuItem>
                                    <MenuItem value={'premium'}>Premium ($$$)</MenuItem>
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "flex-start", paddingLeft: 25, paddingBottom: 30 }}>
                            <Button onClick={() => saveChangedStatus()} style={{ background: "#6259CA", color: "#fff", padding: "15px 27px" }}>
                                Save Changes
                            </Button>
                            <Button onClick={() => handleClose()} color="primary" variant="outlined" style={{ padding: 15, marginLeft: 16 }}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog style={{ maxWidth: 550, margin: "auto" }} open={openBadge} onClose={(e) => handleCloseBadge(e)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" style={{ background: "#F5F5FC", color: "#6B6987" }}>Attach/ Detach badge</DialogTitle>
                        <DialogContent>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <h5 className={classes.h5}>Select Badges</h5>
                                <Select
                                    fullWidth={true}

                                    className={`${classes.heightOfMultiSelect} ${classes.selectadj}`}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={badgesToSendId}
                                    onChange={handleChangeMulti}
                                    input={<OutlinedInput />}
                                    // MenuProps={MenuProps}
                                    renderValue={() => badgeNameDisp.join(', ')}
                                >
                                    {manualBadge.length > 0 &&
                                        manualBadge.map((name) => (
                                            <MenuItem className={classes.listView} key={name.id} value={name.id}>
                                                <Checkbox checked={badgesToSendId.indexOf(name.id) > -1} 
                                                    />
                                                <ListItemText primary={name.name} />
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "flex-start", paddingLeft: 25, paddingBottom: 30 }}>
                            <Button onClick={saveAttachBadge} style={{ background: "#6259CA", color: "#fff", padding: "15px 27px" }}>
                                Attach Badge
                            </Button>
                            <Button onClick={saveDetachBadge} style={{ background: "#6259CA", color: "#fff", padding: "15px 27px" }}>
                                Detach Badge
                            </Button>
                            <Button onClick={() => handleCloseBadge()} color="primary" variant="outlined" style={{ padding: 15, marginLeft: 16 }}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
                <Grid container style={{ padding: 10, background: "#fff", borderRadius: 5, paddingBottom: "5pc" }} >
                    <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "flex-end", background: "#8e8cac30", height: "88px", alignItems: "center", paddingRight: 20, borderRadius: 5 }}>

                        <IconButton aria-label="edit" onClick={() => setOpenBadge(true)} style={{ paddingRight: '5px' }}>
                            <FeaturedVideoIcon style={{ width: '21px', height: '18px', color: '#8E8CAC' }} />
                        </IconButton>

                        <IconButton aria-label="edit" onClick={() => handleEditOpen()} style={{ paddingLeft: '5px' }}>
                            <img src={edit} style={{ width: '21px', height: '18px', color: '#8E8CAC' }} />
                        </IconButton>
                        <Link className={classes.textDecNone}>
                            <Button
                                variant="contained"
                                color="default"
                                className={classes.button}
                                onClick={() => handleClickOpen()}
                                startIcon={<Icon style={{ color: '#6B6987' }}>settings</Icon>}
                            >Account Settings
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item md={12} style={{ display: "flex", justifyContent: "center", minHeight: "120px", alignItems: "center", position: "relative" }}>

                        <Grid item container>
                            <Grid item xs={6} sm={6} md={6} container alignItems="flex-end" style={{ display: "flex", padding: "0 20px 0" }}>
                                <div className={`${classes.ImgBorder} ${classes.serviceProviderProfilePic}`} style={{ backgroundImage: `url('${data?.user?.image_url}')` }}></div>
                                <div className={isSmMedia ? classes.marginTop14 : classes.marginTop40}>
                                    <Typography gutterBottom variant="h5" style={{ fontSize: 20, marginBottom: "unset", fontFamily: "Muli-Bold", color: "#25233C", wordBreak: "break-word" }} >
                                        {data?.user?.name} {selectedVerifiedStatus == "verified" && <img src={blueTick} style={{ width: 20, height: 20 }} />}
                                    </Typography>
                                    <Typography gutterBottom variant="h5" style={{ fontSize: 15, marginBottom: "unset", fontFamily: 'Muli', color: "#9997B2" }} >
                                        {data?.user?.profession ? data.user.profession : ''}
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.starSection} style={{ fontSize: 6, alignItems: 'center', color: "#6259CA" }}>
                                        {avgReview !== 0 &&
                                            [...Array(parseInt(avgReview))].map((value, index) => {
                                                return <StarIcon key={index} />
                                            })
                                        }
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} align="right" >
                                <Grid item xs container direction="row" alignItems="flex-end" spacing={2} className={classes.creditBalanceAndTimesHiredSectionGrid}>
                                    <Grid item xs={3} sm={4} md={3} className={'text-center'} style={{ paddingRight: 20, flexBasis: 144, maxWidth: '100%' }}>
                                        {
                                            badgeDisplay.length > 0 && badgeDisplay.map((prop, index) => {
                                                return <div key={index} style={{ width: '132px', height: '42px', backgroundColor: `${prop.container_color}`, border: `1px solid ${prop.outline_color}`, borderRadius: '5px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginBottom: '10px' }} className={classes.badgeInfo}>
                                                    <div>
                                                        <img style={{ width: '19px', height: '19px', paddingRight: '5px' }} src={`${prop.icon}`} />
                                                    </div>
                                                    <div>
                                                        <Typography style={{ color: `${prop.text_color}`, fontSize: 15, color: '#6B6987', fontFamily: "Muli-SemiBold" }} className={classes.badgeInfoTitle}>
                                                            {prop.title}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            })
                                        }

                                    </Grid>
                                    <Grid item xs={6} sm={6} md={3} className={'text-center'} style={{ paddingRight: 20, flexBasis: 144 }}>
                                        <Typography gutterBottom variant="h5" style={{ fontFamily: "Muli-SemiBold", color: "#6259CA", fontSize: 25 }}>
                                            {dataContext.stateOfUser.credits}
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontSize: 14, color: '#6B6987', fontFamily: "Muli-SemiBold" }}>
                                            CREDIT Balance
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={3} className={'text-center'} style={{ paddingRight: 20, flexBasis: 130 }}>
                                        <Typography gutterBottom variant="h5" style={{ fontFamily: "Muli-SemiBold", color: "#6259CA", fontSize: 25 }}>
                                            {data?.hired_time}
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontSize: 14, color: '#6B6987', fontFamily: "Muli-SemiBold" }}>
                                            TIMES HIRED
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>}
            <>
                {alert}
            </>
        </>
    )
}

export default ServiceProviderDetailHeader;
