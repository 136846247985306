import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import AddCategory from './AddCategory';
import { categoryService } from '_services/category.service';
const EditCategory = (props) => {
    const { slug } = useParams();
    const [data, setData] = useState(null);
    useEffect(() => {
        categoryService.getCategorybyId(slug)
            .then((res) => {
                setData(res);
            })
            .catch((err) => {
                console.log("ERROR EDIT CATEGORY ", err);
            })
    }, []);
    return (
        <div>
            {data && <AddCategory {...props} editData={data} />}
        </div>
    )
}

export default EditCategory
