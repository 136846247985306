import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { Grid, Paper, Switch } from "@material-ui/core";
import {
  Link,
  Route,
  BrowserRouter as Router,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "components/CustomButtons/Button.js";
import { CKEditor } from "ckeditor4-react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { IconButton } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { userService } from "_services/user.service";

const useStyles = makeStyles((theme) => ({
  SaveButton: {
    backgroundColor: "#6362ca",
    color: "#ffffff",
    width: 100,
    fontSize: "10px",
    padding: "10px",
    textTransform: "capitalize",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#6362ca",
      color: "#ffffff",
    },
    "&:focus": {
      backgroundColor: "#6362ca",
      color: "#ffffff",
    },
  },
  DelButton: {
    border: "1px solid grey",
    color: "#8E8CAC",
    textTransform: "capitalize",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#6362ca",
      color: "#ffffff",
    },
    "&:focus": {
      backgroundColor: "#6362ca",
      color: "#ffffff",
    },
  },
  btnAlign: {
    display: "flex",
    marginLeft: "5px",
  },
  h5: {
    fontSize: 18,
    color: "#25233C",
    fontFamily: "Muli-SemiBold",
  },
  container: {
    // border:"1px solid grey",
    margin: "0 auto",
    paddingTop: 15,
    paddingBottom: 15,
    width: "100%",
  },
  Imginput: {
    display: "none",
  },
  UploadBtn: {
    padding: "10px 15px",
    fontSize: "14px",
    textTransform: "capitalize",
    marginBottom: "7px",
    background: "#8E8CAC",
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
  },
  radiobtn: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  navHead: {
    fontFamily: "Muli-Bold !important",
    marginBottom: "11px",
  },
}));
const AddPromotion = (props) => {
  const [startMinDate, setStartMinDate] = React.useState(new Date());
  let history = useHistory();
  const { slug } = useParams();
  const classes = useStyles();
  const [alert, setAlert] = useState(null);
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [isEdit, setIsEdit] = React.useState(false);
  const [optionData, setOptionData] = React.useState("5");
  const [startDateMin, setStartDateMin] = React.useState(
    formatDate(new Date())
  );
  const [startDateVal, setStartDateVal] = React.useState(null);
  const [endDateMin, setEndDateMin] = React.useState(formatDate(new Date()));
  const [startDateMax, setStartDateMax] = React.useState(null);
  const [EndDateVal, setEndDateVal] = React.useState(null);
  const [endDateMax, setEndDateMax] = React.useState(null);
  const [CKDesc, setCKDesc] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [descData, setDescData] = React.useState("");
  const ckConfig = {
    uiColor: "#5783b0",
    removeButtons: CkButton.join(","),
  };

  useEffect(() => {
    const abortCont = new AbortController();

    return () => abortCont.abort();
  }, []);

  function changeDescriptionText(e) {
    setCount(e.target.value.length);
    setDescData(e.target.value);
  }

  const handleSelectChange = (event) => {
    setOptionData(event.target.value);
  };

  function getToPrevious() {
    //history.goBack();
    props.simplifiedFunction();
    const { from } = {
      from: {
        pathname: `/app/service_providers/service_provider_detail/${slug}`,
        state: { refreshData: true },
      },
    };
    history.push(from);
  }

  const onHandleSelectChange = (event) => {
    setSelectedSP(event.target.value);
  };
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
  });

  function SweetAlertConfirm(status) {
    const getTrueAlert = () => (
      <SweetAlert success title="Woot!" onConfirm={() => setAlert(null)}>
        Service Activated Successfully
      </SweetAlert>
    );
    const getFalseAlert = () => (
      <SweetAlert success title="Woot!" onConfirm={() => setAlert(null)}>
        Service Deactivated Successfully
      </SweetAlert>
    );
    if (status == "true") setAlert(getTrueAlert());
    else {
      setAlert(getFalseAlert());
    }
  }

  function formatDate(d) {
    var month = "" + moment(d).format("MM"),
      day = "" + moment(d).format("DD"),
      year = moment(d).format("YYYY");

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function changeStartDate(e) {
    setEndDateMin(formatDate(moment(e.target.value)));
    setStartDateVal(formatDate(moment(e.target.value)));
    // setEndDateMin(formatDate(new Date(e.target.value)))
    // setStartDateVal(formatDate(new Date(e.target.value)))
  }

  function changeEndDate(e) {
    setStartDateMax(formatDate(moment(e.target.value)));
    setEndDateVal(formatDate(moment(e.target.value)));
  }

  function CKEditorChange(e) {
    setCKDesc(e.editor.getData());
  }

  function changeStatus(val) {
    let status = "";
    switch (val) {
      case true:
        status = "activateServices";
        break;
      default:
        status = "deactivateServices";
    }
    servicesandpackagesService
      .serviceStatusChange(status, props.editData._id)
      .then((res) => {
        SweetAlertConfirm(res.active);
      })
      .catch((err) => {});
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    changeStatus(event.target.checked);
  };
  function deleteService() {
    servicesandpackagesService
      .deleteServices(props.editData._id)
      .then((res) => {
        const { from } = {
          from: {
            pathname: "/app/product_services/services",
            state: { data: category },
          },
        };
        props.history.push(from);
      })
      .catch((err) => {});
  }
  return (
    <>
      <Paper elevation={0} className={classes.container}>
        <GridContainer style={{ width: "100%" }}>
          <GridItem xs={12} sm={12} md={8}>
            <div>
              <div className={classes.header}>
                <IconButton aria-label="edit" onClick={() => getToPrevious()}>
                  <ArrowBackIcon />
                </IconButton>
                <h4 className={classes.navHead}>Add New Promotion</h4>
              </div>

              <Formik
                initialValues={{
                  sp_id: slug,
                  promocode: "",
                  dicount_percentage: "",
                  start_date: "",
                  end_date: "",
                  per_user_limit: "",
                  total_limit: "",
                  description: "",
                  max_off: "",
                }}
                validationSchema={Yup.object().shape({
                  promocode: Yup.string().required("Promo code is required"),
                  // dicount_percentage: Yup.string().required('Dicount percentage is required'),
                  // start_date: Yup.string().required('Start date is required'),
                  // end_date: Yup.string().required('End date is required'),
                  // per_user_limit: Yup.string().required('Per user limit is required'),
                  // total_limit: Yup.string().required('Total limit is required')
                })}
                onSubmit={(
                  {
                    sp_id,
                    promocode,
                    dicount_percentage,
                    per_user_limit,
                    total_limit,
                    max_off,
                    description,
                  },
                  { setStatus, setSubmitting }
                ) => {
                  setStatus();
                  if (!isEdit) {
                    const obj = {
                      sp_id: sp_id,
                      promocode: promocode,
                      dicount_percentage: optionData,
                      start_date: startDateVal,
                      end_date: EndDateVal,
                      // "per_user_limit": per_user_limit,
                      // "total_limit": total_limit,
                      per_user_limit: 10,
                      total_limit: 100,
                      description: descData,
                      max_off: max_off,
                    };
                    userService.postSPPromotions(obj).then(
                      (res) => {
                        getToPrevious();
                      },
                      (error) => {
                        setSubmitting(false);
                        if (error.promocode?.length > 0) {
                          setStatus(error.promocode[0]);
                        } else if (error.start_date?.length > 0) {
                          setStatus(error.start_date[0]);
                        } else if (error.end_date?.length > 0) {
                          setStatus(error.end_date[0]);
                        } else {
                          setStatus("Server Error");
                        }
                      }
                    );
                  }
                  {
                    const editobj = {
                      id: props.editData ? props.editData._id : "",
                      name: name,
                      description: description,
                      category_id: category_id,
                      // ,"sp_id": selectedSP,
                      // "normal_price": normal_price
                    };
                    servicesandpackagesService.putServices(editobj).then(
                      (res) => {
                        const { from } = {
                          from: {
                            pathname: "/app/product_services/services",
                            state: { data: category },
                          },
                        };
                        props.history.push(from);
                      },
                      (error) => {
                        setSubmitting(false);
                        setStatus(error.name ? error.name[0] : "server error");
                      }
                    );
                  }
                }}
              >
                {({ errors, status, touched, isSubmitting }) => (
                  <Form>
                    {/* <CardHeader
                                    className={`${classes.cardHeader}`}
                                >
                                    <h4 className={`${classes.cardTitle} ${classes.h4} `}>Add Category</h4>
                                </CardHeader> */}

                    <GridContainer spacing={0}>
                      <CardBody className="px-0 pb-0">
                        <Grid item container spacing={0}>
                          <GridItem xs={12} sm={8} md={6}>
                            <div className="form-group">
                              <h5 className={classes.h5}>Promo Code</h5>
                              <Field
                                name="promocode"
                                id="promocode"
                                type="text"
                                placeholder="Enter promo code"
                                className={`${classes.emailInput} ${
                                  "form-control" +
                                  (errors.promocode && touched.promocode
                                    ? " is-invalid"
                                    : "")
                                }`}
                              />
                              <ErrorMessage
                                name="promocode"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={8} md={6}>
                            <div className="form-group">
                              <h5 className={classes.h5}>
                                Discount Percentage
                              </h5>
                              <select
                                name="dicount_percentage"
                                defaultValue={optionData}
                                id="dicount_percentage"
                                onChange={(e) => handleSelectChange(e)}
                                className={`${classes.emailInput} ${
                                  "form-control" +
                                  (errors.dicount_percentage &&
                                  touched.dicount_percentage
                                    ? " is-invalid"
                                    : "")
                                }`}
                              >
                                <option value={"5"}>5%</option>
                                <option value={"10"}>10%</option>
                                <option value={"15"}>15%</option>
                                <option value={"20"}>20%</option>
                                <option value={"25"}>25%</option>
                                <option value={"30"}>30%</option>
                                <option value={"40"}>40%</option>
                                <option value={"50"}>50%</option>
                              </select>
                              <ErrorMessage
                                name="dicount_percentage"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={8} md={6}>
                            <div className="form-group">
                              <h5 className={classes.h5}>Start Date</h5>
                              {/* <Field name="start_date"
                                                                min={startMinDate}
                                                                id="start_date"
                                                                placeholder="Enter start date"
                                                                type="date"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.start_date && touched.start_date ? ' is-invalid' : '')}`} />
                                                            */}
                              <TextField
                                id="start_date"
                                name="start_date"
                                type="date"
                                onChange={(e) => changeStartDate(e)}
                                inputProps={{
                                  min: startDateMin,
                                  max: startDateMax,
                                }}
                                className={`${classes.emailInput} ${
                                  "form-control" +
                                  (errors.start_date && touched.start_date
                                    ? " is-invalid"
                                    : "")
                                }`}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <ErrorMessage
                                name="start_date"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={8} md={6}>
                            <div className="form-group">
                              <h5 className={classes.h5}>End Date</h5>
                              {/* <Field name="end_date"
                                                                id="end_date"
                                                                placeholder="Enter end date"
                                                                type="date"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.end_date && touched.end_date ? ' is-invalid' : '')}`} /> */}
                              <TextField
                                id="end_date"
                                name="end_date"
                                type="date"
                                className={`${classes.emailInput} ${
                                  "form-control" +
                                  (errors.end_date && touched.end_date
                                    ? " is-invalid"
                                    : "")
                                }`}
                                onChange={(e) => changeEndDate(e)}
                                inputProps={{
                                  min: endDateMin,
                                  max: endDateMax,
                                }}
                              />
                              <ErrorMessage
                                name="end_date"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </GridItem>
                          {/* <GridItem xs={12} sm={8} md={6}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5}>Per user limit</h5>
                                                            <Field name="per_user_limit"
                                                                id="per_user_limit"
                                                                placeholder="Enter per user limit"
                                                                type="number"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.per_user_limit && touched.per_user_limit ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="per_user_limit" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem> */}
                          {/* <GridItem xs={12} sm={8} md={6}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5}>Total limit</h5>
                                                            <Field name="total_limit"
                                                                id="total_limit"
                                                                placeholder="Enter total limit"
                                                                type="number"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.total_limit && touched.total_limit ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="total_limit" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem> */}
                          <GridItem xs={12} sm={8} md={12}>
                            <div className="form-group">
                              <h5 className={classes.h5}>Description</h5>
                              {/* <Field name="description"
                                                                as="textarea"
                                                                id="description"
                                                                placeholder="Enter description"
                                                                type="text"
                                                                onChange={(e) => changeDescriptionText(e)}
                                                                className={`${classes.emailInput} ${'form-control'}`} /> */}
                              {/* <TextField
                                                                type="textarea"
                                                                name="description"
                                                                id="description"
                                                                type="text"
                                                                rows={5}
                                                                maxLength={850}
                                                                className={`${classes.emailInput} ${'form-control'}`}
                                                                onChange={e => setCount(e.target.value.length)}
                                                            /> */}
                              <textarea
                                name="description"
                                id="description"
                                type="text"
                                rows={5}
                                maxLength={850}
                                className={`${
                                  classes.emailInput
                                } ${"form-control"}`}
                                onChange={(e) => changeDescriptionText(e)}
                              />
                              <p style={{ float: "right" }}>({count}/850)</p>
                              {/* <CKEditor
                                                                name="description"
                                                                id="description"
                                                                config={ckConfig}
                                                                onChange={(e) => CKEditorChange(e)}
                                                                onInstanceReady={() => {

                                                                }}
                                                            /> */}
                            </div>
                          </GridItem>
                          {/* <GridItem xs={12} sm={8} md={6}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5}>Max off</h5>
                                                            <Field name="max_off"
                                                                id="max_off"
                                                                placeholder="Enter max off"
                                                                type="text"
                                                                className={`${classes.emailInput} ${'form-control'}`} />

                                                        </div>
                                                    </GridItem> */}
                        </Grid>
                      </CardBody>

                      <CardFooter className={classes.btnAlign}>
                        {/* <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button> */}
                        <Grid container>
                          <GridItem xs={6} sm={6} md={6}>
                            <Button
                              type="submit"
                              className={classes.SaveButton}
                              simple
                              size="sm"
                            >
                              Save
                            </Button>
                          </GridItem>
                          {isSubmitting && (
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          )}
                          {isEdit && (
                            <GridItem
                              xs={6}
                              sm={6}
                              md={6}
                              style={{ textAlign: "right" }}
                            >
                              <Button
                                type="button"
                                onClick={() => deleteService()}
                                className={classes.DelButton}
                                simple
                                size="sm"
                              >
                                Delete
                              </Button>
                            </GridItem>
                          )}
                        </Grid>
                        <br />
                      </CardFooter>
                      <div className="row">
                        <div className="col-md-12">
                          {status && (
                            <div className={"alert alert-danger"}>{status}</div>
                          )}
                        </div>
                      </div>
                    </GridContainer>
                  </Form>
                )}
              </Formik>
            </div>
          </GridItem>
        </GridContainer>
      </Paper>
      {alert}
    </>
  );
};

export default AddPromotion;

export const CkButton = [
  "Save",
  "NewPage",
  "DocProps",
  "Preview",
  "Print",
  "Export to PDF",
  "Textarea",
  "Templates",
  "document",
  "Cut",
  "Copy",
  "Paste",
  "PasteText",
  "PasteFromWord",
  "Undo",
  "Redo",
  "Find",
  "Replace",
  "SelectAll",
  "Scayt",
  "Form",
  "Checkbox",
  "Radio",
  "TextField",
  "Select",
  "Button",
  "ImageButton",
  "HiddenField",
  "Strike",
  "Subscript",
  "Superscript",
  "RemoveFormat",
  "NumberedList",
  "BulletedList",
  "Outdent",
  "Indent",
  "Blockquote",
  "CreateDiv",
  "JustifyLeft",
  "JustifyCenter",
  "JustifyRight",
  "JustifyBlock",
  "BidiLtr",
  "BidiRtl",
  "Anchor",
  "CreatePlaceholder",
  "Image",
  "Flash",
  "Table",
  "HorizontalRule",
  "Smiley",
  "SpecialChar",
  "PageBreak",
  "Iframe",
  "InsertPre",
  "Styles",
  "Format",
  "Font",
  "FontSize",
  "TextColor",
  "BGColor",
  "UIColor",
  "Maximize",
  "ShowBlocks",
  "button1",
  "button2",
  "button3",
  "oembed",
  "MediaEmbed",
  "About",
];
