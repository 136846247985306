import { Avatar, Button, Grid, Icon, IconButton, Link, Typography, Box, Paper, TextField } from '@material-ui/core';
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { userService } from "_services/user.service";
// import blueTick from "../../../assets/img/blueTick.png";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';


const useStyles = makeStyles((theme) => ({

    root: {
        "& .MuiStepIcon-root.MuiStepIcon-completed": {
            color: "#00D84F"
        },
        "& .MuiStepIcon-root.MuiStepIcon-active": {
            color: "#FFC02C"
        }
    },
    button: {
        margin: theme.spacing(1),
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "#efefef",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
    },
    formControl: {
        minWidth: '100%',
        marginBottom: 15
    },
    marginTop50px: {
        marginTop: "50px"
    },
    h5: {
        color: "#25233C",
        fontSize: 18,
    },
    selectadj: {
        border: "1px solid #B9B8C4",
        height: 50,
        padding: 15,
        borderRadius: 5
    },
    formContainer: {
        width: "100%",
        height: "98vh",
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    form: {
        minHeight: "100%",
        padding: "16px",
        // margin:"20px 0"
    },
    boxStyle: {
        marginTop: "30px",
    },
    displayFlexJustifyCenter: {
        display: "flex",
        justifyContent: "center"
    },
    marginBottom24: {
        marginBottom: "24px"
    },
    minPaymentSection: {
        display: "flex",
        justifyContent: "space-around",
        width: "65%",
        alignItems: "center",
        marginTop: "30px"
    },
    width44: {
        width: "44%"
    },
    width114px: {
        width: "114px"
    },
    buttonStyle: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "30px",
        width: "18%"
    },
    displayFlex: {
        display: "flex"
    },
    textCenterFontBold: {
        textAlign: "center",
        fontWeight: "bold"
    },
    colorTimingFont12: {
        color: "#005CB2",
        fontSize: "12px"
    },
    buttonStyleContained: {
        backgroundColor: '#DB0D0D',
        width: '25%',
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '10px',
        border: '1px solid #DB0D0D',
        "&:hover": {
            background: "#fff",
            border: "1px solid #DB0D0D",
            color: "#DB0D0D"
        },
        "@media (max-width:600px)": {
            width: '45%'
        }
    },
}));
const stepsL = [
    {
        label: 'Account Setup Complete'
    },
    {
        label: 'Business Profile Setup'
    },
    {
        label: 'Services Setup',
    }
];





const CompletedAccount = ({
    handleNext
}) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(1);

    return <div>
        <Box className={`${classes.marginBottom24}`} style={{ marginTop: "30px" }} >
            <h1 style={{ textAlign: "center", color: "#DB0D0D", fontSize: "45px", marginBottom: "20px", fontWeight: "bold" }}>
                Great!
            </h1>
            <p style={{ textAlign: "center" }}>
                Your Account has been successfully created.
            </p>
            <p style={{ textAlign: "center" }}>
                Let's quickly setup your Business Profile.
            </p>
        </Box >
        <Box>
            <Stepper style={{ display: "flex", alignItems: "center" }} activeStep={activeStep} orientation="vertical">
                {stepsL.map((step, index) => (
                    <Step key={index}>
                        <StepLabel className={classes.root}>
                            {step.label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
        <Box className={`${classes.displayFlexJustifyCenter} ${classes.marginTop50px}`}>
            <Button className={classes.buttonStyleContained} variant="contained" onClick={handleNext}>
                Let's Go
            </Button>
        </Box>

    </div>

}

export default CompletedAccount;