import {
  Avatar,
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import ApplyBackDrop from "../common/ApplyBackDrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Divider } from "@material-ui/core";
import React from "react";
import RemoveIcon from "@material-ui/icons/Remove";
import { SPService } from "_services/serviceprovider.service";
import { renderText } from "../common/DisplayComponent";
import { useParams } from "react-router-dom";
import { userService } from "_services/user.service";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8cac",
    "&:hover": {
      background: "#efefef",
      boxShadow: "unset",
      textDecoration: "none",
    },
  },
  formControl: {
    minWidth: "100%",
    marginBottom: 15,
  },
  h5: {
    color: "#25233C",
    fontSize: 18,
  },
  selectadj: {
    border: "1px solid #B9B8C4",
    height: 50,
    padding: 15,
    borderRadius: 5,
  },
  formContainer: {
    width: "100%",
    height: "98vh",
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    minHeight: "350px",
    padding: "16px",
  },
  boxStyle: {
    marginTop: "30px",
    "@media(max-width:600px)": {
      padding: "0px 10px",
    },
  },
  displayFlexJustifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  marginBottom24: {
    marginBottom: "24px",
  },
  minPaymentSection: {
    display: "flex",
    justifyContent: "space-around",
    width: "65%",
    alignItems: "center",
    marginTop: "30px",
  },
  width44: {
    width: "44%",
  },
  buttonStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    width: "100%",
    "@media (max-width:635px)": {
      width: "30%",
    },
    "@media (max-width:590px)": {
      width: "35%",
    },
    "@media (max-width:510px)": {
      width: "40%",
    },
    "@media (max-width:460px)": {
      width: "50%",
    },
    "@media (max-width:380px)": {
      width: "60%",
    },
  },
  Imginput: {
    display: "none",
  },
  displayFlex: {
    display: "flex",
  },
  dividerStyle: {
    border: "none",
    borderTop: "1px dashed black",
    color: "#fff",
    backgroundColor: "#fff",
    height: "1px",
  },
  buttonStyleContained: {
    marginLeft: 10,
    backgroundColor: "#DB0D0D",
    color: "white",
    fontWeight: "bold",
    borderRadius: "10px",
    border: "1px solid #DB0D0D",
    "&:hover": {
      background: "#fff",
      border: "1px solid #DB0D0D",
      color: "#DB0D0D",
    },
  },
  outlinedButtonStyle: {
    color: "#fff",
    borderColor: "#DB0D0D",
    backgroundColor: "#DB0D0D",
    borderRadius: "10px",
    "&:hover": {
      color: "#DB0D0D",
    },
  },
  removeIcon: {
    "@media(max-width: 600px)": {
      right: "0px !important",
    },
  },
  roots: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 10,
    },
  },
}));

const Step4 = ({
  previousWork,
  handleOnChangePreviousWork,
  handleMoreField,
  handlePrev,
  handlePreviousSave,
  removePrevWorkImage,
  apiCall,
  setPreviousWork,
  removePrevWork,
  uploadLoader,
  uploadLoaderIndex,
}) => {
  const classes = useStyles();
  const { slug } = useParams();
  const [apiCallInner, setApiCallInner] = React.useState(false);

  // get images uploaded by service provider ******************************************************************************************************************************************************

  const getPreviousWorkOfSp = async () => {
    setApiCallInner(true);
    const responsePreviousWork = await SPService.getPreviousWork(
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == "newSp"
        ? JSON.parse(sessionStorage.getItem("currentUser")).user.sp_id
        : slug
    );
    if (responsePreviousWork.length > 0) {
      let arr = [];
      responsePreviousWork.map((prop) => {
        let obj = {
          id: prop._id,
          title: prop.title,
          description: prop.description,
          desLength: prop.description.length,
          image: prop.url ? prop.url : [],
          imageName: prop.url ? prop.url : [],
          titleLength: prop.title.length,
          fullUrl: [],
        };
        arr.push(obj);
      });
      setPreviousWork([...arr]);
      setApiCallInner(false);
    } else {
      setApiCallInner(false);
    }
  };

  React.useEffect(() => {
    getPreviousWorkOfSp();
  }, []);

  return (
    <div>
      <Box className={classes.marginBottom24}>
        {renderText({ label: "Previous Work" })}
      </Box>

      {previousWork.map((inputField, index) => (
        <Box
          key={index}
          style={{
            borderRadius: "8px",
            position: "relative",
          }}
        >
          {previousWork.length > 1 && (
            <div
              onClick={() => {
                removePrevWork(index, inputField?.id);
              }}
              style={{
                height: "20px",
                width: "20px",
                background: "red",
                borderRadius: "40px",
                position: "absolute",
                top: "-20px",
                right: "-10px",
              }}
              className={classes.removeIcon}
            >
              <RemoveIcon
                style={{ width: "20px", color: "white", paddingBottom: "4px" }}
              />
            </div>
          )}

          <Box className={classes.boxStyle}>
            <TextField
              variant="outlined"
              placeholder="Title"
              fullWidth={true}
              inputProps={{ maxLength: 50 }}
              size="small"
              name="title"
              value={inputField.title}
              onChange={(event) => handleOnChangePreviousWork(index, event)}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              className={classes.roots}
            />
          </Box>
          {inputField.titleLength ? (
            <p
              style={{
                color: "grey",
                marginTop: "10px",
                textAlign: "right",
                marginBottom: "0px",
                paddingRight: "10px",
              }}
            >
              {inputField.titleLength}/50
            </p>
          ) : (
            <p
              style={{
                color: "grey",
                marginTop: "10px",
                textAlign: "right",
                marginBottom: "0px",
                paddingRight: "10px",
              }}
            >
              0/50
            </p>
          )}

          <Box className={classes.boxStyle}>
            <TextField
              id="standard-multiline-static"
              multiline
              rows={3}
              inputProps={{ maxLength: 750 }}
              placeholder="Description"
              variant="outlined"
              fullWidth={true}
              size="small"
              name="description"
              value={inputField.description}
              onChange={(event) => handleOnChangePreviousWork(index, event)}
              InputLabelProps={{ shrink: true }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              className={classes.roots}
            />
          </Box>
          <Box>
            {inputField.desLength ? (
              <p
                style={{
                  color: "grey",
                  marginTop: "10px",
                  textAlign: "right",
                  marginBottom: "0px",
                  paddingRight: "10px",
                }}
              >
                {inputField.desLength}/750
              </p>
            ) : (
              <p
                style={{
                  color: "grey",
                  marginTop: "10px",
                  textAlign: "right",
                  marginBottom: "0px",
                  paddingRight: "10px",
                }}
              >
                0/750
              </p>
            )}
          </Box>
          <Box
            className={`${classes.boxStyle} ${classes.displayFlex}`}
            style={{ marginTop: "0px" }}
          >
            <Box style={{ marginRight: "20px" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {inputField.imageName.length > 0 &&
                  inputField.imageName.map((prop, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          backgroundImage: `url(${prop})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                          width: "70px",
                          height: "70px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          borderRadius: "8px",
                          position: "relative",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          onClick={() => {
                            removePrevWorkImage(index, i);
                          }}
                          style={{
                            height: "20px",
                            width: "20px",
                            background: "red",
                            borderRadius: "40px",
                            position: "absolute",
                            top: "0",
                            right: "-10px",
                            cursor: "pointer",
                          }}
                        >
                          <RemoveIcon
                            style={{
                              width: "20px",
                              color: "white",
                              paddingBottom: "4px",
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                <Box style={{ display: "flex", alignItems: "center" }}>
                  {uploadLoader === true ? (
                    ""
                  ) : (
                    <input
                      accept="image/*"
                      className={classes.Imginput}
                      id={`contained-button-file${index}`}
                      name={`contained-button-file${index}`}
                      onChange={(event) =>
                        handleOnChangePreviousWork(index, event)
                      }
                      type="file"
                      multiple={true}
                    />
                  )}
                  <label htmlFor={`contained-button-file${index}`}>
                    {inputField.imageName.length < 5 ? (
                      <Button
                        variant="contained"
                        className={classes.buttonStyleContained}
                        component="span"
                        style={{ marginTop: "0px", marginTop: "10px" }}
                      >
                        {uploadLoader === true &&
                        index === uploadLoaderIndex ? (
                          <CircularProgress sx={{ color: "#E4E4E4" }} />
                        ) : (
                          "Upload Image"
                        )}
                      </Button>
                    ) : (
                      ""
                    )}
                  </label>
                </Box>
              </div>
            </Box>
          </Box>

          {previousWork.length > 1 ? (
            <Box className={classes.boxStyle}>
              <Divider className={classes.dividerStyle} />
            </Box>
          ) : (
            ""
          )}
        </Box>
      ))}

      {previousWork.length > 0 && previousWork.length < 20 ? (
        <Box
          className={classes.buttonStyle}
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Button
            variant="outlined"
            className={classes.outlinedButtonStyle}
            onClick={handleMoreField}
          >
            Add More
          </Button>
        </Box>
      ) : (
        ""
      )}

      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Box className={classes.buttonStyle}>
          <Button
            variant="contained"
            className={classes.buttonStyleContained}
            onClick={handlePrev}
          >
            Prev
          </Button>
          <Button
            variant="contained"
            className={classes.buttonStyleContained}
            onClick={handlePreviousSave}
          >
            Next
          </Button>
          {<ApplyBackDrop apiCall={apiCall} />}
          {apiCallInner == true && <ApplyBackDrop apiCall={true} />}
        </Box>
      </Grid>
    </div>
  );
};

export default Step4;
