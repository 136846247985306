import React, { useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { Container, Divider } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from "components/CustomButtons/Button.js";
import CloudDownload from '@material-ui/icons/CloudDownload';
import Chart from "./Chart";
import RevenueList from "./RevenueList";
import { CSVLink } from "react-csv";
import {bookingAndRevenueService} from "_services/bookingAndRevenue.service";
import CustomDateSelection from "components/CustomDateSelection/CustomDateSelection";
import downloadIcon from "../../assets/img/fat-arrow.svg";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((styles) => ({
  revenueCards:{
    marginBottom: "60px",
    // marginTop: "-25px",
    "@media (max-width: 576px)":{
      marginTop: 0,
      
    }
  },
  revenueCard:{
    marginTop: 0,
    "@media(max-width: 576px)":{
      marginBottom: "16px",
    }
  },
  root: {
    "& .MuiAppBar-root": {
      width: '100%',
      display: 'flex',
    }
  },
  appbarAdj: {
    background: 'transparent',
    boxShadow: 'none',
    position: "absolute",
    top: "-5pc",
  },
  button: {
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8d9d",
  },
  tabs: {
    margin: "0px 10px 0px 10px",
    background: "#EAEDF7",
    borderRadius: "5px",
    paddingLeft: 10
    // "&.MuiTabs-indicator": {
    //   display: "none"
    // }
  },
  tab: {
    "&.MuiButtonBase-root.MuiTab-root": {
      height: "55px",
      padding: "unset",
      minWidth: "72px",
      textTransform: "capitalize",
      fontWeight: "400",
    }
  },
  padding0:{
    "& .MuiBox-root":{
      padding:'0'
    },
  },
  displayFlexPaddingRight32:{
    display:'flex',
    paddingRight:'32px'
  },
  bookingButton:
  {
    paddingLeft:'0px !important',
    backgroundColor:'red',
  },
  bookingTexts:
  {
    marginLeft:'-15px',
    '@media(max-width:599px)': {
      marginLeft:'0px !important',
    }
  }
}));

// const useStyles = makeStyles(styles);


const headersCsv= [
  { label: "Service Provider Name", key: "spName"},
  { label: "Client Name", key: "clientNAme" },
  { label: "Service Provider Id", key: "spId" },
  { label: "Client Id", key: "clientId" },
  {label:"Location",key:"location"},
  { label: "Services Booked", key: "servicesBooked" },
  { label: "Revenue", key: "revenue"},

];


/// ******************** section for Chart ************************ ///


export default function Revenue(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [csvDowloadData,setCsvDownloadData]=React.useState(null);
  
  const [csvDowloadDataForCustom,setCsvDownloadDataForCustom]=React.useState(null);

  const [showCustom,setShowCustom]=React.useState(false);

  const [apiTaskCompleted,setApiTaskCompleted]=React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [bookingAndRevenueDataTotal,setBookingAndRevenueDataTotal]=React.useState({
    'daily':{
      booking:'',
      revenue:''
    },
    'weekly':{
      booking:'',
      revenue:''
    },
    'monthly':{
      booking:'',
      revenue:''
    }
   
  });
  const [revenueListData,setRevenueListData]=React.useState({
    "daily":[],
    "weekly":[],
    "monthly":[],
    "last_month":[],
    "custom":[]
  })

  const [revenueGraph,setRevenueGraph]=React.useState({
    "daily":{
      labels:[],
      datasets:[],
      maxValue:''
    },
    "weekly":{
      labels:[],
      datasets:[],
      maxValue:''
    },
    "monthly":{
      labels:[],
      datasets:[],
      maxValue:''
    },
    "last_month":{
      labels:[],
      datasets:[],
      maxValue:''
    }
  })

  const [totalRevenueInRevenueList,setTotalRevenueInRevenueList]=React.useState({
    "daily":"",
    "weekly":"",
    "monthly":"",
    "last_month":"",
    "custom":""
  })


  const dalWeekMonth=['daily','weekly','monthly'];
  const dalWeekMonthForRevenueList=['daily','weekly','monthly',"last_month"];  

  
  // csvData set Function *********************************************************************************************************

  function setCsvData(checkParameter){
  
  if(checkParameter=="daily"){
    setShowCustom(false);
    if(revenueListData.daily.length>0){
      const csvReport2={
        data:revenueListData.daily,
        headers: headersCsv,
        filename: 'ReportDaily.csv'
      }
      setCsvDownloadData(csvReport2);
    }
    else{
      setCsvDownloadData(null);
    }
  }
  else if(checkParameter=="weekly"){
    setShowCustom(false);
    if(revenueListData.weekly.length>0){
      const csvReport2={
        data:revenueListData.weekly,
        headers: headersCsv,
        filename: 'ReportWeekly.csv'
      }
      setCsvDownloadData(csvReport2);
    }
    else{
      setCsvDownloadData(null);
    }
  }
  else if(checkParameter=="monthly"){
    setShowCustom(false);
    if(revenueListData.monthly.length>0){
      const csvReport2={
        data:revenueListData.monthly,
        headers: headersCsv,
        filename: 'ReportMonthly.csv'
      }
      setCsvDownloadData(csvReport2);
    }
    else{
      setCsvDownloadData(null);
    }
  }
  else if(checkParameter=="lastMonth"){
    setShowCustom(false);
    if(revenueListData.last_month.length>0){
      const csvReport2={
        data:revenueListData.last_month,
        headers: headersCsv,
        filename: 'ReportLastMonth.csv'
      }
      setCsvDownloadData(csvReport2);
    }
    else{
      // console.log('hello');
      setCsvDownloadData(null);
    }
  }

  else if(checkParameter=="custom"){
    setShowCustom(true);
    setCsvData(null);
    if(revenueListData.custom.length>0){
      const csvReport2={
        data:revenueListData.custom,
        headers: headersCsv,
        filename: 'ReportCustom.csv'
      }
      setCsvDownloadDataForCustom({...csvReport2});
    }
    else{
      // console.log('hello');
      setCsvDownloadDataForCustom(null);
    }
  }
  
}


// *********************************************************************************************************

  React.useEffect(()=>{
    
    // gets total revenue day wise

    Promise.all(
    dalWeekMonth.map(async(prop)=>{
      const response=await bookingAndRevenueService.getRevenueDayWise({type:`${prop}`});
         setBookingAndRevenueDataTotal(prevState=>
          {
            return {
            ...prevState,
            [prop]:{booking:response.booking,revenue:response.revenue}
          }
        }
        );
    })
    )

    // details of booking day wise

    Promise.all(
      dalWeekMonthForRevenueList.map(async(prop)=>{
        setApiTaskCompleted(true)
          const responseForRevenueList= await bookingAndRevenueService.getDetailsOfBookingDayWise({type:`${prop}`});
          // console.log("LIST: ", responseForRevenueList)
            let array=[];
            if(responseForRevenueList && responseForRevenueList.data){
              
              responseForRevenueList.data.map((data,index)=>{
                
                let servicesBooked=[];
                
                data.packages_booked?.map((eachPackage)=>{
                  servicesBooked.push(eachPackage)
                })
                
                data.service_booked?.map((eachPackage)=>{
                  servicesBooked.push(eachPackage)
                })

                let obj={
                         spName:data.sp.name,
                         clientNAme:data.client.name,
                         spId:data.sp.id,
                         clientId:data.client.id,
                         location:data.location.zipcode,
                         servicesBooked:servicesBooked.join(', '),
                         revenue:data.revenue,
                         bookingDate: data.booking_date 
                        }
                array.push(obj);
              })
              if(array.length>0){
                setRevenueListData(prevState=>{
                  return {
                    ...prevState,
                    [prop]:array
                  }
                })
  
                setTotalRevenueInRevenueList(prevState=>{
                  return {
                    ...prevState,
                    [prop]:responseForRevenueList.total_revenue
                  }
                })
              }
            }
            setApiTaskCompleted(false) 
        })
    )

    
 },[]);       


 React.useEffect(()=>{
  
  dalWeekMonthForRevenueList.map(async(prop,index)=>{
    const responseRevenueGraph= await bookingAndRevenueService.getBookingGraph({"type":`${prop}`});
    if(responseRevenueGraph.message){
    }
    else{
      let dataSets=[];
      let labels=[];
      let incCounter=1;
      for(let i in responseRevenueGraph){
        labels.push(incCounter.toString());
       let sum= responseRevenueGraph[i].reduce((a, b) => {
         return a + b;
       });
       dataSets.push(sum)
        incCounter++ ;
      }
      setRevenueGraph(
       prevState=>{
         return {
           ...prevState,
           [`${prop}`]:
           {
             labels:[...labels],
             datasets:[...dataSets],
             maxValue:Math.max(...dataSets)
           }
         }
       }
     )
    }
  })
 

 },[])

 // chart data for daily *******************************************************************************************************************************

 const dataForGraphDaily= {
  labels: revenueGraph.daily.labels,
  datasets: [
    {
      label: "",
      data: revenueGraph.daily.datasets,
      fill: true,
      borderColor: 'rgb(128, 0, 255, 0.5)',
      backgroundColor: 'rgb(128, 0, 255, 0.3)',
    }
  ],
};

const optionsForDaily = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    }
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: true,
        borderColor: 'rgb(128, 0, 255, 0.3)',
      },
      grid: {
        color: 'rgb(128, 0, 255, 0.1)',
      },
    },
    y: {
      max: parseInt(revenueGraph.daily.maxValue ? revenueGraph.daily.maxValue : 20) + 1000,
      ticks: {
        beginAtZero: true,
        borderColor: 'rgba(2, 255, 255, 1)',
      },
      grid: {
        color: 'rgb(128, 0, 255, 0.1)',
      }
    },
  },
};


 //  *******************************************************************************************************************************

 // chart data for weekly *******************************************************************************************************************************

const dataForGraphWeekly= {
  labels: revenueGraph.weekly.labels,
  datasets: [
    {
      label: "",
      data: revenueGraph.weekly.datasets,
      fill: true,
      borderColor: 'rgb(128, 0, 255, 0.5)',
      backgroundColor: 'rgb(128, 0, 255, 0.3)',
    }
  ],
};

const optionsForWeekly = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    }
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: true,
        borderColor: 'rgb(128, 0, 255, 0.3)',
      },
      grid: {
        color: 'rgb(128, 0, 255, 0.1)',
      },
    },
    y: {
      max: parseInt(revenueGraph.weekly.maxValue ? revenueGraph.weekly.maxValue : 20) + 1000,
      ticks: {
        beginAtZero: true,
        borderColor: 'rgba(2, 255, 255, 1)',
      },
      grid: {
        color: 'rgb(128, 0, 255, 0.1)',
      }
    },
  },
};

 //  *******************************************************************************************************************************


  // chart data for monthly *******************************************************************************************************************************
  
const dataForGraphMonthly= {
  labels: revenueGraph.monthly.labels,
  datasets: [
    {
      label: "",
      data: revenueGraph.monthly.datasets,
      fill: true,
      borderColor: 'rgb(128, 0, 255, 0.5)',
      backgroundColor: 'rgb(128, 0, 255, 0.3)',
    }
  ],
};

const optionsForMonthly = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    }
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: true,
        borderColor: 'rgb(128, 0, 255, 0.3)',
      },
      grid: {
        color: 'rgb(128, 0, 255, 0.1)',
      },
    },
    y: {
      max: parseInt(revenueGraph.monthly.maxValue ? revenueGraph.monthly.maxValue : 20) + 1000,
      ticks: {
        beginAtZero: true,
        borderColor: 'rgba(2, 255, 255, 1)',
      },
      grid: {
        color: 'rgb(128, 0, 255, 0.1)',
      }
    },
  },
};

 //  *******************************************************************************************************************************

  // chart data for last_month *******************************************************************************************************************************

const dataForGraphLastMonth= {
  labels: revenueGraph.last_month.labels,
  datasets: [
    {
      label: "",
      data: revenueGraph.last_month.datasets,
      fill: true,
      borderColor: 'rgb(128, 0, 255, 0.5)',
      backgroundColor: 'rgb(128, 0, 255, 0.3)',
    }
  ],
};


const optionsForLastMonth = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    }
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: true,
        borderColor: 'rgb(128, 0, 255, 0.3)',
      },
      grid: {
        color: 'rgb(128, 0, 255, 0.1)',
      },
    },
    y: {
      max: parseInt(revenueGraph.last_month.maxValue ? revenueGraph.last_month.maxValue : 20) + 1000,
      ticks: {
        beginAtZero: true,
        borderColor: 'rgba(2, 255, 255, 1)',
      },
      grid: {
        color: 'rgb(128, 0, 255, 0.1)',
      }
    },
  },
};

//  *******************************************************************************************************************************


 React.useEffect(()=>{
// console.log(revenueGraph);
 },[revenueGraph])

 React.useEffect(()=>{
   if(revenueListData.daily.length > 0){
    const csvReport2={
      data:revenueListData.daily,
      headers: headersCsv,
      filename: 'ReportDaily.csv'
    }
    setCsvDownloadData(csvReport2);
   }
   else{
     setCsvDownloadData(null);
   }
 },[totalRevenueInRevenueList])


 React.useEffect(()=>{
  if(revenueListData.custom.length>0){
   setCsvData('custom');
  }
 },[revenueListData.custom])


 // function callback for custom search , details of booking day wise

 const callbackFuncForCustom=async(fromDate,toDate)=>{
   const obj={
     type:"custom",
     from_date:fromDate,
     to_date:toDate
   }
   setApiTaskCompleted(true);

   const responseOfCustom = await bookingAndRevenueService.getDetailsOfBookingDayWise(obj);

   if(responseOfCustom && responseOfCustom.data){
     let array=[];
    responseOfCustom.data.map((data,index)=>{
      let obj={
        spName:data.sp.name,
        clientNAme:data.client.name,
        spId:data.sp.id,
        clientId:data.client.id,
        location:'abc',
        servicesBooked:data.service_booked ? data.service_booked.join(', '):'service type not selected',
        revenue:data.revenue
       }
      array.push(obj);
    })
    if(array.length>0){
      setRevenueListData(prevState=>{
        return {
          ...prevState,
          ['custom']:array
        }
      })
      setTotalRevenueInRevenueList(prevState=>{
        return {
          ...prevState,
          ['custom']:responseOfCustom.total_revenue
        }
      })
    }
    
  }
  else if(!responseOfCustom.data){
    setRevenueListData(prevState=>{
      return {
        ...prevState,
        ['custom']:[]
      }
    })
    
    setCsvDownloadDataForCustom(null);
  }
  setApiTaskCompleted(false);
 }


  return (
    <>
    { window.location.href.split('/')[window.location.href.split('/').length - 1] == 'revenue' &&
      <div className={classes.revenueCards}>
        <GridContainer>
          <GridItem id="bookingText" className={classes.bookingButton}>
            <h4 style={{fontSize: '20px', color:'#25233C',fontFamily:'Muli-Bold'}} className={classes.bookingTexts}>Bookings</h4></GridItem>
          </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3} id="bookingStyles">
            <Card className={classes.revenueCard}>
              <CardHeader>
                <p className={classes.cardCategory} style={{marginBottom: "5px",color:'#25233C',fontSize:'15px',fontFamily:'Muli-SemiBold'}}>Daily Bookings</p>
                <h2 className={classes.cardTitle} style={{marginBottom: "0"}}>
                  <b style={{color:'#25233C',fontSize:'35px',fontFamily:'Muli-Bold'}}>{bookingAndRevenueDataTotal.daily.booking}</b>
                </h2>
              </CardHeader>
              <CardFooter stats style={{ display: 'block', marginTop: '0' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{marginBottom: "0",color:'#6B6987',fontSize:15,fontFamily:'Muli-SemiBold'}}>
                    Daily Revenue(c)
                  </p>
                  <h5 style={{marginBottom: "0",color:'#6B6987',fontSize:'25px',fontFamily:'Muli-Bold'}}>
                    {bookingAndRevenueDataTotal.daily.revenue}
                  </h5>
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3} id="bookingStyles">
            <Card className={classes.revenueCard}>
              <CardHeader>
                <p style={{marginBottom: "5px",color:'#25233C',fontSize:'15px',fontFamily:'Muli-SemiBold'}}>Weekly Bookings</p>
                <h2 className={classes.cardTitle} style={{marginBottom: "0"}}>
                  <b style={{color:'#25233C',fontSize:'35px',fontFamily:'Muli-Bold'}}>{bookingAndRevenueDataTotal.weekly.booking}</b>
                </h2>
              </CardHeader>
              <CardFooter stats style={{ display: 'block', marginTop: '0' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{marginBottom: "0",color:'#6B6987',fontSize:15,fontFamily:'Muli-SemiBold'}}>
                    Weekly Revenue(c)
                  </p>
                  <h5 style={{marginBottom: "0",color:'#6B6987',fontSize:'25px',fontFamily:'Muli-Bold'}}>
                    {bookingAndRevenueDataTotal.weekly.revenue}
                  </h5>
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3} id="bookingStyles">
            <Card className={classes.revenueCard}>
              <CardHeader>
                <p className={classes.cardCategory} style={{marginBottom: "5px",color:'#25233C',fontSize:'15px',fontFamily:'Muli-SemiBold'}}>Monthly Bookings</p>
                <h2 className={classes.cardTitle} style={{marginBottom: "0"}}>
                  <b style={{color:'#25233C',fontSize:'35px',fontFamily:'Muli-Bold'}}>{bookingAndRevenueDataTotal.monthly.booking}</b>
                </h2>
              </CardHeader>
              <CardFooter stats style={{ display: 'block', marginTop: '0' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{marginBottom: "0",color:'#6B6987',fontSize:15,fontFamily:'Muli-SemiBold'}}>
                    Monthly Revenue(c)
                  </p>
                  <h5 style={{marginBottom: "0",color:'#6B6987',fontSize:'25px',fontFamily:'Muli-Bold'}}>
                    {
                    bookingAndRevenueDataTotal.monthly.revenue
                    }
                  </h5>
                </div>
              </CardFooter>
            </Card>
          </GridItem>

        </GridContainer>
        <Divider />

        <div>
          <div className={`container-fluid position-relative ${classes.padding0}`} style={{ top: '100px'}} >
            <AppBar elevation={0} position="static" color="default" className={`${classes.appbarAdj}`} style={{ flexDirection: "row", justifyContent: 'space-between', alignItems: 'center',overflow:'auto',paddingBottom:'10px' }} >
              <h4 style={{fontSize: '20px', color:'#25233C', marginLeft: 5,fontFamily:'Muli-Bold'}} id="Revenue"> Revenue Generated </h4>
              <div className={classes.displayFlexPaddingRight32}>
                <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label="simple tabs example" TabIndicatorProps={{ style: { backgroundColor: "#6259ca" } }} textColor="primary">
                  <Tab className={classes.tab} label="Today" {...a11yProps(0)}   onClick={()=>{setCsvData('daily')}}/>
                  <Tab className={classes.tab} label="Weekly" {...a11yProps(1)}   onClick={()=>{setCsvData('weekly')}}/>
                  <Tab className={classes.tab} label="Monthly" {...a11yProps(2)}   onClick={()=>{setCsvData('monthly')}} />
                  <Tab className={classes.tab} label="Last Month" {...a11yProps(3)}   onClick={()=>{setCsvData('lastMonth')}} />
                  <Tab className={classes.tab} label="custom" {...a11yProps(4)}  onClick={()=>{setCsvData('custom')}} />
                        new
                    </Tabs>
                    {
                      csvDowloadData && showCustom==false &&
                   <CSVLink {...csvDowloadData}>  
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<img style={{width: "15px", height: "16.15px"}} src={downloadIcon} />}
                >
                 Download <span style={{textTransform: "uppercase", paddingLeft: "5px"}}>csv</span>
                 
            </Button>
            </CSVLink>
}
{
                      csvDowloadDataForCustom && showCustom==true &&
                   <CSVLink {...csvDowloadDataForCustom}>  
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<img style={{width: "15px", height: "16.15px"}} src={downloadIcon} />}
                >
                 Download <span style={{textTransform: "uppercase", paddingLeft: "5px"}}>csv</span>
                 
            </Button>
            </CSVLink>
}
              </div>

            </AppBar>
            <TabPanel value={value} index={0}> 
              <GridContainer >{
                revenueListData.daily.length > 0 && apiTaskCompleted==false &&
                <>
                <Chart data={dataForGraphDaily} options={optionsForDaily} />
                <RevenueList {...props} data={revenueListData.daily} totalRevenue={totalRevenueInRevenueList.daily} />
                </>    
}
       {
         revenueListData.daily.length == 0 && apiTaskCompleted==false &&
         <Card style={{padding:20, marginTop: 30}}>
                  <h6 style={{textAlign:'center',color: '#6B6987'}}>No record Found</h6> 
         </Card>
       }
       { apiTaskCompleted==true &&
         <div style={{display:"flex",justifyContent:'center',marginTop:20,width:'100%'}}>
         <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
         </div>
       }
                
              </GridContainer>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <GridContainer>
                { 
                revenueListData.weekly.length > 0 &&
                <>
              <Chart data={dataForGraphWeekly} options={optionsForWeekly} />
              <RevenueList {...props} data={revenueListData.weekly} totalRevenue={totalRevenueInRevenueList.weekly} />
              </>
                }
                {
         revenueListData.weekly.length == 0 && apiTaskCompleted==false &&
         <Card style={{padding:20, marginTop: 30}}>
                  <h6 style={{textAlign:'center',color: '#6B6987'}}>No record Found</h6> 
         </Card>
       }
              </GridContainer>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <GridContainer>
                {
                  revenueListData.monthly.length > 0 &&
                  <>
                    <Chart data={dataForGraphMonthly} options={optionsForMonthly} />
                    <RevenueList {...props} data={revenueListData.monthly} totalRevenue={totalRevenueInRevenueList.monthly} />
                  </>
                }
               {
         revenueListData.monthly.length == 0 && apiTaskCompleted==false &&
         <Card style={{padding:20, marginTop: 30}}>
                  <h6 style={{textAlign:'center',color: '#6B6987'}}>No record Found</h6> 
         </Card>
       }
              </GridContainer>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <GridContainer>
              {
                  revenueListData.last_month.length > 0 &&
                  <>
                    <Chart data={dataForGraphLastMonth} options={optionsForLastMonth} />
                    <RevenueList {...props} data={revenueListData.last_month} totalRevenue={totalRevenueInRevenueList.last_month} />  
                  </>
                }
                {
         revenueListData.last_month.length == 0 && apiTaskCompleted==false &&
         <Card style={{padding:20, marginTop: 30}}>
                  <h6 style={{textAlign:'center',color: '#6B6987'}}>No record Found</h6> 
         </Card>
       }
             
              </GridContainer>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <GridContainer>
              <CustomDateSelection {...props} callbackFuncForCustom={callbackFuncForCustom} />
              {
                  revenueListData.custom.length > 0 && apiTaskCompleted==false &&
                  <>
                    <RevenueList {...props} data={revenueListData.custom} totalRevenue={totalRevenueInRevenueList.custom} />  
                  </>
                }
                {
                  apiTaskCompleted==true &&
                   <div style={{display:"flex",justifyContent:'center',marginTop:20,width:'100%'}}>
                   <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                   </div>
                }
                 {
                  apiTaskCompleted==false && revenueListData.custom.length ==0 &&
                  <Card style={{padding:20, marginTop: 30}}>
                  <h6 style={{textAlign:'center',color: '#6B6987'}}>No record Found</h6> 
                 </Card>  
                }
              </GridContainer>
            </TabPanel>
                 
          </div>
        </div>
      </div>
      }
      <div className="container-fluid">
        <Switch>
          {/* <Route path={`/app/service_providers/service_provider_detail/:slug`}>
          <ServiceProviderDetail {...props} />    
          </Route> */}
        </Switch>
      </div>
    </>
  );
}



 


 {/* <RevenueList {...props} data={arrayForSpViseDataToday} /> */}

 {/* <RevenueList {...props} data={arrayForSpViseDataWeekly}/> */}