import React from "react";

const ReadMore = ({ children, textLimit }) => {
  const text = children[0];
  const [isReadMore, setIsReadMore] = React.useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="notifyText">
      {isReadMore ? text.slice(0, textLimit) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {
          text.length > textLimit &&
          <a style={{ fontSize: 13 }}>
            {isReadMore ? "see more >" : " see less"}
          </a>
        }

      </span>
    </p>
  );
};

export default ReadMore;