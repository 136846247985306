import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import AppBar from '@material-ui/core/AppBar';
import LockIcon from '@material-ui/icons/Lock';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, TextField, Grid, Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { authenticationService } from '_services/authentication.service';
import { categoryService } from '_services/category.service';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const useStyles = makeStyles((theme) => ({
    button: {
        position: "absolute",
        right: "3pc",
        top: "5.5pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#6B6987",
        fontSize:'15px',
        paddingTop:'10px',
        paddingBottom:'10px',
    },
    button2: {
        background: "transparent",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8cac"
    },
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    fontSize20: {
        fontSize: '20px',
        paddingLeft: '20px',
        color: '#25233C'
    },
    boxStyle: {
        display: 'flex'
    },
    formControl: {
        minWidth: '100%',
        marginBottom: 15,
    },
    root: {
        '& .MuiDialog-paperWidthSm': {
            minWidth: '450px',
            "@media (max-width:600px)": {
                minWidth: '300px'
            }
        },
    },
    textError: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid red'
        }
    },
}));

const Profile = (props) => {

    const classes = useStyles();

    const [openDialog, setOpenDialog] = React.useState(false);

    const [visiblePassword, setVisibePassword] = React.useState({
        old: false,
        new: false,
        confirm: false
    })

    const [dataPassword, setDataPassword] = React.useState({
        old: '',
        new: '',
        confirm: ''
    })

    const [error, setError] = React.useState({
        old: false,
        new: false,
        confirm: false,
        match: false
    })

    const [alert, setAlert] = React.useState(null);

    const [profileData, setProfileData] = React.useState({ name: '', email: '', id: '' })

    function SweetAlertConfirm(data, icon) {

        const getAlertSuccess = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                    setAlert(null)
                    setOpenDialog(null)
                }}
            >
                {data}
            </SweetAlert>
        )

        const getAlertError = () => (
            <SweetAlert
                error
                title="Woot!"
                onConfirm={() => {
                    setAlert(null)
                }}
            >
                {data}
            </SweetAlert>
        )

        if (icon == "error") {
            setAlert(getAlertError());
        }
        else if (icon == "success") {
            setAlert(getAlertSuccess())
        }

    }

    function getToPrevious() {

        props.history.goBack();
    }


    const handlePasswordVisibility = (type) => {

        // toggle visibility of password based on type 

        setVisibePassword(prevState => {
            return {
                ...prevState,
                [type]: !prevState[`${type}`]
            }
        })
    }

    const handleDataForPassword = (event) => {
        // this regex is use to terminate white spaces *************************************************************

        if (event.target.value.match(/^\S*$/)) {
            if (event.target.value.length > 0) {

                setError(prevState => {
                    return {
                        ...prevState,
                        match: false,
                        [event.target.name]: false
                    }
                })

                setDataPassword(prevState => {
                    return {
                        ...prevState,
                        [event.target.name]: event.target.value
                    }
                })
            }
            else {

                setError(prevState => {
                    return {
                        ...prevState,
                        match: false,
                        [event.target.name]: true
                    }
                })

                setDataPassword(prevState => {
                    return {
                        ...prevState,
                        [event.target.name]: event.target.value
                    }
                })
            }
        }
    }


    const submitHandlerPasswordChange = () => {
        if (dataPassword.old.length == 0) {
            setError({ ...error, old: true })
        }
        else if (dataPassword.new.length == 0) {
            setError({ ...error, new: true })
        }
        else if (dataPassword.confirm.length == 0) {
            setError({ ...error, confirm: true })
        }

        else if (dataPassword.new !== dataPassword.confirm) {
            setError({ ...error, match: true })
        }
        else {
            setError(
                {
                    old: false,
                    new: false,
                    confirm: false,
                    match: false
                })
            let obj = {
                id: profileData.id,
                old_password: dataPassword.old,
                new_password: dataPassword.new,
                confirm_password: dataPassword.confirm
            }

            categoryService.changeAdminPassword(obj).then((res) => {
                SweetAlertConfirm('Password Updated SuccessFully', 'success');
            }).catch((error) => {
                SweetAlertConfirm(error, 'error')
            })


        }
    }


    React.useEffect(() => {
        const getAuthenticationData = async () => {

            // fetching current user as observable

            authenticationService.currentUser.subscribe(x => {
                if (x) {
                    setProfileData({
                        name: x.user?.name,
                        email: x.user.email,
                        id: x.user?._id
                    })
                }
            });
        }

        getAuthenticationData();
    }, [])

    return (
        <>
            {
                <div>
                    <Dialog className={classes.root} style={{ maxWidth: 450, margin: "auto" }} open={openDialog} onClose={(e) => setOpenDialog(false)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" style={{ background: "#F5F5FC", color: "#6B6987" }}>Change Password</DialogTitle>
                        <form >
                            <DialogContent>

                                <FormControl className={classes.formControl}>
                                    <h5 className={classes.h5}>Old Password</h5>
                                    <Box className={classes.boxStyle}>
                                        <TextField
                                            className={error.old == true ? classes.textError : ''}
                                            type={visiblePassword.old == true ? 'text' : 'password'}
                                            variant="outlined"
                                            placeholder="Old Password..."
                                            fullWidth={true}
                                            size="small"
                                            name="old"
                                            value={dataPassword.old}
                                            autoComplete="false"
                                            onChange={(event) => { handleDataForPassword(event) }}
                                        />
                                        <div className='Icons' style={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton onClick={() => { handlePasswordVisibility('old') }} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                                {visiblePassword.old ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </div>
                                    </Box>
                                    {
                                        error.old == true &&
                                        <p style={{ color: 'red', marginBottom: 0, fontSize: '12px' }}>
                                            * This Field is required
                                        </p>
                                    }
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <h5 className={classes.h5}>New Password</h5>
                                    <Box className={classes.boxStyle}>
                                        <TextField
                                            className={error.new == true ? classes.textError : ''}
                                            type={visiblePassword.new == true ? 'text' : 'password'}
                                            variant="outlined"
                                            placeholder="New Password..."
                                            fullWidth={true}
                                            size="small"
                                            name="new"
                                            value={dataPassword.new}
                                            autoComplete="false"
                                            onChange={(event) => { handleDataForPassword(event) }}
                                        />
                                        <div className='Icons' style={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton onClick={() => { handlePasswordVisibility('new') }} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                                {visiblePassword.new ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </div>
                                    </Box>
                                    {
                                        error.new == true &&
                                        <p style={{ color: 'red', marginBottom: 0, fontSize: '12px' }}>
                                            * This Field is required
                                        </p>
                                    }
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <h5 className={classes.h5}>Confirm Password</h5>
                                    <Box className={classes.boxStyle}>
                                        <TextField
                                            className={(error.confirm == true || error.match == true) ? classes.textError : ''}
                                            type={visiblePassword.confirm == true ? 'text' : 'password'}
                                            variant="outlined"
                                            placeholder="Confirm Password..."
                                            fullWidth={true}
                                            size="small"
                                            name="confirm"
                                            value={dataPassword.confirm}
                                            autoComplete="false"
                                            onChange={(event) => { handleDataForPassword(event) }}
                                        />
                                        <div className='Icons' style={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton onClick={() => { handlePasswordVisibility('confirm') }} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                                {visiblePassword.confirm ? <VisibilityIcon /> : <VisibilityOffIcon />}

                                            </IconButton>
                                        </div>
                                    </Box>
                                    {
                                        error.confirm == true &&
                                        <p style={{ color: 'red', marginBottom: 0, fontSize: '12px' }}>
                                            * This Field is required
                                        </p>
                                    }
                                    {
                                        error.match == true &&
                                        <p style={{ color: 'red', marginBottom: 0, fontSize: '12px' }}>
                                            * Does not Match with new Password
                                        </p>
                                    }
                                </FormControl>
                            </DialogContent>
                        </form>
                        <DialogActions style={{ justifyContent: "flex-start", paddingLeft: 25, paddingBottom: 30 }}>
                            <Button onClick={submitHandlerPasswordChange} style={{ background: "#6259CA", color: "#fff", padding: "15px 27px", fontSize:'15px',fontWeight:'700' }}>
                                Save Changes
                            </Button>
                            <Button onClick={() => { setOpenDialog(false) }} style={{ background: "#6259CA", color: "#fff", padding: "15px 27px", marginLeft: 16, fontSize:'15px',fontWeight:'700' }}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <AppBar elevation={0} position="static" color="transparent" className={classes.appbarAdj} >
                        <div className={classes.header}>
                            {/* <IconButton aria-label="edit" onClick={getToPrevious}>
                                <ArrowBackIcon />
                            </IconButton> */}
                            <h4 className={classes.fontSize20} >Profile</h4>
                        {/* </div> */}
                        <Button
                            onClick={() => {
                                setError({ old: false, new: false, confirm: false, match: false })

                                setVisibePassword({ old: false, new: false, confirm: false })

                                setDataPassword({ old: '', new: '', confirm: '' })

                                setOpenDialog(true)
                            }}
                            variant="contained"
                            className={classes.button}
                            startIcon={<LockIcon />}
                            id="ChangePasswordText"
                        >
                            Change Password
                        </Button>
                         </div>
                    </AppBar>
                    <GridContainer container>
                        <GridItem xs={12} md={8}>
                            <Card>
                                {/* <CardHeader color="primary" style={{ height: '5px' }} icon>
                                </CardHeader> */}
                                <CardBody className="px-4 mb-2 mt-0 pt-0">
                                    <GridContainer container>
                                        <GridItem xs={12} sm={12} md={6} >
                                            <Box className="mt-3">
                                                <InputLabel htmlFor="title" style={{ color: '#25233C',fontSize:'18px' }}>Name</InputLabel>
                                                <TextField
                                                    // label="Working At (Optional)"
                                                    disabled={true}
                                                    variant="outlined"
                                                    placeholder="Name"
                                                    fullWidth={true}
                                                    size="small"
                                                    name="title"
                                                    value={profileData.name}
                                                    id="ProfileTextField"
                                                />

                                            </Box>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={6} className={classes.boxStyle} >
                                            <Box className="mt-3">
                                                <InputLabel htmlFor="title" style={{ color: '#25233C',fontSize:'18px'}}>Email</InputLabel>
                                                <TextField
                                                    // label="Working At (Optional)"
                                                    disabled={true}
                                                    variant="outlined"
                                                    placeholder="Email"
                                                    fullWidth={true}
                                                    size="small"
                                                    name="title"
                                                    value={profileData.email}
                                                    id="ProfileTextField"
                                                />
                                            </Box>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            }
            <>
                {alert}
            </>
        </>
    )
}

export default Profile;