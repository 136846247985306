import {
  Avatar,
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Typography,
  Box,
  Paper,
  TextField,
} from '@material-ui/core'
import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { userService } from '_services/user.service'
import { renderText } from '../common/DisplayComponent'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ApplyBackDrop from '../common/ApplyBackDrop'
import { CenterFocusStrong } from '@material-ui/icons'
import moon from "../../../../../assets/img/avalibilityIcons/moon.svg";
import sun from "../../../../../assets/img/avalibilityIcons/sun.svg";
import sunrise from "../../../../../assets/img/avalibilityIcons/sunrise.svg";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: '#fff',
    boxShadow: 'unset',
    textTransform: 'capitalize',
    color: '#8f8cac',
    '&:hover': {
      background: '#efefef',
      boxShadow: 'unset',
      textDecoration: 'none',
      // color: '#ffffff',
    },
  },
  formControl: {
    minWidth: '100%',
    marginBottom: 15,
  },
  h5: {
    color: '#25233C',
    fontSize: 18,
  },
  selectadj: {
    border: '1px solid #B9B8C4',
    height: 50,
    padding: 15,
    borderRadius: 5,
  },
  formContainer: {
    width: '100%',
    height: '98vh',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    minHeight: '100%',
    padding: '16px',
    // margin:"20px 0"
  },
  boxStyle: {
    marginTop: '30px',
  },
  displayFlexJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,

  },
  marginBottom24: {
    marginBottom: '24px',
  },
  minPaymentSection: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '65%',
    alignItems: 'center',
    marginTop: '30px',
  },
  width44: {
    width: '44%',
    // width: '34%',

  },
  width150Height42: {
    width: '150px',
    height: '42px',
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    width: '100%',
    '@media (max-width:635px)': {
      width: '30%',
    },
    '@media (max-width:590px)': {
      width: '35%',
    },
    '@media (max-width:510px)': {
      width: '40%',
    },
    '@media (max-width:460px)': {
      width: '50%',
    },
    '@media (max-width:380px)': {
      width: '60%',
    },
  },
  displayFlex: {
    display: 'flex',
  },
  textCenterFontBold: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '20px'
  },
  colorTimingFont12: {
    color: '#005CB2',
    fontSize: '12px',
  },
  marginTop15AlignCenter: {
    marginTop: '15px',
    alignItems: 'center',
    marginLeft: '-20px',
  },
  marginLeft30: {
    // marginLeft: '30px',
    marginLeft: '0px',
    '@media (max-width:600px)': {
      marginLeft: '0px',
    },
  },
  displayFlexJustifySpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  width114px: {
    width: '114px',
    borderRadius: '10px'
  },
  filledButtonStyle: {
    marginLeft: 0,
    backgroundColor: '#DB0D0D',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '10px',
    border: '1px solid #DB0D0D',
    '&:hover': {
      background: "#fff",
      border: "1px solid #DB0D0D",
      color: "#DB0D0D"
    },
  },
  outlinedButtonStyle: {
    color: 'black',
    borderColor: '#DB0D0D',
    '&:hover': {
      borderColor: 'red',
    },
  },
  checkedCheckBox: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#005cb2',
    },
  },
}))

// 0px 0px 2px 2px #9ea9e4;

const Step6 = ({
  availabilitySlots,
  handleCheckBoxAllFieldsTimingSlot,
  handleCheckBoxDayTimeDay,
  handleNext,
  handlePrev,
  apiCall,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.marginBottom24}>
        {renderText({ label: 'Our Timing Slots are as Follows' })}
      </Box>

      <Box
        className={classes.displayFlex}
        style={{ justifyContent: 'space-around', flexWrap: 'wrap' }}
        id="TimmingSlot"
      >
        <Box>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ textAlign: 'center', marginBottom: 15 }}>
              <img src={moon} style={{ width: 50, height: 50, }} />
            </div>
            <p className={classes.textCenterFontBold}>Morning</p>
            <p className={classes.colorTimingFont12}>(08:00 AM to 12:00 PM)</p>
            {/* marginLeft: 47, marginBottom: 15  */}
          </Box>
        </Box>
        <Box>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ textAlign: 'center', marginBottom: 15 }}>
              <img src={sun} style={{ width: 50, height: 50, }} />
            </div>
            <p className={classes.textCenterFontBold}>Afternoon</p>
            <p className={classes.colorTimingFont12}>(12:00 PM to 04:00 PM)</p>
          </Box>
        </Box>
        <Box>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ textAlign: 'center', marginBottom: 15 }}>
              <img src={sunrise} style={{ width: 50, height: 50, }} />
            </div>
            <p className={classes.textCenterFontBold}>Evening</p>
            <p className={classes.colorTimingFont12}>(04:00 PM to 08:00 PM)</p>
          </Box>
        </Box>
      </Box>
        <Grid container style={{ marginTop: '30px', alignItems: 'center', marginLeft: '-20px' }} id="wrapText">
          <Grid item xs={12} sm={1} className={classes.displayFlexJustifyCenter} >
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="monday"
                    checked={availabilitySlots.monday.morning
                      && availabilitySlots.monday.afternoon
                      && availabilitySlots.monday.evening}
                    onChange={() => handleCheckBoxAllFieldsTimingSlot('monday')}

                  />
                }
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}> */}
          <Grid item xs={12} sm={2} >

            <Box className={classes.width44}>
              <Button
                className={`
                    ${classes.width114px} 
                    ${availabilitySlots.monday.morning &&
                    availabilitySlots.monday.afternoon &&
                    availabilitySlots.monday.evening
                    ? classes.filledButtonStyle
                    : classes.outlinedButtonStyle
                  }`}
                name="monday"
                variant={
                  availabilitySlots.monday.morning &&
                    availabilitySlots.monday.afternoon &&
                    availabilitySlots.monday.evening
                    ? 'contained'
                    : 'outlined'
                }
                // color="primary"
                onClick={() => handleCheckBoxAllFieldsTimingSlot('monday')}
              >
                Monday
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            className={`${classes.displayFlexJustifyCenter} ${classes.marginLeft30}`}
          >
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="morning"
                    checked={availabilitySlots.monday.morning}
                    onChange={() => handleCheckBoxDayTimeDay('morning', 'monday')}
                  />
                }

                label={<span style={{ color: 'black' }}>Morning</span>}
              />

            </Box>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="afternoon"
                    checked={availabilitySlots.monday.afternoon}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('afternoon', 'monday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>AfterNoon</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="evening"
                    checked={availabilitySlots.monday.evening}
                    onChange={() => handleCheckBoxDayTimeDay('evening', 'monday')}
                  />
                }
                label={<span style={{ color: 'black' }}>Evening</span>}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container className={classes.marginTop15AlignCenter}id="wrapText">
          <Grid item xs={12} sm={1} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="tuesday"
                    checked={availabilitySlots.tuesday.morning
                      && availabilitySlots.tuesday.afternoon
                      && availabilitySlots.tuesday.evening}
                    onChange={() => handleCheckBoxAllFieldsTimingSlot('tuesday')}
                  />
                }
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}>
         */}
          <Grid item xs={12} sm={2} >

            <Box className={classes.width44}>
              <Button
                className={`
                    ${classes.width114px} 
                    ${availabilitySlots.tuesday.morning &&
                    availabilitySlots.tuesday.afternoon &&
                    availabilitySlots.tuesday.evening
                    ? classes.filledButtonStyle
                    : classes.outlinedButtonStyle
                  }`}
                variant={
                  availabilitySlots.tuesday.morning &&
                    availabilitySlots.tuesday.afternoon &&
                    availabilitySlots.tuesday.evening
                    ? 'contained'
                    : 'outlined'
                }
                // color="primary"
                onClick={() => handleCheckBoxAllFieldsTimingSlot('tuesday')}
              >
                Tuesday
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            className={`${classes.displayFlexJustifyCenter} ${classes.marginLeft30}`}
          >
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="morning"
                    checked={availabilitySlots.tuesday.morning}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('morning', 'tuesday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>Morning</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="afternoon"
                    checked={availabilitySlots.tuesday.afternoon}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('afternoon', 'tuesday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>AfterNoon</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="evening"
                    checked={availabilitySlots.tuesday.evening}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('evening', 'tuesday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>Evening</span>}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '15px', alignItems: 'center', marginLeft: '-20px' }}  id="wrapText">
          <Grid item xs={12} sm={1} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="wednesday"
                    checked={availabilitySlots.wednesday.morning
                      && availabilitySlots.wednesday.afternoon
                      && availabilitySlots.wednesday.evening}
                    onChange={() => handleCheckBoxAllFieldsTimingSlot('wednesday')}
                  />
                }
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}>
         */}
          <Grid item xs={12} sm={2} >

            <Box className={classes.width44}>
              <Button
                className={`
                    ${classes.width114px} 
                    ${availabilitySlots.wednesday.morning &&
                    availabilitySlots.wednesday.afternoon &&
                    availabilitySlots.wednesday.evening
                    ? classes.filledButtonStyle
                    : classes.outlinedButtonStyle
                  }`}
                variant={
                  availabilitySlots.wednesday.morning &&
                    availabilitySlots.wednesday.afternoon &&
                    availabilitySlots.wednesday.evening
                    ? 'contained'
                    : 'outlined'
                }
                // color="primary"
                onClick={() => handleCheckBoxAllFieldsTimingSlot('wednesday')}
              >
                Wednesday
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            className={`${classes.displayFlexJustifyCenter} ${classes.marginLeft30}`}
          >
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="morning"
                    checked={availabilitySlots.wednesday.morning}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('morning', 'wednesday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>Morning</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="afternoon"
                    checked={availabilitySlots.wednesday.afternoon}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('afternoon', 'wednesday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>AfterNoon</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="evening"
                    checked={availabilitySlots.wednesday.evening}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('evening', 'wednesday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>Evening</span>}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.marginTop15AlignCenter}  id="wrapText">
          <Grid item xs={12} sm={1} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="thursday"
                    checked={availabilitySlots.thursday.morning
                      && availabilitySlots.thursday.afternoon
                      && availabilitySlots.thursday.evening}
                    onChange={() => handleCheckBoxAllFieldsTimingSlot('thursday')}
                  />
                }
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}> */}
          <Grid item xs={12} sm={2}>

            <Box className={classes.width44}>
              <Button
                className={`
                     ${classes.width114px} 
                     ${availabilitySlots.thursday.morning &&
                    availabilitySlots.thursday.afternoon &&
                    availabilitySlots.thursday.evening
                    ? classes.filledButtonStyle
                    : classes.outlinedButtonStyle
                  }`}
                variant={
                  availabilitySlots.thursday.morning &&
                    availabilitySlots.thursday.afternoon &&
                    availabilitySlots.thursday.evening
                    ? 'contained'
                    : 'outlined'
                }
                //  color="primary"
                onClick={() => handleCheckBoxAllFieldsTimingSlot('thursday')}
              >
                Thursday
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            className={`${classes.displayFlexJustifyCenter} ${classes.marginLeft30}`}
          >
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="morning"
                    checked={availabilitySlots.thursday.morning}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('morning', 'thursday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>Morning</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="afternoon"
                    checked={availabilitySlots.thursday.afternoon}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('afternoon', 'thursday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>AfterNoon</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="evening"
                    checked={availabilitySlots.thursday.evening}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('evening', 'thursday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>Evening</span>}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.marginTop15AlignCenter}  id="wrapText">
          <Grid item xs={12} sm={1} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="friday"
                    checked={availabilitySlots.friday.morning
                      && availabilitySlots.friday.afternoon
                      && availabilitySlots.friday.evening}
                    onChange={() => handleCheckBoxAllFieldsTimingSlot('friday')}
                  />
                }
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}> */}
          <Grid item xs={12} sm={2} >

            <Box className={classes.width44}>
              <Button
                className={`
                    ${classes.width114px} 
                    ${availabilitySlots.friday.morning &&
                    availabilitySlots.friday.afternoon &&
                    availabilitySlots.friday.evening
                    ? classes.filledButtonStyle
                    : classes.outlinedButtonStyle
                  }`}
                variant={
                  availabilitySlots.friday.morning &&
                    availabilitySlots.friday.afternoon &&
                    availabilitySlots.friday.evening
                    ? 'contained'
                    : 'outlined'
                }
                // color="primary"
                onClick={() => handleCheckBoxAllFieldsTimingSlot('friday')}
              >
                Friday
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            className={`${classes.displayFlexJustifyCenter} ${classes.marginLeft30}`}
          >
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="morning"
                    checked={availabilitySlots.friday.morning}
                    onChange={() => handleCheckBoxDayTimeDay('morning', 'friday')}
                  />
                }
                label={<span style={{ color: 'black' }}>Morning</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="afternoon"
                    checked={availabilitySlots.friday.afternoon}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('afternoon', 'friday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>AfterNoon</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="evening"
                    checked={availabilitySlots.friday.evening}
                    onChange={() => handleCheckBoxDayTimeDay('evening', 'friday')}
                  />
                }
                label={<span style={{ color: 'black' }}>Evening</span>}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.marginTop15AlignCenter}  id="wrapText">
          <Grid item xs={12} sm={1} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="saturday"
                    checked={availabilitySlots.saturday.morning
                      && availabilitySlots.saturday.afternoon
                      && availabilitySlots.saturday.evening}
                    onChange={() => handleCheckBoxAllFieldsTimingSlot('saturday')}
                  />
                }
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}> */}
          <Grid item xs={12} sm={2} >

            <Box className={classes.width44}>
              <Button
                className={`
                    ${classes.width114px} 
                    ${availabilitySlots.saturday.morning &&
                    availabilitySlots.saturday.afternoon &&
                    availabilitySlots.saturday.evening
                    ? classes.filledButtonStyle
                    : classes.outlinedButtonStyle
                  }`}
                variant={
                  availabilitySlots.saturday.morning &&
                    availabilitySlots.saturday.afternoon &&
                    availabilitySlots.saturday.evening
                    ? 'contained'
                    : 'outlined'
                }
                // color="primary"
                onClick={() => handleCheckBoxAllFieldsTimingSlot('saturday')}
              >
                Saturday
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            className={`${classes.displayFlexJustifyCenter} ${classes.marginLeft30}`}
          >
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="morning"
                    checked={availabilitySlots.saturday.morning}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('morning', 'saturday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>Morning</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="afternoon"
                    checked={availabilitySlots.saturday.afternoon}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('afternoon', 'saturday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>AfterNoon</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="evening"
                    checked={availabilitySlots.saturday.evening}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('evening', 'saturday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>Evening</span>}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.marginTop15AlignCenter}id="wrapText">
          <Grid item xs={12} sm={1} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="sunday"
                    checked={availabilitySlots.sunday.morning
                      && availabilitySlots.sunday.afternoon
                      && availabilitySlots.sunday.evening}
                    onChange={() => handleCheckBoxAllFieldsTimingSlot('sunday')}
                  />
                }
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}> */}
          <Grid item xs={12} sm={2}>

            <Box className={classes.width44}>
              <Button
                className={`
                    ${classes.width114px} 
                    ${availabilitySlots.sunday.morning &&
                    availabilitySlots.sunday.afternoon &&
                    availabilitySlots.sunday.evening
                    ? classes.filledButtonStyle
                    : classes.outlinedButtonStyle
                  }`}
                variant={
                  availabilitySlots.sunday.morning &&
                    availabilitySlots.sunday.afternoon &&
                    availabilitySlots.sunday.evening
                    ? 'contained'
                    : 'outlined'
                }
                // color="primary"
                onClick={() => handleCheckBoxAllFieldsTimingSlot('sunday')}
              >
                Sunday
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            className={`${classes.displayFlexJustifyCenter} ${classes.marginLeft30}`}
          >
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="morning"
                    checked={availabilitySlots.sunday.morning}
                    onChange={() => handleCheckBoxDayTimeDay('morning', 'sunday')}
                  />
                }
                label={<span style={{ color: 'black' }}>Morning</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="afternoon"
                    checked={availabilitySlots.sunday.afternoon}
                    onChange={() =>
                      handleCheckBoxDayTimeDay('afternoon', 'sunday')
                    }
                  />
                }
                label={<span style={{ color: 'black' }}>AfterNoon</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter}>
            <Box className={classes.width44}>
              <FormControlLabel
                className={classes.checkedCheckBox}
                control={
                  <Checkbox
                    name="evening"
                    checked={availabilitySlots.sunday.evening}
                    onChange={() => handleCheckBoxDayTimeDay('evening', 'sunday')}
                  />
                }
                label={<span style={{ color: 'black' }}>Evening</span>}
              />
            </Box>
          </Grid>
        </Grid>
      <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
        <Box className={classes.buttonStyle}>
          <Button
            variant="contained"
            className={classes.filledButtonStyle}
            onClick={handlePrev}
          >
            Prev
          </Button>
          <Button
            variant="contained"
            className={classes.filledButtonStyle}
            onClick={handleNext}
            style={{ marginLeft: '10px', }}
          >
            {window.location.href.split('/')[
              window.location.href.split('/').length - 1
            ] == 'newSp'
              ? 'Next'
              : 'Save'}
          </Button>
          {<ApplyBackDrop apiCall={apiCall} />}
        </Box>
      </Grid>
    </div>
  )
}

export default Step6

// const Step6 = ({ availabilitySlots,
//     handleCheckBoxAllFieldsTimingSlot, handleCheckBoxDayTimeDay,
//     handleNext, handlePrev,apiCall
// }) => {
//     const classes = useStyles();
//     return <div>
//         <Box className={classes.marginBottom24} >
//             {renderText({ label: "Our Timing Slots are as Follows" })}
//         </Box>

//         <Box className={classes.displayFlex} style={{ justifyContent: "space-around" }}>
//             <Box>
//                 <Box style={{ display: "flex", flexDirection: "column" }}>
//                     <p className={classes.textCenterFontBold}>
//                         Morning
//          </p>
//                     <p className={classes.colorTimingFont12}>
//                         (08:00 AM to 12:00 PM)
//          </p>
//                 </Box>
//             </Box>
//             <Box>
//                 <Box style={{ display: "flex", flexDirection: "column" }}>
//                     <p className={classes.textCenterFontBold}>
//                         Afternoon
//          </p>
//                     <p className={classes.colorTimingFont12}>
//                         (12:00 PM to 04:00 PM)
//          </p>
//                 </Box>
//             </Box>
//             <Box>
//                 <Box style={{ display: "flex", flexDirection: "column" }}>
//                     <p className={classes.textCenterFontBold}>
//                         Evening
//          </p>
//                     <p className={classes.colorTimingFont12}>
//                         (04:00 PM to 08:00 PM)
//          </p>
//                 </Box>
//             </Box>
//         </Box>

//         <Grid container style={{ marginTop: "30px", alignItems: "center" }}>
//             <Grid item xs={12} sm={4} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.displayFlex} >
//                     <Button className={`${classes.width150Height42 } ${classes.displayFlexJustifySpaceBetween}`}   name="monday" variant="outlined" color="primary" onClick={() => handleCheckBoxAllFieldsTimingSlot('monday')}>
//                     <FormControlLabel
//                         style={{marginBottom:'0'}}
//                         control={<Checkbox name="morning" color="primary" checked={(availabilitySlots.monday.morning && availabilitySlots.monday.afternoon && availabilitySlots.monday.evening) ? true : false  } onChange={() => handleCheckBoxAllFieldsTimingSlot('monday')} />}
//                     />
//                     <p style={{margin:0}}>
//                     MONDAY
//                     </p>
//             </Button>

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={`${classes.displayFlexJustifyCenter} `}>
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="morning" checked={availabilitySlots.monday.morning } onChange={() => handleCheckBoxDayTimeDay('morning', 'monday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Morning

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="afternoon" checked={availabilitySlots.monday.afternoon} onChange={() => handleCheckBoxDayTimeDay('afternoon', 'monday')} />}
//                         label={<span style={{ color: "black" }}>

//                             AfterNoon

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="evening" checked={availabilitySlots.monday.evening} onChange={() => handleCheckBoxDayTimeDay('evening', 'monday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Evening

// </span>}
//                     />

//                 </Box>
//             </Grid>
//         </Grid>

//         <Grid container className={classes.marginTop15AlignCenter}>
//             <Grid item xs={12} sm={4} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.displayFlex} >

//                     <Button className={`${classes.width150Height42 } ${classes.displayFlexJustifySpaceBetween}`} variant="outlined" color="primary" onClick={() => handleCheckBoxAllFieldsTimingSlot('tuesday')}>
//                     <FormControlLabel
//                         style={{marginBottom:'0'}}
//                         control={<Checkbox name="morning" color="primary" checked={(availabilitySlots.tuesday.morning && availabilitySlots.tuesday.afternoon && availabilitySlots.tuesday.evening) ? true : false  } onChange={() => handleCheckBoxAllFieldsTimingSlot('tuesday')} />}
//                     />
//                      <p style={{margin:0}}>
//                      Tuesday
//                      </p>

//             </Button>
//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={`${classes.displayFlexJustifyCenter} `} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="morning" checked={availabilitySlots.tuesday.morning} onChange={() => handleCheckBoxDayTimeDay('morning', 'tuesday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Morning

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="afternoon" checked={availabilitySlots.tuesday.afternoon} onChange={() => handleCheckBoxDayTimeDay('afternoon', 'tuesday')} />}
//                         label={<span style={{ color: "black" }}>

//                             AfterNoon

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="evening" checked={availabilitySlots.tuesday.evening } onChange={() => handleCheckBoxDayTimeDay('evening', 'tuesday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Evening

// </span>}
//                     />

//                 </Box>
//             </Grid>
//         </Grid>
//         <Grid container style={{ marginTop: "15px", alignItems: "center" }}>
//             <Grid item xs={12} sm={4} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.displayFlex} >
//                     <Button className={`${classes.width150Height42 } ${classes.displayFlexJustifySpaceBetween}`} variant="outlined" color="primary" onClick={() => handleCheckBoxAllFieldsTimingSlot('wednesday')}>
//                     <FormControlLabel
//                         style={{marginBottom:'0'}}
//                         control={<Checkbox name="morning" color="primary" checked={(availabilitySlots.wednesday.morning && availabilitySlots.wednesday.afternoon && availabilitySlots.wednesday.evening) ? true : false  } onChange={() => handleCheckBoxAllFieldsTimingSlot('wednesday')} />}
//                     />
//                     <p style={{margin:0}}>
//                     WEDNESDAY
//                     </p>

//             </Button>
//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={`${classes.displayFlexJustifyCenter}`} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="morning" checked={availabilitySlots.wednesday.morning} onChange={() => handleCheckBoxDayTimeDay('morning', 'wednesday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Morning

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="afternoon" checked={availabilitySlots.wednesday.afternoon} onChange={() => handleCheckBoxDayTimeDay('afternoon', 'wednesday')} />}
//                         label={<span style={{ color: "black" }}>

//                             AfterNoon

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="evening" checked={availabilitySlots.wednesday.evening} onChange={() => handleCheckBoxDayTimeDay('evening', 'wednesday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Evening

// </span>}
//                     />

//                 </Box>
//             </Grid>
//         </Grid>
//         <Grid container className={classes.marginTop15AlignCenter}>
//             <Grid item xs={12} sm={4} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.displayFlex} >
//                     <Button className={`${classes.width150Height42 } ${classes.displayFlexJustifySpaceBetween}`} variant="outlined" color="primary" onClick={() => handleCheckBoxAllFieldsTimingSlot('thursday')}>
//                     <FormControlLabel
//                         style={{marginBottom:'0'}}
//                         control={<Checkbox name="morning" color="primary" checked={(availabilitySlots.thursday.morning && availabilitySlots.thursday.afternoon && availabilitySlots.thursday.evening) ? true : false  } onChange={() => handleCheckBoxAllFieldsTimingSlot('thursday')} />}
//                     />
//                         <p style={{margin:0}}>
//                         THURSDAY
//                         </p>

//             </Button>
//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={`${classes.displayFlexJustifyCenter} `} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="morning" checked={availabilitySlots.thursday.morning} onChange={() => handleCheckBoxDayTimeDay('morning', 'thursday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Morning

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="afternoon" checked={availabilitySlots.thursday.afternoon} onChange={() => handleCheckBoxDayTimeDay('afternoon', 'thursday')} />}
//                         label={<span style={{ color: "black" }}>

//                             AfterNoon

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="evening" checked={availabilitySlots.thursday.evening} onChange={() => handleCheckBoxDayTimeDay('evening', 'thursday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Evening

// </span>}
//                     />

//                 </Box>
//             </Grid>
//         </Grid>
//         <Grid container className={classes.marginTop15AlignCenter}>
//             <Grid item xs={12} sm={4} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.displayFlex} >
//                     <Button className={`${classes.width150Height42 } ${classes.displayFlexJustifySpaceBetween}`} variant="outlined" color="primary" onClick={() => handleCheckBoxAllFieldsTimingSlot('friday')}>
//                     <FormControlLabel
//                         style={{marginBottom:'0'}}
//                         control={<Checkbox name="morning" color="primary" checked={(availabilitySlots.friday.morning && availabilitySlots.friday.afternoon && availabilitySlots.friday.evening) ? true : false  } onChange={() => handleCheckBoxAllFieldsTimingSlot('friday')} />}
//                     />
//                     <p style={{margin:0}}>
//                     FRIDAY
//                     </p>

//             </Button>
//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={`${classes.displayFlexJustifyCenter} `} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="morning" checked={availabilitySlots.friday.morning} onChange={() => handleCheckBoxDayTimeDay('morning', 'friday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Morning

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="afternoon" checked={availabilitySlots.friday.afternoon} onChange={() => handleCheckBoxDayTimeDay('afternoon', 'friday')} />}
//                         label={<span style={{ color: "black" }}>

//                             AfterNoon

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="evening" checked={availabilitySlots.friday.evening} onChange={() => handleCheckBoxDayTimeDay('evening', 'friday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Evening

// </span>}
//                     />

//                 </Box>
//             </Grid>
//         </Grid>
//         <Grid container className={classes.marginTop15AlignCenter}>
//             <Grid item xs={12} sm={4} className={classes.displayFlexJustifyCenter} >
//                 <Box className={`${classes.width150Height42 } ${classes.displayFlexJustifySpaceBetween}`} >
//                     <Button className={classes.width150Height42} variant="outlined" color="primary" onClick={() => handleCheckBoxAllFieldsTimingSlot('saturday')}>
//                     <FormControlLabel
//                         style={{marginBottom:'0'}}
//                         control={<Checkbox name="morning" color="primary" checked={(availabilitySlots.saturday.morning && availabilitySlots.saturday.afternoon && availabilitySlots.saturday.evening) ? true : false  } onChange={() => handleCheckBoxAllFieldsTimingSlot('saturday')} />}
//                     />
//                     <p style={{margin:0}}>
//                     SATURDAY
//                     </p>

//             </Button>
//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={`${classes.displayFlexJustifyCenter}`}>
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="morning" checked={availabilitySlots.saturday.morning} onChange={() => handleCheckBoxDayTimeDay('morning', 'saturday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Morning

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="afternoon" checked={availabilitySlots.saturday.afternoon} onChange={() => handleCheckBoxDayTimeDay('afternoon', 'saturday')} />}
//                         label={<span style={{ color: "black" }}>

//                             AfterNoon

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="evening" checked={availabilitySlots.saturday.evening } onChange={() => handleCheckBoxDayTimeDay('evening', 'saturday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Evening

// </span>}
//                     />

//                 </Box>
//             </Grid>
//         </Grid>
//         <Grid container className={classes.marginTop15AlignCenter}>
//             <Grid item xs={12} sm={4} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.displayFlex} >
//                     <Button className={`${classes.width150Height42 } ${classes.displayFlexJustifySpaceBetween}`} variant="outlined" color="primary" onClick={() => handleCheckBoxAllFieldsTimingSlot('sunday')}>
//                     <FormControlLabel
//                         style={{marginBottom:'0'}}
//                         control={<Checkbox name="morning" color="primary" checked={(availabilitySlots.sunday.morning && availabilitySlots.sunday.afternoon && availabilitySlots.sunday.evening) ? true : false  } onChange={() => handleCheckBoxAllFieldsTimingSlot('sunday')} />}
//                     />
//                     <p style={{margin:0}}>
//                     SUNDAY
//                     </p>

//             </Button>
//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={`${classes.displayFlexJustifyCenter}`} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="morning" checked={availabilitySlots.sunday.morning} onChange={() => handleCheckBoxDayTimeDay('morning', 'sunday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Morning

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={3} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="afternoon" checked={availabilitySlots.sunday.afternoon } onChange={() => handleCheckBoxDayTimeDay('afternoon', 'sunday')} />}
//                         label={<span style={{ color: "black" }}>

//                             AfterNoon

// </span>}
//                     />

//                 </Box>
//             </Grid>
//             <Grid item xs={12} sm={2} className={classes.displayFlexJustifyCenter} >
//                 <Box className={classes.width44} >
//                     <FormControlLabel
//                         control={<Checkbox name="evening" checked={availabilitySlots.sunday.evening} onChange={() => handleCheckBoxDayTimeDay('evening', 'sunday')} />}
//                         label={<span style={{ color: "black" }}>

//                             Evening

// </span>}
//                     />

//                 </Box>
//             </Grid>
//         </Grid>

//         <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
//             <Box className={classes.buttonStyle}>
//                 <Button variant="outlined" color="primary" onClick={handlePrev}>
//                     Prev
//             </Button>
//                 <Button variant="outlined" color="primary" onClick={handleNext}>
//                 {window.location.href.split('/')[window.location.href.split('/').length - 1] == "newSp" ? 'Next' : 'Save'}
//             </Button>
//             {

//                <ApplyBackDrop apiCall={apiCall} />

//                       }
//             </Box>
//         </Grid>
//     </div>

// }

// export default Step6;
