import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardMedia, Icon, IconButton, setRef, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import SweetAlert from 'react-bootstrap-sweetalert';
import { userService } from "_services/user.service";
import {categoryService} from "_services/category.service";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
import AddPromotion from '../SubComponents/AddPromotion';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    cover: {
        border: "1px solid #8E8CAC",
        height: "100%",
        maxWidth: 74,
        minHeight: 74,
        width: "100%",

    },
    cardTitle: {
        margin: "unset",
        padding: "0 20px",
        fontSize: 20,
        color: "#25233C",
        fontWeight: "400"
    },
    cardAction: {
        color: "#000",
    },
    button: {
        margin: theme.spacing(1),
        background: "transparent",
        boxShadow: "unset",
        border: "1px solid #8f8cac",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "transparent",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
    }
    ,
    table: {
        margin: "10px 0",
        background: '#f5f5fc'
    },
    tcontainer: {
        //   margin:"0 -5px",
    },
    thead: {
        padding: 10
    },
    gridCont: {
        paddingTop: "10px",
        margin: 0,
    },
    gridItem1: {
        display: "flex",
        alignItems: "center",
    },
    gridItem2: {
        textAlign: "right",
    },
    tcell1: {
        border: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell2: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell2sub: {
        padding: "unset",
        margin: "auto",
        border: "unset",
    },
    tcell3: {
        border: "unset",
        paddingLeft: "unset",
        paddingBottom: "unset",
    },
    div1: {
        display: "flex",
        alignItems: "center",
        background: "#F3F3FC",
        padding: 10,
        minHeight: 50,
        color: "#25233C"
    },
    div2: {
        background: "#F3F3FC",
        padding: 15,
        color: "#25233C",
        minHeight: 50,
        display: "flex",
        justifyContent: "space-between",
    },
    div3: {
        background: "#F3F3FC",
        padding: 15,
        color: "#25233C",
        minHeight: 50,
        display: "flex",
        justifyContent: "space-between",
    },
    div4: {
        background: "#F3F3FC",
        padding: 15,
        color: "#25233C",
        minHeight: 50,
        display: "flex",
        justifyContent: "space-between",
    },
    blockTitle: {
        paddingLeft: 10,
        color: "#25233C"
    },
    mainCard: {
        marginBottom: '2em',
        // marginLeft: -25,
        // width: "calc(100% + 75px)"
        marginInline: "auto",
        width: "100%",
        marginTop: 0
    },
    subcellWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    subCell: {
        padding: 0,
        border: "unset",
        margin: "auto",
    },
    textDecNone: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none"
        },
    },
    imgItem: { border: "1px solid grey", height: 38, width: 38, background: "#fff", alignItems: "center", display: "flex", justifyContent: "center" },
    bold:{
        fontFamily: "Muli-Bold !important",
        fontSize: 16
    }

}));


const StyledTableCell = withStyles((theme) => ({
    head: {
        //   backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
        backgroundColor: "#F5F5FC",
        color: "#6c6987",
        whiteSpace: "nowrap"
    },

}))(TableCell);

const Promotions = (props) => {
    const [refresh, setRefresh] = React.useState(props.location.state ? props.location.state.refreshData : false);
    let { path, url } = useRouteMatch();
    const [promoData, setPromoData] = React.useState([]);
    const [alertDelete, setAlertDelete] = React.useState(null)
    const classes = useStyles();
    if (window.location.href.split('/')[window.location.href.split('/').length - 1] != 'addpromotion') {
        var { slug } = useParams();
    }
    function movetoAddPromotion() {
        const { from } = { from: { pathname: `/app/service_providers/service_provider_detail/addpromotion/${slug}` } };
        props.history.push(from);
    }
    function simplifiedFunction() {
        const s = window.location.href.split('/')[window.location.href.split('/').length - 1];
        userService.getAllSPPromotions(s).then((response) => {
            setPromoData(response);
        });
    };

    const deletePromo = (id) => {
        categoryService.deleteAdminPromo(id).then(() => {
            SweetAlertConfirm("Deleted");
          })
    }

    function SweetAlertConfirmDelete(id) {
        const getAlert = () => (id &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => {
                    setAlertDelete(null);
                    deletePromo(id);
                }}
                onCancel={() => setAlertDelete(null)}
                focusCancelBtn
            >
                You want to delete this record?
              </SweetAlert>
        )
        setAlertDelete(getAlert());
    }

    function SweetAlertConfirm(data) {
        const getAlert = () => (
          <SweetAlert
            success
            title="Woot!"
            onConfirm={() => {
              setAlertDelete(null)
              }
            }
          >
            Data {data} successfully
          </SweetAlert>
        )
        setAlertDelete(getAlert());
      }
      


    React.useEffect(() => {
        const fetchPromotions = async () => {
            try{
            const response = await userService.getAllSPPromotions(slug);
            setPromoData(response);
            }
            catch(err){
                
            }
        };
        fetchPromotions();
    }, [alertDelete]);

    return (
        <>
            {window.location.href.split('/')[window.location.href.split('/').length - 2] !== 'addpromotion' && <div>

                {/* <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={() => movetoAddPromotion()}
                    startIcon={<Icon color="primary">add</Icon>}
                >
                    Add Promotion
                </Button> */}
                <Card className={`${classes.mainCard} productCategory`}>
                    <div style={{ paddingTop: 10, paddingRight: 10, display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            onClick={() => movetoAddPromotion()}
                            startIcon={<Icon color="primary">add</Icon>}
                        >
                            Add Promotion
                        </Button>
                    </div>
                    {promoData && promoData.length > 0 && <GridContainer style={{ paddingBottom: "20px", margin: 0 }}>
                        <TableContainer className={classes.tcontainer}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead className={classes.thead}>
                                    <TableRow>
                                        <StyledTableCell className={classes.bold}>Promo Code</StyledTableCell>
                                        <StyledTableCell className={classes.bold}>From </StyledTableCell>
                                        <StyledTableCell className={classes.bold}>To</StyledTableCell>
                                        <StyledTableCell className={classes.bold}>Discount Portion(%)</StyledTableCell>
                                        <StyledTableCell className={classes.bold}>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
                                    {promoData.map((promo) => (
                                        <TableRow key={promo._id}>
                                            <TableCell component="th" scope="row" className={classes.tcell1}>
                                                <div className={classes.div1}>
                                                    {promo.promocode}
                                                </div>

                                            </TableCell>
                                            <TableCell align="left" className={classes.tcell2}>
                                                <div className={classes.div2}>
                                                    {moment(promo.start_date).format('DD MMMM YYYY')}
                                                </div>

                                            </TableCell>
                                            <TableCell align="left" className={classes.tcell2}>
                                                <div className={classes.div3}>
                                                {moment(promo.end_date).format('DD MMMM YYYY')}
                                                </div>

                                            </TableCell>
                                            <TableCell align="left" className={classes.tcell2}>
                                                <div className={classes.div3}>
                                                    {promo.dicount_percentage}
                                                </div>

                                            </TableCell>
                                            <TableCell align="left" className={classes.tcell3}>
                                                <div className={classes.div4}>
                                                    <IconButton aria-label="delete" onClick={() => { SweetAlertConfirmDelete(promo._id) }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>

                                            </TableCell>
                                        </TableRow>
                                    )
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </GridContainer>
                    }
                </Card>
            </div>}
            {
                alertDelete
            }
            <div className="container-fluid">
                <Switch>
                    <Route path={`/app/service_providers/service_provider_detail/addpromotion/:slug`}>
                        <AddPromotion simplifiedFunction={simplifiedFunction} {...props} />
                    </Route>
                </Switch>
            </div>
        </>
    )
}

export default Promotions
