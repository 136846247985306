import React from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Icon } from '@material-ui/core';
import classnames from "classnames";

//imported custom components
import UserHeader from './UserHeader.js';
import UserBookings from './UserBookings.js';
import UserPros from './UserPros.js'
import UserRequest from './UserRequest.js'
import ContactInfo from './ContactInfo.js'
//

import { userService } from "_services/user.service";


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
function TabPanel(props) {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    appbarAdj: {
        background: 'transparent',
        boxShadow: 'none',
        position: "absolute",
        top: "-5pc",

    },
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    tabpanel: {
        margin: "0",
        "& > div": { padding: 0 },
    },
    tabs: {
        margin: "0px 40px 0px 10px",
        background: "#EAEDF7",
        borderRadius: "5px",
        paddingLeft: 10,
        paddingLeft: 10,
        "& > div": { overflow: "auto !important" }
        // "&.MuiTabs-indicator": {
        //   display: "none"
        // }
    },
    tab: {
        "&.MuiButtonBase-root.MuiTab-root": {
            height: "55px",
            padding: "unset",
            minWidth: "100px",
            textTransform: "capitalize",
            fontSize:'15px',
            fontFamily:'Muli'
        }
    },
}));


const UserDetail = (props) => {
    const classes = useStyles();
    const { slug } = useParams();
    let { path, url } = useRouteMatch();
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState(null);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    React.useEffect(() => {
        const obj = {
            "id": slug
        }
        const fetchUser = async (obj) => {
            try {
                let response = await userService.getCustomerDetail(obj);
                setData(response);
            }
            catch (err) { }
        };

        fetchUser(obj);

    }, []);
    return (
        <>
            <div>
                {data &&
                    <UserHeader {...props} data={data} />}
                {data && <div className={'container-fluid position-relative'} style={{paddingLeft:'0px',paddingRight:'0px'}}>
                    <AppBar elevation={0} position="static" color="default" className={classes.appbarAdj}>
                        <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label="simple tabs example" TabIndicatorProps={{ style: { backgroundColor: "#6259ca",bottom:'10px' } }} textColor="primary" >
                            <Tab className={classes.tab} label="Bookings" {...a11yProps(0)} />
                            <Tab className={classes.tab} label="My Pros" {...a11yProps(1)}/>
                            <Tab className={classes.tab} label="Requests" {...a11yProps(2)} />
                            <Tab className={classes.tab} label="Contacts" {...a11yProps(3)} />
                            new
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0} className={classes.tabpanel}>
                        <UserBookings tabFlag={"0"} data={data} />
                    </TabPanel>
                    <TabPanel value={value} index={1} className={classes.tabpanel}>
                        <UserPros tabFlag={"1"} data={data}/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <UserRequest tabFlag={"2"} data={data} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <ContactInfo tabFlag={"3"} data={data} />
                    </TabPanel>
                </div>}
            </div>
        </>
    )
}

export default UserDetail
