import {
  Avatar,
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Typography,
  Box,
  Paper,
  TextField,
} from '@material-ui/core'
import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { userService } from '_services/user.service'
// import blueTick from "../../../assets/img/blueTick.png";
import { renderText } from '../common/DisplayComponent'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import ApplyBackDrop from '../common/ApplyBackDrop'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: '#fff',
    boxShadow: 'unset',
    textTransform: 'capitalize',
    color: '#8f8cac',
    '&:hover': {
      background: '#efefef',
      boxShadow: 'unset',
      textDecoration: 'none',
      // color: '#ffffff',
    },
  },
  formControl: {
    minWidth: '100%',
    marginBottom: 15,
  },
  h5: {
    color: '#25233C',
    fontSize: 18,
  },
  selectadj: {
    border: '1px solid #B9B8C4',
    height: 50,
    padding: 15,
    borderRadius: 10,
  },
  formContainer: {
    width: '100%',
    height: '98vh',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    minHeight: '350px',
    padding: '16px',
    // margin:"20px 0"
  },
  boxStyle: {
    marginTop: '30px',
  },
  displayFlexJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  marginBottom24: {
    marginBottom: '24px',
  },
  minPaymentSection: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '65%',
    alignItems: 'center',
    marginTop: '30px',
  },
  width44: {
    width: '44%',
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    width: '100%',
    '@media (max-width:635px)': {
      width: '30%',
    },
    '@media (max-width:590px)': {
      width: '35%',
    },
    '@media (max-width:510px)': {
      width: '40%',
    },
    '@media (max-width:460px)': {
      width: '50%',
    },
    '@media (max-width:380px)': {
      width: '60%',
    },
  },
  errorSelectStyle: {
    border: '2px solid red',
    borderRadius: '10px',
    height: '45px',
  },
  buttonStyleNextPrevious: {
    backgroundColor: '#DB0D0D',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '10px',
    border: '1px solid #DB0D0D',
    '&:hover': {
      background: "#fff",
      border: "1px solid #DB0D0D",
      color: "#DB0D0D"
    },
  },
}))

const ServiceLocation = ({
  handleNextServiceLocation,
  handlePrev,
  uniqueServiceLocation,
  handleChangeServiceLocation,
  defaultValueServiceLocation,
  errorForServiceLocation,
  placesLocation,
  apiCall,

}) => {
  const classes = useStyles()
  const animatedComponents = makeAnimated()
  //.log(placesLocation);
  // console.log(errorForServiceLocation);
  // console.log(uniqueServiceLocation);
  //  console.log(defaultValueServiceLocation);
  return (
    <div>
      <Box className={classes.marginBottom24}>
        {renderText({ label: 'Add cities / ZIP codes' })}
      </Box>

      <Grid container>
        <Box style={{ width: '100%',padding:'0px 10px' }}>
          <Select
            defaultValue={
              placesLocation.length > 0 &&
              placesLocation.map((prop) => {
                return {
                  label: prop,
                  value: prop,
                }
              })
            }
            style={{ width: '100%' }}
            components={animatedComponents}
            isMulti
            name="locationAvailable"
            options={uniqueServiceLocation.map((prop) => {
              return {
                label: prop,
                value: prop,
              }
            }
            )}
            onChange={handleChangeServiceLocation}
            className="basic-multi-select"
            classNamePrefix="select"
            openMenuOnClick={false}
            placeholder={"Select*"}
            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
          />
        </Box>
        {errorForServiceLocation == true && (
          <p style={{ color: 'red', fontSize: '12px' }}>
            {' '}
            * This Field is Required
          </p>
        )}
      </Grid>
      <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
        <Box className={classes.buttonStyle}>
          <Button
            variant="contained"
            className={classes.buttonStyleNextPrevious}
            onClick={handlePrev}
          >
            Prev
          </Button>
          <Button
            variant="contained"
            className={classes.buttonStyleNextPrevious}
            onClick={handleNextServiceLocation}
            style={{ marginLeft: '10px' }}
          >
            {window.location.href.split('/')[
              window.location.href.split('/').length - 1
            ] == 'newSp'
              ? 'Next'
              : 'Save'}
          </Button>
          {<ApplyBackDrop apiCall={apiCall} />}
        </Box>
      </Grid>
    </div>
  )
}

export default ServiceLocation
