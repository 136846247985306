import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import EnhancedTable from '_components/EnhancedTable';
import {
  defaultFont,
  dangerColor,
  whiteColor,
} from "assets/jss/material-dashboard-react.js";
import { userService } from "_services/user.service";
import { CSVLink } from 'react-csv';
import { useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import GridItem from "_components/Grid/GridItem.js";
import Button from "_components/CustomButtons/Button.js";
import Card from "_components/Card/Card.js";
import CardBody from "_components/Card/CardBody.js";
import CardIcon from "_components/Card/CardIcon.js";
import CardHeader from "_components/Card/CardHeader.js";
import ReactTable from "_components/ReactTable.js";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { CardContent, Divider, Grid, Icon, Typography } from "@material-ui/core";
import SPVerification from "./SPVerification";
import VerificationDetail from "../ServiceProvider/components/VerificationDetail";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import ServiceProviderDetail from "views/ServiceProvider/ServiceProviderDetail/ServiceProviderDetail";

import FormComponent from "../ServiceProvider/components/FormComponents/FormComponent";


const useStylesNotification = makeStyles(theme => ({
  notifications: {
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "-2px",
      border: "1px solid " + whiteColor,
      right: "-4px",
      fontSize: "9px",
      background: dangerColor[0],
      color: whiteColor,
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "16px",
      verticalAlign: "middle",
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      ...defaultFont,
      fontSize: "14px",
      marginRight: "8px",
      top: "-2px",
      color: "#FFF",
      right: "-4px",
      border: "1px solid #FFF",
      height: "16px",
      display: "block",
      position: "absolute",
      fontSize: "9px",
      minWidth: "16px",
      background: "#f44336",
      textAlign: "center",
      lineHeight: "16px",
      borderRadius: "10px",
      verticalAlign: "middle",
    },
  },
}))

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Muli', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  regTitle: {
    fontSize: 15,
    color:'#25233C',
    fontFamily:'Muli-SemiBold'
  },
  userCount: {
    fontFamily: "Muli-Bold",
    fontSize: 35,
    color:'#25233C'
  },
  button: {
    background: "#fff",
    boxShadow: "unset",
    textDecoration: "none",
    textTransform: "capitalize",
    fontSize: "15px",
    color: "#6B6987",
    fontFamily:'Muli',
    "&:hover": {
      background: "#cecece",
      boxShadow: "unset",
      textDecoration: "none"
      // color: '#ffffff',
    },
  },
  textDecNone: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    },
  },
  tableCard:{
    "@media (max-width: 1340px)":{
      overflow: "auto"
    }
  },
  tableCardBody:{
    padding: 0,
    "@media (max-width: 1340px)":{
      width: "991px",
    }
  },
  fontSp:
  {
    fontSize:'20px',
    color:'#25233C',
    fontFamily:'Muli-Bold'
  }

};

const useStyles = makeStyles(styles);

export default function SPList(props) {
  let { path, url } = useRouteMatch();
  const classes = useStyles();

  const classesNotification = useStylesNotification();

  const [data, setData] = React.useState([]);
  const [dashboardData, setDashboardData] = React.useState(null);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [totalVerification, setTotalVerification] = React.useState(0);
  const [apiCall, setApiCall] = React.useState(false)

  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        setApiCall(true);
        const response = await userService.getAllSPs();
        const res1 = await userService.getSPDashBoardData();
        setDashboardData(res1);
        // setData(response);
        // console.log(response);
        // setData(response.map((prop, key) => {
        //   return {
        //     id: key,
        //     _id: prop._id,
        //     name: prop.user?.name ? prop.user?.name: "not provided",
        //     address: prop.user?.address ? prop.user?.address :"not provided",
        //     mobile_number: prop.user?.mobile_number ? prop.user?.mobile_number :"not provided",
        //     email: prop.user?.email ? prop.user?.email :"not provided",
        //     services_taken: prop.services_taken,
        //     last_activity: prop.last_activity
        //   }
        // }))

        let arr = [];
        const arr2 = response.map((prop, key) => {
          if (prop.user) {

            // this part is done because we have corropt data too after clening only array part will be used

            let address = '';
            if (prop.user.address && typeof prop.user.address === 'string') {
              address = prop.user.address
            }
            else if (prop.user.address && typeof prop.user.address !== 'string') {
              // console.log(prop.user.address);
              prop.user.address.map((addressData) => {
                if (addressData.default_address == "true") {
                  address = addressData.street_address
                }
              })
            }

            // used to format date

            let formatDate = '';
            if (prop.user.last_active_at) {
              formatDate = new Date(prop.user.last_active_at.replace(/-/g, "/")).toDateString().toString().split(' ');
            }

            let obj = {
              id: key,
              _id: prop._id,
              name: prop.user.name ? prop.user.name : "not provided",
              // address: address.length > 0 ? address : "not provided",
              address:prop.place_names?.join(', '),
              mobile_number: prop.user.mobile_number ? prop.user.mobile_number : "not provided",
              email: prop.user.email ? prop.user.email : "not provided",
              services_taken: prop.services?.join(', '),
              last_activity: prop.user.last_active_at ? `${formatDate[1]} ${formatDate[2]}, ${formatDate[3]}` : ''
            }
            arr.push(obj);
          }
        })
        setData([...arr]);
        setApiCall(false)
      }
      catch (err) {
        setApiCall(false)
      }
    };

    fetchUsers();
  }, [props.location.pathname]);

  React.useEffect(() => {
    const getVerifyList = async () => {
      const res = await userService.getSPVerificationList();
      if (res.length > 0) {
        setTotalVerification(res.length)
      }
    }
    getVerifyList();
  }, [props.location.pathname])

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'user.name',
      },
      {
        Header: 'Mobile',
        accessor: 'user.mobile_number',
      },
      {
        Header: 'Email',
        accessor: 'user.email',
      },
      {
        Header: 'Address',
        accessor: 'user.address',
      },
    ],
    []
  )

  function getColumns() {
    let cols = [];
    columns.forEach(item => {
      cols.push({ label: item.Header, key: item.accessor })
    })
    return cols;
  }

  let history = useHistory();
  const showSPProfile = (row) => {
    history.push("/app/service_provider/" + row.original.user.sp_id);
  }

  const showVerificationRequests = () => {
    history.push("/app/service_providers/verification/requests");
  }

  const handleRowClick = (rowData, clickType) => {
    history.push("/app/service_providers/service_provider_detail/" + rowData.original._id);
  };

  return (
    <>
      {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'service_providers' && apiCall == false && <>
        <Grid container spacing={2}>
          <Grid item container md={12} xs={12} style={{justifyContent:'space-between'}}>
            <Grid item md={6} style={{display:'flex',alignItems:'center'}}>
              <h4 className={classes.fontSp} >Service Provider</h4>
            </Grid>
            <Grid item md={6} style={{ textAlign: "right", alignItems: "center", display: "flex", justifyContent: "flex-end", position: 'relative' }} >
              <Link className={classes.textDecNone} to="/app/service_providers/verification/requests" >
                <span className={classesNotification.notifications}> {totalVerification} </span>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<Icon style={{ top: 6 }} color="primary">touch_app</Icon>}
                >
                  Activation Requests
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="body2" component="p" className={classes.regTitle}>
                  Total registered SPs
                </Typography>
                <Typography variant="h5" component="h2" className={classes.userCount}>
                  {dashboardData?.totaluser}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="body2" component="p" className={classes.regTitle}>
                  Active/ Inactivate SPs
                </Typography>
                <Typography variant="h5" component="h2" className={classes.userCount}>
                  {dashboardData?.activeuser}/ {dashboardData?.inactiveuser}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="body2" component="p" className={classes.regTitle}>
                  Monthly Registrations
                </Typography>
                <Typography variant="h5" component="h2" className={classes.userCount}>
                  {dashboardData?.lastmonth_registration}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Divider />
        <div>
          {data && <GridContainer>
            <Grid item xs={12} style={{ padding: "unset !important", margin: "0 -5px" }}>
              <Card className={classes.tableCard}>
                <CardBody className={classes.tableCardBody}>
                  <ReactTable
                    className={'user-table-adj'}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                        placeholder:"name"
                      },
                      {
                        Header: "Location",
                        accessor: "address",
                        placeholder:"location"
                      },
                      {
                        Header: "Contact No.",
                        accessor: "mobile_number",
                        placeholder:"contact no."
                      }, {
                        Header: "Email",
                        accessor: "email",
                        placeholder:"email"
                      },
                      {
                        Header: "Services",
                        accessor: "services_taken",
                        placeholder:"services",
                        Footer: "Services"
                      },
                      {
                        Header: "Last Activity",
                        accessor: "last_activity",
                      }
                    ]}
                    data={data}
                    onRowClick={(rowData, clickType) => handleRowClick(rowData, clickType)}
                  />
                </CardBody>
              </Card>
            </Grid>
          </GridContainer>}
        </div>
      </>}
      {
        apiCall == true && window.location.href.split('/')[window.location.href.split('/').length - 1] == 'service_providers' &&
        <div style={{ display: "flex", justifyContent: 'center', margin: '0 auto', alignItems: 'center', height: '80vh' }}>
          <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
      }


      <div className="container-fluid">
        <Switch>
          <Route path={`${path}/service_provider_detail/edit/:slug`}>
            <FormComponent {...props} />

          </Route>
          <Route path={`${path}/service_provider_detail/newSp`}>
            <FormComponent {...props} />

          </Route>
          <Route path={`${path}/service_provider_detail/:slug`}>
            <ServiceProviderDetail {...props} />
          </Route>
          <Route path={`${path}/verification/requests`}>
            <SPVerification {...props} />
          </Route>
          <Route path={`${path}/verification/detail/:slug`}>
            <VerificationDetail {...props} />
          </Route>
        </Switch>
      </div>
    </>

  );
}
