import { Paper, TableCell } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useEffect, useState } from 'react';
import ReactTable from "_components/ReactTable.js";
import { processRefundService } from '_services/processRefund.service';
import SweetAlert from 'react-bootstrap-sweetalert';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles((theme) => ({
    cover: {
        border: "1px solid grey",
        height: "100%",
        maxWidth: 60,
        width: "100%"
    },
    cardTitle: {
        margin: "unset",
        padding: "0 10px",
        fontSize: 20,
        color: "#6f6c8a"
    },
    cardAction: {
        color: "#000",
    },
    button: {
        margin: theme.spacing(1),
        background: "transparent",
        boxShadow: "unset",
        border: "1px solid #8f8cac",
        textTransform: "capitalize",
        color: "#8f8cac"
    },
    table: {
        margin: "10px 0",
    },
    tcontainer: {
        //   margin:"0 -5px",
    },
    thead: {
        padding: 10
    },
    gridCont: {
        paddingTop: "10px",
        margin: 0,
    },
    gridItem1: {
        display: "flex",
        alignItems: "center",
    },
    gridItem2: {
        textAlign: "right",
    },
    tcell1: {
        border: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell2: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell3: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell4: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell5: {
        border: "unset",
        paddingLeft: "unset",
        paddingBottom: "unset",
    },
    div1: {
        display: "flex",
        alignItems: "center",
        background: "#F3F3FC",
        padding: 10,
        height: 50,
    },
    div2: {
        background: "#F3F3FC",
        padding: 15,
        color: "#6d6b75",
        height: 50,
    },
    div3: {
        background: "#F3F3FC",
        padding: 1,
        height: 50,
    },
    blockTitle: {
        paddingLeft: 10,
        color: "#6d6b75",
    },
    mainCard: {
        marginBottom: '2em'
    },
    formControl: {
        minWidth: '100%'
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        //   backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
        backgroundColor: "#F5F5FC",
        color: "#6c6987",
    },
}))(TableCell);

const ProcessRefundTable = (props) => {

    const classes = useStyles();
    const [alert, setAlert] = useState(null);
    const [refundRequest, setRefundRequest] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [refreshFlag, setRefreshFlag] = useState(false);
    useEffect(() => {
        processRefundService.getProcessRefunds()
            .then((refunds) => {
                setRefundRequest(refunds.map((prop, key) => {
                    return {
                        id: key,
                        serviceprovider_id: prop.serviceprovider.user.name,
                        message: prop.message,
                        status: prop.status,
                        date: prop.date,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* use this button to add a like kind of action */}
                                <Button
                                    onClick={() => {
                                        console.log(prop)
                                        handleClickOpen(prop);
                                    }}
                                >
                                    <SettingsIcon />
                                </Button>{" "}
                            </div>
                        ),
                    };
                }));
            }).catch((err) => {

            })
    }, [refreshFlag === true]);

    function deleteRecord(service) {
        processRefundService.deleteRefundRequest(service._id)
            .then((res) => {

            })
    }

    const handleClickOpen = (ref) => {
        setSelectedRecord(ref);
        setSelectedStatus(ref.status || '');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        // setSelectedValue(value || '');
    };


    function changeStatus(service) {

    }

    function handleSelectChange(event) {
        setSelectedStatus(event.target.value || '');
    }

    function saveChangedStatus() {
        let obj = {
            id: selectedRecord._id,
            status: selectedStatus
        }
        setRefreshFlag(false);
        processRefundService.updateStatus(obj)
            .then((res) => {
                setRefreshFlag(true);
                SweetAlertConfirm();
                handleClose();
            }).catch(err => {

            })
    }

    function SweetAlertConfirm() {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => setAlert(null)}
            >
                Status of Process refund changed
            </SweetAlert>
        )
        setAlert(getAlert());
    }

    return (
        <>
            <div >
                {/* <Card className={`${classes.mainCard} productCategory`} >
                    <GridContainer style={{ paddingTop: "10px", paddingBottom: "10px", margin: 0 }}>
                        <TableContainer className={classes.tcontainer}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead className={classes.thead}>
                                    <TableRow>
                                        <StyledTableCell>Service Provider</StyledTableCell>
                                        <StyledTableCell align="left">Message</StyledTableCell>
                                        <StyledTableCell align="left">Status</StyledTableCell>
                                        <StyledTableCell align="left">Date</StyledTableCell>
                                        <StyledTableCell align="right">Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
                                    {refundRequest.length > 0 && refundRequest.map((service) => (
                                        <TableRow key={service._id}>
                                            <TableCell component="th" className={classes.tcell1}>
                                                <div className={classes.div1}>
                                                    <Typography variant="body1" className={classes.blockTitle}>
                                                        {service.serviceprovider_id}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" className={classes.tcell2}>
                                                <div className={classes.div1}>
                                                    <Typography variant="body1" className={classes.blockTitle}>
                                                        {service.message}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" className={classes.tcell3}>
                                                <div className={classes.div1}>
                                                    <Typography variant="body1" className={classes.blockTitle}>
                                                        {service.status}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell align="left" className={classes.tcell4}>
                                                <div className={classes.div2}>
                                                    {service.date}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" className={classes.tcell5}>
                                                <div className={classes.div3}>
                                                    <IconButton title="Change Status" aria-label="edit" onClick={() => handleClickOpen(service)}>
                                                        <SettingsIcon />
                                                    </IconButton>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </GridContainer>
                </Card> */}
                <Dialog open={open} onClose={(e) => handleClose(e)} aria-labelledby="form-dialog-title"  fullWidth maxWidth="xs">
                    <DialogTitle id="form-dialog-title" style={{ background: "#F5F5FC", color: "#6B6987" }}>Select Status</DialogTitle>
                    <DialogContent style={{paddingTop:20}}>
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="demo-dialog-select-label"
                                id="demo-dialog-select"
                                value={selectedStatus}
                                onChange={(e) => handleSelectChange(e)}
                                input={<Input />} >
                                {/* <MenuItem value={'Requested'}>Requested</MenuItem> */}
                                <MenuItem value={'Processing'}>Processing</MenuItem>
                                <MenuItem value={'Approved'}>Approved</MenuItem>
                                <MenuItem value={'Rejected'}>Rejected</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions style={{display:"flex",justifyContent:"flex-start",padding:"20px"}}>
                        <Button style={{ background: "#6259CA", color: "#fff", padding: "15px 27px" }} onClick={() => saveChangedStatus()} color="primary">
                            Save
                        </Button>
                        <Button onClick={() => handleClose()} color="primary" simple block variant="outlined" style={{ padding: 15, marginLeft: 16 }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Paper style={{padding:"20px 0px"}}>
                    {refundRequest.length > 0 && <ReactTable
                        columns={[
                            {
                                Header: "Service Provider",
                                accessor: "serviceprovider_id",
                            },
                            {
                                Header: "Message",
                                accessor: "message",
                            },
                            {
                                Header: "Status",
                                accessor: "status",
                            },
                            {
                                Header: "Date",
                                accessor: "date",
                            },
                            {
                                Header: "Actions",
                                accessor: "actions",
                            },
                        ]}
                        data={refundRequest}
                        onRowClick={(rowData, clickType) => console.log("")}
                    />}
                </Paper>
            </div>
            {alert}
        </>
    )
}




export default ProcessRefundTable
