import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { Grid, Switch } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import { userService } from "_services/user.service";
import { servicesandpackagesService } from '_services/servicesandpackages.service';
import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    radiobtn: {
        position: "absolute",
        right: 0,
        top: 0,
    }
}));
const AddServices = (props) => {
    const classes = useStyles();
    const [alert, setAlert] = useState(null);
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const routePath = props.location ? props.location.pathname.split('/')[props.location.pathname.split('/').length - 1] : "ediService";
    const isEdit = props.location && props.location.pathname.split('/')[props.location.pathname.split('/').length - 1] == "addservicesandpackages" ? false : true;
    const [selectedSP, setSelectedSP] = useState(props.editData ? props.editData.serviceprovider_id : "");
    const category = props.location.state ? props.location.state.data ?props.location.state.data : props.editData : null;
    const category_id = props.location.state ? props.location.state.data ? props.location.state.data._id : props.location.state.service.subcategory_id : "";
    const [SPList, setSPList] = useState([]);
    useEffect(() => {
        const abortCont = new AbortController();
        if (routePath !== 'addservicesandpackages') {
            setState({ ...state, ["checkedB"]: props.editData.active == "true" ? true : false });
        }
        userService.getAllSPs()
            .then((res) => {
                setSPList(res)
            }).catch(err => {

            })
        return () => abortCont.abort();
    }, [])
    function prePopulateData() {
        return routePath === 'addservicesandpackages' ? {
            name: '',
            description: ''
            // ,sp_id: selectedSP,
            // normal_price: '',
        } : {
            name: props ? props.editData.name : "",
            description: props ? props.editData.description : ""
            // ,sp_id: props ? props.editData.serviceprovider_id : "",
            // normal_price: props ? props.editData.normal_price : "",
        }
    }

    function getToPrevious() {
        const { from } = { from: { pathname: "/app/product_services/services", state: { data: props.location.state.data ? props.location.state.data : null } } };
        props.history.push(from);
    }

    const onHandleSelectChange = (event) => {
        setSelectedSP(event.target.value);
    }
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
    });

    function SweetAlertConfirm(status) {
        const getTrueAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => setAlert(null)}
            >
                Service Activated Successfully
            </SweetAlert>
        )
        const getFalseAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => setAlert(null)}
            >
                Service Deactivated Successfully
            </SweetAlert>
        )
        if (status == "true")
            setAlert(getTrueAlert())
        else {
            setAlert(getFalseAlert())
        }
    }

    function changeStatus(val) {
        let status = "";
        switch (val) {
            case true:
                status = "activateServices";
                break;
            default:
                status = "deactivateServices";

        }
        servicesandpackagesService.serviceStatusChange(status, props.editData._id)
            .then((res) => {                
                SweetAlertConfirm(res.active);
            })
            .catch(err => {

            })
    }

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        changeStatus(event.target.checked);
    };
    function deleteService() {
        servicesandpackagesService.deleteServices(props.editData._id).then((res) => {
            const { from } = { from: { pathname: "/app/product_services/services", state: { data: category } } };
            props.history.push(from);
        })
            .catch(err => {

            })
    }
    return (
        <>
            <div className={classes.container}>
                <GridContainer style={{ width: "100%" }}>
                    <GridItem xs={12} sm={12} md={8}>
                        <div>
                            <div className={classes.header}>
                                <IconButton aria-label="edit" onClick={() => getToPrevious()} style={{paddingLeft:'0px',marginLeft:'-4px'}}>
                                    <ArrowBackIcon />
                                </IconButton>
                                {routePath === 'addservicesandpackages' && <h4 className={""} >Add New Package</h4>}
                                {routePath !== 'addservicesandpackages' && <h4 className={""}>Edit Package</h4>}
                            </div>

                            <Formik
                                initialValues={prePopulateData()}
                                validationSchema={Yup.object().shape({
                                    name: Yup.string().required('Name is required'),
                                    description: Yup.string().required('Description is required'),
                                })}
                                onSubmit={({ name, description, sp_id, normal_price }, { setStatus, setSubmitting }) => {
                                    setStatus();
                                    if (!isEdit) {
                                        const obj = {
                                            "name": name,
                                            "description": description,
                                            "category_id": category_id
                                            // ,"sp_id": selectedSP,
                                            // "normal_price": normal_price,
                                        }
                                        servicesandpackagesService.postServices(obj).then(
                                            res => {
                                                const { from } = { from: { pathname: "/app/product_services/services", state: { data: category } } };
                                                props.history.push(from);
                                            },
                                            error => {
                                                setSubmitting(false);
                                                setStatus(error.name.length ? error.name[0] : "server error");
                                            }
                                        );
                                    }
                                    else {
                                        const editobj = {
                                            "id": props.editData ? props.editData._id : "",
                                            "name": name,
                                            "description": description,
                                            "category_id": category_id
                                            // ,"sp_id": selectedSP,
                                            // "normal_price": normal_price
                                        }
                                        servicesandpackagesService.putServices(editobj).then(
                                            res => {
                                                const { from } = { from: { pathname: "/app/product_services/services", state: { data: category } } };
                                                props.history.push(from);
                                            },
                                            error => {
                                                setSubmitting(false);
                                                setStatus(error.name ? error.name[0] : "server error");
                                            }
                                        );
                                    }

                                }}>
                                {({ errors, status, touched, isSubmitting }) => (
                                    <Form>
                                        <Card className={classes[cardAnimaton]}>
                                            {/* <CardHeader
                                        className={`${classes.cardHeader}`}
                                    >
                                        <h4 className={`${classes.cardTitle} ${classes.h4} `}>Add Category</h4>
                                    </CardHeader> */}
                                            <CardBody className="px-0 pb-0">
                                                <GridContainer spacing={0}>
                                                    {/* {SPList.length > 0 && <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5}>Choose Service Provider</h5>
                                                            <select name="sp_id"
                                                                defaultValue={selectedSP}
                                                                id="sp_id"
                                                                onChange={(e) => onHandleSelectChange(e)}
                                                                className={`${classes.emailInput} ${'form-control' + (errors.sp_id
                                                                    && touched.sp_id ? ' is-invalid' : '')}`} >
                                                                <option value="">Select Service Provider</option>
                                                                {SPList && SPList.map((c, key) => (
                                                                    <option key={key} value={c._id}>
                                                                        {c.user.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <ErrorMessage name="sp_id" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>} */}
                                                    <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5}>Name</h5>
                                                            <Field name="name"
                                                                id="name"
                                                                type="text"
                                                                placeholder="Enter name"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5}>Description</h5>
                                                            <Field name="description"
                                                                id="description"
                                                                placeholder="Enter Description"
                                                                type="text"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.description && touched.description ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>
                                                    {/* <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5}>Price</h5>
                                                            <Field name="normal_price"
                                                                id="normal_price"
                                                                placeholder="Enter Price"
                                                                type="text"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.normal_price && touched.normal_price ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="normal_price" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem> */}

                                                    {isEdit && <div className={[classes.active, classes.radiobtn]}>
                                                        <Switch
                                                            checked={state.checkedB}
                                                            onChange={(e) => handleChange(e)}
                                                            color="primary"
                                                            name="checkedB"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>}
                                                </GridContainer>
                                            </CardBody>
                                            <CardFooter className={classes.btnAlign}>
                                                {/* <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button> */}
                                                <Grid container>
                                                    <GridItem xs={6} sm={6} md={6}>
                                                        <Button type="submit" className={classes.SaveButton} simple size="sm">
                                                            Save
                                                        </Button>
                                                    </GridItem>
                                                    {isSubmitting &&
                                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                    {isEdit && <GridItem xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
                                                        <Button type="button" onClick={() => deleteService()} className={classes.DelButton} simple size="sm">
                                                            Delete
                                                        </Button>
                                                    </GridItem>}
                                                </Grid>
                                                <br />
                                            </CardFooter>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {status &&
                                                        <div className={'alert alert-danger'}>{status}</div>
                                                    }
                                                </div>
                                            </div>
                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </GridItem>
                </GridContainer>
            </div >
            {alert}
        </>
    )
}

export default AddServices
