import "../../../../assets/css/material-dashboard-react.css";

import {
  Avatar,
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import BackGround from "../../../../assets/img/BackgroundImageForm.png";
import Brandlogo from "../../../../assets/img/Brandlogo.png";
import Card from "components/Card/Card.js";
import CompletedAccount from "./steps/CompletedAccount";
import CompletedProfile from "./steps/CompletedProfile";
import CompletedServices from "./steps/CompletedServices";
import CreateAccount from "./steps/CreateAccount";
import { SPService } from "_services/serviceprovider.service";
import ServiceLocation from "./steps/ServiceLocation";
import ServicePackageSetup from "./steps/ServicePackageSetup";
import Step from "@material-ui/core/Step";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import Step6 from "./steps/Step6";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import SweetAlert from "react-bootstrap-sweetalert";
import { authenticationService } from "_services/authentication.service";
import { categoryService } from "_services/category.service";
import { servicesandpackagesService } from "_services/servicesandpackages.service";
import { userService } from "_services/user.service";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8cac",
    "&:hover": {
      background: "#efefef",
      boxShadow: "unset",
      textDecoration: "none",
    },
  },
  formControl: {
    minWidth: "100%",
    marginBottom: 15,
  },

  cardBackground: {
    "& .makeStyles-card-30": {
      background: "red",
    },
  },

  marginTop50px: {
    marginTop: "50px",
  },
  h5: {
    color: "#25233C",
    fontSize: 18,
  },
  selectadj: {
    border: "1px solid #B9B8C4",
    height: 50,
    padding: 15,
    borderRadius: 5,
  },
  formContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    minHeight: "100%",
    padding: "16px",
    "@media(max-width:600px)": {
      paddingInline: "0px",
    },
  },
  boxStyle: {
    marginTop: "30px",
  },
  displayFlexJustifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  marginBottom24: {
    marginBottom: "24px",
  },
  minPaymentSection: {
    display: "flex",
    justifyContent: "space-around",
    width: "65%",
    alignItems: "center",
    marginTop: "30px",
  },
  width44: {
    width: "44%",
  },
  width114px: {
    width: "114px",
  },
  buttonStyle: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
    width: "18%",
  },
  displayFlex: {
    display: "flex",
  },
  textCenterFontBold: {
    textAlign: "center",
    fontWeight: "bold",
  },
  colorTimingFont12: {
    color: "#005CB2",
    fontSize: "12px",
  },
  profileImageUpload: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    border: "1px solid grey",
  },
  imgUploadprofileDisplay: {
    display: "flex",
    alignItems: "center",
  },
  marginLeft20px: {
    marginLeft: "20px",
  },
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid red",
    },
  },
  errorSelectStyle: {
    border: "2px solid red",
    borderRadius: "5px",
    height: "45px",
  },
  displayFlexJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    minHeight: "calc(100vh - 123px)",
  },
  widthDialog: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "450px",
      "@media (max-width:600px)": {
        minWidth: "300px",
      },
    },
  },
  colorStep: {
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#DB0D0D",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#DB0D0D",
    },
  },
  formBackground: {
    minHeight: "100%",
    backgroundImage: `url(${BackGround})`,
    backgroundSize: "cover",
    "@media (min-width:1500px) and (max-width:1810px)": {
      minHeight: "115%",
    },
  },
  mediaMobileScroll: {
    "@media (max-width:600px)": {
      overflowX: "scroll",
    },
  },
}));

const FormComponent = (props) => {
  const [uploadLoader, setUploadLoader] = useState(false);
  const [uploadLoaderIndex, setUploadLoaderIndex] = useState(0);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState({ imgUrl: null });
  // ref for top most div

  const myRef = React.useRef();

  // state for step1 section
  const [about, setAbout] = React.useState("");
  const { slug } = useParams();
  const [workingAt, setWorkingAt] = React.useState("");
  const [experience, setExperience] = React.useState("");
  const [certificate, setCertificate] = React.useState("");
  const [teamSize, setTeamSize] = React.useState("");
  const [locationAvaliable, setLocationAvailable] = React.useState({
    text: "location,location2",
    arr: ["location", "location2"],
  });
  const [checkedCustom, setCheckedCustom] = React.useState(false);
  const [licenseNo, setLicenseNo] = React.useState("");
  const [errorCheckAboutPage, setErorCheckAboutPage] = React.useState({
    about: false,
    locationAvaliable: false,
    experience: false,
    licenseNo: false,
  });
  /////////******************************************************************************* */

  const [currentStep, setCurrentStep] = React.useState(0);

  // state for Step2 section
  const [paymentCheckData, setPaymentCheckData] = React.useState({
    id: "",
    Cash: false,
    Zelle: false,
    Venmo: false,
    PayPal: false,
    CreditCard: false,
    Others: false,
    minimumAdvance: "0",
    minBookingPrice: "",
  });
  // const [errorCheckPaymentOptions, setErrorCheckPaymentOptions] = useState({
  //     minimumBooking: false,
  // })

  /////////******************************************************************************* */

  // state for Step3 section

  const [covidSafetyData, setCovidSafetyData] = React.useState({
    follow: false,
    vaccinated: false,
  });
  /////////******************************************************************************* */

  // state for Step4 section

  const [previousWork, setPreviousWork] = React.useState([
    {
      id: "",
      title: "",
      description: "",
      desLength: "0",
      image: [],
      imageName: [],
      titleLength: "0",
      fullUrl: [],
    },
  ]);

  /////////******************************************************************************* */

  // state for Step5 section

  const [faq, setFaq] = React.useState([
    {
      question: "",
      answer: "",
      answerLength: "",
      questionLength: "",
    },
  ]);

  const [faqFetched, setFaqFetched] = React.useState([{}]);

  /////////******************************************************************************* */

  // state for Step6 section

  const [availabilitySlots, setAvailabilitySlots] = React.useState({
    monday: {
      morning: false,
      evening: false,
      afternoon: false,
    },
    tuesday: {
      morning: false,
      evening: false,
      afternoon: false,
    },
    wednesday: {
      morning: false,
      evening: false,
      afternoon: false,
    },
    thursday: {
      morning: false,
      evening: false,
      afternoon: false,
    },
    friday: {
      morning: false,
      evening: false,
      afternoon: false,
    },
    saturday: {
      morning: false,
      evening: false,
      afternoon: false,
    },
    sunday: {
      morning: false,
      evening: false,
      afternoon: false,
    },
  });

  /////////******************************************************************************* */

  // state for createAccount section
  const [createAcountData, setCreateAcountData] = React.useState({
    id: "",
    uploadImgShow: "",
    fname: "",
    lName: "",
    email: "",
    phone: "",
    profession: "",
    password: "",
    image: null,
    showPassword: false,
    emailOtp: "",
    phoneOtp: "",
  });

  const [
    errorCheckCreateAccountData,
    setErrorCheckCreateAccountData,
  ] = React.useState({
    fname: false,
    phone: false,
    lname: false,
    email: false,
    password: false,
    profession: false,
    emailOtp: false,
    phoneOtp: false,
  });

  const [validEmail, setValidEmail] = React.useState(false);
  const [validPassword, setValidPassword] = React.useState(false);

  const [apiCall, setApiCall] = React.useState(false);

  const [openDialogVerification, setOpenDialogVerification] = React.useState({
    mobile: false,
    email: false,
  });

  const [verificationStatus, setVerificationStatus] = React.useState({
    mobile: { key: "", status: false },
    email: { key: "", status: false },
  });

  /////////******************************************************************************* */

  // state for service location section

  const [
    listOfAllLocationAvailable,
    setListOfAllLocationAvailable,
  ] = React.useState([]);
  const [uniqueServiceLocation, setUniqueServiceLocation] = React.useState([]);
  const [
    wholeDataFromHandleChangeLocation,
    setWholeDataFromHandleChangeLocation,
  ] = React.useState([]);
  const [
    dataTosendServiceLocation,
    setDataToSendServiceLocation,
  ] = React.useState([]);
  const [
    defaultValueServiceLocation,
    setDefaultValueServiceLocation,
  ] = React.useState([]);
  const [placesLocation, setPlacesLocation] = React.useState([]);
  const [errorForServiceLocation, setErrorForServiceLocation] = React.useState(
    false
  );
  const [
    handleChangeSelectServiceLoc,
    setHandleChangeSelectServiceLoc,
  ] = React.useState(false);

  /////////******************************************************************************* */

  // State For service and packages  section

  const [open, setOpen] = React.useState(false);
  const [openPackage, setOpenPackage] = React.useState(false);
  const [categoryArray, setCategoryArray] = React.useState([]);
  const [subCategoryArray, setSubCategotyArray] = React.useState([]);
  const [addedService, setAddedService] = React.useState([]);
  const [errorCheckForService, setErrorCheckForService] = React.useState({
    categoryId: false,
    subcategoryId: false,
    servicesId: false,
    price: false,
    duration: false,
  });

  const [categoryArrayForPackage, setCategoryArrayForPackage] = React.useState(
    []
  );
  const [
    subcategoryArrayForPackage,
    setSubCategoryArrayForPackage,
  ] = React.useState([]);
  const [servicesArrayForPackage, setServicesArrayForPackage] = React.useState(
    []
  );
  const [serviceId, setserviceId] = React.useState([]);
  const [serviceNameDisp, setserviceNameDisp] = React.useState([]);
  const [packageId, setPackageId] = React.useState([]);
  const [priceTotal, setPriceTotal] = React.useState(0);
  const [addedPackage, setAddedPackage] = React.useState([]);
  const [errorCheckForPackage, setErrorCheckForPackage] = React.useState({
    title: false,
    categoryId: false,
    subcategoryId: false,
    servicesId: false,
    price: false,
    duration: false,
  });

  const [servicesArray, setServicesArray] = React.useState([]);
  const [dataSelectServices, setDataSelectServices] = React.useState({
    category: {
      id: "",
      categoryName: "",
    },
    subCategory: {
      id: "",
      subCategoryName: "",
    },
    services: {
      id: "",
      serviceName: "",
    },
    price: "",
    duration: "",
    description: "",
  });

  const [addServiceData, setAddServiceData] = React.useState({
    title: "",
    categoryId: "",
    subCategoryId: "",
    serviceId: "",
    price: "",
    duration: "",
    description: "",
  });

  const [servicesAdded, setServicesAdded] = React.useState("");

  /////////******************************************************************************* */

  // use state for alerts

  const [alertError, setAlertError] = React.useState(null);
  const [alertSuccess, setAlertSuccess] = React.useState(null);
  const [alertDelete, setAlertDelete] = React.useState(null);
  const [render, setRender] = React.useState(false);

  /////////******************************************************************************* */

  // counter state for resend Otp Section ******************************************************************************* */

  const [counter, setCounter] = React.useState(-3);
  const [counterDisplay, setCounterDisplay] = React.useState("");

  React.useEffect(() => {
    const timer =
      counter > -2 &&
      setInterval(() => {
        let time_s = counter;
        let minute = Math.floor(time_s / 60);
        let rest_seconds = time_s % 60;
        setCounterDisplay(
          minute.toString().padStart(2, "0") +
            ":" +
            rest_seconds.toString().padStart(2, "0")
        );
        setCounter(counter - 1);
        if (counter == -1) {
          setCounterDisplay("00:00");
        }
      }, 1000);
    return () => clearInterval(timer);
  }, [counter]);

  /////////******************************************************************************* */

  function SweetAlertConfirm(msg) {
    const getAlert = () => (
      <SweetAlert error title="Oops!" onConfirm={() => setAlertError(null)}>
        {msg}
      </SweetAlert>
    );
    setAlertError(getAlert());
  }

  function SweetAlertConfirmSuccess(msg) {
    const getAlert = () => (
      <SweetAlert
        success
        title="woot"
        onConfirm={() => {
          setAlertSuccess(null);
          setRender(!render);
        }}
      >
        {msg}
      </SweetAlert>
    );
    setAlertSuccess(getAlert());
  }

  function SweetAlertConfirmDelete(
    id,
    parameterForDetectionofServiceOrPacakge
  ) {
    const getAlert = () =>
      id && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Are you sure?"
          onConfirm={() => {
            setAlertDelete(null);
            deleteData(id, parameterForDetectionofServiceOrPacakge);
          }}
          onCancel={() => setAlertDelete(null)}
          focusCancelBtn
        >
          You want to delete this record?
        </SweetAlert>
      );
    setAlertDelete(getAlert());
  }

  React.useEffect(() => {
    const getServiceProviderDetail = async () => {
      try {
        if (
          window.location.href.split("/")[
            window.location.href.split("/").length - 1
          ] !== "newSp"
        ) {
          const responseSpDetail = await userService.getSP(slug);

          if (responseSpDetail) {
            let fullName = responseSpDetail.user.name;
            let detailName = [];
            detailName = fullName.split(" ");
            localStorage.setItem("userEmail", responseSpDetail.user.email);

            setCreateAcountData({
              ...createAcountData,
              id:
                responseSpDetail.user && responseSpDetail.user._id
                  ? responseSpDetail.user._id
                  : "",
              fname:
                responseSpDetail.user && detailName[0] ? detailName[0] : "",
              lName:
                responseSpDetail.user && detailName[1] ? detailName[1] : "",
              email:
                responseSpDetail.user && responseSpDetail.user.email
                  ? responseSpDetail.user.email
                  : "",
              phone:
                responseSpDetail.user && responseSpDetail.user.mobile_number
                  ? responseSpDetail.user.mobile_number
                  : "",
              uploadImgShow:
                responseSpDetail.user && responseSpDetail.user.image_url
                  ? responseSpDetail.user.image_url
                  : "",
              profession:
                responseSpDetail.user && responseSpDetail.user.profession
                  ? responseSpDetail.user.profession
                  : "",
            });
            if (responseSpDetail.faq && responseSpDetail.faq.length > 0) {
              setFaqFetched(responseSpDetail.faq);
              setFaq(responseSpDetail.faq);
            }

            setAbout(
              responseSpDetail.brief_desription
                ? responseSpDetail.brief_desription
                : ""
            );

            setExperience(
              responseSpDetail.experience ? responseSpDetail.experience : ""
            );

            setWorkingAt(
              responseSpDetail.working_at ? responseSpDetail.working_at : ""
            );

            setCertificate(
              responseSpDetail.certificaton_no &&
                responseSpDetail.certificaton_no.length > 0
                ? responseSpDetail.certificaton_no
                : ""
            );

            setLicenseNo(
              responseSpDetail.license_no &&
                responseSpDetail.license_no.length > 0
                ? responseSpDetail.license_no
                : ""
            );

            setTeamSize(
              responseSpDetail.team_size &&
                responseSpDetail.team_size.length > 0
                ? responseSpDetail.team_size
                : ""
            );

            setDefaultValueServiceLocation(
              responseSpDetail.service_location &&
                responseSpDetail.service_location.length > 0
                ? responseSpDetail.service_location
                : []
            );
            setPlacesLocation(
              responseSpDetail.place_names &&
                responseSpDetail.place_names.length > 0
                ? responseSpDetail.place_names
                : []
            );

            if (
              responseSpDetail.service_location &&
              responseSpDetail.service_location.length > 0
            ) {
              setLocationAvailable({
                text: responseSpDetail.service_location.toString(),
                arr: responseSpDetail.service_location,
              });
            }
            setCheckedCustom(
              responseSpDetail.message_for_custom_requirement &&
                responseSpDetail.message_for_custom_requirement == "true"
                ? true
                : false
            );

            setPaymentCheckData({
              ...paymentCheckData,
              minBookingPrice: responseSpDetail.minimum_charge
                ? responseSpDetail.minimum_charge
                : "",
              id: responseSpDetail._id,
              Cash:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes("Cash")
                  ? true
                  : false,
              Venmo:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes("Venmo")
                  ? true
                  : false,
              PayPal:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes("PayPal")
                  ? true
                  : false,
              Zelle:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes("Zelle")
                  ? true
                  : false,
              CreditCard:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes(
                  "Credit Card"
                )
                  ? true
                  : false,
              Others:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes("Others")
                  ? true
                  : false,
              minimumAdvance: responseSpDetail.min_advanced_payment_percentage
                ? responseSpDetail.min_advanced_payment_percentage
                : "0",
            });

            setCovidSafetyData({
              follow:
                responseSpDetail.follow_covid_safety_measures &&
                responseSpDetail.follow_covid_safety_measures == "true"
                  ? true
                  : false,
              vaccinated:
                responseSpDetail.is_vaccinated &&
                responseSpDetail.is_vaccinated == "true"
                  ? true
                  : false,
            });

            if (responseSpDetail.slots_for_service_availability) {
              setAvailabilitySlots({
                monday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.monday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.monday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.monday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                tuesday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.tuesday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.tuesday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.tuesday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                wednesday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.wednesday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.wednesday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.wednesday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                thursday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.thursday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.thursday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.thursday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                friday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.friday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.friday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.friday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                saturday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.saturday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.saturday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.saturday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                sunday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.sunday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.sunday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.sunday
                      .afternoon == "true"
                      ? true
                      : false,
                },
              });
            }
          }
        } else if (
          window.location.href.split("/")[
            window.location.href.split("/").length - 1
          ] == "newSp" &&
          JSON.parse(sessionStorage.getItem("currentUser"))?.user?.role == "111"
        ) {
          setApiCall(true);

          const responseSpDetail = await userService.getSP(
            JSON.parse(sessionStorage.getItem("currentUser")).user.sp_id
          );
          localStorage.setItem(
            "newSpId",
            JSON.parse(sessionStorage.getItem("currentUser")).user.sp_id
          );
          localStorage.setItem(
            "newSpUserId",
            JSON.parse(sessionStorage.getItem("currentUser")).user._id
          );

          if (responseSpDetail) {
            if (
              responseSpDetail.register_step == "completed" &&
              responseSpDetail.credit == 0
            ) {
              setCurrentStep(10);
            } else if (
              responseSpDetail.register_step == "completed" &&
              responseSpDetail.credit > 0
            ) {
              setCurrentStep(12);
            } else if (responseSpDetail.register_step) {
              setCurrentStep(responseSpDetail.register_step + 1);
            } else {
              setCurrentStep(2);
            }

            let fullName = responseSpDetail.user.name;
            let detailName = [];
            detailName = fullName.split(" ");
            localStorage.setItem("userEmail", responseSpDetail.user.email);

            setCreateAcountData({
              ...createAcountData,
              id:
                responseSpDetail.user && responseSpDetail.user._id
                  ? responseSpDetail.user._id
                  : "",
              fname:
                responseSpDetail.user && detailName[0] ? detailName[0] : "",
              lName:
                responseSpDetail.user && detailName[1] ? detailName[1] : "",
              email:
                responseSpDetail.user && responseSpDetail.user.email
                  ? responseSpDetail.user.email
                  : "",
              phone:
                responseSpDetail.user && responseSpDetail.user.mobile_number
                  ? responseSpDetail.user.mobile_number
                  : "",
              uploadImgShow:
                responseSpDetail.user && responseSpDetail.user.image_url
                  ? responseSpDetail.user.image_url
                  : "",
              profession:
                responseSpDetail.user && responseSpDetail.user.profession
                  ? responseSpDetail.user.profession
                  : "",
            });

            if (responseSpDetail.faq && responseSpDetail.faq.length > 0) {
              setFaqFetched(responseSpDetail.faq);
              setFaq(responseSpDetail.faq);
            }

            setAbout(
              responseSpDetail.brief_desription
                ? responseSpDetail.brief_desription
                : ""
            );

            setExperience(
              responseSpDetail.experience ? responseSpDetail.experience : ""
            );

            setWorkingAt(
              responseSpDetail.working_at ? responseSpDetail.working_at : ""
            );

            setCertificate(
              responseSpDetail.certificaton_no &&
                responseSpDetail.certificaton_no.length > 0
                ? responseSpDetail.certificaton_no
                : ""
            );

            setLicenseNo(
              responseSpDetail.license_no &&
                responseSpDetail.license_no.length > 0
                ? responseSpDetail.license_no
                : ""
            );

            setTeamSize(
              responseSpDetail.team_size &&
                responseSpDetail.team_size.length > 0
                ? responseSpDetail.team_size
                : ""
            );

            setDefaultValueServiceLocation(
              responseSpDetail.service_location &&
                responseSpDetail.service_location.length > 0
                ? responseSpDetail.service_location
                : []
            );
            setPlacesLocation(
              responseSpDetail.place_names &&
                responseSpDetail.place_names.length > 0
                ? responseSpDetail.place_names
                : []
            );

            if (
              responseSpDetail.service_location &&
              responseSpDetail.service_location.length > 0
            ) {
              setLocationAvailable({
                text: responseSpDetail.service_location.toString(),
                arr: responseSpDetail.service_location,
              });
            }
            setCheckedCustom(
              responseSpDetail.message_for_custom_requirement &&
                responseSpDetail.message_for_custom_requirement == "true"
                ? true
                : false
            );

            setPaymentCheckData({
              ...paymentCheckData,
              minBookingPrice: responseSpDetail.minimum_charge
                ? responseSpDetail.minimum_charge
                : "",
              id: responseSpDetail._id,
              Cash:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes("Cash")
                  ? true
                  : false,
              Venmo:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes("Venmo")
                  ? true
                  : false,
              PayPal:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes("PayPal")
                  ? true
                  : false,
              Zelle:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes("Zelle")
                  ? true
                  : false,
              CreditCard:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes(
                  "Credit Card"
                )
                  ? true
                  : false,
              Others:
                responseSpDetail.accepted_payment_methods &&
                responseSpDetail.accepted_payment_methods.includes("Others")
                  ? true
                  : false,
              minimumAdvance: responseSpDetail.min_advanced_payment_percentage
                ? responseSpDetail.min_advanced_payment_percentage
                : "0",
            });

            setCovidSafetyData({
              follow:
                responseSpDetail.follow_covid_safety_measures &&
                responseSpDetail.follow_covid_safety_measures == "true"
                  ? true
                  : false,
              vaccinated:
                responseSpDetail.is_vaccinated &&
                responseSpDetail.is_vaccinated == "true"
                  ? true
                  : false,
            });

            if (responseSpDetail.slots_for_service_availability) {
              setAvailabilitySlots({
                monday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.monday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.monday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.monday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                tuesday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.tuesday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.tuesday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.tuesday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                wednesday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.wednesday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.wednesday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.wednesday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                thursday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.thursday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.thursday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.thursday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                friday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.friday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.friday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.friday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                saturday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.saturday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.saturday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.saturday
                      .afternoon == "true"
                      ? true
                      : false,
                },
                sunday: {
                  morning:
                    responseSpDetail.slots_for_service_availability.sunday
                      .morning == "true"
                      ? true
                      : false,
                  evening:
                    responseSpDetail.slots_for_service_availability.sunday
                      .evening == "true"
                      ? true
                      : false,
                  afternoon:
                    responseSpDetail.slots_for_service_availability.sunday
                      .afternoon == "true"
                      ? true
                      : false,
                },
              });
            }

            setApiCall(false);
          } else {
            setApiCall(false);
          }
        }
      } catch (err) {
        setApiCall(false);
      }
    };

    getServiceProviderDetail();
  }, []);

  React.useEffect(() => {
    if (listOfAllLocationAvailable.length > 0) {
      let zipCodes = [];
      let cityTown = [];
      let unique = [];
      listOfAllLocationAvailable.map((prop, index) => {
        cityTown.push(prop["city/town"]);
        zipCodes.push(prop.zipcode);
      });
      unique = [...new Set(cityTown), ...new Set(zipCodes)];
      setUniqueServiceLocation([...unique]);
    }
  }, [listOfAllLocationAvailable]);

  React.useEffect(() => {
    const getZipCodeAndCategory = async () => {
      if (currentStep > 2) {
        try {
          let allZipcodeResponse = await categoryService.getAllZipCodes();
          setListOfAllLocationAvailable([...allZipcodeResponse]);

          let categoryArr = [];
          const response = await categoryService.getCategory();
          categoryArr = response.map((prop, index) => {
            return {
              id: prop._id,
              name: prop.name,
            };
          });
          setCategoryArray(categoryArr);
        } catch (err) {}
      }
    };

    getZipCodeAndCategory();
  }, [currentStep]);

  // * first step functions *****************************************************************************************************************************

  const handleOnChangeAbout = (e) => {
    if (e.target.value.length > 0) {
      setErorCheckAboutPage({ ...errorCheckAboutPage, about: false });
      setAbout(e.target.value);
    } else {
      setErorCheckAboutPage({ ...errorCheckAboutPage, about: true });
      setAbout(e.target.value);
    }
  };
  const handleOnChangeWorkingAt = (e) => {
    setWorkingAt(e.target.value);
  };
  const handleOnChangeSelect = (e) => {
    if (e.target.value.length > 0) {
      setErorCheckAboutPage({ ...errorCheckAboutPage, experience: false });
      setExperience(e.target.value);
    } else {
      if (e.target.value.length > 0) {
        setErorCheckAboutPage({ ...errorCheckAboutPage, experience: true });
        setExperience(e.target.value);
      }
    }
  };
  const handleOnChangeLicenseNo = (e) => {
    if (e.target.value.length > 0) {
      setErorCheckAboutPage({ ...errorCheckAboutPage, licenseNo: false });
      setLicenseNo(e.target.value);
    } else {
      setErorCheckAboutPage({ ...errorCheckAboutPage, licenseNo: true });
      setLicenseNo(e.target.value);
    }
  };

  const handleOnChangeCertificate = (e) => {
    setCertificate(e.target.value);
  };

  const handleOnChangeSelectTeam = (e) => {
    setTeamSize(e.target.value);
  };
  const handleOnChangeLocationAvailable = (e) => {
    let arrayConvert = [];
    if (e.target.value.length > 0) {
      arrayConvert = e.target.value.split(",");
      setErorCheckAboutPage({
        ...errorCheckAboutPage,
        locationAvaliable: false,
      });
      setLocationAvailable({ arr: arrayConvert, text: e.target.value });
    } else {
      arrayConvert = e.target.value.split(",");
      setErorCheckAboutPage({
        ...errorCheckAboutPage,
        locationAvaliable: true,
      });
      setLocationAvailable({ arr: arrayConvert, text: e.target.value });
    }
  };
  const handleCheckBoxCustomRequest = (e) => {
    setCheckedCustom(!checkedCustom);
  };
  /////////////////// *****************************************************************************************************************************

  // * Second step functions  *****************************************************************************************************************************

  const handleCheckBoxPaymentCash = (event) => {
    setPaymentCheckData((prev) => {
      if ((event.target.name = "Cash")) {
        return {
          ...paymentCheckData,
          Cash: !prev.Cash,
        };
      }
    });
  };

  const handleCheckBoxPaymentZelle = (event) => {
    setPaymentCheckData((prev) => {
      if ((event.target.name = "Zelle")) {
        return {
          ...paymentCheckData,
          Zelle: !prev.Zelle,
        };
      }
    });
  };
  const handleCheckBoxPaymentVenmo = (event) => {
    setPaymentCheckData((prev) => {
      if ((event.target.name = "Venmo")) {
        return {
          ...paymentCheckData,
          Venmo: !prev.Venmo,
        };
      }
    });
  };

  const handleCheckBoxPaymentPayPal = (event) => {
    setPaymentCheckData((prev) => {
      if ((event.target.name = "PayPal")) {
        return {
          ...paymentCheckData,
          PayPal: !prev.PayPal,
        };
      }
    });
  };

  const handleCheckBoxPaymentCreditCard = (event) => {
    setPaymentCheckData((prev) => {
      if ((event.target.name = "Credit Card")) {
        return {
          ...paymentCheckData,
          CreditCard: !prev.CreditCard,
        };
      }
    });
  };

  const handleCheckBoxOthers = (event) => {
    setPaymentCheckData((prev) => {
      if ((event.target.name = "Others")) {
        return {
          ...paymentCheckData,
          Others: !prev.Others,
        };
      }
    });
  };

  const handleOnChangeMinAdvancePaymentSelect = (event) => {
    setPaymentCheckData((prev) => {
      if ((event.target.name = "minimumAdvance")) {
        return {
          ...paymentCheckData,
          minimumAdvance: event.target.value,
        };
      }
    });
  };

  const handleOnChangeMinBookingPrice = (event) => {
    setPaymentCheckData((prev) => {
      if ((event.target.name = "minimumBookingPrice")) {
        let str = event.target.value.replace("$", "");
        if (str.match(/^[0-9]+$/)) {
          return {
            ...paymentCheckData,
            minBookingPrice: `$${str}`,
          };
        } else {
          return {
            ...paymentCheckData,
            minBookingPrice: "",
          };
        }
      }
    });
  };

  const handleNextPaymentMethod = () => {
    const paymentArray = [];
    if (paymentCheckData.Cash == true) {
      paymentArray.push("Cash");
    }
    if (paymentCheckData.Venmo == true) {
      paymentArray.push("Venmo");
    }
    if (paymentCheckData.Zelle == true) {
      paymentArray.push("Zelle");
    }
    if (paymentCheckData.PayPal == true) {
      paymentArray.push("PayPal");
    }
    if (paymentCheckData.CreditCard == true) {
      paymentArray.push("Credit Card");
    }
    if (paymentCheckData.Others == true) {
      paymentArray.push("Others");
    }
    if (paymentArray.length == 0) {
      return SweetAlertConfirm(
        "At least one payment method should be selected"
      );
    } else {
      handleNext();
    }
  };

  //////// *****************************************************************************************************************************

  // * Step3 functions *****************************************************************************************************************************

  const handleCheckBoxFollowRules = (e) => {
    setCovidSafetyData({ ...covidSafetyData, follow: !covidSafetyData.follow });
  };
  const handleCheckBoxFullyVaccinated = (e) => {
    setCovidSafetyData({
      ...covidSafetyData,
      vaccinated: !covidSafetyData.vaccinated,
    });
  };

  ////////*****************************************************************************************************************************

  // Step 4 functions *****************************************************************************************************************************

  // API CALL
  async function postPreviousWorkImages(formData) {
    try {
      let requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Connection: "keep-alive",
      };
      const response = await fetch(
        "https://bookapro.ebizonstaging.com/LumenConnection/public/api/v1/app/SpAppController/tempPreviousWork",
        requestOptions
      );
      return { ok: response.ok, data: await response.json() };
    } catch (e) {
      console.log(e);
    }
  }

  const previousWorkImageUpload = async (imageArray, index) => {
    setUploadLoader(true);
    setUploadLoaderIndex(index);
    let formData = new FormData();
    let spId;
    if (
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] === "newSp"
    ) {
      spId = localStorage.getItem("newSpId");
    } else spId = slug;

    formData.append("id", spId);
    formData.append("Previndex", index);
    imageArray.forEach((item) => {
      formData.append("imagefile[]", item, item.name);
    });

    let imageUpload = await postPreviousWorkImages(formData);
    if (imageUpload?.ok) {
      setUploadLoader(false);
      let temp = [...previousWork];
      temp.map((item, idx) => {
        if (idx === index) {
          imageUpload?.data?.url?.map((url) => item.imageName.push(url));
          imageUpload?.data?.full_url?.map((fullUrl) =>
            item?.fullUrl?.push(fullUrl)
          );
        }
      });
      setPreviousWork(temp);
    } else {
      setUploadLoader(false);
    }
  };

  const handleOnChangePreviousWork = (index, event) => {
    const values = [...previousWork];
    let fileArray = [];
    if (event.target.name == "description") {
      values[index]["desLength"] = event.target.value.length;
    }

    if (event.target.name == "title") {
      values[index]["titleLength"] = event.target.value.length;
    }

    if (event.target.name == "title" || event.target.name == "description") {
      values[index][event.target.name] = event.target.value;
    } else {
      if (previousWork[index]?.image?.length + event.target.files.length <= 5) {
        fileArray = Array.from(event.target.files).map((file) => {
          return file;
        });
        // values[index]['image'] = [...values[index]['image'], ...fileArray];
      }
      previousWorkImageUpload(fileArray, index);
    }

    setPreviousWork(values);
  };

  const removePrevWorkImage = (indexOfPrevWork, arrayPosition) => {
    const values = [...previousWork];

    // for (var i = 0; i < values[indexOfPrevWork]['image'].length; i++) {
    // if (i === arrayPosition) {
    values[indexOfPrevWork]["image"] = values[indexOfPrevWork]["image"].filter(
      (element, index) => index !== arrayPosition
    );
    values[indexOfPrevWork]["imageName"] = values[indexOfPrevWork][
      "imageName"
    ].filter((element, index) => index !== arrayPosition);
    // }
    // }
    setPreviousWork(values);
  };

  const removePrevWork = async (indexOfPrevWork, id) => {
    if (id) {
      let apiBody = { id: id };
      let deleteApiResponse = await userService.deletePreviousWork(apiBody);
      if (deleteApiResponse?.status === "success") {
        let temp = previousWork?.filter((element, i) => i !== indexOfPrevWork);
        setPreviousWork([...temp]);
        SweetAlertConfirmSuccess("Previous work deleted successfully");
      }
    } else {
      let arr = previousWork.filter((data, index) => index !== indexOfPrevWork);
      setPreviousWork(arr);
    }
  };

  const handleMoreField = () => {
    if (
      previousWork[previousWork.length - 1].title.split(" ").join("").length >
        0 &&
      previousWork[previousWork.length - 1].description.split(" ").join("")
        .length > 0
    ) {
      setPreviousWork([
        ...previousWork,
        {
          id: "",
          title: "",
          description: "",
          image: [],
          imageName: [],
          fullUrl: [],
        },
      ]);
    } else {
      SweetAlertConfirm("Fill Empty values first");
    }
  };

  const handlePreviousSave = () => {
    const tempArray = JSON.parse(JSON.stringify(previousWork));

    let error = 0;

    previousWork.map((prop) => {
      if (
        prop.title.split(" ").join("").length > 0 &&
        prop.description.split(" ").join("").length > 0
      ) {
        error = error;
      } else if (
        prop.title.split(" ").join("").length > 0 &&
        prop.description.split(" ").join("").length == 0
      ) {
        error = error + 1;
      } else if (
        prop.title.split(" ").join("").length == 0 &&
        prop.description.split(" ").join("").length > 0
      ) {
        error = error + 1;
      } else if (
        prop.title.split(" ").join("").length == 0 &&
        prop.description.split(" ").join("").length == 0
      ) {
        if (prop.image.length > 0) {
          error = error + 1;
        } else {
          error = error;
        }
      }
    });

    if (error > 0) {
      SweetAlertConfirm("Fill all empty values First");
    }

    if (error == 0) {
      let previousWorkToSend = [];
      previousWork.map((prop) => {
        if (
          prop.title.split(" ").join("").length > 0 &&
          prop.description.split(" ").join("").length > 0
        ) {
          previousWorkToSend.push(prop);
        }
      });
      if (previousWorkToSend.length > 0) {
        tempArray?.map((item) => {
          item.fullUrl.map((data) => {
            item.imageName.map((data1, index) => {
              if (data1?.includes(data)) {
                item.imageName.splice(index, 1, data);
              }
            });
          });
        });

        let obj = {
          id:
            window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ] == "newSp"
              ? localStorage.getItem("newSpId")
              : slug,
          previousWork: tempArray,
        };
        setApiCall(true);
        SPService.updatePreviousWork(obj)
          .then((res) => {
            setApiCall(false);
            setCurrentStep(currentStep + 1);
          })
          .catch((error) => {
            SweetAlertConfirm("Something went wrong");
            setApiCall(false);
          });
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  // *****************************************************************************************************************************

  // Step 5 functions *****************************************************************************************************************************

  const handleOnChangeFaq = (index, event) => {
    const values = [...faq];
    if (event.target.name == "question" || event.target.name == "answer") {
      values[index][event.target.name] = event.target.value;
    }
    if (event.target.name == "question") {
      values[index]["questionLength"] = event.target.value.length;
    }
    if (event.target.name == "answer") {
      values[index]["answerLength"] = event.target.value.length;
    }
    setFaq(values);
  };

  const handleMoreFieldFaq = () => {
    if (
      faq[faq.length - 1].question.length > 0 &&
      faq[faq.length - 1].answer.length > 0
    ) {
      setFaq([...faq, { question: "", answer: "", answerLength: "" }]);
    } else {
      SweetAlertConfirm("Fill Empty values first");
    }
  };

  const handleNextFaq = () => {
    let str = 0;
    faq.map((prop) => {
      if (
        prop.question.split(" ").join("").length == 0 &&
        prop.answer.split(" ").join("").length == 0
      ) {
        str = str;
      } else if (
        prop.question.split(" ").join("").length == 0 &&
        prop.answer.split(" ").join("").length > 0
      ) {
        str = str + 1;
      } else if (
        prop.question.split(" ").join("").length > 0 &&
        prop.answer.split(" ").join("").length == 0
      ) {
        str = str + 1;
      } else {
        str = str;
      }
    });
    if (str > 0) {
      SweetAlertConfirm("Please fill all the empty feilds");
    } else if (str == 0) {
      handleNext();
    }
  };

  //////// *****************************************************************************************************************************

  // Step 6 functions *****************************************************************************************************************************

  const handleCheckBoxAllFieldsTimingSlot = (name) => {
    if (
      availabilitySlots[name].morning == false &&
      availabilitySlots[name].afternoon == false &&
      availabilitySlots[name].evening == false
    ) {
      setAvailabilitySlots({
        ...availabilitySlots,
        [name]: {
          morning: true,
          afternoon: true,
          evening: true,
        },
      });
    } else if (
      availabilitySlots[name].morning == true &&
      availabilitySlots[name].afternoon == true &&
      availabilitySlots[name].evening == true
    ) {
      setAvailabilitySlots({
        ...availabilitySlots,
        [name]: {
          morning: false,
          afternoon: false,
          evening: false,
        },
      });
    } else {
      setAvailabilitySlots({
        ...availabilitySlots,
        [name]: {
          morning: true,
          afternoon: true,
          evening: true,
        },
      });
    }
  };

  const handleCheckBoxDayTimeDay = (dayTime, day) => {
    const EventName = dayTime;
    const dayName = day;
    let temp = availabilitySlots;
    temp[dayName][EventName] = !temp[dayName][EventName];
    setAvailabilitySlots({ ...temp });
  };

  /////// *****************************************************************************************************************************

  /// servive location functions *****************************************************************************************************************************

  const handleChangeServiceLocation = (e) => {
    setHandleChangeSelectServiceLoc(true);
    if (e.length > 0) {
      setErrorForServiceLocation(false);
      setWholeDataFromHandleChangeLocation([...e]);
    } else {
      setErrorForServiceLocation(true);
      setWholeDataFromHandleChangeLocation([]);
    }
  };

  const handleNextServiceLocation = () => {
    if (
      wholeDataFromHandleChangeLocation.length > 0 &&
      handleChangeSelectServiceLoc == true
    ) {
      setErrorForServiceLocation(false);
      let arr = [];
      let places = [];

      wholeDataFromHandleChangeLocation.map((prop) => {
        places.push(prop.value);
      });

      setPlacesLocation([...places]);

      wholeDataFromHandleChangeLocation.map((prop) => {
        if (isNaN(parseInt(prop.value))) {
          listOfAllLocationAvailable.map((data) => {
            if (data["city/town"] === prop.value) {
              arr.push(data.zipcode);
            }
          });
        } else {
          arr.push(prop.value);
        }
      });
      let dataToSendArray = [...new Set(arr)];
      setDataToSendServiceLocation([...dataToSendArray]);
    } else if (
      wholeDataFromHandleChangeLocation.length == 0 &&
      handleChangeSelectServiceLoc == false
    ) {
      if (defaultValueServiceLocation.length == 0) {
        setErrorForServiceLocation(true);
      }
      setDataToSendServiceLocation([...defaultValueServiceLocation]);
    } else if (
      wholeDataFromHandleChangeLocation.length == 0 &&
      handleChangeSelectServiceLoc == true
    ) {
      setErrorForServiceLocation(true);
    }
  };

  React.useEffect(() => {
    if (dataTosendServiceLocation.length > 0) {
      handleSaveSpDetail();
    }
  }, [dataTosendServiceLocation]);

  /////// *****************************************************************************************************************************

  /// CreateAccount functions *****************************************************************************************************************************

  const handleImageUploadProfile = (event) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setCreateAcountData({
          ...createAcountData,
          uploadImgShow: reader.result,
          image: event.target.files[0],
        });
      }
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const sendOtpEmailVerification = () => {
    if (createAcountData.fname.length > 0) {
      setCreateAcountData({ ...createAcountData, emailOtp: "" });
      setApiCall(true);
      let obj = {
        name: createAcountData.fname,
        email: createAcountData.email,
      };

      SPService.getServiceProviderEmailOtp(obj)
        .then((res) => {
          sessionStorage.setItem("emailOtpData", JSON.stringify(res));
          setCounter(90);

          setOpenDialogVerification({ ...openDialogVerification, email: true });
          setApiCall(false);
        })
        .catch((error) => {
          setApiCall(false);
          SweetAlertConfirm(error);
        });
    } else {
      setErrorCheckCreateAccountData({
        ...errorCheckCreateAccountData,
        fname: true,
      });
    }
  };

  const sendOtpMobileVerification = () => {
    if (createAcountData.phone.length > 9) {
      setCreateAcountData({ ...createAcountData, phoneOtp: "" });
      setApiCall(true);
      let phone = createAcountData.phone;
      if (createAcountData.phone.includes("(")) {
        phone = phone.replace(/[^\w\s]/gi, "");
        phone = phone.substring(1);
      }

      let obj = {
        mobile_number: "+1" + phone,
      };

      SPService.getServiceProviderMobileOtp(obj)
        .then((res) => {
          if (res.data == "pending") {
            setCounter(90);
            setOpenDialogVerification({
              ...openDialogVerification,
              mobile: true,
            });
            setApiCall(false);
          }
        })
        .catch((error) => {
          setApiCall(false);
          SweetAlertConfirm(error);
        });
    } else {
      setErrorCheckCreateAccountData({
        ...errorCheckCreateAccountData,
        fname: true,
      });
    }
  };

  const verifyOtp = () => {
    if (createAcountData.phoneOtp.length > 5) {
      setApiCall(true);

      // format the string contaning brackets to normal numbers ************************************************************************************

      let phone = createAcountData.phone;
      if (createAcountData.phone.includes("(")) {
        phone = phone.replace(/[^\w\s]/gi, "");
        phone = phone.substring(1);
      }

      let obj = {
        mobile_number: "+1" + phone,
        verification_code: createAcountData.phoneOtp,
      };

      SPService.serviceProviderMobileVerification(obj)
        .then((res) => {
          if (res.data == "approved") {
            setApiCall(false);
            setOpenDialogVerification({
              ...openDialogVerification,
              mobile: false,
            });
            setVerificationStatus({
              ...verificationStatus,
              mobile: { key: createAcountData.phone, status: true },
            });
          } else {
            SweetAlertConfirm("Incorrect OTP");
            setApiCall(false);
          }
        })
        .catch((error) => {
          SweetAlertConfirm("Incorrect OTP");
          setApiCall(false);
        });
    } else {
      setErrorCheckCreateAccountData({
        ...errorCheckCreateAccountData,
        phoneOtp: true,
      });
    }
  };

  const verifyEmail = () => {
    if (createAcountData.emailOtp.length > 5) {
      let emailOtpData = JSON.parse(sessionStorage.getItem("emailOtpData"));
      let obj = {
        verification_time: emailOtpData.verificationtime,
        verification_code: emailOtpData.verificationcode,
        user_verification_code: createAcountData.emailOtp,
        email: emailOtpData.email,
        name: emailOtpData.name,
      };

      setApiCall(true);

      SPService.serviceProviderEmailVerification(obj)
        .then((res) => {
          setApiCall(false);
          setOpenDialogVerification({
            ...openDialogVerification,
            email: false,
          });
          setValidEmail(false);
          setVerificationStatus({
            ...verificationStatus,
            email: { key: createAcountData.email, status: true },
          });
        })
        .catch((error) => {
          SweetAlertConfirm("Invalid OTP");
          setApiCall(false);
        });
    } else {
      setErrorCheckCreateAccountData({
        ...errorCheckCreateAccountData,
        emailOtp: true,
      });
    }
  };

  const handleOnChangeCreateAccount = (event) => {
    if (event.target.name == "email") {
      if (event.target.value.length > 0) {
        // this below if is for email otp verification
        if (event.target.value !== verificationStatus.email.key) {
          setVerificationStatus({
            ...verificationStatus,
            email: { key: verificationStatus.email.key, status: false },
          });
        } else {
          setVerificationStatus({
            ...verificationStatus,
            email: { key: verificationStatus.email.key, status: true },
          });
        }
        // below if is for regex of email
        if (
          event.target.value.match(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
          )
        ) {
          setValidEmail(false);
          setErrorCheckCreateAccountData({
            ...errorCheckCreateAccountData,
            [event.target.name]: false,
          });
          setCreateAcountData({
            ...createAcountData,
            [event.target.name]: event.target.value,
          });
        } else {
          setValidEmail(true);
          setErrorCheckCreateAccountData({
            ...errorCheckCreateAccountData,
            [event.target.name]: false,
          });
          setCreateAcountData({
            ...createAcountData,
            [event.target.name]: event.target.value,
          });
        }
      } else {
        setErrorCheckCreateAccountData({
          ...errorCheckCreateAccountData,
          [event.target.name]: true,
        });
        setCreateAcountData({
          ...createAcountData,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name == "password") {
      if (event.target.value.length > 0) {
        if (
          event.target.value.match(
            /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
          )
        ) {
          setValidPassword(true);
          setErrorCheckCreateAccountData({
            ...errorCheckCreateAccountData,
            [event.target.name]: false,
          });
          setCreateAcountData({
            ...createAcountData,
            [event.target.name]: event.target.value,
          });
        } else {
          setValidPassword(false);
          setErrorCheckCreateAccountData({
            ...errorCheckCreateAccountData,
            [event.target.name]: false,
          });
          setCreateAcountData({
            ...createAcountData,
            [event.target.name]: event.target.value,
          });
        }
      } else {
        setErrorCheckCreateAccountData({
          ...errorCheckCreateAccountData,
          [event.target.name]: true,
        });
        setCreateAcountData({
          ...createAcountData,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name == "phone") {
      if (event.target.value.length > 0) {
        if (!isNaN(event.target.value) && event.target.value.length < 11) {
          if (event.target.value !== verificationStatus.mobile.key) {
            setVerificationStatus({
              ...verificationStatus,
              mobile: { key: verificationStatus.mobile.key, status: false },
            });
          } else {
            setVerificationStatus({
              ...verificationStatus,
              mobile: { key: verificationStatus.mobile.key, status: true },
            });
          }
          // below if is for handling otp verification
          if (event.target.value.length > 9) {
            if (event.target.value.match(/^\d*[.]?\d*$/)) {
              let cleaned = "" + event.target.value.replace(/\D/g, "");
              let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
              if (match) {
                let intlCode = match[1] ? "+1 " : "",
                  number = [
                    intlCode,
                    "(",
                    match[2],
                    ")",
                    match[3],
                    "-",
                    match[4],
                  ].join("");
                setCreateAcountData({
                  ...createAcountData,
                  phone: `+1${number}`,
                });
                setErrorCheckCreateAccountData({
                  ...errorCheckCreateAccountData,
                  [event.target.name]: false,
                });
              } else {
                setErrorCheckCreateAccountData({
                  ...errorCheckCreateAccountData,
                  [event.target.name]: false,
                });
                setCreateAcountData({
                  ...createAcountData,
                  [event.target.name]: event.target.value,
                });
              }
            }
          } else {
            let phone = event.target.value.replace(/[^\w\s]/gi, "");
            // phone = phone.replace('1', '');
            if (phone.length < 11) {
              setCreateAcountData({
                ...createAcountData,
                [event.target.name]: phone,
              });
            }
          }
        } else {
          if (event.target.value.length > 10) {
            let phone = event.target.value.replace(/[^\w\s]/gi, "");
            phone = phone.replace("1", "");
            if (phone.length < 11) {
              setCreateAcountData({
                ...createAcountData,
                [event.target.name]: phone,
              });
            }
          }
        }
      } else {
        setErrorCheckCreateAccountData({
          ...errorCheckCreateAccountData,
          [event.target.name]: true,
        });
        setCreateAcountData({
          ...createAcountData,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name == "profession") {
      if (event.target.value.length > 0) {
        setErrorCheckCreateAccountData({
          ...errorCheckCreateAccountData,
          [event.target.name]: false,
        });
        setCreateAcountData({
          ...createAcountData,
          [event.target.name]: event.target.value,
        });
      } else {
        setErrorCheckCreateAccountData({
          ...errorCheckCreateAccountData,
          [event.target.name]: true,
        });
        setCreateAcountData({
          ...createAcountData,
          [event.target.name]: event.target.value,
        });
      }
    } else {
      if (event.target.value.match(/^\S*$/)) {
        if (event.target.value.length > 0) {
          setErrorCheckCreateAccountData({
            ...errorCheckCreateAccountData,
            [event.target.name]: false,
          });
          setCreateAcountData({
            ...createAcountData,
            [event.target.name]: event.target.value,
          });
        } else {
          setErrorCheckCreateAccountData({
            ...errorCheckCreateAccountData,
            [event.target.name]: true,
          });
          setCreateAcountData({
            ...createAcountData,
            [event.target.name]: event.target.value,
          });
        }
      }
    }
  };

  const handleTogglePassword = () => {
    setCreateAcountData({
      ...createAcountData,
      showPassword: !createAcountData.showPassword,
    });
  };

  const saveCreateAccount = () => {
    if (
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == "newSp" &&
      createAcountData.image == null
    ) {
      SweetAlertConfirm("Please upload an image to continue");
    } else if (createAcountData.fname.split(" ").join("").length == 0) {
      setErrorCheckCreateAccountData({
        fname: true,
        phone: false,
        email: false,
        lname: false,
        password: false,
        profession: false,
      });
    } else if (createAcountData.email.split(" ").join("").length == 0) {
      setErrorCheckCreateAccountData({
        fname: false,
        phone: false,
        email: true,
        lname: false,
        password: false,
        profession: false,
      });
    } else if (createAcountData.phone.length == 0) {
      setErrorCheckCreateAccountData({
        fname: false,
        phone: true,
        email: false,
        lname: false,
        password: false,
        profession: false,
      });
    } else if (createAcountData.profession.length == 0) {
      setErrorCheckCreateAccountData({
        fname: false,
        phone: false,
        email: false,
        lname: false,
        password: false,
        profession: true,
      });
    } else {
      const obj = {
        id: createAcountData.id,
        mobile_number: createAcountData.phone,
        name: createAcountData.fname + " " + createAcountData.lName,
        firstName: createAcountData?.fname,
        lastName: createAcountData?.lName,
        profession: createAcountData.profession,
        email: createAcountData.email,
        role: "111",
        address: [],
      };

      if (createAcountData.image) {
        const obj2 = {
          id: createAcountData.id,
          image: createAcountData.image,
        };
        // for image upload only
        userService.userImageUpdate(obj2).then((res) => {});
      }

      if (localStorage.getItem("userEmail") != createAcountData.email) {
        obj.email = data.email;
      }

      // for data other then image upload
      setApiCall(true);
      userService
        .putUser(obj)
        .then((res) => {
          setCurrentStep(currentStep + 1);
          setApiCall(false);
        })
        .catch((err) => {
          SweetAlertConfirm("Error occurred");
          setCurrentStep(currentStep + 1);
          setApiCall(false);
        });
    }
  };

  const createNewSp = () => {
    if (
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == "newSp" &&
      createAcountData.image == null
    ) {
      SweetAlertConfirm("Please upload an image to continue");
    } else if (createAcountData.fname.split(" ").join("").length == 0) {
      setErrorCheckCreateAccountData({
        fname: true,
        phone: false,
        email: false,
        lname: false,
        password: false,
        profession: false,
      });
    } else if (createAcountData.lName.split(" ").join("").length == 0) {
      setErrorCheckCreateAccountData({
        fname: false,
        phone: false,
        email: false,
        lName: true,
        password: false,
        profession: false,
      });
    } else if (createAcountData.email.length == 0) {
      setErrorCheckCreateAccountData({
        fname: false,
        phone: false,
        email: true,
        lname: false,
        password: false,
        profession: false,
      });
    } else if (createAcountData.phone.length == 0) {
      setErrorCheckCreateAccountData({
        fname: false,
        phone: true,
        email: false,
        lname: false,
        password: false,
        profession: false,
      });
    } else if (
      createAcountData.phone.length > 0 &&
      createAcountData.phone.length <= 9
    ) {
      setErrorCheckCreateAccountData({
        fname: false,
        phone: true,
        email: false,
        lname: false,
        password: false,
        profession: false,
      });
    } else if (createAcountData.profession.length == 0) {
      setErrorCheckCreateAccountData({
        fname: false,
        phone: false,
        email: false,
        lname: false,
        password: false,
        profession: true,
      });
    } else if (createAcountData.password.length == 0) {
      setErrorCheckCreateAccountData({
        fname: false,
        phone: false,
        email: false,
        lname: false,
        password: true,
        profession: false,
      });
    } else if (createAcountData.password.length > 0 && validPassword == false) {
      setErrorCheckCreateAccountData({
        fname: false,
        phone: false,
        email: false,
        lname: false,
        password: true,
        profession: false,
      });
    } else if (validEmail == true) {
      SweetAlertConfirm("Please enter a valid email to continue");
    } else if (verificationStatus.email.status == false) {
      SweetAlertConfirm("Please verify email Address to continue");
    } else if (verificationStatus.mobile.status == false) {
      SweetAlertConfirm("Please verify Phone Number to continue");
    } else {
      let phone = createAcountData.phone;

      if (createAcountData.phone.includes("(")) {
        phone = phone.replace(/[^\w\s]/gi, "");
        phone = phone.substring(1);
      }
      const obj = {
        mobile_number: parseInt(phone),
        name: createAcountData.fname + " " + createAcountData.lName,
        firstName: createAcountData?.fname,
        lastName: createAcountData?.lName,
        profession: createAcountData.profession,
        password: createAcountData.password,
        email: createAcountData.email,
        role: "111",
      };
      setApiCall(true);
      userService
        .postUser(obj)
        .then((res) => {
          localStorage.setItem("newSpId", res.sp_id);
          localStorage.setItem("newSpUserId", res._id);

          authenticationService
            .login(createAcountData.email, createAcountData.password)
            .then(() => {
              if (createAcountData.image) {
                const obj2 = {
                  id: localStorage.getItem("newSpUserId"),
                  image: createAcountData.image,
                };
                // for image upload only
                userService.userImageUpdate(obj2).then((res) => {
                  setApiCall(false);
                  setCurrentStep(currentStep + 1);
                });
              }
            });
        })
        .catch((err) => {
          setTimeout(() => {
            setApiCall(false);
            SweetAlertConfirm("Something Went Wrong, Please Try Again.");
          }, 1000);
        });
    }
  };

  /////////// *****************************************************************************************************************************

  //  functions for handling services and packages section

  // handle open and close functionality for service and packages // executes whenever Category for service changes *****************************************************************************************************************************

  const handleClickOpen = () => {
    setSubCategotyArray([]);
    setServicesArray([]);
    setDataSelectServices({
      ...dataSelectServices,
      category: { id: "", categoryName: "" },
      subCategory: { id: "", subCategoryName: "" },
      services: { id: "", serviceName: "" },
      price: "",
      description: "",
    });
    setOpen(true);
  };

  const handleClickOpenPackage = () => {
    setSubCategoryArrayForPackage([]);
    setServicesArrayForPackage([]);
    setPackageId([]);
    setserviceNameDisp([]);
    setPriceTotal(0);
    setserviceId([]);
    setAddServiceData({
      title: "",
      categoryId: "",
      subCategoryId: "",
      serviceId: "",
      price: "",
      duration: "",
      description: "",
    });
    setOpenPackage(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePackage = () => {
    setOpenPackage(false);
  };

  const handleOpenPicker = () => {
    setOpenPackage(false);
    setOpenPicker(true);
  };
  const handleClosePicker = () => {
    setOpenPackage(true);
    setOpenPicker(false);
  };

  // executes whenever Category for service changes *****************************************************************************************************************************

  const onChangeSelectCategory = async (event) => {
    let subCatArr = [];
    let arr2 = [];
    setErrorCheckForService({ ...errorCheckForService, categoryId: false });
    categoryArray.map((prop, index) => {
      if (prop.id == event.target.value) {
        arr2 = [
          {
            id: prop.id,
            name: prop.name,
          },
        ];
      } else {
        return;
      }
    });
    setServicesArray([]);
    if (open == true) {
      setDataSelectServices({
        ...dataSelectServices,
        category: { id: arr2[0].id, categoryName: arr2[0].name },
      });
    }
    const response2 = await categoryService.getSubCategorybyId2(
      event.target.value
    );
    subCatArr = response2.map((prop, index) => {
      return {
        id: prop._id,
        name: prop.name,
      };
    });
    if (subCatArr) {
      setSubCategotyArray(subCatArr);
    }
  };

  // executes whenever subCategory for service changes *****************************************************************************************************************************

  const onChangeSelectSubCategory = async (event) => {
    setErrorCheckForService({ ...errorCheckForService, subcategoryId: false });
    let arr2 = [];
    let servicesArr = [];
    subCategoryArray.map((prop, index) => {
      if (prop.id == event.target.value) {
        arr2 = [
          {
            id: prop.id,
            name: prop.name,
          },
        ];
      } else {
        return;
      }
    });
    if (open == true) {
      setDataSelectServices({
        ...dataSelectServices,
        subCategory: { id: arr2[0].id, categoryName: arr2[0].name },
      });
    }
    const response3 = await servicesandpackagesService.getServicebyId2(
      event.target.value
    );
    servicesArr = response3.map((prop, index) => {
      return {
        id: prop._id,
        name: prop.name,
      };
    });
    if (servicesArr) {
      setServicesArray(servicesArr);
    }
  };

  // executes whenever services for service changes *****************************************************************************************************************************

  const onChangeSelectService = (event) => {
    setErrorCheckForService({ ...errorCheckForService, servicesId: false });
    let arr2 = [];
    servicesArray.map((prop, index) => {
      if (prop.id == event.target.value) {
        arr2 = [
          {
            id: prop.id,
            name: prop.name,
          },
        ];
      } else {
        return;
      }
    });
    if (open == true) {
      setDataSelectServices({
        ...dataSelectServices,
        services: { id: arr2[0].id, serviceName: arr2[0].name },
      });
    }
  };

  const handleServiceData = (data, event) => {
    let price = event.target.value;
    let pattern = /^[0-9]*$/;
    setDataSelectServices({ ...dataSelectServices, [data]: price });
    if (data == "price" && price.length > 0 && pattern.test(price)) {
      setErrorCheckForService({ ...errorCheckForService, price: false });
    } else if (data == "price" && price.length <= 0 && !pattern.test(price)) {
      setErrorCheckForService({ ...errorCheckForService, price: true });
    }
  };

  const handleServiceDataDuration = (event) => {
    setErrorCheckForService({ ...errorCheckForService, duration: false });
    setDataSelectServices({
      ...dataSelectServices,
      [event.target.name]: event.target.value,
    });
  };

  // save sevices by handling error check *****************************************************************************************************************************

  const saveServices = () => {
    if (
      dataSelectServices.category.id == undefined ||
      dataSelectServices.category.id.length == 0
    ) {
      setErrorCheckForService({
        categoryId: true,
        subcategoryId: false,
        servicesId: false,
        price: false,
        duration: false,
      });
    } else if (
      dataSelectServices.subCategory.id == undefined ||
      dataSelectServices.subCategory.id.length == 0
    ) {
      setErrorCheckForService({
        categoryId: false,
        subcategoryId: true,
        servicesId: false,
        price: false,
        duration: false,
      });
    } else if (
      dataSelectServices.services.id == undefined ||
      dataSelectServices.services.id.length == 0
    ) {
      setErrorCheckForService({
        categoryId: false,
        subcategoryId: false,
        servicesId: true,
        price: false,
        duration: false,
      });
    } else if (
      !/^[0-9]*$/.test(dataSelectServices.price) ||
      dataSelectServices.price == undefined ||
      dataSelectServices.price.length == 0
    ) {
      setErrorCheckForService({
        categoryId: false,
        subcategoryId: false,
        servicesId: false,
        price: true,
        duration: false,
      });
    } else if (
      dataSelectServices.duration == undefined ||
      dataSelectServices.duration.length == 0
    ) {
      setErrorCheckForService({
        categoryId: false,
        subcategoryId: false,
        servicesId: false,
        price: false,
        duration: true,
      });
    } else {
      const obj = {
        normal_price: dataSelectServices.price,
        sp_id:
          window.location.href.split("/")[
            window.location.href.split("/").length - 1
          ] == "newSp"
            ? localStorage.getItem("newSpId")
            : slug,
        service_id: dataSelectServices.services.id,
        description: dataSelectServices.description,
        title: dataSelectServices.services.serviceName,
        duration: dataSelectServices.duration,
        category_id: dataSelectServices.category.id,
        subcategory_id: dataSelectServices.subCategory.id,
      };
      servicesandpackagesService.postPackage(obj).then((res) => {
        if (res.error) {
          setOpen(false);
          SweetAlertConfirm(res.error);
        } else {
          setOpen(false);
          SweetAlertConfirmSuccess("saved");
          let stringAppend = "";
          stringAppend = servicesAdded.concat(
            `${dataSelectServices.services.serviceName}, `
          );
          setServicesAdded(stringAppend);
        }
      });
    }
  };

  // executes whenever service and pakages are added to service provider and shows list of services and packages for that service provider *****************************************************************************************************************************

  React.useEffect(() => {
    const getPackagesComboPackagesByServiceProvider = async () => {
      if (currentStep > 5) {
        try {
          const servicesResponse = await servicesandpackagesService.getPackagesbyServiceprovider(
            window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ] == "newSp"
              ? localStorage.getItem("newSpId")
              : slug
          );
          setAddedService(servicesResponse);

          const packageResponse = await servicesandpackagesService.getComboPackagesBySp(
            window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ] == "newSp"
              ? localStorage.getItem("newSpId")
              : slug
          );
          if (packageResponse.length > 0) {
            packageResponse.map((prop) => {
              var strForTitle = "";
              prop.services?.map((prop2, index) => {
                const obj = Object.assign({}, ...prop2);
                strForTitle = strForTitle.concat(obj.name, ", ");
              });
              let services = strForTitle.substring(0, strForTitle.length - 2);
              prop.service_included = services.split(",");
            });
            setAddedPackage(packageResponse);
          } else {
            setAddedPackage([]);
          }

          let obj = {
            serviceprovider_id:
              window.location.href.split("/")[
                window.location.href.split("/").length - 1
              ] == "newSp"
                ? localStorage.getItem("newSpId")
                : slug,
            value_by: "category",
          };

          const responseOfCategoryAvailableForSp = await servicesandpackagesService.getCategoryOrSubcategoryBasedOnSpService(
            obj
          );

          if (responseOfCategoryAvailableForSp.category?.length > 0) {
            let categoryArr = responseOfCategoryAvailableForSp.category[0].map(
              (prop, index) => {
                return {
                  id: prop._id,
                  name: prop.name,
                };
              }
            );
            setCategoryArrayForPackage([...categoryArr]);
          }
        } catch (err) {}
      }
    };

    getPackagesComboPackagesByServiceProvider();
  }, [currentStep, render]);

  React.useEffect(() => {
    let tempArray = [...packageId];
    let price = 0;
    if (packageId.length > 0) {
      addedService.map((service) => {
        if (tempArray.includes(service._id)) {
          price = price + parseInt(service.normal_price);
          setPriceTotal(price);
        } else {
        }
      });
    }
  }, [packageId]);

  // executes whenever Category for package changes *****************************************************************************************************************************

  const onChangeSelectCategoryForPackage = async (event) => {
    setErrorCheckForPackage((prevState) => {
      return {
        categoryId: false,
        subcategoryId: false,
        servicesId: false,
        price: false,
        duration: false,
      };
    });
    setAddServiceData({
      ...addServiceData,
      categoryId: event.target.value,
      subCategoryId: "",
      serviceId: "",
    });
    setserviceNameDisp([]);
    setServicesArrayForPackage([]);
    let obj = {
      serviceprovider_id:
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ] == "newSp"
          ? localStorage.getItem("newSpId")
          : slug,
      value_by: "subcategory",
      category_id: event.target.value,
    };
    const responseOfSubCategoryAvailableForSp = await servicesandpackagesService.getCategoryOrSubcategoryBasedOnSpService(
      obj
    );
    // const responseForSubCategoryData = await categoryService.getSubCategorybyId2(event.target.value);
    let subcategoryArr = responseOfSubCategoryAvailableForSp.subcategory[0].map(
      (prop, index) => {
        return {
          id: prop._id,
          name: prop.name,
        };
      }
    );
    setSubCategoryArrayForPackage([...subcategoryArr]);
  };

  // executes whenever subCategory for package changes *****************************************************************************************************************************

  const onChangeSelectSubCategoryForPackage = async (event) => {
    setErrorCheckForPackage((prevState) => {
      return {
        categoryId: false,
        subcategoryId: false,
        servicesId: false,
        price: false,
        duration: false,
      };
    });
    setAddServiceData({
      ...addServiceData,
      subCategoryId: event.target.value,
      serviceId: "",
    });
    setserviceNameDisp([]);
    let obj = {
      serviceprovider_id:
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ] == "newSp"
          ? localStorage.getItem("newSpId")
          : slug,
      subcategory_id: event.target.value,
    };
    const responseForServiceBasedOnSpAndSubcategory = await servicesandpackagesService.getServicesByServiceProviderAndSubcategory(
      obj
    );
    let servicesArr = responseForServiceBasedOnSpAndSubcategory.map(
      (prop, index) => {
        return {
          id: prop.services_id,
          name: prop.title,
          pid: prop._id,
          price: prop.normal_price,
        };
      }
    );
    setServicesArrayForPackage([...servicesArr]);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // function for removing services from  the list of services included in package *****************************************************************************************************************************

  const removeService = (id, servicesId) => {
    let arrayForRemovePackage = [...packageId];
    let arrayForRemoveService = [...serviceId];
    let tempForPackageId = arrayForRemovePackage.filter(function (item) {
      return item !== id;
    });
    let tempForServiceId = arrayForRemoveService.filter(function (item) {
      return item !== servicesId;
    });
    setPackageId([...tempForPackageId]);
    setserviceId([...tempForServiceId]);
  };

  //section to  handle multiple selection of service for creatong package *****************************************************************************************************************************

  const handleChangeMulti = (event) => {
    let clickedMultiData = event.target.value;
    let value = [...new Set([...clickedMultiData, ...serviceId])];
    setserviceId(typeof value === "string" ? value : value);
  };

  React.useEffect(() => {
    if (serviceId.length > 0) {
      setErrorCheckForPackage({ ...errorCheckForPackage, servicesId: false });
      let array = [];
      let arrayPid = [];
      let arrid = [];
      servicesArrayForPackage.map((prop, index) => {
        if (serviceId.includes(prop.id)) {
          array.push(prop.name);
          arrayPid.push(prop.pid);
          arrid.push(prop.id);
        } else {
          return;
        }
      });
      setPackageId([...new Set([...packageId, ...arrayPid])]);
      setserviceNameDisp([...array]);
    } else {
      setPackageId([...packageId]);
      setserviceNameDisp([]);
      // setPriceTotal(0);
    }
  }, [serviceId]);

  // all written data for package controoled by this function *****************************************************************************************************************************

  const handleTextDataForPackage = (event) => {
    setAddServiceData({
      ...addServiceData,
      [event.target.name]: event.target.value,
    });
    if (event.target.value.length == 0) {
      setErrorCheckForPackage((prevState) => {
        return {
          ...prevState,
          [event.target.name]: true,
        };
      });
    } else {
      setErrorCheckForPackage((prevState) => {
        return {
          ...prevState,
          [event.target.name]: false,
        };
      });
    }
  };

  // saves a package by handling error *****************************************************************************************************************************

  const savePackage = () => {
    if (addServiceData.title == undefined || addServiceData.title.length == 0) {
      setErrorCheckForPackage({
        title: true,
        categoryId: false,
        subcategoryId: false,
        servicesId: false,
        price: false,
        duration: false,
      });
    } else if (
      addServiceData.categoryId == undefined ||
      addServiceData.categoryId.length == 0
    ) {
      setErrorCheckForPackage({
        categoryId: true,
        subcategoryId: false,
        servicesId: false,
        price: false,
        duration: false,
      });
    } else if (
      addServiceData.subCategoryId == undefined ||
      addServiceData.subCategoryId.length == 0
    ) {
      setErrorCheckForPackage({
        categoryId: false,
        subcategoryId: true,
        servicesId: false,
        price: false,
        duration: false,
      });
    } else if (serviceId.length == 0) {
      setErrorCheckForPackage({
        categoryId: false,
        subcategoryId: false,
        servicesId: true,
        price: false,
        duration: false,
      });
    } else if (
      addServiceData.price == undefined ||
      addServiceData.price.length == 0
    ) {
      setErrorCheckForPackage({
        categoryId: false,
        subcategoryId: false,
        servicesId: false,
        price: true,
        duration: false,
      });
    } else if (
      addServiceData.duration == undefined ||
      addServiceData.duration.length == 0
    ) {
      setErrorCheckForPackage({
        categoryId: false,
        subcategoryId: false,
        servicesId: false,
        price: false,
        duration: true,
      });
    } else if (packageId.length < 2) {
      return SweetAlertConfirm("Atleast 2 services required");
    } else {
      let catArray = [];
      let subCatArr = [];
      packageId.map((prop) => {
        addedService.map((service) => {
          if (prop == service._id) {
            catArray.push(service.category_id);
            subCatArr.push(service.subcategory_id);
          }
        });
      });

      const obj = {
        normal_price: addServiceData.price,
        sp_id:
          window.location.href.split("/")[
            window.location.href.split("/").length - 1
          ] == "newSp"
            ? localStorage.getItem("newSpId")
            : slug,
        category_id: [...new Set(catArray)],
        sub_category_id: [...new Set(subCatArr)],
        service_id: serviceId,
        packages_id: packageId,
        title: addServiceData.title,
        duration: addServiceData.duration,
        description: addServiceData.description
          ? addServiceData.description
          : "",
        image_url: selectedImage.imgUrl,
      };

      servicesandpackagesService.postComboPackage(obj).then((res) => {
        if (res.error) {
          setOpenPackage(false);
          setSelectedImage({ imgUrl: null });
          SweetAlertConfirm(res.error);
        } else {
          setOpenPackage(false);
          setSelectedImage({ imgUrl: null });
          SweetAlertConfirmSuccess("saved");
        }
      });
    }
  };

  // delete (service/Package) data *****************************************************************************************************************************

  const deleteData = (id, parameter) => {
    if (parameter == "service") {
      servicesandpackagesService.deletePackage(id).then(() => {
        SweetAlertConfirmSuccess("Deleted");
      });
    } else if (parameter == "package") {
      servicesandpackagesService.deleteComboPackage(id).then(() => {
        SweetAlertConfirmSuccess("Deleted");
      });
    }
  };

  /////////// *****************************************************************************************************************************

  //  function for saving sp details (called on each time we trigger next except for previous work) *****************************************************************************************************************************

  const handleSaveSpDetail = () => {
    let faqToSend = [];
    if (faq.length > 0) {
      faq.map((prop) => {
        if (
          prop.question.split(" ").join("").length !== 0 &&
          prop.answer.split(" ").join("").length !== 0
        ) {
          faqToSend.push(prop);
        }
      });
    }
    const paymentArray = [];
    if (paymentCheckData.Cash == true) {
      paymentArray.push("Cash");
    }
    if (paymentCheckData.Venmo == true) {
      paymentArray.push("Venmo");
    }
    if (paymentCheckData.Zelle == true) {
      paymentArray.push("Zelle");
    }
    if (paymentCheckData.PayPal == true) {
      paymentArray.push("PayPal");
    }
    if (paymentCheckData.CreditCard == true) {
      paymentArray.push("Credit Card");
    }
    if (paymentCheckData.Others == true) {
      paymentArray.push("Others");
    }

    const obj = {
      id:
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ] == "newSp"
          ? localStorage.getItem("newSpId")
          : paymentCheckData.id,
      license_no: certificate?.trim()?.length > 0 ? certificate : "NA",
      minimum_charge:
        paymentCheckData.minBookingPrice?.length > 0
          ? paymentCheckData.minBookingPrice.replace("$", "")
          : "",
      slots_for_service_availability: {
        monday: {
          morning: `${availabilitySlots.monday.morning}`,
          evening: `${availabilitySlots.monday.evening}`,
          afternoon: `${availabilitySlots.monday.afternoon}`,
        },
        sunday: {
          morning: `${availabilitySlots.sunday.morning}`,
          evening: `${availabilitySlots.sunday.evening}`,
          afternoon: `${availabilitySlots.sunday.afternoon}`,
        },
        saturday: {
          morning: `${availabilitySlots.saturday.morning}`,
          evening: `${availabilitySlots.saturday.evening}`,
          afternoon: `${availabilitySlots.saturday.afternoon}`,
        },
        friday: {
          morning: `${availabilitySlots.friday.morning}`,
          evening: `${availabilitySlots.friday.evening}`,
          afternoon: `${availabilitySlots.friday.afternoon}`,
        },
        thursday: {
          morning: `${availabilitySlots.thursday.morning}`,
          evening: `${availabilitySlots.thursday.evening}`,
          afternoon: `${availabilitySlots.thursday.afternoon}`,
        },
        wednesday: {
          morning: `${availabilitySlots.wednesday.morning}`,
          evening: `${availabilitySlots.wednesday.evening}`,
          afternoon: `${availabilitySlots.wednesday.afternoon}`,
        },
        tuesday: {
          morning: `${availabilitySlots.tuesday.morning}`,
          evening: `${availabilitySlots.tuesday.evening}`,
          afternoon: `${availabilitySlots.tuesday.afternoon}`,
        },
      },
      experience: experience,
      brief_desription: about,
      is_vaccinated: `${covidSafetyData.vaccinated}`,
      follow_covid_safety_measures: `${covidSafetyData.follow}`,
      accepted_payment_methods: paymentArray,
      faq: faqToSend.length > 0 ? faqToSend : [],
      team_size: teamSize ? teamSize : "",
      working_at: workingAt ? workingAt : "",
      certificaton_no: certificate ? certificate : "",
      min_advanced_payment_percentage: paymentCheckData.minimumAdvance
        ? paymentCheckData.minimumAdvance.toString()
        : "",
      register_step:
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ] !== "newSp"
          ? "completed"
          : currentStep >= 8
          ? "completed"
          : currentStep,
    };
    if (about.split(" ").join("").length == 0) {
      setErorCheckAboutPage({
        about: true,
        locationAvaliable: false,
        experience: false,
        licenseNo: false,
      });
    } else if (experience.length == 0) {
      setErorCheckAboutPage({
        about: false,
        locationAvaliable: false,
        experience: true,
        licenseNo: false,
      });
    } else {
      if (currentStep == 5 && dataTosendServiceLocation.length > 0) {
        obj.service_location = dataTosendServiceLocation;
        obj.place_names = placesLocation;
      } else if (
        currentStep == 5 &&
        dataTosendServiceLocation.length == 0 &&
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ] !== "newSp"
      ) {
        obj.service_location = defaultValueServiceLocation;
      }
      setApiCall(true);
      SPService.putSP(obj)
        .then((res) => {
          setCurrentStep(currentStep + 1);
          setApiCall(false);
          scrollToTop();
        })
        .catch((err) => {
          setApiCall(false);
          SweetAlertConfirm("Error occurred");
        });
    }
  };

  /////////// *****************************************************************************************************************************

  //  always points to form top when step changes........

  const scrollToTop = () => {
    myRef.current.scrollIntoView();
    myRef.current.focus();
  };

  /////////// *****************************************************************************************************************************

  //   function trigger on clicking next for some steps

  const handleNext = () => {
    // checks if it is a new sp then its api call performs at second step but for admin view it occurs at first step

    if (
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == "newSp"
    ) {
      if (currentStep == 1) {
        setCurrentStep(currentStep + 1);
      } else if (currentStep == 2) {
        const objForCustom = {
          id:
            window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ] == "newSp"
              ? localStorage.getItem("newSpUserId")
              : createAcountData.id,
          custom_message: checkedCustom == true ? "true" : "false",
        };
        userService.manageAccountSetting(objForCustom).then(() => {});
        handleSaveSpDetail();
      } else if (currentStep == 11) {
        props.history.push("/payment");
      } else {
        handleSaveSpDetail();
      }
    } else {
      if (currentStep == 1) {
        const objForCustom = {
          id: createAcountData.id,
          custom_message: checkedCustom == true ? "true" : "false",
        };
        userService.manageAccountSetting(objForCustom).then(() => {});
        handleSaveSpDetail();
      } else {
        handleSaveSpDetail();
      }
    }
  };

  /////////// *****************************************************************************************************************************

  // function trigger when clicking on prev

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
    scrollToTop();
  };

  /////////// *****************************************************************************************************************************

  // step labels *****************************************************************************************************************************

  const stepper = [
    { label: "About" },
    { label: "Payment Options" },
    { label: "COVID Safety" },
    { label: "Service Location" },
    { label: "Availability" },
    { label: "Previous Work" },
    { label: "FAQs" },
  ];
  const stepperForAdmin = [
    { label: "About" },
    { label: "Payment Methods" },
    { label: "COVID Safety" },
    { label: "Availability" },
    { label: "Service Location" },
    { label: "Previous Work" },
    { label: "FAQs" },
  ];

  // service provider view steps **************************************************************************************************************************************************

  const getStepItems = (steps) => {
    switch (steps) {
      case 0:
        return (
          <CreateAccount
            saveCreateAccount={saveCreateAccount}
            createAcountData={createAcountData}
            handleImageUploadProfile={handleImageUploadProfile}
            handleOnChangeCreateAccount={handleOnChangeCreateAccount}
            handleTogglePassword={handleTogglePassword}
            errorCheckCreateAccountData={errorCheckCreateAccountData}
            createNewSp={createNewSp}
            validEmail={validEmail}
            apiCall={apiCall}
            openDialogVerification={openDialogVerification}
            setOpenDialogVerification={setOpenDialogVerification}
            verificationStatus={verificationStatus}
            setVerificationStatus={setVerificationStatus}
            verifyOtp={verifyOtp}
            verifyEmail={verifyEmail}
            sendOtpEmailVerification={sendOtpEmailVerification}
            setCreateAcountData={setCreateAcountData}
            sendOtpMobileVerification={sendOtpMobileVerification}
            counter={counter}
            counterDisplay={counterDisplay}
            validPassword={validPassword}
          />
        );

      case 1:
        return <CompletedAccount handleNext={handleNext} />;

      case 2:
        return (
          <Step1
            about={about}
            handleOnChangeAbout={handleOnChangeAbout}
            workingAt={workingAt}
            handleOnChangeWorkingAt={handleOnChangeWorkingAt}
            experience={experience}
            handleOnChangeSelect={handleOnChangeSelect}
            certificate={certificate}
            handleOnChangeCertificate={handleOnChangeCertificate}
            teamSize={teamSize}
            handleOnChangeSelectTeam={handleOnChangeSelectTeam}
            locationAvaliable={locationAvaliable}
            handleOnChangeLocationAvailable={handleOnChangeLocationAvailable}
            checkedCustom={checkedCustom}
            handleCheckBoxCustomRequest={handleCheckBoxCustomRequest}
            handleOnChangeLicenseNo={handleOnChangeLicenseNo}
            licenseNo={licenseNo}
            handleNext={handleNext}
            handlePrev={handlePrev}
            errorCheckAboutPage={errorCheckAboutPage}
            apiCall={apiCall}
          />
        );

      case 3:
        return (
          <Step2
            paymentCheckData={paymentCheckData}
            handleCheckBoxPaymentCash={handleCheckBoxPaymentCash}
            handleCheckBoxPaymentZelle={handleCheckBoxPaymentZelle}
            handleCheckBoxPaymentVenmo={handleCheckBoxPaymentVenmo}
            handleCheckBoxPaymentPayPal={handleCheckBoxPaymentPayPal}
            handleCheckBoxPaymentCreditCard={handleCheckBoxPaymentCreditCard}
            handleCheckBoxOthers={handleCheckBoxOthers}
            handleOnChangeMinAdvancePaymentSelect={
              handleOnChangeMinAdvancePaymentSelect
            }
            handleOnChangeMinBookingPrice={handleOnChangeMinBookingPrice}
            handleNextPaymentMethod={handleNextPaymentMethod}
            handlePrev={handlePrev}
            apiCall={apiCall}
          />
        );

      case 4:
        return (
          <Step3
            covidSafetyData={covidSafetyData}
            handleCheckBoxFullyVaccinated={handleCheckBoxFullyVaccinated}
            handleCheckBoxFollowRules={handleCheckBoxFollowRules}
            handleNext={handleNext}
            handlePrev={handlePrev}
            apiCall={apiCall}
          />
        );
      case 5:
        return (
          <ServiceLocation
            handleNextServiceLocation={handleNextServiceLocation}
            handlePrev={handlePrev}
            uniqueServiceLocation={uniqueServiceLocation}
            handleChangeServiceLocation={handleChangeServiceLocation}
            defaultValueServiceLocation={defaultValueServiceLocation}
            errorForServiceLocation={errorForServiceLocation}
            placesLocation={placesLocation}
            apiCall={apiCall}
          />
        );

      case 6:
        return (
          <Step6
            availabilitySlots={availabilitySlots}
            handleCheckBoxDayTimeDay={handleCheckBoxDayTimeDay}
            handleCheckBoxAllFieldsTimingSlot={
              handleCheckBoxAllFieldsTimingSlot
            }
            handlePrev={handlePrev}
            handleNext={handleNext}
            apiCall={apiCall}
          />
        );

      case 7:
        return (
          <Step4
            uploadLoader={uploadLoader}
            uploadLoaderIndex={uploadLoaderIndex}
            previousWork={previousWork}
            handleOnChangePreviousWork={handleOnChangePreviousWork}
            handleMoreField={handleMoreField}
            handlePrev={handlePrev}
            handlePreviousSave={handlePreviousSave}
            removePrevWorkImage={removePrevWorkImage}
            apiCall={apiCall}
            setApiCall={setApiCall}
            setPreviousWork={setPreviousWork}
            removePrevWork={removePrevWork}
          />
        );

      case 8:
        return (
          <Step5
            faq={faq}
            handleOnChangeFaq={handleOnChangeFaq}
            handleMoreFieldFaq={handleMoreFieldFaq}
            handleNextFaq={handleNextFaq}
            handlePrev={handlePrev}
            apiCall={apiCall}
          />
        );

      case 9:
        return <CompletedProfile handleNext={handleNext} />;

      case 10:
        return (
          <ServicePackageSetup
            open={open}
            handleClose={handleClose}
            errorCheckForService={errorCheckForService}
            onChangeSelectCategory={onChangeSelectCategory}
            dataSelectServices={dataSelectServices}
            categoryArray={categoryArray}
            onChangeSelectSubCategory={onChangeSelectSubCategory}
            subCategoryArray={subCategoryArray}
            onChangeSelectService={onChangeSelectService}
            servicesArray={servicesArray}
            handleServiceData={handleServiceData}
            handleServiceDataDuration={handleServiceDataDuration}
            saveServices={saveServices}
            openPackage={openPackage}
            handleClosePackage={handleClosePackage}
            errorCheckForPackage={errorCheckForPackage}
            addServiceData={addServiceData}
            handleTextDataForPackage={handleTextDataForPackage}
            onChangeSelectCategoryForPackage={onChangeSelectCategoryForPackage}
            categoryArrayForPackage={categoryArrayForPackage}
            onChangeSelectSubCategoryForPackage={
              onChangeSelectSubCategoryForPackage
            }
            subcategoryArrayForPackage={subcategoryArrayForPackage}
            serviceId={serviceId}
            handleChangeMulti={handleChangeMulti}
            serviceNameDisp={serviceNameDisp}
            servicesArrayForPackage={servicesArrayForPackage}
            packageId={packageId}
            addedService={addedService}
            removeService={removeService}
            priceTotal={priceTotal}
            savePackage={savePackage}
            addedPackage={addedPackage}
            SweetAlertConfirmDelete={SweetAlertConfirmDelete}
            SweetAlertConfirm={SweetAlertConfirm}
            handleNext={handleNext}
            handleClickOpen={handleClickOpen}
            handleClickOpenPackage={handleClickOpenPackage}
            openPicker={openPicker}
            handleOpenPicker={handleOpenPicker}
            handleClosePicker={handleClosePicker}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        );

      case 11:
        return <CompletedServices handleNext={handleNext} />;

      case 12:
        return (
          <SweetAlert
            success
            title="woot"
            onConfirm={() => {
              setAlertSuccess(null);
              localStorage.clear();
              sessionStorage.clear();
              props.history.goBack();
            }}
          >
            You are All Done...
          </SweetAlert>
        );
    }
  };

  // admin view steps **************************************************************************************************************************************************

  const getStepItemsAdmin = (steps) => {
    switch (steps) {
      case 0:
        return (
          <CreateAccount
            saveCreateAccount={saveCreateAccount}
            createAcountData={createAcountData}
            handleImageUploadProfile={handleImageUploadProfile}
            handleOnChangeCreateAccount={handleOnChangeCreateAccount}
            handleTogglePassword={handleTogglePassword}
            errorCheckCreateAccountData={errorCheckCreateAccountData}
            validEmail={validEmail}
            apiCall={apiCall}
            openDialogVerification={openDialogVerification}
            setOpenDialogVerification={setOpenDialogVerification}
            verificationStatus={verificationStatus}
            setVerificationStatus={setVerificationStatus}
            verifyOtp={verifyOtp}
            verifyEmail={verifyEmail}
            sendOtpEmailVerification={sendOtpEmailVerification}
            setCreateAcountData={setCreateAcountData}
            sendOtpMobileVerification={sendOtpMobileVerification}
            counter={counter}
            counterDisplay={counterDisplay}
            validPassword={validPassword}
          />
        );

      case 1:
        return (
          <Step1
            about={about}
            handleOnChangeAbout={handleOnChangeAbout}
            workingAt={workingAt}
            handleOnChangeWorkingAt={handleOnChangeWorkingAt}
            experience={experience}
            handleOnChangeSelect={handleOnChangeSelect}
            certificate={certificate}
            handleOnChangeCertificate={handleOnChangeCertificate}
            teamSize={teamSize}
            handleOnChangeSelectTeam={handleOnChangeSelectTeam}
            locationAvaliable={locationAvaliable}
            handleOnChangeLocationAvailable={handleOnChangeLocationAvailable}
            checkedCustom={checkedCustom}
            handleCheckBoxCustomRequest={handleCheckBoxCustomRequest}
            handleNext={handleNext}
            handlePrev={handlePrev}
            errorCheckAboutPage={errorCheckAboutPage}
            handleOnChangeLicenseNo={handleOnChangeLicenseNo}
            licenseNo={licenseNo}
            apiCall={apiCall}
          />
        );

      case 2:
        return (
          <Step2
            paymentCheckData={paymentCheckData}
            handleCheckBoxPaymentCash={handleCheckBoxPaymentCash}
            handleCheckBoxPaymentZelle={handleCheckBoxPaymentZelle}
            handleCheckBoxPaymentVenmo={handleCheckBoxPaymentVenmo}
            handleCheckBoxPaymentPayPal={handleCheckBoxPaymentPayPal}
            handleCheckBoxPaymentCreditCard={handleCheckBoxPaymentCreditCard}
            handleCheckBoxOthers={handleCheckBoxOthers}
            handleOnChangeMinAdvancePaymentSelect={
              handleOnChangeMinAdvancePaymentSelect
            }
            handleOnChangeMinBookingPrice={handleOnChangeMinBookingPrice}
            handleNextPaymentMethod={handleNextPaymentMethod}
            handlePrev={handlePrev}
            apiCall={apiCall}
          />
        );

      case 3:
        return (
          <Step3
            covidSafetyData={covidSafetyData}
            handleCheckBoxFullyVaccinated={handleCheckBoxFullyVaccinated}
            handleCheckBoxFollowRules={handleCheckBoxFollowRules}
            handleNext={handleNext}
            handlePrev={handlePrev}
            apiCall={apiCall}
          />
        );

      case 4:
        return (
          <Step6
            availabilitySlots={availabilitySlots}
            handleCheckBoxDayTimeDay={handleCheckBoxDayTimeDay}
            handleCheckBoxAllFieldsTimingSlot={
              handleCheckBoxAllFieldsTimingSlot
            }
            handlePrev={handlePrev}
            handleNext={handleNext}
            apiCall={apiCall}
          />
        );

      case 5:
        return (
          <ServiceLocation
            handleNextServiceLocation={handleNextServiceLocation}
            handlePrev={handlePrev}
            uniqueServiceLocation={uniqueServiceLocation}
            handleChangeServiceLocation={handleChangeServiceLocation}
            defaultValueServiceLocation={defaultValueServiceLocation}
            errorForServiceLocation={errorForServiceLocation}
            placesLocation={placesLocation}
            apiCall={apiCall}
          />
        );

      case 6:
        return (
          <Step4
            uploadLoader={uploadLoader}
            uploadLoaderIndex={uploadLoaderIndex}
            previousWork={previousWork}
            handleOnChangePreviousWork={handleOnChangePreviousWork}
            handleMoreField={handleMoreField}
            handlePrev={handlePrev}
            handlePreviousSave={handlePreviousSave}
            removePrevWorkImage={removePrevWorkImage}
            apiCall={apiCall}
            setApiCall={setApiCall}
            setPreviousWork={setPreviousWork}
            removePrevWork={removePrevWork}
          />
        );

      case 7:
        return (
          <Step5
            faq={faq}
            handleOnChangeFaq={handleOnChangeFaq}
            handleMoreFieldFaq={handleMoreFieldFaq}
            handleNextFaq={handleNextFaq}
            handlePrev={handlePrev}
            apiCall={apiCall}
          />
        );

      case 8:
        return (
          <SweetAlert
            success
            title="woot"
            onConfirm={() => {
              setAlertSuccess(null);
              props.history.goBack();
            }}
          >
            Data saved successfully
          </SweetAlert>
        );
    }
  };

  const classes = useStyles();

  // same form is rendered for both sp and admin in lower code

  return (
    <>
      {window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == "newSp" && (
        <div
          style={{ height: "100vh", paddingTop: 0, backgroundColor: "#fff" }}
        >
          <div
            style={{
              height: "80px",
              left: "20px",
              top: "0px",
              borderBottom: "3px solid #e6e8ea",
              marginBottom: "0px",
              paddingLeft: "25px",
              display: "flex",
              position: "sticky",
              zIndex: "99",
              alignItems: "center",
              background: "#fff",
              width: "100%",
            }}
          >
            <img src={Brandlogo} style={{}} />
          </div>

          <Grid
            ref={myRef}
            container
            className={`${classes.formContainer} ${classes.formBackground}`}
          >
            <Grid
              item
              xs={10}
              sm={10}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                className={"removebackgroundFromCard"}
                style={{ maxWidth: "700px", marginTop: "-160" }}
              >
                {currentStep < 9 && currentStep > 1 && (
                  <Paper
                    component={Box}
                    p={2}
                    style={{ backgroundColor: "unset" }}
                  >
                    <Stepper
                      className={`${classes.colorStep} ${classes.mediaMobileScroll}`}
                      activeStep={currentStep - 2}
                      alternativeLabel
                      style={{ backgroundColor: "unset" }}
                    >
                      {stepper.map((item, i) => (
                        <Step key={i}>
                          <StepLabel>{item.label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Paper>
                )}

                <Box
                  component={Paper}
                  className={"removebackgroundcolorFromCard"}
                  style={{ backgroundColor: "unset", width: "100%" }}
                >
                  <form className={classes.form} autoComplete="off">
                    {getStepItems(currentStep)}
                  </form>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}

      {window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] !== "newSp" && (
        <Grid ref={myRef} container className={classes.formContainer}>
          <Grid
            item
            xs={12}
            sm={7}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Card
              className={"removebackgroundFromCard"}
              style={{ maxWidth: "650px" }}
              id="AccountTop"
            >
              {currentStep < 7 && currentStep > 0 && (
                <Paper
                  className={"removebackgroundcolorFromCard"}
                  component={Box}
                  p={2}
                >
                  <Stepper
                    className={classes.colorStep}
                    activeStep={currentStep - 1}
                    alternativeLabel
                    style={{ overflow: "auto" }}
                  >
                    {stepperForAdmin.map((item, i) => (
                      <Step key={i}>
                        <StepLabel>{item.label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Paper>
              )}

              <Box component={Paper}>
                <form className={classes.form} autoComplete="off">
                  {getStepItemsAdmin(currentStep)}
                </form>
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}

      <>{alertError}</>
      <>{alertSuccess}</>
      <>{alertDelete}</>
    </>
  );
};

export default FormComponent;
