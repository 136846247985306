import {
  Avatar,
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Typography,
  Box,
  Paper,
  pField,
} from '@material-ui/core'
import React from 'react'
// import { withclassNames, makeclassNames } from '@material-ui/core/classNames'


// const useclassNames = makeclassNames((theme) => (
//   {
//     passwordValid: {
//       color: 'green',
//       fontSize: '12px',
//       lineHeight: '16px'
//     },
//     passwordInvalid: {
//       color: 'red',
//       fontSize: '12px',
//       lineHeight: '16px'
//     }
//   }
// ))


const PasswordContraints = ({ createAcountData }) => {

  // console.log(createAcountData.password)
  // const classes = useclassNames()


  const passwordConstraints = [
    "Password must contain at least 1 lowercase alphabetical character",
    "Password must contain at least 1 uppercase alphabetical character",
    "Password must contain at least 1 numeric character",
    "Password must contain at least one special character !@#$%^&",
    "Password must be eight characters or longer",
  ];

  return (

    <div>

      {/^(?=.*[a-z])/.test(createAcountData.password) ? (
        <p style={{ color: 'green', marginBottom: '3px' }}>
          {passwordConstraints[0]}
        </p>
      ) : (
        <p style={{ color: 'red', marginBottom: '3px' }}>
          {passwordConstraints[0]}
        </p>
      )}


      {
        /^(?=.*[A-Z])/.test(createAcountData.password) ? (
          <p style={{ color: 'green', marginBottom: '3px' }}>
            {passwordConstraints[1]}
          </p>
        ) : (
          <p style={{ color: 'red', marginBottom: '3px' }}>
            {passwordConstraints[1]}
          </p>
        )
      }



      {
        /^(?=.*[0-9])/.test(createAcountData.password) ? (
          <p style={{ color: 'green', marginBottom: '3px' }}>
            {passwordConstraints[2]}
          </p>
        ) : (
          <p style={{ color: 'red', marginBottom: '3px' }}>
            {passwordConstraints[2]}
          </p>
        )}



      {
        /[`!@#$%^&]/.test(createAcountData.password) ? (
          <p style={{ color: 'green', marginBottom: '3px' }}>
            {passwordConstraints[3]}
          </p>
        ) : (
          <p style={{ color: 'red', marginBottom: '3px' }}>
            {passwordConstraints[3]}
          </p>
        )}



      {/^(?=.{8,})/.test(createAcountData.password) ? (
        <p style={{ color: 'green', marginBottom: '3px' }}>
          {passwordConstraints[4]}
        </p>
      ) : (
        <p style={{ color: 'red', marginBottom: '3px' }}>
          {passwordConstraints[4]}
        </p>
      )}


    </div>
  );

};

export default PasswordContraints;