import React, { useState, useEffect } from 'react';
import AddService from './AddService';

const EditService = (props) => {
    return (
        <>
            <AddService {...props} />
         
        </>
    );
}

export default EditService