import React, { useState } from 'react'
import ProcessRefundTable from './ProcessRefundTable';
const ProcessRefundRequest = (props) => {
    
    
    return (
        <div style={{width:'100%'}}>
            <ProcessRefundTable {...props} />
        </div>
    )
}

export default ProcessRefundRequest
