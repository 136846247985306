import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { configurationService } from '_services/configuration.service';
import { Grid } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { TagsInput } from "react-tag-input-component";
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    }
}));

const AddConfiguration = (props) => {
    const classes = useStyles();
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const [alert, setAlert] = useState(null);
    const [configData, setConfigData] = useState(null);
    const [selected, setSelected] = useState([]);
    function SweetAlertConfirm() {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => setAlert(null)}
            >
                Configurations updated successfully
            </SweetAlert>
        )
        setAlert(getAlert());
    }
    useEffect(() => {
        configurationService.getConfiguration()
            .then(res => {
                if (res) {
                    let arr = res.professionValue.split(',')
                    setSelected([...arr])
                    setConfigData(res);
                }
                else {
                    setConfigData(null);
                }

            })
            .catch(err => {
                return {
                    service_wait_time: "",
                    about_us_url: "",
                    terms_condition_url: "",
                    privacy_policy_url: "",
                    faq_url: "",
                    how_it_works_url: "",
                    professionValue: ""
                }
            })
    }, []);

    return (
        <>
            <div className={classes.container}>
                <GridContainer style={{ width: "100%" }}>
                    <GridItem xs={12} sm={12} md={8}>
                        {configData && <div>
                            <div className="backArrow" style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton aria-label="edit" onClick={() => { props.history.goBack() }} style={{paddingLeft:'0px',marginLeft:'-4px'}}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <h4 className={""} style={{marginBottom:'4px',fontSize: "20px", fontFamily: "Muli-Bold"}}>Configuration</h4>
                            </div>
                            <Formik
                                initialValues={{
                                    service_wait_time: configData ? configData.service_wait_time : "",
                                    about_us_url: configData ? configData.about_us_url : "",
                                    terms_condition_url: configData ? configData.terms_condition_url : "",
                                    privacy_policy_url: configData ? configData.privacy_policy_url : "",
                                    faq_url: configData ? configData.faq_url : "",
                                    how_it_works_url: configData ? configData.how_it_works_url : "",
                                    professionValue: configData ? configData.professionValue.split(",") : "",
                                    credit_deduct_percentage: configData ? configData.credit_deduct_percentage : ""
                                }}
                                validationSchema={Yup.object().shape({
                                    service_wait_time: Yup.string().required('Service wait time is required'),
                                    about_us_url: Yup.string().required('About us is required'),
                                    terms_condition_url: Yup.string().required('Terms & Condition is required'),
                                    privacy_policy_url: Yup.string().required('Privacy policy is required'),
                                    faq_url: Yup.string().required('FAQ is required'),
                                    how_it_works_url: Yup.string().required('How it works is required'),
                                    // professionValue: Yup.string().required('Profession is required'),
                                    credit_deduct_percentage: Yup.string().required('Credit Deduct Percentage is required'),
                                    // img_upload: Yup.string().required('Image is required')
                                })}
                                onSubmit={({ service_wait_time, about_us_url, terms_condition_url, privacy_policy_url, faq_url, how_it_works_url, professionValue, credit_deduct_percentage }, { setStatus, setSubmitting }) => {
                                    setStatus();
                                    const editobj = {
                                        "service_wait_time": service_wait_time.toString(),
                                        "about_us_url": about_us_url,
                                        "terms_condition_url": terms_condition_url,
                                        "privacy_policy_url": privacy_policy_url,
                                        "faq_url": faq_url,
                                        "how_it_works_url": how_it_works_url,
                                        "professionValue": selected.join(','),
                                        "credit_deduct_percentage": credit_deduct_percentage
                                    }
                                    // console.log(selected.join(','));
                                    if (selected.length > 0) {
                                        // console.log(selected);
                                        configurationService.postConfiguration(editobj).then(
                                            res => {
                                                setSubmitting(false);
                                                SweetAlertConfirm();
                                            },
                                            error => {
                                                setSubmitting(false);
                                                setStatus(error.name ? error.name : "server error");
                                            }
                                        );
                                    }
                                    else {
                                        setSubmitting(false);
                                        setStatus("Profession Feild cannot be empty");
                                    }
                                }}>
                                {({ errors, status, touched, isSubmitting }) => (
                                    <Form>
                                        <Card className={classes[cardAnimaton]}>
                                            {/* <CardHeader
                                        className={`${classes.cardHeader}`}
                                    >
                                        <h4 className={`${classes.cardTitle} ${classes.h4} `}>Add Category</h4>
                                    </CardHeader> */}
                                            <CardBody className="px-0 pb-0">
                                                <GridContainer spacing={0}>
                                                    <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5} style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>Service Time</h5>
                                                            <Field name="service_wait_time"
                                                                id="service_wait_time"
                                                                type="text"
                                                                placeholder="Enter Service Wait time"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.service_wait_time && touched.service_wait_time ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="service_wait_time" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5} style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>About Us Page</h5>
                                                            <Field name="about_us_url"
                                                                id="about_us_url"
                                                                placeholder="Enter About Us Page"
                                                                type="text"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.about_us_url && touched.about_us_url ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="about_us_url" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5} style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>Terms & Conditions</h5>
                                                            <Field name="terms_condition_url"
                                                                id="terms_condition_url"
                                                                placeholder="Enter Terms & Condition"
                                                                type="text"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.terms_condition_url && touched.terms_condition_url ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="terms_condition_url" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5} style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>Privacy Policy</h5>
                                                            <Field name="privacy_policy_url"
                                                                id="privacy_policy_url"
                                                                placeholder="Enter Privacy Policy"
                                                                type="text"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.privacy_policy_url && touched.privacy_policy_url ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="privacy_policy_url" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5} style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>FAQ</h5>
                                                            <Field name="faq_url"
                                                                id="faq_url"
                                                                placeholder="Enter FAQ"
                                                                type="text"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.faq_url && touched.faq_url ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="faq_url" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5} style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>How it Works</h5>
                                                            <Field name="how_it_works_url"
                                                                id="how_it_works_url"
                                                                placeholder="Enter How it Works"
                                                                type="text"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.how_it_works_url && touched.how_it_works_url ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="how_it_works_url" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>
                                                    {/* <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5}>Profession</h5>
                                                            <Field name="professionValue"
                                                                id="professionValue"
                                                                placeholder="Enter Profession values seprated by comma"
                                                                type="text"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.professionValue && touched.professionValue ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="professionValue" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem> */}
                                                    <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5} style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>Profession</h5>
                                                            <TagsInput
                                                                value={selected}
                                                                onChange={setSelected}
                                                                name="profession"
                                                                placeHolder="Enter Profession"
                                                            />
                                                            <ErrorMessage name="professionValue" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>

                                                    <GridItem xs={12} sm={8} md={8}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5} style={{fontSize: "18px", fontFamily: "Muli-SemiBold"}}>Credit Deduct Percentage</h5>
                                                            <Field 
                                                            
                                                                name="credit_deduct_percentage"
                                                                id="credit_deduct_percentage"
                                                                placeholder="Enter credit deduct percentage"
                                                                type="text"
                                                                className={`${classes.emailInput} ${'form-control' + (errors.credit_deduct_percentage && touched.credit_deduct_percentage ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="credit_deduct_percentage" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>
                                                </GridContainer>
                                            </CardBody>
                                            <CardFooter className={classes.btnAlign}>
                                                {/* <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button> */}
                                                <Grid container>
                                                    <GridItem xs={6} sm={6} md={6}>
                                                        <Button type="submit" className={classes.SaveButton} simple size="sm">
                                                            Save
                                                        </Button>
                                                    </GridItem>
                                                    {isSubmitting &&
                                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                    {/* <GridItem xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
                                                <Button type="submit" className={classes.DelButton} simple size="sm">
                                                    Delete
                                                </Button>
                                            </GridItem> */}
                                                </Grid>
                                                <br />
                                            </CardFooter>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {status &&
                                                        <div className={'alert alert-danger'}>{status}</div>
                                                    }
                                                </div>
                                            </div>
                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        </div>}
                    </GridItem>
                </GridContainer>
            </div>
            {alert}
        </>
    )
}

export default AddConfiguration;
