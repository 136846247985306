import routes from "routes";

export const useRouteBreadCrumbs = () => {
    let name = "";
    routes.forEach((route) => {
        if (window.location.href.indexOf(route.layout + route.path) !== -1) {
            name = routes.rtlActive ? route.rtlName : route.name;
        }
    });
    if (name.length > 0) {
        let count = 0
        let arr = window.location.href.split('/');
        arr.map((appElement, index) => {
            if (appElement == "app") {
                count = index + 1;
            }
        })

        let newBreadCrumbsArray = arr.splice(
            count,
            arr.length
        ).map(element => {
            return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
        });

    
        if(newBreadCrumbsArray.includes('Product_services')){
            return newBreadCrumbsArray.splice(0,2).join(' > ');
        }
        else{
            newBreadCrumbsArray=newBreadCrumbsArray.filter((element)=> element!=='Service_provider_detail')
            return newBreadCrumbsArray.splice(0,3).join(' > ');
        }
    }

};
