import { Typography } from "@material-ui/core";

// Render text common

const renderText = ({ label, align, color, component, varient, fontSize }) => {
    return (
        <Typography
            color={color ? color : "black"}
            align={align ? align : "center"}
            //  variant={varient ? varient :"h6"}>
            variant={varient ? varient : "h4"}>
            {label}
        </Typography>
    );
}
export {
    renderText
}