import { Avatar, Button, Grid, Icon, IconButton, Link, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Image } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {userService} from '_services/user.service';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useRouteMatch
} from "react-router-dom";
import edit from '../../../edit-svgrepo-com.svg'
const useStyles = makeStyles((theme) => ({

    button: {
        margin: theme.spacing(1),
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "#efefef",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
        '@media(max-width:360px)': {
            marginTop: '0px',
            paddingTop: '2px',
            paddingBottom: '2px',
            marginLeft:'5px',
            paddingRight:'10px !important'
        }
    },
    formControl: {
        minWidth: '100%'
    },
    greyBg: {

        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "flex-end !important",
            height: "95px !important",
            paddingRight:"unset !important"
        },
    },
    userName: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center"
        },

    },
    ImgBorder:
    {
        '@media(max-width:360px)': {
            left: '6px !important',
            height: '100px !important',
            width:'100px !important',
            top:'-3.5em !important',
        }
    },
    editIcon:
    {
        '@media(max-width:360px)': {
            paddingBottom: '6px'
        }
    },

    User:
    {
        '@media(max-width:360px)': {
            alignItems:'center !important',
            paddingBottom: '6px !important'
        }
    },
    AccountName:
    {
        '@media(max-width:360px)': {
        fontSize:'12px !important',
        },
        fontSize:'15px',
        fontFamily:'Muli',
        color:'#6B6987'
    }
}));

const UserHeader = (props) => {
    const classes = useStyles((theme) =>
    ({

    })
    );
    const [data, setData] = React.useState(props.data);
    const [open, setOpen] = React.useState(false);
    const [selectedStatus, setSelectedStatus] = React.useState('');
    const [activeInactiveUser,setActiveInactiveUser] = React.useState('');
    const [imgUser,setImgUser] = React.useState('');
    const { slug } = useParams();
    const [backdropUseEffect,setBackdropUseEffect] = React.useState(0);
    const [alert, setAlert] = React.useState(null);

    React.useEffect(() => {
        const data_id = {
            id: slug
        }
        // console.log(props);
        const fetchData = async () => {
            try{
                const response = await userService.getUser(props.data.user._id);
                setImgUser(response && response.image_url ? response.image_url :'')
                setSelectedStatus(response.active || '');
            }
            catch(err){}
        }

        fetchData();

    },[backdropUseEffect])

    function SweetAlertConfirm() {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => setAlert(null)}
            >
                Account status updated successfully
            </SweetAlert>
        )
        setAlert(getAlert());
    }

    const handleClickOpen = () => {
        // setSelectedStatus(data.user.active || '');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setBackdropUseEffect(!backdropUseEffect);
    };

    const handleSelectChange =(e) =>{

        setSelectedStatus(e.target.value);

    }

    const saveChangedStatus = () => {
        const obj = {
            id:props.data.user._id
        }

        if(selectedStatus == "true"){
            userService.activateUser(obj)
                .then(res => {
                    setOpen(false);
                    SweetAlertConfirm();
                })
        }
        else {
            userService.deactivateUser(obj)
                .then(res => {
                    setOpen(false);
                    SweetAlertConfirm();
                })
        }

    }
    const handleEditOpen = () => {
        //history.push("/app/service_providers/service_provider_detail/edit/" + slug);
        const { from } = { from: { pathname: "/app/users/edit/" + slug } };
        props.history.push(from);
    }

    return (
        <>
            {data && <>
                <div>
                    <Dialog open={open} onClose={(e) => handleClose(e)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Change user status</DialogTitle>
                        <DialogContent>
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={selectedStatus}
                                    onChange={(e) => handleSelectChange(e)}
                                    input={<Input />} >
                                    <MenuItem value={'true'}>Active</MenuItem>
                                    <MenuItem value={'false'}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose()} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => saveChangedStatus()} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Grid container justifyContent="flex-end" style={{ margin: '15px 0px', padding: '10px', background: "#fff", borderRadius: 5,paddingBottom: "5pc" }} >
                    <Grid item md={12} xs={12} className={classes.greyBg} style={{ display: "flex", justifyContent: "flex-end", background: "#8e8cac30", height: "88px", alignItems: "center", paddingRight: 20, borderRadius: 5 }}>
                        <IconButton aria-label="edit" onClick={() => handleEditOpen()} className={classes.editIcon}>
                        <img src={edit} style={{width:'21px', height:'18px'}}/>
                        </IconButton>
                        <Link className={classes.textDecNone} id="accountSetting">
                            <Button
                                variant="contained"
                                color="default"
                                className={classes.button}
                                onClick={() => handleClickOpen()}
                                startIcon={<Icon color="#6B6987">settings</Icon>}

                            >
                                <span className={classes.AccountName}>Account Settings</span>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item md={12} xs={12} style={{ display: "flex", justifyContent: "flex-end", height: "90px", alignItems: "center", position: "relative" }}>
                        <div className={classes.ImgBorder} style={{ border: "3px solid #fff", height: 115, width: 115, borderRadius: "50%", position: "absolute", left: 20, top: "-4.5em",backgroundImage: `url(${imgUser.length > 0 && imgUser ? imgUser : 'https://clipartart.com/images/clipart-profile-4.jpg'})`, backgroundPosition: "center center", backgroundSize: "cover" }}>
                        </div>
                        <Grid xs={12} item container id="topResponse">
                            <Grid item md={6} xs={6} className={classes.userName} alignItems="flex-end" style={{ display: "flex", padding: "0 20px 0" }}>
                                <Typography gutterBottom variant="h5" style={{ fontSize: 20, marginBottom: "unset", fontFamily: "Muli-SemiBold",color:'#25233C' }} className={classes.User}>
                                    {data.user.name}
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} align="right">
                                <Grid item xs container direction="column" alignItems="flex-end" spacing={2} style={{ paddingRight: 20 }}>
                                    <Grid item md={5} className={'text-center'} style={{ paddingRight: 20 }}>
                                        <Typography gutterBottom variant="h5" style={{ fontFamily: "Muli-Bold", color: "#6259CA", fontSize: 25 }}>
                                            {data.services_taken ? data.services_taken :'0'}
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontSize: 14,color:'#6B6987',fontFamily:'Muli-SemiBold' }}>
                                            Services Taken
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>}
            {alert}
        </>

    )
}

export default UserHeader
