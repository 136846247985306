import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CardMedia, Icon, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { userService } from '_services/user.service'
import "../../../assets/css/notification.css";

import {
    BrowserRouter as Router,

    useRouteMatch
} from "react-router-dom";



const StyledTableCell = withStyles((theme) => ({
    head: {
        //   backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
        backgroundColor: "#F5F5FC",
        color: "#6c6987",
    },

}))(TableCell);


const useStyles = makeStyles((theme) => ({
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    button2: {
        background: "transparent",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8cac"
    },
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    fontSize20: {
        fontSize: '20px',
        color: '#25233C'
    },
    cover: {
        border: "1px solid #8E8CAC",
        height: "100%",
        maxWidth: 74,
        minHeight: 74,
        width: "100%",

    },
    cardTitle: {
        margin: "unset",
        padding: "0 20px",
        fontSize: 20,
        color: "#25233C",
        fontWeight: "400"
    },
    cardAction: {
        color: "#000",
    },
    button: {
        margin: theme.spacing(1),
        background: "transparent",
        boxShadow: "unset",
        border: "1px solid #8f8cac",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "transparent",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
    }
    ,
    table: {
        margin: "10px 0",
    },
    thead: {
        // padding: 10
        padding:'0px',
    },
    gridCont: {
        paddingTop: "10px",
        margin: 0,
    },
    gridItem1: {
        display: "flex",
        alignItems: "center",
    },
    gridItem2: {
        textAlign: "right",
    },
    tcell1: {
        border: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
        background: "#F3F3FC",
        height: "100%"
    },
    tcell2: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
        background: "#F3F3FC",
        height: "100%",
    },
    tcell2sub: {
        padding: "unset",
        margin: "auto",
        border: "unset",
    },
    tcell3: {
        border: "unset",
        paddingLeft: "unset",
        paddingBottom: "unset",
        background: "#F3F3FC",
        height: "100%"
    },
    div1: {
        display: "flex",
        alignItems: "center",
        color: "#6d6b75",
        padding: 15,
        // paddingLeft: '0px',
    },
    div2: {
        padding: 15,
        color: "#6d6b75",
        display: "flex",
        justifyContent: "space-between",
        maxWidth: "475px",
        textAlign: "start"
    },
    div3: {
        padding: 15,
        color: "#6d6b75",
        display: "flex",
        justifyContent: "flex-end",
        whiteSpace: "nowrap",
        paddingRight: '0px'
    },
    blockTitle: {
        paddingLeft: 10,
        color: "#25233C"
    },
    mainCard: {
        marginBottom: '2em'
    },
    subcellWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    subCell: {
        padding: 0,
        border: "unset",
        margin: "auto",
    },
    textDecNone: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none"
        },
    },
    minHeight:{
        minHeight: 50   
    },
    fontSize20:
    {
        marginBottom:'7px'
    }
}));

const Notification = (props) => {
    const classes = useStyles();
    // let { path, url } = useRouteMatch();
    // const [topProData, setTopProData] = React.useState([]);
    const [apiCall, setApiCall] = React.useState(false);
    const [notificationData, setNotificationData] = React.useState([]);



    React.useEffect(() => {
        setApiCall(true);
        userService.updateNotificationStatus().then(async (res) => {
            let responseOfNotifications = await userService.getAllAdminNotification({ seen_status: "seen" });
            if (responseOfNotifications.data.length > 0) {

                let arr = [];
                responseOfNotifications.data.map((prop) => {

                    let date = new Date(prop.created_at.replace(/-/g, "/")); // M-D-YYYY
                    let d = date.getDate();
                    let m = date.toLocaleString('default', { month: 'long' });
                    let y = date.getFullYear();
                    let dateString = (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;

                    var hours = date.getHours();
                    var minutes = date.getMinutes();
                    var ampm = hours >= 12 ? 'pm' : 'am';
                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    minutes = minutes < 10 ? '0' + minutes : minutes;
                    var strTime = hours + ':' + minutes + ' ' + ampm;

                    let obj = {
                        date: dateString,
                        body: prop.msg,
                        time: strTime
                    }
                    arr.push(obj);
                })

                if (arr.length > 0) {
                    setNotificationData([...arr])
                    setApiCall(false)
                }
                else {
                    setApiCall(false)
                }
            }
            else {
                setApiCall(false)
            }
        })
        //  console.log(responseOfNotifications);
    }, [])


    // return to previous page 

    function getToPrevious() {
        // const { from } = { from: { pathname: "/app/product_services" } };
        props.history.goBack();
    }


    return (
        <>
            {
                window.location.href.split('/')[window.location.href.split('/').length - 1] == 'notification' && <div>
                    <div elevation={0} position="static" color="transparent" className={classes.divAdj} >
                        <div className={classes.header}>
                            <IconButton style={{ paddingBlock: "8px", paddingLeft: 0,marginLeft:'-4px' }} aria-label="edit" onClick={getToPrevious}>
                                <ArrowBackIcon />
                            </IconButton>
                            <h4 className={classes.fontSize20} style={{fontWeight: "600"}}>Notification</h4>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Card className={`productCategory`}>
                                <CardHeader color="primary" icon>   
                                </CardHeader>
                                <CardBody className="p-0">
                                    <TableContainer className={[classes.tcontainer, "notification-table-container"]}>
                                        <Table className={[classes.table, "notification-table"]} style={{ margin: '0px'}} aria-label="simple table">
                                            <TableHead className={classes.thead} >
                                                <TableRow>
                                                    <StyledTableCell style={{fontSize:'16px',color:'#6B6987', fontWeight: 900}}>Date</StyledTableCell>
                                                    <StyledTableCell style={{fontSize:'16px',color:'#6B6987', fontWeight: 900}}>
                                                        Body

                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" style={{paddingRight:'30px',fontSize:'16px',color:'#6B6987', fontWeight: 900}}>Time</StyledTableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody
                                                // style={{ padding: '15px', width: "50%" }} 
                                                className={"tbody-adj"}>
                                                {notificationData.length > 0 && apiCall == false &&
                                                    notificationData.map((prop, index) => {
                                                        return <TableRow className={classes.minHeight} key={index} >
                                                            <TableCell component="td" scope="row" className={classes.tcell1}>
                                                                <div className={classes.div1} style={{ color: '#25233C', fontSize: '14px'}}>

                                                                    {prop.date}

                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="center" className={classes.tcell2}>
                                                                <div className={classes.div2} style={{ justifyContent: 'left', color: '#25233C', fontSize: '14px' }}>
                                                                    {prop.body}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="right" className={classes.tcell3}>
                                                                <div className={classes.div3} style={{ color: '#25233C', fontSize: '14px' }}>
                                                                    {prop.time}
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    })
                                                }

                                            </TableBody>

                                        </Table>
                                        {
                                            apiCall == true &&
                                            <div style={{ display: "flex", justifyContent: 'center', marginTop: 20, width: '100%' }}>
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            </div>
                                        }
                                        {
                                            apiCall == false && notificationData.length == 0 &&
                                            <h6 style={{ textAlign: 'center', color: '#6B6987' }}>No record Found</h6>
                                        }
                                    </TableContainer>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            }

        </>
    );
}

export default Notification;
