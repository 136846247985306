import React from 'react';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from "@material-ui/core/styles";

import { authenticationService } from '_services/authentication.service.js';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/loginPageStyle.js";
const useStyles = makeStyles(styles);
const ChangePasswordForm = props => {
    const classes = useStyles();
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        //Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });
    return (
        <div className={`${classes.container} ${classes.centerdiv}`}>
            <GridContainer style={{ width: "100vw" }} justify="center">
                <GridItem xs={12} sm={6} md={5}>
                    <div>
                        <Formik
                            initialValues={{
                                password: '',
                                confirmPassword: ''
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string().required('Enter password'),
                                confirmPassword: Yup.string()
                                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                                    .required('Confirm Password is required')
                            })}
                            onSubmit={({ confirmPassword }, { setStatus, setSubmitting }) => {
                                setStatus();
                                authenticationService.changePassword(confirmPassword, props.token)
                                    .then(
                                        response => {
                                            setSubmitting(false);
                                            setStatus(response.status);
                                            props.passwordChangeStatus(true);
                                        },
                                        error => {
                                            setSubmitting(false);
                                            setStatus("The link has expired, please try again.");
                                        }
                                    );
                            }}>
                            {({ errors, status, touched, isSubmitting }) => (
                                <Form>
                                    <Card className={classes[cardAnimaton]}>
                                        <CardHeader
                                            className={`${classes.cardHeader}`}
                                        >
                                            <h4 className={`${classes.cardTitle} ${classes.h4}`}>Update Password</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="form-group">
                                                <h5 className={classes.h5}>New Password</h5>
                                                <Field name="password" type="password" placeholder="Enter you new password" className={ `${classes.emailInput} ${'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}`} />
                                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <h5 className={classes.h5}>Confirm New Password</h5>
                                                <Field name="confirmPassword" type="password" placeholder="Enter you new password" className={ `${classes.emailInput} ${'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}`} />
                                                <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                            </div>
                                        </CardBody>
                                        <CardFooter className={classes.justifyContentCenter}>
                                            <Button type="submit" className={classes.loginButton} disabled={isSubmitting} simple size="lg" block>
                                                Submit
                                            </Button>
                                            {isSubmitting &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }                                            
                                        </CardFooter>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {status &&
                                                    <div className={'alert alert-danger'}>{status}</div>
                                                }
                                            </div>
                                        </div>
                                    </Card>

                                </Form>
                            )}
                        </Formik>
                    </div>

                </GridItem>
            </GridContainer>
        </div>
    );
}

const PasswordChanged = () => {

    let history = useHistory();

    const redirectToLogin = () => {
        history.push("/login");
    }

    return (
        <div>
            <SweetAlert
                success
                title="Password Changed!!"
                onConfirm={redirectToLogin}
                confirmBtnText="Return to Sign-in"
            />
        </div>
    );
}

const ChangePassword = props => {
    const [isPasswordChanged, setIsPasswordChanged] = React.useState(false);

    var changePasswordComp;
    if (isPasswordChanged) {
        changePasswordComp = <PasswordChanged />
    } else {
        changePasswordComp = <ChangePasswordForm token={props.match.params.slug} passwordChangeStatus={setIsPasswordChanged} />
    }

    return (
        <>
            {changePasswordComp}
        </>
    );
}

export default ChangePassword;