import React from 'react';
import SpdataContext from './spdataContext';
import { userService } from '_services/user.service';

// this context provider is created here so that we can set data from header and profile , payment from one section only //

//  If we are not using it we have to reload the page after each api call to show thw changes in both component unaware of each other //

const SpDataState = (props) => {

    const stateUser = {
        credits: 0,
        tierType: ''
    }

    const [stateOfUser, setStateOfUser] = React.useState(stateUser);

    const update = async (value) => {
        try {
            const response = await userService.getSP(value);
            if (response) {
                // console.log(response);
                setStateOfUser({ credits: response.credit, tierType: response.tier_type })
            }
        }
        catch (err) { }

    }

    return (
        <SpdataContext.Provider value={{ stateOfUser, update }} >

            {props.children}

        </SpdataContext.Provider>
    )

}

export default SpDataState;