import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { Card, CardHeader, CardContent, CardMedia, CardActions, Grid, IconButton, InputBase, Paper, Typography, ListItemSecondaryAction } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    BrowserRouter as Router,
    useParams,

} from "react-router-dom";
import { userService } from "_services/user.service";

const useStyles = makeStyles((theme) => ({
    List: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        paddingTop: "unset"
    },
    searchWrap: { border: "1px solid #B9B8C4", display: "flex", paddingLeft: 15, borderRadius: 5, width: "100%", justifyContent: "space-between" },
    container: {
        bottom: 0
    },
    bubbleContainer: {
        width: "100%",
        display: "flex"
    },
    bubble: {
        // border: "0.5px solid black",
        borderRadius: "10px",
        margin: "5px",
        padding: "15px",
        display: "inline-block"
    },
    msg: {
        color: "#25233C",
        fontFamily:'Muli',
        fontSize: "16px",
    },
    Listdate: {
        // transform: "translateY(-65%)",
        color: "#8E8CAC",
        fontSize: 13,
        position: "absolute",
        right: "20px",
        top: "17px",
        fontFamily:'Muli-SemiBold'
    },
    chatWrapper: {
        [theme.breakpoints.down('md')]: {
            padding: "unset !important"
        },
    },
    messsage:
    {
        color:'#25233C',
        fontSize:'18px',
        fontFamily:'Muli-SemiBold !important'
    },
    input:
    {
        fontFamily:'Muli !important',
        color:'#9997B2',
        fontSize:'14px'
    }

}));




const UserRequest = () => {
    const classes = useStyles();

    const { slug } = useParams();

    // function for sorting list of user on basis of their name

    const sortUsers = (e) => {
        if (e.target.value.length > 0) {
            let dataToSort = []
            totalUsers.map((data) => {
                if (data.name.toLowerCase().includes(e.target.value.toLowerCase())) {
                    dataToSort.push(data);
                }
            })
            setListOfUsers([...dataToSort])
        }
        else {
            setListOfUsers([...totalUsers]);
        }

    }



    const getMessageList = async (id) => {
        setApiCallForMsg(true);
        try {
            const fullChatResponse = await userService.getUserOneFullChat(id)
            if (fullChatResponse.length > 0) {
                let arr = [];
                fullChatResponse.map((prop) => {
                    if (prop.send_by !== "customer") {
                        let obj = {
                            msg: prop.message_text,
                            direction: 'left'
                        }
                        if (prop.url) {
                            obj.url = prop.url
                        }
                        arr.push(obj);
                    }
                    else {
                        let obj = {
                            msg: prop.message_text,
                            direction: 'right'
                        }
                        if (prop.url) {
                            obj.url = prop.url
                        }
                        arr.push(obj)
                    }
                })

                setMessageFullData({
                    image: fullChatResponse[0].sp_image,
                    name: fullChatResponse[0].sp_name,
                    id: fullChatResponse[0].unique_id,
                    data: [...arr]
                })
            }
            setApiCallForMsg(false);
        }
        catch (err) {
            setApiCallForMsg(false);
        }
    }

    const [totalUsers, setTotalUsers] = React.useState([]);
    const [listOfUsers, setListOfUsers] = React.useState([]);
    const [apiCall, setApiCall] = React.useState(false);
    const [apiCallForMsg, setApiCallForMsg] = React.useState(false);
    const [messageFullData, setMessageFullData] = React.useState({ image: '', name: '', id: '', data: [] })

    const ListNameColor = {
        color: "#25233C",
        fontSize: 18,
        marginBottom: 5
    };
    const ListDetail = {
        color: "#25233C",
    };


    // when first time renders useEffect call to get all sp chats *************************************************

    React.useEffect(() => {

        const getChatOfUser = async () => {
            setApiCall(true);
            try {
                const listOfMessages = await userService.getUserMessageList(slug);
                setApiCall(false);
                if (listOfMessages.length > 0) {
                    let array = [];
                    listOfMessages.map((data) => {
                        let date = new Date(data.created_at.replace(/-/g, "/")); // M-D-YYYY
                        let d = date.getDate();
                        let m = date.toLocaleString('default', { month: 'long' });
                        let y = date.getFullYear();
                        let dateString = (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
                        let obj = {
                            id: data.unique_id,
                            name: data.sp_name,
                            msg: data.url?.length > 0 ? 'Image' : data.message_text,
                            date: dateString,
                            image: data.sp_image
                        }
                        array.push(obj)
                    })
                    setListOfUsers([...array])
                    setTotalUsers([...array])
                }
                else {
                    setListOfUsers([])
                    setTotalUsers([])
                }

            }
            catch (err) {
                setApiCall(false);
            }
        }

        getChatOfUser();



        return function cleanup() {
            setListOfUsers([])
            setTotalUsers([])
            setMessageFullData([])
            setApiCallForMsg(false)
            setApiCall(false)
        }

    }, [])


    React.useEffect(() => {
        if (totalUsers.length > 0) {

            getMessageList(totalUsers[0].id);


        }


    }, [totalUsers])

    return (
        <div className={classes.root}>
            { apiCall == false && totalUsers.length > 0 &&
                <Grid container spacing={5} >
                    <Grid item xl={3} lg={4} xs={12} className="px-0">
                        <List className={classes.List}>
                            <ListItem className={'py-4'}>
                                <div className={classes.searchWrap}>
                                    <InputBase
                                        className={classes.input}
                                        placeholder="Search"
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={sortUsers}
                                    />
                                    <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </div>
                            </ListItem>
                            <Divider />
                            <div style={{ height: '420px', overflowY: 'scroll' }}>
                                {
                                    listOfUsers.map((prop, index) => {
                                        return <div key={prop.id} onClick={() => { getMessageList(prop.id) }} >
                                            <ListItem button>
                                                <Avatar className={'mr-2'}>
                                                    <img style={{ height: '50px', width: '50px' }} src={`${prop.image}`} />
                                                </Avatar>
                                                <ListItemText primaryTypographyProps={{ style: ListNameColor }} primary={prop.name}
                                                    secondaryTypographyProps={{ style: ListDetail }} secondary={prop.msg} className={classes.messsage}/>
                                                <Typography className={classes.Listdate}>{prop.date}</Typography>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    })
                                }
                            </div>
                        </List>
                    </Grid>
                    <Grid item xl={9} lg={8} xs={12} className={classes.chatWrapper} >
                        <Card className={classes.root}>
                            {
                                messageFullData.data.length > 0 &&
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            <img style={{ height: '50px', width: '50px' }} src={`${messageFullData.image}`} />
                                        </Avatar>
                                    }
                                    // action={
                                    //     <IconButton aria-label="settings">
                                    //         <DeleteIcon />
                                    //     </IconButton>
                                    // }
                                    title={messageFullData.name}
                                    style={{fontSize:'18px',color:'#25233C',fontFamily:'Muli-SemiBold !important'}}
                                // subheader="September 14, 2016"
                                />
                            }

                            <Divider />

                            <CardContent style={{ height: '450px', overflowY: 'scroll' }}>
                                {apiCallForMsg == false && messageFullData.data.map((obj, i = 0) => {
                                    if (!obj.url) {
                                        return <div className={`${classes.bubbleContainer} ${obj.direction}`} key={i}>
                                            <div key={i++} className={`${classes.bubble} ${'msg'}`} >
                                                <div className={classes.msg}>{obj.msg}</div>
                                            </div>
                                        </div>
                                    }
                                    else {
                                        return <div className={`${classes.bubbleContainer} ${obj.direction}`} key={i}>
                                            <div onClick={() => { window.open(obj.url); }} key={i++} className={`${classes.bubble} ${'msg'}`} style={{ background: `url(${obj.url})`, height: '200px', width: '200px', backgroundSize: 'cover', backgroundPosition: 'center center', cursor: 'pointer' }} >

                                            </div>
                                        </div>
                                    }

                                })}
                                {
                                    apiCallForMsg == true &&
                                    <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </div>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {
                apiCall == true &&
                <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
            }
            { apiCall == false && totalUsers.length == 0 &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h6>No Requests</h6>
                </div>
            }
        </div>
    )
}

export default UserRequest
