import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import AppBar from '@material-ui/core/AppBar';
import ReactTable from "_components/ReactTable.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { SPService } from '_services/serviceprovider.service';
import EditIcon from '@material-ui/icons/Edit';
import edit from '../../../edit-svgrepo-com.svg'
import { useRouteBreadCrumbs } from "hooks/useRouteBreadCrumbs";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch,

} from "react-router-dom";
import AddPro from './AddPro';
import EditPro from './EditPro';

const useStyles = makeStyles((theme) => ({
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    button2: {
        background: "transparent",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8cac"
    },
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    fontSize20: {
        fontSize: '20px',
        color:'#25233C',
        paddingTop:'12px',
        paddingBottom:'4px',
        fontWeight:'bold'
    },
    tableCard:{
    "@media (max-width: 1340px)":{
        overflow: "auto"
    }
    },
    tableCardBody:{
    padding: 0,
    "@media (max-width: 1340px)":{
        width: "991px",
    }
    },

}));

const TopPros = (props) => {
    const classes = useStyles();
    let { path, url } = useRouteMatch();
    const [topProData, setTopProData] = React.useState([]);
    const [apiCall, setApiCall] = React.useState(false);
    const [updatedTopPros, setUpdatedTopPros] = React.useState([]);
    const breadCrumbs = useRouteBreadCrumbs();
    function formatDate(dateParameter) {
        let date = new Date(dateParameter); // M-D-YYYY
        let d = date.getDate();
        let m = date.toLocaleString('default', { month: 'long' });
        let y = date.getFullYear();
        let dateFormat = (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
        return dateFormat;
    }

    React.useEffect(() => {
        const getTopPros = async () => {
            try {
                setApiCall(true);
                const resForTopPros = await SPService.getTopPros();
                setTopProData(resForTopPros);

                // if (resForTopPros) {
                //     let array2 = [];
                //     resForTopPros.map((prop, index) => {

                //         const dateFromString = formatDate(prop.start_date);

                //         const dateToString = formatDate(prop.end_date !== null ? prop.end_date : Date.now());

                //         array2.push({
                //             id: prop._id,
                //             subcategory: prop.subcategoryName ? prop.subcategoryName : "not Available",
                //             serviceprovider: prop.sp_name,
                //             fromDate: dateFromString,
                //             toDate: prop.end_date !== null ? dateToString : 'Till Now',
                //             location: prop.place_names ? prop.place_names.join(', ') : "not Available",
                //         });
                //     })
                //     setTopProData([...array2]);
                // }
                setApiCall(false);
            }
            catch (error) {
                setApiCall(false);
            }
        }

        getTopPros();


    }, [props.history.location.pathname])

    // console.log(topProData, "pushed data")
    React.useEffect(() => {
        let arrayAction = [];
        if (topProData.length > 0) {
            topProData.map((prop) => {
                const dateFromString = formatDate(prop.start_date);
                const dateToString = formatDate(prop.end_date !== null ? prop.end_date : Date.now());

                arrayAction.push({
                    id: prop._id,
                    subcategory: prop.subcategoryName ? prop.subcategoryName : "not Available",
                    serviceprovider: prop.sp_name,
                    fromDate: dateFromString,
                    toDate: prop.end_date !== null ? dateToString : 'Till Now',
                    location: prop.location ? prop.location : "not Available",
                    actions: (
                        <div className="actions-right">
                            <Link to={{
                                pathname: "/app/product_services/topPro/editPro", state: {
                                    id: prop._id,
                                    subcategory: prop.subcategoryName ? prop.subcategoryName : "not Available",
                                    serviceprovider: prop.sp_name,
                                    fromDate: dateFromString,
                                    toDate: prop.end_date !== null ? dateToString : 'Till Now',
                                    location: prop.location  ? prop.location  : "not Available",
                                }
                            }}>
                                <IconButton >
                                    {/* <EditIcon /> */}
                                    <img src={edit} style={{width:'21px', height:'18px',color:'#8E8CAC'}}/>
                                </IconButton>
                            </Link>
                            {" "}
                            {/* { prop.type !== 'auto' &&
                    <IconButton onClick={() => {
                      SweetAlertConfirm(prop._id);
                    }} >
                      <DeleteIcon />
                    </IconButton>}
    
                  {" "} */}
                        </div>
                    )
                })
                // })
            });
            setUpdatedTopPros(arrayAction);
        }
    }, [topProData])

    // return to previous page 

    function getToPrevious() {
        // const { from } = { from: { pathname: "/app/product_services" } };
        props.history.goBack();
    }


    return (
        <>
            {
                window.location.href.split('/')[window.location.href.split('/').length - 1] == 'topPro' && <div>
                    <AppBar elevation={0} position="static" color="transparent" className={classes.appbarAdj} >
                        <div className={classes.header}>
                            <IconButton aria-label="edit" onClick={getToPrevious} style={{paddingLeft:'0px',color:'#25233C'}}>
                                <ArrowBackIcon />
                            </IconButton>
                            <h4 className={classes.fontSize20} style={{fontFamily: "Muli-Bold", transform: 'translateY(-5px)'}}>Top Pros</h4>
                        </div>
                    </AppBar>
                    <GridContainer container>
                        <GridItem xs={12}>
                            <Card className={classes.tableCard} style={{marginLeft:'-15px',marginTop:'10px'}}>
                                <CardBody className={classes.tableCardBody}>
                                    {topProData.length == 0 && apiCall == true &&
                                        <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    }

                                    {topProData.length == 0 && apiCall == false &&

                                        <h6 style={{ textAlign: 'center', marginBottom: 20 }}>
                                            No Top Pros Assigned
                                        </h6>

                                    }


                                    {topProData.length > 0 && apiCall == false &&

                                        <ReactTable
                                            className={'user-table-adj'}
                                            columns={[
                                                {
                                                    Header: "Sub Category",
                                                    accessor: "subcategory",
                                                    placeholder:'category'
                                                },
                                                {
                                                    Header: "Service Providers",
                                                    accessor: "serviceprovider",
                                                    placeholder:'name'

                                                },
                                                {
                                                    Header: "From",
                                                    accessor: 'fromDate',
                                                    placeholder:'date'

                                                },
                                                {
                                                    Header: "To",
                                                    accessor: 'toDate',
                                                    placeholder:'date'

                                                },
                                                {
                                                    Header: "Location",
                                                    accessor: 'location',
                                                    Footer:'Location'
                                                    
                                                },
                                                {
                                                    Header: "",
                                                    accessor: "actions",
                                                }
                                            ]}


                                            data={updatedTopPros.length > 0 ? updatedTopPros : []}
                                            onRowClick={(rowData, clickType) => { }}
                                        // onRowClick={(rowData, clickType) => handleRowClick(rowData, clickType)}
                                        />
                                    }
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            }

            <div className="container-fluid">
                <Switch>
                    <Route path={`${path}/addPro`}>
                        <AddPro {...props} />
                    </Route>
                    <Route path={`${path}/editPro`}>
                        <EditPro {...props} />
                    </Route>
                </Switch>
            </div>

        </>
    );
}

export default TopPros;
