import React, { useState, useEffect } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardMedia, Icon, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import SweetAlert from 'react-bootstrap-sweetalert';
import { categoryService } from '_services/category.service';
import { generalService } from '_services/general.service';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
import AddCategory from './AddCategory';
import edit from '../../../edit-svgrepo-com.svg'
const useStyles = makeStyles((theme) => ({
    mdFlex:{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "100%"
    },
    cover: {
        border: "1px solid #8E8CAC",
        height: "100%",
        maxWidth: 74,
        minHeight:74,
        width: "100%",
        
    },
    cardTitle: {
        margin: "unset",
        padding: "0 20px",
        fontSize: 20,
        color: "#25233C",
        fontWeight:"400",
        '@media(max-width:388px)':
        {
            fontSize:'18px'
        }
    },
    cardAction: {
        color: "#000",
    },
    button: {
        margin: theme.spacing(1),
        background: "transparent",
        boxShadow: "unset",
        border: "1px solid #8f8cac",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "transparent",
            boxShadow: "unset",
            textDecoration:"none"
            // color: '#ffffff',
        },
        '@media(max-width:388px)':
        {
            // width:'50%'
        }
    }
,
    table: {
        margin: "10px 0",
        background: "#f5f5fc"
    },
    tcontainer: {
        //   margin:"0 -5px",
    },
    thead: {
        padding: 10
    },
    gridCont: {
        paddingTop: "10px",
        margin: 0,
    },
    gridItem1: {
        display: "flex",
        alignItems: "center",
    },
    gridItem2: {
        textAlign: "right",
    },
    tcell1: {
        border: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
        paddingLeft: "0px !important",
    },
    tcell2: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell2sub: {
        padding: "unset",
        margin: "auto",
        border: "unset",
    },
    tcell3: {
        border: "unset",
        paddingLeft: "unset",
        paddingBottom: "unset",
        '@media(max-width:388px)':
        {
            width:'50%',
            paddingRight:'0px'
           
        }
    },
    div1: {
        display: "flex",
        alignItems: "center",
        background: "#F3F3FC",
        padding: 10,
        height: 50,
        '@media(max-width:388px)':
        {
            width:'50%'
        }
    },
    div2: {
        background: "#F3F3FC",
        padding: 15,
        color: "#6d6b75",
        height: 50,
        display: "flex",
        justifyContent: "space-between",
    },
    div3: {
        background: "#F3F3FC",
        padding: 1,
        height: 'auto',
        "@media (max-width: 480px)":{
            width: "115px",
            marginLeft: "auto"
        }
    },
    blockTitle: {
        paddingLeft: 10,
        color:"#25233C",
        '@media(max-width:388px)':
        {
            fontSize:'14px',
            whiteSpace: 'nowrap'
        }
    },
    mainCard: {
        marginBottom: '2em',
    },
    subcellWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    subCell: {
        padding: 0,
        border: "unset",
        margin: "auto",
    },
    textDecNone:{
        textDecoration:"none",
        "&:hover": {
            textDecoration:"none"
           },
    },
    imgItem:{border:"1px solid grey",height:38,width:38,background:"#fff",alignItems: "center",display: "flex",justifyContent: "center"},
    actions:{
        textAlign: "right",
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
        "@media (max-width: 959px)":{
            justifyContent: "flex-start"
        },
    }
    
}));


const StyledTableCell = withStyles((theme) => ({
    head: {
        //   backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
        backgroundColor: "#F5F5FC",
        color: "#6c6987",
    },

}))(TableCell);


function createData(name, credit) {
    return { name, credit };
}

const rows = [
    createData('Waxing & Threading', 10, 6.0),
    createData('Facial & Cleanup', 25, 9.0),
    createData('Bleach & Detan', 30, 16.0),
];


const ProductCategoryMaster = (props) => {
    const classes = useStyles();
    const [pageRefresh, setPageRefresh] = useState(false);
    const [alert, setAlert] = useState(null);
    const [category, setCategory] = useState([]);
    const [tabTypeEvent, settabTypeEvent] = useState(props.eventTab);


    function deleteCategory(cat) {
        setPageRefresh(false);
        categoryService.deleteCategory(cat._id)
            .then((res) => {
                setAlert(null);
                setPageRefresh(true);
            })
            .catch((err) => {
                SweetAlertConfirmError(err)
            })
    }

    function editCategory(cat) {
        const { from } = { from: { pathname: "/app/product_services/categories/editcategory/" + cat._id, ...props } };
        props.history.push(from);
    }

    function deleteSubCategory(subCat) {
        setPageRefresh(false);
        categoryService.deleteSubCategory(subCat._id)
            .then((res) => {
                setAlert(null)
                setPageRefresh(true);
            })
            .catch((err) => {
                SweetAlertConfirmError(err)
            })
    }

    function editSubCategory(cat, subCat) {
        const { from } = { from: { pathname: "/app/product_services/categories/editsubcategory/" + subCat._id, ...props, state: { category: category, cat: cat } } };
        props.history.push(from);
    }

    function SweetAlertConfirmError(msg) {
        const getAlert = () => (
            <SweetAlert
            error
            title="Error"
            onConfirm={() =>{ 
                setPageRefresh(true);
                setAlert(null)
            }}
        >
            {msg}
        </SweetAlert>
        )
        setAlert(getAlert());
    }


    function SweetAlertConfirm(flag, cat) {
        const getAlert = () => (cat &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() => flag == 'cat' ? deleteCategory(cat) : deleteSubCategory(cat)}
                onCancel={() => setAlert(null)}
                focusCancelBtn
            >
                You want to delete this record?
            </SweetAlert>
        )
        setAlert(getAlert());
    }

    useEffect(() => {
        categoryService.getCategory()
            .then((res) => {
                setCategory(res);
            })
            .catch((err) => {

            })
    }, [pageRefresh === true]);
    const bull = <span className={classes.bullet}>•</span>;
    return (
        <>
            {category.length > 0 && tabTypeEvent == "false" && category.map((cat) => (cat.event == "false" &&
                <CategoryCards category={category} cat={cat} key={cat._id} SweetAlertConfirm={SweetAlertConfirm} editCategory={editCategory} editSubCategory={editSubCategory} />
            ))}
            {category.length > 0 && tabTypeEvent == "true" && category.map((cat) => (cat.event == "true" &&
                <CategoryCards cat={cat} key={cat._id} SweetAlertConfirm={SweetAlertConfirm} editCategory={editCategory} editSubCategory={editSubCategory} />
            ))}
            {alert}
        </>
    )
}

export default ProductCategoryMaster;


export function CategoryCards({ category, cat, SweetAlertConfirm, editCategory, editSubCategory }) {
    const classes = useStyles();
    const isMedium = useMediaQuery("@media(min-width: 767px)")

    return (
        <Card className={`${classes.mainCard} productCategory`} key={cat._id}>
            <GridContainer style={{ paddingTop: "20px", paddingBottom: "20px", margin: 0}}>
                <GridItem xs={12} sm={6} md={6} style={{ display: "flex", alignItems: "center",marginBottom:5 }}>
                    {!cat.image_url && <CardMedia className={classes.cover} image="https://maxcdn.icons8.com/Share/icon/p1em/Very_Basic/home1600.png" title={cat.name} />}
                    {cat.image_url && <CardMedia className={classes.cover} image={cat.image_url} title={cat.name} />}
                    <h4 className={classes.cardTitle}>{cat.name}</h4>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.actions} >
                    <div className={isMedium && classes.mdFlex}>
                    <Link className={classes.textDecNone} to={{ pathname: "/app/product_services/categories/addsubcategory", state: { category: category, cat: cat } }}>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            startIcon={<Icon color="primary">add</Icon>}
                        >
                            <span className="">
                            Add Sub Category
                            </span>

                        </Button>
                    </Link>
                    <IconButton aria-label="edit" onClick={() => editCategory(cat)} style={{paddingRight:'5px'}}>
                    <img src={edit} style={{width:'21px', height:'18px'}}/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => SweetAlertConfirm('cat', cat)} style={{paddingLeft:'0px'}}>
                    <DeleteIcon style={{width:'21px', height:'18px',color:'#8E8CAC'}}/>
                    </IconButton>
                    </div>
                </GridItem>
                {cat.subcategory.length > 0 &&
                    <TableContainer className={classes.tcontainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.thead}>
                                <TableRow>
                                    <StyledTableCell style={{fontSize: 16, fontFamily: "Muli-Bold"}}>Name</StyledTableCell>
                                    {/* <StyledTableCell className={'text-center'} align="center">
                                       <div className={'mb-2'}> Credit Points </div>
                                        <div className={classes.subcellWrapper}>
                                            <StyledTableCell className={classes.subCell} >First 3 bookings</StyledTableCell>
                                            <StyledTableCell className={classes.subCell} >Up to 10 bookings</StyledTableCell>
                                            <StyledTableCell className={classes.subCell} >10+ bookings</StyledTableCell>
                                        </div>
                                    </StyledTableCell> */}
                                    <StyledTableCell align="right" style={{fontSize: 16, fontFamily: "Muli-Bold"}}>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
                                {cat.subcategory.map((row) => (<TableRow key={row.name}>
                                    <TableCell component="th" scope="row" className={classes.tcell1}>
                                        <div className={classes.div1}>
                                        <div className={classes.imgItem}>
                                            {!row.image_url && <img height="30" src="https://maxcdn.icons8.com/Share/icon/p1em/Very_Basic/home1600.png" />}
                                            {row.image_url && <img height="38" width="38px" src={row.image_url} />}
                                        </div>
                                            <Link className={classes.textDecNone} to={{ pathname: "/app/product_services/services", state: { category: category, cat: cat, row: row } }}> <Typography variant="body1" className={classes.blockTitle} style={{fontSize: 14}}>
                                                {row.name}
                                            </Typography>
                                            </Link>
                                        </div>
                                    </TableCell>
                                    {/* <TableCell align="left" className={classes.tcell2}>
                                        <div className={classes.div2}>
                                            <TableCell className={classes.tcell2sub}> {row.slab_first}</TableCell>
                                            <TableCell className={classes.tcell2sub}> {row.slab_second}</TableCell>
                                            <TableCell className={classes.tcell2sub}> {row.slab_third}</TableCell>
                                        </div>
                                    </TableCell> */}
                                    <TableCell align="right" className={classes.tcell3}>
                                        <div className={classes.div3}>
                                            <IconButton aria-label="edit" onClick={() => editSubCategory(cat, row)}style={{paddingRight:'5px'}}>
                                            <img src={edit} style={{width:'21px', height:'18px'}}/>
                                            </IconButton>
                                            <IconButton aria-label="delete" onClick={() => SweetAlertConfirm('subCat', row)} style={{paddingLeft:'0px'}}>
                                            <DeleteIcon style={{width:'21px', height:'18px',color:'#8E8CAC'}}/>
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
            </GridContainer>
        </Card>
    )
}
