import React from "react";
// react plugin for creating charts
import { Line } from 'react-chartjs-2';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardList from "components/CardList/CardList.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { userService } from "_services/user.service";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Profile from "../Profile/Profile";

const useStyles = makeStyles((theme) => ({
  cardTitleWhite:
  {
    fontSize:'20px',
    color:'#25233C'
  },
  cardCategory:
  {
    fontSize:'15px',
    color:'#25233C'
  },
  cardTitle:
  {
    fontSize:'35px',
    color:'#25233C'
  },
  notificationValue:{
    minHeight:'287px'
  }
}));


const data = {
  labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  datasets: [
    {
      label: "",
      data: [3, 3.5, 4.5, 5, 6, 5, 5.5, 6, 5.5, 4],
      fill: false,
      borderColor: 'rgb(128, 0, 255, 0.5)',
      backgroundColor: 'rgb(128, 0, 255, 0.3)',
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    }
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: true,
        borderColor: 'rgb(128, 0, 255, 0.3)',
      },
      grid: {
        color: 'rgb(128, 0, 255, 0.1)',
      },
    },
    y: {
      max: 10,
      ticks: {
        beginAtZero: true,
        borderColor: 'rgba(2, 255, 255, 1)',
      },
      grid: {
        color: 'rgb(128, 0, 255, 0.1)',
      },
    },
  },
};

export default function Dashboard(props) {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [spRegistrationData, setSpRegistrationData] = React.useState(null);
  const [userRegistrationData, setUserRegistrationData] = React.useState(null);
  const [userLastRegisDate, setUserLastRegisDate] = React.useState([]);
  const [spLastRegisDate, setSpLastRegisDate] = React.useState([]);
  const [notificationData, setNotificationData] = React.useState([]);


  // function for calculating time difference

  function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    //diffInMilliSeconds -= days * 86400;
    // console.log('calculated days', days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    // diffInMilliSeconds -= hours * 3600;
    // console.log('calculated hours', hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    // diffInMilliSeconds -= minutes * 60;
    // console.log('minutes', minutes);

    let difference = '';
    if (days > 0) {
      difference += (days === 1) ? `${days} day ` : `${days} days `;
    }

    if (days <= 0 && hours > 0) {
      difference += (hours === 0 || hours === 1) ? `${hours} hour ` : `${hours} hours `;
    }
    if (days <= 0 && hours <= 0 && minutes > 0) {
      if (minutes >= 4) {
        difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;
      }
      else {
        difference += 'A moment'
      }
    }
    return difference;
  }

  React.useEffect(() => {
    let isMount = false;

    const getData = async () => {
      if (!isMount) {
        try {
          const res1 = await userService.getSPDashBoardData();
          const res2 = await userService.getUserDashBoardData();
          let responseOfNotifications = await userService.getAllAdminNotification({ seen_status: "unseen" });
          if (res1) {
            const spLastDateReg = new Date(res1?.lastuser_registration_at).toDateString().toString().split(' ');
            setSpLastRegisDate(spLastDateReg);
            setSpRegistrationData(res1);
          }

          if (res2) {
            const userLastDateReg = new Date(res2?.lastuser_registration_at).toDateString().toString().split(' ');
            setUserLastRegisDate(userLastDateReg)
            setUserRegistrationData(res2);
          }

          if (responseOfNotifications.data.length > 0) {

            const arrForNotification = [];
            const date = [];

            responseOfNotifications.data.map((prop) => {

              const notificationDate = new Date(prop.created_at.replace(/-/g, "/")).toDateString().toString().split(' ');

              const time = timeDiffCalc(new Date(prop.created_at.replace(/-/g, "/")), new Date(responseOfNotifications.current_date.replace(/-/g, "/")));

              let obj = {
                msg: [prop.msg],
                date: notificationDate,
                time: time
              }
              arrForNotification.push(obj);
            })
            setNotificationData([...arrForNotification]);
          }
          else {
            setNotificationData([]);
          }
        }
        catch (err) { }
      }
    }


    getData();



    return function cleanup() {

      isMount = true
      setSpRegistrationData(null);
      setUserRegistrationData(null);
      setUserLastRegisDate([])
      setSpLastRegisDate([]);
      setNotificationData([]);

    }
  }, [])

  return (
    <>
      {
        window.location.href.split('/')[window.location.href.split('/').length - 1] == 'dashboard' &&
        <div className="row" style={{marginTop:'0px'}}>
          <div className="col-md-8 col-12">
            <h4 style={{fontSize:'20px', color:'#25233C'}}>Users</h4>
            <GridContainer style={{ margin: "0 -15px" }}>
              <GridItem xs={12} sm={6} md={4}>
                <Card style={{marginTop:'0px'}}>
                  <CardHeader>
                    <p className={classes.cardCategory}>Total Registered Users</p>
                    <h2 className={classes.cardTitle}>
                      <b>{userRegistrationData?.totaluser?userRegistrationData.totaluser:0}</b>
                    </h2>
                  </CardHeader>
                  {/* <CardFooter stats>
                    {`Last Registration On ${userLastRegisDate[1]} ${userLastRegisDate[2]}, ${userLastRegisDate[3]}`}
                  </CardFooter> */}
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={4}>
                <Card style={{marginTop:'0px'}}>
                  <CardHeader>
                    {/* <p className={classes.cardCategory}>Active / Inactive Users</p> */}
                    <p className={classes.cardCategory}>Active Users</p>
                    <h2 className={classes.cardTitle}>
                      {/* <b>{userRegistrationData.activeuser} / {userRegistrationData.inactiveuser}</b> */}
                      <b>{userRegistrationData?.activeuser?userRegistrationData?.activeuser:0}</b>
                    </h2>
                  </CardHeader>
                  {/* <CardFooter stats>
                    {userRegistrationData.activeuser} Users Currently Active
              </CardFooter> */}
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={4}>
                <Card style={{marginTop:'0px'}}>
                  <CardHeader>
                    <p className={classes.cardCategory}>Monthly Registration</p>
                    <h2 className={classes.cardTitle}>
                      <b>{userRegistrationData?.lastmonth_registration?userRegistrationData?.lastmonth_registration:0}</b>
                    </h2>
                  </CardHeader>
                  {/* <CardFooter stats>
                    {`Last Registration On ${userLastRegisDate[1]} ${userLastRegisDate[2]}, ${userLastRegisDate[3]}`}
                  </CardFooter> */}
                </Card>
              </GridItem>

            </GridContainer>
            <h4 style={{fontSize:'20px', color:'#25233C'}}>Service Providers</h4>
            <GridContainer style={{ margin: "0 -15px" }}>

              <GridItem xs={12} sm={6} md={4}>
                <Card style={{marginTop:'0px'}}>
                  <CardHeader>
                    <p className={classes.cardCategory}>Total Registered Service Providers</p>
                    <h2 className={classes.cardTitle}>
                      <b>{spRegistrationData?.totaluser?spRegistrationData?.totaluser:0}</b>
                    </h2>
                  </CardHeader>
                  {/* <CardFooter stats>
                    {`Last Registration On ${spLastRegisDate[1]} ${spLastRegisDate[2]}, ${spLastRegisDate[3]}`}
                  </CardFooter> */}
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={4}>
                <Card style={{marginTop:'0px'}}>
                  <CardHeader>
                    <p className={classes.cardCategory}>Active Service Providers</p>
                    <h2 className={classes.cardTitle}>
                      {/* <b>{spRegistrationData.activeuser} / {spRegistrationData.inactiveuser}</b> */}
                      <b>{spRegistrationData?.activeuser?spRegistrationData?.activeuser:0}</b>
                    </h2>
                  </CardHeader>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={4}>
                <Card style={{marginTop:'0px'}}>
                  <CardHeader>
                    <p className={classes.cardCategory}>Monthly Registration</p>
                    <h2 className={classes.cardTitle}>
                      <b>{spRegistrationData?.lastmonth_registration?spRegistrationData?.lastmonth_registration:0}</b>
                    </h2>
                  </CardHeader>
                </Card>
              </GridItem>

            </GridContainer>
             <div>
          <h4 style={{fontSize:'20px', color:'#25233C'}}>Revenue Generated</h4>
          <Card style={{ position: "relative", height:"25vh", width:"auto" }}>
            <Line 
              data={data}
              options={options}
            />
          </Card>
        </div> 
          </div>
          <div className="col-md-4 col-12">
            <h4 className={classes.cardTitleWhite}>Notifications</h4>
            <Card style={{marginTop:'0px'}} className={classes.notificationValue}>
              <CardBody style={{ padding: "0 10px" }}>
                <CardList
                  tableData={
                    notificationData
                  }
                />
              </CardBody>
            </Card>
          </div>
        </div>
      }

      <div className="container-fluid">
        <Switch>
          <Route path={`${path}/profile`}>
            <Profile {...props} />
          </Route>
        </Switch>
      </div>

    </>
  );
}
