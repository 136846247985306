import { Button, IconButton, Box, TextField } from '@material-ui/core'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { OutlinedInput } from '@material-ui/core'
import { Image } from '@material-ui/icons'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { renderText } from '../common/DisplayComponent'
import Checkbox from '@material-ui/core/Checkbox'
import { ListItemText } from '@material-ui/core'
import Card from 'components/Card/Card.js'
import DeleteIcon from '@material-ui/icons/Delete'
import RemoveIcon from '@material-ui/icons/Remove'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { useRef } from 'react'
import { getPackageImages } from "_services/servicesandpackages.service";


const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const customStyles = {
  btn:{
    padding: "15px",
    borderRadius: "10px",
    width: "100%",
    border: "1px solid #B9B8C4",
    height: "50px",
    backgroundColor: "transparent",
    cursor: "pointer"
  },
  imageGrid:{
    display: "grid",
    gap:"10px",
    gridTemplateColumns: "repeat(auto-fill, minmax(75px, 1fr))",
  },
  img:{
    width: "100%",
    height: "75px",
    aspectRatio: "1/1",
    objectFit: "cover",
    cursor:"pointer"
  },
  removeIcon:{
    color:"#DB0D0D",
    position: "relative",
    top: "-30px",
    right: "15px",
    cursor: "pointer"
}
}

const useStyles = makeStyles((theme) => ({
  cameraIcon:{
    color: "rgba(0,0,0,0.5)"
  },
  button: {
    margin: theme.spacing(1),
    background: '#fff',
    boxShadow: 'unset',
    textTransform: 'capitalize',
    color: '#8f8cac',
    '&:hover': {
      background: '#efefef',
      boxShadow: 'unset',
      textDecoration: 'none',
      // color: '#ffffff',
    },
  },
  formControl: {
    minWidth: '100%',
    marginBottom: 15,
  },
  marginTop50px: {
    marginTop: '50px',
  },
  h5: {
    color: '#25233C',
    fontSize: 18,
  },
  selectadj: {
    border: '1px solid #B9B8C4',
    height: 50,
    padding: 15,
    borderRadius: 10,
  },
  selectService:
  {
    border: '1px solid #B9B8C4',
    height: 50,
    padding: 15,
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 10
    },
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    minHeight: '100%',
    padding: '16px',
    // margin:"20px 0"
  },
  boxStyle: {
    marginTop: '30px',
  },
  displayFlexJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  marginBottom24: {
    marginBottom: '24px',
  },
  minPaymentSection: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '65%',
    alignItems: 'center',
    marginTop: '30px',
  },
  width44: {
    width: '44%',
  },
  width114px: {
    width: '114px',
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    width: '18%',
  },
  displayFlex: {
    display: 'flex',
  },
  textCenterFontBold: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  colorTimingFont12: {
    color: '#005CB2',
    fontSize: '12px',
  },
  profileImageUpload: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    border: '1px solid grey',
  },
  imgUploadprofileDisplay: {
    display: 'flex',
    alignItems: 'center',
  },
  marginLeft20px: {
    marginLeft: '20px',
  },
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid red',
    },
  },
  buttonStyleNextPrevious: {
    marginLeft: 10,
    backgroundColor: '#DB0D0D',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '10px',
    border: "1px solid #DB0D0D",
    // border:'1px solid #DBODOD',
    '&:hover': {
      background: "#fff",
      color: "#DB0D0D"
    },
  },
  errorSelectStyle: {
    border: '2px solid red',
    borderRadius: '5px',
    height: '45px',
  },
  displayFlexJustifyBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  widthDialog: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '450px',
      '@media (max-width:600px)': {
        minWidth: '300px',
      },
    },
  },
  imgStyle: {
    width: '90px',
    height: '90px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    marginRight: '20px',
    borderRadius: '10px',
  },

  input1: {
    height: '30px',

  },
  roots: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 10
    },
  },
}))

const ServicePackageSetup = ({
  open,
  handleClose,
  errorCheckForService,
  onChangeSelectCategory,
  dataSelectServices,
  categoryArray,
  onChangeSelectSubCategory,
  subCategoryArray,
  onChangeSelectService,
  servicesArray,
  handleServiceData,
  handleServiceDataDuration,
  saveServices,
  openPackage,
  handleClosePackage,
  errorCheckForPackage,
  addServiceData,
  handleTextDataForPackage,
  onChangeSelectCategoryForPackage,
  categoryArrayForPackage,
  onChangeSelectSubCategoryForPackage,
  subcategoryArrayForPackage,
  serviceId,
  handleChangeMulti,
  serviceNameDisp,
  servicesArrayForPackage,
  packageId,
  addedService,
  removeService,
  priceTotal,
  savePackage,
  addedPackage,
  SweetAlertConfirmDelete,
  SweetAlertConfirm,
  handleNext,
  handleClickOpen,
  handleClickOpenPackage,
  openPicker,
  handleClosePicker,
  handleOpenPicker,
  selectedImage,
  setSelectedImage,
}) => {
  const classes = useStyles()
  const myRefPackage = useRef()

  const [packageImages, setPackageImages] = React.useState([]);


  React.useEffect(() => {
    fetchPackageImages();
  }, []);

  const fetchPackageImages = async () => {
    const res = await getPackageImages();

    if(res.ok){
      setPackageImages(...packageImages, res.data)
    }
  }

  // this function is used to focus on title when sp forget to put packages title ************************************************************************************

  React.useEffect(() => {
    if (errorCheckForPackage.title == true) {
      myRefPackage.current.scrollIntoView()
      myRefPackage.current.focus()
    }
  }, [errorCheckForPackage])

  // Images grid component
  const Images = () => {
    return(
      <div style={customStyles.imageGrid}>
        {
          packageImages?.map((image, idx) => <img style={customStyles.img} key={idx} src={image} onClick={() => imageSelectionHandler(image)} />)
        }
      </div>
    )
  }

  const imageSelectionHandler = (url) => {
    setSelectedImage({imgUrl: url})
    handleClosePicker();
  }

  return (
    <div>
      {
        <>
          {/* this dialogue is for services section *************************************************************************************************************************************** */}
          <div>
            <Dialog
              className={classes.widthDialog}
              style={{ maxWidth: 450, margin: 'auto' }}
              open={open}
              onClose={(e) => handleClose(e)}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ background: '#F5F5FC', color: '#6B6987' }}
              >
                Create Services
              </DialogTitle>
              <DialogContent>
                <FormControl variant="outlined" className={classes.formControl}>
                  <h5 className={classes.h5}>Choose Category *</h5>
                  <Select
                    disableUnderline
                    className={`${classes.paddingLeft24} ${errorCheckForService.categoryId == true
                      ? classes.errorSelectStyle
                      : classes.selectadj
                      }`}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={dataSelectServices.category.id}
                    onChange={(event) => {
                      onChangeSelectCategory(event)
                    }}
                    input={<Input />}
                  >
                    {categoryArray.length > 0 &&
                      categoryArray.map((prop, index) => {
                        return (
                          <MenuItem key={index} value={`${prop.id}`}>
                            {prop.name}
                          </MenuItem>
                        )
                      })}
                    {/* <MenuItem value={'verified'}>Yes</MenuItem>
                                                    <MenuItem value={'na'}>No</MenuItem> */}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <h5 className={classes.h5}>Choose SubCategory *</h5>
                  <Select
                    disableUnderline
                    className={`${classes.paddingLeft24}
                     ${errorCheckForService.subcategoryId == true
                        ? classes.errorSelectStyle
                        : classes.selectadj
                      }`}
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={dataSelectServices.subCategory.id}
                    onChange={(event) => {
                      onChangeSelectSubCategory(event)
                    }} errorCheckForService
                    input={<Input />}
                  >
                     {dataSelectServices?.category?.id == '' && (
                      <MenuItem value={''}>Select Category First </MenuItem>
                    )}
                    {subCategoryArray.length > 0 &&
                      subCategoryArray.map((prop, index) => {
                        return (
                          <MenuItem key={index} value={`${prop.id}`}>
                            {prop.name}
                          </MenuItem>
                        )
                      })}
                    {/* <MenuItem value={'true'}>Active</MenuItem>
                                                    <MenuItem value={'false'}>Inactive</MenuItem> */}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <h5 className={classes.h5}>Choose Services *</h5>
                  <Select
                    disableUnderline
                    className={`${classes.paddingLeft24} ${errorCheckForService.servicesId == true
                      ? classes.errorSelectStyle
                      : classes.selectadj
                      }`} InputProps={{ classes: { input: classes.input1 } }}
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={dataSelectServices.services.id}
                    onChange={(event) => {
                      onChangeSelectService(event)
                    }}
                    style={{padding:'10px'}}
                    input={<Input />}
                  >
                     {dataSelectServices?.subCategory?.id == '' && (
                      <MenuItem value={''}>Select SubCategory First </MenuItem>
                    )}
                    {servicesArray.length > 0 &&
                      servicesArray.map((prop, index) => {
                        return (
                          <MenuItem key={index} value={`${prop.id}`}>
                            {prop.name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>

                <FormControl className={classes.formControl} >
                  <h5 className={classes.h5}>Price *</h5>
                  <TextField
                    // label="Working At (Optional)"
                    className={
                      errorCheckForService.price == true ? classes.root : classes.roots
                    }
                    variant="outlined"
                    placeholder="Price"
                    fullWidth={true}
                    size="small"
                    name="price"
                    value={dataSelectServices.price}
                    onChange={(e) => {
                      handleServiceData('price', e)
                    }}
                    InputProps={{ classes: { input: classes.input1 } }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <h5 className={classes.h5}>Duration</h5>
                  <Select
                    disableUnderline
                    className={`${classes.paddingLeft24} ${errorCheckForService.duration == true
                      ? classes.errorSelectStyle
                      : classes.selectadj
                      }`}
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    name="duration"
                    value={dataSelectServices.duration}
                    onChange={(event) => {
                      handleServiceDataDuration(event)
                    }}
                    input={<Input />}
                  >
                    <MenuItem value={'15 min'}>15 min </MenuItem>
                    <MenuItem value={'30 min'}>30 min </MenuItem>
                    <MenuItem value={'45 min'}>45 min </MenuItem>
                    <MenuItem value={'60 min'}>60 min </MenuItem>
                    <MenuItem value={'1-2 hrs'}>1-2 hrs </MenuItem>
                    <MenuItem value={'2+ hrs'}>2+ hrs </MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <h5 className={classes.h5}>Description </h5>
                  <TextField
                    id="standard-multiline-static"
                    multiline
                    rows={3}
                    placeholder="Enter Description"
                    inputProps={{ maxlength: 80 }}
                    variant="outlined"
                    fullWidth={true}
                    size="small"
                    name="description"
                    value={dataSelectServices.description}
                    onChange={() => {
                      handleServiceData('description', event)
                    }}
                    InputLabelProps={{ shrink: true }}
                    className={classes.roots}
                  />
                </FormControl>
                <Box>
                  {
                    dataSelectServices.description.length ? (
                      <p style={{ color: 'grey', marginTop: '10px', textAlign: 'right', marginTop: '0px' }}>{dataSelectServices.description.length}/80</p>
                    ) :
                      (<p style={{ color: 'grey', marginTop: '10px', textAlign: 'right', marginTop: '0px' }}>0/80</p>)
                  }
                </Box>
              </DialogContent>
              <DialogActions
                style={{
                  justifyContent: 'flex-start',
                  paddingLeft: 25,
                  paddingBottom: 20,
                }}
              >
                <Button
                  onClick={saveServices}
                  className={classes.buttonStyleNextPrevious}
                  style={{
                    // background: '#6259CA',
                    // color: '#fff',
                    padding: '6px 27px',
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  onClick={() => handleClose()}
                  className={classes.buttonStyleNextPrevious}
                  color="primary"
                  variant="outlined"
                  style={{ padding: '6px 27px', marginLeft: 16, }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          {/* this dialogue is for Packages section *************************************************************************************************************************************** */}
          <div>
            <Dialog
              className={classes.widthDialog}
              style={{ maxWidth: 450, margin: 'auto' }}
              open={openPackage}
              onClose={(e) => handleClosePackage(e)}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ background: '#F5F5FC', color: '#6B6987' }}
              >
                Create Package
              </DialogTitle>
              <DialogContent>
                <FormControl ref={myRefPackage} className={classes.formControl}>
                  <h5 className={classes.h5}>Title *</h5>
                  <TextField
                    // label="Working At (Optional)"
                    className={
                      errorCheckForPackage.title == true ? classes.root : classes.roots
                    }
                    variant="outlined"
                    placeholder="Enter Title For Package"
                    fullWidth={true}
                    size="small"
                    name="title"
                    value={addServiceData.title}
                    onChange={handleTextDataForPackage}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <h5 className={classes.h5}>Select Category*</h5>
                  <Select
                    required
                    disableUnderline={true}
                    className={`${classes.paddingLeft24} ${errorCheckForPackage.categoryId == true
                      ? classes.errorSelectStyle
                      : classes.selectadj
                      }`}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="category"
                    fullWidth={true}
                    value={addServiceData.categoryId}
                    onChange={onChangeSelectCategoryForPackage}
                    input={
                      <Input
                      // classes={{
                      //     underline:errorCheck.spid==true ? classes.underline:'',
                      //   }}
                      />
                    }
                  >
                    {categoryArrayForPackage.length == 0 && (
                      <MenuItem value={''}>No Category added *</MenuItem>
                    )}
                    {categoryArrayForPackage.length > 0 &&
                      categoryArrayForPackage.map((val, index) => (
                        <MenuItem key={val.id} value={val.id}>
                          {val.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <h5 className={classes.h5}>Select SubCategory*</h5>
                  <Select
                    disableUnderline={true}
                    className={`${classes.paddingLeft24} ${errorCheckForPackage.subcategoryId == true
                      ? classes.errorSelectStyle
                      : classes.selectadj
                      }`}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="subcategoryid"
                    fullWidth={true}
                    value={addServiceData.subCategoryId}
                    onChange={onChangeSelectSubCategoryForPackage}
                    input={
                      <Input
                      // classes={{
                      //     underline:errorCheck.spid==true ? classes.underline:'',
                      //   }}
                      />
                    }
                  >
                    {addServiceData.categoryId == '' && (
                      <MenuItem value={''}>Select Category First </MenuItem>
                    )}
                    {addServiceData.categoryId !== '' &&
                      subcategoryArrayForPackage.length == 0 && (
                        <MenuItem value={''}>No SubCategory Found </MenuItem>
                      )}
                    {subcategoryArrayForPackage.length > 0 &&
                      subcategoryArrayForPackage.map((val, index) => (
                        <MenuItem key={val.id} value={val.id}>
                          {val.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <h5 className={classes.h5}>Choose Services *</h5>
                  <Select
                    fullWidth={true}
                    className={`${classes.heightOfMultiSelect} ${errorCheckForPackage.servicesId == true
                      ? classes.errorSelectStyle
                      : classes.selectService
                      }`}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={serviceId}
                    onChange={handleChangeMulti}
                    input={<OutlinedInput/>}
                    MenuProps={MenuProps}
                    renderValue={() => serviceNameDisp.join(', ')}
                    style={{padding:'0px',border:'none'}}
                  >
                    {addServiceData.subCategoryId == '' && (
                      <MenuItem value={''}>Select SubCategory First </MenuItem>
                    )}
                    {addServiceData.subCategoryId !== '' &&
                      servicesArrayForPackage.length == 0 && (
                        <MenuItem value={''}>No Service Found </MenuItem>
                      )}
                    {servicesArrayForPackage.length > 0 &&
                      servicesArrayForPackage.map((name) => (
                        <MenuItem key={name.id} value={name.id}>
                          {name.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  className={` removePaddingMarginTopFromCard ${classes.formControl}`}
                >
                  <Box className={classes.boxStyle}>
                    {packageId.length > 0 &&
                      addedService.map((service) => {
                        if (packageId.includes(service._id)) {
                          return (
                            <Card
                              key={service._id}
                              style={{ backgroundColor: '#EAEDF7' }}
                            >
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div style={{ display: 'flex' }}>
                                  <p
                                    style={{
                                      marginTop: '15px',
                                      marginLeft: '10px',
                                      color: 'black',
                                    }}
                                  >
                                    {service.title}{' '}
                                  </p>
                                  <p
                                    style={{
                                      marginTop: '15px',
                                      marginLeft: '10px',
                                      color: 'red',
                                    }}
                                  >{`$${service.normal_price}`}</p>
                                </div>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    removeService(
                                      service._id,
                                      service.services_id,
                                    )
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </Card>
                          )
                        }
                      })}
                  </Box>
                </FormControl>
                {packageId.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <Box>
                      <hr></hr>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <p
                            htmlFor="service"
                            style={{
                              marginTop: '15px',
                              marginLeft: '10px',
                              color: 'black',
                            }}
                          >
                            Total
                          </p>
                        </div>
                        <p
                          htmlFor="service"
                          style={{
                            marginTop: '15px',
                            marginLeft: '10px',
                            color: 'red',
                          }}
                        >{`$${priceTotal}`}</p>
                      </Box>
                    </Box>
                  </FormControl>
                )}

                <FormControl className={classes.formControl}>
                  <h5 className={classes.h5}>Price *</h5>
                  <TextField
                    // label="Working At (Optional)"
                    className={
                      errorCheckForPackage.price == true ? classes.root : classes.roots
                    }
                    variant="outlined"
                    placeholder="Price"
                    fullWidth={true}
                    size="small"
                    name="price"
                    type="number"
                    value={addServiceData.price}
                    onChange={handleTextDataForPackage}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <h5 className={classes.h5}>Duration *</h5>
                  <Select
                    disableUnderline={true}
                    className={`${classes.paddingLeft24} ${errorCheckForPackage.duration == true
                      ? classes.errorSelectStyle
                      : classes.selectadj
                      }`}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="duration"
                    fullWidth={true}
                    value={addServiceData.duration}
                    onChange={handleTextDataForPackage}
                    input={
                      <Input
                      // classes={{Services Setup
                      //     underline:errorCheck.spid==true ? classes.underline:'',
                      //   }}
                      />
                    }
                  >
                    <MenuItem value={'15 min'}>15 min </MenuItem>
                    <MenuItem value={'30 min'}>30 min </MenuItem>
                    <MenuItem value={'45 min'}>45 min </MenuItem>
                    <MenuItem value={'60 min'}>60 min </MenuItem>
                    <MenuItem value={'1-2 hrs'}>1-2 hrs </MenuItem>
                    <MenuItem value={'2+ hrs'}>2+ hrs </MenuItem>
                  </Select>
                </FormControl>

                <h5 className={classes.h5}>Upload image</h5>
                {
                  !selectedImage.imgUrl &&
                  <div style={{marginBottom: "16px"}}>
                    <button style={customStyles.btn} onClick={() => handleOpenPicker()}>
                      <PhotoCameraIcon className={classes.cameraIcon}/>
                    </button>
                  </div>
                }

                {selectedImage.imgUrl &&
                  <div style={{marginTop: "16px", marginBottom: "16px"}}>
                    <img src={selectedImage.imgUrl} style={{ ...customStyles.img, width: "75px" }} />
                    <RemoveCircleIcon style={customStyles.removeIcon}
                      onClick={() => setSelectedImage({ imgUrl: null })} />
                  </div>
                }

                <FormControl className={classes.formControl}>
                  <h5 className={classes.h5}>Description</h5>
                  <TextField
                    // label="Working At (Optional)"
                    multiline
                    rows={3}
                    variant="outlined"
                    placeholder="Enter Description"
                    inputProps={{ maxlength: 80 }}
                    fullWidth={true}
                    size="small"
                    name="description"
                    value={addServiceData.description}
                    onChange={handleTextDataForPackage}
                    className={classes.roots}
                  />
                </FormControl>
                <Box>
                  {
                    addServiceData.description.length ? (
                      <p style={{ color: 'grey', marginTop: '10px', textAlign: 'right', marginTop: '0px' }}>{addServiceData.description.length}/80</p>
                    ) :
                      (<p style={{ color: 'grey', marginTop: '10px', textAlign: 'right', marginTop: '0px' }}>0/80</p>)
                  }
                </Box>
              </DialogContent>
              <DialogActions
                style={{
                  justifyContent: 'flex-start',
                  paddingLeft: 25,
                  paddingBottom: 30,
                }}
              >
                <Button
                  onClick={savePackage}
                  className={classes.buttonStyleNextPrevious}
                  variant="outlined"
                  style={{
                    // background: '#6259CA',
                    // color: '#fff',
                    padding: '6px 27px',
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  onClick={() => handleClosePackage()}
                  color="primary"
                  variant="outlined"
                  className={classes.buttonStyleNextPrevious}
                  style={{ padding: '6px 27px', marginLeft: 16}}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {/* this dialogue is for image picker */}
          <div>
            <Dialog
              className={classes.widthDialog}
              style={{ maxWidth: 450, margin: 'auto' }}
              open={openPicker}
              onClose={(e) => handleClosePicker(e)}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ background: '#F5F5FC', color: '#6B6987' }}
              >
                Choose Image
              </DialogTitle>
              <DialogContent>
                <Images />
                <Button
                  onClick={() => handleClosePicker()}
                  color="primary"
                  variant="outlined"
                  className={classes.buttonStyleNextPrevious}
                  style={{ padding: '6px 27px', marginBlock: "24px", marginLeft: 0}}
                >
                  Cancel
                </Button>
              </DialogContent>
            </Dialog>
          </div>


          <Box className={classes.marginBottom24}>
            {renderText({ label: 'Services Setup' })}
          </Box>

          {addedService.map((prop, index) => {
            return (
              <Card
                key={index}
                style={{ backgroundColor: '#EAEDF7', positon: 'relative' }}
              >
                <div
                  onClick={() => {
                    SweetAlertConfirmDelete(prop._id, 'service')
                  }}
                  style={{
                    height: '20px',
                    width: '20px',
                    background: 'red',
                    borderRadius: '40px',
                    position: 'absolute',
                    top: '-7px',
                    right: '-10px',
                    cursor: 'pointer',
                  }}
                >
                  <RemoveIcon style={{ width: '20px', color: 'white', marginBottom: '4px' }} />
                </div>
                <Box
                  className={classes.displayFlexJustifyBetween}
                  style={{ alignItems: 'center' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel
                      htmlFor="addService"
                      style={{
                        marginTop: '15px',
                        marginLeft: '10px',
                        fontWeight: '800',
                      }}
                    >
                      {prop.title}{' '}
                    </InputLabel>
                    <InputLabel
                      htmlFor="addService"
                      style={{ marginTop: '5px', marginLeft: '10px' }}
                    >
                      {`${prop.duration}`}{' '}
                    </InputLabel>
                    <InputLabel
                      htmlFor="addService"
                      style={{
                        marginTop: '5px',
                        marginLeft: '10px',
                        color: '#eb3f3f',
                      }}
                    >
                      {`$${prop.normal_price}`}{' '}
                    </InputLabel>
                  </div>
                  <div
                    style={{
                      backgroundImage: `url('${prop.services.subcategory.image_url}')`,
                    }}
                    className={classes.imgStyle}
                  ></div>
                  {/* <IconButton aria-label="delete" onClick={() => { SweetAlertConfirmDelete(prop._id, 'service') }}>
                                            <DeleteIcon />
                                        </IconButton> */}
                </Box>
              </Card>
            )
          })}

          <Box className={classes.boxStyle}>
            <InputLabel htmlFor="name-multiple">Add Services</InputLabel>
            <TextField
              // label="Working At (Optional)"
              variant="outlined"
              placeholder="+ Add a Services"
              fullWidth={true}
              size="small"
              name="addService"
              onClick={handleClickOpen}
              className={classes.roots}
            />
          </Box>

          {addedPackage.map((prop, index) => {
            return (
              <Card
                key={index}
                style={{ backgroundColor: '#EAEDF7', position: 'relative' }}
              >
                <div
                  onClick={() => {
                    SweetAlertConfirmDelete(prop._id, 'package')
                  }}
                  style={{
                    height: '20px',
                    width: '20px',
                    background: 'red',
                    borderRadius: '40px',
                    position: 'absolute',
                    top: '-7px',
                    right: '-10px',
                    cursor: 'pointer',
                  }}
                >
                  <RemoveIcon style={{ width: '20px', color: 'white', marginBottom: '4px' }} />
                </div>
                <Box
                  className={classes.displayFlexJustifyBetween}
                  style={{ alignItems: 'center' }}
                >
                  <div style={{ width: '400px' }}>
                    <div style={{ display: 'flex' }}>
                      <InputLabel
                        htmlFor="addService"
                        style={{
                          marginTop: '15px',
                          marginLeft: '10px',
                          fontWeight: '800',
                        }}
                      >
                        {prop.title}{' '}
                      </InputLabel>
                    </div>
                    <InputLabel
                      htmlFor="addService"
                      style={{ marginTop: '15px', marginLeft: '10px' }}
                    >
                      {`${prop.duration}`}{' '}
                    </InputLabel>

                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {prop.service_included.map((service, index) => {
                        return (
                          <ul key={index}>
                            <li>
                              <InputLabel
                                htmlFor="addService"
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '10px',
                                }}
                              >{`${service}`}</InputLabel>
                            </li>
                          </ul>
                        )
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundImage: `url('${prop.image_url}')`,
                    }}
                    className={classes.imgStyle}
                  ></div>
                  {/* <IconButton aria-label="delete" onClick={() => { SweetAlertConfirmDelete(prop._id, 'package') }}>
                                            <DeleteIcon />
                                        </IconButton> */}
                </Box>
                <InputLabel
                  htmlFor="addService"
                  style={{
                    marginTop: '15px',
                    marginLeft: '10px',
                    color: '#eb3f3f',
                  }}
                >
                  {`$${prop.normal_price}`}{' '}
                </InputLabel>
              </Card>
            )
          })}

          <Box className={classes.boxStyle}>
            <InputLabel htmlFor="name-multiple">Create Packages</InputLabel>
            <TextField
              variant="outlined"
              placeholder="+ Create a Packages"
              fullWidth={true}
              size="small"
              name="addPackage"
              onClick={() => {
                if (addedService.length > 1) {
                  handleClickOpenPackage()
                } else {
                  SweetAlertConfirm('Atleast 2 services are required!')
                }
              }}
              className={classes.roots}
            />
          </Box>
          <Box
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonStyleNextPrevious}
              onClick={() => {
                if (addedService.length > 0) {
                  handleNext()
                }
                else {
                  SweetAlertConfirm('Add services to continue')
                }
              }}
            >
              Submit
            </Button>
          </Box>
        </>
      }
    </div>
  )
}

export default ServicePackageSetup
