import {
  Avatar,
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Typography,
  Box,
  Paper,
  TextField,
} from '@material-ui/core'
import React,{useEffect} from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { userService } from '_services/user.service'
// import blueTick from "../../../assets/img/blueTick.png";
import { useHistory, useRouteMatch } from 'react-router-dom'
import { renderText } from '../common/DisplayComponent'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ApplyBackDrop from '../common/ApplyBackDrop'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: '#fff',
    boxShadow: 'unset',
    textTransform: 'capitalize',
    color: '#8f8cac',
    '&:hover': {
      background: '#efefef',
      boxShadow: 'unset',
      textDecoration: 'none',
      // color: '#ffffff',
    },
  },
  formControl: {
    minWidth: '100%',
    marginBottom: 15,
  },
  h5: {
    color: '#25233C',
    fontSize: 18,
  },
  selectadj: {
    border: '1px solid #B9B8C4',
    height: 40,
    padding: 15,
    borderRadius: 10,
    backgroundColor: "#FFF",
  },
  formContainer: {
    width: '100%',
    height: '98vh',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    minHeight: '350px',
    padding: '16px',
    // margin:"20px 0"
  },
  boxStyle: {
    marginTop: '30px',
    '@media(max-width:500px)': {
      padding:'0px 10px '
    }
  },
  displayFlex: {
    display: 'flex',
    justifyContent: "center"
  },
  marginBottom24: {
    marginBottom: '24px',
  },
  marginBottom15: {
    marginBottom: '10px'
  },
  minPaymentSection: {
    display: 'flex',
    justifyContent: 'center',
    width: '90%',
    alignItems: 'center',
    marginTop: '15px',
    // '@media (max-width:1390px)': {
    //   width: '90%',
    // },
    // '@media (max-width:1283px)': {
    //   width: '75%',
    // },
    // '@media (max-width:1173px)': {
    //   width: '75%',
    // },
  },
  width44: {
    width: '100%',
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    width: '100%',
    '@media (max-width:635px)': {
      width: '30%',
    },
    '@media (max-width:590px)': {
      width: '35%',
    },
    '@media (max-width:510px)': {
      width: '40%',
    },
    '@media (max-width:460px)': {
      width: '50%',
    },
    '@media (max-width:380px)': {
      width: '60%',
    },
  },
  buttonStyleNextPrevious: {
    marginLeft: 10,
    backgroundColor: '#DB0D0D',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '10px',
    border: '1px solid #DB0D0D',
    '&:hover': {
      background: "#fff",
      border: "1px solid #DB0D0D",
      color: "#DB0D0D"
    },
  },
  checkedCheckBox: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#005cb2',
    },
  },
  marginLeft20px: {
    '@media (max-width:950px)': {
      marginLeft: '20px',
    },
  },
  customTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 10
    },
    '@media (max-width:400px)': {
      '& input::placeholder': {
        fontSize: '14px',
      },
      '& .MuiInputLabel-outlined': {
        fontSize: '14px',
      },
    },
  },
  fontSize14pxForMobile: {
    '@media (max-width:400px)': {
      fontSize: '14px',
    },
  },
  PaymentSection:{
  '@media (max-width:600px)': {
    marginLeft:'20px !important',
    justifyContent:'center'
  },
  '@media (min-width:601px and max-width:900px)': {
    marginLeft:'25px !important',
    justifyContent:'center'
  },
  '@media (max-width:375px)': {
    marginLeft:'13px !important',
    justifyContent:'center'
  },
},
minPayment:
{
  '@media(max-width:500px)': {
    padding:'0px 10px'
  }
},
}))

const Step2 = ({
  paymentCheckData,
  handleCheckBoxPaymentCash,
  handleCheckBoxPaymentZelle,
  handleCheckBoxPaymentVenmo,
  handleCheckBoxPaymentPayPal,
  handleCheckBoxPaymentCreditCard,
  handleOnChangeMinAdvancePaymentSelect,
  handleOnChangeMinBookingPrice,
  handleNextPaymentMethod,
  handlePrev,
  apiCall,
  handleCheckBoxOthers,
  // errorCheckPaymentOptions
}) => {
  const classes = useStyles()

  const match = useRouteMatch("/newSp");

  return (
    <div>
      <Box> 
        {renderText({ label: 'Accepted Payment Methods' })}
      </Box>
      <Box className={classes.minPaymentSection} style={{ textAlign: "center", marginTop: '0px',width:'100%' }}>
        <Box style={{ textAlign: "center", paddingTop: '15px', paddingBottom: '15px' }} >Select atleast one Payment Method. </Box>
      </Box>
      <Grid container style={ match?.isExact && { marginLeft: 50 }} className={classes.PaymentSection}>
        <Grid item xs={4} sm={4} className={classes.displayFlex}>
          <Box className={classes.width44} style={{}}>
            <FormControlLabel
              className={classes.checkedCheckBox}
              control={
                <Checkbox
                  name="Cash"
                  checked={paymentCheckData.Cash}
                  onChange={handleCheckBoxPaymentCash}
                />
              }
              label={
                <span
                  className={classes.fontSize14pxForMobile}
                  style={{ color: 'black' }}
                >
                  Cash
                </span>
              }
            />
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} className={classes.displayFlex}>
          <Box className={classes.width44} style={{}}>
            <FormControlLabel
              className={classes.checkedCheckBox}
              control={
                <Checkbox
                  name="Zelle"
                  checked={paymentCheckData.Zelle}
                  onChange={handleCheckBoxPaymentZelle}
                />
              }
              label={
                <span
                  className={classes.fontSize14pxForMobile}
                  style={{ color: 'black' }}
                >
                  Zelle
                </span>
              }
            />
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} className={classes.displayFlex}>
          <Box className={classes.width44} style={{}}>
            <FormControlLabel
              className={classes.checkedCheckBox}
              control={
                <Checkbox
                  name="Venmo"
                  checked={paymentCheckData.Venmo}
                  onChange={handleCheckBoxPaymentVenmo}
                />
              }
              label={
                <span
                  className={classes.fontSize14pxForMobile}
                  style={{ color: 'black' }}
                >
                  Venmo
                </span>
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container style={ match?.isExact && { marginLeft: 50 }} className={classes.PaymentSection}>
        <Grid item xs={4} sm={4} className={classes.displayFlex}>
          <Box className={classes.width44} style={{}}>
            <FormControlLabel
              className={classes.checkedCheckBox}
              control={
                <Checkbox
                  name="PayPal"
                  checked={paymentCheckData.PayPal}
                  onChange={handleCheckBoxPaymentPayPal}
                />
              }
              label={
                <span
                  className={classes.fontSize14pxForMobile}
                  style={{ color: 'black' }}
                >
                  PayPal
                </span>
              }
            />
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} classNcursorame={classes.displayFlex}>
          <Box className={classes.width44} style={{}}>
            <FormControlLabel
              className={classes.checkedCheckBox}
              control={
                <Checkbox
                  name="Credit Card"
                  checked={paymentCheckData.CreditCard}
                  onChange={handleCheckBoxPaymentCreditCard}
                />
              }
              label={
                <span
                  className={classes.fontSize14pxForMobile}
                  style={{ color: 'black' }}
                >
                  Credit Card
                </span>
              }
            />
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} className={classes.displayFlex}>
          <Box className={classes.width44} style={{}}>
            <FormControlLabel
              className={classes.checkedCheckBox}
              control={
                <Checkbox
                  name="Others"
                  checked={paymentCheckData.Others}
                  onChange={handleCheckBoxOthers}
                />
              }
              label={
                <span
                  className={classes.fontSize14pxForMobile}
                  style={{ color: 'black' }}
                >
                  Others
                </span>
              }
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        className={`${classes.marginBottom24} ${classes.displayFlex}`}
      >
        <Box className={classes.minPaymentSection} style={{ textAlign: "center" }}>
          <Box style={{ textAlign: "center", paddingTop: '15px', paddingBottom: '15px' }} >Advance Payment </Box>
        </Box>
        <Box style={{ width: '435px' }} className={classes.minPayment}>
          <Select
            style={{ width: '100%' }}
            disableUnderline
            className={classes.selectadj}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="minimumAdvance"
            value={paymentCheckData.minimumAdvance}
            onChange={handleOnChangeMinAdvancePaymentSelect}
            input={<Input />}
          >
            <MenuItem value={'0'}>0%</MenuItem>
            <MenuItem value={'5'}>5%</MenuItem>
            <MenuItem value={'10'}>10%</MenuItem>
            <MenuItem value={'15'}>15%</MenuItem>
            <MenuItem value={'20'}>20%</MenuItem>
            <MenuItem value={'25'}>25%</MenuItem>
            <MenuItem value={'30'}>30%</MenuItem>
          </Select>
        </Box>
        <Box className={classes.minPaymentSection} style={{ textAlign: "center" }}>
          <Box style={{ textAlign: "Justify" }} >You have an option of requiring advance payment for your bookings. Advance payments may be used if you offer customized services and you would be putting in prep work before the booking itself, such as custom cakes. Otherwise, we recommend that you keep it at 0%. </Box>
        </Box>
      </Grid>
      

      <Grid
        container
        className={`${classes.marginBottom24} ${classes.displayFlex}`}
      >
      <Box className={`${classes.boxStyle} ${classes.displayFlex}`} style={{ width: '435px' }}>
        <TextField
          style={{ width: '100%' }}
          className={classes.customTextField}
          label="Minimum Booking Fee"
          variant="outlined"
          size="small"
          type="text"
          name="minimumBookingPrice"
          placeholder="Minimum Booking Fee"
          value={paymentCheckData.minBookingPrice}
          onChange={handleOnChangeMinBookingPrice}
        />
      </Box>
      {/* {errorCheckPaymentOptions.minimumBooking == true && (
        <p style={{ color: 'red', fontSize: '12px' }}>
          {' '}
          * This Field is Required
        </p>
      )} */}
      <Box className={classes.minPaymentSection} style={{ textAlign: "center" }}>
        <Box style={{ textAlign: "justify", }} >A minimum booking fee ensures that each booking is worth your time and effort. In case the total amount of services selected by users is less than the minimum booking fee, the total would still be the minimum booking fee. We encourage you to keep this reasonable and fair, as higher booking fees tend to get lower bookings.
        </Box>
      </Box>
      </Grid>

      <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
        <Box className={classes.buttonStyle}>
          <Button
            variant="contained"
            className={classes.buttonStyleNextPrevious}
            onClick={handlePrev}
          >
            Prev
          </Button>
          <Button
            variant="contained"
            className={classes.buttonStyleNextPrevious}
            onClick={handleNextPaymentMethod}
          >
            {window.location.href.split('/')[
              window.location.href.split('/').length - 1
            ] == 'newSp'
              ? 'Next'
              : 'Save'}
          </Button>
          {<ApplyBackDrop apiCall={apiCall} />}
        </Box>
      </Grid>
    </div>
  )
}

export default Step2
