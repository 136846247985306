/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, withRouter, Switch, Redirect } from "react-router-dom";

// core components
import RTL from "layouts/RTL.js";
import App from "layouts/App.js"
import Login from 'views/Login/Login';

import { PrivateRoute } from "_components/PrivateRoute";
import PageNotFound from "views/404-page";
import PasswordReset from "views/PasswordReset";
import ChangePassword from "views/ChangePassword";
import "assets/css/material-dashboard-react.css?v=1.10.0";
import "assets/css/bootstrap.min.css";
import FormComponent from "./views/ServiceProvider/components/FormComponents/FormComponent";
import AddCredits from "./views/ServiceProvider/components/FormComponents/steps/AddCredits";

// setup fake backend
import { configureFakeBackend } from '_helpers/fake-backend.js';
import { Step } from "@material-ui/core";
// configureFakeBackend();

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <PrivateRoute exact path="/" component={App} />
      <PrivateRoute path="/app" component={App} />
      <Route exact path="/login" component={Login} />
      <Route path="/login/:slug" component={ChangePassword} />
      <Route exact path="/newSp" component={FormComponent} />

      <Route exact path="/payment" component={AddCredits} />

      <Route path="/password_reset" component={PasswordReset} />
      <Route component={PageNotFound} />
      {/* <Route exact path="/loginnewSp" component={LoginnewSp} /> */}

     

    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
