import React, { useState, useEffect } from 'react'
import AddServices from './AddServices';
import { servicesandpackagesService } from '_services/servicesandpackages.service';

const EditServices = (props) => {
    const [data, setData] = useState(null);
    useEffect(() => {
        servicesandpackagesService.getServicebyId(props.location.state.service._id)
            .then((res) => {
                setData(res);
            })
    }, [])
    return (
        <div>
            {data && <AddServices {...props} editData={data} />}
        </div>
    )
}

export default EditServices
