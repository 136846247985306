import { Avatar, Button, Grid, Icon, IconButton, Link, Typography, Box, Paper, TextField } from '@material-ui/core';
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import BackGround from '../../../../../assets/img/BackgroundImageForm.png'
import Card from "components/Card/Card.js";
import FormControl from '@material-ui/core/FormControl';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { SPService } from "_services/serviceprovider.service";
import Brandlogo from "assets/img/Brandlogo.png";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom'
import { userService } from '_services/user.service';
import ApplyBackDrop from '../common/ApplyBackDrop';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiButton-outlinedPrimary": {
            color: "#DB0D0D"
        }
    },
    button: {
        margin: theme.spacing(1),
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "#efefef",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
    },
    formControl: {
        minWidth: '100%',
        marginBottom: 15
    },
    marginTop50px: {
        marginTop: "50px"
    },
    h5: {
        color: "#25233C",
        fontSize: 18,
    },
    selectadj: {
        border: "1px solid #B9B8C4",
        height: 50,
        padding: 15,
        borderRadius: 5
    },
    formContainer: {
        width: "100%",
        minHeight: "100%",
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    form: {
        minHeight: "100%",
        padding: "16px",
        // margin:"20px 0"
    },
    boxStyle: {
        marginTop: "30px",
    },
    displayFlexJustifyCenter: {
        display: "flex",
        justifyContent: "center"
    },
    marginBottom24: {
        marginBottom: "24px"
    },
    minPaymentSection: {
        display: "flex",
        justifyContent: "space-around",
        width: "65%",
        alignItems: "center",
        marginTop: "30px"
    },
    width44: {
        width: "44%"
    },
    width114px: {
        width: "114px"
    },
    buttonStyle: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "30px",
        width: "18%"
    },
    displayFlex: {
        display: "flex"
    },
    textCenterFontBold: {
        textAlign: "center",
        fontWeight: "bold"
    },
    colorTimingFont12: {
        color: "#005CB2",
        fontSize: "12px"
    },
    formBackground: {
        minHeight: '100%',
        backgroundImage: `url(${BackGround})`,
        backgroundSize: 'cover'
        // "@media (max-width:1810px)":{
        //     backgroundSize:'contain'
        //   }
    },
    buttonsAlign: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-end'
    },
    paddingVertical: {
        padding: '24px 0'
    },
    addButtonStyle: {
        backgroundColor: '#DB0D0D',
        width: '25%',
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '10px',
        border: '1px solid #DB0D0D',
        '&:hover': {
            background: "#fff",
            border: "1px solid #DB0D0D",
            color: "#DB0D0D"
        },
    },
    margintop25pxMobileView: {
        "@media (max-width:600px)": {
            marginTop: '25px'
        }
    },
    // paymentCardWidth:{
    //     minWidth:'500px'
    // },
    padding30BackGroundBoxShadow: {
        padding: 0,
        backgroundColor: 'unset',
        boxShadow: '0px 0px 1px 1px #ff3f3f',
    },
    roots: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 10
        },
      },

}));


// fetching stripe public key from .env for payment **************************************************************************************

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPEPUBLICKEY}`);


const CheckoutForm = (props) => {

    const [alert, setAlert] = React.useState(null);
    const history = useHistory();

    let stripeUserId = JSON.parse(sessionStorage.getItem('currentUser'))?.user.stripe_customer_id
    let spId = JSON.parse(sessionStorage.getItem('currentUser'))?.user.sp_id

    function SweetAlertConfirm(msg) {
        const getAlert = () => (
            <SweetAlert
                error
                title="Oops!"
                onConfirm={() => {
                    setAlert(null)
                    // window.location.reload();
                }}
            >
                {msg}
            </SweetAlert>
        )
        setAlert(getAlert());
    }
    function SweetAlertConfirmSuccess(msg) {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                    setAlert(null)
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.replace('http://bookaprowp.ebizontech.biz/welcome-aboard/')
                }}
            >
                {msg}
            </SweetAlert>
        )
        setAlert(getAlert());
    }


    const { stripe } = props;
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { stripe, elements, credit } = props;

        // converting to Int if credits comes as string, and then multipling by 100 beacuse payment are in decimals it take 10 as 0.1 ************************************************

        let sendAmount = (+(credit) * 100).toString()

        if (elements == null) {
            return;
        }

        if (credit > 9) {

            setIsSubmitting(true)

            try {
                const { client_secret } = await SPService.getClientSecret({
                    amount: sendAmount,
                    currency: "inr",
                    stripeCustomerId: stripeUserId
                })

                if (client_secret) {
                    const res = await stripe.confirmCardPayment(client_secret, {
                        payment_method: {
                            card: elements.getElement(CardElement),
                        }
                    })

                    //  if error occurs while creating a payment or payment is incompleted attach user to intent 

                    if (res.error) {

                        if (res.error.payment_intent) {
                            let objAttachIntent = {
                                payment_intent_id: res.error.payment_intent.id,
                                stripeCustomerId: stripeUserId
                            }

                            const objAttachCreditsToProfile = {
                                sp_id: spId,
                                payment_intent_id: res.error.payment_intent.id,
                                credits: credit.toString(),
                                stripe_payment_data: res,
                                status: res.error.payment_intent.status
                            }
                            try {
                                const allPromise = await Promise.all([SPService.attachUserToIntent(objAttachIntent), userService.postSPCredit(objAttachCreditsToProfile)])
                                setIsSubmitting(false)
                                SweetAlertConfirm(res.error.message);
                                // SweetAlertConfirmSuccess("Payment Successfull")
                            }
                            catch (error) {
                                setIsSubmitting(false)
                                SweetAlertConfirm("error");
                            }
                        }
                        else {
                            setIsSubmitting(false)
                            SweetAlertConfirm(res.error.message);
                        }
                    }

                    // if payment succeeded then give credential to service provider and attach user to intent ************************************************************************************************************

                    else if (res.paymentIntent && res.paymentIntent.status == "succeeded") {

                        let objAttachIntent = {
                            payment_intent_id: res.paymentIntent.id,
                            stripeCustomerId: stripeUserId
                        }
                        const objAttachCreditsToProfile = {
                            sp_id: spId,
                            payment_intent_id: res.paymentIntent.id,
                            credits: credit.toString(),
                            stripe_payment_data: res,
                            status: res.paymentIntent.status
                        }
                        try {
                            const allPromise = await Promise.all([SPService.attachUserToIntent(objAttachIntent), userService.postSPCredit(objAttachCreditsToProfile)])
                            setIsSubmitting(false)
                            SweetAlertConfirmSuccess("Payment Successfull")
                        }
                        catch (error) {
                            setIsSubmitting(false)
                            SweetAlertConfirm("error");
                        }
                    }
                    // Rest conditions will be handled here if occurs in live environment ************************************************************************************************************
                    else {
                        console.log(res);
                    }
                }
                else {
                    setIsSubmitting(false)
                    SweetAlertConfirm("Error Occured Try Again");
                }
            }

            catch (err) {
                setIsSubmitting(false)
                SweetAlertConfirm(err);
            }
        }
        else {
            // console.log(elements.getElement(CardElement))
            SweetAlertConfirm("Minimum Of 10 Credits Is Required**");
        }
        // console.log(paymentIntent);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <CardElement />
                <Button className={classes.addButtonStyle} variant="outlined" type="submit" disabled={!stripe}>
                    Pay
                </Button>
                {
                    <ApplyBackDrop apiCall={isSubmitting} />
                }
            </form>
            {
                alert
            }
        </>
    );
}

const InjectedCheckoutForm = (props) => {
    const classes = useStyles();

    return <ElementsConsumer>
        {({ stripe, elements }) => (
            <Grid container style={{ padding: '0 22px' }} >
                <Grid item xs={12} sm={10} style={{ display: 'flex', justifyContent: 'center', minWidth: '100%' }}>
                    <Card style={{ background: "unset", boxShadow: "none"}}>
                        <Box component={Paper} className={classes.padding30BackGroundBoxShadow} >
                            <CheckoutForm stripe={stripe} elements={elements} credit={props.credit} />
                        </Box>
                    </Card>
                </Grid>
            </Grid>

        )}
    </ElementsConsumer>

};


const AddCredits = ({
}) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(2);
    const [creditAmount, setCreditAmount] = React.useState('');
    const history = useHistory();

    React.useEffect(() => {
        if (JSON.parse(sessionStorage.getItem('currentUser'))?.user.role !== '111') {
            history.push('/newSp')
        }
    }, [])

    const handleCreditChange = (event) => {
        if(event.target.value > 0) setCreditAmount(event.target.value)
    }

    return (
        <>
            {JSON.parse(sessionStorage.getItem('currentUser'))?.user.role == '111' &&
                <Elements stripe={stripePromise}>

                    <div className={`${classes.container} ${classes.centerdiv}`} style={{ paddingTop: 25, backgroundColor: "#fff" }}>
                        <div style={{ width: 118, height: 28, left: 20, top: 26, paddingBottom: 50, paddingLeft: 25 }}>
                            <img src={Brandlogo} style={{}} />
                        </div>
                    </div>
                    <div style={{ height: '100vh' }}>
                        <Grid container className={`${classes.formContainer} ${classes.formBackground}`}>
                            <Grid item xs={10} md={12} sm={10} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Card style={{ maxWidth: '650px', backgroundColor: "unset", boxShadow: "unset" }}>
                                    <Box component={Paper} className={classes.paddingVertical} style={{ backgroundColor: "unset" }}>
                                        <Box className={`${classes.marginBottom24}`} style={{ marginTop: "30px" }} >
                                            <h4 style={{ textAlign: "center", color: "black", fontSize: "30px", marginBottom: "20px", fontWeight: "bold" }}>
                                                Add Credits
                                            </h4>
                                        </Box >
                                        <GridContainer >
                                            <GridItem xs={12} sm={6} md={6} >
                                                <FormControl style={{ width: '100%' }}>
                                                    <p>Minimum Of 10 Credits Is Required**</p>
                                                    <TextField
                                                        className={false == true ? `${classes.root}` : `${classes.roots}`}
                                                        variant="outlined"
                                                        placeholder="Enter Credit Amount*"
                                                        type="number"
                                                        fullWidth={true}
                                                        size="small"
                                                        name="phoneOtp"
                                                        value={creditAmount}
                                                        onChange={handleCreditChange}
                                                        onBlur={() => { }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6} style={{ display: 'flex', alignItems: 'end', marginBottom:'2px' }} >
                                                <div className={classes.margintop25pxMobileView} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                                                    <Button className={classes.addButtonStyle} variant="contained" onClick={() => { setCreditAmount((+creditAmount) + 10) }}>
                                                        + 10
                                                    </Button>
                                                    <Button className={classes.addButtonStyle} variant="contained" onClick={() => { setCreditAmount((+creditAmount) + 25) }}>
                                                        + 25
                                                    </Button>
                                                    <Button className={classes.addButtonStyle} variant="contained" onClick={() => { setCreditAmount((+creditAmount) + 50) }}>
                                                        + 50
                                                    </Button>
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        <InjectedCheckoutForm credit={creditAmount} />
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Elements>
            }
        </>
    )

}
export default AddCredits;






        //   const {error, paymentMethod} = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card: elements.getElement(CardElement),
        //   });

        //   console.log(paymentMethod);

        // console.log(stripeUserId,sendAmount)