import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Card from "components/Card/Card.js";
import ReactTable from "_components/ReactTable.js";
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CardBody from '_components/Card/CardBody';

const useStyles = makeStyles((theme) => ({
    displayFlex: {
        display: "flex"
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    fontSize20: {
        fontSize: '20px'
    },
    fontSize14: {
        fontSize: '14px'
    },
  divPadColor:{
    background: "#F3F3FC",
    padding:'16px',
    whiteSpace:'nowrap' 
},
tcell1: {
    border: "unset",
    paddingRight: "unset",
    paddingBottom: "unset",
},
tcell2: {
    border: "unset",
    paddingLeft: "unset",
    paddingRight: "unset",
    paddingBottom: "unset",
},
padding16:{
    padding:'16px'
},
padding0:{
    "& .MuiBox-root":{
      padding:'0'
    },
  },
  marginLeft30Px:{
      marginLeft:'30px'
  },
  tableCard:{
    "@media (max-width: 1340px)":{
        overflow: "auto"
    }
  },
  tableCardBody:{
  padding: 0,
  "@media (max-width: 1340px)":{
        width: "991px",
    }
  }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F5F5FC",
        color: "#6c6987",
    },
  
  }))(TableCell);




const UserBookingTable = (props) => { 
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    return (
              <Card className={`${classes.tableCard} bookingTable`}>
                 <CardBody className={classes.tableCardBody}>
                  <ReactTable
                      className={'user-table-adj'}
                      columns={[
                        {
                          Header: "Name",
                          accessor: "name",
                        },
                        {
                          Header: "Services",
                          accessor: "services",
                        }
                      ]}
                      data={props.data.slice(0,props.count)}
                      onRowClick={(rowData, clickType) => {}}
                    />
                 </CardBody>
             {/* <TableContainer className={classes.tcontainer}>
                        <Table aria-label="simple table">
                            <TableHead className={classes.thead}>
                                <TableRow>                       
                                    <StyledTableCell>
                                       Name
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      Registered On
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      Contact no.
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      Location
                                    </StyledTableCell>
                               </TableRow>
                            </TableHead>
                            <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
                                { props.data?.map((prop,index)=>{
                                    return  index < props.count && <TableRow key={index}>
                                    <TableCell className={classes.tcell1}>
                                      
                                      <div className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                      {prop.userName}
                                      </div>
                                                                  
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                      {prop.registrationDate}
                                      </div>
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                      {prop.contactNo}
                                      </div>
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                      {prop.location}
                                      </div>
                                    </TableCell>
                                </TableRow>
                                })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer> */}
             </Card>
    );
}

export default UserBookingTable