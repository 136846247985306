import React from 'react'
import SPVerificationDetailBody from './SPVerificationDetailBody'
import SPVerificationHeader from './SPVerificationHeader'

const VerificationDetail = (props) => {
    return (
        <div>
            <SPVerificationHeader {...props} />
            <SPVerificationDetailBody {...props} />
        </div>
    )
}

export default VerificationDetail
