import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";
import MenuIcon from '@mui/icons-material/Menu';
import '../../assets/css/material-dashboard-react.css';
// or
// import { Breadcrumbs } from '@mui/material';
//hooks
import { useRouteName } from "hooks";
import { useRouteBreadCrumbs } from "hooks/useRouteBreadCrumbs";

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const routeName = useRouteName();
  const breadCrumbs = useRouteBreadCrumbs();
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });

  // get current user value
  const [currentUser, setCurrentUser] = React.useState('')
  const [toggle, setToggle] = React.useState(false)
  const getUserInfo = () => {
    const user = sessionStorage.getItem('currentUser')
    setCurrentUser(JSON.parse(user)?.user?.name);
  }
  useEffect(() => {
    getUserInfo();
  }, [])


  const formatBreadCrum = () => {
    if (breadCrumbs?.includes("_")) {
      const newBreadCrums = breadCrumbs?.replace(/_/g, " ");
      const spaceArray = newBreadCrums.split(" ");
      spaceArray[1] = spaceArray[1]?.charAt(0).toUpperCase() + spaceArray[1]?.slice(1);
      const breadCrumValue = spaceArray.join(" ");
      return breadCrumValue;
    }
    else {
      return breadCrumbs;
    }
  }

  return (
    <>

      <AppBar className={classes.appBar + appBarClasses} style={{ backgroundColor: '#FFFFFF', paddingTop: '0px', paddingBottom: '0px' }}>
        <Toolbar className={classes.container} style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={classes.flex} style={{ display: 'flex' }}>
            <p style={{ color: '#8E8CAC', fontWeight: 'normal', paddingTop: '10px', marginBottom: '8px', fontSize:'20px',
          paddingLeft:'15px'}} id="welcomeText" >Welcome, <span className="userName" style={{ color: '#8E8CAC', fontWeight: '600' }}>{currentUser}</span></p>
          </div>

          <Hidden smDown implementation="css">
            {props.rtlActive ? <RTLNavbarLinks id="sideHamburger" /> : <AdminNavbarLinks {...props} id="sideHamburger" />}
          </Hidden>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
              id="toggleBar"
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <div className="col-12" style={{ backgroundColor: '#8e8cac36', color: '#8E8CAC', fontSize: '13px', paddingLeft:'30px' }} id="breadcrumName">
          {breadCrumbs === 'Dashboard' ? 'Home' : `Home > ${formatBreadCrum()}`}
        </div>
      </AppBar>

    </>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
