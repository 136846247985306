import React from 'react'
import ServiceProviderDetailBody from './ServiceProviderDetailBody'
import ServiceProviderDetailHeader from './ServiceProviderDetailHeader'
import SpDataState from "context/SpDataState";

const ServiceProviderDetail = (props) => {
    return (
        <div>
            <SpDataState >

            <ServiceProviderDetailHeader {...props} />
            <ServiceProviderDetailBody {...props} />
            
            </SpDataState>
        </div>
    )
}

export default ServiceProviderDetail
