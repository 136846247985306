import { handleResponse } from "_helpers/handle-response";

export const bookingAndRevenueService = {
  getRevenueDayWise,
  getDetailsOfBookingDayWise,
  getUsersBooking,
  getSpBookingListing,
  getBookingGraph,
  getAllBookingsAccordingToType
};


function getRevenueDayWise(obj) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body : JSON.stringify(obj)
    }
    return fetch(`${process.env.REACT_APP_URL}/BookingController/BookingAndRevenue`, requestOptions).then(handleResponse);
}

function getDetailsOfBookingDayWise(obj) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body : JSON.stringify(obj)
    }
    return fetch(`${process.env.REACT_APP_URL}/BookingController/RevenueGenerated`, requestOptions).then(handleResponse);
}

function getUsersBooking(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token,
            'customerid':id
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/BookingController/userBookingListing`, requestOptions).then(handleResponse);
}

function getSpBookingListing(obj) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token,
            'spid':obj.spid,
            'status':obj.status
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/BookingController/spBookingListing`, requestOptions).then(handleResponse);
}

function getBookingGraph(obj) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body : JSON.stringify(obj)
    }
    return fetch(`${process.env.REACT_APP_URL}/BookingController/RevenueGraph`, requestOptions).then(handleResponse);
}

function getAllBookingsAccordingToType(obj){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body : JSON.stringify(obj)
    }
    return fetch(`${process.env.REACT_APP_URL}/BookingController/BookingReports`, requestOptions).then(handleResponse);
}