import { Avatar, Button, Grid, Icon, IconButton, Typography, Box, Paper, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import SweetAlert from 'react-bootstrap-sweetalert';
// import blueTick from "../../../assets/img/blueTick.png";
import { useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { userService } from "_services/user.service";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "#efefef",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
    },
    formControl: {
        minWidth: '100%',
        marginBottom: 15
    },
    marginTop50px: {
        marginTop: "50px"
    },
    h5: {
        color: "#25233C",
        fontSize: 18,
    },
    selectadj: {
        border: "1px solid #B9B8C4",
        height: 50,
        padding: 15,
        borderRadius: 5
    },
    formContainer: {
        width: "100%",
        height: "98vh",
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    form: {
        minHeight: "100%",
        padding: "16px",
        // margin:"20px 0"
    },
    boxStyle: {
        marginTop: "30px",
    },
    boxStyle1: {
        marginTop: "30px",
        display: 'flex',
        justifyContent: 'space-between',
    },
    displayFlexJustifyCenter: {
        display: "flex",
        justifyContent: "center"
    },
    marginBottom24: {
        marginBottom: "24px"
    },
    minPaymentSection: {
        display: "flex",
        justifyContent: "space-around",
        width: "65%",
        alignItems: "center",
        marginTop: "30px"
    },
    width44: {
        width: "44%"
    },
    width114px: {
        width: "114px"
    },
    buttonStyle: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "30px",
        width: "18%"
    },
    displayFlex: {
        display: "flex"
    },
    textCenterFontBold: {
        textAlign: "center",
        fontWeight: "bold"
    },
    colorTimingFont12: {
        color: "#005CB2",
        fontSize: "12px"
    },
    profileImageUpload: {
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        border: "1px solid grey"
    },
    imgUploadprofileDisplay: {
        display: "flex",
        alignItems: "center"
    },
    marginLeft20px: {
        marginLeft: "20px"
    },
    header: {
        display: "flex",
        alignItems: "flex-end",
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    backButton:
    {
        paddingLeft: '0px !important',
        backgroundColor: 'red',
    },
    addressField:
    {
        width: '49%'
    },
    checkBoxColor:
    {
        color: 'black'
    },
    CheckBoxes:
    {
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));


const UserEdit = (props) => {
    const classes = useStyles();
    const { slug } = useParams();
    let { path, url } = useRouteMatch();
    const [accountData, setAcountData] = React.useState({
        id: '',
        uploadImgShow: '',
        fname: '',
        lName: '',
        email: '',
        phone: '',
        image: null,
        address_label: '',
        zipcode: '',
        apt: '',
        street_address: '',
        default_address: ''
    })
    const [alert, setAlert] = React.useState(null);
    const [checkBox, setCheckBox] = React.useState(false);
    const [errorTop, setErrorTop] = React.useState({
        zipcode: ''
    });
    React.useEffect(() => {
        const getCustomerDetail = async () => {
            const response = await userService.getCustomerDetail({ id: slug })
            if (response) {
                try {
                    let fullName = response.user.name;
                    let detailName = [];
                    detailName = fullName.split(' ');
                    localStorage.setItem("userEmail", response.user.email);
                    const defaultAddress = response?.user?.address?.filter((item) => item?.default_address === "true")
                    if (defaultAddress.length > 0) {
                        setCheckBox(true);
                        setAcountData({
                            ...accountData,
                            id: response.user && response.user._id ? response.user._id : '',
                            fname: response.user && detailName[0] ? detailName[0] : '',
                            lName: response.user && detailName[1] ? detailName[1] : '',
                            email: response.user && response.user.email ? response.user.email : '',
                            phone: response.user && response.user.mobile_number ? response.user.mobile_number : '',
                            uploadImgShow: response.user && response.user.image_url ? response.user.image_url : '',
                            address_label: defaultAddress[0]?.address_label,
                            street_address: defaultAddress[0]?.street_address,
                            apt: defaultAddress[0]?.apt,
                            zipcode: defaultAddress[0]?.zipcode,
                        });
                    }
                    else {
                        setCheckBox(false);
                        setAcountData({
                            ...accountData,
                            id: response.user && response.user._id ? response.user._id : '',
                            fname: response.user && detailName[0] ? detailName[0] : '',
                            lName: response.user && detailName[1] ? detailName[1] : '',
                            email: response.user && response.user.email ? response.user.email : '',
                            phone: response.user && response.user.mobile_number ? response.user.mobile_number : '',
                            uploadImgShow: response.user && response.user.image_url ? response.user.image_url : '',
                            address_label: response?.user?.address[0]?.address_label,
                            street_address: response?.user?.address[0]?.street_address,
                            apt: response?.user?.address[0]?.apt,
                            zipcode: response?.user?.address[0]?.zipcode,
                        });
                    }
                }
                catch (err) { }
            }
        }

        getCustomerDetail();


    }, [])

    const handleImageUploadProfile = (event) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2 && event.target.files[0]) {
                setAcountData({ ...accountData, uploadImgShow: reader.result, image: event.target.files[0] })
            }
        }
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }

    }

    useEffect(() => {
        // console.log(checkBox, "checkBox");
        // console.log("DATA ACCOUNT ", accountData)
    }, [checkBox])

    const handleOnChangeAccount = (event) => {
        
        setAcountData({ ...accountData, [event.target.name]: event.target.value });
    }

    const handleCheckBox = () => {
        setCheckBox(!checkBox);
    }

    const saveAccountDetail = () => {
        if (accountData?.zipcode?.length <= 0 || accountData?.zipcode?.length == undefined) {
            setErrorTop({ zipcode: true })
        }
        else {
            setErrorTop({ zipcode: false })
            const obj = {
                id: accountData.id,
                mobile_number: accountData.phone,
                name: accountData.fname + ' ' + accountData.lName,
                address: [{
                    address_label: accountData?.address_label?.length>0?accountData.address_label:'',
                    street_address: accountData?.street_address?.length>0?accountData.street_address:'',
                    apt: accountData?.apt?.length>0?accountData.apt:'',
                    zipcode: accountData.zipcode.length>0?accountData.zipcode:'',
                    default_address: `${checkBox}`
                }]
            }
            if (localStorage.getItem("userEmail") != accountData.email) {
                obj.email = accountData.email
            }
            userService.putUser(obj)
                .then(res => {
                    SweetAlertConfirm();
                });

            if (accountData.image) {
                const obj2 = {
                    id: accountData.id,
                    image: accountData.image
                }
                // for image upload only
                userService.userImageUpdate(obj2).then(res => {
                    // console.log('success Image');
                });
            }

        }
    }
    function SweetAlertConfirm() {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => setAlert(null)}
            >
                Account status updated successfully
            </SweetAlert>
        )
        setAlert(getAlert());
    }

    function getToPrevious() {
        // console.log('hello')
        const { from } = { from: { pathname: "/app/users/" + slug } };
        props.history.goBack();
    }
    return (
        <>{
            <div>
                <GridContainer style={{ width: "100%", marginLeft: '0px' }}>
                    <GridItem xs={12} sm={12} md={8}>
                        <div style={{ marginLeft: "-15px" }}>
                            <div className={classes.header}>
                                <IconButton aria-label="edit" onClick={getToPrevious} style={{ paddingLeft: '0px' }}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <h4 className={""} style={{ marginBottom: '11px' }}>Edit</h4>
                            </div>

                        </div>
                    </GridItem>
                </GridContainer>
                <form style={{ padding: '0px 0px', width: '100%' }}>
                    <Card>
                        <CardBody className="px-0 pb-0">
                            <div style={{ padding: '0px 30px' }}>

                                <Box className={classes.imgUploadprofileDisplay}>
                                    <div className={classes.profileImageUpload} style={{ backgroundImage: `url(${accountData.uploadImgShow})`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                                    </div>
                                    <div>
                                        <input
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            id={'uploadImage'}
                                            name={'uploadImage'}
                                            onChange={handleImageUploadProfile}
                                            type="file"
                                        />
                                        <label htmlFor={'uploadImage'}>
                                            <Button className={classes.marginLeft20px} variant="contained" color="primary" component="span" >
                                                Upload Image
                                            </Button>
                                        </label>
                                    </div>
                                </Box>

                                <Box className={classes.boxStyle}>
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        placeholder="First Name"
                                        fullWidth={true}
                                        size="small"
                                        name="fname"
                                        value={accountData.fname}
                                        onChange={handleOnChangeAccount} />
                                </Box>
                                <Box className={classes.boxStyle}>
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        placeholder="Last Name"
                                        fullWidth={true}
                                        size="small"
                                        name="lName"
                                        value={accountData.lName}
                                        onChange={handleOnChangeAccount} />
                                </Box>
                                <Box className={classes.boxStyle}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        placeholder="Email"
                                        disabled={true}
                                        fullWidth={true}
                                        size="small"
                                        name="email"
                                        value={accountData.email}
                                        onChange={handleOnChangeAccount} />
                                </Box>

                                <Box className={classes.boxStyle}>
                                    <TextField
                                        label="Phone"
                                        variant="outlined"
                                        disabled={true}
                                        placeholder="Phone"
                                        size="small"
                                        fullWidth={true}
                                        name="phone"
                                        value={accountData.phone}
                                        onChange={handleOnChangeAccount} />
                                </Box>
                                <Box className={classes.boxStyle1}>
                                    <TextField
                                        label="Address Label"
                                        variant="outlined"
                                        placeholder="address"
                                        fullWidth={false}
                                        size="small"
                                        name="address_label"
                                        value={accountData.address_label}
                                        onChange={handleOnChangeAccount}
                                        className={classes.addressField}
                                    />

                                    <TextField
                                        label="street"
                                        variant="outlined"
                                        placeholder="Street Label"
                                        fullWidth={false}
                                        size="small"
                                        name="street_address"
                                        value={accountData.street_address}
                                        onChange={handleOnChangeAccount}
                                        className={classes.addressField}
                                    />
                                </Box>

                                <Box className={classes.boxStyle1}>
                                    <TextField
                                        label="apt"
                                        variant="outlined"
                                        placeholder="apt"
                                        fullWidth={false}
                                        size="small"
                                        name="apt"
                                        value={accountData.apt}
                                        onChange={handleOnChangeAccount}
                                        className={classes.addressField}
                                    />
                                    <Box></Box>
                                    <TextField
                                        label="zipCode"
                                        variant="outlined"
                                        placeholder="zipCode"
                                        fullWidth={false}
                                        size="small"
                                        name="zipcode"
                                        value={accountData.zipcode}
                                        onChange={handleOnChangeAccount}
                                        className={classes.addressField}
                                        required
                                    />
                                </Box>
                                <div style={{display: 'flex',justifyContent: 'center',paddingLeft: '174px'}}>
                                    {
                                        errorTop.zipcode == true &&
                                        <p style={{ color: 'red', marginBottom: 0 }}>
                                            ZIP Code is required *
                                        </p>
                                    }
                                </div>
                                <Box className={classes.boxStyle1}>
                                    <FormControlLabel
                                        className={`${classes.checkedCheckBox} ${classes.dFlex}`}
                                        control={
                                            <Checkbox
                                                name="follow"
                                                checked={checkBox}
                                                onChange={handleCheckBox}
                                            />
                                        }
                                        label={
                                            <span className={checkBox === true ? classes.checkBoxColor : classes.CheckBoxes}>
                                                Make as Default Address
                                            </span>
                                        }
                                    />
                                </Box>

                                <Box style={{ marginTop: "20px", marginBottom: "20px", display: "flex", justifyContent: "center" }}>
                                    <Button variant="outlined" color="primary" onClick={saveAccountDetail}>
                                        Save
                                    </Button>
                                </Box>
                            </div>
                        </CardBody>
                    </Card>
                </form>

            </div>
        }
            <>
                {alert}
            </>
        </>
    )
}

export default UserEdit
