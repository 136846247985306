import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ProductCategoryMaster from "./ProductCategory/ProductCategoryMaster";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Icon } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { Paper } from '@material-ui/core';

import AddCategory from "./ProductCategory/AddCategory";
import AddSubCategory from './ProductCategory/AddSubCategory';
import EditCategory from './ProductCategory/EditCategory';
import EditSubCategory from './ProductCategory/EditSubCategory';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  appbarAdj: {
    background: 'transparent',
    boxShadow: 'none'
  },
  button: {
    position: "absolute",
    right: "4pc",
    top: "10pc",
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8d9d",
    paddingTop: '6px'
  },
  buttonSm: {
    position: "unset",
    right: "0",
    top: "0",
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8d9d",
    "@media (max-width: 576px)":{
      position: "unset",
      top: "unset",
      right: "unset",
      marginTop: "16px",
      paddingLeft: "12px !important"
    }
  },
  tabpanel: {
    margin: "0 -25px",
  },
}));


export default function ProductServicesChild(props) {
  const media = useMediaQuery("(max-width:576px)");
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'categories' &&
        <>
          <div className="backArrow" style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton aria-label="edit" onClick={() => { props.history.goBack() }} style={{paddingLeft:'0px',marginLeft:'-4px'}}>
              <ArrowBackIcon />
            </IconButton>
            <h4 className={""} style={{marginBottom:'4px',fontFamily: "Muli-Bold"}}>Categories / Sub-categories</h4>
          </div>

          <div className="container-fluid">

            <AppBar elevation={0} position="static" color="default" className={classes.appbarAdj} >
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" TabIndicatorProps={{ style: { backgroundColor: "#6259ca" } }} textColor="primary">
                <Tab label="Home Salon / Makeup" {...a11yProps(0)} style={{paddingLeft:'0px'}} />
                <Tab label="Event" {...a11yProps(1)} className={classes.Event}  style={{paddingLeft:'0px'}}/>
                new
              </Tabs>
              <Link to="/app/product_services/categories/addcategory">
                <Button
                  style={{fontSize: 15}}
                  variant="contained"
                  color="default"
                  className={media ? classes.buttonSm : classes.button}
                  startIcon={<Icon color="primary">add</Icon>}
                >
                  Add Category
                </Button>
              </Link>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.tabpanel}>
              <ProductCategoryMaster  {...props} eventTab="false" />
            </TabPanel>
            <TabPanel value={value} index={1}  style={{marginLeft:'-24px', marginRight:'-24px'}}>
              <ProductCategoryMaster  {...props} eventTab="true"/>
            </TabPanel>
          </div>
        </>
      }

      <div className="container-fluid">
        <Switch>
          <Route path={`${path}/addcategory`}>
            <AddCategory {...props} tabvalue={value} />
          </Route>
          <Route path={`${path}/addsubcategory`}>
            <AddSubCategory {...props} tabvalue={value} />
          </Route>
          <Route path={`${path}/editcategory/:slug`}>
            <EditCategory {...props} tabvalue={value} />
          </Route>
          <Route path={`${path}/editsubcategory/:slug`}>
            <EditSubCategory {...props} tabvalue={value} />
          </Route>
        </Switch>
      </div>
    </>

  )
}

