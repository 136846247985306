import React, { useState } from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "_components/Grid/GridContainer.js";
import GridItem from "_components/Grid/GridItem.js";
import Button from "_components/CustomButtons/Button.js";
import Card from "_components/Card/Card.js";
import CardBody from "_components/Card/CardBody.js";
import CardIcon from "_components/Card/CardIcon.js";
import CardHeader from "_components/Card/CardHeader.js";
import ReactTable from "_components/ReactTable.js";
import { bookingAndRevenueService } from "_services/bookingAndRevenue.service";
import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};
const useStyles = makeStyles((theme) => ({
  tableCard:{ "@media (max-width: 1340px)":{overflow: "auto" } },
tableCardBody:{ padding: 0,
  "@media (max-width: 1340px)":{ width: "991px", } }
}));

const handleRowClick = (rowData, clickType) => {
  console.log(typeof (rowData));

}

const UserBookings = (props) => {
  // console.log(props.data._id);
  React.useEffect(() => {
    const getUserBooking = async () => {
      setIsLoading(true);
      try {
        const res = await bookingAndRevenueService.getUsersBooking(props.data._id);
        let arr = [];
        if (res) {
          res.map((prop) => {
            var strForTitle = '';
            let services = [];
            prop.services?.map((prop2, index) => {
              // const obj = Object.assign({}, ...prop2 );
              // strForTitle=strForTitle.concat(obj.title,', ')
              services.push(prop2)
            });
            prop.packages?.map((prop3, index) => {
              // const obj=Object.assign({}, ...prop3 );
              // strForTitle=strForTitle.concat(obj.title,', ')
              services.push(prop3)
            })

            // let services=strForTitle.substring(0,strForTitle.length-2)


            let obj = {
              spName: prop.sp_detail.name,
              date: new Date(prop.created_at).toISOString().split('T')[0],
              serviceTaken: services.join(', '),
              status: prop.booking_status == 'created' ? 'On Hold' : prop.booking_status,
              bill: `$ ${prop.amount}`
            }

            arr.push(obj);
          })
        }
        setData([...arr]);
        setIsLoading(false);
      }
      catch (err) {
        setIsLoading(false);
      }
    }

    getUserBooking()


  }, []);

  // dataTable.dataRows.map((prop, key) => {
  //   return {
  //     id: key,
  //     name: prop[0],
  //     position: prop[1],
  //     office: prop[2],
  //     age: prop[3],
  //     actions: (  
  //       <div className="actions-right">
  //         <Button
  //           justIcon
  //           round
  //           simple
  //           onClick={() => {
  //             let obj = data.find((o) => o.id === key);
  //             alert(
  //               "You've clicked LIKE button on \n{ \nName: " +
  //                 obj.name +
  //                 ", \nposition: " +
  //                 obj.position +
  //                 ", \noffice: " +
  //                 obj.office +
  //                 ", \nage: " +
  //                 obj.age +
  //                 "\n}."
  //             );                  

  //           }}
  //           color="info"
  //           className="like"
  //         >
  //           <Favorite />
  //         </Button>{" "}
  //       </div>
  //     ),
  //   };
  // })

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();


  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card className={classes.tableCard}>
          {/* <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Booking</h4>
            </CardHeader> */}
          <CardBody className={classes.tableCardBody}>
            {
              data.length > 0 &&
              <ReactTable
                columns={[
                  {
                    Header: "Service Provider",
                    accessor: "spName",
                    placeholder:"name"
                  },
                  {
                    Header: "Date",
                    accessor: "date",
                    placeholder:'date'
                  },
                  {
                    Header: "Services Taken",
                    accessor: "serviceTaken",
                    placeholder:'service'
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                    Searchable: false
                  },
                  {
                    Header: "Bill Total",
                    accessor: "bill",
                  },
                ]}
                data={data}
                onRowClick={(rowData, clickType) => handleRowClick(rowData, clickType)}
              />
            }
            {
              isLoading == false && data.length <= 0 &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h6>No Bookings</h6>
              </div>
            }
            {isLoading == true &&
              <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
            }
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default UserBookings
