import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import VisibilityIcon from '@material-ui/icons/Visibility';
import GridContainer from "components/Grid/GridContainer.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import EnhancedTable from '_components/EnhancedTable';
import { userService } from "_services/user.service";
import { CSVLink } from 'react-csv';
import GridItem from "_components/Grid/GridItem.js";
import Button from "_components/CustomButtons/Button.js";
import Card from "_components/Card/Card.js";
import CardBody from "_components/Card/CardBody.js";
import CardIcon from "_components/Card/CardIcon.js";
import CardHeader from "_components/Card/CardHeader.js";
import ReactTable from "_components/ReactTable.js";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { CardContent, Divider, Grid, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    userProsContainer:{
        "& > div.MuiGrid-grid-xs-12":{
            padding: "0 0 !important",
        }
    },
    cover: {
        border: "1px solid grey",
        height: "100%",
        maxWidth: 60,
        width: "100%"
    },
    cardTitle: {
        margin: "unset",
        padding: "0 10px",
        fontSize: 20,
        color: "#6f6c8a"
    },
    cardAction: {
        color: "#000",
    },
    button: {
        margin: theme.spacing(1),
        background: "transparent",
        boxShadow: "unset",
        border: "1px solid #8f8cac",
        textTransform: "capitalize",
        color: "#8f8cac"
    },
    table: {
        margin: "10px 0",
    },
    tcontainer: {
        //   margin:"0 -5px",
    },
    thead: {
        padding: 10
    },
    gridCont: {
        paddingTop: "10px",
        margin: 0,
    },
    gridItem1: {
        display: "flex",
        alignItems: "center",
    },
    gridItem2: {
        textAlign: "right",
    },
    tcell1: {
        border: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell2: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell3: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell4: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell5: {
        border: "unset",
        paddingLeft: "unset",
        paddingBottom: "unset",
    },
    div1: {
        display: "flex",
        alignItems: "center",
        background: "#F3F3FC",
        padding: 10,
        height: 50,
    },
    div2: {
        background: "#F3F3FC",
        padding: 15,
        color: "#6d6b75",
        height: 50,
    },
    div3: {
        background: "#F3F3FC",
        padding: 1,
        height: 50,
    },
    blockTitle: {
        paddingLeft: 10,
        color: "#6d6b75",
    },
    mainCard: {
        marginBottom: '2em'
    },
    formControl: {
        minWidth: '100%'
    },
    regTitle: {
        fontSize: 15
    },
    userCount: {
        fontFamily: "Muli-SemiBold",
        fontSize: 35
    },
    tableCard:{ "@media (max-width: 1340px)":{overflow: "auto" } },
    tableCardBody:{ padding: 0,
      "@media (max-width: 1340px)":{ width: "991px", } }
}));




const UserPros = (props) => {

    const [data, setData] = useState();
    const [tableDataValue, setTableDataValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getCustomerDetail = async () => {
            setIsLoading(true);
            const obj = {
                id: props.data._id
            }
            try {
                const response = await userService.getCustomerDetail(obj);
                setData(response);
                setIsLoading(false);
            }
            catch (err) {
                setIsLoading(false);
            }
        }

        getCustomerDetail()

    }, [])

    useEffect(() => {
        if (data) {
            setTableDataValue(data.mypros.map((prop, index) => {
                var services = "";
                prop.service_name.forEach((item, i) => {
                    services = services.concat(item + ", ")
                })
                var servicesFixed = services.substring(0, services.length - 2)
                return ({ id: index, name: prop.user.name, service: servicesFixed })
            }));
        }
    }, [data])

    // useEffect(()=>{
    //     if(tableDataValue){
    //         console.log(tableDataValue);
    //     }

    // },[tableDataValue]);


    const classes = useStyles();
    // const obj=[{id:1,name:"rohit",services:"web service"},{id:2,name:"saurav",services:"app service"}]

    return (
        <>
            {
                <GridContainer classAttr={classes.userProsContainer}>
                    <GridItem xs={12}>
                        <Card className={classes.tableCard}>
                            <CardBody className={classes.tableCardBody}>
                                {isLoading == false && tableDataValue !== null &&
                                    <ReactTable
                                        className={'user-table-adj'}
                                        columns={[
                                            {
                                                Header: "Name",
                                                accessor: "name",
                                            },
                                            {
                                                Header: "Services",
                                                accessor: "service",
                                                Search: "Service"
                                            },
                                        ]}
                                        data={tableDataValue}

                                        onRowClick={(rowData, clickType) => { }}
                                    />
                                }

                                {
                                    isLoading == false && tableDataValue == null &&
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <h6>No Bookings</h6>
                                    </div>
                                }
                                {isLoading == true &&
                                    <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            }
        </>

    )
}

export default UserPros
