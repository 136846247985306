import "../../../assets/css/notification.css";

import { Box, Grid, TextField } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { CKEditor } from "ckeditor4-react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import DateFnsUtils from "@date-io/date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Icon } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ReactTable from "_components/ReactTable.js";
import Select from "@material-ui/core/Select";
import SweetAlert from "react-bootstrap-sweetalert";
import { categoryService } from "_services/category.service";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  btnPrimary: {
    backgroundColor: "#6259CA",
    "& span": {
      fontSize: "16px",
      fontWeight: "400",
    },
  },
  button: {
    position: "absolute",
    right: "3pc",
    top: "7pc",
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8d9d",
  },
  button2: {
    background: "transparent",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8cac",
  },
  selectStyle: {
    border: "1px solid #c4c4c4",
    borderRadius: "5px",
    height: "45px",
  },
  errorSelectStyle: {
    border: "2px solid red",
    borderRadius: "5px",
    height: "45px",
  },
  SaveButton: {
    backgroundColor: "#6362ca",
    color: "#ffffff",
    width: 100,
    fontSize: "10px",
    padding: "10px",
    textTransform: "capitalize",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#6362ca",
      color: "#ffffff",
    },
    "&:focus": {
      backgroundColor: "#6362ca",
      color: "#ffffff",
    },
  },
  DelButton: {
    border: "1px solid grey",
    color: "#8E8CAC",
    textTransform: "capitalize",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#6362ca",
      color: "#ffffff",
    },
    "&:focus": {
      backgroundColor: "#6362ca",
      color: "#ffffff",
    },
  },
  btnAlign: {
    display: "flex",
    marginLeft: "5px",
  },
  h5: {
    fontSize: 18,
  },
  container: {
    // border:"1px solid grey",
    margin: "0 -20px",
  },
  Imginput: {
    display: "none",
  },
  UploadBtn: {
    padding: "10px 15px",
    fontSize: "14px",
    textTransform: "capitalize",
    marginBottom: "7px",
    background: "#8E8CAC",
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
  },
  font20: {
    fontSize: "20px",
    marginBottom: "10px",
  },
  rounded: {
    "& fieldset": {
      borderRadius: 5,
      padding: 14,
    },
  },
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid red",
      borderRadius: 5,
    },
  },
  buttonPosition: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px",
    marginBottom: "15px",
  },
  paddingLeft24: {
    paddingLeft: "24px",
  },
  tableCard: {
    "@media (max-width: 1660px)": {
      overflow: "auto",
    },
  },
  tableCardBody: {
    padding: 0,
    "@media (max-width: 1660px)": {
      width: "1400px",
    },
  },
}));

const AdminPromotions = (props) => {
  const classes = useStyles();

  const myRef = React.useRef();

  let { path, url } = useRouteMatch();
  const [promoFullData, setPromoFullData] = React.useState([]);
  const [promoDisplayed, setPromoDisplayed] = React.useState([]);
  const [updateButton, setUpdateButton] = React.useState(false);
  const [dataSendPromo, setDataSendPromo] = React.useState({
    type: "all",
    category: "",
    subcategory: "",
    promoCode: "",
    discountPortion: 5,
    fromDate: null,
    toDate: null,
    description: "",
  });
  const [promoIdForUpdate, setPromoIdForUpdate] = React.useState("");
  const [errorDataSendPromo, setErrorDataSendPromo] = React.useState({
    category: false,
    subcategory: false,
    promoCode: false,
    discountPortion: false,
    fromDate: false,
    toDate: false,
  });

  const [categoryArray, setCategoryArray] = React.useState([]);
  const [subCategoryArray, setSubCategoryArray] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [alertDelete, setAlertDelete] = React.useState(null);
  const [apiCall, setApiCall] = React.useState(false);
  const [descLength, setDescLength] = React.useState(0);

  const deletePromotion = (id) => {
    categoryService.deleteAdminPromotion(id).then(() => {
      SweetAlertConfirm("Deleted");
    });
  };

  function SweetAlertConfirm(data) {
    const getAlert = () => (
      <SweetAlert
        success
        title="Woot!"
        onConfirm={() => {
          setAlert(null);
          setUpdateButton(false);
          setPromoIdForUpdate("");
          setDataSendPromo({
            type: "all",
            category: "",
            subcategory: "",
            promoCode: "",
            discountPortion: 10,
            fromDate: null,
            toDate: null,
            description: "",
          });
          CKEDITOR?.instances?.editor1?.setData("");
        }}
      >
        Data {data} successfully
      </SweetAlert>
    );
    setAlert(getAlert());
  }

  function SweetAlertConfirmDelete(id) {
    const getAlert = () =>
      id && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Are you sure?"
          onConfirm={() => {
            setAlertDelete(null);
            deletePromotion(id);
          }}
          onCancel={() => setAlertDelete(null)}
          focusCancelBtn
        >
          You want to delete this record?
        </SweetAlert>
      );
    setAlertDelete(getAlert());
  }

  function SweetAlertConfirmError(data) {
    const getAlert = () => (
      <SweetAlert
        error
        title="Error"
        onConfirm={() => {
          setAlert(null);
          setUpdateButton(false);
          setPromoIdForUpdate("");
          setDataSendPromo({
            type: "all",
            category: "",
            subcategory: "",
            promoCode: "",
            discountPortion: 10,
            fromDate: null,
            toDate: null,
            description: "",
          });
          CKEDITOR.instances.editor1.setData("");
        }}
      >
        {data}
      </SweetAlert>
    );
    setAlert(getAlert());
  }

  // when edit button press -> set form value to the value clicked by admin

  const EditPromo = (prop) => {
    myRef.current.scrollIntoView();
    myRef.current.focus();
    setPromoIdForUpdate(prop.promoId);
    setUpdateButton(true);

    let promoLevelDesc = "";

    if (prop.promoLevel == "category") {
      promoLevelDesc = "category";
    } else if (prop.promoLevel == "sub_category") {
      promoLevelDesc = "subcategory";
    } else if (prop.promoLevel == "all") {
      promoLevelDesc = "all";
    }

    CKEDITOR.instances.editor1.setData(prop.description);

    setDataSendPromo({
      type: promoLevelDesc,
      category: prop.category_id ? prop.category_id : "",
      subcategory: prop.sub_category_id ? prop.sub_category_id : "",
      promoCode: prop.promoCode ? prop.promoCode : "",
      discountPortion: prop.discountPortion ? prop.discountPortion : "",
      fromDate: prop.promoValidForm ? prop.promoValidForm : "",
      toDate: prop.promoValidTill ? prop.promoValidTill : "",
      description: prop.description ? prop.description : "",
    });
  };

  React.useEffect(() => {
    const getCategorySubCategoryAndPromo = async () => {
      setApiCall(true);
      try {
        const resForCategory = await categoryService.getCategory();
        const resForSubCategory = await categoryService.getAllSubCategory();
        const res = await categoryService.getAdminPromo();
        if (resForCategory && resForCategory.length > 0) {
          let catArray = [];
          resForCategory.map((prop) => {
            let obj = {
              id: prop._id,
              name: prop.name,
            };
            catArray.push(obj);
          });
          setCategoryArray([...catArray]);
        }

        if (resForSubCategory && resForSubCategory.length > 0) {
          let subCatArray = [];
          resForSubCategory.map((prop) => {
            let obj = {
              id: prop._id,
              name: prop.name,
            };
            subCatArray.push(obj);
          });
          setSubCategoryArray([...subCatArray]);
        }

        if (res && res.length > 0) {
          let array2 = [];
          res.map((prop) => {
            array2.push({
              promoValidForm: moment(prop.start_date).format("DD MMMM YYYY"),
              promoValidTill: moment(prop.end_date).format("DD MMMM YYYY"),
              promoId: prop._id,
              promoCode: prop.promocode,
              discountPortion: prop.dicount_percentage,
              category_id: prop.category_id ? prop.category_id : "",
              sub_category_id: prop.sub_category_id ? prop.sub_category_id : "",
              promoLevel: prop.promotion_level ? prop.promotion_level : "",
              description: prop.description ? prop.description : "",
              promo_name: prop.promo_name ? prop.promo_name : "-",
            });
          });
          setPromoFullData([...array2]);
        }
        setApiCall(false);
      } catch (err) {
        setApiCall(false);
      }
    };

    getCategorySubCategoryAndPromo();
  }, [props.history.location.pathname, alert]);

  React.useEffect(() => {
    if (promoFullData.length > 0) {
      let array = [];
      promoFullData.map((prop) => {
        array.push({
          id: prop.promoId,
          promoCode: prop.promoCode,
          promoLevel: prop.promoLevel,
          from: prop.promoValidForm,
          to: prop.promoValidTill,
          discountPortion: prop.discountPortion,
          description: prop.description ? prop.description : "",
          promo_name: prop.promo_name ? prop.promo_name : "-",
          actions: (
            <div className="actions-right">
              <IconButton
                onClick={() => {
                  SweetAlertConfirmDelete(prop.promoId);
                }}
              >
                <DeleteIcon />
              </IconButton>{" "}
            </div>
          ),
        });
      });
      setPromoDisplayed([...array]);
    }
  }, [promoFullData]);

  const onChangeRadio = (e) => {
    setDataSendPromo({
      ...dataSendPromo,
      category: "",
      subcategory: "",
      type: e.target.value,
    });
  };

  const handleAddPromotionData = (e) => {
    if (e.target.value.length > 0) {
      setErrorDataSendPromo({ ...errorDataSendPromo, [e.target.name]: false });
      setDataSendPromo({ ...dataSendPromo, [e.target.name]: e.target.value });
    } else {
      setErrorDataSendPromo({ ...errorDataSendPromo, [e.target.name]: true });
      setDataSendPromo({ ...dataSendPromo, [e.target.name]: e.target.value });
    }
  };

  const handleAddPromotionDateData = (e, dateType) => {
    if (e) {
      setErrorDataSendPromo({ ...errorDataSendPromo, [dateType]: false });
      setDataSendPromo({ ...dataSendPromo, [dateType]: moment(e)._d });
    } else {
      setErrorDataSendPromo({ ...errorDataSendPromo, [dateType]: true });
      setDataSendPromo({ ...dataSendPromo, [dateType]: moment(e)._d });
    }
  };

  function getToPrevious() {
    const { from } = { from: { pathname: "/app/product_services" } };
    props.history.goBack();
  }

  const handleEditorData = (e, desc) => {
    let eventValue = e.editor.getData();
    const regex = /(<([^>]+)>)/gi;
    const descriptionText = eventValue.replace(regex, "");
    setDescLength(e.editor.getData().length);
    setDataSendPromo((prevState) => {
      return {
        ...prevState,
        [desc]: descriptionText.trim(),
      };
    });
  };

  useEffect(() => {
    const ele = document.querySelector(".cke_path_empty");

    if (ele) {
      ele.style.position = "absolute";
      ele.style.right = "25px";
      ele.innerText = `${descLength}/850`;
    }
  }, [descLength]);

  // function for saving admin promo

  const savePromoAdmin = () => {
    if (dataSendPromo.type == "all") {
      if (dataSendPromo.promoCode.length == 0) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: true,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
      } else if (dataSendPromo.fromDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: true,
          toDate: false,
        });
      } else if (dataSendPromo.toDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: true,
        });
      } else {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });

        let obj = {
          promotion_level: "all",
          promocode: dataSendPromo.promoCode,
          dicount_percentage: dataSendPromo.discountPortion,
          start_date: moment(dataSendPromo.fromDate).format("YYYY-MM-DD"),
          end_date: moment(dataSendPromo.toDate).format("YYYY-MM-DD"),
          description: dataSendPromo.description,
        };
        categoryService
          .postAdminPromo(obj)
          .then((res) => {
            SweetAlertConfirm("Saved");
          })
          .catch((error) => {
            SweetAlertConfirmError(error.message);
          });
      }
    } else if (dataSendPromo.type == "category") {
      if (dataSendPromo.category.length == 0) {
        setErrorDataSendPromo({
          category: true,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
      } else if (dataSendPromo.promoCode.length == 0) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: true,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
      } else if (dataSendPromo.fromDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: true,
          toDate: false,
        });
      } else if (dataSendPromo.toDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: true,
        });
      } else {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
        let obj = {
          category_id: dataSendPromo.category,
          promotion_level: "category",
          promocode: dataSendPromo.promoCode,
          dicount_percentage: dataSendPromo.discountPortion,
          start_date: moment(dataSendPromo.fromDate).format("YYYY-MM-DD"),
          end_date: moment(dataSendPromo.toDate).format("YYYY-MM-DD"),
          description: dataSendPromo.description,
        };
        categoryService
          .postAdminPromo(obj)
          .then((res) => {
            SweetAlertConfirm("Saved");
          })
          .catch((error) => {
            SweetAlertConfirmError(error.message);
          });
      }
    } else if (dataSendPromo.type == "subcategory") {
      if (dataSendPromo.subcategory.length == 0) {
        setErrorDataSendPromo({
          category: false,
          subcategory: true,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
      } else if (dataSendPromo.promoCode.length == 0) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: true,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
      } else if (dataSendPromo.fromDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: true,
          toDate: false,
        });
      } else if (dataSendPromo.toDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: true,
        });
      } else {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });

        let obj = {
          sub_category_id: dataSendPromo.subcategory,
          promotion_level: "sub_category",
          promocode: dataSendPromo.promoCode,
          dicount_percentage: dataSendPromo.discountPortion,
          start_date: moment(dataSendPromo.fromDate).format("YYYY-MM-DD"),
          end_date: moment(dataSendPromo.toDate).format("YYYY-MM-DD"),
          description: dataSendPromo.description,
        };
        categoryService
          .postAdminPromo(obj)
          .then((res) => {
            SweetAlertConfirm("Saved");
          })
          .catch((error) => {
            SweetAlertConfirmError(error.message);
          });
      }
    }
  };

  // function for Update admin promo

  const updatePromoAdmin = () => {
    if (dataSendPromo.type == "all") {
      if (dataSendPromo.promoCode.length == 0) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: true,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
      } else if (dataSendPromo.fromDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: true,
          toDate: false,
        });
      } else if (dataSendPromo.toDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: true,
        });
      } else {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });

        let obj = {
          id: promoIdForUpdate,
          promotion_level: "all",
          promocode: dataSendPromo.promoCode,
          dicount_percentage: dataSendPromo.discountPortion,
          start_date: moment(dataSendPromo.fromDate).format("YYYY-MM-DD"),
          end_date: moment(dataSendPromo.toDate).format("YYYY-MM-DD"),
          description: dataSendPromo.description,
        };
        categoryService
          .putAdminPromo(obj)
          .then((res) => {
            SweetAlertConfirm("Updated");
          })
          .catch((error) => {
            SweetAlertConfirmError(error.message);
          });
      }
    } else if (dataSendPromo.type == "category") {
      if (dataSendPromo.category.length == 0) {
        setErrorDataSendPromo({
          category: true,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
      } else if (dataSendPromo.promoCode.length == 0) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: true,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
      } else if (dataSendPromo.fromDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: true,
          toDate: false,
        });
      } else if (dataSendPromo.toDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: true,
        });
      } else {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
        let obj = {
          id: promoIdForUpdate,
          category_id: dataSendPromo.category,
          promotion_level: "category",
          promocode: dataSendPromo.promoCode,
          dicount_percentage: dataSendPromo.discountPortion,
          start_date: moment(dataSendPromo.fromDate).format("YYYY-MM-DD"),
          end_date: moment(dataSendPromo.toDate).format("YYYY-MM-DD"),
          description: dataSendPromo.description,
        };
        categoryService
          .putAdminPromo(obj)
          .then((res) => {
            SweetAlertConfirm("Updated");
          })
          .catch((error) => {
            SweetAlertConfirmError(error.message);
          });
      }
    } else if (dataSendPromo.type == "subcategory") {
      if (dataSendPromo.subcategory.length == 0) {
        setErrorDataSendPromo({
          category: false,
          subcategory: true,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
      } else if (dataSendPromo.promoCode.length == 0) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: true,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });
      } else if (dataSendPromo.fromDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: true,
          toDate: false,
        });
      } else if (dataSendPromo.toDate == null) {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: true,
        });
      } else {
        setErrorDataSendPromo({
          category: false,
          subcategory: false,
          promoCode: false,
          discountPortion: false,
          fromDate: false,
          toDate: false,
        });

        let obj = {
          id: promoIdForUpdate,
          sub_category_id: dataSendPromo.subcategory,
          promotion_level: "sub_category",
          promocode: dataSendPromo.promoCode,
          dicount_percentage: dataSendPromo.discountPortion,
          start_date: moment(dataSendPromo.fromDate).format("YYYY-MM-DD"),
          end_date: moment(dataSendPromo.toDate).format("YYYY-MM-DD"),
          description: dataSendPromo.description,
        };
        categoryService
          .putAdminPromo(obj)
          .then((res) => {
            SweetAlertConfirm("Updated");
          })
          .catch((error) => {
            SweetAlertConfirmError(error.message);
          });
      }
    }
  };

  return (
    <>
      {window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == "promotions" && (
        <div ref={myRef}>
          <AppBar
            elevation={0}
            position="static"
            color="transparent"
            className={classes.appbarAdj}
          >
            <div className={classes.header}>
              <IconButton
                aria-label="edit"
                onClick={getToPrevious}
                style={{ paddingLeft: "0px", marginLeft: "-4px" }}
              >
                <ArrowBackIcon />
              </IconButton>
              <h4
                className={classes.font20}
                style={{
                  fontSize: 20,
                  fontFamily: "Muli-Bold",
                  transform: "translateY(-5px)",
                }}
              >
                Promotions
              </h4>
            </div>

            {updateButton == true && (
              <Button
                onClick={() => {
                  setUpdateButton(false);
                  setPromoIdForUpdate("");
                  setDataSendPromo({
                    type: "all",
                    category: "",
                    subcategory: "",
                    promoCode: "",
                    discountPortion: 10,
                    fromDate: null,
                    toDate: null,
                    description: "",
                  });
                }}
                variant="contained"
                className={classes.button}
                startIcon={<Icon color="primary">add</Icon>}
              >
                Add New promotion
              </Button>
            )}
          </AppBar>
          <Card>
            <CardBody className="px-0 pb-0"></CardBody>
            <div style={{ padding: "0px 0px" }}>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginBottom: "20px" }}
                >
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      style={{ fontSize: 18, fontFamily: "Muli-SemiBold" }}
                    >
                      Promotion Level
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={dataSendPromo.type}
                      onChange={onChangeRadio}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All"
                        style={{ fontSize: 16 }}
                      />
                      <FormControlLabel
                        value="category"
                        control={<Radio />}
                        label="Category"
                        style={{ fontSize: 16 }}
                      />
                      <FormControlLabel
                        value="subcategory"
                        control={<Radio />}
                        label="Sub Category"
                        style={{ fontSize: 16 }}
                      />
                    </RadioGroup>
                  </FormControl>
                </GridItem>
                {dataSendPromo.type == "category" && (
                  <GridItem xs={12} sm={12} md={12}>
                    <Box
                      className={classes.boxStyle}
                      style={{ width: "30%", marginBottom: "20px" }}
                    >
                      <InputLabel
                        htmlFor="name-multiple"
                        style={{ fontSize: 18, fontFamily: "Muli-SemiBold" }}
                      >
                        Select Category
                      </InputLabel>
                      <Select
                        disableUnderline={true}
                        className={`${
                          errorDataSendPromo.category == true
                            ? classes.errorSelectStyle
                            : classes.selectStyle
                        } ${classes.paddingLeft24}`}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="category"
                        fullWidth={true}
                        value={dataSendPromo.category}
                        onChange={handleAddPromotionData}
                        onBlur={handleAddPromotionData}
                        MenuProps={MenuProps}
                        input={
                          <Input
                          // classes={{
                          //     underline:errorCheck.subcategoryid==true ? classes.underline:'',
                          //   }}
                          />
                        }
                      >
                        {categoryArray.length > 0 &&
                          categoryArray.map((prop, index) => (
                            <MenuItem key={index} value={prop.id}>
                              {prop.name}
                            </MenuItem>
                          ))}
                        {/* <MenuItem value={'1-2'}>1-2years</MenuItem>
                                            <MenuItem value={'2-3'}>2-3years</MenuItem>
                                            <MenuItem value={'3-4'}>3-4years</MenuItem>
                                            <MenuItem value={'4-5'}>4-5years</MenuItem>
                                            <MenuItem value={'5-6'}>5-6years</MenuItem> */}
                      </Select>
                      {errorDataSendPromo.category == true && (
                        <p style={{ color: "red", marginBottom: 0 }}>
                          * This Field is required
                        </p>
                      )}
                    </Box>
                  </GridItem>
                )}
                {dataSendPromo.type == "subcategory" && (
                  <GridItem xs={12} sm={12} md={12}>
                    <Box
                      className={classes.boxStyle}
                      style={{ width: "30%", marginBottom: "20px" }}
                    >
                      <InputLabel
                        htmlFor="name-multiple"
                        style={{ fontSize: 18, fontFamily: "Muli-SemiBold" }}
                      >
                        Select SubCategory
                      </InputLabel>
                      <Select
                        disableUnderline={true}
                        className={`${
                          errorDataSendPromo.subcategory == true
                            ? classes.errorSelectStyle
                            : classes.selectStyle
                        } ${classes.paddingLeft24}`}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="subcategory"
                        fullWidth={true}
                        value={dataSendPromo.subcategory}
                        onChange={handleAddPromotionData}
                        onBlur={handleAddPromotionData}
                        MenuProps={MenuProps}
                        input={
                          <Input
                          // classes={{
                          //     underline:errorCheck.subcategoryid==true ? classes.underline:'',
                          //   }}
                          />
                        }
                      >
                        {subCategoryArray.length > 0 &&
                          subCategoryArray.map((prop, index) => (
                            <MenuItem key={index} value={prop.id}>
                              {prop.name}
                            </MenuItem>
                          ))}
                        .
                        {/* <MenuItem value={'1-2'}>1-2years</MenuItem>
                                            <MenuItem value={'2-3'}>2-3years</MenuItem>
                                            <MenuItem value={'3-4'}>3-4years</MenuItem>
                                            <MenuItem value={'4-5'}>4-5years</MenuItem>
                                            <MenuItem value={'5-6'}>5-6years</MenuItem> */}
                      </Select>
                      {errorDataSendPromo.subcategory == true && (
                        <p style={{ color: "red", marginBottom: 0 }}>
                          * This Field is required
                        </p>
                      )}
                    </Box>
                  </GridItem>
                )}
                <GridItem
                  xs={12}
                  sm={12}
                  md={5}
                  style={{ marginBottom: "20px" }}
                >
                  <Box className={classes.boxStyle}>
                    <InputLabel
                      htmlFor="title"
                      style={{ fontSize: 18, fontFamily: "Muli-SemiBold" }}
                    >
                      Promo Code
                    </InputLabel>
                    <TextField
                      // label="Working At (Optional)"
                      className={
                        errorDataSendPromo.promoCode == true
                          ? classes.root
                          : classes.rounded
                      }
                      variant="outlined"
                      placeholder="Please fill the exact PROMO Code"
                      fullWidth={true}
                      size="small"
                      name="promoCode"
                      value={dataSendPromo.promoCode}
                      onChange={handleAddPromotionData}
                      onBlur={handleAddPromotionData}
                    />
                  </Box>
                  {errorDataSendPromo.promoCode == true && (
                    <p style={{ color: "red", marginBottom: 0 }}>
                      * This Field is required
                    </p>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <Box
                    className={classes.boxStyle}
                    style={{ marginBottom: "20px" }}
                  >
                    <InputLabel
                      htmlFor="name-multiple"
                      style={{ fontSize: 18, fontFamily: "Muli-SemiBold" }}
                    >
                      Discount Portion(%)
                    </InputLabel>
                    <Select
                      disableUnderline={true}
                      className={`${classes.selectStyle} ${classes.paddingLeft24}`}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="discountPortion"
                      fullWidth={true}
                      value={dataSendPromo.discountPortion}
                      onChange={handleAddPromotionData}
                      onBlur={handleAddPromotionData}
                      input={
                        <Input
                        // classes={{
                        //     underline:errorCheck.subcategoryid==true ? classes.underline:'',
                        //   }}
                        />
                      }
                    >
                      <MenuItem value={"5"}>5%</MenuItem>
                      <MenuItem value={"10"}>10%</MenuItem>
                      <MenuItem value={"15"}>15%</MenuItem>
                      <MenuItem value={"20"}>20%</MenuItem>
                      <MenuItem value={"25"}>25%</MenuItem>
                      <MenuItem value={"30"}>30%</MenuItem>
                      <MenuItem value={"40"}>40%</MenuItem>
                      <MenuItem value={"50"}>50%</MenuItem>
                    </Select>
                  </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <div className="form-group">
                    <InputLabel
                      htmlFor="subCategory"
                      style={{ fontSize: 18, fontFamily: "Muli-SemiBold" }}
                    >
                      From
                    </InputLabel>
                    {
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          InputProps={{ readOnly: true }}
                          className={
                            errorDataSendPromo.fromDate == true
                              ? classes.root
                              : classes.rounded
                          }
                          minDate={moment()}
                          fullWidth={true}
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MMM/yyyy"
                          name="fromDate"
                          value={dataSendPromo.fromDate}
                          InputAdornmentProps={{ position: "start" }}
                          onChange={(e) =>
                            handleAddPromotionDateData(e, "fromDate")
                          }
                        />
                      </MuiPickersUtilsProvider>
                    }
                    {errorDataSendPromo.fromDate == true && (
                      <p style={{ color: "red", marginBottom: 0 }}>
                        * This Field is required
                      </p>
                    )}
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <div className="form-group">
                    <InputLabel
                      htmlFor="subCategory"
                      style={{ fontSize: 18, fontFamily: "Muli-SemiBold" }}
                    >
                      To
                    </InputLabel>
                    {
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          InputProps={{ readOnly: true }}
                          className={
                            errorDataSendPromo.toDate == true
                              ? classes.root
                              : classes.rounded
                          }
                          minDate={dataSendPromo.fromDate}
                          fullWidth={true}
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MMM/yyyy"
                          name="toDate"
                          value={dataSendPromo.toDate}
                          InputAdornmentProps={{ position: "start" }}
                          onChange={(e) =>
                            handleAddPromotionDateData(e, "toDate")
                          }
                        />
                      </MuiPickersUtilsProvider>
                    }
                    {errorDataSendPromo.toDate == true && (
                      <p style={{ color: "red", marginBottom: 0 }}>
                        * This Field is required
                      </p>
                    )}
                  </div>
                </GridItem>
                <GridItem xs={12} sm={4} md={12}>
                  <div className="form-group">
                    <InputLabel
                      htmlFor="subCategory"
                      style={{ fontSize: 18, fontFamily: "Muli-SemiBold" }}
                    >
                      Description
                    </InputLabel>

                    <CKEditor
                      data={dataSendPromo.description}
                      type="classic"
                      onChange={(e) => {
                        handleEditorData(e, "description");
                      }}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                </GridItem>
                <div className={classes.buttonPosition}>
                  {updateButton == false && (
                    <Button
                      className={[classes.showBtn, classes.btnPrimary]}
                      variant="contained"
                      color="primary"
                      onClick={savePromoAdmin}
                    >
                      Save
                    </Button>
                  )}
                  {updateButton == true && (
                    <Button
                      className={[classes.showBtn, classes.btnPrimary]}
                      variant="contained"
                      color="primary"
                      onClick={updatePromoAdmin}
                    >
                      update
                    </Button>
                  )}

                  {updateButton == true && (
                    <Button
                      className={[classes.showBtn, classes.btnPrimary]}
                      variant="contained"
                      onClick={() => {
                        SweetAlertConfirmDelete(promoIdForUpdate);
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              </GridContainer>
            </div>
          </Card>
          <AppBar
            elevation={0}
            position="static"
            color="transparent"
            className={"appbarHistory"}
          >
            <div className={classes.header}>
              <h4 className={classes.font20}>Promotion History</h4>
            </div>
          </AppBar>
          <GridContainer
            container
            style={{
              background: "#fff",
              borderRadius: "5px",
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <GridItem xs={12}>
              <Card className={classes.tableCard}>
                <CardHeader color="primary" icon>
                  {/* <CardIcon color="primary">
                    <Assignment />
                  </CardIcon> */}
                  {/* <h4 className={classes.cardIconTitle}>React Table</h4> */}
                </CardHeader>
                <CardBody className={classes.tableCardBody}>
                  {promoFullData.length == 0 && apiCall == true && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 20,
                      }}
                    >
                      <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                  )}

                  {promoFullData.length == 0 && apiCall == false && (
                    <h6 style={{ textAlign: "center", marginBottom: 20 }}>
                      No Promo Available
                    </h6>
                  )}
                  {promoFullData.length > 0 && apiCall == false && (
                    <ReactTable
                      className={"user-table-adj"}
                      columns={[
                        {
                          Header: "Promo Code",
                          accessor: "promoCode",
                          placeholder: "code",
                        },
                        {
                          Header: "From",
                          accessor: "from",
                          placeholder: "date",
                        },
                        {
                          Header: "To",
                          accessor: "to",
                          placeholder: "date",
                        },
                        {
                          Header: "Promotion Level",
                          accessor: "promoLevel",
                          placeholder: "level",
                          isCursor: false,
                        },
                        {
                          Header: "Promotion Level Type",
                          accessor: "promo_name",
                          placeholder: "level type",
                          isCursor: false,
                        },
                        {
                          Header: "Discount Portion(%)",
                          accessor: "discountPortion",
                          placeholder: "portion",
                        },
                        {
                          Header: "Action",
                          accessor: "actions",
                          placeholder: "adminPromotion",
                        },
                      ]}
                      data={promoDisplayed.length > 0 ? promoDisplayed : []}
                      onRowClick={(rowData, clickType) => {}}
                      // onRowClick={(rowData, clickType) => handleRowClick(rowData, clickType)}
                    />
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}

      <>{alert}</>
      <>{alertDelete}</>
    </>
  );
};

export default AdminPromotions;
