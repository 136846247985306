import React from "react";
import { userService } from "_services/user.service";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";

const styles = {
  typo: {
    paddingLeft: "1%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    display: "block",
    fontWeight: "200",
    fontSize: "15px",
    lineHeight: "0px",
    marginLeft: "15%",
    position: "absolute",
    width: "260px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Muli', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function SPProfile(props) {
  const { slug } = useParams();
  const classes = useStyles();
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const fetchUsers = async () => {
      try{
      const response = await userService.getSP(slug);
      setData(response);
      }
      catch(err){}
    };
    
    fetchUsers();
    
   
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>User Profile</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <h5>Name: </h5>
                    <div className={classes.note}>{data && data.user && data.user.name}</div>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <h5>Mobile: </h5>
                    <div className={classes.note}>{data && data.user && data.user.mobile_number}</div>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <h5>Email: </h5>
                    <div className={classes.note}>{data && data.user && data.user.email}</div>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <h5>Address: </h5>
                    <div className={classes.note}>{data && data.user && data.user.address}</div>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary">Update Profile</Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <Button color="primary" round>
                Change Photo
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
