/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Home from "@material-ui/icons/Home";
import Build from "@material-ui/icons/Build";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Person from "@material-ui/icons/Person";
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import HomePage from "views/HomePage/HomePage.js";
import ProductServices from "views/ProductServices/ProductServices";
import SPList from "views/SPList/SPList.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import Revenue from "views/Revenue/Revenue.js";
import Reports from "views/Reports/Reports.js";
import UserList from "views/UserList/UserList.js";
import ReactTable from "views/ReactTable/ReactTable.js";
import User from "views/User/User.js"
import ServiceProvider from "views/ServiceProvider/ServiceProvider";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
//import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";

import { Role } from "_helpers/role";
import FormComponent from "views/ServiceProvider/components/FormComponents/FormComponent";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Home,
    component: DashboardPage,
    layout: "/app",
    roles: [ Role.Admin],
  },
  // {
  //   path: "/newSp",
  //   name: "Complete Profile",
  //   rtlName: "لوحة القيادة",
  //   icon: Home,
  //   component: FormComponent,
  //   layout: "/app",
  //   roles: [Role.ServiceProvider],
  // },
  {
    path: "/product_services",
    name: "Product Services",
    rtlName: "لوحة القيادة",
    icon: Build,
    component: ProductServices,
    layout: "/app",
    roles: [ Role.Admin],
  },
  {
    path: "/service_providers",
    name: "Service Providers",
    rtlName: "قائمة الجدول",
    icon: ManageAccountsIcon,
    component: SPList,
    layout: "/app",
    roles: [ Role.Admin],
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "قائمة الجدول",
    icon: GroupIcon,
    component: UserList,
    layout: "/app",
    roles: [ Role.Admin],
  },
  
  // {
  //   path: "/user/:slug",
  //   name: "User",
  //   rtlName: "قائمة الجدول",
  //   icon: Person,
  //   component: User,
  //   layout: "/app",
  // },
  // {
  //   path: "/homePage",
  //   name: "Home",
  //   rtlName: "لوحة القيادة",
  //   icon: Dashboard,
  //   component: HomePage,
  //   layout: "/app",
  //   roles: [ Role.Admin, Role.Customer],
  // },
  // {
  //   path: "/user_profile",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/app",
  //   roles: [ Role.Admin ],
  // },
  {
    path: "/revenue",
    name: "Booking / Revenue",
    rtlName: "قائمة الجدول",
    icon: AttachMoneyOutlinedIcon,
    component: Revenue,
    layout: "/app",
    roles: [ Role.Admin],
  },
  {
    path: "/reports",
    name: "Reports",
    rtlName: "قائمة الجدول",
    icon: DescriptionIcon,
    component: Reports,
    layout: "/app",
    roles: [ Role.Admin],
  },
  // // {
  // //   path: "/service_provider/:slug",
  // //   name: "Service Provider",
  // //   rtlName: "قائمة الجدول",
  // //   icon: Person,
  // //   component: ServiceProvider,
  // //   layout: "/app",
  // // },
  
  // {
  //   path: "/react_table",
  //   name: "React Table",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: ReactTable,
  //   layout: "/app",
  //   roles: [ Role.Admin, Role.ServiceProvider],
  // },
  // {
  //   path: "/table_list",
  //   name: "Table List",
  //   rtlName: "لوحة القيادة",
  //   icon: LibraryBooks,
  //   component: TableList,
  //   layout: "/app",
  //   roles: [ Role.Admin ],
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/app",
  //   roles: [ Role.Admin ],
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/app",
  //   roles: [ Role.Admin ],
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/app",
  //   roles: [ Role.Admin ],
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/app",
  //   roles: [ Role.Admin],
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl",
  //   roles: [ Role.Admin ],
  // },
  // // {
  // //   path: "/upgrade-to-pro",
  // //   name: "Upgrade To PRO",
  // //   rtlName: "التطور للاحترافية",
  // //   icon: Unarchive,
  // //   component: UpgradeToPro,
  // //   layout: "/app",
  // //   roles: [ Role.Admin ],
  // // },
];

export default dashboardRoutes;
