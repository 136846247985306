import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
// import Person from "@material-ui/icons/Person";
// import Notifications from "@material-ui/icons/Notifications";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

import { useHistory } from 'react-router-dom'
import { authenticationService } from "_services/authentication.service";
import { userService } from "_services/user.service";
// import { history } from '_helpers/history.js';

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks(props) {
  let history = useHistory();
  const classes = useStyles();
  const [notificationData, setNotificationData] = React.useState([]);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);


  React.useEffect(() => {
    const getAdminData = async () => {
      try {
        const responseOfNotification = await userService.getAllAdminNotification({ seen_status: "unseen" });
        // console.log(responseOfNotification);
        if (responseOfNotification.data.length > 0) {
          setNotificationData([...responseOfNotification.data])
        }
        else {
          setNotificationData([]);
        }
      }
      catch (err) { }
    }

    getAdminData();

    return function cleanup() {
      setNotificationData([]);
      setOpenNotification(null);
      setOpenProfile(null);
    }
  }, [props.location.pathname])

  const handleClickNotification = (event) => {
    if (notificationData.length > 0) {
      if (openNotification && openNotification.contains(event.target)) {
        setOpenNotification(null);
      } else {
        setOpenNotification(event.currentTarget);
      }
    }
    else {
      handleRouteForNotification();
    }

  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const handleRouteForNotification = () => {
    setOpenNotification(null);
    userService.updateNotificationStatus().then((res) => {
      const { from } = { from: { pathname: "/app/product_services/notification" } };
      props.history.push(from)
    })
  }

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleOpenProfile = () => {
    const { from } = { from: { pathname: "/app/dashboard/profile" } };
    props.history.push(from)
    setOpenProfile(null);
  };
  const logout = () => {
    authenticationService.logout();
    history.push('/login');
  }
  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
          style={{paddingTop:'0px',paddingBottom:'0px',border:'1px solid rgb(107 105 135 / 13%)',marginRight:'10px',height:'38px',width:'38px'}}
        >
          <NotificationsNoneIcon className={classes.icons} />
          <span className={classes.notifications} style={{top:'3px',right:'6px'}}>{notificationData.length}</span>
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu" style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                    {notificationData.map((prop) => {
                      return <MenuItem
                        key={prop._id}
                        onClick={handleRouteForNotification}
                        className={classes.dropdownItem}
                      >
                        {prop.msg}
                      </MenuItem>
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
          style={{paddingTop:'0px',paddingBottom:'0px',border:'1px solid rgb(107 105 135 / 13%)',height:'38px',width:'38px'}}
        >
          <PersonOutlineIcon className={classes.icons}/>
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
          style={{zIndex:'1'}}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile} >
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleOpenProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={logout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
