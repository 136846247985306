import React from "react";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import VisibilityIcon from '@material-ui/icons/Visibility';
import GridContainer from "components/Grid/GridContainer.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import EnhancedTable from '_components/EnhancedTable';
import { userService } from "_services/user.service";
import { CSVLink } from 'react-csv';
import GridItem from "_components/Grid/GridItem.js";
import Button from "_components/CustomButtons/Button.js";
import Card from "_components/Card/Card.js";
import CardBody from "_components/Card/CardBody.js";
import CardIcon from "_components/Card/CardIcon.js";
import CardHeader from "_components/Card/CardHeader.js";
import ReactTable from "_components/ReactTable.js";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import UserDetail from './UserDetail/UserDetail.js';
import UserEdit from "./UserDetail/UserEdit.js";
import { CardContent, Divider, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cover: {
    border: "1px solid grey",
    height: "100%",
    maxWidth: 60,
    width: "100%"
  },
  cardTitle: {
    margin: "unset",
    padding: "0 10px",
    fontSize: 20,
    color: "#6f6c8a"
  },
  cardAction: {
    color: "#000",
  },
  button: {
    margin: theme.spacing(1),
    background: "transparent",
    boxShadow: "unset",
    border: "1px solid #8f8cac",
    textTransform: "capitalize",
    color: "#8f8cac"
  },
  table: {
    margin: "10px 0",
  },
  tcontainer: {
    //   margin:"0 -5px",
  },
  thead: {
    padding: 10
  },

  formControl: {
    minWidth: '100%'
  },
  regTitle: {
    fontSize: 15,
    color:'#25233C',
    fontFamily:'Muli-SemiBold'
  },
  userCount: {
    fontFamily: "Muli-SemiBold",
    fontSize: 35,
    color:'#25233C'
  },
  tableCard:{ "@media (max-width: 1340px)":{overflow: "auto" } },
   tableCardBody:{ padding: 0,
     "@media (max-width: 1340px)":{ width: "991px", } },
     cardHeading: {
      fontSize: '20px',
      color: "#25233C",
      alignSelf: "center",
      marginBottom: 0,
      paddingLeft:'0px',
      paddingRight:'0px',
      fontFamily:'Muli-Bold'
  },
  userProsContainer:{
    "& > div.MuiGrid-grid-xs-12":{
        padding: "0 0 !important",
    }
},
}));

// const useStyles = makeStyles(styles);

export default function UserList(props) {
  let { path, url } = useRouteMatch();
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [dashboardData, setDashboardData] = React.useState(null);
  function openUserDetail(prop) {

  }
  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
      let response = await userService.getCustomerList();
      let res1 = await userService.getUserDashBoardData();
      // setData(response);
      setDashboardData(res1);
      // setData(response.map((prop, key) => {
      //   return {
      //     id: key,
      //     _id: prop._id,
      //     name: prop.user.name,
      //     address: prop.user.address,
      //     mobile_number: prop.user.mobile_number,
      //     email: prop.user.email,
      //     services_taken: prop.services_taken,
      //     last_activity: prop.last_activity
      //   }
      // }))

      let arr = [];

      const arr2 = response.map((prop, key) => {
        if (prop.user) {
          let address = '';

          // this part is done because we have corropt data too after clening only array part will be used

          if (prop.user.address && typeof prop.user.address === 'string') {
            address = prop.user.address
          }
          else if (prop.user.address && typeof prop.user.address !== 'string') {
            // console.log(prop.user.address);
            prop.user.address.map((addressData) => {
              if (addressData.default_address == "true") {
                address = addressData.street_address
              }
            })
          }

          // used to format date

          let formatDate = '';
          if (prop.user.last_active_at) {
            formatDate = new Date(prop.user.last_active_at.replace(/-/g, "/")).toDateString().toString().split(' ');
          }

          let obj = {
            id: key,
            _id: prop._id,
            name: prop.user.name ? prop.user.name : "not provided",
            address: address.length > 0 ? address : "not provided",
            mobile_number: prop.user.mobile_number ? prop.user.mobile_number : "not provided",
            email: prop.user.email ? prop.user.email : "not provided",
            services_taken: prop.services_taken,
            last_activity: prop.user.last_active_at ? `${formatDate[1]} ${formatDate[2]}, ${formatDate[3]}` : ''
          }
          arr.push(obj);
        }
      })
      setData([...arr]);
    }
    catch (err) { }
    
    };
    
      fetchUsers();
    
    
  }, []);

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile_number',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
    ],
    []
  )

  function getColumns() {
    let cols = [];
    columns.forEach(item => {
      cols.push({ label: item.Header, key: item.accessor })
    })
    return cols;
  }

  let history = useHistory();


  const handleRowClick = (rowData, clickType) => {
    history.push("/app/users/" + rowData.original._id);
  };
  return (
    <>
      {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'users'&& <>
      <div>
      <h4 className={classes.cardHeading}>Users</h4>
        <Grid container spacing={2} id="Registrationuser">
          <Grid item md={3} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="body2" component="p" className={classes.regTitle}>
                  Total registered users
                </Typography>
                <Typography variant="h5" component="h2" className={classes.userCount}>
                  {dashboardData?.totaluser?dashboardData?.totaluser:0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="body2" component="p" className={classes.regTitle}>
                  Active/Inactive users
                </Typography>
                <Typography variant="h5" component="h2" className={classes.userCount}>
                  {dashboardData?.activeuser?dashboardData?.activeuser:0}/ {dashboardData?.inactiveuser?dashboardData?.inactiveuser:0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="body2" component="p" className={classes.regTitle}>
                  Monthly registrations
                </Typography>
                <Typography variant="h5" component="h2" className={classes.userCount}>
                  {dashboardData?.lastmonth_registration?dashboardData?.lastmonth_registration:0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Divider />
        <div>
          {data && <GridContainer classAttr={classes.userProsContainer} style={{padding:'0px'}}>
            <GridItem xs={12}>
              <Card className={classes.tableCard}>
                <CardBody className={classes.tableCardBody}>
                  <ReactTable
                    className={'user-table-adj'}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                        placeholder:'name'
                      },
                      {
                        Header: "Location",
                        accessor: "address",
                        placeholder:'location'
                      },
                      {
                        Header: "Contact No.",
                        accessor: "mobile_number",
                        placeholder:'contact no.'
                      }, {
                        Header: "Email",
                        accessor: "email",
                        placeholder:'email',
                        Footer: "Email"
                      },
                      {
                        Header: "Services Taken",
                        accessor: "services_taken",
                        Footer:"Services Taken"
                      },
                      {
                        Header: "Last Activity",
                        accessor: "last_activity",
                      }
                    ]}
                    data={data}
                    onRowClick={(rowData, clickType) => handleRowClick(rowData, clickType)}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>}
        </div>
        </div>
      </>}


      <div>
        <Switch>
          <Route path={`${path}/edit/:slug`}>
            <UserEdit {...props} />
          </Route>
          <Route path={`${path}/:slug`}>
            <UserDetail {...props} />
          </Route>

        </Switch>
      </div>
    </>
  );

}