import React, { useState, useEffect } from 'react';
import AddPackage from './AddPackage';

const EditPackage = (props) => {
    return (
        <>
            <AddPackage {...props} />
         
        </>
    );
}

export default EditPackage