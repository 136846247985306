import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Grid } from '@material-ui/core';
import { userService } from '_services/user.service';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useRouteMatch
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        // margin: "0 -20px"
    },
    labelText: {
        color: "#25233C",
        fontSize: 18,
        marginBottom: 10,
        fontFamily: "Muli-SemiBold"
    },
    root: {
        "& .MuiInputBase-root": {
            padding: 0,
            "& .MuiButtonBase-root": {
                padding: 0,
                paddingLeft: 10
            },
            "& .MuiInputBase-input": {
                paddingTop: 9.5,
                paddingBottom: 9.5
            }
        }
    }

}));



const ContactInfo = (props) => {
    const classes = useStyles();
    const { slug } = useParams();
    const [data, setData] = useState({
        date: new Date(),
        email: '',
        mobileNumber: ''
    });
    const [placeholderData, setPlaceholderData] = useState({
        date: new Date(),
        email: '',
        mobileNumber: ''
    });
    const [render, setRender] = useState(0);
    const [alert, setAlert] = React.useState(null);
    const [apiCall, setApiCall] = React.useState(false);

    useEffect(() => {
        const getServiceProvider = async () => {
            try {
                setApiCall(true);
                const response = await userService.getSP(slug);
                const responseData = response.user;
                localStorage.setItem("userEmail", responseData.email);
                let dateString = new Date(responseData.created_at?.replace(/-/g, "/")).toISOString().toString().split('T')[0]
                setData({
                    date: dateString,
                    email: responseData.email,
                    mobileNumber: responseData.mobile_number,
                    id: responseData._id
                });

                setPlaceholderData({
                    date: dateString,
                    email: responseData.email,
                    mobileNumber: responseData.mobile_number
                })
                setApiCall(false)
            }
            catch (err) {
                setApiCall(false);
            }
        }
        getServiceProvider();
    }, [render])

    function SweetAlertConfirm() {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => setAlert(null)}
            >
                Account status updated successfully
            </SweetAlert>
        )
        setAlert(getAlert());
    }

    const onChangeMobileNumber = (e) => {
        if (e.target.value) {
            setData({ ...data, mobileNumber: e.target.value });
        }
        else {

            setData({ ...data, mobileNumber: placeholderData.mobileNumber })

        }
    }

    const onChangeEmail = (e) => {
        if (e.target.value) {
            setData({ ...data, email: e.target.value });
        }
        else {

            setData({ ...data, email: placeholderData.email })

        }
    }

    const onChangeDate = () => {

    }
    // const saveChangedStatus=()=>{
    //     const obj={
    //         id:data.id,
    //         mobile_number:data.mobileNumber,
    //         created_at:data.date
    //      };

    //      if(localStorage.getItem("userEmail")!=data.email){
    //          console.log('email called');
    //       obj.email=data.email
    //      }
    //      userService.putUser(obj)
    //      .then(res => {
    //          console.log("success");
    //          SweetAlertConfirm();
    //          setRender(!render);
    //      })  

    // }

    return (
        <>
            { apiCall == false &&
                <div className={classes.container}>
                    <GridContainer style={{ width: "100%", margin: "unset" }}>
                        <Formik>
                            <Form style={{ width: "100%" }}>
                                <Card style={{ marginTop: "unset" }}>
                                    <CardBody className="px-0 pb-0" style={{ height: "378px" }}>
                                        <GridContainer spacing={0}>
                                            <GridItem xs={12} sm={8} md={3}>
                                                <div className="form-group">
                                                    <h5 className={classes.labelText}>Mobile No.</h5>
                                                    <Field name="mobile"
                                                        id="mobile"
                                                        type="number"
                                                        onChange={() => { }}
                                                        placeholder={data.mobileNumber ? data.mobileNumber : "Not Found"}
                                                        value={data.mobileNumber}
                                                        readOnly
                                                        className={'form-control'} />
                                                    <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={3}>
                                                <div className="form-group">
                                                    <h5 className={classes.labelText}>Email</h5>
                                                    <Field name="email"
                                                        id="email"
                                                        placeholder={data.email ? data.email : "Not Found"}
                                                        type="text"
                                                        readOnly
                                                        value={data.email}
                                                        onChange={() => { }}

                                                        className={'form-control'} />
                                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={3}>
                                                <div className="form-group">
                                                    <h5 className={classes.labelText}>Joined On</h5>
                                                    {
                                                        <Field name="date"
                                                            id="date"
                                                            placeholder={data.date ? data.date : "Not found"}
                                                            type="text"
                                                            readOnly
                                                            value={data.date}
                                                            onChange={() => { }}

                                                            className={'form-control'} />
                                                        // <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        // <KeyboardDatePicker className={classes.root}
                                                        //     autoOk
                                                        //     variant="inline"
                                                        //     inputVariant="outlined"                                                       
                                                        //     format="MM/dd/yyyy"
                                                        //     value={data.date}
                                                        //     InputAdornmentProps={{ position: "start" }}
                                                        //     onChange={()=>{}}
                                                        //      />
                                                        //      </MuiPickersUtilsProvider>

                                                    }
                                                    <ErrorMessage name="joined_on" component="div" className="invalid-feedback" />
                                                </div>
                                            </GridItem>
                                            {/* <GridItem xs={12} sm={8} md={8}>
                                                <Button className={classes.SaveButton} onClick={() => saveChangedStatus()} color="primary">Save</Button>
                                                </GridItem> */}
                                        </GridContainer>

                                    </CardBody>
                                </Card>
                            </Form>
                        </Formik>
                    </GridContainer>
                </div>
            }
            
            {
            alert
            }
            
            {
                apiCall == true &&
                <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>

            }
        </>
    )
}

export default ContactInfo
