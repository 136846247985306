import {
  Avatar,
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Typography,
  Box,
  Paper,
  TextField,
} from '@material-ui/core'
import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { userService } from '_services/user.service'
// import blueTick from "../../../assets/img/blueTick.png";
import { renderText } from '../common/DisplayComponent'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ApplyBackDrop from '../common/ApplyBackDrop'

const useStyles = makeStyles((theme) => ({
  dFlex:{
    display: "flex",
    alignItems: "flex-start",
  },
  button: {
    margin: theme.spacing(1),
    background: '#fff',
    boxShadow: 'unset',
    textTransform: 'capitalize',
    color: '#8f8cac',
    '&:hover': {
      background: '#efefef',
      boxShadow: 'unset',
      textDecoration: 'none',
      // color: '#ffffff',
    },
  },
  formControl: {
    minWidth: '100%',
    marginBottom: 15,
  },
  h5: {
    color: '#25233C',
    fontSize: 18,
  },
  selectadj: {
    border: '1px solid #B9B8C4',
    height: 50,
    padding: 15,
    borderRadius: 5,
  },
  formContainer: {
    width: '100%',
    height: '98vh',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    minHeight: '350px',
    padding: '16px',
    // margin:"20px 0"
  },
  boxStyle: {
    marginTop: '30px',
  },
  displayFlexJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  marginBottom24: {
    marginBottom: '24px',
  },
  minPaymentSection: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '65%',
    alignItems: 'center',
    marginTop: '30px',
  },
  width44: {
    width: '44%',
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    width: '100%',
    '@media (max-width:635px)': {
      width: '30%',
    },
    '@media (max-width:590px)': {
      width: '35%',
    },
    '@media (max-width:510px)': {
      width: '40%',
    },
    '@media (max-width:460px)': {
      width: '50%',
    },
    '@media (max-width:380px)': {
      width: '60%',
    },
  },
  buttonStyleNextPrevious: {
    marginLeft: 10,
    backgroundColor: '#DB0D0D',
    color: 'white',
    fontWeight: 'bold',
    borderRadius:'10px',
    border:'1px solid #DB0D0D',
    '&:hover': {
      background: "#fff",
      border: "1px solid #DB0D0D",
      color: "#DB0D0D"
    },
  },
  checkedCheckBox: {
    marginLeft:'0px!important',
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#005cb2',
    },
  },
  checkLabel:{
    "& label > span":{
      padding: "0px 9px"
    }
  }
}))

const Step3 = ({
  covidSafetyData,
  handleCheckBoxFollowRules,
  handleCheckBoxFullyVaccinated,
  handleNext,
  handlePrev,
  apiCall,
}) => {
  const classes = useStyles()

  return (
    <div>
      <Box className={classes.marginBottom24}>
        {renderText({ label: 'COVID Safety' })}
      </Box>

      <Grid container className={classes.checkLabel} style={{ display: 'flex' }}>
        <Box>
          <FormControlLabel
            className={`${classes.checkedCheckBox} ${classes.dFlex}`}
            control={
              <Checkbox
                name="follow"
                checked={covidSafetyData.follow}
                onChange={handleCheckBoxFollowRules}
              />
            }
            label={
              <span style={{ color: 'black' }}>
                I will follow COVID safety measures for all my appointments -
                wearing a mask, sanitizing my hands and equipments.
              </span>
            }
          />
        </Box>
        <Box>
          <FormControlLabel
            className={classes.checkedCheckBox}
            control={
              <Checkbox
                name="vaccinated"
                checked={covidSafetyData.vaccinated}
                onChange={handleCheckBoxFullyVaccinated}
              />
            }
            label={
              <span style={{ color: 'black' }}>I am fully vaccinated.</span>
            }
          />
        </Box>
      </Grid>
      <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
        <Box className={classes.buttonStyle}>
          <Button
            variant="contained"
            className={classes.buttonStyleNextPrevious}
            onClick={handlePrev}
          >
            Prev
          </Button>
          <Button
            variant="contained"
            className={classes.buttonStyleNextPrevious}
            onClick={handleNext}
          >
            {window.location.href.split('/')[
              window.location.href.split('/').length - 1
            ] == 'newSp'
              ? 'Next'
              : 'Save'}
          </Button>
          {<ApplyBackDrop apiCall={apiCall} />}
        </Box>
      </Grid>
    </div>
  )
}

export default Step3
