import React from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";
import Profile from '../components/SPTabs/Profile';
import MyServices from '../components/SPTabs/MyServices';
import ContactInfo from '../components/SPTabs/ContactInfo';
import BookingSchedule from '../components/SPTabs/BookingSchedule';
import BookingHistory from '../components/SPTabs/BookingHistory';
import PaymentHistory from '../components/SPTabs/PaymentHistory';
import Requests from '../components/SPTabs/Requests';
import Promotions from '../components/SPTabs/Promotions';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";

function TabPanel(props) {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    appbarAdj: {
        background: 'transparent',
        boxShadow: 'none',
        position: "absolute",
        top: "-5pc",
    },
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    tabpanel: {
        margin: "0",
        "& > div":{
            padding: 0,
        }
    },
    tabs: {
        margin: "0px 10px 0px 10px",
        background: "#EAEDF7",
        borderRadius: "5px",
        paddingLeft:10,
        "& > div":{
            overflow: "auto !important"
        }
        // "&.MuiTabs-indicator": {
        //   display: "none"
        // }
      },
      tab: {
        "&.MuiButtonBase-root.MuiTab-root": {
            height: "55px",
            padding: "unset",
            minWidth: "125px",
            textTransform: "capitalize",
            fontWeight: "400",
        }
      },
}));
const ServiceProviderDetailBody = (props) => {
    const { slug } =useParams();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

     React.useEffect(()=>{
         
        if(slug=='addService' || slug=='addPackage' || slug=='editService' || slug=='editPackage' || slug=='addpromotion' ){
            const { from } = { from: { pathname: "/app/service_providers/service_provider_detail/" + window.location.href.split('/')[window.location.href.split('/').length - 1]} };
             props.history.push(from);
            // props.history.push('/app/service_providers');  
        }
     },[])

     function returnToDetailSpPage(){
        const { from } = { from: { pathname: "/app/service_providers/service_provider_detail/" + window.location.href.split('/')[window.location.href.split('/').length - 1]} };
        props.history.push(from);
     }

    return (
        <div>
            <div className={'position-relative'} style={{marginTop: 10}}>
                <AppBar elevation={0} style={{marginTop: 10}} color="default" className={classes.appbarAdj} >
                    <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label="simple tabs example" TabIndicatorProps={{ style: { backgroundColor: "#6259ca" } }} textColor="primary">
                        <Tab className={classes.tab} label="Profile" {...a11yProps(0)}  onClick={()=>returnToDetailSpPage()} />
                        <Tab className={classes.tab} label="My Services" {...a11yProps(1)} onClick={()=>{returnToDetailSpPage()}} />
                        <Tab className={classes.tab} label="Booking Schedule" {...a11yProps(2)} onClick={()=>{returnToDetailSpPage()}} />
                        <Tab className={classes.tab} label="Booking History" {...a11yProps(3)} onClick={()=>{returnToDetailSpPage()}} />
                        <Tab className={classes.tab} label="Payment History" {...a11yProps(4)}  onClick={()=>{returnToDetailSpPage()}} />
                        <Tab className={classes.tab} label="Requests" {...a11yProps(5)} onClick={()=>{returnToDetailSpPage()}} />
                        <Tab className={classes.tab} label="Promotions" {...a11yProps(6)} onClick={()=>{returnToDetailSpPage()}} />
                        <Tab className={classes.tab} label="Contact Info" {...a11yProps(7)} onClick={()=>{returnToDetailSpPage()}} />
                        new
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0} className={classes.tabpanel}>
                    <Profile data={props} />
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.tabpanel}>
                    <MyServices {...props} /> 
                </TabPanel>
                <TabPanel value={value} index={2} className={classes.tabpanel}>
                    <BookingSchedule />
                </TabPanel>
                <TabPanel value={value} index={3} className={classes.tabpanel}>
                    <BookingHistory {...props} />
                </TabPanel>
                <TabPanel value={value} index={4} className={classes.tabpanel}>
                    <PaymentHistory {...props} />
                </TabPanel>
                <TabPanel value={value} index={5} className={classes.tabpanel}>
                    <Requests />
                </TabPanel>
                <TabPanel value={value} index={6} className={classes.tabpanel}>
                    <Promotions {...props} />
                </TabPanel>
                <TabPanel value={value} index={7} className={classes.tabpanel}>
                    <ContactInfo data={props} />
                </TabPanel>
            </div>
        </div>
    )
}

export default ServiceProviderDetailBody
