import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { categoryService } from '_services/category.service';
import { Grid, Switch } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
const useStyles = makeStyles((theme) => ({
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    radiobtn: {
        position: "absolute",
        right: 0,
        top: 0,
    }
}));
const AddSubCategory = (props) => {
    const [alert, setAlert] = useState(null);
    const history = useHistory();
    const routePath = props.location ? props.location.pathname.split('/')[props.location.pathname.split('/').length - 1] : "editsubCategory";
    const classes = useStyles();
    const ogName = props.editData ? props.editData.name : "";
    function getCategory(){
        if(props.location && props.location.state && props.location.state.cat){
            return (props.location.state.cat._id);
        }
        else if(props.location && props.location.state && props.location.state.data){
            return (props.location.state.data._id);
        }
        else if(props.editData) {
            return (props.editData._id);
        }
        else{
             return null;
        }
    }
    const categoryList = props.location && props.location.state ? props.location.state.category : [];
    const [selectedCategory, setSelectedCategory] = useState(getCategory())
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const [image, setImage] = React.useState(null);
    const [imageName, setImageName] = React.useState('');
    const [previewImage, setPreviewImage] = React.useState(props?.editData?.image_url ? props.editData.image_url : '');
    const isEdit = props.location && props.location.pathname.split('/')[props.location.pathname.split('/').length - 1] == "addsubcategory" ? false : true;
    const tabEvent = props.tabvalue == 0 ? "false" : "true";
    const [toggleData, setToggleData] = useState(null);
    const isService = props.isService  ? props.isService : false;
    function prePopulateData() {
        return routePath === 'addsubcategory' ? {
            category_id: selectedCategory,
            name: '',
            description: '',
            default_credits: '',
            // slab_first: '',
            // slab_second: '',
            // slab_third: '',
            img_upload: imageName,

        } : {
            name: props ? props.editData.name : "",
            description: props ? props.editData.description : "",
            category_id: props ? props.editData.category_id : "",
            default_credits: '',
            // slab_first: props ? props.editData.slab_first : '',
            // slab_second: props ? props.editData.slab_second : '',
            // slab_third: props ? props.editData.slab_third : '',
            img_upload: props ? props.editData.image_url.split('/')[props.editData.image_url.split('/').length - 1] : "",
        }
    }

    React.useEffect(() => {
        const abortCont = new AbortController();
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        if (routePath !== 'addsubcategory') {
            setState({ ...state, ["checkedB"]: props.editData.active == "true" ? true : false });
            setImageName(props.editData.image_url.split('/')[props.editData.image_url.split('/').length - 1]);
            setSelectedCategory(getCategory());
        }

        //Specify how to clean up after this effect:
        return () => abortCont.abort();
    },[]);

     const setImageProps = (event) => {
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                // setCreateAcountData({ ...createAcountData, uploadImgShow: reader.result, image: event.target.files[0] })
                setImage(event.target.files[0]);
                setPreviewImage(reader.result);
                await setImageName(event.target.files[0].name)
            }
        }
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    function deleteSubCategory() {
        categoryService.deleteSubCategory(props.editData._id).then((res) => {
            const { from } = { from: { pathname: "/app/product_services/categories" } };
            props.history.push(from);
        }).catch(err => {
            SweetAlertConfirmError(err)
            })
    }

    function SweetAlertConfirm(status) {
        const getTrueAlert = () => (
            <SweetAlert
            success
            title="Woot!"
            onConfirm={() => setAlert(null)}
          >
            Sub Catgeory Activated Successfully
          </SweetAlert>          
        )
        const getFalseAlert = () => (
            <SweetAlert
            success
            title="Woot!"
            onConfirm={() => setAlert(null)}
          >
            Sub Catgeory Deactivated Successfully
          </SweetAlert>          
        )
        if(status == "true")
        setAlert(getTrueAlert())
        else{
            setAlert(getFalseAlert())
        }
    }

    function SweetAlertConfirmError(msg) {
        const getErrorAlert = () => (
            <SweetAlert
                error
                title="Woot!"
                onConfirm={() => setAlert(null)}
            >
                {msg}
            </SweetAlert>
        )
        
            setAlert(getErrorAlert()) 
    }

    function getToPrevious() {
        const { from } = { from: { pathname: "/app/product_services/categories" } };
        props.history.push(from);
    }

    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
    });

    function changeStatus(val,name) {
        let status = "";
        switch (val) {
            case true:
                status = "activateSubCategory";
                break;
            default:
                status = "deactivateSubCategory";

        }
        categoryService.categoryStatusChange(status, props.editData._id)
            .then((res) => {
                setToggleData(res);
                SweetAlertConfirm(res.active);
                setState({ ...state, [name]: res.active=="false" ? false : true });
            })
            .catch(err => {
                    SweetAlertConfirmError(err)
            })
    }

    const handleChange = (event) => {
       
        changeStatus(event.target.checked,event.target.name);
    };
    
    const handleSelectChange = (event) => {
        setSelectedCategory(event.target.value);
    }

    return (
        <>
        <div className={classes.container}>
            <GridContainer style={{ width: "100%" }}>
                <GridItem xs={12} sm={12} md={8}>
                    <div>
                      <div className={classes.header}>
                            <IconButton aria-label="edit" onClick={() => getToPrevious()} style={{paddingLeft:'0px',marginLeft:'-4px'}}>
                                <ArrowBackIcon />
                            </IconButton>
                            {routePath === 'addsubcategory' && <h4 className={""} style={{fontSize: 20, fontFamily: "Muli-Bold", transform: 'translateY(-5px)'}}>Add Sub Category</h4>}
                            {routePath === 'services' && <h4 className={""} >Manage Services and Packages</h4>}
                            {routePath !== 'addsubcategory' && routePath !== 'services'&& <h4 className={""} style={{marginBottom:'12px'}}>Edit Sub Category</h4>}
                        </div>

                        <Formik
                            initialValues={prePopulateData()}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required('Title is required'),
                                category_id: Yup.string().required('Category is required'),
                                // description: Yup.string().required('Description is required'),
                                // img_upload: Yup.string().required('Image is required'),
                                // slab_first: Yup.string().required('First Slab is required'),
                                // slab_second: Yup.string().required('Second Slab is required'),
                                // slab_third: Yup.string().required('Third Slab is required'),
                            })}
                            onSubmit={({ name, description, category_id, slab_first, slab_second, slab_third }, { setStatus, setSubmitting }) => {
                                setStatus();
                                if (!isEdit) {

                                    if(image==null){
                                        setSubmitting(false);
                                        SweetAlertConfirmError('Please upload an image to continue');
                                    }
                                    else{
                                        const obj = {
                                            "name": name,
                                            "description": description,
                                            "category_id": selectedCategory,
                                            // "slab_first": slab_first,
                                            // "slab_second": slab_second,
                                            // "slab_third": slab_third,
                                            "image": image,
                                        }
                                        categoryService.postSubCategory(obj).then(
                                            res => {
                                                const { from } = { from: { pathname: "/app/product_services/categories" } };
                                                props.history.push(from);
                                            },
                                            error => {
                                                setSubmitting(false);
                                                setStatus(error.name.length ? error.name[0] : "server error");
                                            }
                                        );
                                    }
                                   
                                }
                                {
                                    const editobj = {
                                        "id": props.editData._id,
                                        "name": ogName !== name ? name : "",
                                        "description": description,
                                        "category_id": selectedCategory,
                                        // "slab_first": slab_first,
                                        // "slab_second": slab_second,
                                        // "slab_third": slab_third,
                                        "image": image,
                                    }
                                    categoryService.putSubCategory(editobj).then(
                                        res => {
                                            const { from } = { from: { pathname: "/app/product_services/categories" } };
                                            props.history.push(from);
                                        },
                                        error => {
                                            setSubmitting(false);
                                            setStatus(error.name ? error.name[0] : "server error");
                                        }
                                    );
                                }

                            }}>
                            {({ errors, status, touched, isSubmitting }) => (
                                <Form>
                                    <Card className={classes[cardAnimaton]}>
                                        {/* <CardHeader
                                        className={`${classes.cardHeader}`}
                                    >
                                        <h4 className={`${classes.cardTitle} ${classes.h4} `}>Add Category</h4>
                                    </CardHeader> */}
                                        <CardBody className="px-0 pb-0">
                                            <GridContainer spacing={0}>
                                                <GridItem xs={12} sm={8} md={8} >
                                                {selectedCategory && 
                                                
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <div className="form-group">
                                                       {routePath === 'services' && <h5 className={classes.h5} style={{fontSize: 18, fontFamily: "Muli-SemiBold"}}>Category</h5>} 
                                                        {routePath !== 'services' && <h5 className={classes.h5} style={{fontSize: 18, fontFamily: "Muli-SemiBold"}}>Choose Category</h5>}
                                                        <select name="category_id" 
                                                        defaultValue={selectedCategory} 
                                                        id="category_id"
                                                        onChange={(e) => handleSelectChange(e)}
                                                        disabled={isService === 'true' ? true : false}
                                                        className={`${classes.emailInput} ${'form-control' + (errors.categoryId 
                                                        && touched.categoryId ? ' is-invalid' : '')}`} >
                                                            <option value="">Select category</option>
                                                            {categoryList && categoryList.map((c, key) => (
                                                                <option key={key} value={c._id}>
                                                                    {c.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                    </div>
                                                </GridItem>}
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <div className="form-group">
                                                        <h5 className={classes.h5} style={{fontSize: 18, fontFamily: "Muli-SemiBold"}}>Name</h5>
                                                        <Field name="name" 
                                                        id="name" 
                                                        type="text" 
                                                        placeholder="Enter name" 
                                                        disabled={isService === 'true' ? true : false}
                                                        className={`${classes.emailInput} ${'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}`} />
                                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                    </div>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <div className="form-group">
                                                        <h5 className={classes.h5} style={{fontSize: 18, fontFamily: "Muli-SemiBold"}}>Description</h5>
                                                        <Field name="description" 
                                                        id="description" 
                                                        placeholder="Enter Description" 
                                                        type="text" 
                                                        disabled={isService === 'true' ? true : false}
                                                        className={`${classes.emailInput} ${'form-control' + (errors.description && touched.description ? ' is-invalid' : '')}`} />
                                                        <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                                    </div>
                                                </GridItem>
                                                </GridItem>
                                                <GridItem xs={12} sm={4} md={4}>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }} >
                                                            <div style={{ width: '120px', height:'120px', backgroundSize: "contain",backgroundRepeat:'no-repeat' }} >
                                                            <img src={previewImage}  style={{ width: '100%', height:'100%'}} />
                                                            </div>
                                                        </div>
                                                    </GridItem>
                                                <Grid container item xs={12} sm={12} md={12} style={{marginLeft:15}}>
                                                    <GridItem xs={6} sm={6} md={3}>
                                                        <div className="form-group">
                                                            <h5 className={classes.h5} style={{fontSize: 18, fontFamily: "Muli-SemiBold"}}>Add Icon</h5>
                                                            <Field name="img_upload" 
                                                            disabled value={imageName} 
                                                            id="img_upload" 
                                                            placeholder="Select Image" 
                                                            type="text" className={`${classes.emailInput} ${'form-control' + (errors.img_upload && touched.img_upload ? ' is-invalid' : '')}`} />
                                                            <ErrorMessage name="img_upload" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={6} sm={6} md={3} alignItems="flex-end" style={{ display: "flex" }}>
                                                        <input
                                                            accept="image/*"
                                                            className={classes.Imginput}
                                                            id="contained-button-file"
                                                            name="contained-button-file"
                                                            onChange={setImageProps}
                                                            type="file"
                                                        />
                                                        <label htmlFor="contained-button-file">
                                                            <Button variant="contained"
                                                             color="primary" 
                                                             component="span"
                                                             style={{fontSize: 16}}
                                                             disabled={isService === 'true' ? true : false} 
                                                             className={classes.UploadBtn}>
                                                                Upload
                                                            </Button>
                                                        </label>
                                                    </GridItem>
                                                </Grid>
                                                {isEdit && <div className={[classes.active, classes.radiobtn]}>
                                                    <Switch
                                                        checked={state.checkedB}
                                                        onChange={(e) => handleChange(e)}
                                                        disabled={isService === 'true' ? true : false}
                                                        color="primary"
                                                        name="checkedB"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </div>}
                                            </GridContainer>
                                        </CardBody>
                                        <CardFooter className={classes.btnAlign}>
                                            {/* <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button> */}
                                            {!isService && <Grid container>
                                                <GridItem xs={6} sm={6} md={6}>
                                                    <Button type="submit" className={classes.SaveButton} simple size="sm" style={{fontSize: 16}}>
                                                        Save
                                                    </Button>
                                                </GridItem>
                                                {isSubmitting &&
                                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                }
                                                {isEdit && <GridItem xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
                                                    <Button type="button" onClick={() => deleteSubCategory()} className={classes.DelButton} simple size="sm" style={{fontSize: 16}}>
                                                        Delete
                                                    </Button>
                                                </GridItem>}
                                            </Grid>}
                                            <br />
                                        </CardFooter>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {status &&
                                                    <div className={'alert alert-danger'}>{status}</div>
                                                }
                                            </div>
                                        </div>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </GridItem>
            </GridContainer>
        </div >
        {alert}        
        </>    
        )                                    
}

export default AddSubCategory
