import React, { useState, useEffect } from 'react';
import GridItem from "components/Grid/GridItem.js";
// import { IconButton, TextField, Grid, Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Card from "components/Card/Card.js";
import {  withStyles,makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";


const useStyles = makeStyles((styles) => ({
    displayFlex:{
        display:'flex',
        flexDirection:'row',
        alignItems: "flex-end",
        '@media (max-width: 576px)':{
            flexDirection: "column",
            alignItems: "flex-start",
        }
    }, 
    button:{
        backgroundColor: "#6259CA",
        color: "#fff",
        // paddingLeft: "20px !important"
    }
  }));
  


const CustomDateSelection = (props) => {

    const classes=useStyles();

    const [customDates,setCustomDates]=React.useState({
        fromDate:null,
        toDate:null
    })   
    
    
    const handleDatePickerChange=(event,prop)=>{
        setCustomDates({...customDates,[prop]:event})
    }

    const handleSubmit=()=>{
        props.callbackFuncForCustom( new Date(customDates.fromDate).toISOString().split('T')[0], new Date(customDates.toDate).toISOString().split('T')[0]);
    }

    return (
        <Card className={classes.displayFlex}>
            <GridItem xs={12} sm={8} md={4} style={{ marginTop: "20px" }}>
                <div className="form-group">
                    <InputLabel htmlFor="fromDate" style={{fontSize: 18, fontFamily: "Muli-SemiBold"}}>From</InputLabel>
                    {
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                InputProps={{readOnly: true}}
                                autoOk
                                maxDate={customDates.toDate!==null ? customDates.toDate:new Date(Date.now())}
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MMM/yyyy"
                                name="fromDate"
                                value={customDates.fromDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={(event) => { handleDatePickerChange(event,'fromDate')}}
                            />
                        </MuiPickersUtilsProvider>

                    }
                </div>
            </GridItem>
            <GridItem xs={12} sm={8} md={4} style={{ marginTop: "20px" }}>
                <div className="form-group">
                    <InputLabel htmlFor="toDate" style={{fontSize: 18, fontFamily: "Muli-SemiBold"}}>To</InputLabel>
                    {

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                InputProps={{readOnly: true}}
                                minDate={customDates.fromDate!==null ? customDates.fromDate : null}
                                maxDate={new Date(Date.now())}
                                autoOk
                                variant="inline"    
                                inputVariant="outlined"
                                format="dd/MMM/yyyy"
                                name="toDate"
                                value={customDates.toDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={(event) => {handleDatePickerChange(event,'toDate') }}
                            />
                        </MuiPickersUtilsProvider>

                    }
                </div>
            </GridItem>
            {
                customDates.fromDate!==null && customDates.toDate!==null &&
                <GridItem xs={12} sm={8} md={4} style={{ marginTop: "20px" }}>
                <div className="form-group">
                {/* <InputLabel htmlFor="subCategory"> &nbsp;</InputLabel> */}
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.button}

                >
                  Show Details
            </Button>
                </div>
            </GridItem>
            }
        </Card>
    );
}

export default CustomDateSelection