import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";

import NotificationsIcon from '@material-ui/icons/Notifications';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SettingsIcon from '@material-ui/icons/Settings';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from '@material-ui/core/Card'
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Registration from "./Registrations/Registration";
import Booking from './Bookings/Booking';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    cardBody: {
        fontSize: 15,
        color: "#25233C",
        alignSelf: "center",
        paddingLeft: 10
    },
    cardHeading: {
        fontSize: 15,
        color: "#25233C",
        alignSelf: "center",
        paddingLeft: 10,
        marginBottom: 0,
        fontFamily:'Muli'
    },
    cardBodyWrapper: { padding: "30px", display: "flex", justifyContent: "center", maxHeight: "150px" },
    cardIconAdj: { height: 40, width: 40, margin: "unset !important" },
    IconAdj: { height: "20px !important", width: "20px !important", margin: "unset !important", color: "#fff" },
    productCard: { marginBottom: ".5rem", marginTop: ".5rem", boxShadow: "0px 3px 6px #0000001A", marginLeft:'-20px',marginRight:'10px' },
}));

export default function ProductServices(props) {
    let { path, url } = useRouteMatch();
    const classes = useStyles();
    return (
        <div className="container-fluid">
            {
             window.location.href.split('/')[window.location.href.split('/').length - 1] == 'reports' &&
            <div classame={classes.row}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={5}>
                    <Link to={`${url}/registrations`} >
                        <Card className={classes.productCard} style={{borderRadius:'8px'}}>
                            <CardBody className={classes.cardBodyWrapper}>
                                <CardIcon color="success" className={classes.cardIconAdj} id='registerIcon'>
                                    <NotificationsNoneIcon className={classes.IconAdj} />
                                </CardIcon>
                                <h4 className={classes.cardHeading}>Registrations</h4>
                            </CardBody>
                        </Card>
                      </Link>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={5}>
                    <Link to={`${url}/bookings`} >
                        <Card className={classes.productCard} style={{borderRadius:'8px'}}>
                            <CardBody className={classes.cardBodyWrapper}>
                                <CardIcon color="primary" className={classes.cardIconAdj} id='bookingIcon'>
                                    <FormatListBulletedIcon className={classes.IconAdj} />
                                </CardIcon>
                                <h4 className={classes.cardHeading}>Bookings</h4>
                            </CardBody>
                        </Card>
                        </Link>
                    </GridItem>

                </GridContainer>
            </div>
            }
            <div>
                <Switch>
                    <Route path={`${path}/registrations`}>
                        < Registration {...props} />
                    </Route>
                    <Route path={`${path}/bookings`}>
                        <Booking {...props}/>
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
