import React, { useState, useEffect } from 'react';
import AddFeatured from "./AddFeatured";
const EditFeatured = (props) => {
    return (
        <>
            <AddFeatured {...props} />
         
        </>
    );
}

export default EditFeatured
