import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { authenticationService } from '_services/authentication.service.js';
import { Link } from 'react-router-dom';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import BackGround from '../../assets/img/BackgroundImageForm.png';
import Brandlogo from "../../assets/img/Brandlogo.png";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/loginPageStyle.js";


const useStyles = makeStyles((theme) => ({
  loginButton: {
    background: "#DB0D0D",
    borderRadius: 10,
    padding: "14px 30px",
    "&:hover": {
      background: "#fff",
      border: "1px solid #DB0D0D",
      color: "#DB0D0D"
    },
    "&:focus": {
      background: "#fff",
      border: "1px solid #DB0D0D",
      color: "#DB0D0D"
    },
  },
  formBackground: {
    minHeight: '100%',
    backgroundImage: `url(${BackGround})`,
    backgroundSize: 'cover',
    overflow: 'hidden',
    width: '100%',
    borderTop: "3px solid #e6e8ea "
  },
  body: {
    backgroundColor: "#EAEDF7"
  },
  emailInput: {
    background: "#fff",
    borderRadius: "10px"
  }


}));
export default function LoginPage(props) {

  // constructor(props) {
  //     super(props);
  //     const classes = useStyles();
  //     // redirect to home if already logged in
  //     if (authenticationService.currentUserValue) { 
  //         this.props.history.push('/app');
  //     }
  // }






  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    //Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  return (

    <div className={`${classes.container} ${classes.centerdiv}`} style={{ paddingTop: 25, backgroundColor: "#fff" }}>
      <div style={{ width: 118, height: 28, left: 20, top: 26, marginBottom: 20, paddingLeft: 25 }}>
        <img src={Brandlogo} style={{}} />
      </div>
      <div className={classes.formBackground} style={{}}>
        <GridContainer className={classes.formBackground} style={{ width: "100%", marginTop: 25 }} justify="center" >

          <GridItem xs={12} sm={6} md={5}>
            <div>
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email().required('Email is required'),
                  password: Yup.string().required('Password is required')
                })}
                onSubmit={({ email, password }, { setStatus, setSubmitting }) => {
                  setStatus();
                  authenticationService.login(email, password)
                    .then(
                      user => {
                        if (user.user.role == '111') {
                          const { from } = props.location.state || { from: { pathname: "/app/newSp" } };
                          props.history.push(from);
                        }
                        else if (user.user.role == '333') {
                          const { from } = props.location.state || { from: { pathname: "/" } };
                          props.history.push(from);
                        }

                      },
                      error => {
                        setSubmitting(false);
                        setStatus(error);
                      }
                    );
                }}>
                {({ errors, status, touched, isSubmitting }) => (


                  <Form style={{ height: '100vh', marginLeft: "auto", width: "345px", marginRight: "auto" }}>
                    <Card className={classes[cardAnimaton]} style={{ backgroundColor: "unset", boxShadow: "unset" }} >
                      <CardHeader
                        className={`${classes.cardHeader}`} >
                        <h4 className={`${classes.cardTitle} ${classes.h4} `} style={{ fontSize: "28px", fontWeight: "450", lineHeight: "38px", fontFamily: "Muli", color: "#1e1e1e" }}>Sign In to Your Account</h4>
                      </CardHeader>
                      <CardBody>
                        <div className="form-group">
                          <h5 className={classes.h5} style={{ fontSize: 14, fontWeight: 400, fontFamily: "Muli", color: "#111111" }}>EMAIL ADDRESS</h5>
                          <Field name="email" id="email" type="email" placeholder="" className={`${classes.emailInput} ${'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}`} />
                          <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                          <h5 className={classes.h5} style={{ fontSize: 14, fontWeight: 400, fontFamily: "Muli" }}>PASSWORD</h5>
                          <Field name="password" id="password" placeholder="" type="password" className={`${classes.emailInput} ${'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}`} />
                          <ErrorMessage name="password" component="div" className="invalid-feedback" />
                        </div>
                        <div className="row">
                          <div className="col-md-12" align="right" style={{ textAlign: 'center' }}>
                            <Link style={{ textDecoration: 'none', color: "#005CB2" }} to="/password_reset" className={classes.link}> Forgot Password?</Link>
                          </div>
                        </div>
                      </CardBody>
                      <CardFooter className={classes.justifyContentCenter}>
                        {/* <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button> */}

                        <Button type="submit" className={classes.loginButton} simple block size="lg">
                          Sign In
                        </Button>
                        {isSubmitting &&
                          <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                        <br />
                      </CardFooter>
                      <div className="row">
                        <div className="col-md-12">
                          {status &&
                            <div className={'alert alert-danger'}>{status}</div>
                          }
                        </div>
                      </div>

                    </Card>

                  </Form>
                )}
              </Formik>

            </div>

          </GridItem>
        </GridContainer>
      </div>

      {/* <div className="alert alert-info">
                    <strong>Administrator</strong> - U: bookapro@admin.com P: Bookapro<br />
                    <strong>Service Provider</strong> - U: bookapro@sp.com P: test<br />
                    <strong>Customer</strong> - U: bookapro@cust.com P: test
                </div>
                <h2>Login</h2> */}
    </div>
  )

}

//export default Login;

// import React, { useState } from "react";

// //@material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";

// //@material-ui/icons
// import Face from "@material-ui/icons/Face";
// import Email from "@material-ui/icons/Email";
// //import LockOutline from "@material-ui/icons/LockOutline";

// //@core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardFooter from "components/Card/CardFooter.js";
// import styles from "assets/jss/material-dashboard-react/views/loginPageStyle.js";
// import { Link } from 'react-router-dom';
// const useStyles = makeStyles(styles);

// import { authenticationService } from '_services/authentication.service.js';

// export default function LoginPage(props) {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
//   React.useEffect(() => {
//     let id = setTimeout(function () {
//       setCardAnimation("");
//     }, 700);
//     //Specify how to clean up after this effect:
//     return function cleanup() {
//       window.clearTimeout(id);
//     };
//   });

//   const handleLogin = (e) => {
//     e.preventDefault();
//     authenticationService.login(email, password)
//       .then(
//         user => {
//           const { from } = props.location.state || { from: { pathname: "/" } };
//           props.history.push(from);
//         },
//         error => {
//         }
//       )
//   }
//   const classes = useStyles();
//   return (
//     <div className={classes.container}>
//       <GridContainer justify="center">
//         <GridItem xs={12} sm={6} md={4}>
//           <form onSubmit={handleLogin}>
//             <Card className={classes[cardAnimaton]}>
//               <CardHeader
//                 className={`${classes.cardHeader}`}
//               >
//                 <h4 className={classes.cardTitle}>Sign In to Your Account</h4>
//               </CardHeader>
//               <CardBody>
//                 <CustomInput
//                   labelText="Email..."
//                   id="email"
//                   formControlProps={{
//                     fullWidth: true,
//                   }}
//                   inputProps={{
//                     required: true,
//                     onChange: (event) => setEmail(event.target.value),
//                     type: "email",
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <Email className={classes.inputAdornmentIcon} />
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//                 <CustomInput
//                   labelText="Password"
//                   id="password"
//                   formControlProps={{
//                     fullWidth: true,
//                   }}
//                   inputProps={{
//                     required: true,
//                     onChange: (event) => setPassword(event.target.value),
//                     type: "password",
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <Icon className={classes.inputAdornmentIcon}>
//                           lock_outline
//                         </Icon>
//                       </InputAdornment>
//                     ),
//                     type: "password",
//                     autoComplete: "off",
//                   }}
//                 />

//                 <div className="col-md-12" align="right">
//                 <Link to="/password_reset"> Forgot Password?</Link>
//                 </div>                
//               </CardBody>
//               <CardFooter className={classes.justifyContentCenter}>                  
//                   <Button type="submit" className={classes.loginButton} simple size="lg" block>
//                     Sign In
//                   </Button>
//               </CardFooter>
//             </Card>
//           </form>


//         </GridItem>
//       </GridContainer>
//     </div>
//   );
// }