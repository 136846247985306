import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from '@material-ui/core/Button';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, TextField, Grid, Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { userService } from "_services/user.service";
import { categoryService } from "_services/category.service";
import { SPService } from "_services/serviceprovider.service";
import SweetAlert from 'react-bootstrap-sweetalert';
import { set } from 'date-fns';
import { Icon } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    displayFlex: {
        display: "flex"
    },
    DelButton: {
        // border: "1px solid grey",
        color: "#6362ca",
        backgroundColor: 'white',
        textTransform: "capitalize",
        fontSize: 15,
        "&:hover": {
            backgroundColor: 'grey',
            color: 'white',
        },
        "&:focus": {
            backgroundColor: 'grey',
            color: 'White',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    // container: {
    //     // border:"1px solid grey",
    //     margin: "0 -20px"
    // },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end",
        color: '#25233C',
    },
    Imginput: {
        display: "none"
    },
    boxStyle: {
        marginTop: "30px",
    },
    root: {
        '& .MuiInput-underline:before': {
            border: '2px solid red',
        },
    },
    selectStyle: {

        border: '1px solid #c4c4c4',
        borderRadius: '5px',
        height: '45px',
        // backgroundColor:'#eaeaea'

    },
    select: {
        border: '1px solid #c4c4c4',
        borderRadius: '5px',
        height: '45px',
        backgroundColor:'#eaeaea'
    },
    errorSelectStyle: {
        border: '2px solid red',
        borderRadius: '5px',
        height: '45px'

    },
    paddingLeft24: {
        paddingLeft: '24px'
    },
    displayFlexJustifyEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    fontSize20: {
        fontSize: '20px',
        color: '#25233C',
        marginBottom: 14,
    },
    rounded: {
        "& fieldset": {
            borderRadius: 5,
            padding: 14
        },
    },
    lableStyle: {
        color: '#25233C',
        fontSize: '18px',
        fontWeight: "500"
    }
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const MenuProps2 = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

const AddFeatured = (props) => {
    const classes = useStyles();


    //  functions ************************************************************************************************************************************

    function getToPrevious() {
        const { from } = { from: { pathname: "/app/product_services/featuredListing" } };
        // props.history.push(from);
        props.history.goBack();
    }



    function SweetAlertConfirmError(data) {
        const getAlert = () => (
            <SweetAlert
                error
                title="Error"
                onConfirm={() => {
                    setAlertError(null);
                }}
            >
                {data}
            </SweetAlert>
        )
        setAlertError(getAlert());
    }

    function SweetAlertConfirm(data) {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                    if (data == "updated") {
                        setAlert(null)
                    }
                    else if (data == "saved") {
                        setAlert(null);
                        props.history.goBack();
                    }
                    else if (data == "deleted") {
                        setAlert(null);
                        props.history.goBack();
                    }
                }}
            >
                Data {data} successfully
            </SweetAlert>
        )
        setAlert(getAlert());
    }


    const deleteFeatured = (idToDelete) => {

        const obj = {
            id: idToDelete
        }
        SPService.deleteFeaturedSp(obj).then((res) => {
            SweetAlertConfirm('deleted')
        })
    }


    function SweetAlertConfirmDelete(id) {
        const getAlert = () => (id &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => {
                    setAlertDelete(null);
                    deleteFeatured(id);
                }}
                onCancel={() => setAlertDelete(null)}
                focusCancelBtn
            >
                You want to delete this record?
            </SweetAlert>
        )
        setAlertDelete(getAlert());
    }


    const setFeaturedDetail = (event) => {
        // console.log(event.target.value.length);
        if (event.target.value.length > 0) {
            setErrorCheck({ ...errorCheck, [event.target.name]: false })
            setDataSendFeatured({ ...dataSendFeatured, [event.target.name]: event.target.value })
        }
    }


    const handleDates = (event, dataName) => {
        if (dataName == "fromDate" && dataSendFeatured.toDate == null) {
            setDataSendFeatured({ ...dataSendFeatured, [dataName]: new Date(event) })
        }

        else if (dataName == "fromDate" && dataSendFeatured.toDate !== null) {
            let date2 = new Date(event);
            let date1 = dataSendFeatured.toDate;
            let diffDays = date1.getDate() - date2.getDate();
            if (diffDays < 0) {
                SweetAlertConfirmError("From Featured date should be less than to featured Date");
            }
            else if (diffDays >= 0) {
                setDataSendFeatured({ ...dataSendFeatured, [dataName]: new Date(event) })
            }
        }
        if (dataName == "toDate") {
            setDataSendFeatured({ ...dataSendFeatured, [dataName]: new Date(event) })
        }
    }


    const saveFeatured = () => {
        if (dataSendFeatured.subcategoryid.length == 0) {
            setErrorCheck({ spid: false, subcategoryid: true });
        } else if (dataSendFeatured.spid.length == 0) {
            setErrorCheck({ spid: true, subcategoryid: false });
        } else if (dataSendFeatured.subcategoryid.length == 0 && dataSendFeatured.spid.length == 0) {
            setErrorCheck({ spid: true, subcategoryid: true });
        }
        else {
            // console.log(dataSendFeatured);
            const obj = {
                id: dataSendFeatured.spid,
                featured_from: dataSendFeatured.fromDate.toISOString().split('T')[0],
                featured_to: dataSendFeatured.toDate == null ? new Date(dataSendFeatured.fromDate).toISOString().split('T')[0] : dataSendFeatured.toDate.toISOString().split('T')[0],
                subcategory_id: dataSendFeatured.subcategoryid
            }
            SPService.postFeaturedSp(obj).then((res) => {
                if (res.message) {
                    SweetAlertConfirmError(res.message)
                }
                else if (res.error) {
                    SweetAlertConfirmError(res.error)
                }
                else {
                    SweetAlertConfirm('saved');
                }
            })
        }
    }

    const updateFeatured = () => {
        if (dataSendFeatured.subcategoryid.length == 0) {
            setErrorCheck({ spid: false, subcategoryid: true });
        } else if (dataSendFeatured.spid.length == 0) {
            setErrorCheck({ spid: true, subcategoryid: false });
        } else if (dataSendFeatured.subcategoryid.length == 0 && dataSendFeatured.spid.length == 0) {
            setErrorCheck({ spid: true, subcategoryid: true });
        }
        else {
            // console.log(dataSendFeatured);
            const obj = {
                id: props.location.state.uniqueIdVerify,
                featured_from: dataSendFeatured.fromDate.toISOString().split('T')[0],
                featured_to: dataSendFeatured.toDate == null ? new Date(dataSendFeatured.fromDate).toISOString().split('T')[0] : dataSendFeatured.toDate.toISOString().split('T')[0],
                subcategory_id: dataSendFeatured.subcategoryid,
                serviceprovider_id: dataSendFeatured.spid
            }
            SPService.putFeaturedSp(obj).then((res) => {
                if (res.message) {
                    SweetAlertConfirmError(res.message)
                }
                else if (res.error) {
                    SweetAlertConfirmError(res.error)
                }
                else {
                    SweetAlertConfirm('updated');
                }
            })
        }
    }

    //  setState declarations ************************************************************************************************************************************

    const [spdata, setSpData] = React.useState([]);
    const [subcategoryData, setSubCategoryData] = React.useState([]);
    const [errorCheck, setErrorCheck] = React.useState({
        spid: false,
        subcategoryid: false,
        calFrom: false,
        calTo: false
    });

    const [alertError, setAlertError] = React.useState(null);

    const [alert, setAlert] = React.useState(null);

    const [alertDelete, setAlertDelete] = React.useState(null);

    const [dataSendFeatured, setDataSendFeatured] = React.useState({
        spid: '',
        subcategoryid: '',
        fromDate: new Date(),
        toDate: null
    })


    //  useEffect Code  ************************************************************************************************************************************


    React.useEffect(() => {

        const getAllSubCategory = async () => {
            try {
                const responseAllSubcategoty = await categoryService.getAllSubCategory();
                if (responseAllSubcategoty && responseAllSubcategoty.length > 0) {
                    let subCategoryArray = []
                    responseAllSubcategoty.map((prop, index) => {
                        let obj = { id: prop._id, name: prop.name }
                        subCategoryArray.push(obj);
                    })
                    if (subCategoryArray.length > 0) {
                        setSubCategoryData(subCategoryArray);
                    }
                }
            }
            catch (err) { }
        }

        getAllSubCategory();

    }, [])

    React.useEffect(() => {
        if (window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured' && subcategoryData.length > 0) {
            setDataSendFeatured({
                ...dataSendFeatured,
                subcategoryid: props.location.state.subCatid,
                fromDate: new Date(props.location.state.featuredFromDate),
                toDate: new Date(props.location.state.featuredToDate)
            })
        }
    }, [subcategoryData])

    React.useEffect(() => {
        const setSpDataOnSelectedSubCategory = async () => {
            if (dataSendFeatured.subcategoryid) {
                try {
                    const res = await SPService.getServiceProviderBySubcategoryId(dataSendFeatured.subcategoryid);
                    // if(Array.isArray({id:'2'})){
                    //     console.log('yes');
                    // }       
                    if (res && res.message) {
                        setSpData([]);
                    }
                    else if (res && res.length > 0) {
                        let spArrayData = []
                        res.map((prop, index) => {
                            let obj = { id: prop.sp_id, name: prop.name };
                            spArrayData.push(obj);
                        });
                        if (spArrayData.length > 0) {
                            setSpData(spArrayData);
                        }
                    }
                    else {
                        setSpData([]);
                    }
                }
                catch (err) { }
            }
        }

        setSpDataOnSelectedSubCategory();


    }, [dataSendFeatured.subcategoryid])

    React.useEffect(() => {
        if (spdata.length > 0 && window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured') {
            setDataSendFeatured({ ...dataSendFeatured, spid: props.location.state.spid });
        }
    }, [spdata])

    // React.useEffect(() => {

    //     console.log(subcategoryData);
    // }, [subcategoryData])

    return (

        <>
            {
                <div className={classes.container}>
                    <GridContainer style={{ width: "100%", paddingLeft:'0px' }}>
                        <GridItem xs={12} sm={12} md={8}  id="featuredListHeader">
                            <div>
                                <div className={classes.header}>
                                    <IconButton aria-label="edit" onClick={getToPrevious} style={{paddingLeft:'0px',marginLeft:'-8px'}}>
                                        <ArrowBackIcon style={{ color: '#25233C' }} />
                                    </IconButton>
                                    {
                                        window.location.href.split('/')[window.location.href.split('/').length - 1] == 'addFeatured' ?
                                            <h4 className={classes.fontSize20} style={{fontFamily: "Muli-Bold"}}>Featured Listing</h4> : <h4 className={classes.fontSize20} style={{fontFamily: "Muli-Bold"}} >Edit Featured Listing</h4>
                                    }
                                </div>

                            </div>
                        </GridItem>
                    </GridContainer>

                    {
                        ((window.location.href.split('/')[window.location.href.split('/').length - 1] == "editFeatured" && dataSendFeatured.fromDate !== null && dataSendFeatured.toDate !== null && dataSendFeatured.spid !== '') ||
                            (window.location.href.split('/')[window.location.href.split('/').length - 1] == "addFeatured" && dataSendFeatured.fromDate !== null)) ?
                            <form style={{ padding: '0px'}}>
                                <Card>
                                    <CardBody className="px-0 pb-0" style={{paddingTop:'0px'}}></CardBody>
                                    <div style={{ padding: '0px 30px' }}>
                                        <GridContainer >
                                            <GridItem xs={12} sm={12} md={12} className={classes.boxStyle}>

                                                <Box className={classes.boxStyle} style={{ width: "31.25%" }}>
                                                    <InputLabel htmlFor="subCategory" className={classes.lableStyle}>Choose Sub Category</InputLabel>
                                                    <Select
                                                        readOnly={window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured' ? true : false}
                                                        disableUnderline={true}
                                                        className={`${classes.paddingLeft24} ${errorCheck.subcategoryid == true ? classes.errorSelectStyle : window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured'?classes.select:classes.selectStyle}`}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id={window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured'?"select-outlined":""}
                                                        name="subcategoryid"
                                                        fullWidth={true}
                                                        value={dataSendFeatured.subcategoryid ? dataSendFeatured.subcategoryid : ''}
                                                        onChange={setFeaturedDetail}
                                                        MenuProps={MenuProps}
                                                        input={<Input
                                                        // classes={{
                                                        //     underline:errorCheck.spid==true ? classes.underline:'',
                                                        //   }} 
                                                        />} >
                                                        {/* <MenuItem value={''}>Select SubCategory </MenuItem> */}
                                                        {subcategoryData.length > 0 &&
                                                            subcategoryData.map((val, index) => (
                                                                <MenuItem key={val.id} value={val.id}>
                                                                    {val.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </Box>
                                                {
                                                    errorCheck.subcategoryid == true &&
                                                    <p style={{ color: 'red', marginBottom: 0 }}>
                                                        * This Field is required
                                                    </p>
                                                }
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={4} style={{ marginTop: "20px" }}>
                                                <div className="form-group">
                                                    <InputLabel htmlFor="subCategory" className={classes.lableStyle}>From</InputLabel>
                                                    {

                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                            <KeyboardDatePicker
                                                                style={{ width: "100%" }}
                                                                InputProps={{ readOnly: true }}
                                                                className={errorCheck.calFrom == true ? classes.calenderError : classes.rounded}
                                                                autoOk
                                                                minDate={props?.location?.state?.featuredFromDate ? new Date(props.location.state.featuredFromDate) : new Date()}
                                                                maxDate={dataSendFeatured.toDate}
                                                                variant="inline"
                                                                inputVariant="outlined"
                                                                name="fromDate"
                                                                format="yyyy/MM/dd"
                                                                value={dataSendFeatured.fromDate}
                                                                InputAdornmentProps={{ position: "start" }}
                                                                onChange={(event) => { handleDates(event, 'fromDate') }}

                                                            />
                                                        </MuiPickersUtilsProvider>

                                                    }
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={4} style={{ marginTop: "20px" }}>
                                                <div className="form-group">
                                                    <InputLabel htmlFor="subCategory" className={classes.lableStyle}>To</InputLabel>
                                                    {

                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                style={{ width: "100%" }}
                                                                InputProps={{ readOnly: true }}
                                                                className={errorCheck.calTo == true ? classes.calenderError : classes.rounded}
                                                                // min date -> check if props containing from date, then giving min date of from date + 1 ,else ->regular flow    
                                                                minDate={props?.location?.state?.featuredFromDate ? new Date(props.location.state.featuredFromDate) : new Date(dataSendFeatured['fromDate'])}
                                                                autoOk
                                                                variant="inline"
                                                                inputVariant="outlined"
                                                                format="yyyy/MM/dd"
                                                                name="toDate"
                                                                value={dataSendFeatured.toDate == null ? dataSendFeatured.fromDate : dataSendFeatured.toDate}
                                                                InputAdornmentProps={{ position: "start" }}
                                                                onChange={(event) => { handleDates(event, 'toDate') }}

                                                            />
                                                        </MuiPickersUtilsProvider>

                                                    }
                                                </div>
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={12} style={{ marginBottom: '20px' }}>
                                                <Box className={classes.boxStyle} style={{ width: '31.25%' }}>

                                                    <InputLabel htmlFor="name-multiple"
                                                        className={classes.lableStyle}>Select Service Provider</InputLabel>
                                                    <Select
                                                        readOnly={window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured' ? true : false}
                                                        disableUnderline={true}
                                                        className={`${classes.paddingLeft24} ${errorCheck.subcategoryid == true ? classes.errorSelectStyle : window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured'?classes.select:classes.selectStyle}`}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id={window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured'?"select-outlined":""}
                                                        name="spid"
                                                        fullWidth={true}
                                                        value={dataSendFeatured.spid}
                                                        onChange={setFeaturedDetail}
                                                        MenuProps={MenuProps2}
                                                        input={<Input/>}>
                                                        {dataSendFeatured.subcategoryid == "" && spdata.length == 0 && <MenuItem value={''}>Select Subcategory First</MenuItem>}
                                                        {dataSendFeatured.subcategoryid !== "" && spdata.length == 0 && <MenuItem value={''}>No service provider for this subcategory</MenuItem>}
                                                        {spdata.length > 0 &&
                                                            spdata.map((val, index) => (
                                                                <MenuItem key={val.id} value={val.id}>
                                                                    {val.name}
                                                                </MenuItem>
                                                            ))}

                                                        {/* <MenuItem value={'1-2'}>1-2years</MenuItem>
                                            <MenuItem value={'2-3'}>2-3years</MenuItem>
                                            <MenuItem value={'3-4'}>3-4years</MenuItem>
                                            <MenuItem value={'4-5'}>4-5years</MenuItem>
                                            <MenuItem value={'5-6'}>5-6years</MenuItem> */}
                                                    </Select>
                                                </Box>
                                                {
                                                    errorCheck.spid == true &&
                                                    <p style={{ color: 'red', marginBottom: 0 }}>
                                                        * This Field is required
                                                    </p>
                                                }
                                                
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </Card>
                            </form> :
                            <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </div>
                    }
                    <GridContainer style={{ padding: "0 20px" }}>
                        <Grid item style={{ width: '100%' }}>
                            {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'addFeatured' &&
                                <div className={classes.displayFlex} style={{ justifyContent: 'space-between' }}>
                                    <div >
                                        <Button style={{fontSize: 16}} className={classes.showBtn} variant="contained" color="primary" onClick={saveFeatured}>
                                            Save
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        {/* <Button className={classes.DelButton} variant="contained" onClick={() => SweetAlertConfirmDelete(props?.location?.state?.uniqueIdVerify)} style={{ color: '#8E8CAC' }}>
                                            Delete
                                        </Button> */}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button 
                                            style={{fontSize: 15, color: '#6B6987'}}
                                            className={`${classes.DelButton}`} variant="contained"
                                            startIcon={<Icon color="primary" style={{ color: '#6B6987' }}>add</Icon>}
                                            onClick={() => props.history.goBack()}>

                                            Add More

                                        </Button>
                                    </div>
                                </div>
                            }
                            {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured' && dataSendFeatured.spid !== '' &&
                                <div className={classes.displayFlex} style={{ justifyContent: 'space-between' }}>
                                    <div >
                                        <Button className={classes.showBtn} variant="contained" color="primary" onClick={updateFeatured}>
                                            {dataSendFeatured.fromDate !== null && dataSendFeatured.toDate !== null && 'Update'}
                                        </Button>
                                    </div>
                                    
                                </div>
                            }
                            {/* {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured' && dataSendFeatured.spid !== '' &&
                                <div className={classes.displayFlex} style={{ justifyContent: 'space-between' }}>
                                    <div >
                                        <Button className={classes.showBtn} variant="contained" color="primary" onClick={updateFeatured}>
                                            {dataSendFeatured.fromDate !== null && dataSendFeatured.toDate !== null && 'Update'}
                                        </Button>
                                    </div>
                                    
                                </div>
                            } */}
                        </Grid>


                        {/* {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured' &&

                        <Grid item xs={2}>
                                <Button className={classes.DelButton} variant="contained" onClick={()=>SweetAlertConfirmDelete(props.location.state.uniqueIdVerify)}>
                                  {dataSendFeatured.fromDate!==null && dataSendFeatured.toDate!==null && 'Delete'}
                    </Button> 
                            
                        </Grid>
}

                        {window.location.href.split('/')[window.location.href.split('/').length - 1] == 'editFeatured' &&
                        <Grid className={`${classes.displayFlexJustifyEnd}`} item xs={5}>
                            
                                <Button className={`${classes.DelButton}`} variant="contained" onClick={()=>props.history.goBack()}>
                                {dataSendFeatured.fromDate!==null && dataSendFeatured.toDate!==null && '+ Add More'}
                    </Button> 
                            
                        </Grid>
} */}
                    </GridContainer>
                </div>
            }
            <>
                {alertError}
            </>
            <>
                {alert}
            </>
            <>
                {alertDelete}
            </>

        </>


    );
}

export default AddFeatured;
