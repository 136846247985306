
import React from 'react'

const PageNotFound = () => {
    return (
        <div id="wrapper" style={{margin:"10%", width:"50%"}}>
            <img src="https://i.imgur.com/qIufhof.png" width="30%" />
            <div id="info">
                <h3>This page could not be found</h3>
            </div>
        </div >
    )
}

export default PageNotFound