import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import { Icon } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { userService } from "_services/user.service";
import { categoryService } from "_services/category.service";
import { SPService } from "_services/serviceprovider.service";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useRouteMatch
} from "react-router-dom";

import spdataContext from 'context/spdataContext';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        paddingBottom: "1.5em",
        width: "100%"
        // width: "calc(100% + 25px)",
        // maxWidth: "calc(100% + 25px)"
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    button: {
        background: '#EAEDF7',
        color: '#25233C',
        lineHeight: '19px',
        textTransform: "initial",
        fontWeight: "unset",
        border: "1px solid #80808000",
        "& > *":{
            fontSize: "15px !important",
        }
    },
    workImg: { objectFit: "contain", margin: 10, borderRadius: 5, cursor: 'pointer' },
    faqTitle: {
        background: "#EAEDF7",
        padding: "14px",
        margin: "unset",
        borderRadius: "5px 5px 0 0 ",
        fontWeight: "bold"
    },
    faqDet: {
        border: "1px solid #EAEDF7",
        borderRadius: "0 0 5px 5px",
        padding: 15
    },
    reviewCard: {
        border: "1px solid #EAEDF7",
        borderRadius: 5,
        flex: "0 0 30%",
        maxWidth: "30%",
        marginRight: 15,
        [theme.breakpoints.down('sm')]: {
            flex: "0 0 100%",
            maxWidth: "100%",
        },

    },
    starSection: {
        color: "#6259CA",
        display: "flex",
        justifyContent: "flex-end"
    },
    showBtn: {
        textTransform: "capitalize",
        fontWeight: "unset",
        padding: "12px 22px",
        fontSize: 16,
        backgroundColor: "#6259CA"
    },
    displayFlexJustifyCenter: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

const Profile = (props) => {
    const classes = useStyles();
    const { slug } = useParams();
    const [dataObjProfile, setDataObjProfile] = React.useState({
        about: '',
        creditBalance: 0,
        timesHired: '',
        faq: [],
        serviceLocation: [],
        experience: '',
        paymentAccepted: [],
        team_size: '',
        working_at: '',
        min_advanced_payment: '',
        tier_type: '',
        place_names: [],
        min_booking_amount:''
    })

    const [reviewData, setReviewData] = React.useState([]);
    const [showMore, setShowMore] = React.useState(3);
    const [previousWork, setPreviousWork] = React.useState([]);
    const [showSaveButton, setShowButton] = React.useState(false);

    const dataContext = React.useContext(spdataContext);


    // In useEffect in some section we have called api in two methods like by taking slug and window object ,reason for that is some times the slug can't get fetched due to stack of routes over each other, so we used window object for that


    React.useEffect(() => {
        const getDataOfSp = async () => {
            if (slug == 'addService' || slug == 'addPackage' || slug == 'editService' || slug == 'editPackage' || slug == 'addpromotion') {
                try {
                    // const response = await userService.getSP(window.location.href.split('/')[window.location.href.split('/').length - 1]);
                    const response = await userService.getSP(window.location.href.split('/')[window.location.href.split('/').length - 1]);
                    if (response) {
                        setDataObjProfile({
                            about: response.brief_desription ? response.brief_desription : '',
                            creditBalance: response.credit ? response.credit : 0,
                            faq: response.faq ? response.faq : [],
                            serviceLocation: response.service_location ? response.service_location : [],
                            experience: response.experience ? response.experience : '',
                            paymentAccepted: response.accepted_payment_methods ? response.accepted_payment_methods : [],
                            team_size: response.team_size ? response.team_size : '',
                            working_at: response.team_size ? response.team_size : '',
                            min_advanced_payment: response.min_advanced_payment_percentage ? response.min_advanced_payment_percentage : '',
                            tier_type: response.tier_type ? response.tier_type : '',
                            place_names: response.place_names ? response.place_names : [],
                            min_booking_amount:response.minimum_charge ? response.minimum_charge : ''
                        })
                    }
                }
                catch (err) {
                    console.log(err);
                }
            }
            else {
                try {
                    const response = await userService.getSP(slug);
                    if (response) {
                        setDataObjProfile({
                            about: response.brief_desription ? response.brief_desription : '',
                            creditBalance: response.credit ? response.credit : 0,
                            faq: response.faq ? response.faq : [],
                            serviceLocation: response.service_location ? response.service_location : [],
                            experience: response.experience ? response.experience : '',
                            paymentAccepted: response.accepted_payment_methods ? response.accepted_payment_methods : [],
                            team_size: response.team_size ? response.team_size : '',
                            working_at: response.team_size ? response.team_size : '',
                            min_advanced_payment: response.min_advanced_payment_percentage ? response.min_advanced_payment_percentage : '',
                            tier_type: response.tier_type ? response.tier_type : '',
                            place_names: response.place_names ? response.place_names : [],
                            min_booking_amount:response.minimum_charge ? response.minimum_charge : ''
                        })
                    }
                }
                catch (err) {
                    console.log(err);
                }
            } // setReviewData([...reviewArray]);
        }
        getDataOfSp();
    }, [])
    React.useEffect(() => {
        const getReviewOfSp = async () => {
            if (slug == 'addService' || slug == 'addPackage' || slug == 'editService' || slug == 'editPackage' || slug == 'addpromotion') {
                try {
                    const responseOfReview = await categoryService.getReviewsOfSp(window.location.href.split('/')[window.location.href.split('/').length - 1]);
                    let arr = [];
                    if (responseOfReview.reviews.length > 0) {
                        responseOfReview.reviews.map(async (prop, index) => {
                            let obj = {
                                _id: prop._id,
                                customerName: prop.customer_name,
                                rating: prop.avg_rating,
                                reviewText: prop.text,
                                status: prop.status == "true" ? true : false,
                                spId: prop.serviceprovider_id
                            }
                            arr.push(obj);
                        })
                        if (arr.length > 0) {
                            setReviewData(arr);
                        }
                    }
                }
                catch (err) {
                    console.log(err);
                }
            }
            else {
                try {
                    const responseOfReview = await categoryService.getReviewsOfSp(slug);
                    let arr = [];
                    if (responseOfReview.reviews.length > 0) {
                        responseOfReview.reviews.map(async (prop, index) => {
                            let obj = {
                                _id: prop._id,
                                customerName: prop.customer_name,
                                rating: prop.avg_rating,
                                reviewText: prop.text,
                                status: prop.status == "true" ? true : false,
                                spId: prop.serviceprovider_id
                            }
                            arr.push(obj);
                        })
                        if (arr.length > 0) {
                            setReviewData(arr);
                        }
                    }
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
        getReviewOfSp();
    }, [])


    React.useEffect(() => {
        const getPreviousWorkOfSp = async () => {
            if (slug == 'addService' || slug == 'addPackage' || slug == 'editService' || slug == 'editPackage' || slug == 'addpromotion') {
                try {
                    const responsePreviousWork = await SPService.getPreviousWork(window.location.href.split('/')[window.location.href.split('/').length - 1]);
                    let arr = [];
                    if (responsePreviousWork.length > 0) {
                        responsePreviousWork.map((prop, index) => {
                            if (prop.url && prop.url.length > 0) {
                                prop.url.map((urlValue, index) => {
                                    let obj = {
                                        "id": prop._id,
                                        "serviceProviderId": prop.serviceprovider_id,
                                        "image": urlValue
                                    }
                                    arr.push(obj);
                                })
                            }
                        })
                    }
                    setPreviousWork([...arr]);
                }
                catch (err) {
                    console.log(err);
                }
            }
            else {
                try {
                    const responsePreviousWork = await SPService.getPreviousWork(slug);
                    let arr = [];
                    if (responsePreviousWork.length > 0) {
                        responsePreviousWork.map((prop, index) => {
                            if (prop.url && prop.url.length > 0) {
                                prop.url.map((urlValue, index) => {
                                    let obj = {
                                        "id": prop._id,
                                        "serviceProviderId": prop.serviceprovider_id,
                                        "image": urlValue
                                    }
                                    arr.push(obj);
                                })
                            }
                        })
                    }
                    setPreviousWork([...arr]);
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
        getPreviousWorkOfSp();
    }, [])

    const handleCheckedReviews = (prop) => {
        let objToUpdate = {
            "id": prop._id,
            "status": prop.status == true ? "false" : "true"      // check if current instance is true then set data status false and api status false, vice-versa
        }
        categoryService.putReviewStatus(objToUpdate).then((res) => {
            let newState = reviewData.map(obj =>
                obj._id === prop._id ? { ...obj, status: !obj.status } : obj
            );
            setReviewData([...newState]);
        })
    }

    const showMoreFunction = () => {
        if (showMore <= reviewData.length) {
            setShowMore(showMore + 3);
        }
    }
    const showLessFunction = () => {
        if (showMore > 3) {
            setShowMore(showMore - 3);
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid container className="btn-lg-adj" spacing={2} item md={12} sm={12} alignItems="center" style={{paddingLeft:'14px'}}>

                        {
                            dataContext?.stateOfUser?.tierType &&
                            <Button className={classes.button} variant={'outlined'} color={'primary'} style={{fontSize: 15}} startIcon={<Icon>monetization_on</Icon>}>
                                {dataContext.stateOfUser.tierType}
                            </Button>
                        }
                        {
                            dataObjProfile.place_names && dataObjProfile.place_names.length > 0 &&
                            <Button className={classes.button} variant={'outlined'} color={'primary'} style={{fontSize: 15}} startIcon={<Icon>location_on</Icon>}>
                                {dataObjProfile.place_names.join(', ')}
                            </Button>
                        }

                        {
                            dataObjProfile.experience && dataObjProfile.experience.length > 0 &&
                            <Button className={classes.button} variant={'outlined'} color={'primary'} style={{fontSize: 15}} startIcon={<Icon>business_center</Icon>}>
                                {dataObjProfile.experience}
                            </Button>
                        }
                        {/* <Button className={classes.button} variant={'outlined'} color={'primary'} startIcon={<Icon>stars</Icon>}>
                            119 Times Hired
                        </Button> */}
                        {dataObjProfile.paymentAccepted && dataObjProfile.paymentAccepted.length > 0 &&
                            <Button className={classes.button} variant={'outlined'} color={'primary'} startIcon={<Icon>volunteer_activism</Icon>}>
                                {dataObjProfile.paymentAccepted.join(', ')}
                            </Button>
                        }
                        {
                            dataObjProfile.min_advanced_payment &&
                            <Button className={classes.button} variant={'outlined'} color={'primary'} startIcon={<Icon>notifications</Icon>}>
                                {`${dataObjProfile.min_advanced_payment}% advance needed for booking`}
                            </Button>
                        }
                        {
                            dataObjProfile.team_size &&

                            <Button className={classes.button} variant={'outlined'} color={'primary'} startIcon={<Icon>groups</Icon>}>
                                {`Team of ${dataObjProfile.team_size}`}
                            </Button>
                        }
                        {
                            dataObjProfile.min_booking_amount &&

                            <Button className={classes.button} variant={'outlined'} color={'primary'} startIcon={<Icon>monetization_on</Icon>}>
                                {`Minimum Booking Fee ${dataObjProfile.min_booking_amount}`}
                            </Button>
                        }
                    </Grid>
                    <>{
                        dataObjProfile.about &&
                        <Grid item md={12} sm={12} xs={12} container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography variant="body1" gutterBottom className='font-weight-bold' style={{fontSize: 18, fontFamily: "Muli-Bold", color:"#25233C"}}>
                                        About
                                    </Typography>
                                    <Typography style={{fontSize: 15, fontFamily: "Muli-Bold", color: "#25233C"}} variant="body2" gutterBottom>
                                        {dataObjProfile.about}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    </>
                    {
                        previousWork.length > 0 &&
                        <Grid item md={12} sm={12} xs={12} container>
                            <Grid item md container direction="column" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom className='font-weight-bold' style={{fontSize: 18, fontFamily: "Muli-Bold", color:"#25233C"}}>
                                        Previous Work
                                    </Typography>
                                    {                                        previousWork.map((prop, index) => {
                                            return <img onClick={() => { window.open(prop.image); }} key={index} src={`${prop.image}`} height='100' width="100" className={classes.workImg} />
                                        })
                                    }
                                    {/* <img src="https://cdn.pixabay.com/photo/2019/02/06/16/32/architect-3979490_960_720.jpg" height='100' width="100" className={classes.workImg} />
                                <img src="https://cdn.pixabay.com/photo/2019/02/06/16/32/architect-3979490_960_720.jpg" height='100' width="100" className={classes.workImg} />
                                <img src="https://cdn.pixabay.com/photo/2019/02/06/16/32/architect-3979490_960_720.jpg" height='100' width="100" className={classes.workImg} />
                                <img src="https://cdn.pixabay.com/photo/2019/02/06/16/32/architect-3979490_960_720.jpg" height='100' width="100" className={classes.workImg} />
                                <img src="https://cdn.pixabay.com/photo/2019/02/06/16/32/architect-3979490_960_720.jpg" height='100' width="100" className={classes.workImg} />
                                <img src="https://cdn.pixabay.com/photo/2019/02/06/16/32/architect-3979490_960_720.jpg" height='100' width="100" className={classes.workImg} /> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    <>{
                        dataObjProfile.faq.length > 0 &&
                        <Grid item md={12} sm={12} xs={12} container>
                            <Grid item md container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography gutterBottom variant="subtitle1" className='font-weight-bold' style={{fontSize: 18, fontFamily: "Muli-Bold", color:"#25233C"}}>
                                        FAQ
                                    </Typography>
                                </Grid>


                                {dataObjProfile.faq.map((prop, index) => {
                                    return (
                                        <Grid item xs key={index}>
                                            <Typography variant="body1" gutterBottom className={classes.faqTitle} style={{fontSize: 15, fontFamily: "Muli-Bold", color: "#25233C"}}>
                                                {prop.question}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom className={classes.faqDet}>
                                                {prop.answer}
                                            </Typography>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    }
                    </>


                    {reviewData.length > 0 ?
                        
                        < Grid item container xs={12} spacing={4} style={{ margin: "unset" }}>
                    <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom className='font-weight-bold' style={{margin: "-10px", fontSize: 18, fontFamily: "Muli-Bold", color: "#25233C"}}>
                            {/* Reviews({reviewData.length}) */}
                            Reviews

                        </Typography>
                    </Grid>
                    {reviewData.length > 0 && showMore > 0 &&
                        reviewData.map((prop, index) => {
                            return index < showMore && <Grid key={prop._id} style={{ marginBottom: 15 }} item md={4} xs={12} lg={6} container className={classes.reviewCard}>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Typography variant="body1" gutterBottom className='font-weight-bold' style={{fontSize: 15, fontFamily: "Muli-Bold", color: "#25233C"}}>
                                        {prop.customerName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Typography variant="subtitle1" className={classes.starSection}>
                                        {prop.rating &&
                                            [...Array(parseInt(prop.rating))].map((value, index) => {
                                                return <StarIcon key={index} />
                                            })
                                        }
                                        {`(${prop.rating})`}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} >
                                    <Typography variant="body2" gutterBottom style={{fontSize: 15, fontFamily: "Muli"}}>
                                        {prop.reviewText}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <FormControlLabel
                                        control={<Checkbox name="follow" color="primary" checked={prop.status} onChange={() => handleCheckedReviews(prop)} />}
                                    />
                                </Grid>
                            </Grid>
                        })
                    }

                </Grid>
                :

                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom className='font-weight-bold'>
                        {/* Reviews({reviewData.length}) */}
                        No Reviews Received Yet

                    </Typography>
                </Grid>

                    }
                    {reviewData.length > 0 &&
                        <Grid item xs={6}>
                            <Button className={classes.showBtn} variant="contained" color="primary" onClick={showMoreFunction} style={{fontSize: 16, fontFamily: "Muli"}}>
                                Show More
                        </Button>
                        </Grid>
                    }
                    {showMore > 3 &&
                        <Grid item xs={6}>
                            <Button className={classes.showBtn} variant="contained" color="secondary" onClick={showLessFunction} style={{fontSize: 16, fontFamily: "Muli"}}>
                                Show less
                        </Button>
                    </Grid>
                }
                {/* { showSaveButton==true &&
                    <Grid className={`${classes.displayFlexJustifyCenter}`} item xs={showMore > 3 ? 5 : 8 }>
                        <Button  className={`${classes.showBtn}`} variant="contained" color="primary" onClick={()=>{}}>
                            save
                        </Button>
                    </Grid>
} */}
            </Grid>
        </Paper>
        </div>
    );
}
export default Profile