/* eslint-disable prettier/prettier */
import { authHeader } from "_helpers/auth-header.js";
import { handleResponse } from "_helpers/handle-response";

export const SPService = {
  getAllSPPromotions,
  putSP,
  putPreviousWork,
  getServiceProviderBySubcategoryId,
  postFeaturedSp,
  getFeaturedList,
  deleteFeaturedSp,
  putFeaturedSp,
  getPreviousWork,
  getTopPros,
  getMessageListOfSp,
  getFullChatOfSp,
  serviceProviderEmailVerification,
  getServiceProviderEmailOtp,
  getServiceProviderMobileOtp,
  serviceProviderMobileVerification,
  updatePreviousWork,
  getClientSecret,
  attachUserToIntent
};

function getAllSPPromotions(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json', 'Connection': 'keep-alive',
      spid: id,
      token: JSON.parse(sessionStorage.getItem('currentUser')).token
    }
  };

  return fetch(`${process.env.REACT_APP_URL}/UsersController/Promocode`, requestOptions).then(handleResponse);


}

function putSP(obj) {
  const requestOptions = {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json', 'Connection': 'keep-alive',
      token: JSON.parse(sessionStorage.getItem('currentUser')).token
    },
    body: JSON.stringify(obj),
  };

  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/ServiceProvider`, requestOptions).then(handleResponse);


}

function getServiceProviderBySubcategoryId(data) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive',
      'id': data,
      token: JSON.parse(sessionStorage.getItem('currentUser')).token
    }
  }
  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/ServiceproviderBySubCategoryid`, requestOptions).then(handleResponse);
}


function getPreviousWork(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json', 'Connection': 'keep-alive',
      spid: id,
      token: JSON.parse(sessionStorage.getItem('currentUser')).token
    }
  };

  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/PreviousWork`, requestOptions).then(handleResponse);

}

function putPreviousWork(obj) {
  var myHeaders = new Headers();
  myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);

  var formdata = new FormData();
  formdata.append("id", obj.id);
  formdata.append("description", obj.description);
  formdata.append("title", obj.title)
  if (obj.image && obj.image !== null) {
    obj.image.map((prop, index) => {
      formdata.append("file[]", prop)
    })
  }
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/PreviousWork`, requestOptions).then(handleResponse);

}
function updatePreviousWork(obj) {
  var myHeaders = new Headers();
  myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);

  var formdata = new FormData();
  formdata.append("spid", obj.id);

  obj.previousWork.map((prop, index) => {
    formdata.append(`previouswork[${index}][title]`, prop.title)
    formdata.append(`previouswork[${index}][description]`, prop.description)
    formdata.append(`previouswork[${index}][id]`, prop.id)
    if (prop.imageName.length > 0) {
      prop.imageName.map((image, indexImage) => {
        formdata.append(`previouswork[${index}][file][${indexImage}]`, image)
      })
    }
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow',
    'Content-Type': 'application/json',
    'Connection': 'keep-alive',
  };
  return fetch('https://bookapro.ebizonstaging.com/LumenConnection/public/api/v1/app/SpAppController/addBulkPreviousWorkTemp', requestOptions).then(handleResponse);
}

function postFeaturedSp(data) {
  const requestOptions = {
    method: "POST",
    headers: { 'Content-Type': 'application/json', 'Connection': 'keep-alive', token: JSON.parse(sessionStorage.getItem('currentUser')).token },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/Featured`, requestOptions).then(handleResponse);

}

function getFeaturedList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive',
      token: JSON.parse(sessionStorage.getItem('currentUser')).token
    }
  }
  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/Featured`, requestOptions).then(handleResponse);
}

function deleteFeaturedSp(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json', 'Connection': 'keep-alive',
      token: JSON.parse(sessionStorage.getItem('currentUser')).token
    },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/Featured`, requestOptions).then(handleResponse);

}

function putFeaturedSp(obj) {
  const requestOptions = {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json', 'Connection': 'keep-alive',
      token: JSON.parse(sessionStorage.getItem('currentUser')).token
    },
    body: JSON.stringify(obj),
  };

  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/Featured`, requestOptions).then(handleResponse);

}

function getTopPros() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive',
      token: JSON.parse(sessionStorage.getItem('currentUser')).token
    }
  }
  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/getTopPros`, requestOptions).then(handleResponse);
}

function getMessageListOfSp(id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive',
      token: JSON.parse(sessionStorage.getItem('currentUser')).token,
      serviceproviderid: id
    }
  }
  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/SpMessageList`, requestOptions).then(handleResponse);
}

function getFullChatOfSp(id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive',
      token: JSON.parse(sessionStorage.getItem('currentUser')).token,
      uniqueid: id
    }
  }
  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/SpOneFullChat`, requestOptions).then(handleResponse);
}

function getServiceProviderEmailOtp(obj) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive',
      email: obj.email,
      name: obj.name,
      role: '111'
    }
  }
  return fetch(`${process.env.REACT_APP_URL}/UsersController/SendVerifyEmail`, requestOptions).then(handleResponse);
}

function serviceProviderEmailVerification(obj) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive'
    },
    body: JSON.stringify(obj)
  }
  return fetch(`${process.env.REACT_APP_URL}/UsersController/verifyEmail`, requestOptions).then(handleResponse);
}

function getServiceProviderMobileOtp(obj) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive'
    },
    body: JSON.stringify(obj)
  }
  return fetch(`${process.env.REACT_APP_URL}/UsersController/sendOtp`, requestOptions).then(handleResponse);
}

function serviceProviderMobileVerification(obj) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive'
    },
    body: JSON.stringify(obj)
  }
  return fetch(`${process.env.REACT_APP_URL}/UsersController/verifyOtp`, requestOptions).then(handleResponse);
}

function getClientSecret(obj) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive',
      token: JSON.parse(sessionStorage.getItem('currentUser')).token,
    },

    body: JSON.stringify(obj)
  }
  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/stripeBalanceAndIntent`, requestOptions).then(handleResponse);
}

function attachUserToIntent(obj) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'keep-alive',
      token: JSON.parse(sessionStorage.getItem('currentUser')).token,
    },
    body: JSON.stringify(obj)
  }
  return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/attachUserToIntent`, requestOptions).then(handleResponse);
}