import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import Box from '@material-ui/core/Box';
import UserRegistration from './UserRegistration';
import SpRegistration from './SpRegistration';

const useStyles = makeStyles((theme) => ({
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    perPageSection: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        '@media(max-width:420px)':
        {
            top: "9.5pc"
        }
    },
    resultPerPageDropdown:{
        background:"white",
        padding:"0 5px",
        borderRadius:'4px'
    },
    displayFlex: {
        display: "flex"
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    Imginput: {
        display: "none"
    },
    boxStyle: {
        marginTop: "30px",
    },
    displayFlexJustifyEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    displayFlexJustifySpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems:'center'
    },
    backButtonSize: {
        '& .MuiIconButton-root': {
            padding: '8px'
        },
        paddingLeft:'0px',
        marginLeft:'-4px'
    },
    fontSize20: {
        fontSize: '20px',
        marginBottom:'14px',
        color:'#25233C',
        fontFamily:'Muli-Bold'
    },
    fontSize14: {
        fontSize: '14px',
        color:'#6B6987',
        fontFamily:'Muli !important',
        marginBottom:'0px !important'
    },
    width175px: {
        width:'175px'
    }
}));


const Registration = (props) => {
    const classes=useStyles()
    function getToPrevious() {
        // console.log('hello')
        const { from } = { from: { pathname: "/app/product_services" } };
        props.history.goBack();
    }

    const changePerSize=(event)=>{
     setPerPageData(event.target.value);
    }

    const [perPageData,setPerPageData]=React.useState(5);


    return (
        <>
         <AppBar elevation={0} position="static" color="transparent" className={classes.appbarAdj} >
                <div className={`${classes.header}`}>
                    <IconButton aria-label="edit" className={classes.backButtonSize} onClick={getToPrevious}>
                        <ArrowBackIcon />
                    </IconButton>
                    <h4 className={classes.fontSize20} >Registrations</h4>
                </div>
                <div className={`${classes.perPageSection} ${classes.displayFlexJustifySpaceBetween} ${classes.width175px} `} >
                    <p className={classes.fontSize14}>
                        Results per page
                  </p>
                    <Box sx={{ maxWidth: 61 }}>
                        <FormControl fullWidth className={classes.resultPerPageDropdown}>
                            <Select
                                className={classes.fontSize14}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                disableUnderline={true}
                                value={perPageData}
                                label="Age"
                                onChange={changePerSize}
                            >
                                <MenuItem value={5}>10</MenuItem>
                                <MenuItem value={25}>50</MenuItem>
                                <MenuItem value={50}>100</MenuItem>
                                <MenuItem value={75}>150</MenuItem>
                                <MenuItem value={100}>200</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                </div>
            </AppBar>
        <UserRegistration {...props} count={perPageData} />
        <SpRegistration {...props} count={perPageData} />
        </>
    );
}

export default Registration