const dataUrlToFile = (url, fileName) => {
    const [mediaType, data] = url.split(",");
  

    console.log(url);
    let mime = mediaType.match(/:(.*?);/)?.[0];
  
    var n = data.length;
  
    const arr = new Uint8Array(n);
  
    while (n--) {
      arr[n] = data.charCodeAt(n);
    }
    
    mime=mime.replace(":", "")
    mime=mime.replace(";", "")
    console.log(mime);
  
    return new File([arr], fileName, { type: mime });
  };
  

  // use this function to convert base64 data image to file for upload  ***************************************************************************************************************************** 

  const dataUrlToFileUsingFetch = async (
    url,
    fileName,
    mimeType
  ) => {
    // const header = new Headers({mode: 'no-cors'});
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
  
    return new File([buffer], fileName, { type: mimeType });
  };

  
  // use this function at that place where you want to convert an image https url to a base64 data image  ***************************************************************************************************************************** 


  const dataUrlToFileUsingFetchNoCors = async (
    url,
    fileName,
    mimeType
  ) => {
    // const header = new Headers({mode: 'no-cors'});
    const response = await fetch(url,{mode: 'no-cors'});
    const buffer = await response.arrayBuffer();
  
    return new File([buffer], fileName, { type: mimeType });
  };
  
  export { dataUrlToFile, dataUrlToFileUsingFetch,dataUrlToFileUsingFetchNoCors};