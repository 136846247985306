import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardMedia, Icon, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import SweetAlert from 'react-bootstrap-sweetalert';
import { servicesandpackagesService } from '_services/servicesandpackages.service';
import edit from '../../../edit-svgrepo-com.svg'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    cover: {
        border: "1px solid grey",
        height: "100%",
        maxWidth: 60,
        width: "100%"
    },
    cardTitle: {
        margin: "unset",
        padding: "0 10px",
        fontSize: 20,
        color: "#6f6c8a"
    },
    cardAction: {
        color: "#000",
    },
    button: {
        margin: theme.spacing(1),
        background: "transparent",
        boxShadow: "unset",
        border: "1px solid #8f8cac",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "transparent",
            boxShadow: "unset",
            textDecoration:"none"
            // color: '#ffffff',
        },
    },
    table: {
        margin: "10px 0",
        background: '#f5f5fc'
    },
    tcontainer: {
        //   margin:"0 -5px",
    },
    thead: {
        padding: 10
    },
    gridCont: {
        paddingTop: "10px",
        margin: 0,
    },
    gridItem1: {
        display: "flex",
        alignItems: "center",
    },
    gridItem2: {
        textAlign: "right",
    },
    tcell1: {
        border: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
        paddingLeft: "7px"
    },
    tcell2: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell3: {
        border: "unset",
        paddingLeft: "unset",
        paddingBottom: "unset",
    },
    div1: {
        display: "flex",
        alignItems: "center",
        background: "#F3F3FC",
        padding: 10,
        height: 50,
    },
    div2: {
        background: "#F3F3FC",
        padding: 15,
        color: "#25233C",
        height: 50,
    },
    div3: {
        background: "#F3F3FC",
        padding: 1,
        height:'auto'
    },
    blockTitle: {
        // paddingLeft: 10,
        color: "#25233C",
        fontSize:14
    },
    mainCard: {
        marginBottom: '2em'
    },
    firstHead:{
        paddingLeft:"1.6rem"
    },
    lastHead:{
        paddingRight:"2rem"
    },
    textDecNone:{
        textDecoration:"none"
    },
    // thead:
    // {
    //     borderLeft: "15px solid #F5F5FC",
    //     borderRight: "15px solid #F5F5FC",
    //     borderBottom: "15px solid #F5F5FC",
    //     "& th":{
    //         padding: "16px 0 0 16px",
    //         whiteSpace: "nowrap",
    //         fontFamily: 'Muli-Bold !important'
    //       }
    // }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        //   backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
        backgroundColor: "#F5F5FC",
        color: "#6c6987",
    },
}))(TableCell);

const ServiceGrid = (props) => {
    const [serviceData, setServiceData] = useState([]);
    const [data, setData] = useState(props.location.state ? props.location.state.row ? props.location.state.row : props.location.state.data : null);
    const classes = useStyles();
    const [alert, setAlert] = useState(null);
    const [pageRefresh, setPageRefresh] = useState(false);
    useEffect(() => {
        servicesandpackagesService.getServicesbySubCategory(data._id)
            .then((res) => {
                setServiceData(res);
            }).catch(() => {
            })
    }, [pageRefresh == true]);
    function editService(service) {
        const { from } = { from: { pathname: "/app/product_services/services/editservicesandpackages/" + service._id, ...props, state: { service: service, data: props.location.state.row ? props.location.state.row : props.location.state.data } } };
        props.history.push(from);
    }
    function deleteService(service) {
        setPageRefresh(false);
        servicesandpackagesService.deleteServices(service._id)
            .then((res) => {
                setAlert(null)
                setPageRefresh(true);
            })
            .catch((err) => {
                setAlert(null)
                SweetAlertConfirmError(err)
            })
    }
    function SweetAlertConfirm(service) {
        const getAlert = () => (service &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() =>  deleteService(service)}
                onCancel={() => setAlert(null)}
                focusCancelBtn
            >
                You want to delete this record?
            </SweetAlert>
        )
        setAlert(getAlert());
    }

    function SweetAlertConfirmError(msg) {
        const getAlert = () => (
            <SweetAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
        >
            {msg}
        </SweetAlert>
        )
        setAlert(getAlert());
    }

    return (
        <>
            <Card className={`${classes.mainCard} productCategory`} >
                <GridContainer style={{ paddingTop: "10px", paddingBottom: "10px", margin: 0 }}>
                    <GridItem xs={12} sm={12} md={6} style={{ display: "flex", alignItems: "center" }}>

                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} style={{ textAlign: "right" }} >
                        <Link className={classes.textDecNone} to={{ pathname: "/app/product_services/services/addservicesandpackages", state: { data: data } }}>
                            <Button
                                variant="contained"
                                color="default"
                                className={classes.button}
                                startIcon={<Icon color="primary">add</Icon>}
                            >Add Service and Package
                            </Button>
                        </Link>
                    </GridItem>


                    <TableContainer className={classes.tcontainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.thead}>
                                <TableRow>
                                <StyledTableCell className={classes.firstHead}>Name</StyledTableCell>
                                    <StyledTableCell>Description</StyledTableCell>
                                    <StyledTableCell align="right" className={classes.lastHead}>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
                                {serviceData && serviceData.length > 0 && serviceData.map((service) => (
                                     service !== null && <TableRow key={service._id}>
                                        <TableCell component="th" className={classes.tcell1}>
                                            <div className={classes.div1}>
                                                <Typography variant="body1" className={classes.blockTitle}>
                                                    {service.name}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left" className={classes.tcell2}>
                                            <div className={classes.div2}>
                                                {service.description}
                                            </div>
                                        </TableCell>
                                        <TableCell align="right" className={classes.tcell3}>
                                            <div className={classes.div3}>
                                                <IconButton aria-label="edit" onClick={() => editService(service)} style={{paddingRight:'5px',paddingLeft:'0px'}}>
                                                <img src={edit} style={{width:'21px', height:'18px',color:'#8E8CAC'}}/>
                                                </IconButton>
                                                <IconButton aria-label="delete" onClick={() => SweetAlertConfirm(service)} style={{paddingRight:'5px',paddingLeft:'0px'}}>
                                                <DeleteIcon style={{width:'21px', height:'18px',color:'#8E8CAC'}}/>
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </GridContainer>
            </Card>
            {alert}
        </>
    )
}

export default ServiceGrid
