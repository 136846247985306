import React from "react";
import { Line } from 'react-chartjs-2';
import Card from "components/Card/Card.js";
import {  withStyles,makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((styles) => ({
    cardStyle:{
        position: "relative",
        height: "30vh",
        width: "100%",
        padding:'10px'
    }
  }));
  

const Chart = (props) => {   
    const classes=useStyles();
    return (
        <Card className={classes.cardStyle} style={{marginTop: 0}}>
        <Line
          data={props.data}
          options={props.options}
        />
      </Card>

    );
}

export default Chart;
