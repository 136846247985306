import React, { useState, useEffect } from 'react';
import AddBadge from './AddBadge'
const EditBadge = (props) => {
    return (
        <>
            <AddBadge {...props} />
         
        </>
    );
}

export default EditBadge
