import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from '@material-ui/core/Button';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, TextField, Grid, Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { userService } from "_services/user.service";
import { categoryService } from "_services/category.service";
import { SPService } from "_services/serviceprovider.service";
import SweetAlert from 'react-bootstrap-sweetalert';



const useStyles = makeStyles((theme) => ({
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    displayFlex: {
        display: "flex"
    },
    DelButton: {
        border: "1px solid grey",
        color: "#6362ca",
        backgroundColor: 'white',
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: 'grey',
            color: 'white',
        },
        "&:focus": {
            backgroundColor: 'grey',
            color: 'White',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    Imginput: {
        display: "none"
    },
    boxStyle: {
        marginTop: "30px",
    },
    root: {
        '& .MuiInput-underline:before': {
            border: '2px solid red',
        },
    },
    selectStyle: {

        border: '1px solid #c4c4c4',
        borderRadius: '5px',
        height: '45px'

    },
    errorSelectStyle: {
        border: '2px solid red',
        borderRadius: '5px',
        height: '45px'

    },
    paddingLeft24: {
        paddingLeft: '24px'
    },
    displayFlexJustifyEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const MenuProps2 = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

const NewAdv = (props) => {
    const classes = useStyles();


    //  functions ************************************************************************************************************************************

    function getToPrevious() {
        // props.history.push(from);
        props.history.goBack();
    }



    function SweetAlertConfirmError(data) {
        const getAlert = () => (
            <SweetAlert
                error
                title="Error"
                onConfirm={() => {
                    setAlertError(null);
                }}
            >
                {data}
            </SweetAlert>
        )
        setAlertError(getAlert());
    }

    function SweetAlertConfirm(data) {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                    if (data == "updated") {
                        setAlert(null)
                    }
                    else if (data == "saved") {
                        setAlert(null);
                        props.history.goBack();
                    }
                    else if (data == "deleted") {
                        setAlert(null);
                        props.history.goBack();
                    }
                }}
            >
                Data {data} successfully
            </SweetAlert>
        )
        setAlert(getAlert());
    }


    const deleteFeatured = (idToDelete) => {

        const obj = {
            id: idToDelete
        }
        SPService.deleteFeaturedSp(obj).then((res) => {
            SweetAlertConfirm('deleted')
        })
    }


    function SweetAlertConfirmDelete(id) {
        const getAlert = () => (id &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => {
                    setAlertDelete(null);
                    deleteFeatured(id);
                }}
                onCancel={() => setAlertDelete(null)}
                focusCancelBtn
            >
                You want to delete this record?
            </SweetAlert>
        )
        setAlertDelete(getAlert());
    }

    


    //  setState declarations ************************************************************************************************************************************

   
    const [errorCheck, setErrorCheck] = React.useState({
        spid: false,
        subcategoryid: false,
        calFrom: false,
        calTo: false
    });

    const [alertError, setAlertError] = React.useState(null);

    const [alert, setAlert] = React.useState(null);

    const [alertDelete, setAlertDelete] = React.useState(null);



    //  useEffect Code  ************************************************************************************************************************************


    React.useEffect(async () => {

    }, [])


    // React.useEffect(() => {

    //     console.log(subcategoryData);
    // }, [subcategoryData])

    return (

        <>
            {
                <div className={classes.container}>
                    <GridContainer style={{ width: "100%" }}>
                        <GridItem xs={12} sm={12} md={8}>
                            <div>
                                <div className={classes.header}>
                                    <IconButton aria-label="edit" onClick={getToPrevious}>
                                        <ArrowBackIcon />
                                    </IconButton>

                                    <h4 className={""} >Add Advertisement</h4>

                                </div>

                            </div>
                        </GridItem>
                    </GridContainer>

                    {
                        <form style={{ padding: '0px 20px', maxWidth: 1200 }}>
                            <Card >
                                <CardBody className="px-0 pb-0"></CardBody>
                                <div style={{ padding: '0px 30px' }}>
                                    <GridContainer style={{marginBottom:'30px'}} >
                                        <GridItem xs={12} sm={12} md={6} className={classes.boxStyle}>

                                            <Box className={classes.boxStyle}>
                                                <InputLabel htmlFor="screenName">Screen Name </InputLabel>
                                                <Select
                                                    disableUnderline={true}
                                                    className={`${classes.paddingLeft24} ${errorCheck.subcategoryid == true ? classes.errorSelectStyle : classes.selectStyle}`}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="screenName"
                                                    fullWidth={true}
                                                    value={''}
                                                    onChange={() => { }}
                                                    MenuProps={MenuProps}
                                                    input={<Input
                                                    // classes={{
                                                    //     underline:errorCheck.spid==true ? classes.underline:'',
                                                    //   }} 
                                                    />} >
                                                    {/* <MenuItem value={''}>Select SubCategory </MenuItem> */}
                                                    <MenuItem value={'homePage'}>HomePage</MenuItem>
                                                </Select>
                                            </Box>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={6} style={{ marginBottom: '20px' }}>
                                            <Box className={classes.boxStyle}>
                                                <InputLabel htmlFor="location"> Location </InputLabel>
                                                <Select
                                                    disableUnderline={true}
                                                    className={`${classes.paddingLeft24}  ${errorCheck.subcategoryid == true ? classes.errorSelectStyle : classes.selectStyle} `}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="location"
                                                    fullWidth={true}
                                                    value={''}
                                                    onChange={()=>{}}
                                                    MenuProps={MenuProps2}
                                                    input={<Input
                                                    // classes={{
                                                    //     underline:errorCheck.subcategoryid==true ? classes.underline:'',
                                                    //   }} 
                                                    />} >

                                                    <MenuItem value={'banner1'}>Banner 1</MenuItem>
                                                    {/* <MenuItem value={'2-3'}>2-3years</MenuItem>
                                                    <MenuItem value={'3-4'}>3-4years</MenuItem>
                                                    <MenuItem value={'4-5'}>4-5years</MenuItem>
                                                    <MenuItem value={'5-6'}>5-6years</MenuItem> */}
                                                </Select>
                                            </Box>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                    <Box>
                                        <InputLabel htmlFor="icon">Add Code</InputLabel>
                                        <Select
                                                    disableUnderline={true}
                                                    className={`${classes.paddingLeft24} ${errorCheck.subcategoryid == true ? classes.errorSelectStyle : classes.selectStyle}`}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="screenName"
                                                    fullWidth={true}
                                                    value={''}
                                                    onChange={() => { }}
                                                    MenuProps={MenuProps}
                                                    input={<Input
                                                    // classes={{
                                                    //     underline:errorCheck.spid==true ? classes.underline:'',
                                                    //   }} 
                                                    />} >
                                                    {/* <MenuItem value={''}>Select SubCategory </MenuItem> */}
                                                    <MenuItem value={'hdhdhdh'}>AdCode1</MenuItem>
                                                </Select>
                                       
                                    </Box>

                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} >
                                    <Box>
                                        <InputLabel htmlFor="landingUrl">Landing URL</InputLabel>
                                        <TextField
                                            // label="Working At (Optional)"
                                            variant="outlined"
                                            placeholder="#000000"
                                            fullWidth={true}
                                            size="small"
                                            name="landingUrl"
                                            value={''}
                                            onChange={()=>{}} 
                                            onBlur={()=>{}}
                                            />
                                    </Box>
                                </GridItem>
                                    </GridContainer>
                                </div>
                            </Card>
                        </form>
                    }
                    <GridContainer style={{ padding: "0 20px" }}>
                        <Grid item style={{ width: '1000px' }}>


                            <div className={classes.displayFlex} style={{ justifyContent: 'space-between' }}>
                                <div >
                                    <Button className={classes.showBtn} variant="contained" color="primary" onClick={() => { }}>
                                        Save
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button className={classes.DelButton} variant="contained" onClick={() => SweetAlertConfirmDelete(props.location.state.uniqueIdVerify)}>
                                        Delete
                                    </Button>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button className={`${classes.DelButton}`} variant="contained" onClick={() => props.history.goBack()}>
                                        + Add MORE
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    </GridContainer>
                </div>
            }
            <>
                {alertError}
            </>
            <>
                {alert}
            </>
            <>
                {alertDelete}
            </>

        </>


    );
}

export default NewAdv;
