import {
  Avatar,
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Typography,
  Box,
  Paper,
  TextField,
} from "@material-ui/core";
import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Image } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import SweetAlert from "react-bootstrap-sweetalert";
import { userService } from "_services/user.service";
// import blueTick from "../../../assets/img/blueTick.png";
import { renderText } from "../common/DisplayComponent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ApplyBackDrop from "../common/ApplyBackDrop";

const useStyles = makeStyles((theme) => ({
  colorDark: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
  button: {
    margin: theme.spacing(1),
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8cac",
    "&:hover": {
      background: "#efefef",
      boxShadow: "unset",
      textDecoration: "none",
      // color: '#ffffff',
    },
  },
  formControl: {
    minWidth: "100%",
    marginBottom: 15,
  },
  h5: {
    color: "#25233C",
    fontSize: 18,
  },
  selectadj: {
    border: "1px solid #B9B8C4",
    height: 40,
    padding: 15,
    borderRadius: 10,
  },
  formContainer: {
    width: "100%",
    height: "98vh",
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    minHeight: "350px",
    padding: "16px",
    // margin:"20px 0"
  },
  boxStyle: {
    marginTop: "30px",
  },
  buttonStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    width: "100%",
  },
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid red",
      borderRadius: 10,
    },
  },
  errorSelectStyle: {
    border: "2px solid red",
    borderRadius: "10px",
    height: "45px",
  },
  buttonStyleNextPrevious: {
    backgroundColor: "#DB0D0D",
    color: "white",
    fontWeight: "bold",
    borderRadius: "10px",
    border: "1px solid #DB0D0D",
    "&:hover": {
      background: "#fff",
      border: "1px solid #DB0D0D",
      color: "#DB0D0D",
    },
  },
  minPaymentSection: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    marginTop: "10px",
    "@media (max-width:1390px)": {
      width: "75%",
    },
  },
  checkedCheckBox: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#005cb2",
    },
  },

  customTextField: {
    whiteSpace: "nowrap",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 10,
    },
    "@media (max-width:430px)": {
      "& input::placeholder": {
        fontSize: "16px",
      },
      "& .MuiInputLabel-outlined": {
        fontSize: "16px",
      },
    },
  },
  roots: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 10,
    },
  },
}));

const Step1 = ({
  about,
  handleOnChangeAbout,
  workingAt,
  handleOnChangeWorkingAt,
  experience,
  handleOnChangeSelect,
  certificate,
  handleOnChangeCertificate,
  teamSize,
  handleOnChangeSelectTeam,
  locationAvaliable,
  handleOnChangeLocationAvailable,
  checkedCustom,
  handleCheckBoxCustomRequest,
  handleNext,
  handlePrev,
  errorCheckAboutPage,
  handleOnChangeLicenseNo,
  licenseNo,
  apiCall,
}) => {
  const classes = useStyles();

  return (
    <div id="marginInline">
      <Box>{renderText({ label: "About" })}</Box>

      <Box className={classes.boxStyle}>
        <TextField
          // className={errorCheckAboutPage.about == true ? `${classes.root}` : ''}
          className={
            errorCheckAboutPage.about == true
              ? `${classes.root}`
              : `${classes.roots}`
          }
          label="About Yourself*"
          id="standard-multiline-static"
          multiline
          rows={3}
          placeholder="About Yourself*"
          variant="outlined"
          fullWidth={true}
          size="small"
          name="about"
          value={about}
          onChange={handleOnChangeAbout}
          onBlur={handleOnChangeAbout}
          // InputLabelProps={{ shrink: true }}
        />
      </Box>
      {errorCheckAboutPage.about == true && (
        <p style={{ color: "red", fontSize: "12px" }}>
          {" "}
          * This Field is Required
        </p>
      )}

      <Box className={classes.boxStyle}>
        <TextField
          label="Working At (Optional)"
          variant="outlined"
          placeholder="Working At (optional)"
          fullWidth={true}
          size="small"
          name="workingAt"
          value={workingAt}
          onChange={handleOnChangeWorkingAt}
          className={classes.roots}
        />
      </Box>
      {errorCheckAboutPage.licenseNo == true && (
        <p style={{ color: "red", fontSize: "12px" }}>
          {" "}
          * This Field is Required
        </p>
      )}

      <Box className={classes.boxStyle}>
        <InputLabel htmlFor="name-multiple">Years of Experience*</InputLabel>
        <Select
          disableUnderline
          className={
            errorCheckAboutPage.experience == true
              ? `${classes.errorSelectStyle}`
              : `${classes.selectadj}`
          }
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="experience"
          fullWidth={true}
          value={experience}
          displayEmpty
          // renderValue={(value) => value ? value :"Select*"}
          onChange={handleOnChangeSelect}
          input={<Input />}
          style={{ backgroundColor: "#FFF" }}
        >
          <MenuItem value={"0-2"}>0-2 years</MenuItem>
          <MenuItem value={"2-5"}>2-5 years</MenuItem>
          <MenuItem value={"5+"}>5+ years</MenuItem>
        </Select>
      </Box>
      {errorCheckAboutPage.experience == true && (
        <p style={{ color: "red", fontSize: "12px" }}>
          {" "}
          * This Field is Required
        </p>
      )}
      <Box className={classes.boxStyle}>
        <TextField
          className={classes.customTextField}
          label="Certifications / License Number (optional)"
          variant="outlined"
          fullWidth={true}
          size="small"
          placeholder="Certifications / License Number (optional)"
          name="workingAt"
          value={certificate}
          onChange={handleOnChangeCertificate}
        />
      </Box>

      <Box className={classes.boxStyle}>
        <TextField
          label="Team Size (optional)"
          variant="outlined"
          placeholder="Team Size (optional)"
          fullWidth={true}
          type="number"
          size="small"
          name="teamSize"
          value={teamSize}
          onChange={handleOnChangeSelectTeam}
          className={classes.roots}
        />
      </Box>

      {/* <Box className={classes.boxStyle}>
                    <TextField
                    label=" Available In (Zip Code)"
                    className={errorCheckAboutPage.locationAvaliable == true ? `${classes.root}` : ''}
                    variant="outlined"
                    fullWidth={true}
                    size="small"
                    type="number"
                    placeholder=" Available In (Enter Cities/Zip Codes)"
                    name="workingAt"
                    value={locationAvaliable.text}
                    onChange={handleOnChangeLocationAvailable} 
                    onBlur={handleOnChangeLocationAvailable}
                    />
                    </Box> */}
      <Box>
        <FormControlLabel
          className={`${classes.checkedCheckBox} ${
            checkedCustom && classes.colorDark
          }`}
          control={
            <Checkbox
              checked={checkedCustom}
              onChange={handleCheckBoxCustomRequest}
            />
          }
          label="Open to custom request"
        />
        {/* 797579 */}
        {/* 9D9696 */}
      </Box>
      <Box
        className={classes.minPaymentSection}
        style={{ textAlign: "center", width: "100%" }}
      >
        <Box
          className={classes.aboutScreen}
          style={{
            textAlign: "left",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "16px",
          }}
        >
          Please select this option if you would like to receive custom requests
          from customers. If a customer in your service area has a special
          request related to your service category, you will receive the
          message. You can then connect with the customer over chat. Custom
          requests are chargable at 1 credit/request for receiving a request.
          This may not necessarily convert into a booking. You can turn this
          option on/off at any time.{" "}
        </Box>
      </Box>

      <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
        <Box className={classes.buttonStyle}>
          {window.location.href.split("/")[
            window.location.href.split("/").length - 1
          ] !== "newSp" && (
            <Button
              variant="contained"
              className={classes.buttonStyleNextPrevious}
              onClick={handlePrev}
            >
              Prev
            </Button>
          )}
          <Button
            variant="contained"
            className={classes.buttonStyleNextPrevious}
            onClick={handleNext}
            style={{ marginLeft: "10px" }}
          >
            {window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ] == "newSp"
              ? "Next"
              : "Save"}
          </Button>
          {<ApplyBackDrop apiCall={apiCall} />}
        </Box>
      </Grid>
    </div>
  );
};

export default Step1;
