/* eslint-disable prettier/prettier */
import { BehaviorSubject } from 'rxjs';

import { handleResponse } from "_helpers/handle-response.js";

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));

export const configurationService = {
    getConfiguration,
    postConfiguration,
    getConfigurationKeyWise,
    currentUser: currentUserSubject.asObservable(),
    getProfessions,
    get currentUserValue() { return currentUserSubject.value }
};

function getConfiguration() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/UsersController/Configuration`, requestOptions).then(handleResponse);
}


function getProfessions() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'configKey':'professionValue'
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/UsersController/Configuration`, requestOptions).then(handleResponse);
}


function getConfigurationKeyWise(key) {
    var myHeaders= new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("configKey",key);
    const requestOptions = {
        method: 'GET',
        headers: myHeaders
    }
    return fetch(`${process.env.REACT_APP_URL}/UsersController/Configuration`, requestOptions).then(handleResponse);
}

function postConfiguration(data) {
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(sessionStorage.getItem('currentUser')).token);
    myHeaders.append("Content-Type", "application/json");
    

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_URL}/UsersController/Configuration`, requestOptions).then(handleResponse);
}
