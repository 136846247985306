import React, { useState, useEffect } from 'react';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from '@material-ui/core/Button';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { OutlinedInput, useMediaQuery } from '@material-ui/core';
import { IconButton, TextField, Grid, Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { servicesandpackagesService, getPackageImages } from "_services/servicesandpackages.service";
import { categoryService } from "_services/category.service";
import { SPService } from "_services/serviceprovider.service";
import SweetAlert from 'react-bootstrap-sweetalert';
import Checkbox from "@material-ui/core/Checkbox";
import { ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useRouteMatch
} from "react-router-dom";
import { array } from 'prop-types';


const customStyles = {
    btn:{
      padding: "15px",
      borderRadius: "10px",
      width: "100%",
      border: "1px solid #B9B8C4",
      height: "50px",
      backgroundColor: "transparent",
      cursor: "pointer" 
    },
    imageGrid:{
      display: "grid",
      gap:"10px",
      gridTemplateColumns: "repeat(auto-fill, minmax(75px, 1fr))",
    },
    img:{
      width: "100%",
      height: "75px",
      aspectRatio: "1/1",
      objectFit: "cover",
      cursor:"pointer"
    },
    removeIcon:{
      color:"#DB0D0D",
      position: "relative",
      top: "-30px", 
      right: "15px", 
      cursor: "pointer"}
}  
  
const useStyles = makeStyles((theme) => ({
    cameraIcon:{
        color: "rgba(0,0,0,0.54)"
    },
    h5: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: 16,
        fontWeight: "normal"
    },
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    displayFlex: {
        display: "flex"
    },
    DelButton: {
        border: "1px solid grey",
        color: "#6362ca",
        backgroundColor: 'white',
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: 'grey',
            color: 'white',
        },
        "&:focus": {
            backgroundColor: 'grey',
            color: 'White',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    container: {
        // border:"1px solid grey",
        // margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    Imginput: {
        display: "none"
    },
    boxStyle: {
        marginTop: "30px",
    },
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid red'
        }
    },
    heightOfMultiSelect: {
        '& .MuiOutlinedInput-input': {
            padding: '13.5px 14px'
        }
    },
    selectStyle: {

        border: '1px solid #c4c4c4',
        borderRadius: '5px',
        height: '45px'

    },
    errorSelectStyle: {
        border: '2px solid red',
        borderRadius: '5px',
        height: '45px'

    },
    paddingLeft24: {
        paddingLeft: '24px'
    },
    displayFlexJustifyEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    widthDialog: {
        '& .MuiDialog-paperWidthSm': {
          minWidth: '450px',
          '@media (max-width:600px)': {
            minWidth: '300px',
          },
        },
      },
      imgStyle: {
        width: '90px',
        height: '90px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        marginRight: '20px',
        borderRadius: '10px',
      },
}));



const AddPackage = (props) => {
    const classes = useStyles();
    const [openPicker, setOpenPicker] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState({imgUrl: null});
    const [packageImages, setPackageImages] = React.useState([]);

    React.useEffect(()=>{
        fetchPackageImages();
      }, []);
    
      const fetchPackageImages = async () => {
          const res =  await getPackageImages();
    
          if(res.ok){
              setPackageImages(...packageImages, res.data)
          }
      }

    const imageSelectionHandler = (url) => {
        setSelectedImage({imgUrl: url})
        handleClosePicker();
    }

      // Images grid component
    const Images = () => {
        return(
        <div style={customStyles.imageGrid}>
            {
            packageImages?.map((image, idx) => <img style={customStyles.img} key={idx} src={image} onClick={() => imageSelectionHandler(image)} />)
            }
        </div>
        )
    }

    const handleOpenPicker = () => {
        setOpenPicker(true);
    }
    
    const handleClosePicker = () => {
        setOpenPicker(false);
    }

    const { slug } = useParams();
    //  functions ************************************************************************************************************************************

    function getToPrevious() {
        // const { from } = { from: { pathname: "/app/product_services/featuredListing" } };
        // props.history.push(from);
        props.history.goBack();
    }


    function SweetAlertConfirmError(data) {
        const getAlert = () => (
            <SweetAlert
                error
                title="Error"
                onConfirm={() => {
                    setAlertError(null);
                }}
            >
                {data}
            </SweetAlert>
        )
        setAlertError(getAlert());
    }

    function SweetAlertConfirm(data) {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                    if (data == "updated") {
                        setAlert(null);
                        props.history.goBack();
                    }
                    else if (data == "saved") {
                        setAlert(null);
                        props.history.goBack();
                    }
                    else if (data == "deleted") {
                        setAlert(null);
                        props.history.goBack();
                    }
                }}
            >
                Data {data} successfully
            </SweetAlert>
        )
        setAlert(getAlert());
    }


    const deleteFeatured = (idToDelete) => {

        const obj = {
            id: idToDelete
        }
        SPService.deleteFeaturedSp(obj).then((res) => {
            SweetAlertConfirm('deleted')
        })
    }


    // const getTotal=()=>{
    //    let total = totalServicesOfSp.map(service => {
    //         if (packageId.includes(service._id)) {
    //             setPriceTotal(priceTotal + parseInt(service.normal_price))
    //             return priceTotal + parseInt(service.normal_price)
    //         }
    //         else{
    //              setPriceTotal(0)
    //              return 0
    //         }
    //     })
    //     return total
    // }

    function SweetAlertConfirmDelete(id) {
        const getAlert = () => (id &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => {
                    setAlertDelete(null);
                    deleteFeatured(id);
                }}
                onCancel={() => setAlertDelete(null)}
                focusCancelBtn
            >
                You want to delete this record?
              </SweetAlert>
        )
        setAlertDelete(getAlert());
    }


    const handleTextData = (event) => {
        setAddServiceData({ ...addServiceData, [event.target.name]: event.target.value })

        if (event.target.value.length == 0) {
            setErrorCheck(prevState => {
                return {
                    ...prevState,
                    [event.target.name]: true
                }
            })
        }
        else {
            setErrorCheck(prevState => {
                return {
                    ...prevState,
                    [event.target.name]: false
                }
            })
        }
    }


    const saveService = (btnType) => {

        if (addServiceData.title == undefined || addServiceData.title.length == 0) {
            setErrorCheck({ title: true, categoryId: false, subcategoryId: false, servicesId: false, price: false, duration: false })
        }

        // else if (addServiceData.categoryId == undefined || addServiceData.categoryId.length == 0) {
        //     setErrorCheck({ categoryId: true, subcategoryId: false, servicesId: false, price: false, duration: false })
        // }
        // else if (addServiceData.subCategoryId == undefined || addServiceData.subCategoryId.length == 0) {
        //     setErrorCheck({ categoryId: false, subcategoryId: true, servicesId: false, price: false, duration: false })
        // }
        else if (serviceId.length == 0) {
            setErrorCheck({ categoryId: false, subcategoryId: false, servicesId: true, price: false, duration: false })
        }
        else if (addServiceData.price == undefined || addServiceData.price.length == 0) {
            setErrorCheck({ categoryId: false, subcategoryId: false, servicesId: false, price: true, duration: false })
        }
        else if (addServiceData.duration == undefined || addServiceData.duration.length == 0) {
            setErrorCheck({ categoryId: false, subcategoryId: false, servicesId: false, price: false, duration: true })
        }
        else {

            if (btnType == 'save') {
                let catArray = [];
                let subCatArr = [];
                packageId.map((prop) => {
                    totalServicesOfSp.map((service) => {
                        if (prop == service._id) {
                            catArray.push(service.category_id)
                            subCatArr.push(service.subcategory_id);
                        }
                    })
                })

                const obj = {
                    normal_price: addServiceData.price,
                    sp_id: slug,
                    category_id: [...new Set(catArray)],
                    sub_category_id: [...new Set(subCatArr)],
                    service_id: serviceId,
                    packages_id: packageId,
                    title: addServiceData.title,
                    duration: addServiceData.duration,
                    description: addServiceData.description ? addServiceData.description : '',
                    image_url: selectedImage.imgUrl
                }

                // console.log(obj);


                servicesandpackagesService.postComboPackage(obj).then((res) => {
                    if (res.error) {
                        setSelectedImage({imgUrl: null})
                        SweetAlertConfirmError(res.error);
                    }
                    else {
                        setSelectedImage({imgUrl: null})
                        SweetAlertConfirm('saved');
                    }
                })
            }

            if (btnType == 'update') {
                let catArray = [];
                let subCatArr = [];
                packageId.map((prop) => {
                    totalServicesOfSp.map((service) => {
                        if (prop == service._id) {
                            catArray.push(service.category_id)
                            subCatArr.push(service.subcategory_id);
                        }
                    })
                })

                const obj = {
                    id: props.location.state.id,
                    normal_price: addServiceData.price,
                    sp_id: slug,
                    category_id: [...new Set(catArray)],
                    sub_category_id: [...new Set(subCatArr)],
                    service_id: serviceId,
                    packages_id: packageId,
                    title: addServiceData.title,
                    duration: addServiceData.duration,
                    description: addServiceData.description ? addServiceData.description : '',
                    image_url: selectedImage.imgUrl
                }

                servicesandpackagesService.updateComboPackage(obj).then((res) => {
                    if (res.error) {
                        setSelectedImage({imgUrl: null})
                        SweetAlertConfirmError(res.error);
                    }
                    else {
                        setSelectedImage({imgUrl: null})
                        SweetAlertConfirm('updated');
                    }
                })
            }

        }
    }

    const removeService = (id, servicesId) => {
        let arrayForRemovePackage = [...packageId];
        let arrayForRemoveService = [...serviceId];
        let tempForPackageId = arrayForRemovePackage.filter(function (item) {
            return item !== id
        })
        let tempForServiceId = arrayForRemoveService.filter(function (item) {
            return item !== servicesId
        })
        //console.log(tempForPackageId);
        setPackageId([...tempForPackageId]);
        setserviceId([...tempForServiceId]);
    }

    //  setState declarations ************************************************************************************************************************************

    const [errorCheck, setErrorCheck] = React.useState({
        title: false,
        categoryId: false,
        subcategoryId: false,
        servicesId: false,
        price: false,
        duration: false
    });

    const [categoryArray, setCategoryArray] = React.useState([]);
    const [subcategoryArray, setSubCategoryArray] = React.useState([]);
    const [servicesArray, setServicesArray] = React.useState([]);
    const [alertError, setAlertError] = React.useState(null);
    const [alert, setAlert] = React.useState(null);
    const [alertDelete, setAlertDelete] = React.useState(null);
    const [addServiceData, setAddServiceData] = React.useState({
        title: '',
        categoryId: '',
        subCategoryId: '',
        serviceId: '',
        price: '',
        duration: '',
        description: ''
    })
    const [totalServicesOfSp, setTotalServicesOfSp] = React.useState([]);
    const [serviceId, setserviceId] = React.useState([]);
    const [serviceNameDisp, setserviceNameDisp] = React.useState([]);
    const [packageId, setPackageId] = React.useState([]);
    const [priceTotal, setPriceTotal] = React.useState(0);
    const [apiCallForEdit, setApiCallForEdit] = React.useState(false);



    //  useEffect Code  ************************************************************************************************************************************


    React.useEffect(() => {
        const getCategoryPackageOnSpBased=async()=>{
            setApiCallForEdit(true);
            // const response = await categoryService.getCategory();
             // console.log(slug);
             try{
             const responseOfCategoryAvailableForSp = await servicesandpackagesService.getCategoryOrSubcategoryBasedOnSpService({ serviceprovider_id: slug, value_by: 'category' });
             let categoryArr = responseOfCategoryAvailableForSp.category[0].map((prop, index) => {
                 return {
                     'id': prop._id,
                     'name': prop.name
                 }
             })
             const responseForServicesAvailableForServiceProvider = await servicesandpackagesService.getPackagesbyServiceprovider(slug);
             // console.log(responseForServicesAvailableForServiceProvider);
             setTotalServicesOfSp([...responseForServicesAvailableForServiceProvider]);
             setCategoryArray([...categoryArr]);
             const responseOfSubCategoryAvailableForSp = await servicesandpackagesService.getCategoryOrSubcategoryBasedOnSpService({ serviceprovider_id: slug, value_by: 'subcategory', category_id: props.location.state.categoryId[0]});
             // const responseForSubCategoryData = await categoryService.getSubCategorybyId2(event.target.value);
             let subcategoryArr = responseOfSubCategoryAvailableForSp.subcategory[0].map((prop, index) => {
                 return {
                     'id': prop._id,
                     'name': prop.name
                 }
             })
             setSubCategoryArray([...subcategoryArr]);
             setApiCallForEdit(false);
            }
            catch(err){
                setApiCallForEdit(false);
            }
        }
        getCategoryPackageOnSpBased()

    }, [])




    React.useEffect(() => {
        if (categoryArray.length > 0 && window.location.href.split('/')[window.location.href.split('/').length - 2] == 'editPackage') {
            setApiCallForEdit(true);

            setPackageId([...props.location.state.packages])
            setserviceId([...props.location.state.services])
            setApiCallForEdit(false);
            setAddServiceData(prevState => {
                            return {
                                ...prevState,
                                title: props.location.state.title,
                                duration: props.location.state.duration,
                                price: props.location.state.normal_price,   
                                categoryId: props.location.state.categoryId,
                                subCategoryId: props.location.state.subCategoryId,
                                description: props.location.state.description ? props.location.state.description : ''
                            }
                        })
             }
        //     if (props.location?.state?.categoryId) {
        //         setAddServiceData(prevState => {
        //             return {
        //                 ...prevState,
        //                 categoryId: props.location.state.categoryId,
        //                 title: props.location.state.title,
        //                 duration: props.location.state.duration,
        //                 price: props.location.state.normal_price,
        //                 description: props.location.description ? props.location.description : ''
        //             }
        //         })

        //         const responseOfSubCategoryAvailableForSp = await servicesandpackagesService.getCategoryOrSubcategoryBasedOnSpService({ serviceprovider_id: slug, value_by: 'subcategory', category_id: props.location.state.categoryId });
        //         let subcategoryArr = responseOfSubCategoryAvailableForSp.subcategory[0].map((prop, index) => {
        //             return {
        //                 'id': prop._id,
        //                 'name': prop.name
        //             }
        //         })
        //         setSubCategoryArray([...subcategoryArr]);

        //         setAddServiceData(prevState => {
        //             return {
        //                 ...prevState,
        //                 subCategoryId: props.location.state.subCategoryId
        //             }
        //         })
        //         const responseForServiceBasedOnSpAndSubcategory = await servicesandpackagesService.getServicesByServiceProviderAndSubcategory({ serviceprovider_id: slug, subcategory_id: props.location.state.subCategoryId });
        //         let servicesArr = responseForServiceBasedOnSpAndSubcategory.map((prop, index) => {
        //             return {
        //                 'id': prop.services_id,
        //                 'name': prop.title,
        //                 'pid': prop._id,
        //                 'price': prop.normal_price
        //             }
        //         })
        //         setServicesArray([...servicesArr])
        //         setPackageId([...props.location.state.packages]);
        //         setserviceNameDisp([]);
        //         // setPriceTotal(0);
        //         setserviceId([...props.location.state.services]);
        //         setApiCallForEdit(false);
        //     }
        // }

    }, [categoryArray])


    // function when Category changes ****************************************************************************

    const onChangeSelectCategory = async (event) => {
        setErrorCheck(prevState => {
            return {
                categoryId: false,
                subcategoryId: false,
                servicesId: false,
                price: false,
                duration: false
            }
        })
        setAddServiceData({ ...addServiceData, categoryId: event.target.value, subCategoryId: '', serviceId: '' });
        // setPackageId([]);
        setserviceNameDisp([]);
        // setPriceTotal(0);
        // setserviceId([]);
        setServicesArray([]);
        try{
        const responseOfSubCategoryAvailableForSp = await servicesandpackagesService.getCategoryOrSubcategoryBasedOnSpService({ serviceprovider_id: slug, value_by: 'subcategory', category_id: event.target.value });
        //  console.log(responseOfSubCategoryAvailableForSp.subcategory);
        // const responseForSubCategoryData = await categoryService.getSubCategorybyId2(event.target.value);
        let subcategoryArr = responseOfSubCategoryAvailableForSp.subcategory[0].map((prop, index) => {
            return {
                'id': prop._id,
                'name': prop.name
            }
        })
        setSubCategoryArray([...subcategoryArr]);
    }
    catch(err){
        
    }
    }


    // function when subCategory changes 

    const onChangeSelectSubCategory = async (event) => {
        setErrorCheck(prevState => {
            return {
                categoryId: false,
                subcategoryId: false,
                servicesId: false,
                price: false,
                duration: false
            }
        })
        setAddServiceData({ ...addServiceData, subCategoryId: event.target.value, serviceId: '' });
        setserviceNameDisp([]);
        const responseForServiceBasedOnSpAndSubcategory = await servicesandpackagesService.getServicesByServiceProviderAndSubcategory({ serviceprovider_id: slug, subcategory_id: event.target.value });
        let servicesArr = responseForServiceBasedOnSpAndSubcategory.map((prop, index) => {
            return {
                'id': prop.services_id,
                'name': prop.title,
                'pid': prop._id,
                'price': prop.normal_price
            }
        })
        setServicesArray([...servicesArr])
    }


    // *****************************************************************************************************************************************************************

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    // function for handle change multi select

    const handleChangeMulti = (event) => {
        let clickedMultiData = event.target.value;
        let value = [...new Set([...clickedMultiData, ...serviceId])];
        // value = event.target.value;
        setserviceId(
            typeof value === 'string' ? value : value,
        );
    };

    React.useEffect(() => {
        if (serviceId.length > 0) {
            setErrorCheck({ ...errorCheck, servicesId: false })
            let array = [];
            let arrayPid = [];
            let arrid = [];
            servicesArray.map((prop, index) => {
                if (serviceId.includes(prop.id)) {
                    array.push(prop.name);
                    arrayPid.push(prop.pid);
                    arrid.push(prop.id);
                }
                else {
                    return
                }
            });
            setPackageId([...new Set([...packageId, ...arrayPid])]);
            setserviceNameDisp([...array]);
        }
        else {
            setPackageId([...packageId]);
            setserviceNameDisp([]);
            // setPriceTotal(0);
        }
    }, [serviceId])


        // const checkboxValue=(name)=>
        // {

        //     console.log(name,"nameValue")
        // }
    // useEffect for calculating price

    React.useEffect(()=>{
     let tempArray=[...packageId]
      let price=0;
        if(packageId.length > 0){
            totalServicesOfSp.map(service => {
                if (tempArray.includes(service._id)) {
                    price=price + parseInt(service.normal_price)
                    setPriceTotal(price)
                }
                else{
                }
            })
        }
    },[packageId])

    // get editpackage image
    React.useEffect(async ()=>{
        const ids = [...packageId];
        if(packageId.length > 0){
            const responses = await servicesandpackagesService.getComboPackagesBySp(slug);
            if(responses.length > 0){
                responses.map((res)=>{
                    if(res.package_id?.includes(ids[0])){
                        setSelectedImage({imgUrl: res.image_url});
                    }
            })
            }
        }else{
            setSelectedImage({imgUrl: null})
        }
        
    },[packageId])

    const isSm = useMediaQuery("@media(max-width: 480px)");
    return (
        <>
            {
                <div className={classes.container}>
                    {
                        <form style={{ padding: '0px'}}>
                            <Card style={{marginTop: 0}}>
                                <GridContainer style={{ width: "100%" }}>
                                    <GridItem xs={12} sm={12} md={8}>
                                        <div>
                                            <div className={classes.header}>
                                                <IconButton aria-label="edit" onClick={getToPrevious}>
                                                    <ArrowBackIcon />
                                                </IconButton>
                                                {window.location.href.split('/')[window.location.href.split('/').length - 2] !== 'editPackage' && <h5 style={{marginBottom:'14px'}}>Add Package</h5>}
                                                {window.location.href.split('/')[window.location.href.split('/').length - 2] == 'editPackage' && <h5 style={{marginBottom:'14px'}}>Edit Package</h5>}
                                            </div>

                                        </div>
                                    </GridItem>
                                </GridContainer>
                                <CardBody className="px-0 pb-0"></CardBody>
                                {
                                    apiCallForEdit == false && categoryArray.length > 0 &&
                                    <div style={{ padding: '0px 10px' }}>
                                        <GridContainer >

                                            <GridItem xs={12} sm={12} md={6}>
                                                <Box className={classes.boxStyle}>
                                                    <InputLabel htmlFor="title">Title *</InputLabel>
                                                    <TextField
                                                        // label="Working At (Optional)"
                                                        className={errorCheck.title == true ? classes.root : ''}
                                                        variant="outlined"
                                                        placeholder="Enter Title For Package"
                                                        fullWidth={true}
                                                        size="small"
                                                        name="title"
                                                        value={addServiceData.title}
                                                        onChange={handleTextData} />
                                                </Box>
                                                {
                                                    errorCheck.title == true &&
                                                    <p style={{ color: 'red', marginBottom: 0 }}>
                                                        * This Field is required
                                            </p>
                                                }
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} className={classes.boxStyle}>

                                                <Box className={classes.boxStyle} >
                                                    <InputLabel htmlFor="category">Select Category *</InputLabel>
                                                    <Select
                                                        required
                                                        disableUnderline={true}
                                                        className={`${classes.paddingLeft24} ${errorCheck.categoryId == true ? classes.errorSelectStyle : classes.selectStyle}`}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        name="category"
                                                        fullWidth={true}
                                                        value={addServiceData.categoryId}
                                                        onChange={onChangeSelectCategory}
                                                        input={<Input
                                                        // classes={{
                                                        //     underline:errorCheck.spid==true ? classes.underline:'',
                                                        //   }} 
                                                        />} >
                                                        {categoryArray.length == 0 && <MenuItem value={''}>No Category added *</MenuItem>}
                                                        {categoryArray.length > 0 &&
                                                            categoryArray.map((val, index) => (
                                                                <MenuItem key={val.id} value={val.id}>
                                                                    {val.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </Box>
                                                {
                                                    errorCheck.categoryId == true &&
                                                    <p style={{ color: 'red', marginBottom: 0 }}>
                                                        * This Field is required
                                            </p>
                                                }
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} className={classes.boxStyle}>

                                                <Box className={classes.boxStyle}>
                                                    <InputLabel htmlFor="subCategory">Select SubCategory *</InputLabel>
                                                    <Select
                                                        disableUnderline={true}
                                                        className={`${classes.paddingLeft24} ${errorCheck.subcategoryId == true ? classes.errorSelectStyle : classes.selectStyle}`}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        name="subcategoryid"
                                                        fullWidth={true}
                                                        value={addServiceData.subCategoryId}
                                                        onChange={onChangeSelectSubCategory}
                                                        input={<Input
                                                        // classes={{
                                                        //     underline:errorCheck.spid==true ? classes.underline:'',
                                                        //   }} 
                                                        />} >
                                                        {addServiceData.categoryId == '' && <MenuItem value={''}>Select Category First </MenuItem>}
                                                        {addServiceData.categoryId !== '' && subcategoryArray.length == 0 && <MenuItem value={''}>No SubCategory Found </MenuItem>}
                                                        {subcategoryArray.length > 0 &&
                                                            subcategoryArray.map((val, index) => (
                                                                <MenuItem key={val.id} value={val.id}>
                                                                    {val.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </Box>
                                                {
                                                    errorCheck.subcategoryId == true &&
                                                    <p style={{ color: 'red', marginBottom: 0 }}>
                                                        * This Field is required
                                            </p>
                                                }
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={6} className={classes.boxStyle}>
                                                <Box className={classes.boxStyle} >
                                                    <InputLabel htmlFor="subCategory">Select Service *</InputLabel>
                                                    <Select
                                                        fullWidth={true}
                                                        className={`${classes.heightOfMultiSelect} ${errorCheck.servicesId == true ? classes.errorSelectStyle : classes.selectStyle}`}
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={serviceId}
                                                        onChange={handleChangeMulti}
                                                        input={<OutlinedInput/>}
                                                        MenuProps={MenuProps}
                                                        renderValue={() => serviceNameDisp.join(', ')}
                                                    >
                                                        {addServiceData.subCategoryId == '' && <MenuItem value={''}>Select SubCategory First </MenuItem>}
                                                        {addServiceData.subCategoryId !== '' && servicesArray.length == 0 && <MenuItem value={''}>No Service Found </MenuItem>}
                                                        {servicesArray.length > 0 &&
                                                            servicesArray.map((name) => (
                                                                <MenuItem key={name.id} value={name.id}>
                                                                    <Checkbox checked={serviceId.indexOf(name.id) > -1 } 
                                                                    // onChange={checkboxValue(name)}
                                                                    />
                                                                    <ListItemText primary={name.name} />
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </Box>
                                                {
                                                    errorCheck.servicesId == true &&
                                                    <p style={{ color: 'red', marginBottom: 0 }}>
                                                        * This Field is required
                                            </p>
                                                }
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Box className={classes.boxStyle}>
                                                    {packageId.length > 0 &&
                                                        totalServicesOfSp.map(service => {
                                                            if (packageId.includes(service._id)) {
                                                                return <Card key={service._id} style={{ backgroundColor: '#EAEDF7' }}>
                                                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <InputLabel htmlFor="service" style={{ marginTop: '15px', marginLeft: '10px',color:'black' }}>{service.title} </InputLabel>
                                                                            <InputLabel htmlFor="service" style={{ marginTop: '15px', marginLeft: '10px',color:'red' }}>{`$${service.normal_price}`}</InputLabel>
                                                                        </div>
                                                                        <IconButton aria-label="delete" onClick={() => {

                                                                            removeService(service._id, service.services_id)
                                                                        }}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                </Card>
                                                            }


                                                        })

                                                    }
                                                </Box>
                                            </GridItem>
                                            {
                                                packageId.length > 0 && 
                                                <GridItem xs={12} sm={12} md={12}>
                                                <hr></hr>
                                                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <InputLabel htmlFor="service" style={{ marginTop: '15px', marginLeft: '10px',color:'black' }}>Total</InputLabel>
                                                       
                                                    </div>
                                                    <InputLabel htmlFor="service" style={{ marginTop: '15px', marginLeft: '10px',color:'red' }}>{`$${priceTotal}`}</InputLabel>
                                            
                                                </Box>
                                            </GridItem>
                                            }
                                            
                                            {/* this dialogue is for image picker */}
                                            <div>
                                                <Dialog
                                                className={classes.widthDialog}
                                                style={{ maxWidth: 450, margin: 'auto' }}
                                                open={openPicker}
                                                onClose={(e) => handleClosePicker(e)}
                                                aria-labelledby="form-dialog-title"
                                                >
                                                <DialogTitle
                                                    id="form-dialog-title"
                                                    style={{ background: '#F5F5FC', color: '#6B6987' }}
                                                >
                                                    Choose Image
                                                </DialogTitle>
                                                <DialogContent>
                                                    <Images />
                                                    <Button
                                                    onClick={() => handleClosePicker()}
                                                    color="primary"
                                                    variant="outlined"
                                                    className={classes.buttonStyleNextPrevious}
                                                    style={{ padding: '6px 27px', marginBlock: "24px", marginLeft: 0}}
                                                    >
                                                    Cancel
                                                </Button>
                                                </DialogContent>
                                                </Dialog>
                                            </div>


                                            <GridItem xs={12} sm={12} md={6}>
                                                <Box className={classes.boxStyle}>
                                                    <InputLabel htmlFor="price">Price *</InputLabel>
                                                    <TextField
                                                        // label="Working At (Optional)"
                                                        className={errorCheck.price == true ? classes.root : ''}
                                                        variant="outlined"
                                                        placeholder="Enter Price"
                                                        fullWidth={true}
                                                        size="small"
                                                        name="price"
                                                        type="number"
                                                        value={addServiceData.price}
                                                        onChange={handleTextData} />
                                                </Box>
                                                {
                                                    errorCheck.price == true &&
                                                    <p style={{ color: 'red', marginBottom: 0 }}>
                                                        * This Field is required
                                            </p>
                                                }
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} className={classes.boxStyle}>

                                                <Box className={classes.boxStyle} >
                                                    <InputLabel htmlFor="duration">Duration *</InputLabel>
                                                    <Select
                                                        disableUnderline={true}
                                                        className={`${classes.paddingLeft24} ${errorCheck.duration == true ? classes.errorSelectStyle : classes.selectStyle}`}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        name="duration"
                                                        fullWidth={true}
                                                        value={addServiceData.duration}
                                                        onChange={handleTextData}
                                                        input={<Input
                                                        // classes={{
                                                        //     underline:errorCheck.spid==true ? classes.underline:'',
                                                        //   }} 
                                                        />} >
                                            <MenuItem value={'15 min'}>15 min </MenuItem>
                                            <MenuItem value={'30 min'}>30 min </MenuItem>
                                            <MenuItem value={'45 min'}>45 min </MenuItem>
                                            <MenuItem value={'60 min'}>60 min </MenuItem>
                                            <MenuItem value={'1-2 hrs'}>1-2 hrs </MenuItem>
                                            <MenuItem value={'2+ hrs'}>2+ hrs </MenuItem>
                                                    </Select>
                                                </Box>
                                                {
                                                    errorCheck.duration == true &&
                                                    <p style={{ color: 'red', marginBottom: 0 }}>
                                                        * This Field is required
                                            </p>
                                                }
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <Box className={classes.boxStyle}>
                                                    <InputLabel htmlFor="description">Description (optional)</InputLabel>
                                                    <TextField
                                                        // label="Working At (Optional)"
                                                        variant="outlined"
                                                        placeholder="Enter Description"
                                                        fullWidth={true}
                                                        size="small"
                                                        name="description"
                                                        value={addServiceData.description}
                                                        onChange={handleTextData} />
                                                </Box>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Box className={classes.boxStyle} style={{width: "145px"}}>
                                                    <h5 className={classes.h5}>Upload image</h5>
                                                    {
                                                    !selectedImage?.imgUrl &&
                                                    <div style={{marginBottom: "16px"}}>
                                                        <button type='button' style={customStyles.btn} onClick={() => handleOpenPicker()}>
                                                        <PhotoCameraIcon className={classes.cameraIcon}/>
                                                        </button>
                                                    </div>
                                                    }

                                                    {selectedImage?.imgUrl &&
                                                    <div style={{marginTop: "16px", marginBottom: "16px"}}>
                                                        <img src={selectedImage.imgUrl} style={{...customStyles.img, width: "75px"}}/>
                                                        <RemoveCircleIcon style={customStyles.removeIcon} 
                                                        onClick = {() => setSelectedImage({imgUrl: null})}
                                                        />
                                                    </div>
                                                    }
                                                </Box>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Box className={classes.boxStyle} style={{ width: "40%" }}>
                                                    {
                                                        window.location.href.split('/')[window.location.href.split('/').length - 2] !== 'editPackage' &&
                                                        <Button className={classes.showBtn} variant="contained" color="primary" onClick={() => { saveService('save') }}>
                                                            Save
                                            </Button>
                                                    }
                                                    {
                                                        window.location.href.split('/')[window.location.href.split('/').length - 2] == 'editPackage' &&
                                                        <Button className={classes.showBtn} variant="contained" color="primary" onClick={() => { saveService('update') }}>
                                                            Update
                                            </Button>
                                                    }

                                                </Box>
                                            </GridItem>

                                        </GridContainer>
                                    </div>}
                                {apiCallForEdit == true &&
                                    <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </div>
                                }
                            </Card>
                        </form>
                    }
                </div>
            }
            <>
                {alertError}
            </>
            <>
                {alert}
            </>
            <>
                {alertDelete}
            </>

        </>


    );
}

export default AddPackage;
