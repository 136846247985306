import React, { useState, useEffect } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardMedia, Icon, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import { servicesandpackagesService } from "_services/servicesandpackages.service";
import SweetAlert from 'react-bootstrap-sweetalert';
import { categoryService } from '_services/category.service';
import AddService from '../SubComponents/AddService';
import AddPackage from '../SubComponents/AddPackage';
import EditService from '../SubComponents/EditService';
import EditPackage from '../SubComponents/EditPackage';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
import edit from '../../../../edit-svgrepo-com.svg'
const useStyles = makeStyles((theme) => ({
    bold: {
        fontFamily: "Muli-Bold !important",
    },
    cover: {
        border: "1px solid #8E8CAC",
        height: "100%",
        maxWidth: 74,
        minHeight: 74,
        width: "100%",

    },
    cardTitle: {
        margin: "unset",
        padding: "0 20px",
        fontSize: 20,
        color: "#25233C",
        fontWeight: "400"
    },
    cardAction: {
        color: "#000",
    },
    button: {
        margin: theme.spacing(1),
        background: "transparent",
        boxShadow: "unset",
        border: "1px solid #8f8cac",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "transparent",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
    }
    ,
    table: {
        margin: "10px 0",
        background: '#f5f5fc'
    },
    tcontainer: {
        //   margin:"0 -5px",
    },
    thead: {
        padding: 10
    },
    gridCont: {
        paddingTop: "10px",
        margin: 0,
    },
    gridItem1: {
        display: "flex",
        alignItems: "center",
    },
    gridItem2: {
        textAlign: "right",
    },
    tcell1: {
        border: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
        paddingLeft:'0px !important'
    },
    tcell2: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
    },
    tcell2sub: {
        padding: "unset",
        margin: "auto",
        border: "unset",
    },
    tcell3: {
        border: "unset",
        paddingLeft: "unset",
        paddingBottom: "unset",
    },
    div1: {
        display: "flex",
        alignItems: "center",
        background: "#F3F3FC",
        padding: 10,
        minHeight: 50,
        paddingLeft:'0px'
    },
    div2: {
        background: "#F3F3FC",
        padding: 15,
        color: "#6d6b75",
        minHeight: 50,
        display: "flex",
        justifyContent: "space-between",
    },
    div3: {
        background: "#F3F3FC",
        padding: 1,
        // height: 50,
        "& > button": {
            padding: 0
        }
    },
    blockTitle: {
        paddingLeft: 10,
        color: "#25233C"
    },
    mainCard: {
        marginBottom: '2em'
    },
    subcellWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    subCell: {
        padding: 0,
        border: "unset",
        margin: "auto",
    },
    textDecNone: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none"
        },
    },
    imgItem: { border: "1px solid grey", height: 38, width: 38, background: "#fff", alignItems: "center", display: "flex", justifyContent: "center" },

}));


const StyledTableCell = withStyles((theme) => ({
    head: {
        //   backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
        backgroundColor: "#F5F5FC",
        color: "#6c6987",
    },

}))(TableCell);

const MyServices = (props) => {
    const { slug } = useParams();
    const classes = useStyles();
    const [serviceData, setServiceData] = React.useState([]);
    const [packageData, setPackageData] = React.useState([]);
    const [alertDelete, setAlertDelete] = React.useState(null);
    const [alert, setAlert] = React.useState(null);
    const [apiCallForGetServices, setApiCallForGetServices] = React.useState(false);
    const [apiCallForGetPackages, setApiCallForGetPackages] = React.useState(false);


    React.useEffect(() => {
        const getPackageBySp = async () => {
            try {
                setApiCallForGetServices(true);
                const servicesResponse = await servicesandpackagesService.getPackagesbyServiceprovider(slug);
                setApiCallForGetServices(false);
                if (servicesResponse && servicesResponse.length > 0) {
                    setServiceData([...servicesResponse]);
                }
                else {
                    setServiceData([]);
                }
            }
            catch (err) {
                console.log(err)
                setApiCallForGetServices(false);
            }
        }
        getPackageBySp();
    }, [props.history.location.pathname, alert]);


    React.useEffect(() => {
        const getComboPackagesBySp = async () => {
            setApiCallForGetPackages(true);
            try {
                const packageResponse = await servicesandpackagesService.getComboPackagesBySp(slug);
                packageResponse.map((prop) => {
                    var strForTitle = '';
                    prop.services?.map((prop2, index) => {
                        const obj = Object.assign({}, ...prop2);
                        strForTitle = strForTitle.concat(obj.name, ', ')
                    });
                    let services = strForTitle.substring(0, strForTitle.length - 2)
                    prop.service_included = services;
                })
                setApiCallForGetPackages(false);
                if (packageResponse && packageResponse.length > 0) {
                    setPackageData([...packageResponse]);
                }
                else {
                    setPackageData([]);
                }
            }
            catch (err) {
                console.log(err)
                setApiCallForGetPackages(false);
            }
        }
        getComboPackagesBySp();
    }, [props.history.location.pathname, alert])

    // React.useEffect(() => {
    //     console.log(packageData);
    // }, [packageData]);




    function SweetAlertConfirm() {
        const getAlert = () => (
            <SweetAlert
                success
                title="Woot!"
                onConfirm={() => {
                    setAlert(null)
                }}
            >
                Data deleted successfully
            </SweetAlert>
        )
        setAlert(getAlert());
    }


    const deleteData = (id, parameter) => {
        if (parameter == 'service') {
            servicesandpackagesService.deletePackage(id).then(() => {
                SweetAlertConfirm()
            })
        }
        else if (parameter == 'package') {
            servicesandpackagesService.deleteComboPackage(id).then(() => {
                SweetAlertConfirm()
            })
        }
    }

    function SweetAlertConfirmDelete(id, parameterForDetectionofServiceOrPacakge) {
        const getAlert = () => (id &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => {
                    setAlertDelete(null);
                    deleteData(id, parameterForDetectionofServiceOrPacakge);
                }}
                onCancel={() => setAlertDelete(null)}
                focusCancelBtn
            >
                You want to delete this record?
            </SweetAlert>
        )
        setAlertDelete(getAlert());
    }


    return (
        <>
            {
                ((window.location.href.split('/')[window.location.href.split('/').length - 2] !== 'addService') &&
                    (window.location.href.split('/')[window.location.href.split('/').length - 2] !== 'addPackage') &&
                    (window.location.href.split('/')[window.location.href.split('/').length - 2] !== 'editService') &&
                    (window.location.href.split('/')[window.location.href.split('/').length - 2] !== 'editPackage')
                )
                &&
                <>
                    <Card className={`${classes.mainCard} productCategory`}>
                        <GridContainer style={{ paddingTop: "20px", paddingBottom: "20px", margin: 0 }}>
                            <GridItem xs={12} sm={12} md={6} style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                                <CardMedia className={classes.cover} image={serviceData.length > 0 ? serviceData[0].services.subcategory.image_url : "https://maxcdn.icons8.com/Share/icon/p1em/Very_Basic/home1600.png"} title={'Services'} />
                                {/* {cat.image_url && <CardMedia className={classes.cover} image={cat.image_url} title={cat.name} />} */}
                                <h4 className={classes.cardTitle} style={{ fontSize: 20, fontFamily: "Muli" }}>{'Services'}</h4>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} style={{ textAlign: "right", alignItems: "center", display: "flex", justifyContent: "flex-end" }} >
                                <Link className={classes.textDecNone} to={{ pathname: "/app/service_providers/service_provider_detail/addService/" + slug }}>
                                    {
                                        window.location.href.split('/')[window.location.href.split('/').length - 2] == 'service_provider_detail' &&

                                        <Button
                                            variant="contained"
                                            color="default"
                                            className={classes.button}
                                            startIcon={<Icon color="primary">add</Icon>}
                                        >Add New Service
                                        </Button>
                                    }
                                </Link>
                            </GridItem>
                            {
                                apiCallForGetServices == false &&
                                <TableContainer className={classes.tcontainer}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead className={classes.thead}>
                                            <TableRow>
                                                <StyledTableCell className={classes.bold} style={{ fontSize: 16, fontFamily: "Muli-Bold", color: "#25233C" }}>Name</StyledTableCell>
                                                <StyledTableCell className={'text-center'} align="center">
                                                    {/* <div className={'mb-2'}> Credit Points </div> */}
                                                    <div className={classes.subcellWrapper}>
                                                        <StyledTableCell className={`${classes.subCell} ${classes.bold}`} style={{ fontSize: 16, fontFamily: "Muli-Bold", color: "#25233C" }}>Price</StyledTableCell>
                                                        {/* <StyledTableCell className={classes.subCell} >Up to 10 bookings</StyledTableCell>
                                        <StyledTableCell className={classes.subCell} >10+ bookings</StyledTableCell> */}
                                                    </div>
                                                </StyledTableCell>
                                                {window.location.href.split('/')[window.location.href.split('/').length - 2] == 'service_provider_detail' &&
                                                    <StyledTableCell align="right" className={classes.bold}>Actions</StyledTableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
                                            {serviceData.length > 0 &&
                                                serviceData.map((prop, index) => {
                                                    return <TableRow key={index} >
                                                        <TableCell component="th" scope="row" className={classes.tcell1}>
                                                            <div className={classes.div1}>
                                                                {/* <div className={classes.imgItem}>
                                        <img height="30" src="https://maxcdn.icons8.com/Share/icon/p1em/Very_Basic/home1600.png" />
                                        {row.image_url && <img height="30" src={row.image_url} />}
                                    </div> */}

                                                                <Typography variant="body1" className={classes.blockTitle} style={{ fontSize: 14, fontFamily: "Muli", color: "#25233C" }}>
                                                                    {prop.title}
                                                                </Typography>

                                                            </div>

                                                        </TableCell>
                                                        <TableCell align="left" className={classes.tcell2}>
                                                            <div className={classes.div2}>
                                                                <TableCell className={classes.tcell2sub} style={{ fontSize: 14, fontFamily: "Muli", color: "#25233C" }}> {prop.normal_price}</TableCell>
                                                                {/* {row.credit} */}
                                                            </div>
                                                        </TableCell>
                                                        {
                                                            window.location.href.split('/')[window.location.href.split('/').length - 2] == 'service_provider_detail' &&
                                                            <TableCell align="right" className={classes.tcell3}>
                                                                <div className={classes.div3}>
                                                                    {/* <Link className={classes.textDecNone} to={{ 
                                                            pathname: "/app/service_providers/service_provider_detail/editService/" + slug, 
                                                            state: { categoryId: 'abc',
                                                                     subCategoryId: 'hello', 
                                                                     price:prop.normal_price, 
                                                                     duration:prop.duration,
                                                                     description:prop.description ? prop.description :'',
                                                                     serviceId:prop.services_id,
                                                                     serviceIdCreatedBySp:prop._id
                                                                    } 
                                                            }
                                                            }> */}
                                                                    <IconButton aria-label="edit" onClick={() => {
                                                                        props.history.push({
                                                                            pathname: "/app/service_providers/service_provider_detail/editService/" + slug,
                                                                            state: {
                                                                                categoryId: prop.services.subcategory.category_id,
                                                                                subCategoryId: prop.services.subcategory._id,
                                                                                price: prop.normal_price,
                                                                                duration: prop.duration,
                                                                                description: prop.description ? prop.description : '',
                                                                                serviceId: prop.services_id,
                                                                                serviceIdCreatedBySp: prop._id
                                                                            }
                                                                        })
                                                                    }}>
                                                                        <img src={edit} style={{ width: '21px', height: '18px' }} />
                                                                    </IconButton>
                                                                    {/* </Link> */}
                                                                    <IconButton aria-label="delete" onClick={() => SweetAlertConfirmDelete(prop._id, 'service')}>
                                                                        <DeleteIcon style={{ width: '21px', height: '18px', color: '#8E8CAC' }} />
                                                                    </IconButton>
                                                                </div>
                                                            </TableCell>
                                                        }
                                                    </TableRow>
                                                })
                                            }
                                            {
                                                serviceData.length == 0 &&
                                                <TableCell component="th" scope="row" className={classes.tcell1}>
                                                    <Typography variant="body1" className={classes.blockTitle}>
                                                        No service added
                                                    </Typography>
                                                </TableCell>
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            {
                                apiCallForGetServices == true &&
                                <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20, width: '100%' }}>
                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                </div>
                            }
                        </GridContainer>
                    </Card>

                    {serviceData.length > 0 &&
                        <Card className={`${classes.mainCard} productCategory`}>
                            <GridContainer style={{ paddingTop: "20px", paddingBottom: "20px", margin: 0 }}>
                                <GridItem xs={12} sm={12} md={6} style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                                    <CardMedia className={classes.cover} image={packageData.length > 0 ? packageData[0].subcategory.image_url : "https://maxcdn.icons8.com/Share/icon/p1em/Very_Basic/home1600.png"} title={'Packages'} />
                                    {/* {cat.image_url && <CardMedia className={classes.cover} image={cat.image_url} title={cat.name} />} */}
                                    <h4 className={classes.cardTitle} style={{ fontSize: 20, fontFamily: "Muli" }}>{'Packages'}</h4>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} style={{ textAlign: "right", alignItems: "center", display: "flex", justifyContent: "flex-end" }} >
                                    <Link className={classes.textDecNone} to={{ pathname: "/app/service_providers/service_provider_detail/addPackage/" + slug }}>
                                        {
                                            window.location.href.split('/')[window.location.href.split('/').length - 2] == 'service_provider_detail' &&
                                            <Button
                                                variant="contained"
                                                color="default"
                                                className={classes.button}
                                                startIcon={<Icon color="primary">add</Icon>}
                                            >Add New Package
                                            </Button>
                                        }
                                    </Link>
                                </GridItem>

                                {
                                    apiCallForGetPackages == false &&
                                    <TableContainer className={classes.tcontainer}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead className={classes.thead}>
                                                <TableRow>
                                                    <StyledTableCell className={classes.bold} style={{ fontSize: 16, fontFamily: "Muli-Bold", color: "#25233C" }}>Name</StyledTableCell>
                                                    <StyledTableCell className={`${classes.bold} 'text-center'`} align="center">
                                                        {/* <div className={'mb-2'}> Credit Points </div> */}
                                                        <div className={classes.subcellWrapper}>
                                                            <StyledTableCell className={`${classes.bold} ${classes.subCell}`} style={{ fontSize: 16, fontFamily: "Muli-Bold", color: "#25233C" }}>Service Included</StyledTableCell>
                                                            {/* <StyledTableCell className={classes.subCell} >Price</StyledTableCell> */}
                                                            {/* <StyledTableCell className={classes.subCell} >Up to 10 bookings</StyledTableCell>
                                        <StyledTableCell className={classes.subCell} >10+ bookings</StyledTableCell> */}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell className={'text-center'} align="center">
                                                        {/* <div className={'mb-2'}> Credit Points </div> */}
                                                        <div className={classes.subcellWrapper}>
                                                            {/* <StyledTableCell className={classes.subCell} >Service Included</StyledTableCell> */}
                                                            <StyledTableCell className={`${classes.subCell} ${classes.bold}`} style={{ fontSize: 16, fontFamily: "Muli-Bold", color: "#25233C" }}>Price</StyledTableCell>
                                                            {/* <StyledTableCell className={classes.subCell} >Up to 10 bookings</StyledTableCell>
                                        <StyledTableCell className={classes.subCell} >10+ bookings</StyledTableCell> */}
                                                        </div>
                                                    </StyledTableCell>
                                                    {
                                                        window.location.href.split('/')[window.location.href.split('/').length - 2] == 'service_provider_detail' &&
                                                        <StyledTableCell align="right" className={classes.bold}>Actions</StyledTableCell>
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
                                                {packageData.length > 0 &&
                                                    packageData.map((prop, index) => {
                                                        return <TableRow key={index} >
                                                            <TableCell component="th" scope="row" className={classes.tcell1}>
                                                                <div className={classes.div1}>
                                                                    {/* <div className={classes.imgItem}>
                                        <img height="30" src="https://maxcdn.icons8.com/Share/icon/p1em/Very_Basic/home1600.png" />
                                        {row.image_url && <img height="30" src={row.image_url} />}
                                    </div> */}

                                                                    <Typography variant="body1" className={classes.blockTitle} style={{ fontSize: 14, fontFamily: "Muli", color: "#25233C" }}>
                                                                        {prop.title}
                                                                    </Typography>

                                                                </div>

                                                            </TableCell>
                                                            <TableCell align="left" className={classes.tcell2}>
                                                                <div className={classes.div2}>
                                                                    <TableCell className={classes.tcell2sub} style={{ fontSize: 14, fontFamily: "Muli", color: "#25233C" }}> {prop.service_included}</TableCell>
                                                                    {/* <TableCell className={classes.tcell2sub}> {prop.normal_price}</TableCell> */}
                                                                    {/* {row.credit} */}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="left" className={classes.tcell2}>
                                                                <div className={classes.div2}>
                                                                    {/* <TableCell className={classes.tcell2sub}> {prop.service_included}</TableCell> */}
                                                                    <TableCell className={classes.tcell2sub} style={{ fontSize: 14, fontFamily: "Muli", color: "#25233C" }}> {prop.normal_price}</TableCell>
                                                                    {/* {row.credit} */}
                                                                </div>
                                                            </TableCell>
                                                            {
                                                                window.location.href.split('/')[window.location.href.split('/').length - 2] == 'service_provider_detail' &&
                                                                <TableCell align="right" className={classes.tcell3}>
                                                                    <div className={classes.div3}>
                                                                        <IconButton aria-label="edit" onClick={() => {
                                                                            props.history.push({
                                                                                pathname: "/app/service_providers/service_provider_detail/editPackage/" + slug,
                                                                                state: {
                                                                                    id: prop._id,
                                                                                    categoryId: prop.category_id,
                                                                                    subCategoryId: prop.sub_category_id,
                                                                                    title: prop.title,
                                                                                    services: prop.service_id,
                                                                                    packages: prop.package_id,
                                                                                    description: prop.description,
                                                                                    duration: prop.duration,
                                                                                    normal_price: prop.normal_price
                                                                                }
                                                                            })
                                                                        }}>
                                                                            <img src={edit} style={{ width: '21px', height: '18px' }} />
                                                                        </IconButton>
                                                                        <IconButton aria-label="delete" onClick={() => SweetAlertConfirmDelete(prop._id, 'package')}>
                                                                            <DeleteIcon style={{ width: '21px', height: '18px', color: '#8E8CAC' }} />
                                                                        </IconButton>
                                                                    </div>
                                                                </TableCell>
                                                            }

                                                        </TableRow>
                                                    })
                                                }
                                                {
                                                    serviceData.length == 0 &&
                                                    <TableCell component="th" scope="row" className={classes.tcell1}>
                                                        <Typography variant="body1" className={classes.blockTitle}>
                                                            No packages added
                                                        </Typography>
                                                    </TableCell>
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                                {
                                    apiCallForGetPackages == true &&
                                    <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20, width: '100%' }}>
                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </div>
                                }
                            </GridContainer>
                        </Card>
                    }

                    <>
                        {alertDelete}
                    </>
                    <>
                        {alert}
                    </>

                </>

            }
            <div className="container-fluid" style={{ padding: '0px' }}>
                <Switch>
                    <Route path={`/app/service_providers/service_provider_detail/addService/:slug`}>
                        <AddService {...props} />
                    </Route>
                    <Route path={`/app/service_providers/service_provider_detail/addPackage/:slug`}>
                        <AddPackage {...props} />
                    </Route>
                    <Route path={`/app/service_providers/service_provider_detail/editService/:slug`}>
                        <EditService {...props} />
                    </Route>
                    <Route path={`/app/service_providers/service_provider_detail/editPackage/:slug`}>
                        <EditPackage {...props} />
                    </Route>
                </Switch>
            </div>
        </>
    )
}

export default MyServices
