import {
  Avatar,
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Typography,
  Box,
  Paper,
  TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
// import SweetAlert from 'react-bootstrap-sweetalert';
// import { userService } from "_services/user.service";
// import blueTick from "../../../assets/img/blueTick.png";
// import { useHistory } from "react-router-dom";
import { renderText } from '../common/DisplayComponent'
import { configurationService } from '_services/configuration.service'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ApplyBackDrop from '../common/ApplyBackDrop'
import Cropper from 'react-cropper'
import { dataUrlToFile, dataUrlToFileUsingFetch } from '../common/Utils'
import 'cropperjs/dist/cropper.css'
import PasswordContraints from '../common/PasswordConstraints'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: '#fff',
    boxShadow: 'unset',
    textTransform: 'capitalize',
    color: '#8f8cac',
    '&:hover': {
      background: '#efefef',
      boxShadow: 'unset',
      textDecoration: 'none',
      // color: '#ffffff',
    },
  },
  buttonTransparent: {
    '& .MuiButton-outlinedPrimary': {
      border: '1px solid transparent',
    },
  },
  formControl: {
    minWidth: '100%',
    marginBottom: 15,
  },
  marginTop50px: {
    marginTop: '50px',
  },
  h5: {
    color: '#25233C',
    fontSize: 18,
  },
  selectadj: {
    border: '1px solid #B9B8C4',
    height: 40,
    padding: 0,
    paddingLeft: '13px',
    borderRadius: 10,
    background: '#fff',
    // width:'95%'
    paddingRight: '10px'
  },
  formContainer: {
    width: '100%',
    height: '98vh',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    minHeight: '100%',
    padding: '16px',
    // margin:"20px 0"
  },
  boxStyle: {
    marginTop: '30px',
    borderRadius: 10
  },
  displayFlexJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  marginBottom24: {
    marginBottom: '24px',
  },
  minPaymentSection: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '65%',
    alignItems: 'center',
    marginTop: '30px',
  },
  width44: {
    width: '44%',
  },
  width114px: {
    width: '114px',
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    width: '18%',
  },
  displayFlex: {
    display: 'flex',
  },
  textCenterFontBold: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  colorTimingFont12: {
    color: '#005CB2',
    fontSize: '12px',
  },
  profileImageUpload: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    border: '1px solid grey',
  },
  displayFlexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '0px',
  },
  marginLeft20px: {
    marginLeft: '20px',
  },
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid red',
      borderRadius: 10
    },
  },

  roots: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 10
    },
  },
  //used if password id valid
  root1: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid green',
      borderRadius: 10
    },

  },
  errorSelectStyle: {
    border: '2px solid red',
    borderRadius: '10px',
    height: '45px',
  },
  displayFlexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  marginLeft20: {
    marginLeft: '20px',
  },
  widthDialog: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '650px',
      '@media (max-width:600px)': {
        minWidth: '300px',
      },
    },
  },
  buttonStyle: {
    backgroundColor: '#DB0D0D',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: 10,
    border: '1px solid #DB0D0D',
    padding: '6px 16px',
    '&:hover': {
      background: "#fff",
      border: "1px solid #DB0D0D",
      color: "#DB0D0D"
    },
  },
  outlinedButtonStyle: {
    color: 'black',
    borderColor: 'green',
    '&:hover': {
      borderColor: 'red',
    },
  },
  passwordValidationContainerStyle: {
    color: 'red',
    fontSize: '12px',
    marginTop: "5px"
  },
  passwordValidationStyle: {
    fontSize: '14px',
    marginBottom: '3px'
  },
  postionRel:
  {
    position:'relative',
  },
  postionAbs:
  {
    position:'absolute',
    right:'8px',
    top:'50%',
    transform: "translateY(-50%)",
  }
}))

const CreateAccount = ({
  createAcountData,
  handleImageUploadProfile,
  handleOnChangeCreateAccount,
  handleTogglePassword,
  saveCreateAccount,
  errorCheckCreateAccountData,
  createNewSp,
  validEmail,
  apiCall,
  openDialogVerification,
  setOpenDialogVerification,
  verificationStatus,
  verifyOtp,
  verifyEmail,
  sendOtpEmailVerification,
  setCreateAcountData,
  sendOtpMobileVerification,
  counter,
  counterDisplay,
  validPassword,

}) => {
  // console.log(createAcountData.phone,verificationStatus);
  const classes = useStyles()
  const [arrayProfession, setArrayProfession] = React.useState([])
  const [openImage, setOpenImage] = React.useState(false)
  const [image, setImage] = useState(null)
  const [cropData, setCropData] = useState('#')
  const [cropper, setCropper] = useState(null)
  const [uploadShow, setUploadShow] = useState(false)
  const [cropShow, setCropShow] = useState(false)

  // read file data ************************************************************************************************************************

  const onChange = (e) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
      setCropShow(true)
    }
    reader.readAsDataURL(files[0])
  }

  // get cropped image data ***************************************************************************************************************************************
  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setUploadShow(true)
      setCropData(cropper.getCroppedCanvas().toDataURL())
    }
  }

  // handle upload image *********************************************************************************************************************************************************************

  const handleUpload = async (url) => {
    const date = new Date()

    const file = await dataUrlToFileUsingFetch(
      url,
      `output${date.getTime()}.png`,
      'image/png',
    )

    setCreateAcountData({
      ...createAcountData,
      uploadImgShow: cropData,
      image: file,
    })

    //     `We have File "${file.name}", now we can upload it wherever we want!`
    //   );
    setOpenImage(false)
  }

  // fetch profession feilds ************************************************************************************************************************************************************************************

  React.useEffect(() => {
    const getProfessions = async () => {
      let array = []
      var res = await configurationService.getProfessions()
      array = res.professionValue ? res.professionValue.split(',') : []
      setArrayProfession([...array])
    }
    getProfessions()
  }, [])

  // console.log(verificationStatus);
  // React.useEffect(()=>{
  // console.log(arrayProfession);
  // },[arrayProfession])


  return (
    <div id="mainArea">
      <div>
        {/* Dialog for image Cropper ********************************************************************************************************************************** */}

        <Dialog
          className={classes.widthDialog}
          open={openImage}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <div>
              <div style={{ width: '100%' }}>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={'uploadImage'}
                  name={'uploadImage'}
                  onChange={onChange}
                />
                <label htmlFor={'uploadImage'}>
                  <Button
                    className={`${classes.marginLeft20px} ${classes.buttonStyle}`}
                    variant="contained"
                    component="span"
                  >
                    Select Image
                  </Button>
                </label>
                {/* <button>Use default img</button> */}
                <br />
                <br />
                <Cropper
                  style={{ height: 400, width: '100%' }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  aspectRatio={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance)
                  }}
                  guides={true}
                />
              </div>
              <div>
                <div
                  className="box"
                  style={{
                    width: '100%',
                    height: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {cropShow == true && (
                    <Button
                      style={{ margin: '20px 0' }}
                      className={classes.buttonStyle}
                      variant="contained"
                      onClick={getCropData}
                    >
                      Crop Image
                    </Button>
                  )}
                  {cropData && uploadShow == true && (
                    <img
                      style={{ width: '50%' }}
                      src={cropData}
                      alt="cropped"
                    />
                  )}
                </div>
              </div>
              <br style={{ clear: 'both' }} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                // console.log(verificationStatus,createAcountData)
                setOpenImage(false)
              }}
              className={classes.buttonStyle}
              color="secondary"
              variant="outlined"
            >
              Cancel
            </Button>
            {cropData && uploadShow == true && (
              <Button
                onClick={() => handleUpload(cropData)}
                className={classes.buttonStyle}
                variant="contained"
              >
                Upload
              </Button>
            )}
          </DialogActions>
        </Dialog>

        {/* Dialog for mobile verification ********************************************************************************************************************************** */}

        <Dialog
          open={openDialogVerification.mobile}
          style={{ zIndex: 100 }}
          //  onClose={(e) => { setOpenDialogVerification({ ...openDialogVerification, mobile: false }) }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            style={{ background: '#F5F5FC', color: '#6B6987' }}
            id="form-dialog-title"
          >
            Enter OTP
          </DialogTitle>
          <DialogContent>
            <FormControl
              className={classes.formControl}
              style={{ marginTop: 20 }}
            >
              <TextField
                className={
                  errorCheckCreateAccountData.phoneOtp == true
                    ? `${classes.root}`
                    : `${classes.roots}`
                }
                variant="outlined"
                placeholder="Enter OTP"
                fullWidth={true}
                size="small"
                name="phoneOtp"
                value={createAcountData.phoneOtp}
                onChange={handleOnChangeCreateAccount}
                onBlur={handleOnChangeCreateAccount}
                inputProps={{ maxLength: 6 }}
              />
            </FormControl>
            {errorCheckCreateAccountData.phoneOtp == true && (
              <p style={{ color: 'red', fontSize: '12px' }}>
                {' '}
                * OTP must be 6 characters long
              </p>
            )}
            <Box>
              {counter > -2 && counter <= 90 && (
                <Typography fontWeight={500} align="left">
                  {' '}
                  Resend OTP in{' '}
                  <span style={{ color: 'green', fontWeight: 'bold' }}>
                    {' '}
                    {counterDisplay}
                  </span>
                </Typography>
              )}
              {counter < -1 && (
                <Button color="secondary" onClick={sendOtpMobileVerification}>
                  Resend OTP
                </Button>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialogVerification({
                  ...openDialogVerification,
                  mobile: false,
                })
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={verifyOtp} color="primary" style={{ borderRadius: '10px' }}>
              verify
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for email verification ********************************************************************************************************************************** */}

        <Dialog
          open={openDialogVerification.email}
          style={{ zIndex: 100 }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            style={{ background: '#F5F5FC', color: '#6B6987' }}
            id="form-dialog-title"
          >
            Enter OTP{' '}
          </DialogTitle>
          <DialogContent>
            <FormControl
              className={classes.formControl}
              style={{ marginTop: 20 }}
            >
              <TextField
                className={
                  errorCheckCreateAccountData.emailOtp == true
                    ? `${classes.root}`
                    : `${classes.roots}`
                }
                variant="outlined"
                placeholder="Enter OTP"
                fullWidth={true}
                size="small"
                name="emailOtp"
                value={createAcountData.emailOtp}
                onChange={handleOnChangeCreateAccount}
                onBlur={handleOnChangeCreateAccount}
                inputProps={{ maxLength: 6 }}
              />
            </FormControl>
            {errorCheckCreateAccountData.emailOtp == true && (
              <p style={{ color: 'red', fontSize: '12px' }}>
                {' '}
                * OTP must be 6 characters long
              </p>
            )}
            <Box>
              {counter > -2 && counter <= 90 && (
                <Typography fontWeight={500} align="left">
                  {' '}
                  Resend OTP in{' '}
                  <span style={{ color: 'green', fontWeight: 'bold' }}>
                    {' '}
                    {counterDisplay}
                  </span>
                </Typography>
              )}

              {counter < -1 && (
                <Button color="secondary" onClick={sendOtpEmailVerification}>
                  Resend OTP
                </Button>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                // console.log(verificationStatus,createAcountData)
                setOpenDialogVerification({
                  ...openDialogVerification,
                  email: false,
                })
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={verifyEmail} style={{ borderRadius: '10px' }}>verify</Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* Form Section ********************************************************************************************************************************** */}

      <Box style={{}}>
        {renderText({
          label:
            window.location.href.split('/')[
              window.location.href.split('/').length - 1
            ] !== 'newSp'
              ? 'Update Account'
              : 'Create an Account',
        })}
      </Box>

      <Box className={classes.displayFlexAlignCenter} style={{ marginTop: '22px' }}>
        {
          createAcountData.uploadImgShow.length == 0 &&
          <div
            className={classes.profileImageUpload}
            style={{
              backgroundImage:
                `url('https://www.mansfield.edu//profiles/images/avatar.jpg')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
          ></div>

        }
        {
          createAcountData.uploadImgShow.length > 0 &&
          <div
            className={classes.profileImageUpload}
            style={{
              backgroundImage:
                `url('${createAcountData.uploadImgShow}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
          ></div>
        }
        {/* <div 
          className={classes.profileImageUpload}
          style={{
            backgroundImage: 
              `url('${createAcountData.uploadImgShow}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        ></div> */}
        <div>
          {/* <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id={'uploadImage'}
                    name={'uploadImage'}
                    onChange={event => handleImageUploadProfile(event)}
                    type="file"
                /> */}
          {/* <label htmlFor={'uploadImage'}> */}
          <Button
            // className={classes.marginLeft20px}
            onClick={() => {
              setOpenImage(true)
              setUploadShow(false)
              setCropShow(false)
              setImage(null)
            }}
            variant="contained"
            className={`${classes.buttonStyle} ${classes.marginLeft20}`}
            component="span"
          >
            Upload Image
          </Button>
          {/* </label> */}
        </div>
      </Box >

      <Box className={classes.boxStyle}>
        <TextField
          className={
            // errorCheckCreateAccountData.fname == true ? `${classes.root}` : ''
            errorCheckCreateAccountData.fname == true ? `${classes.root}` : `${classes.roots}`
          }
          label="First Name*"
          variant="outlined"
          placeholder="First Name*"
          inputProps={{ maxLength: 25 }}
          fullWidth={true}
          size="small"
          name="fname"
          value={createAcountData.fname}
          onChange={handleOnChangeCreateAccount}
          onBlur={handleOnChangeCreateAccount}
        />
      </Box>
      {
        errorCheckCreateAccountData.fname == true && (
          <p style={{ color: 'red', fontSize: '12px' }}>
            {' '}
            * This Field is Required
          </p>
        )
      }
      <Box className={classes.boxStyle}>
        <TextField
          // className={
          //   errorCheckCreateAccountData.lName == true ? `${classes.root}` : ''
          // }
          className={
            errorCheckCreateAccountData.lName == true ? `${classes.root}` : `${classes.roots}`
          }
          label="Last Name*"
          variant="outlined"
          placeholder="Last Name*"
          inputProps={{ maxLength: 25 }}
          fullWidth={true}
          size="small"
          name="lName"
          value={createAcountData.lName}
          onChange={handleOnChangeCreateAccount}
          onBlur={handleOnChangeCreateAccount}
        />
      </Box>
      {
        errorCheckCreateAccountData.lName == true && (
          <p style={{ color: 'red', fontSize: '12px' }}>
            {' '}
            * This Field is Required
          </p>
        )
      }
      <Box className={`${classes.boxStyle} ${classes.displayFlex} ${classes.postionRel}`}>
        <TextField
          disabled={
            window.location.href.split('/')[
              window.location.href.split('/').length - 1
            ] == 'newSp'
              ? false
              : true
          }
          className={`${errorCheckCreateAccountData.email == true ? `${classes.root}` : `${classes.roots}`
            } `}
          label="Email*"
          variant="outlined"
          placeholder="Email*"
          fullWidth={true}
          size="small"
          type="email"
          name="email"
          value={createAcountData.email}
          onChange={handleOnChangeCreateAccount}
          onBlur={handleOnChangeCreateAccount}
        />
        {window.location.href.split('/')[
          window.location.href.split('/').length - 1
        ] == 'newSp' &&
          validEmail == false &&
          createAcountData.email.length > 0 &&
          verificationStatus.email.status == false && (
            <Button
              className={`${classes.marginLeft20} ${classes.outlinedButtonStyle}`}
              variant="outlined"
              color="primary"
              onClick={sendOtpEmailVerification}
              style={{ borderRadius: '10px' }}
            >
              Verify
            </Button>
          )}
        {verificationStatus.email.status == true && (
          <div
            className={`${classes.displayFlexAlignCenter} ${classes.marginLeft20} ${classes.postionAbs}`}>
            <CheckCircleIcon
              style={{ color: 'green', width: 25, height: 25 }}
            />
          </div>
        )}
      </Box>
      {createAcountData.email.length > 0 && validEmail == false && verificationStatus.email.status == false && (
        <p style={{ fontSize: '14px', marginLeft: '10px' }}>
          An OTP will be sent on your Email for verification
        </p>
      )}
      {
        errorCheckCreateAccountData.email == true && (
          <p style={{ color: 'red', fontSize: '12px' }}>
            {' '}
            * This Field is Required
          </p>
        )
      }
      {
        createAcountData.email.length > 0 && validEmail == true && (
          <p style={{ color: 'red', fontSize: '12px' }}> * Not a valid Email</p>
        )
      }

      <Box>
        <Box className={`${classes.boxStyle} ${classes.displayFlex} ${classes.postionRel}`}>
          <TextField
            disabled={
              window.location.href.split('/')[
                window.location.href.split('/').length - 1
              ] == 'newSp'
                ? false
                : true
            }
            className={`${errorCheckCreateAccountData.phone == true ? `${classes.root}` : `${classes.roots}`
              }  ${classes.marginRight20}`}
            fullWidth={true}
            label="Phone*"
            variant="outlined"
            placeholder="Phone*"
            size="small"
            name="phone"
            type="text"
            value={createAcountData.phone}
            onChange={handleOnChangeCreateAccount}
            onBlur={handleOnChangeCreateAccount}
          />

          {window.location.href.split('/')[
            window.location.href.split('/').length - 1
          ] == 'newSp' &&
            createAcountData.phone.length > 9 &&
            verificationStatus.mobile.status == false && (
              <Button
                className={`${classes.marginLeft20} ${classes.outlinedButtonStyle}`}
                variant="outlined"
                color="primary"
                onClick={sendOtpMobileVerification}
                style={{ borderRadius: '10px' }}

              >
                Verify
              </Button>
            )}
          {verificationStatus.mobile.status == true && (
            <div
              className={`${classes.displayFlexAlignCenter} ${classes.marginLeft20} ${classes.postionAbs}`}
            >
              <CheckCircleIcon
                style={{ color: 'green', width: 25, height: 25 }}
              />
            </div>
          )}
        </Box>
        {/* {window.location.href.split('/')[
          window.location.href.split('/').length - 1
        ] == 'newSp' && */}
        {
          createAcountData.phone.length > 0 &&
          createAcountData.phone.length > 10 === true && errorCheckCreateAccountData.phone == false && verificationStatus.mobile.status == false && (
            <p style={{ fontSize: '14px', marginLeft: '10px' }}>
              An OTP will be sent to you for verification
            </p>
          )}
        {errorCheckCreateAccountData.phone == true &&
          createAcountData.phone.length == 0 && (
            <p style={{ color: 'red', fontSize: '12px' }}>
              {' '}
              * This Field is Required
            </p>
          )}
        {errorCheckCreateAccountData.phone == true &&
          createAcountData.phone.length > 0 &&
          createAcountData.phone.length <= 9 && (
            <p style={{ color: 'red', fontSize: '12px' }}>
              {' '}
              * Enter a valid mobile number
            </p>
          )}
      </Box>

      <Box className={`${classes.boxStyle}`}>
        <InputLabel htmlFor="profession" style={{ fontSize: 14, fontWeight: 400, fontFamily: "Muli", color: "#111111" }}>Profession*</InputLabel>
        <Select
          disableUnderline
          className={
            errorCheckCreateAccountData.profession == true
              ? `${classes.errorSelectStyle}`
              : `${classes.selectadj}`
          }
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="profession"
          fullWidth={true}
          value={createAcountData.profession}
          onChange={handleOnChangeCreateAccount}
          input={<Input />}
        >
          {arrayProfession &&
            arrayProfession.length > 0 &&
            arrayProfession.map((value, index) => {
              return (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              )
            })}
        </Select>
      </Box>
      {
        errorCheckCreateAccountData.profession == true && (
          <p style={{ color: 'red', fontSize: '12px' }}>
            {' '}
            * This Field is Required
          </p>
        )
      }

      {
        window.location.href.split('/')[
        window.location.href.split('/').length - 1
        ] == 'newSp' && (
          <Box
            className={`${classes.boxStyle} ${classes.postionRel}`}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <TextField
              // className={
              //   errorCheckCreateAccountData.password == true
              //     ? `${classes.root}`
              //     : validPassword == true ? `${classes.root1}` : ''
              // }
              className={
                errorCheckCreateAccountData.password == true
                  ? `${classes.root}`
                  : validPassword == true ? `${classes.root1}` : `${classes.roots}`
              }
              label="Create Password*"
              variant="outlined"
              placeholder="Create Password*"
              fullWidth={true}
              type={createAcountData.showPassword ? 'text' : 'password'}
              size="small"
              name="password"
              value={createAcountData.password}
              onChange={handleOnChangeCreateAccount}
              onBlur={handleOnChangeCreateAccount}
            // onFocus={handleOnChangeCreateAccount}
            />
            {/* {console.log(createAcountData.showPassword)} */}

            {createAcountData.password.length > 0 && (

              <IconButton onClick={handleTogglePassword} className={classes.postionAbs}>
                {createAcountData.showPassword === true ?
                  <VisibilityIcon />
                  :
                  <VisibilityOffIcon />
                }
              </IconButton>
            )}
          </Box>
        )
      }
      {
        errorCheckCreateAccountData.password == true && (
          <div className={classes.passwordValidationContainerStyle}>
            {' '}
            <p className={classes.passwordValidationStyle}>Password must contain at least 1 lowercase alphabetical character</p>
            <p className={classes.passwordValidationStyle}>Password must contain at least 1 uppercase alphabetical character</p>
            <p className={classes.passwordValidationStyle}>Password must contain at least 1 numeric character</p>
            <p className={classes.passwordValidationStyle}>Password must contain at least one special character !@#$%^&</p>
            <p className={classes.passwordValidationStyle}>Password must be eight characters or longer</p>
          </div>
        )
      }
      {
        createAcountData.password.length > 0 && errorCheckCreateAccountData.password == false && (
          <PasswordContraints createAcountData={createAcountData} />
        )
      }

      {/* {
        createAcountData.password.length > 20 && validPassword == false ? (
          <p style={{ color: 'red', fontSize: '12px' }}> Password can not be greater then 20 character</p>) : null
      }
      {
        createAcountData.password.length > 0 && createAcountData.password.length <= 20 && validPassword == false ? (
          <p style={{ color: 'red', fontSize: '12px' }}> Password should have atleast 1 capital and 1 small letter, 1 number, 1 special symbol. </p>) :
          createAcountData.password.length > 0 && createAcountData.password.length <= 20 && validPassword == true ?
            (<p style={{ color: 'green', fontSize: '12px' }}> Strong password</p>) : null
      } */}

      {/* <Box>
        {
          createAcountData.password.length > 0 && validPassword == false ? (
            <PasswordContraints createAcountData={createAcountData} />
          ) : null
        }
      </Box> */}




      <Box
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      >
        {window.location.href.split('/')[
          window.location.href.split('/').length - 1
        ] == 'newSp' && (
            <Button style={{ width: "100%" }}
              variant="outlined"
              className={classes.buttonStyle}
              onClick={createNewSp}
            >
              Sign Up
            </Button>
          )}
        {window.location.href.split('/')[
          window.location.href.split('/').length - 1
        ] !== 'newSp' && (
            <Button
              variant="outlined"
              color="primary"
              onClick={saveCreateAccount}
            >
              save Changes
            </Button>
          )}
        {<ApplyBackDrop apiCall={apiCall} />}{' '}
      </Box>
    </div >
  )
}

export default CreateAccount
