import React from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";
import Profile from './SPTabs/Profile';
import ContactInfo from './SPTabs/ContactInfo';
import MyServices from './SPTabs/MyServices';

function TabPanel(props) {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    appbarAdj: {
        background: 'transparent',
        boxShadow: 'none',
        position:"absolute",
        top:'-5pc',
    },
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    tabpanel: {
        margin: "0 -25px",
    },
    tabs: {
        margin: "0px 10px 0px 10px",
        background: "#EAEDF7",
        borderRadius: "5px",
        paddingLeft:10
        // "&.MuiTabs-indicator": {
        //   display: "none"
        // }
      },
      tab: {
        "&.MuiButtonBase-root.MuiTab-root": {
            height: "55px",
            padding: "unset",
            minWidth: "125px",
            textTransform: "capitalize",
            fontWeight: "400",
        }
      }
}));

const SPVerificationDetailBody = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <div className={'container-fluid position-relative'} >
                <AppBar elevation={0} position="static" color="default" className={classes.appbarAdj} >
                    <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label="simple tabs example" TabIndicatorProps={{ style: { backgroundColor: "#6259ca" } }} textColor="primary">
                        <Tab className={classes.tab} label="Profile" {...a11yProps(0)} />
                        <Tab className={classes.tab} label="My Services" {...a11yProps(1)} />
                        <Tab className={classes.tab} label="Contact Info" {...a11yProps(2)} />
                        new
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0} className={classes.tabpanel}>
                    <Profile />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MyServices {...props} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ContactInfo />
                </TabPanel>
            </div>
        </div>
    )
}

export default SPVerificationDetailBody
