import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { Button, Box, Paper, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        position: "absolute",
        right: "3pc",
        top: "7pc",
        background: "#fff",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8d9d",
    },
    button2: {
        background: "transparent",
        boxShadow: "unset",
        textTransform: "capitalize",
        color: "#8f8cac"
    },
    SaveButton: {
        backgroundColor: '#6362ca',
        color: '#ffffff',
        width: 100,
        fontSize: "10px",
        padding: "10px",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    DelButton: {
        border: "1px solid grey",
        color: "#8E8CAC",
        textTransform: "capitalize",
        fontSize: 14,
        "&:hover": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        },
        "&:focus": {
            backgroundColor: '#6362ca',
            color: '#ffffff',
        }
    },
    btnAlign: {
        display: "flex",
        marginLeft: "5px",
    },
    h5: {
        fontSize: 18
    },
    container: {
        // border:"1px solid grey",
        margin: "0 -20px"
    },
    Imginput: {
        display: "none",

    },
    UploadBtn: {
        padding: "10px 15px",
        fontSize: "14px",
        textTransform: "capitalize",
        marginBottom: "7px",
        background: "#8E8CAC"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    fontSize20: {
        fontSize: '20px'
    }
}));

const PackageImage = (props) => {

    const classes = useStyles();

    const [imagetoSend, setImageToSend] = React.useState({
        imageName: [],
        imageFile: []
    })

    function getToPrevious() {
        // console.log('hello')
        const { from } = { from: { pathname: "/app/product_services" } };
        props.history.goBack();
    }

    const handleOnChangeSelectImage = (e) => {
        //   console.log(imagetoSend);
        const values = { ...imagetoSend };
        //   console.log(values);
        let fileDispArray = [];
        let fileArray = [];
        const reader = new FileReader();
        if (event.target.files.length > 0) {
            fileDispArray = Array.from(event.target.files).map((file) => URL.createObjectURL(file));
            fileArray = Array.from(event.target.files).map((file) => {
                return file;
            });
            //   console.log(fileArray);
            //   console.log(fileDispArray);
            values['imageFile'] = [...values.imageFile, ...fileArray];
            values['imageName'] = [...values.imageName, ...fileDispArray];

            setImageToSend(values)
        }

    }

    const removeImage = (arrayPosition) => {
        const values = { ...imagetoSend };
        for (var i = 0; i < values['imageFile'].length; i++) {
            if (i === arrayPosition) {

                values['imageFile'].splice(arrayPosition, 1);
                values['imageName'].splice(arrayPosition, 1);
            }

        }
        setImageToSend(values);
    }



    return (
        <>
            {
                window.location.href.split('/')[window.location.href.split('/').length - 1] == 'addImageForPackage' && <div>
                    <AppBar elevation={0} position="static" color="transparent" className={classes.appbarAdj} >
                        <div className={classes.header}>
                            <IconButton aria-label="edit" onClick={getToPrevious}>
                                <ArrowBackIcon />
                            </IconButton>
                            <h4 className={classes.fontSize20} >Add Images</h4>
                        </div>
                    </AppBar>
                    <GridContainer container>
                        <GridItem xs={12}>
                            <Card>
                                <CardHeader color="primary" style={{ height: '20px' }} icon>
                                    {/* <CardIcon color="primary">
                        <Assignment />
                      </CardIcon> */}
                                    {/* <h4 className={classes.cardIconTitle}>React Table</h4> */}
                                </CardHeader>
                                <CardBody className="p-0">
                                    <Box style={{ marginRight: "20px", marginLeft: '20px' }} >
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                                            {
                                                imagetoSend.imageName.length > 0 &&
                                                imagetoSend.imageName.map((prop, i) => {
                                                    return <div key={i} style={{ backgroundImage: `url(${prop})`, backgroundSize: "cover", backgroundPosition: "center center", width: '100px', height: '100px', margin: '10px', borderRadius: '8px', position: 'relative' }}>
                                                        <div onClick={() => {
                                                            removeImage(i);
                                                        }}
                                                            style={{ height: '20px', width: '20px', background: 'red', borderRadius: '40px', position: 'absolute', top: '0', right: '-10px', cursor: 'pointer' }}>

                                                            <RemoveIcon style={{ width: '20px', color: 'white' }} />

                                                        </div>
                                                    </div>

                                                })
                                            }
                                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '20px', marginBottom: '20px' }}>
                                                <input
                                                    accept="image/*"
                                                    className={classes.Imginput}
                                                    id={`contained-button-file`}
                                                    name={`contained-button-file`}
                                                    onChange={
                                                        event => handleOnChangeSelectImage(event)
                                                    }
                                                    type="file"
                                                    multiple={true}
                                                />
                                                <label htmlFor={`contained-button-file`}>
                                                    <Button variant="contained" color="primary" component="span" >
                                                        + Add Image
                                    </Button>
                                                </label>
                                            </Box>
                                        </div>
                                    </Box>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem  >
                            {
                                imagetoSend.imageFile.length > 0 &&
                                <Button variant="contained" color="primary" component="span" >
                                    save
                            </Button>
                            }

                        </GridItem>

                    </GridContainer>
                </div>
            }

        </>
    );

}

export default PackageImage;