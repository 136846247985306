// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  'Sign contract for "What are conference organizers afraid of?"',
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  "Create 4 Invisible User Experiences you Never Knew About",
];
var website = [
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"',
];
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"',
];

var dataTable = {
  dataRows: [
  ["Tiger Nixon", "System Architect","Edinburgh","61"],
  ["Garrett Winters","Accountant","Tokyo","63"],
  ["Ashton Cox","Junior Technical Author","San Francisco","66"],
  ["Cedric Kelly","Senior Javascript Developer","Edinburgh","22"],
  ["Airi Satou","Accountant","Tokyo","33"],
  ["Brielle Williamson","Integration Specialist","New York","61"],
  ["Herrod Chandler","Sales Assistant","San Francisco","59"],
  ["Rhona Davidson","Integration Specialist","Tokyo","55"],
  ["Colleen Hurst","Javascript Developer","San Francisco","39"],
  ["Sonya Frost","Software Engineer","Edinburgh","23"],
  ["Tiger Nixon", "System Architect","Edinburgh","61"],
  ["Garrett Winters","Accountant","Tokyo","63"],
  ["Ashton Cox","Junior Technical Author","San Francisco","66"],
  ["Cedric Kelly","Senior Javascript Developer","Edinburgh","22"],
  ["Airi Satou","Accountant","Tokyo","33"],
  ["Brielle Williamson","Integration Specialist","New York","61"],
  ["Herrod Chandler","Sales Assistant","San Francisco","59"],
  ["Rhona Davidson","Integration Specialist","Tokyo","55"],
  ["Colleen Hurst","Javascript Developer","San Francisco","39"],
  ["Sonya Frost","Software Engineer","Edinburgh","23"],
  ["Tiger Nixon", "System Architect","Edinburgh","61"],
  ["Garrett Winters","Accountant","Tokyo","63"],
  ["Ashton Cox","Junior Technical Author","San Francisco","66"],
  ["Cedric Kelly","Senior Javascript Developer","Edinburgh","22"],
  ["Airi Satou","Accountant","Tokyo","33"],
  ["Brielle Williamson","Integration Specialist","New York","61"],
  ["Herrod Chandler","Sales Assistant","San Francisco","59"],
  ["Rhona Davidson","Integration Specialist","Tokyo","55"],
  ["Colleen Hurst","Javascript Developer","San Francisco","39"],
  ["Sonya Frost","Software Engineer","Edinburgh","23"]
]};

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  server,
  dataTable
};
