import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardMedia, Icon, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { userService } from "_services/user.service";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    cover: {
        border: "1px solid grey",
        height: "100%",
        maxWidth: 60,
        width: "100%"
    },
    header: {
        display: "flex",
        alignItems: "flex-end"
    },
    cardTitle: {
        margin: "unset",
        padding: "0 10px",
        fontSize: 20,
        color: "#6f6c8a"
    },
    cardAction: {
        color: "#000",
    },
    button: {
        margin: theme.spacing(1),
        background: "transparent",
        boxShadow: "unset",
        border: "1px solid #8f8cac",
        textTransform: "capitalize",
        color: "#8f8cac",
        "&:hover": {
            background: "transparent",
            boxShadow: "unset",
            textDecoration: "none"
            // color: '#ffffff',
        },
    },
    table: {
        margin: "0 0 10px",
        border: '1px'
    },
    tcontainer: {
        //   margin:"0 -5px",
    },
    thead: {
        padding: 10
    },
    gridCont: {
        paddingTop: "10px",
        margin: 0,
    },
    gridItem1: {
        display: "flex",
        alignItems: "center",
    },
    gridItem2: {
        textAlign: "right",
    },
    tcell1: {
        border: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
        height: "auto",
        paddingLeft: '7px !important',
    },
    tcell2: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
        height: "auto"
    },
    tcell3: {
        border: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingBottom: "unset",
        height: "auto"
    },
    tcell4: {
        border: "unset",
        paddingLeft: "unset",
        paddingBottom: "unset",
        height: "auto"
    },
    div1: {
        display: "flex",
        alignItems: "center",
        background: "#F3F3FC",
        padding: 10,
        height: "auto",
    },
    div2: {
        background: "#F3F3FC",
        padding: 15,
        color: "#25233C",
        height: "auto",
    },
    div3: {
        background: "#F3F3FC",
        padding: 1,
        height: "auto",
    },
    blockTitle: {
        // paddingLeft: 10,
        color: "#25233C",
        fontSize: 14
    },
    mainCard: {
        marginBottom: '2em'
    },
    firstHead: {
        paddingLeft: "1.6rem",
    },
    lastHead: {
        paddingRight: "2rem"
    },
    textDecNone: {
        textDecoration: "none"
    },
    thead:
    {
        "& th": {
            whiteSpace: "nowrap",
            fontFamily: 'Muli-Bold !important'
        }
    }
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        //   backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
        backgroundColor: "#F5F5FC",
        color: "#6c6987",
    },
}))(TableCell);

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        border: '1px solid',
        backgroundColor: '#388c04',
        borderColor: '#388c04',
        color: '#ffffff',
        '&:hover': {
            border: '1px solid',
            backgroundColor: '#388c04',
            borderColor: '#388c04',
            color: '#ffffff',
        },
        '&:active': {
            border: '1px solid',
            backgroundColor: '#388c04',
            borderColor: '#388c04',
            color: '#ffffff',
        },
        '&:focus': {
            border: '1px solid',
            backgroundColor: '#388c04',
            borderColor: '#388c04',
            color: '#ffffff',
        },
    },
})(Button);

const SPVerification = (props) => {
    const classes = useStyles();
    let history = useHistory();
    const [SPRequest, setSPRequest] = React.useState([]);
    const serviceData = [{
        "_id": "1",
        "name": "name",
        "location": "desc",
        "submitted_on": "12 July 2021"
    }]
    React.useEffect(() => {
        // const res = userService.getSPVerificationList();
        // setSPRequest
        const fetchRequests = async () => {
            try {
                const res = await userService.getSPVerificationList();
                let sortedVerificationList = [];
                sortedVerificationList = [...res]
                sortedVerificationList = sortedVerificationList.filter((a) => (a.user !== null));
                sortedVerificationList = sortedVerificationList.sort((a, b) => (a.user.created_at > b.user.created_at) ? -1 : 1);
                setSPRequest([...sortedVerificationList]);
            }
            catch (err) { }
        }

        fetchRequests();


    }, []);
    function gotoDetail(request) {
        const { from } = { from: { pathname: "/app/service_providers/verification/detail/" + request._id, state: { request } } };
        props.history.push(from);
        // history.push("/app/service_provider/verication/requests" + request._id);
    }

    function getToPrevious() {
        const { from } = { from: { pathname: "/app/service_providers" } };
        props.history.push(from);
    }
    return (
        <div>
            {
                SPRequest.length > 0 ?
                    <>
                        <div className={classes.header}>
                            <IconButton aria-label="edit" onClick={() => getToPrevious()} style={{ paddingLeft: '0px' }}>
                                <ArrowBackIcon />
                            </IconButton>
                            <h4 className={""} style={{ marginBottom: '11px' }}>Verification Requests</h4>
                        </div>
                        <Card className={`${classes.mainCard} productCategory`} >
                            <GridContainer style={{ paddingBottom: "10px", margin: 0 }}>
                                <GridItem xs={12} sm={12} md={6} style={{ display: "flex", alignItems: "center" }}>
                                </GridItem>
                                <TableContainer className={classes.tcontainer}>
                                    <Table className={classes.table} aria-label="simple table" style={{ background: '#f3f3fc' }}>
                                        <TableHead className={classes.thead}>
                                            <TableRow>
                                                <StyledTableCell className={classes.firstHead}>Name</StyledTableCell>
                                                <StyledTableCell>Location</StyledTableCell>
                                                <StyledTableCell>Submitted On</StyledTableCell>
                                                <StyledTableCell align="right" className={classes.lastHead}>Actions</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{ padding: 10 }} className={"tbody-adj"} id="verificationData">
                                            {SPRequest && SPRequest.length > 0 && SPRequest.map((request) => (
                                                request !== null && <TableRow key={request.user._id}>
                                                    <TableCell component="th" className={classes.tcell1}>
                                                        <div className={classes.div1}>
                                                            <Typography variant="body1" className={classes.blockTitle}>
                                                                {request.user.name}
                                                            </Typography>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tcell2}>
                                                        <div className={classes.div2}>
                                                            {request.place_names ? request.place_names.join(', ') : ''}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tcell3}>
                                                        <div className={classes.div2}>

                                                            {new Date(request.user.created_at.replace(/-/g, "/")).toLocaleDateString().toString().split('T')[0]}
                                                        </div>
                                                    </TableCell>

                                                    <TableCell align="right" className={classes.tcell4}>
                                                        <div className={classes.div3}>
                                                            {/* <IconButton aria-label="edit" >
                                                    <EditIcon />
                                                </IconButton> */}
                                                            <BootstrapButton
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => gotoDetail(request)}
                                                                className={classes.button}
                                                            >Review
                                                            </BootstrapButton>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </GridContainer>
                        </Card>
                    </>
                    :
                    <>
                        {SPRequest.length === 0 ? <div style={{textAlign:'center',color:'#25233C',fontSize:'16px',fontFamily:'Muli'}}>No Activation Request</div> :
                            <div style={{ display: "flex", justifyContent: 'center', margin: '0 auto', alignItems: 'center', height: '80vh' }}>
                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </div>
                        }
                    </>
            }
        </div>

    )
}

export default SPVerification
