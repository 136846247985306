import React, { useState, useEffect } from 'react';
import AddPro from './AddPro'
const EditPro = (props) => {
    return (
        <>
            <AddPro {...props} />
         
        </>
    );
}

export default EditPro
