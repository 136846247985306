/* eslint-disable prettier/prettier */
import { BehaviorSubject } from 'rxjs';

import { handleResponse } from "_helpers/handle-response.js";

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));

export const processRefundService = {
    getProcessRefunds,
    updateStatus
}

function getProcessRefunds(){
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'valueby' : 'subcategory',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        }
    }
    return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/ProcessRefund`, requestOptions).then(handleResponse);
}

function updateStatus(obj){
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'valueby' : 'subcategory',
            token: JSON.parse(sessionStorage.getItem('currentUser')).token
        },
        body : JSON.stringify(obj)
    }
    return fetch(`${process.env.REACT_APP_URL}/ServiceProviderController/ProcessRefund`, requestOptions).then(handleResponse);
}
