import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { categoryService } from '_services/category.service';
import CreditTable from './CreditTable';
import { CSVLink } from "react-csv";
import Button from "components/CustomButtons/Button.js";
import CloudDownload from '@material-ui/icons/CloudDownload';
import CustomDateSelection from 'components/CustomDateSelection/CustomDateSelection';
import downloadIcon from "../../../assets/img/fat-arrow.svg";

const useStyles = makeStyles((theme) => ({
  button: {
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8d9d",
  },
  perPageSection: {
    position: "absolute",
    right: "3pc",
    top: "7pc",
  },
  displayFlex: {
    display: "flex"
  },
  btnAlign: {
    display: "flex",
    marginLeft: "5px",
  },
  h5: {
    fontSize: 18
  },
  container: {
    // border:"1px solid grey",
    margin: "0 -20px"
  },
  Imginput: {
    display: "none",

  },
  header: {
    display: "flex",
    alignItems: "flex-end"
  },
  Imginput: {
    display: "none"
  },
  boxStyle: {
    marginTop: "30px",
  },
  displayFlexJustifyEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  displayFlexJustifySpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  backButtonSize: {
    '& .MuiIconButton-root': {
      padding: '8px'
    }
  },
  fontSize20: {
    fontSize: '20px',
    marginBottom:'10px'
  },
  fontSize14: {
    fontSize: '14px'
  },
  width165px: {
    width: '165px'
  },
  displayFlexPaddingRight32: {
    display: 'flex',
    paddingRight: '32px',
    overflow: "auto !important"
  },
  tabs: {
    margin: "0px 10px 0px 10px",
    background: "#EAEDF7",
    borderBottom: '1px solid #DDDDE4',
    '& > div': {
      overflow: "auto !important"
    }
    // "&.MuiTabs-indicator": {
    //   display: "none"
    // }
  },
  tab: {
    "&.MuiButtonBase-root.MuiTab-root": {
      height: "45px",
      padding: "unset",
      minWidth: "90px",
      textTransform: "capitalize",
      fontWeight: "400",
    }
  },
  divPadColor: {
    background: "#F3F3FC",
    padding: '16px'
  },
  tcell1: {
    border: "unset",
    paddingRight: "unset",
    paddingBottom: "unset",
  },
  tcell2: {
    border: "unset",
    paddingLeft: "unset",
    paddingRight: "unset",
    paddingBottom: "unset",
  },
  padding16: {
    padding: '16px'
  },
  padding0: {
    "& .MuiBox-root": {
      padding: '0'
    },
  },
  marginLeft30Px: {
    marginLeft: '30px'
  }
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// /api/v1/CategoryController/getCreditsHistory
// {
//     "type":"weekly",
//     "from_date":"2022-03-02",
//     "to_date":"2020-03-25"
// }

// const spDetailArray = [
//   { name: 'ewqeqwe', services: 'jdjjd,djdj' },
//   { name: 'ewqeqwe', services: 'jdjjd,djdj' },
//   { name: 'ewqeqwe', services: 'jdjjd,djdj' },
//   { name: 'ewqeqwe', services: 'jdjjd,djdj' },
//   { name: 'ewqeqwe', services: 'jdjjd,djdj' },
//   { name: 'ewqeqwe', services: 'jdjjd,djdj' },
// ]
//https://xd.adobe.com/view/6f2fcf0a-ec74-4fd9-90a5-d0bc1375062f-3a7c/screen/87593b6e-885e-4815-a8f7-8eb31058dfa1/

const Credits = (props) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [spCrditList, setSpCrditList] = React.useState({
    "daily": [],
    "weekly": [],
    "monthly": [],
    "last_month": [],
    "custom": []
  })
  const [totalOfCredits, setTotalOfCreits] = React.useState({
    "daily": "",
    "weekly": "",
    "monthly": "",
    "last_month": "",
    "custom": ""
  })
  const [csvDownloadData, setCsvDownloadData] = React.useState(null);
  const [apiCall, setApiCall] = React.useState(false);
  const [csvDownloadDataForCustom, setCsvDownloadDataForCustom] = React.useState(null);
  const [showCustom, setShowCustom] = React.useState(false);
  const dalWeekMonth = ['daily', 'weekly', 'monthly', 'last_month'];

  // const arrSpCredit = [
  //   { 'name': 'Prashant Rawat', 'invoiceNo': '35453453453453', 'date': '12-3-2022', 'time': '12:00 am', 'credit': "1000" },
  //   { 'name': 'Prashant Rawat', 'invoiceNo': '35453453453453', 'date': '12-3-2022', 'time': '12:00 am', 'credit': "1000" },
  //   { 'name': 'Prashant Rawat', 'invoiceNo': '35453453453453', 'date': '12-3-2022', 'time': '12:00 am', 'credit': "1000" },
  //   { 'name': 'Prashant Rawat', 'invoiceNo': '35453453453453', 'date': '12-3-2022', 'time': '12:00 am', 'credit': "1000" }
  // ]
  const headersCsv = [
    { label: "Name", key: "name" },
    { label: "Invoice No", key: "invoiceNo" },
    { label: "Date", key: "date" },
    { label: "Time", key: "time" },
    { label: "credit", key: "credit" }

  ];

  // const csvReport = {
  //   data: arrSpCredit,
  //   headers: headersCsv,
  //   filename: 'ReportDaily.csv'
  // }

  // async function formatAMPM(date) {

  //   return strTime;
  // }

  React.useEffect(() => {

    Promise.all(
      dalWeekMonth.map(async (prop) => {
        setApiCall(true);
        const response = await categoryService.getAllSpCreditsAccordingToType({ type: `${prop}` });
        // console.log(response);
        let arr = [];

        if (response.data && response.data.length > 0) {
          // console.log('hello')
          response.data.map((data, index) => {
            let date = new Date(data.created_at.replace(/-/g, "/"));   // converted '-' to '/' , because conflicting in macOS
            let d = date.getDate();
            let m = date.toLocaleString('default', { month: 'long' });
            let y = date.getFullYear();
            let dateString = (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;

            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;

            let obj = {
              name: data.sp_name ? data.sp_name : data.serviceprovider_id,
              invoiceNo: data.payment_intent_id ? data.payment_intent_id : data.description,
              status: data.status,
              date: dateString,
              time: strTime,
              credit: `${data.credits} (${data.type})`
            }
            arr.push(obj);
          })
        }
        setSpCrditList(prevState => {
          return {
            ...prevState,
            [prop]: arr
          }
        }
        );
        setTotalOfCreits(prevState => {
          return {
            ...prevState,
            [prop]: response.total_credit
          }
        })
        setApiCall(false);
      })
    )

  }, [])


  React.useEffect(() => {
    if (spCrditList.daily.length > 0) {
      setCsvData('daily');
    }

  }, [spCrditList])

  function setCsvData(checkParameter) {

    if (checkParameter == "daily") {
      setShowCustom(false);
      if (spCrditList.daily.length > 0) {
        const csvReport2 = {
          data: spCrditList.daily,
          headers: headersCsv,
          filename: 'ReportDaily.csv'
        }
        setCsvDownloadData(csvReport2);
      }
      else {
        setCsvDownloadData(null);
      }
    }
    else if (checkParameter == "weekly") {
      setShowCustom(false);
      if (spCrditList.weekly.length > 0) {
        const csvReport2 = {
          data: spCrditList.weekly,
          headers: headersCsv,
          filename: 'ReportWeekly.csv'
        }
        setCsvDownloadData(csvReport2);
      }
      else {
        setCsvDownloadData(null);
      }
    }
    else if (checkParameter == "monthly") {
      setShowCustom(false);
      if (spCrditList.monthly.length > 0) {
        const csvReport2 = {
          data: spCrditList.monthly,
          headers: headersCsv,
          filename: 'ReportMonthly.csv'
        }
        setCsvDownloadData(csvReport2);
      }
      else {
        setCsvDownloadData(null);
      }
    }
    else if (checkParameter == "lastMonth") {
      setShowCustom(false);
      if (spCrditList.last_month.length > 0) {
        const csvReport2 = {
          data: spCrditList.last_month,
          headers: headersCsv,
          filename: 'ReportLastMonth.csv'
        }
        setCsvDownloadData(csvReport2);
      }
      else {
        // console.log('hello');
        setCsvDownloadData(null);
      }
    }

    else if (checkParameter == "custom") {
      setShowCustom(true);
      setCsvData(null);
      if (spCrditList.custom.length > 0) {
        const csvReport2 = {
          data: spCrditList.custom,
          headers: headersCsv,
          filename: 'ReportCustom.csv'
        }
        setCsvDownloadDataForCustom({ ...csvReport2 });
      }
      else {
        // console.log('hello');
        setCsvDownloadDataForCustom(null);
      }
    }

  }

  React.useEffect(() => {
    if (spCrditList.custom.length > 0) {
      setCsvData('custom');
    }
  }, [spCrditList.custom])


  const callbackFuncForCustom = async (fromDate, toDate) => {
    // console.log(fromDate, toDate);
    setApiCall(true);
    const response = await categoryService.getAllSpCreditsAccordingToType({ type: `custom`, from_date: fromDate, to_date: toDate });
    let arr = [];
    if (response.data.length > 0) {
      response.data.map((data, index) => {
        let date = new Date(data.created_at); // M-D-YYYY
        let d = date.getDate();
        let m = date.toLocaleString('default', { month: 'long' });
        let y = date.getFullYear();
        let dateString = (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;

        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        let obj = {
          name: data.sp_name ? data.sp_name : data.serviceprovider_id,
          invoiceNo: data.payment_intent_id ? data.payment_intent_id : "Added By Admin",
          status: data.status,
          date: dateString,
          time: strTime,
          credit: `${data.credits} (${data.type})`
        }
        arr.push(obj);
      })
    }
    setSpCrditList(prevState => {
      return {
        ...prevState,
        ['custom']: arr
      }
    }
    );
    setTotalOfCreits(prevState => {
      return {
        ...prevState,
        ['custom']: response.total_credit
      }
    })
    setApiCall(false);
  }



  return (
    <>
      <AppBar elevation={0} position="static" color="default" className={`${classes.appbarAdj}`} style={{ flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', backgroundColor: "transparent", marginTop: '20px', flexWrap: "wrap" }} >
        <div className={classes.header}>
          <IconButton aria-label="edit" onClick={() => { props.history.goBack() }} style={{ paddingLeft: '0px' }}>
            <ArrowBackIcon />
          </IconButton>
          <h4 className={classes.fontSize20} style={{fontSize: 20, fontFamily: "Muli-Bold", transform: 'translateY(-5px)'}}>Credits</h4>
        </div>

        <div className={classes.displayFlexPaddingRight32}>
          <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label="simple tabs example" TabIndicatorProps={{ style: { backgroundColor: "#6259ca" } }} textColor="primary">
            <Tab className={classes.tab} label="Today" {...a11yProps(0)} onClick={() => { setCsvData('daily') }} />
            <Tab className={classes.tab} label="Weekly" {...a11yProps(1)} onClick={() => { setCsvData('weekly') }} />
            <Tab className={classes.tab} label="Monthly" {...a11yProps(2)} onClick={() => { setCsvData('monthly') }} />
            <Tab className={classes.tab} label="Last Month" {...a11yProps(3)} onClick={() => { setCsvData('lastMonth') }} />
            <Tab className={classes.tab} label="Custom" {...a11yProps(4)} onClick={() => { setCsvData('custom') }} />
          </Tabs>
          {csvDownloadData !== null && showCustom == false &&
            <CSVLink {...csvDownloadData}>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<img style={{ width: "15px", height: "16.15px" }} src={downloadIcon} />}
              >
                <span style={{fontSize: 15}}>Download </span><span style={{textTransform: "uppercase", paddingLeft: "5px", fontSize: 15}}>csv</span>

              </Button>
            </CSVLink>
          }
          {csvDownloadDataForCustom !== null && showCustom == true &&
            <CSVLink {...csvDownloadDataForCustom}>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<img style={{ width: "15px", height: "16.15px" }} src={downloadIcon} />}
              >
                Download <span style={{ textTransform: "uppercase", paddingLeft: "5px" }}>csv</span>

              </Button>
            </CSVLink>
          }
        </div>

      </AppBar>
      {/* whenever use this class removePaddingTopFromCard padding top and bottom from card is removed and margin top is reduced to 8px-> material-dashboard-react.css */}
      <TabPanel className={'removePaddingTopFromCard removeInlinePad'} value={value} index={0}>
        <GridContainer >
          {
            spCrditList.daily.length > 0 && apiCall == false &&
            <CreditTable {...props} data={spCrditList.daily} totalCredit={totalOfCredits.daily} />
          }
          {apiCall == true &&
            <div style={{ display: "flex", justifyContent: 'center', marginTop: 20, width: '100%' }}>
              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
          }
          {
            spCrditList.daily.length == 0 && apiCall == false &&
            <Card style={{ padding: 20 }}>
              <h6 style={{ textAlign: 'center', color: '#6B6987' }}>No record Found</h6>
            </Card>
          }
        </GridContainer>
      </TabPanel>

      <TabPanel className={'removePaddingTopFromCard removeInlinePad'} value={value} index={1}>
        <GridContainer >
          {
            spCrditList.weekly.length > 0 && apiCall == false &&
            <CreditTable {...props} data={spCrditList.weekly} totalCredit={totalOfCredits.weekly} />
          }
          {
            spCrditList.weekly.length == 0 && apiCall == false &&
            <Card style={{ padding: 20 }}>
              <h6 style={{ textAlign: 'center', color: '#6B6987' }}>No record Found</h6>
            </Card>
          }
        </GridContainer>
      </TabPanel>

      <TabPanel className={'removePaddingTopFromCard removeInlinePad'} value={value} index={2}>
        <GridContainer >
          {
            spCrditList.monthly.length > 0 && apiCall == false &&
            <CreditTable {...props} data={spCrditList.monthly} totalCredit={totalOfCredits.monthly} />
          }
          {
            spCrditList.monthly.length == 0 && apiCall == false &&
            <Card style={{ padding: 20 }}>
              <h6 style={{ textAlign: 'center', color: '#6B6987' }}>No record Found</h6>
            </Card>
          }
        </GridContainer>
      </TabPanel>

      <TabPanel className={'removePaddingTopFromCard removeInlinePad'} value={value} index={3}>
        <GridContainer >

          {
            spCrditList.last_month.length > 0 && apiCall == false &&
            <CreditTable {...props} data={spCrditList.last_month} totalCredit={totalOfCredits.last_month} />
          }
          {
            spCrditList.last_month.length == 0 && apiCall == false &&
            <Card style={{ padding: 20 }}>
              <h6 style={{ textAlign: 'center', color: '#6B6987' }}>No record Found</h6>
            </Card>
          }

        </GridContainer>
      </TabPanel>
      <TabPanel className={'removePaddingTopFromCard removeInlinePad'} value={value} index={4}>
        <GridContainer >

          <CustomDateSelection {...props} callbackFuncForCustom={callbackFuncForCustom} />

          {
            spCrditList.last_month.length > 0 && apiCall == false &&
            <CreditTable {...props} data={spCrditList.custom} totalCredit={totalOfCredits.custom} />
          }
          {
            spCrditList.custom.length == 0 && apiCall == false &&
            <Card style={{ padding: 20 }}>
              <h6 style={{ textAlign: 'center', color: '#6B6987' }}>No record Found</h6>
            </Card>
          }
          {apiCall == true &&
            <div style={{ display: "flex", justifyContent: 'center', marginTop: 20, width: '100%' }}>
              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
          }
        </GridContainer>
      </TabPanel>
    </>
  );
}

export default Credits