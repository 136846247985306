import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  "emailInput" : {
    "font-size": "11px"
  },
  cardTitle: {
    ...cardTitle,
    color: '#8E8CAC',
    fontWeight: "bold",

  },
  cardBody: {
    padding: "5px 13px"
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    // "&,&:focus,&:hover": {
    //   color: whiteColor,
    // },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "5px",
    backgroundColor: "#f5f5fc",
    color: "#8e8cac"
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  loginButton: {
    backgroundColor: '#6362ca',
    color: '#ffffff',
    width: '100%',
    fontSize: "10px",
    padding: "10px",
    "&:hover": {
      backgroundColor: '#6362ca',
      color: '#ffffff',
    },
    "&:focus": {
      backgroundColor: '#6362ca',
      color: '#ffffff',
    }
  },
  resetButton: {
    backgroundColor: '#6362ca',
    color: '#ffffff',
    width: '100%'
  },
  forgotlink: {
    textDecoration: 'none!important',
    color: "#8e8cac!important"
  },
  centerdiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  h4: {
    fontSize: "12px"
  },
  h5: {
    fontSize: "10px"
  },
  link :{
    fontSize: "10px"
  }
});

export default loginPageStyle;
