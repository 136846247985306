import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "_components/Grid/GridContainer.js";
import GridItem from "_components/Grid/GridItem.js";
import Button from "_components/CustomButtons/Button.js";
import Card from "_components/Card/Card.js";
import CardBody from "_components/Card/CardBody.js";
import CardIcon from "_components/Card/CardIcon.js";
import CardHeader from "_components/Card/CardHeader.js";
import ReactTable from "_components/ReactTable.js";
import { bookingAndRevenueService } from "_services/bookingAndRevenue.service";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch
} from "react-router-dom";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  tableCard:{
    "@media (max-width: 1340px)":{
        overflow: "auto"
    }
  },
  tableCardBody:{
  padding: 0,
  "@media (max-width: 1340px)":{
        width: "991px",
    }
  }
};

const useStyles = makeStyles(styles);

const BookingHistory = (props) => {
  const { slug } = useParams();

  React.useEffect(() => {
    const getBookingHistory = async () => {
      setIsLoading(true);
      const objForApi = {
        spid: slug,
        status: 'completed'
      }
      try {
        const res = await bookingAndRevenueService.getSpBookingListing(objForApi);
        let arr = [];
        if (res) {
          res.map((prop) => {
            var strForTitle = '';

            let services = [];

            prop.services?.map((eachService, index) => {
              // const obj = Object.assign({}, ...prop2 );
              // strForTitle=strForTitle.concat(obj.title,', ')
              services.push(eachService)
            });
            prop.packages?.map((eachPackage, index) => {
              // const obj=Object.assign({}, ...prop3 );
              // strForTitle=strForTitle.concat(obj.title,', ')
              services.push(eachPackage)
            })

            // let services=strForTitle.substring(0,strForTitle.length-2)

            let obj = {
              // clientName: prop.customer_name,
              clientName: prop.customer[0].name,
              date: new Date(prop.created_at.replace(/-/g, "/")).toISOString().split('T')[0],
              serviceBooked: services.join(', '),
              creditDeduct: prop.credit_deducted == null ? '0' : prop.credit_deducted,
              bill: prop.amount
            }
            arr.push(obj);
          })
        }
        setData([...arr]);
        setIsLoading(false);
      }
      catch (err) {
        // console.log(err);
        setIsLoading(false);
      }
    }
    getBookingHistory();
  }, []);

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();


  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card className={classes.tableCard}>
          {/* <CardHeader color="primary" icon>
               <CardIcon color="primary">
                 <Assignment />
               </CardIcon>
               <h4 className={classes.cardIconTitle}>Booking</h4>
             </CardHeader> */}
          <CardBody className={classes.tableCardBody}>
            {
              data.length > 0 &&
              <ReactTable
                columns={[
                  {
                    Header: "Client Name",
                    accessor: "clientName",
                  },
                  {
                    Header: "Date",
                    accessor: "date",
                  },
                  {
                    Header: "Services Taken",
                    accessor: "serviceBooked",
                  },
                  {
                    Header: "Bill Total",
                    accessor: "bill",
                    Footer: "Bill Total"
                  },
                  {
                    Header: "Credits Deducted",
                    accessor: "creditDeduct",
                    Searchable: false
                  },
                ]}
                data={data}
                onRowClick={(rowData, clickType) => { }}
              />
            }
            {
              isLoading == false && data.length <= 0 &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h6>No Bookings</h6>
              </div>
            }
            {isLoading == true &&
              <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
            }
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default BookingHistory
