import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// import { Formik, Field, Form, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { configurationService } from "_services/configuration.service";
import SweetAlert from "react-bootstrap-sweetalert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton, TextField, Grid, Box } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import { categoryService } from "_services/category.service";

const useStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    right: "3pc",
    top: "7pc",
    background: "#fff",
    boxShadow: "unset",
    textTransform: "capitalize",
    color: "#8f8d9d",
  },
  SaveButton: {
    backgroundColor: "#6362ca",
    color: "#ffffff",
    width: 100,
    fontSize: "10px",
    padding: "10px",
    textTransform: "capitalize",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#6362ca",
      color: "#ffffff",
    },
    "&:focus": {
      backgroundColor: "#6362ca",
      color: "#ffffff",
    },
  },
  displayFlex: {
    display: "flex",
  },
  DelButton: {
    border: "1px solid grey",
    color: "#8E8CAC",
    textTransform: "capitalize",
    fontSize: 14,
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#A8A8A8",
      color: "#ffffff",
    },
    "&:focus": {
      backgroundColor: "#A8A8A8",
      color: "#ffffff",
    },
  },
  btnAlign: {
    display: "flex",
    marginLeft: "5px",
  },
  h5: {
    fontSize: 18,
  },
  container: {
    // border:"1px solid grey",
    margin: "0 -20px",
  },
  Imginput: {
    display: "none",
  },
  UploadBtn: {
    padding: "10px 15px",
    fontSize: "14px",
    textTransform: "capitalize",
    marginBottom: "7px",
    background: "#8E8CAC",
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
  },
  Imginput: {
    display: "none",
  },
  boxStyle: {
    marginTop: "30px",
  },
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid red",
    },
  },
  displayFlexJustifyEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  fontSize20: {
    fontSize: "20px",
    marginBottom: "10px",
    color: "#25233C",
  },
}));

const AddBadge = (props) => {
  const classes = useStyles();

  function getToPrevious() {
    const { from } = { from: { pathname: "/app/product_services/badges" } };
    props.history.goBack();
  }

  const [badgeData, setBadgeData] = React.useState({
    type: "",
    id: "",
    title: "",
    icon: null,
    iconName: "",
    containerColor: "",
    textColor: "",
    outlineColor: "",
    width: "",
  });

  const [errorBadge, setErrorBadge] = React.useState({
    title: false,
    icon: false,
    containerColor: false,
    textColor: false,
    outlineColor: false,
    width: false,
  });

  const [alert, setAlert] = React.useState(null);
  const [alertDelete, setAlertDelete] = React.useState(null);

  React.useEffect(() => {
    if (
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == "editBadge"
    ) {
      setBadgeData(props.location.state);
    } else {
    }
  }, []);

  const handleBadgeData = (event) => {
    if (event.target.value.length > 0) {
      setErrorBadge({ ...errorBadge, [event.target.name]: false });
      setBadgeData({ ...badgeData, [event.target.name]: event.target.value });
    } else {
      setErrorBadge({ ...errorBadge, [event.target.name]: true });
      setBadgeData({ ...badgeData, [event.target.name]: event.target.value });
    }
  };

  const handleImageUpload = (event) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setBadgeData({
          ...badgeData,
          icon: event.target.files[0],
          iconName: event.target.files[0].name,
        });
        setErrorBadge({ ...errorBadge, icon: false });
      }
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  function SweetAlertConfirm(data) {
    const getAlert = () => (
      <SweetAlert
        success
        title="Woot!"
        onConfirm={() => {
          if (data == "updated") {
            setAlert(null);
            props.history.goBack();
          } else if (data == "added") {
            setAlert(null);
            props.history.goBack();
          } else if (data == "deleted") {
            setAlert(null);
            props.history.goBack();
          }
        }}
      >
        Data {data} successfully
      </SweetAlert>
    );
    setAlert(getAlert());
  }

  const saveBadge = () => {
    if (
      badgeData?.title?.length == undefined ||
      badgeData?.title?.length == 0
    ) {
      setErrorBadge({ title: true });
    } else if (
      badgeData?.iconName?.length == undefined ||
      badgeData?.iconName?.length == 0
    ) {
      setErrorBadge({ icon: true });
    } else if (
      badgeData?.width?.length == undefined ||
      badgeData?.width?.length == 0
    ) {
      setErrorBadge({ width: true });
    } else if (
      badgeData?.containerColor?.length == undefined ||
      badgeData?.containerColor?.length == 0
    ) {
      setErrorBadge({ containerColor: true });
    } else if (
      badgeData?.outlineColor?.length == undefined ||
      badgeData?.outlineColor?.length == 0
    ) {
      setErrorBadge({ outlineColor: true });
    } else if (
      badgeData?.textColor?.length == undefined ||
      badgeData?.textColor?.length == 0
    ) {
      setErrorBadge({ textColor: true });
    } else {
      const obj = {
        type: "manual",
        title: badgeData.title,
        outline_color: badgeData.outlineColor,
        text_color: badgeData.textColor,
        icon: badgeData.icon,
        height: 20,
        width: badgeData.width,
      };
      categoryService.postBadge(obj).then((res) => {
        SweetAlertConfirm("added");
      });
    }
  };

  const deleteBadge = (id) => {
    categoryService.deleteBadge(id).then((res) => {
      SweetAlertConfirm("deleted");
    });
  };

  function SweetAlertConfirmDelete(id) {
    const getAlert = () =>
      id && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Are you sure?"
          onConfirm={() => {
            setAlertDelete(null);
            deleteBadge(id);
          }}
          onCancel={() => setAlertDelete(null)}
          focusCancelBtn
        >
          You want to delete this record?
        </SweetAlert>
      );
    setAlertDelete(getAlert());
  }

  const updateBadge = () => {
    if (
      badgeData?.title?.length == undefined ||
      badgeData?.title?.length == 0
    ) {
      setErrorBadge({ title: true });
    } else if (
      badgeData?.iconName?.length == undefined ||
      badgeData?.iconName?.length == 0
    ) {
      setErrorBadge({ icon: true });
    } else if (
      badgeData?.width?.length == undefined ||
      badgeData?.width?.length == 0
    ) {
      setErrorBadge({ width: true });
    } else if (
      badgeData?.containerColor?.length == undefined ||
      badgeData?.containerColor?.length == 0
    ) {
      setErrorBadge({ containerColor: true });
    } else if (
      badgeData?.outlineColor?.length == undefined ||
      badgeData?.outlineColor?.length == 0
    ) {
      setErrorBadge({ outlineColor: true });
    } else if (
      badgeData?.textColor?.length == undefined ||
      badgeData?.textColor?.length == 0
    ) {
      setErrorBadge({ textColor: true });
    } else {
      const obj = {};
      if (badgeData.icon) {
        (obj.id = badgeData.id),
          (obj.type = "manual"),
          (obj.title = badgeData.title),
          (obj.container_color = badgeData.containerColor),
          (obj.outline_color = badgeData.outlineColor),
          (obj.text_color = badgeData.textColor),
          (obj.height = 20);
        obj.width = badgeData.width;
        obj.icon = badgeData.icon;
      } else {
        (obj.id = badgeData.id),
          (obj.type = "manual"),
          (obj.title = badgeData.title),
          (obj.container_color = badgeData.containerColor),
          (obj.outline_color = badgeData.outlineColor),
          (obj.text_color = badgeData.textColor);
        obj.height = 20;
        obj.width = badgeData.width;
        obj.icon = badgeData.iconName;
      }
      categoryService.updateBadge(obj).then((res) => {
        console.log("res", res);
        SweetAlertConfirm("updated");
      });
    }
  };

  return (
    <>
      {
        <div className={classes.container}>
          <GridContainer style={{ width: "100%" }}>
            <GridItem xs={12} sm={12} md={8}>
              <div>
                <div className={classes.header}>
                  <IconButton
                    aria-label="edit"
                    onClick={getToPrevious}
                    style={{ paddingLeft: "0px", marginLeft: "-4px" }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  {window.location.href.split("/")[
                    window.location.href.split("/").length - 1
                  ] == "editBadge" && (
                    <h4
                      className={classes.fontSize20}
                      style={{
                        fontSize: 20,
                        fontFamily: "Muli-Bold",
                        transform: "translateY(-5px)",
                        color: "#25233C",
                      }}
                    >
                      Edit Badge
                    </h4>
                  )}
                  {window.location.href.split("/")[
                    window.location.href.split("/").length - 1
                  ] == "addBadge" && (
                    <h4
                      className={classes.fontSize20}
                      style={{
                        fontSize: 20,
                        fontFamily: "Muli-Bold",
                        transform: "translateY(-5px)",
                        color: "#25233C",
                      }}
                    >
                      Badge
                    </h4>
                  )}
                </div>
              </div>
            </GridItem>
          </GridContainer>

          <form style={{ padding: "0px 20px", maxWidth: 1250 }}>
            <Card style={{ paddingBottom: "20px" }}>
              {/* <CardHeader
                                            className={`${classes.cardHeader}`}
                                        >
                                            <h4 className={`${classes.cardTitle} ${classes.h4} `}>Add Category</h4>
                                        </CardHeader> */}
              <CardBody className="px-0 pb-0"></CardBody>

              <div style={{ padding: "0px 30px" }}>
                <GridContainer>
                  <GridItem
                    id="boxSize"
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.boxStyle}
                  >
                    <Box>
                      <InputLabel
                        htmlFor="title"
                        style={{
                          fontSize: "18px",
                          fontFamily: "Muli-SemiBold",
                          color: "#25233C",
                        }}
                      >
                        Title*
                      </InputLabel>
                      <TextField
                        // label="Working At (Optional)"
                        disabled={
                          badgeData.type.length > 0 && badgeData.type == "auto"
                            ? true
                            : false
                        }
                        className={errorBadge.title == true ? classes.root : ""}
                        variant="outlined"
                        placeholder="Title"
                        fullWidth={true}
                        size="small"
                        name="title"
                        value={badgeData.title}
                        onChange={handleBadgeData}
                        onBlur={handleBadgeData}
                      />
                      {errorBadge.title == true && (
                        <p style={{ color: "red", marginBottom: 0 }}>
                          * This Field is required
                        </p>
                      )}
                    </Box>
                  </GridItem>

                  <GridItem
                    id="boxSize"
                    xs={12}
                    sm={6}
                    md={4}
                    style={{ marginTop: "20px" }}
                  >
                    <Box>
                      <InputLabel
                        htmlFor="icon"
                        style={{
                          fontSize: "18px",
                          fontFamily: "Muli-SemiBold",
                          color: "#25233C",
                        }}
                      >
                        Add Icon(Recommended png, jpg, jpeg format)*
                      </InputLabel>
                      <Box className={`${classes.displayFlex}`}>
                        <Box style={{ marginRight: "20px" }}>
                          <TextField
                            className={
                              errorBadge.icon == true ? classes.root : ""
                            }
                            variant="outlined"
                            placeholder="No file selected"
                            size="small"
                            name="icon"
                            value={badgeData.iconName}
                            inputProps={{ readOnly: true }}
                          />
                        </Box>
                        <Box>
                          <input
                            accept="image/*"
                            className={classes.Imginput}
                            id={`iconButton`}
                            name={`iconButton`}
                            onChange={(event) => handleImageUpload(event)}
                            type="file"
                          />
                          <label htmlFor={`iconButton`}>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                            >
                              Upload
                            </Button>
                          </label>
                        </Box>
                      </Box>
                      {errorBadge.icon == true && (
                        <p style={{ color: "red", marginBottom: 0 }}>
                          * This Field is required
                        </p>
                      )}
                    </Box>
                  </GridItem>
                  <GridItem
                    id="boxSize"
                    xs={12}
                    sm={6}
                    md={4}
                    style={{ marginTop: "20px" }}
                  >
                    <Box>
                      <InputLabel
                        htmlFor="height"
                        style={{
                          fontSize: "18px",
                          fontFamily: "Muli-SemiBold",
                          color: "#25233C",
                        }}
                      >
                        Height
                      </InputLabel>
                      <TextField
                        // label="Working At (Optional)"
                        className={
                          errorBadge.height == true ? classes.root : ""
                        }
                        variant="outlined"
                        placeholder="Enter Height"
                        fullWidth={true}
                        size="small"
                        name="height"
                        value={20}
                        disabled={true}
                      />
                    </Box>
                    {errorBadge.height == true && (
                      <p style={{ color: "red", marginBottom: 0 }}>
                        * This Field is required
                      </p>
                    )}
                  </GridItem>

                  <GridItem
                    id="boxSize"
                    xs={12}
                    sm={6}
                    md={4}
                    style={{ marginTop: "20px" }}
                  >
                    <Box>
                      <InputLabel
                        htmlFor="width"
                        style={{
                          fontSize: "18px",
                          fontFamily: "Muli-SemiBold",
                          color: "#25233C",
                        }}
                      >
                        Width(Recommended maxwidth: 120)*
                      </InputLabel>
                      <TextField
                        // label="Working At (Optional)"
                        className={errorBadge.width == true ? classes.root : ""}
                        variant="outlined"
                        placeholder="Enter Width"
                        fullWidth={true}
                        size="small"
                        name="width"
                        value={badgeData.width}
                        onChange={handleBadgeData}
                        onBlur={handleBadgeData}
                        type="number"
                      />
                    </Box>
                    {errorBadge.width == true && (
                      <p style={{ color: "red", marginBottom: 0 }}>
                        * This Field is required
                      </p>
                    )}
                  </GridItem>
                  <GridItem id="containerText" xs={12} sm={12} md={4}>
                    <Box className={classes.boxStyle}>
                      <InputLabel
                        htmlFor="containerColor"
                        style={{
                          fontSize: "18px",
                          fontFamily: "Muli-SemiBold",
                          color: "#25233C",
                        }}
                      >
                        Give a container color*
                      </InputLabel>
                      <TextField
                        // label="Working At (Optional)"
                        className={
                          errorBadge.containerColor == true ? classes.root : ""
                        }
                        variant="outlined"
                        placeholder="#000000"
                        fullWidth={true}
                        size="small"
                        name="containerColor"
                        value={badgeData.containerColor}
                        onChange={handleBadgeData}
                        onBlur={handleBadgeData}
                      />
                    </Box>
                    {errorBadge.containerColor == true && (
                      <p style={{ color: "red", marginBottom: 0 }}>
                        * This Field is required
                      </p>
                    )}
                  </GridItem>
                  <GridItem id="containerText" xs={12} sm={12} md={4}>
                    <Box className={classes.boxStyle}>
                      <InputLabel
                        htmlFor="outlineColor"
                        style={{
                          fontSize: "18px",
                          fontFamily: "Muli-SemiBold",
                          color: "#25233C",
                        }}
                      >
                        Container Outline Color*
                      </InputLabel>
                      <TextField
                        // label="Working At (Optional)"
                        className={
                          errorBadge.outlineColor == true ? classes.root : ""
                        }
                        variant="outlined"
                        placeholder="#000000"
                        fullWidth={true}
                        size="small"
                        name="outlineColor"
                        value={badgeData.outlineColor}
                        onChange={handleBadgeData}
                        onBlur={handleBadgeData}
                      />
                    </Box>
                    {errorBadge.outlineColor == true && (
                      <p style={{ color: "red", marginBottom: 0 }}>
                        * This Field is required
                      </p>
                    )}
                  </GridItem>
                  <GridItem id="containerText" xs={12} sm={12} md={4}>
                    <Box className={classes.boxStyle}>
                      <InputLabel
                        htmlFor="textColor"
                        style={{
                          fontSize: "18px",
                          fontFamily: "Muli-SemiBold",
                          color: "#25233C",
                        }}
                      >
                        Give a text color*
                      </InputLabel>
                      <TextField
                        // label="Working At (Optional)"
                        className={
                          errorBadge.textColor == true ? classes.root : ""
                        }
                        variant="outlined"
                        placeholder="#000000"
                        fullWidth={true}
                        size="small"
                        name="textColor"
                        value={badgeData.textColor}
                        onChange={handleBadgeData}
                        onBlur={handleBadgeData}
                      />
                    </Box>
                    {errorBadge.textColor == true && (
                      <p style={{ color: "red", marginBottom: 0 }}>
                        * This Field is required
                      </p>
                    )}
                  </GridItem>
                  {window.location.href.split("/")[
                    window.location.href.split("/").length - 1
                  ] == "editBadge" && (
                    <>
                      <GridItem id="containerText" xs={6} sm={6} md={6}>
                        <Box className={classes.boxStyle}>
                          <Button
                            className={classes.showBtn}
                            variant="contained"
                            color="primary"
                            onClick={updateBadge}
                          >
                            update
                          </Button>
                        </Box>
                      </GridItem>
                      {/* {console.log(badgeData.type.length,"badgeData.type.length")} */}
                      {(badgeData.type == 0 || badgeData.type !== "auto") && (
                        <GridItem xs={6} sm={6} md={6}>
                          <Box
                            className={`${classes.boxStyle} ${classes.displayFlexJustifyEnd}`}
                          >
                            <Button
                              className={classes.DelButton}
                              variant="contained"
                              onClick={() => {
                                SweetAlertConfirmDelete(badgeData.id);
                              }}
                            >
                              Delete
                            </Button>
                          </Box>
                        </GridItem>
                      )}
                    </>
                  )}
                  {window.location.href.split("/")[
                    window.location.href.split("/").length - 1
                  ] == "addBadge" && (
                    <GridItem xs={12} sm={12} md={4}>
                      <Box className={classes.boxStyle}>
                        <Button
                          className={classes.showBtn}
                          variant="contained"
                          color="primary"
                          onClick={saveBadge}
                        >
                          Save
                        </Button>
                      </Box>
                    </GridItem>
                  )}
                </GridContainer>
              </div>
            </Card>
          </form>
        </div>
      }
      <>{alert}</>
      <>{alertDelete}</>
    </>
  );
};

export default AddBadge;
