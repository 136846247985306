/* eslint-disable prettier/prettier */

import { authHeader } from "_helpers/auth-header.js";
import { handleResponse } from "_helpers/handle-response";

export const userService = {
  getUserAll,
  getAllSPs,
  getUser,
  getSP,
  sendSPVerificationRequest,
  verifyServiceProviderBackground,
  getCustomerList,
  getCustomerDetail,
  getUserDashBoardData,
  getSPDashBoardData,
  getSPVerificationList,
  verifyVerificationRequest,
  deleteVerificationRequest,
  getAllSPPromotions,
  postSPPromotions,
  getAllSPCredit,
  postSPCredit,
  deductSPCredit,
  manageAccountSetting,
  activateUser,
  deactivateUser,
  putUser,
  userImageUpdate,
  getDetailsOfSpRegistration,
  postUser,
  activateServiceProvider,
  getAllAdminNotification,
  getUserMessageList,
  getUserOneFullChat,
  updateNotificationStatus,
  deletePreviousWork,
};

function getUserAll() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/userAll`,
    requestOptions
  ).then(handleResponse);
}

function getAllSPs() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };

  return fetch(
    `${process.env.REACT_APP_URL}/ServiceProviderController/allServiceProvider`,
    requestOptions
  ).then(handleResponse);
}

function getUser(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
      id: id,
    },
  };
  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/user`,
    requestOptions
  ).then(handleResponse);
}

function getUserDashBoardData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/userdashnoard`,
    requestOptions
  ).then(handleResponse);
}

function getSP(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      id: id,
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };
  return fetch(
    `${process.env.REACT_APP_URL}/ServiceProviderController/ServiceProvider`,
    requestOptions
  ).then(handleResponse);
}

function sendSPVerificationRequest(data) {
  var myHeaders = new Headers();
  myHeaders.append(
    "token",
    JSON.parse(sessionStorage.getItem("currentUser")).token
  );

  var formdata = new FormData();
  formdata.append("verificationfile", data.image);
  formdata.append("id", data.id);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_URL}/ServiceProviderController/verificationRequest`,
    requestOptions
  ).then(handleResponse);
}

function verifyServiceProviderBackground(data) {
  var myHeaders = new Headers();
  myHeaders.append(
    "token",
    JSON.parse(sessionStorage.getItem("currentUser")).token
  );
  myHeaders.append("content-type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_URL}/ServiceProviderController/verifyBackground`,
    requestOptions
  ).then(handleResponse);
}

function getCustomerList() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };
  return fetch(
    `${process.env.REACT_APP_URL}/CustomerController/CustomerAll`,
    requestOptions
  ).then(handleResponse);
}

function getCustomerDetail(data) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      id: data.id,
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };
  return fetch(
    `${process.env.REACT_APP_URL}/CustomerController/Customer`,
    requestOptions
  ).then(handleResponse);
}

function getSPDashBoardData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/spdashnoard`,
    requestOptions
  ).then(handleResponse);
}

function getSPVerificationList() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };

  return fetch(
    `${process.env.REACT_APP_URL}/ServiceProviderController/verificationRequest`,
    requestOptions
  ).then(handleResponse);
}

function verifyVerificationRequest(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  return fetch(
    `${process.env.REACT_APP_URL}/ServiceProviderController/verifyBackground`,
    requestOptions
  ).then(handleResponse);
}

function deleteVerificationRequest(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  return fetch(
    `${process.env.REACT_APP_URL}/ServiceProviderController/verifyBackground`,
    requestOptions
  ).then(handleResponse);
}

function getAllSPPromotions(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      spid: id,
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };
  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/Promocode`,
    requestOptions
  ).then(handleResponse);
}

function postSPPromotions(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/Promocode`,
    requestOptions
  ).then(handleResponse);
}

function getAllSPCredit(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      spid: id,
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };
  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/credit`,
    requestOptions
  ).then(handleResponse);
}

function postSPCredit(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/addcreditsbyadmin`,
    requestOptions
  ).then(handleResponse);
}

function deductSPCredit(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/deductcreditsbyadmin`,
    requestOptions
  ).then(handleResponse);
}

function manageAccountSetting(obj) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify(obj),
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/acceptuser`,
    requestOptions
  ).then(handleResponse);
}

function activateUser(obj) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify(obj),
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/activateuser`,
    requestOptions
  ).then(handleResponse);
}

function deactivateUser(obj) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify(obj),
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/deactivateuser`,
    requestOptions
  ).then(handleResponse);
}

function putUser(obj) {
  var myHeaders = new Headers();
  myHeaders.append(
    "token",
    JSON.parse(sessionStorage.getItem("currentUser")).token
  );
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify(obj),
  };
  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/updateuser`,
    requestOptions
  ).then(handleResponse);
}

function userImageUpdate(data) {
  var myHeaders = new Headers();
  myHeaders.append(
    "token",
    JSON.parse(sessionStorage.getItem("currentUser")).token
  );

  var formdata = new FormData();
  formdata.append("id", data.id);
  formdata.append("imagefile", data.image);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/UpdateImage`,
    requestOptions
  ).then(handleResponse);
}

function getDetailsOfSpRegistration(obj) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify(obj),
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/RegisteredReports`,
    requestOptions
  ).then(handleResponse);
}

function postUser(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Connection: "keep-alive" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/user`,
    requestOptions
  ).then(handleResponse);
}

// function getUserPros(data) {
//   const requestOptions = {
//     method: "GET",
//     headers: {
//       'Content-Type': 'application/json',
//       'Connection': 'keep-alive',
//       'id': data.id
//       , token: JSON.parse(sessionStorage.getItem('currentUser')).token
//     }
//   };
//   return fetch(`${process.env.REACT_APP_URL}/CustomerController/Customer`, requestOptions).then(handleResponse);

// }

function activateServiceProvider(data) {
  var myHeaders = new Headers();
  myHeaders.append(
    "token",
    JSON.parse(sessionStorage.getItem("currentUser")).token
  );

  var formdata = new FormData();
  formdata.append("id", data.id);
  formdata.append("active", data.active);
  formdata.append("description", "");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/activateServiceProvider`,
    requestOptions
  ).then(handleResponse);
}

function getAllAdminNotification(obj) {
  // console.log(obj);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify(obj),
  };
  // console.log(requestOptions);
  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/getAllnotification`,
    requestOptions
  ).then(handleResponse);
}

function updateNotificationStatus() {
  // console.log(obj);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
  };
  // console.log(requestOptions);
  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/updateNotificationStatus`,
    requestOptions
  ).then(handleResponse);
}

function getUserMessageList(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
      customerid: id,
    },
  };
  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/UserMessageList`,
    requestOptions
  ).then(handleResponse);
}

function getUserOneFullChat(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
      uniqueid: id,
    },
  };
  return fetch(
    `${process.env.REACT_APP_URL}/UsersController/UserOneFullChat`,
    requestOptions
  ).then(handleResponse);
}

function deletePreviousWork(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      token: JSON.parse(sessionStorage.getItem("currentUser")).token,
    },
    body: JSON.stringify(id),
  };
  return fetch(
    "https://bookapro.ebizonstaging.com/LumenConnection/public/api/v1/app/SpAppController/PreviousWork",
    requestOptions
  ).then(handleResponse);
}
