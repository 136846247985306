import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";

import Store from "@material-ui/icons/Store";
import NotificationsIcon from '@material-ui/icons/Notifications';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BorderAllRoundedIcon from '@material-ui/icons/BorderAllRounded';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from '@material-ui/core/Card'
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ProductServicesChild from "./ProductServicesChild";
import AddConfiguration from "./Configuration/AddConfiguration.js";
import Services from "./ServicesPackages/Services.js"
import ProcessRefundRequest from "./ProcessRefund/ProcessRefundRequest";
import Badges from "./Badges/badges";
import FeaturedList from "./FeaturedListing/FeaturedList";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import AdminPromotions from "./Promotions/AdminPromotions";
import AdManager from "./AdManager/AdManager";
import Credits from "./Credits/Credits";
import TopPros from "./TopPros/TopPros";
import Notification from "./Notification/Notification";
import PackageImage from "./PackageImage/PackageImage";
import CampaignIcon from '@mui/icons-material/Campaign';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import protect from '../../protect-safe-secure-svgrepo-com.svg';
import badge from '../../certificate-badge-svgrepo-com.svg';
const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
    },
    spaceInline: {
        paddingInline: 0,
    },
    cardBody: {
        fontSize: 15,
        color: "#25233C",
        alignSelf: "center",
        paddingLeft: 10
    },
    cardHeading: {
        fontSize: 15,
        color: "#25233C",
        alignSelf: "center",
        paddingLeft: 10,
        marginBottom: 0
    },
    cardBodyWrapper: { padding: "3.5em", display: "flex", justifyContent: "center", maxHeight: "150px", "@media (max-width: 576px)": { justifyContent: "flex-center" } },
    cardIconAdj: { height: 40, width: 40, margin: "unset !important" },
    IconAdj: { height: "20px !important", width: "20px !important", margin: "unset !important", color: "#fff" },
    productCard: { marginBottom: ".5rem", marginTop: ".5rem", boxShadow: "0px 3px 6px #0000001A",
 },
    iconColor1: {
        borderRadius: "50%",
        backgroundColor: "#20C997",
        display: "grid",
        placeContent: "center",
        border:'1px solid #1EDBA3'
    },
    iconColor2: {
        borderRadius: "50%",
        backgroundColor: "#5F63F2",
        display: "grid",
        placeContent: "center",
        border:'1px solid #7D81FE'
    },
    iconColor3: {
        borderRadius: "50%",
        backgroundColor: "#FA8B0C",
        display: "grid",
        placeContent: "center",
        border:'1px solid #FFB561'
    },
    iconColor4: {
        borderRadius: "50%",
        backgroundColor: "#008080",
        display: "grid",
        placeContent: "center",
        border:'1px solid #11B1B1'
    },
    iconColor5: {
        borderRadius: "50%",
        backgroundColor: "#A8C920",
        display: "grid",
        placeContent: "center",
        border:'1px solid #D6EF71'
    },
    iconColor6: {
        borderRadius: "50%",
        backgroundColor: "#004AAC",
        display: "grid",
        placeContent: "center",
        border:'1px solid #3F86E3'
    },
    iconColor7: {
        borderRadius: "50%",
        backgroundColor: "#AC6D00",
        display: "grid",
        placeContent: "center",
        border:'1px solid #CC8509'
    },
    iconColor8: {
        borderRadius: "50%",
        backgroundColor: "#002F80",
        display: "grid",
        placeContent: "center",
        border:'1px solid #4171C2'
    },
    iconColor9: {
        borderRadius: "50%",
        backgroundColor: "#FF6DA7",
        display: "grid",
        placeContent: "center",
        border:'1px solid #FFA5C9'
    },

}));

export default function ProductServices(props) {
    const routePath = props.location.pathname;
    let { path, url } = useRouteMatch();
    const classes = useStyles();
    return (
        <div className={classes.spaceInline}>
            {props.location.pathname.split('/').length == 3 && <div className="row">
                <GridContainer className={classes.root}>
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Link to={`${url}/notification`} >
                            <Card className={classes.productCard}>
                                <CardBody className={classes.cardBodyWrapper}>
                                    <CardIcon  className={`${classes.iconColor1} ${classes.cardIconAdj}`}>
                                        <NotificationsIcon className={classes.IconAdj} />
                                    </CardIcon>
                                    <h4 className={classes.cardHeading}>Notification</h4>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Link to={`${url}/featuredListing`} >
                            <Card className={classes.productCard} style={{ borderRadius: '8px' }} >
                                <CardBody className={classes.cardBodyWrapper}>
                                    <CardIcon className={`${classes.iconColor2} ${classes.cardIconAdj}`}>
                                        <FormatListBulletedIcon className={classes.IconAdj} />
                                    </CardIcon>
                                    <h4 className={classes.cardHeading}>Featured Listings</h4>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Link to={`${url}/categories`} >
                            <Card className={classes.productCard} style={{ borderRadius: '8px' }}>
                                <CardBody className={classes.cardBodyWrapper}>
                                    <CardIcon className={`${classes.iconColor3} ${classes.cardIconAdj}`}>
                                        <BorderAllRoundedIcon className={classes.IconAdj} />
                                    </CardIcon>
                                    <h4 className={classes.cardHeading}>Categories / Sub-categories</h4>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Link to={`${url}/promotions`} >
                            <Card className={classes.productCard} style={{ borderRadius: '8px' }}>
                                <CardBody className={classes.cardBodyWrapper}>
                                    <CardIcon className={`${classes.iconColor4} ${classes.cardIconAdj}`}>
                                        <CampaignIcon className={classes.IconAdj} />
                                    </CardIcon>
                                    <h4 className={classes.cardHeading}>Promotions</h4>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Link to={`${url}/credits`} >
                            <Card className={classes.productCard} style={{ borderRadius: '8px' }}>
                                <CardBody className={classes.cardBodyWrapper}>
                                    <CardIcon className={`${classes.iconColor5} ${classes.cardIconAdj}`}>
                                        <AccountBalanceWalletIcon className={classes.IconAdj} />
                                    </CardIcon>
                                    <h4 className={classes.cardHeading}>Credits</h4>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>
                    {/* <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Card className={classes.productCard}>
                            <CardBody className={classes.cardBodyWrapper}>
                                <CardIcon color="danger" className={classes.cardIconAdj}>
                                    <AttachMoneyOutlinedIcon className={classes.IconAdj} />
                                </CardIcon>
                                <h4 className={classes.cardHeading}>Revenue</h4>
                            </CardBody>
                        </Card>
                    </GridItem> */}
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Link to={`${url}/badges`} >
                            <Card className={classes.productCard} style={{ borderRadius: '8px' }}>
                                <CardBody className={classes.cardBodyWrapper}>
                                    <CardIcon className={`${classes.iconColor6} ${classes.cardIconAdj}`}>
                                        <img src={badge} className={classes.IconAdj} />
                                    </CardIcon>
                                    <h4 className={classes.cardHeading}>Badges</h4>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Link to={`${url}/topPro`} >
                            <Card className={classes.productCard} style={{ borderRadius: '8px' }}>
                                <CardBody className={classes.cardBodyWrapper}>
                                    <CardIcon className={`${classes.iconColor7} ${classes.cardIconAdj}`}>
                                        {/* <FeaturedVideoIcon className={classes.IconAdj} /> */}
                                        <img src={protect} className={classes.IconAdj}/>
                                    </CardIcon>
                                    <h4 className={classes.cardHeading}>Top Pros</h4>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Link to={`${url}/addconfiguration`} >
                            <Card className={classes.productCard} style={{ borderRadius: '8px' }}>
                                <CardBody className={classes.cardBodyWrapper}>
                                    <CardIcon className={`${classes.iconColor8} ${classes.cardIconAdj}`}>
                                        <SettingsIcon className={classes.IconAdj} />
                                    </CardIcon>
                                    <h4 className={classes.cardHeading}>Configuration</h4>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>
                    {/* <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Link to={`${url}/processrefundrequests`} >
                            <Card className={classes.productCard} style={{ borderRadius: '8px' }}>
                                <CardBody className={classes.cardBodyWrapper}>
                                    <CardIcon className={`${classes.iconColor9} ${classes.cardIconAdj}`}>
                                        <SettingsIcon className={classes.IconAdj} />
                                    </CardIcon>
                                    <h4 className={classes.cardHeading}>Process Refund Requests</h4>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem> */}
                    {/* <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>                     <Link to={`${url}/addImageForPackage`} >
                            <Card className={classes.productCard}>
                                <CardBody className={classes.cardBodyWrapper}>
                                    <CardIcon color="primary" className={classes.cardIconAdj}>
                                        <SettingsIcon className={classes.IconAdj} />
                                    </CardIcon>
                                    <h4 className={classes.cardHeading}>Package Images</h4>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem> */}
                </GridContainer>
            </div>
            }
            <div>
                <Switch>
                    <Route path={`${path}/categories`}>
                        <ProductServicesChild {...props} />
                    </Route>
                    <Route path={`${path}/addconfiguration`}>
                        <AddConfiguration {...props} />
                    </Route>
                    <Route path={`${path}/services`}>
                        <Services {...props} />
                    </Route>
                    <Route path={`${path}/processrefundrequests`}>
                        <ProcessRefundRequest {...props} />
                    </Route>
                    <Route path={`${path}/badges`}>
                        <Badges {...props} />
                    </Route>
                    <Route path={`${path}/featuredListing`}>
                        <FeaturedList {...props} />
                    </Route>
                    <Route path={`${path}/promotions`}>
                        <AdminPromotions {...props} />
                    </Route>
                    {/* <Route path={`${path}/adManager`}>
                        <AdManager {...props} />
                    </Route> */}
                    <Route path={`${path}/credits`}>
                        <Credits {...props} />
                    </Route>
                    <Route path={`${path}/topPro`}>
                        <TopPros {...props} />
                    </Route>
                    <Route path={`${path}/notification`}>
                        <Notification {...props} />
                    </Route>
                    {/* <Route path={`${path}/addImageForPackage`}>
                        <PackageImage {...props} />
                    </Route> */}
                </Switch>
            </div>
        </div>
    );
}
