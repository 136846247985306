import React from "react";
import Card from "components/Card/Card.js";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles((styles) => ({
  divPadColor: {
    background: "#F3F3FC",
    padding: '16px',
    whiteSpace: 'nowrap'
  },
  padding16: {
    padding: '16px'
  },
  tcell1: {
    border: "unset",
    paddingRight: "unset",
    paddingBottom: "unset",
  },
  tcell2: {
    border: "unset",
    paddingLeft: "unset",
    paddingRight: "unset",
    paddingBottom: "unset",
  },
  totaldivStyle: {
    width: "87%",
    padding: "0px 28px 11px 28px",
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width:1366px)": {
      width: '92%'
    }
  },
  totalh6Style: {
    fontWeight: 'bold',
    textTransform: 'inherit'
  },
  headerTextStyle: {
    fontSize: '16px',
  },
  thead:{
    borderLeft: "15px solid #f5f5fc",
    borderBottom: "15px solid #f5f5fc",
    borderRight: "15px solid #f5f5fc",
  }

}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F5F5FC",
    color: "#6c6987",
  },

}))(TableCell);

const CreditTable = (props) => {
  const classes = useStyles();
  const spCreditArray = props.data;
  // console.log(props);
  // console.log(spCreditArray);
  // const handleEditOpen=(slug,whichUser)=>{
  //   if(whichUser=="sp"){
  //     const { from } = { from: { pathname: "/app/service_providers/service_provider_detail/" + slug } };
  //     props.history.push(from);
  //   }
  //   else if(whichUser=="user"){
  //     const { from } = { from: { pathname: "/app/users/" + slug } };
  //     props.history.push(from);
  //   }

    return (
        spCreditArray && spCreditArray.length>0 ?
        <Card>
             <TableContainer className={classes.tcontainer}>
                        <Table style={{marginBottom:'16px'}} aria-label="simple table">
                            <TableHead className={classes.thead}>
                                <TableRow>
                                    <StyledTableCell style={{fontSize: 16, fontFamily: "Muli-Bold", padding: "16px 0 0 16px", whiteSpace: "nowrap"}}>
                                      Name
                                    </StyledTableCell>
                                    <StyledTableCell style={{fontSize: 16, fontFamily: "Muli-Bold", padding: "16px 0 0 16px", whiteSpace: "nowrap"}}>
                                    Invoice No.
                                    </StyledTableCell>
                                    {/* <StyledTableCell>
                                    Status
                                    </StyledTableCell> */}
                                    <StyledTableCell style={{fontSize: 16, fontFamily: "Muli-Bold", padding: "16px 0 0 16px", whiteSpace: "nowrap"}}>
                                    Date
                                    </StyledTableCell>
                                    <StyledTableCell style={{fontSize: 16, fontFamily: "Muli-Bold", padding: "16px 0 0 16px", whiteSpace: "nowrap"}}>
                                    Time
                                    </StyledTableCell>
                                    <StyledTableCell style={{fontSize: 16, fontFamily: "Muli-Bold", padding: "16px 0 0 16px", whiteSpace: "nowrap"}}>
                                    Credit Request
                                    </StyledTableCell>
                                    
                               </TableRow>
                            </TableHead>
                            <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
                                { spCreditArray.map((prop,index)=>{
                                    return  <TableRow key={index} >
                                    <TableCell className={classes.tcell1}>
                                      
                                      <div className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                      {prop.name}
                                      </div>
                                                                  
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                      {prop.invoiceNo}
                                      </div>
                                    </TableCell>
                                    {/* <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                      {(prop.status=="succeeded" || prop.status=="success" )? "succeeded" :"Not Completed"}
                                      </div>
                                    </TableCell> */}
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                    {prop.date}
                                      </div>
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                    {prop.time}
                                      </div>
                                    </TableCell>
                                    <TableCell  className={classes.tcell2}>
                                    <div className={index%2==0 ? classes.divPadColor:classes.padding16} >
                                    {prop.credit}
                                      </div>
                                    </TableCell>
                                   
                                </TableRow>
                                 })
                                } 
                                {spCreditArray.length > 0 && 
                                 <TableRow key={"credit-scores"} >
                                   <TableCell className={classes.tcell1}></TableCell>
                                   <TableCell className={classes.tcell2}></TableCell>
                                   <TableCell className={classes.tcell2}></TableCell>
                                   <TableCell className={classes.tcell2}>    
                                      <h6 style={{padding: "16px", textAlign: "right", fontFamily: "Muli-Bold"}}>Total</h6>                                    
                                   </TableCell>
                                   <TableCell className={classes.tcell2}>  
                                      <h6 style={{padding: "16px", fontFamily: "Muli-Bold"}}>{`${props?.totalCredit?.toFixed(2)}C`}</h6>
                                   </TableCell>
                                 </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
             </Card> : null

  );
}

export default CreditTable;
