import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import ReadMore from "components/ReadMore.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

const textLimit = 100;
const numberOfItems = 4;

export default function CardList(props) {
  const classes = useStyles();
  const { tableData } = props;
  const [isSeeAll, setIsSeeAll] = useState(true);
  const toggleSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  const getListItem = (prop, key) => {

    if (isSeeAll && key >= numberOfItems)
      return <div key={key}></div>;

    return (
      <div key={key}>
        <ListItem align="center">
          <ListItemText>
            <div className="row">
              <div className="col-6">
                <small style={{ color: "#8E8CAC" }}>{`${prop.date[1]} ${prop.date[2]}, ${prop.date[3]}`}</small>
              </div>
              <div className="col-6" align="right">
                <small style={{ color: "#8E8CAC" }}>{prop.time} ago</small>
              </div>
            </div>
            <ReadMore textLimit={textLimit} >
              {prop.msg}
            </ReadMore>
          </ListItemText>
        </ListItem>
        <Divider component="li" />
      </div>
    );
  }

  return (
    <List className={classes.root}>
      {tableData.map((prop, key) => {
        return getListItem(prop, key);
      })}{
        tableData.length == 0 &&
        <ListItem align="center" key={100} style={{ padding: 20,textAlign:'center',display:'block' }}>
          <p style={{fontSize: 15}}> No Recent Notification</p>
        </ListItem>
      }
      {
        tableData.length > numberOfItems &&

        <ListItem align="center" key={100} style={{ padding: 20 }}>
          <span onClick={toggleSeeAll} className="read-or-hide-all">
            <a style={{ fontSize: 13 }}>
              {isSeeAll ? "See All >" : " Show less"}
            </a>
          </span>
        </ListItem>

      }

    </List>
  );
}