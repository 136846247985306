/* eslint-disable prettier/prettier */
import { BehaviorSubject } from 'rxjs';

import { handleResponse } from "_helpers/handle-response.js";

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    forgotPassword,
    changePassword,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Connection': 'keep-alive' },
        body: JSON.stringify({
            "email": email,
            "password": password
        })
    };

    return fetch(`${process.env.REACT_APP_URL}/auth/weblogin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

function forgotPassword(email, role) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Connection': 'keep-alive' },
        body: JSON.stringify({
            "email": email,
            "role": role.toString()
        })
    };
    return fetch(`${process.env.REACT_APP_URL}/UsersController/forgotPassword`, requestOptions).then(handleResponse)
        .catch(err => {
            console.log(err)
        });
}

function changePassword(password, token) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Connection': 'keep-alive', 'token': token },
        body: JSON.stringify({
            "password": password
         })
    };
    return fetch(`${process.env.REACT_APP_URL}/UsersController/forgotPasswordToken`, requestOptions).then(handleResponse);
}