import React from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import BackGround from '../assets/img/BackgroundImageForm.png';
import Brandlogo from "../assets/img/Brandlogo.png";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from "@material-ui/core/styles";
import { authenticationService } from '_services/authentication.service.js';
import styles from "assets/jss/material-dashboard-react/views/loginPageStyle.js";
const useStyles = makeStyles((theme) => ({
    
    loginButton: {
        background: "#DB0D0D",
        borderRadius: 10,
        padding: "14px 30px",
        "&:hover":{
            background: "#fff",
            border: "1px solid #DB0D0D",
            color: "#DB0D0D"
        },
        "&:focus":{
            background: "#fff",
            border: "1px solid #DB0D0D",
            color: "#DB0D0D"
          },
      },
      formBackground: {
        minHeight: '100%',
        backgroundImage: `url(${BackGround})`,
        backgroundSize: 'cover',
        overflow:'hidden',
        width: '100%',
        borderTop: "3px solid #e6e8ea " 
    },
    emailInput: {
        background: "#fff",
        borderRadius: 10
      }
}));
const PasswordResetForm = props => {
    const classes = useStyles();
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        //Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });
    return (
       
        <div className={`${classes.container} ${classes.centerdiv}`} style={{paddingTop:25, backgroundColor:"#fff"}}>
<div style={{ width: 118, height: 28, left: 20, top: 26, marginBottom:20, paddingLeft: 25}}>
     <img src={Brandlogo} style={{ }} />
    </div> 
    <div className={classes.formBackground} style={{  }}>
            <GridContainer style={{ width: "100vw", height: "100vw", marginTop:25 }} justify="center">
                <GridItem xs={12} sm={6} md={5}>
                    <div>
                        <Formik
                            initialValues={{
                                email: '',
                                role: 333
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email().required('Email is required'),
                            })}
                            onSubmit={({ email, role }, { setStatus, setSubmitting }) => {
                                setStatus();
                                authenticationService.forgotPassword(email, role)
                                    .then(
                                        response => {
                                            console.log(response);
                                            setSubmitting(false);
                                            if (response === "") {
                                                setStatus("Selected Account is not valid for this email");
                                            } else if(response.status=="error") {
                                                setStatus(response.errors.message);
                                            }
                                            else{
                                                props.changeResetStatus(true);
                                            }
                                        },
                                        error => {
                                            setSubmitting(false);
                                            setStatus(error.email[0]);
                                        }
                                    ).catch((err) => {
                                        console.log(err)
                                    });
                            }}>
                            {({ errors, status, touched, isSubmitting }) => (
                                <Form style={{ marginLeft: "auto", width: "345px", marginRight: "auto"}}>
                                    <Card className={classes[cardAnimaton]} style={{backgroundColor:"unset", boxShadow:"unset"}}>
                                        <CardHeader
                                            className={`${classes.cardHeader}`}
                                        >
                                            <h4 className={`${classes.cardTitle} ${classes.h4}`} style={{ fontSize: "28px", fontWeight: "450", lineHeight: "38px", fontFamily: 'Muli', color: "#1e1e1e" }}>Recover your Password</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="form-group">
                                                <h5 className={classes.h5} style={{ fontSize: 14, fontWeight: 400, fontFamily:"Muli" }}>EMAIL</h5>
                                                <Field name="email" placeholder="" type="email" className={ `${classes.emailInput} ${'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}`} />
                                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                            </div>
                                            <span className={classes.link}>A recovery link will be sent to your Email.</span>
                                            {/* <div className="form-group">
                                        <label htmlFor="role">Select Account type</label>
                                        <Field as="select" name="role" className="form-control">
                                            <option value="333">Admin</option>
                                            <option value="111">Service Provider</option>
                                            <option value="222">Customer</option>
                                        </Field>
                                    </div> */}
                                        </CardBody>
                                        <CardFooter className={classes.justifyContentCenter}>
                                            {/* <button type="submit" className={classes.resetButton} disabled={isSubmitting} simple size="lg" block>Send Link</button> */}
                                            <Button type="submit" className={classes.loginButton} simple size="lg" block>
                                                Send Link
                                            </Button>
                                            {isSubmitting &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }                                           
                                        </CardFooter>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {status &&
                                                    <div className={'alert alert-danger'}>{status}</div>
                                                }
                                            </div>
                                        </div>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </GridItem>
            </GridContainer>
            </div>

        </div>
    );
}

const PasswordReseted = () => {

    let history = useHistory();

    const redirectToLogin = () => {
        history.push("/login");
    }

    return (
        <div>
            <SweetAlert
                success
                title="Reset Password Mail Sent"
                onConfirm={redirectToLogin}
                confirmBtnText="Return to Sign-in"
            >
                Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
            </SweetAlert>
        </div>
    );
}

const PasswordReset = () => {
    const [isPasswordReset, setIsPasswordReset] = React.useState(false);

    var passwordResetComp;
    if (isPasswordReset) {
        passwordResetComp = <PasswordReseted />
    } else {
        passwordResetComp = <PasswordResetForm changeResetStatus={setIsPasswordReset} />
    }

    return (
        <>
            {passwordResetComp}
        </>
    );
}

export default PasswordReset;