import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Card from "components/Card/Card.js";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  height15: {
    height: 15
  },
  displayFlex: {
    display: "flex"
  },
  container: {
    // border:"1px solid grey",
    margin: "0 -20px"
  },
  fontSize20: {
    fontSize: '20px'
  },
  fontSize14: {
    fontSize: '14px'
  },
  divPadColor: {
    background: "#F3F3FC",
    padding: '16px',
    whiteSpace: 'nowrap'
  },
  tcell1: {
    border: "unset",
    paddingRight: "unset",
    paddingBottom: "unset",
  },
  tcell2: {
    border: "unset",
    paddingLeft: "unset",
    paddingRight: "unset",
    paddingBottom: "unset",
  },
  padding16: {
    padding: '16px',
  },
  padding0: {
    "& .MuiBox-root": {
      padding: '0'
    },
  },
  marginLeft30Px: {
    marginLeft: '30px'
  },
  thead:{
    borderLeft: "15px solid #F5F5FC",
    borderRight: "15px solid #F5F5FC",
    borderBottom: "15px solid #F5F5FC",
    "& th":{
      padding: "16px 0 0 16px",
      whiteSpace: "nowrap",
      fontFamily: 'Muli-Bold !important'
    }
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F5F5FC",
    color: "#6c6987",
  },

}))(TableCell);




const UserRegisteredTable = (props) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  return (
    <Card>
      <TableContainer className={classes.tcontainer}>
        <Table aria-label="simple table">
          <TableHead className={classes.thead}>
            <TableRow>
              <StyledTableCell style={{ color: '#6B6987', fontSize: '16px'}}>
                Name
              </StyledTableCell>
              <StyledTableCell style={{ color: '#6B6987', fontSize: '16px'}}>
                Registered On
              </StyledTableCell>
              <StyledTableCell style={{ color: '#6B6987', fontSize: '16px'}}>
                Contact no.
              </StyledTableCell>
              <StyledTableCell style={{ color: '#6B6987', fontSize: '16px'}}>
                Location
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ padding: 10 }} className={"tbody-adj"}>
            {props.data?.map((prop, index) => {
              return index < props.count && <TableRow key={index}>
                <TableCell className={classes.tcell1}>

                  <div className={index % 2 == 0 ? classes.divPadColor : classes.padding16} style={{color:'#25233C',fontFamily:'Muli',fontSize:'14px'}}>
                    {prop.userName}
                  </div>

                </TableCell>
                <TableCell className={classes.tcell2}>
                  <div className={index % 2 == 0 ? classes.divPadColor : classes.padding16} style={{color:'#25233C',fontFamily:'Muli',fontSize:'14px'}}>
                    {prop.registrationDate}
                  </div>
                </TableCell>
                <TableCell className={classes.tcell2}>
                  <div className={index % 2 == 0 ? classes.divPadColor : classes.padding16} style={{color:'#25233C',fontFamily:'Muli',fontSize:'14px'}}>
                    {prop.contactNo}
                  </div>
                </TableCell>
                <TableCell className={classes.tcell2}>
                  <div className={index % 2 == 0 ? classes.divPadColor : classes.padding16} style={{color:'#25233C',fontFamily:'Muli',fontSize:'14px' }} >
                    {prop.location}
                  </div>
                </TableCell>
              </TableRow>
            })
            }
          </TableBody>
        </Table>
        <div className={classes.height15}>

        </div>
      </TableContainer>
    </Card>
  );
}

export default UserRegisteredTable